const generateIndividualFormData = (formData, key, value) => {
	if (Array.isArray(value)) {
		value.map(value => {
			generateIndividualFormData(formData, `${key}[]`, value)
		})
	} else if (value !== null && !(value instanceof File) && typeof value === 'object') {
		Object.entries(value)
			.map(([objKey, objValue]) => {
				generateIndividualFormData(formData, `${key}[${objKey}]`, objValue)
			})
			.join('&')
	} else {
		formData.append(key, value)
	}
}

export const generateFormData = params => {
	const formData = new FormData()

	Object.entries(params || {}).map(([key, value]) => {
		generateIndividualFormData(formData, key, value)
	})

	return formData
}
