import {Chart as ChartJS, CategoryScale, LinearScale, BarElement, Tooltip} from 'chart.js'
import {Bar} from 'react-chartjs-2'

ChartJS.register(CategoryScale, LinearScale, BarElement, Tooltip)

export const options = {
	responsive: true,
	plugins: {
		legend: {
			display: false,
		},
	},
	scales: {
		y: {
			display: true
		},
		yAxis: {
			display: false,
		},
		x: {
			grid: {
				display: false,
			},
		},
	},
}

const BarChart = ({totalCost, time}) => {
	const labels = time
	const data = {
		labels,
		datasets: [
			{
				maxBarThickness: 10,
				minBarLength: 2,
				data: totalCost,
				backgroundColor: 'rgba(44, 121, 224, 0.4)',
			},
		],
	}

	return (
		<div className="relative">
			<Bar data={data} options={options} width={'250%'} />
		</div>
	)
}

export default BarChart
