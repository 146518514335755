import React, { useState, useEffect } from 'react'
import moment from 'moment'

import { Globals } from '../../../Context'
import {focusElement} from '../../../utils/keyboardHandler'

const WatchHistory = ({ handleVisibleWachtHistory ,data }) => {
	const [histories, setHistories] = useState([])
	const [offset, setOffSet] = useState(1)
	const [loadingMoreData, setLoadingMoreData] = useState(false)
	const [pagination, setPagination] = useState({
		totalEntries: 0,
		totalPages: 0,
		currentPage: 1,
	})

	useEffect(() => {
		setLoadingMoreData(true)
		fetchData()
		focusElement('.watch-history-view')
	}, [])

	const fetchData = async () => {
		setLoadingMoreData(true)

		Globals.New_Axios()
			.get(`${Globals.NEW_API_URL}/${Globals.ENDPOINTVERSION}/spaces/${Globals.SELECTEDSPACEID}/watches/${data.id}/histories?page=${offset}&per_page=10`)
			.then(response => {
				setHistories([...histories, ...response.data.histories])
				setOffSet(offset + 1)
				setLoadingMoreData(false)
				setPagination({
					totalEntries: response.data.total_entries,
					totalPages: response.data.total_pages,
					currentPage: response.data.current_page,
				})
			})
	}

	const loadMoreData = async (event) => {
		if (loadingMoreData || histories.length >= pagination.totalEntries) return

		const {scrollTop, scrollHeight, clientHeight} = event.target
		const isDistanceToBottomEqualHalf = scrollTop + clientHeight >= scrollHeight / 2

		if (isDistanceToBottomEqualHalf && histories.length < pagination.totalEntries) {
			await fetchData(true)
		}
	}

	return (
		<div
			tabIndex="-1"
			aria-hidden="true"
			className="watch-history-view bg-black/50 fixed flex h-screen items-center justify-center left-0 overflow-hidden inset-0 w-full z-50"
			onKeyDown={(event)=> {
				if(event.key === 'Escape' && event.target.classList.contains('watch-history-view')) {
					handleVisibleWachtHistory(false)
				}
			}}
		>
			<div className="relative max-h-[1050px] max-w-[583px] w-full">
				<div className="relative bg-white rounded-xl shadow h-full">
					<div className="flex justify-between items-start px-8 py-4 rounded-t-xl border-b bg-slate-600 text-white">
						<h3 className="text-white font-bold text-lg">{'Watch History'}</h3>
						<button
							onClick={() => {
								handleVisibleWachtHistory(false)
							}}
							type="button"
							className="bg-transparent rounded-xl text-sm py-1.5 ml-auto inline-flex items-center"
						>
							<svg className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
								<path
									fillRule="evenodd"
									d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
									clipRule="evenodd"
								></path>
							</svg>
						</button>
					</div>
					<div className='overflow-hidden w-full'>
						<div
							className="px-12 pt-4 space-y-6 overflow-scroll h-[calc(100vh-145px)] max-h-[857px] w-full"
							onScroll={(e) => loadMoreData(e)}
						>
						{/* Loading Effect */}
						{histories.length === 0 && loadingMoreData && (
							<div className="absolute top-0 left-0 right-0 bottom-0 flex justify-center items-center z-50">
								<button
									type="button"
									className="inline-flex items-center px-4 py-2 leading-6 text-sm shadow rounded-md text-white bg-blue transition ease-in-out duration-150 cursor-not-allowed"
									disabled
								>
									<svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
										{' '}
										<circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4" />
										<path
											className="opacity-75"
											fill="currentColor"
											d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
										/>
									</svg>
									<span>Loading... </span>
								</button>
							</div>
						)}
						{histories.length > 0 && histories.map((history, index) => {
							return (
								<div className='p-2 border-b ' key={index}>
									{history.display_updated_attributes.map((value, valueIndex) => {
										return (
											<div key={valueIndex}>{value}</div>
										);
									})}
									<p className='text-gray-400 text-sm mt-1'>{moment(history.updated_at).format('MM/DD/YY h:mm A')} - {history.user_updated?.first_name} {history.user_updated?.last_name}</p>
								</div>
							);
						})}
						</div>
					</div>
				</div>
			</div>

		</div>
	)
}

export default WatchHistory
