import React from 'react'
import Privacy from '../Privacy'

const Export = () => {
	return (
		<div className="relative pt-24 pb-24 w-full">
			<div className="h-screen flex items-center justify-center space-x-2 text-2xl text-center py-4">
				<svg
					className="w-6 h-6"
					fill="none"
					stroke="currentColor"
					strokeWidth={1.5}
					viewBox="0 0 24 24"
					xmlns="http://www.w3.org/2000/svg"
					aria-hidden="true"
				>
					{' '}
					<path
						strokeLinecap="round"
						strokeLinejoin="round"
						d="M12 6v6h4.5m4.5 0a9 9 0 11-18 0 9 9 0 0118 0z"
					/>{' '}
				</svg>
				<span>Coming soon</span>
			</div>
			<Privacy />
		</div>
	)
}
export default Export
