import { arrayMoveImmutable } from 'array-move'
import React, {useState} from 'react'
import {SortableContainer, SortableElement, SortableHandle} from 'react-sortable-hoc'
import { useDispatch } from 'react-redux'
import { addToast } from '../../app/appActions'
import Button from '@mui/material/Button'
import SnackbarContent from '@mui/material/SnackbarContent'


const DragHandler = SortableHandle(() => {
	return (
		<div className='cursor-pointer p-2'>
			<svg width="10" height="12" viewBox="0 0 10 12" fill="none" xmlns="http://www.w3.org/2000/svg">
				<path
					d="M5.38878 0.156094C5.17166 -0.0520313 4.82853 -0.0520313 4.61141 0.156094L0.298908 4.28109C0.132783 4.44009 0.0802831 4.68384 0.165783 4.89722C0.251283 5.11059 0.457533 5.25009 0.687408 5.25009H9.31241C9.54191 5.25009 9.74891 5.11022 9.83478 4.89722C9.92028 4.68422 9.86741 4.44009 9.70166 4.28109L5.38878 0.156094ZM9.31241 6.75009H0.687408C0.457533 6.75009 0.250908 6.88997 0.165408 7.10297C0.0799079 7.31597 0.132783 7.56009 0.298533 7.71909L4.61103 11.8441C4.71978 11.948 4.85966 12.0001 4.99991 12.0001C5.14016 12.0001 5.28003 11.948 5.38878 11.8441L9.70128 7.71909C9.86703 7.56009 9.91991 7.31634 9.83441 7.10297C9.74891 6.88997 9.54191 6.75009 9.31241 6.75009Z"
					fill="#5B5B5B"
					/>
			</svg>
		</div>
	)
})

const SortableItem = SortableElement(({inputIndex, value, setInputs, inputs, selectedColumnEdit, mode}) => {
	const [open, setOpen] = useState(false)
	const handleDeleteInput = (i) => {
		if (mode === "edit") {
			let has = false
			selectedColumnEdit.custom_column.has_watch_options.forEach((option) => {
				if (inputs[inputIndex].value === option.value) {
					setOpen(true)
					has = true
				}
			})
			if (has) {
				return;
			}
			setInputs(inputs => inputs.filter((value, index) => i !== index))
		} else {
			setInputs(inputs => inputs.filter((value, index) => i !== index))
		}
	}

	const handleConfirmDeleteInput = (i) => {
		setInputs(inputs => inputs.filter((value, index) => i !== index))
		setOpen(false)
	}

	const handleInputChange = async (index, event) => {
		const newInputs = [...inputs]
		newInputs[index].value = event.target.value
		setInputs(newInputs)
	}

	const action = (
		<div className='flex flex-row gap-5 justify-center items-center'>
			<Button sx={{ fontWeight: "bold", color: "gray"}} onClick={() => setOpen(false)}>
				Cancel
			</Button>
			<Button sx={{ fontWeight: "bold"}} onClick={() => handleConfirmDeleteInput(inputIndex)}>
				Confirm
			</Button>
		</div>
	)

	return (
		<li className='list-none'>
			<div className="flex flex-row items-center justify-center gap-4 my-3 select-none ">
				<DragHandler />
				<input
					id="column_label"
					type="text"
					name="column_label"
					placeholder="Option"
					className="w-[80%] p-4 my-2 inputLogin appearance-none outline-none text-sm font-medium"
					onChange={e => {
						handleInputChange(inputIndex, e)
					}}
					value={value}
					/>
					<button onClick={() => {handleDeleteInput(inputIndex)}} className="flex flex-col text-xs rounded items-center p-2 pt-4">
				<img src="/Images/remove_icon.png" alt="remove icon" className="w-[22px] h-[22px]" />
				<span className="text-[10px] font-semibold text-[#EE8B8B]">Delete</span>
			</button>
			</div>
			{open ? <SnackbarContent
								sx={{backgroundColor: "white", color: "black", border: 1, borderColor: "gray", borderRadius: 1, boxShadow: 0}}
								message={`"${inputs[inputIndex].value}" will be cleared from all watches, are you sure you want to delete it?`} action={action}>
							</SnackbarContent>
							:
							null
			}
		</li>
	)
})

const SortableList = SortableContainer(({inputs, setInputs, selectedColumnEdit, mode}) => {
	return (
		<ul>
			{inputs.map((input, index) => (
				<SortableItem
					key={index}
					index={index}
					inputIndex={index}
					value={input.value}
					inputs={inputs}
					setInputs={setInputs}
					selectedColumnEdit={selectedColumnEdit}
					mode={mode}
					/>
			))}
		</ul>
	)
})

const uuidv4 = () => {
	return "10000000-1000-4000-8000-100000000000".replace(/[018]/g, c =>
		(+c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> +c / 4).toString(16)
	);
}

const CustomListOptions = ({setOpenListOptions, options, setOptions, selectedColumnEdit, mode}) => {
	const [inputs, setInputs] = useState(options.length === 0 ? [{id: uuidv4(), value: ''}] : options)
	const dispatch = useDispatch()
	const handleAddInput = () => {
		for(let i =0; i < inputs.length; i++) {
			let input = inputs[i].value.replace(/\s+/g, '').toLowerCase()
			if (input === "") {
				dispatch(
					addToast({
							title: 'Error',
							text: 'Please fill out the previous input before adding another.',
							type: 'error',
					}))
				return;
			}
		}

		setInputs([...inputs, {id: uuidv4(), value: ''}])
	}

	const handleSaveInputs = () => {
		const map = new Map()
		if (inputs.length === 0) {
			dispatch(
				addToast({
						title: 'Error',
						text: 'Please add at least one options before continue.',
						type: 'error',
				}))
			return;
		}

		for(let i =0; i < inputs.length; i++) {
			let input = inputs[i].value.replace(/\s+/g, '').toLowerCase()

			if (input.value === "") {
				dispatch(
					addToast({
							title: 'Error',
							text: 'Please fill out all inputs.',
							type: 'error',
					}))
				return;
			} else if (map.has(input.value)) {
				dispatch(
					addToast({
							title: 'Error',
							text: 'Same input value',
							type: 'error',
					}))
				return;
			}
			map.set(input, inputs[i].value)
		}

		setOptions(inputs)
		setOpenListOptions(false)
	}

	const handleCloseForm = () => {
		setInputs([])
		setOpenListOptions(false)
	}

	const onSortEnd = ({oldIndex, newIndex}) => {
		setInputs(arrayMoveImmutable(inputs, oldIndex, newIndex))
	}

	return (
		<div className="fixed top-0 w-screen h-screen bg-black/50 flex items-center justify-center z-50">
			<div className="bg-white drop-shadow-xl rounded-xl sm:w-1/2" style={{maxWidth: '400px'}}>
				<div className="w-full borderCardChrono relative px-8 py-4 flex items-center">
					<span className="text-white font-bold text-lg">Dropdown Option(s)</span>
					<button onClick={() => setOpenListOptions(false)} className="absolute right-0 mr-5 cursor-pointer">
						<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path
								d="M14.8672 0.194897C14.625 0.200615 14.3949 0.301995 14.2271 0.47688L8.00026 6.70377L1.77337 0.47688C1.68784 0.38896 1.58554 0.319098 1.47252 0.271431C1.35951 0.223764 1.23807 0.199261 1.11541 0.199373C0.933136 0.199599 0.755062 0.254158 0.60394 0.35608C0.452819 0.458003 0.335512 0.602661 0.267004 0.771577C0.198497 0.940492 0.181898 1.126 0.21933 1.30439C0.256762 1.48278 0.346524 1.64597 0.47715 1.7731L6.70404 7.99999L0.47715 14.2269C0.389174 14.3113 0.318936 14.4125 0.27055 14.5245C0.222163 14.6364 0.196601 14.7569 0.19536 14.8789C0.194119 15.0008 0.217224 15.1218 0.263323 15.2347C0.309421 15.3476 0.377586 15.4502 0.463825 15.5364C0.550064 15.6227 0.652643 15.6908 0.765556 15.7369C0.878469 15.783 0.999445 15.8061 1.1214 15.8049C1.24335 15.8037 1.36383 15.7781 1.47578 15.7297C1.58774 15.6813 1.68891 15.6111 1.77337 15.5231L8.00026 9.29622L14.2271 15.5231C14.3116 15.6111 14.4128 15.6813 14.5247 15.7297C14.6367 15.7781 14.7572 15.8037 14.8791 15.8049C15.0011 15.8061 15.1221 15.783 15.235 15.7369C15.3479 15.6908 15.4505 15.6227 15.5367 15.5364C15.6229 15.4502 15.6911 15.3476 15.7372 15.2347C15.7833 15.1218 15.8064 15.0008 15.8052 14.8789C15.8039 14.7569 15.7784 14.6364 15.73 14.5245C15.6816 14.4125 15.6113 14.3113 15.5234 14.2269L9.29648 7.99999L15.5234 1.7731C15.6568 1.6452 15.7484 1.47997 15.7862 1.29908C15.824 1.11819 15.8063 0.93009 15.7354 0.759448C15.6644 0.588807 15.5436 0.443591 15.3886 0.342846C15.2337 0.2421 15.052 0.19053 14.8672 0.194897Z"
								fill="white"
							/>
						</svg>
					</button>
				</div>

				<div className="flex flex-col p-7 overflow-y-scroll" style={{maxHeight: "600px"}}>
					<div className="flex flex-col relative ">
						<div className="relative w-full  ">
							<SortableList
								helperClass="sortableHelper"
								useDragHandle={true}
								inputs={inputs}
								transitionDuration={100}
								hideSortableGhost={true}
								setInputs={setInputs}
								onSortEnd={onSortEnd}
								selectedColumnEdit={selectedColumnEdit}
								mode={mode}
								/>
						</div>
					</div>

					<div className="flex flex-col relative">
						<div>
							<button
								onClick={() => {
									handleAddInput()
								}}
								className="py-3 px-4 mt-2 rounded font-medium text-sm cursor-pointer border-solid border-2 border-blue-600 "
							>
								Add another option
							</button>
						</div>
					</div>
					<div className="flex flex-row w-full place-content-end mt-4">
						<button onClick={() => handleCloseForm()} className="py-3 px-4 mt-2 mr-6 rounded font-medium text-sm cursor-pointer border bg-white">
							Cancel
						</button>
						<button onClick={() => handleSaveInputs()} type="submit" className="py-3 px-4 mt-2 rounded font-medium text-sm text-white cursor-pointer uppercase bg-blue">
							Save
						</button>
					</div>
				</div>
			</div>
		</div>
	)
}
export default CustomListOptions
