import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'

import { Globals } from '../../../Context'
import {addToast} from '../../../app/appActions'
import findIndex from 'lodash/findIndex'
import Typography from '@mui/material/Typography'
import Switch from '@mui/material/Switch'


const SensitiveInformation = (props, {addToast}) => {
	const [columns, setColumns] = useState([])
	const getColumListInv = async () => {
		await Globals.New_Axios()
			.get(`${Globals.NEW_API_URL}/${Globals.ENDPOINTVERSION}/spaces/${Globals.SELECTEDSPACEID}/column_configurations/sold_inventory`)
			.then(response => {
				let dataDictionary = response.data.column_configurations
				setColumns(dataDictionary)
			})
			.catch(err => {
				console.error(err)
			})
	}

	useEffect(() => {
		getColumListInv()
	}, [])

	const handleChangeSwitch = async (checked, column) => {
		const currentIndex = findIndex(columns, (value) => column.id === value.id)
		if(currentIndex > -1) {
			columns[currentIndex].sensitive = checked
			await Globals.New_Axios()
			.patch(`${Globals.NEW_API_URL}/${Globals.ENDPOINTVERSION}/spaces/${Globals.SELECTEDSPACEID}/column_configurations/${columns[currentIndex].id}/update`, {sensitive: checked})
			.then(response => {
				setColumns([...columns])
			})
			.catch(err => {
				return addToast({
					title: 'Error',
					text: err?.response?.data?.message,
					type: 'error',
				})
			})
		}
	}

    return (
        <div className="w-full h-full px-20 pt-2 pb-10">
            <div>
				<Typography variant="h6" sx={{ fontWeight: 'bold', mr: 2, mt: 2 }}>
					Sensitive Information
				</Typography>
				{columns.map(column => (
					<div className="w-1/2 grid grid-cols-2 gap-2 mt-2">
						<div className="flex items-center w-full">
								<Typography className='truncate w-[full]' sx={{ fontWeight: 'bold', mr: 2 }}>
									{column.label}
								</Typography>
							</div>
						<div className="flex items-center mr-20">
							<Switch
								disabled={!Globals.adminOrOwner(props.profile)}
								checked={column.sensitive}
								onChange={e => handleChangeSwitch(e.target.checked, column)}
								sx={{
									"&.MuiSwitch-root .MuiSwitch-input": {
										height: "-webkit-fill-available"
									},
								}}
							/>
						</div>
                	</div>
				))}
            </div>
        </div>
    )
}

const mapStateToProps = (state, props) => {
	return {}
}

const mapActionsToProps = {
	addToast,
}

export default connect(mapStateToProps, mapActionsToProps)(SensitiveInformation)
