import Classnames from 'classnames'
import React, {useEffect, useRef, useState} from 'react'
import {Resizable} from 'react-resizable'
import {connect} from 'react-redux'
import Tooltip from '@mui/material/Tooltip'
import {Globals} from '../../Context'
import head from 'lodash/head'
import isFunction from 'lodash/isFunction'
import uniqueId from 'lodash/uniqueId'
import sumBy from 'lodash/sumBy'
import sum from 'lodash/sum'
import findIndex from 'lodash/findIndex'
import isEmpty from 'lodash/isEmpty'
import isUndefined from 'lodash/isUndefined'

import SingleItemDetail from '../Sales/SingleItemDetail'
import InfiniteScroll from 'react-infinite-scroll-component'
const checkboxWidth = 45 // This is a global value for checkbox width

class EleftaTable extends React.Component {
	constructor() {
		super()
		this.fetchAllWatchesCountRef = React.createRef()
		this.listWrapperRef = React.createRef();

		this.state = {
			defaultWidth: 120, //Default width.
			resize: true,
			columns: [],
			dataSource: [],
			isAllChecked: false,
			checkedRows: [],
			hasMore: true,
			importantColumns: ['msrp_price', 'wholesale_price', 'online_price', 'cost', 'vendor'],
			columnWidths: {},
			valueOfDropDown: 'count',
			isVisibleDropDown: false,
			height: 0
		}
		this.previousColumnWidths = {...this.state.columnWidths}
	}

	async componentDidMount() {
		const props = this.props
		const {columns, dataSource, checkedRows, fetchAllWatchesCount} = props
		this.fetchAllWatchesCountRef.current = fetchAllWatchesCount
		if (columns) this.setState({columns: columns})
		if (dataSource) this.setState({dataSource: dataSource})
		if (checkedRows) this.setState({checkedRows: checkedRows})
		await this.getColumnWidth()
		if(!!this.listWrapperRef.current) {
			this.setState({height: this.listWrapperRef.current.clientHeight})
		}
		window.addEventListener('resize', this.handleGetHeightResize);
	}

	componentWillUnmount() {
		// Remove the event listener when the component is unmounted
		window.removeEventListener('resize', this.handleGetHeightResize);
	}


	handleGetHeightResize = () => {
		// Update the state with the new window height
		if(!!this.listWrapperRef.current) {
			this.setState({height: this.listWrapperRef.current.clientHeight})
		}
	};

	async componentDidUpdate(prevProps) {
		const { columnWidths } = this.state
		const { columns, kind } = this.props

		if (this.props.dataSource !== prevProps.dataSource) {
			this.setState({dataSource: this.props.dataSource})
		}

		if (columns !== prevProps.columns) {
			await this.getColumnWidth()
			this.setState({columns: columns})
		}

		if (this.props.checkedRows && this.props.checkedRows.length === 100001 && this.props.checkedRows.length !== prevProps.checkedRows.length) {
			this.setState({checkedRows: this.props.checkedRows, isAllChecked: false})
		}

		if(this.props.fetchAllWatchesCount !== this.fetchAllWatchesCountRef.current) {
			this.fetchAllWatchesCountRef.current = this.props.fetchAllWatchesCount
			this.setState({checkedRows: this.props.checkedRows.map(row => row.id)})
		}
		if (this.previousColumnWidths !== columnWidths && columns !== prevProps.columns && kind !== 'customer') {
			this.generateColumnWidths()
		}

		if (this.previousColumnWidths !== columnWidths && columns  && kind === 'customer') {
			this.generateColumnWidths()
		}
	}

	async generateColumnWidths() {
		const { columnWidths } = this.state
		const { columns } = this.props
		const columsWithCustomWidth = columns.map(column => {
			if (columnWidths[column.key]) {
				column.width = columnWidths[column.key]
			}

			if (!column.width) {
			column.width = this.state.defaultWidth;
			}

			return column
		})
		this.setState({columns: columsWithCustomWidth})
		this.previousColumnWidths = columnWidths
	}

	async getColumnWidth() {
		const {kind} = this.props
		const pageIdentifier = kind
		try {
			let response = await Globals.New_Axios().get(`${Globals.baseUri}/column_widths/${pageIdentifier}`)

			if (response?.status === 200 && response?.data?.column_width_items) {
				this.setState({
					columnWidths: response.data.column_width_items
				})
			}
		} catch (error) {
			console.log('error', error)
		}
	}

	handleResize(resize = true, identifier, event, object) {
		try {
			if (!resize) return
			const state = this.state
			const {columns} = state

			const column = head(columns.filter(c => c.key === identifier))

			let width = object.size.width

			if (column.minWidth > width) {
				width = column.minWidth
			} else if (column.maxWidth < width) {
				width = column.maxWidth
			}

			const __columns = columns.map(column => {
				if (column.key === identifier) {
					column.width = Math.max(width, 45)
				}

				return column
			})

			this.setState({columns: __columns})

			if (this.props.saveView && this.props.saveViewId) {
				//Save sizes in local storage.
				const columnsToSave = {}
				__columns.forEach(col => {
					columnsToSave[col.key] = col.width
				})
				Globals.Cache.set(this.props.saveViewId, JSON.stringify(columnsToSave))
			}
		} catch (error) {
			console.warn(error)
		}
	}

	handleCheckboxAllRows(event) {
		const isChecked = event.target.checked;
		let _checkedRows = [];

		this.setState({ isAllChecked: isChecked }, () => {
		  if (isFunction(this.props.handleChangeWillApllyNewCheckedAll)) {
			this.props.handleChangeWillApllyNewCheckedAll(isChecked);
		  }

		  if (isChecked) {
			const { checkedRows = [], dataSource } = this.props;
			const isPartialSelection = checkedRows.length !== 100001;

			_checkedRows = isPartialSelection
			  ? [...this.props.checkedRows, ...this.state.dataSource].map(data => data.id)
			  : dataSource.map(data => data.id);
		  }

		  this.setState({ checkedRows: _checkedRows }, () => {
			if (isFunction(this.props.onRowChecked)) {
			  this.props.onRowChecked(_checkedRows, isChecked);
			}
		  });
		});
	}

	handleCheckRow(data, $event) {
		const { isAllChecked } = this.state
		$event.stopPropagation()
		let checkedRows = this.state.checkedRows
		if (checkedRows.includes(data.id)) {
			//remove it from the rows checked.
			checkedRows = checkedRows.filter(row => row !== data.id)
		} else {
			//Add to the rows checked.
			checkedRows = [...checkedRows, data.id]
		}

		this.setState({checkedRows: checkedRows}, () => {
			if (isFunction(this.props.handleChangeWillApllyNewCheckedAll)) this.props.handleChangeWillApllyNewCheckedAll(false)
			if (isFunction(this.props.onRowChecked)) this.props.onRowChecked(checkedRows, false)
		})

		if(isAllChecked) {
			this.setState({isAllChecked: false})
		}
	}

	handleTableRowClick(data, column, e) {
		e.stopPropagation()
		if (isFunction(this.props.onRowClick)) this.props.onRowClick(data, column)
	}

	showOrderDetail = (data, column, e) => {
		e.stopPropagation()
		this.props.handleOrderDetailClick(data, column)
	}

	handleUpdateColumnWidth = async ( resize = true, identifier, event, object) => {
		const {kind} = this.props
		const pageIdentifier = kind
		try {
			let response = await Globals.New_Axios().patch(`${Globals.baseUri}/column_widths/${pageIdentifier}`, {
				column_width: {
					column_width_items: {
						...this.state.columnWidths,
						[identifier]: object.size.width,
					}
				}
			})

			if (response.status === 200 && response.data.column_width_items) {
				this.setState({columnWidths: response.data.column_width_items})
			}
		} catch (error) {
			console.log('error', error)
		}
	}

	handleChangeDropDown = (key) => {
		this.setState({valueOfDropDown: key, isVisibleDropDown: false})
	}

	totalPriceDropDown = () => {
		const { valueOfDropDown } = this.state;
		if(valueOfDropDown === 'count') {
			return `${this.props.checkedRows.length}`
	 	} else if(valueOfDropDown === 'wholesale_price') {
			const sum  = sumBy(this.props.checkedRows, (value) => {return !!value?.wholesale_price && value?.wholesale_price})
			return `${Globals.formatCurrency(sum)}`
		} else if (valueOfDropDown === 'cost') {
			const sum  = sumBy(this.props.checkedRows, (value) => {return !!value?.cost && value?.cost})
			return `${Globals.formatCurrency(sum)}`
		} else if (valueOfDropDown === 'sold_price') {
			const sum  = sumBy(this.props.checkedRows, (value) => {return !!value?.sold_price && value?.sold_price})
			return `${Globals.formatCurrency(sum)}`
		}
	}

	titleDropDown = () => {
		const { valueOfDropDown } = this.state;
		if(valueOfDropDown === 'count') {
			return 'Count:'
	 	} else if(valueOfDropDown === 'wholesale_price') {
			return 'Wholesale Price:'
		} else if (valueOfDropDown === 'cost') {
			return 'Cost:'
		} else if (valueOfDropDown === 'sold_price') {
			return 'Sold Price:'
		}
	}

	textContentDropDown = (key) => {
		if(key === 'count') {
			return `Count: ${this.props.checkedRows.length}`
	 	} else if(key === 'wholesale_price') {
			const sum  = sumBy(this.props.checkedRows, (value) => {return !!value?.wholesale_price && value?.wholesale_price})
			return `Wholesale Price: ${Globals.formatCurrency(sum)}`
		} else if (key === 'cost') {
			const sum  = sumBy(this.props.checkedRows, (value) => {return !!value?.cost && value?.cost})
			return `Cost: ${Globals.formatCurrency(sum)}`
		 } else if (key === 'sold_price') {
			const sum  = sumBy(this.props.checkedRows, (value) => {return !!value?.sold_price && value?.sold_price})
			return `Sold Price: ${Globals.formatCurrency(sum)}`
		}
	}

	handleVisibleDropDown = () => {
		if(this.props.loadingDataCheckedRows && this.state.checkedRows.length > 0 && this.state.checkedRows.length !== 100001) {
			this.setState({isVisibleDropDown: !this.state.isVisibleDropDown})
		}
	}

	render() {
		const props = this.props
		const state = this.state
		const {columns} = state
		const _columns = this.props.columns.filter(column => column.enabled)
		const _fixedColumns = _columns.filter(c => c.freezed)
		const {dataSource} = state

		const leftStickyValue = (column, colWidth) => {
			if (props.disableFlow) return 'initial'

			let leftStickyIndex = findIndex(_fixedColumns, _column => _column.key === column.key)

			if (leftStickyIndex > 0 && column.freezed) {
				const previousColWidth = sum(_fixedColumns.slice(0, leftStickyIndex).map(_column => (_column.width ? _column.width : colWidth)))
				let columnWidth = previousColWidth + (props.checkbox ? checkboxWidth : 0)
				return columnWidth
			}

			return column.freezed ? (props.checkbox ? checkboxWidth : 0) : 'initial'
		}

		return (
			<div
				style={{height: "100%"}}
				ref={this.listWrapperRef}
			>
				<InfiniteScroll
					loader={()=>null}
					dataLength={dataSource?.length || 0} //This is important field to render the next data
					next={this.props.loadMoreData && this.props.loadMoreData}
					height={(this.state.height || 0) + "px"}
					scrollThreshold={"50%"}
					hasMore={true}
				>
					<div className="__elefta-table-wrapper">
						<div className="__elefta-table-container">
							{
								state.checkedRows.length > 0 && state.checkedRows.length !== 100001 &&
								<>
									{
										!state.isVisibleDropDown && !props.disableFlow &&
										<div onClick={() => {
											this.handleVisibleDropDown()
										}}
											 className="p-2 flex justify-between  items-center bg-[#abdbbb] rounded-[5px] fixed z-[10] bottom-4 right-4 w-auto max-w-auto text-black text-[14px] min-w-[200px]">
											<div className="flex ">
												{this.titleDropDown()}
												{!this.props.loadingDataCheckedRows && state.checkedRows.length > 0 && state.checkedRows.length !== 100001 ?
													<svg className="inline-block animate-spin h-5 w-5 text-white ml-3"
														 xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
														{' '}
														<circle className="opacity-25" cx="12" cy="12" r="10"
																stroke="currentColor" strokeWidth="4" />
														<path
															className="opacity-75"
															fill="currentColor"
															d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
														/>
													</svg> :
													<p className="px-[10px]">
														{this.totalPriceDropDown()}
													</p>
												}
											</div>
											<svg
												className={Classnames('bg-[#abdbbb] pointer-events-none right-0', {})}
												width="18"
												height="18"
												viewBox="0 0 20 20"
												fill="none"
											>
												<path
													fillRule="evenodd"
													clipRule="evenodd"
													d="M5.29289 7.29289C5.68342 6.90237 6.31658 6.90237 6.7071 7.29289L9.99999 10.5858L13.2929 7.29289C13.6834 6.90237 14.3166 6.90237 14.7071 7.29289C15.0976 7.68342 15.0976 8.31658 14.7071 8.70711L10.7071 12.7071C10.3166 13.0976 9.68341 13.0976 9.29289 12.7071L5.29289 8.70711C4.90237 8.31658 4.90237 7.68342 5.29289 7.29289Z"
													fill="#5D6E81"
												/>
											</svg>
										</div>
									}
									{
										state.isVisibleDropDown &&
										<div
											className="p-2 flex flex-col justify-center items-start bg-[#fff] rounded-[5px] fixed z-[10] bottom-4 right-4 w-auto max-w-auto  min-w-[200px] text-black text-[14px]">
											<div className="p-2 hover:bg-[#ccc] w-full"
												 onClick={() => this.handleChangeDropDown('count')}>
												{this.textContentDropDown('count')}
											</div>
											<div className="p-2 hover:bg-[#ccc] w-full"
												 onClick={() => this.handleChangeDropDown('wholesale_price')}>
												{this.textContentDropDown('wholesale_price')}
											</div>
											<div className="p-2 hover:bg-[#ccc] w-full"
												 onClick={() => this.handleChangeDropDown('cost')}>
												{this.textContentDropDown('cost')}
											</div>
											{
												this.props.kind === 'sold_inventory' &&
												<div className="p-2 hover:bg-[#ccc] w-full"
													 onClick={() => this.handleChangeDropDown('sold_price')}>
													{this.textContentDropDown('sold_price')}
												</div>
											}
										</div>
									}
								</>
							}

							<table
								id={props.tableId}
								ref={eleftaTable => {
									this.eleftaTable = eleftaTable
								}}
								className={Classnames('divide-y divide-gray-200  bg-transparent overflow-scroll', {
									'w-screen table-fixed': !props.initialWidth,
									'w-full': props.initialWidth
								})}
							>
								<thead
									className="bg-white sticky top-0 z-10"
									style={{
										boxShadow: '0px 2px 0px 0px rgba(0, 0, 0, 0.05)',
										WebkitBoxShadow: '0px 2px 0px 0px rgba(0, 0, 0, 0.05)'
									}}
								>
								<tr>
									{props.checkbox && (
										<th
											key="checkbox"
											datakey="checkbox"
											style={{
												width: checkboxWidth,
												position: 'sticky',
												left: 0,
												zIndex: 31,
												boxShadow: this.props.boxShadow ? 'inset 0px 5px 20px rgba(0, 0, 0, 0.05)' : '',
												WebkitBoxShadow: '0px 2px 0px 0px rgba(0, 0, 0, 0.05)'
											}}
											className="text-sm font-bold tracking-wider text-left cursor-pointer bg-white is-sticky elefta-bordered-table-x"
										>
											<div className="py-3 px-2 pr-3">
												<div className="__checkbox select-all">
													<input id={props.checkboxId ? props.checkboxId : `checkbox-thread-all`}
														   checked={this.state.isAllChecked} type="checkbox"
														   onChange={this.handleCheckboxAllRows.bind(this)} />
													<Tooltip title="Check all rows" placement="bottom" arrow>
														<label
															htmlFor={props.checkboxId ? props.checkboxId : `checkbox-thread-all`}></label>
													</Tooltip>
												</div>
											</div>
										</th>
									)}

								{/* {console.log(_columns)} */}
								{_columns.map((column, index) => {
									const stateColumn = head((column.key, columns.filter(c => c.key === column.key)))
									if (isEmpty(stateColumn)) return <React.Fragment key={column.key} />
									const colWidth = stateColumn.width && !props.disableFlow ? stateColumn.width : state.defaultWidth

									if (!column.enabled) return <React.Fragment key={index}></React.Fragment>
									if (column.sensitive && this.props.profile.role == 'manager') return <React.Fragment key={index}></React.Fragment>

									return (
										<Resizable key={column.key} width={colWidth} height={0} onResize={this.handleResize.bind(this, column.resize, column.key)} onResizeStop={this.handleUpdateColumnWidth.bind(this, column.resize, column.key)}>
											<th
												key={column.key}
												datakey={column.key}
												ref={tableHead => {
													this.tableHead = tableHead
												}}
												style={{
													width: colWidth,
													position: column.freezed && !props.disableFlow ? 'sticky' : 'relative',
													left: leftStickyValue(column, colWidth),
													zIndex: column.freezed ? 2 : 1,
													backgroundColor: column.freezed ? 'white' : 'initial',
													boxShadow: this.props.boxShadow ? 'inset 0px 5px 20px rgba(0, 0, 0, 0.05)' : '',
													WebkitBoxShadow: '0px 2px 0px 0px rgba(0, 0, 0, 0.05)'
													//color:this.props.overallFilters[0]?.key === column.key ? "blue" :""
												}}
												className={Classnames('text-sm font-bold tracking-wider text-left cursor-pointer bg-white', {
													'disable-resize': column.resize === false,
													'is-sticky': column.freezed && !props.disableFlow,
												})}
											>
												{props.switchOrder && (
													<div className="py-3 px-2 pr-3">
														{column.data({
															indexPlacement: index,
															isLast: index + 1 === _columns.length,
															isSecondLast: index + 2 === _columns.length,
															orderby: props.orderBy,
															order: props.order,
															sortAction: props.switchOrder,
															filterAction: props.filterAction,
															key: column.key,
															dataPackage: props.dataOriginalSource,
															dataSource: props.headerData,
															filterObject: props.filterObject,
														})}
													</div>
												)}
												{!props.switchOrder && (
													<div className="py-3 px-2 pr-3">
														{column.data({
															indexPlacement: index,
															isLast: index + 1 === _columns.length,
															isSecondLast: index + 2 === _columns.length,
															key: column.key,
															dataPackage: props.dataOriginalSource,
															// headerData: props.dataSource,
															dataSource: props.dataSource,
															filterObject: props.filterObject,
														})}
													</div>
												)}
											</th>
										</Resizable>
									)
								})}
							</tr>
						</thead>

						<tbody className="bg-white divide-y divide-gray-200 h-10 overflow-y-scroll">
							{dataSource.map((data, index) => {
								return (
									<tr
										key={uniqueId(`${data.id}_`)}
										className={Classnames('cursor-pointer', {
											'bg-gray-50': props.separation && index % 2 === 0,
											'bg-white': index % 2 !== 0,
											'bg-red-50': props.separation && props.errorSeparation && index % 2 === 0,
										})}
									>
										{props.checkbox && (
											<td
												key={index}
												datakey="checkbox"
												style={{
													width: 62,
													position: 'sticky',
													left: 0,
													zIndex: 2,
												}}
												className={Classnames(
													props.separation && index % 2 === 0 ? 'bg-gray-50' : 'bg-white',
													'elefta-bordered-table-x',
													props.errorSeparation && index % 2 === 0 ? 'bg-red-50' : 'bg-white',
													'elefta-bordered-table-x'
												)}
											>
												<div className="__checkbox pl-2">
													<input
														id={props.checkboxId ? `${props.checkboxId}-${index}` : `checkbox-thread-${index}`}
														type="checkbox"
														checked={state.checkedRows.includes(data.id)}
														onChange={this.handleCheckRow.bind(this, data)}
													/>
													<label htmlFor={props.checkboxId ? `${props.checkboxId}-${index}` : `checkbox-thread-${index}`}></label>
												</div>
											</td>
										)}


										{_columns.map((column, index) => {
											const stateColumn = head((column.key, columns.filter(c => c.key === column.key)))
											if (isEmpty(stateColumn)) return <React.Fragment key={column.key} />
											const colWidth = stateColumn.width && !props.disableFlow ? stateColumn.width : state.defaultWidth

											if (!column.enabled) return <React.Fragment key={index} />
											if (column.sensitive && this.props.profile.role == 'manager') return <React.Fragment key={index}></React.Fragment>
											if (state.importantColumns.includes(column.key) && column.sensitive && props.hideImportantDetails) {
												return (
													<td
														key={index}
														datakey={column.key}
														style={{
															position: column.freezed && !props.disableFlow ? 'sticky' : 'relative',
															left: leftStickyValue(column, colWidth),
															zIndex: column.freezed ? 2 : 1,
														}}
														className={Classnames('bg-inherit py-1 px-2 pr-3 text-sm font-medium truncate elefta-bordered-table-x', {
															'!bg-red-50': data?.status === 'pending_sale_to',
															'!bg-yellow-50': data?.status === 'on_hold_for',
															'!bg-green-50': data?.status === 'transit_from',
															'!bg-blue-50': data?.status === 'repair_with',
														})}
														onClick={this.handleTableRowClick.bind(this, data, column)}
													>
														<div className="break-words whitespace-pre-wrap max-h-20"
															style={{
																height: 80,
																display: 'flex',
																alignItems: 'center'
															}}>
															<span className="line-clamp-4">
																*******
															</span>
														</div>
													</td>
												)
											}

											let _dataProvideNode = null
											if (column.is_custom_column && data.custom_column_values && !isUndefined(data.custom_column_values[String(column.key)])) {
												_dataProvideNode = data.custom_column_values[column.key]
											} else if (!isUndefined(data[column.key])) {
												_dataProvideNode = data[column.key]
											}

											return (
												<td
													key={index}
													datakey={column.key}
													style={{
														position: column.freezed && !props.disableFlow ? 'sticky' : 'relative',
														left: leftStickyValue(column, colWidth),
														zIndex: column.freezed ? 2 : 1,
													}}
													className={Classnames('bg-inherit py-1 px-2 pr-3 text-sm font-medium truncate elefta-bordered-table-x', {
														'!bg-red-50': data?.status === 'pending_sale_to',
														'!bg-yellow-50': data?.status === 'on_hold_for',
														'!bg-green-50': data?.status === 'transit_from',
														'!bg-blue-50': data?.status === 'repair_with',
													})}
													onClick={
														//column.key === 'Status' ? this.showOrderDetail.bind(this, data, column) : this.handleTableRowClick.bind(this, data, column)}
														this.handleTableRowClick.bind(this, data, column)
													}
												>
													{
														(column.sensitive && this.props.isHiddenImportantDetails) ?
															<div className="break-words whitespace-pre-wrap max-h-20"
																style={{
																	height: 80,
																	display: 'flex',
																	alignItems: 'center'
																}}>
																<span className="line-clamp-4">
																	*******
																</span>
															</div>
														:
															column.render(_dataProvideNode, data, this.showOrderDetail.bind(this, data, column), this.props.profile)
													}
												</td>
											)
										})}
									</tr>
								)
							})}
						</tbody>

							</table>
						</div>
					</div>
				</InfiniteScroll>
			</div>


		)
	}
}

const mapStateToProps = (state, props) => {
	return {
		profile: state.profile,
		loggedin: state.loggedin,
	}
}

const mapActionsToProps = {}

export default connect(mapStateToProps, mapActionsToProps)(EleftaTable)
