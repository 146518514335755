import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { useForm, Controller } from 'react-hook-form'
import Box from '@mui/material/Box'
import RadioGroup from '@mui/material/RadioGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import Radio from '@mui/material/Radio'

import {Globals} from '../../../Context'

const General = (props) => {
    const { handleSubmit, control, watch, reset, setValue } = useForm({});

    return (
        <div className="w-full h-full pt-2 pb-10">
            {Globals.adminOrOwnerOrEditor(props.profile) &&
                <div className='mt-[35px] text-[16px] font-bold text-[#5D6E81]'>Inventory</div>
            }
            <div className="mt-[17px]">
                {props.profile.role === 'owner' &&
                    <div className='flex items-center'>
                        <div className="mx-6 text-[16px]">Do you allow other dealers to display the watches you’ve listed on Elefta’s marketplace on their own sites?</div>
                            <Controller
                                name="shopify_visible"
                                control={control}
                                defaultValue={false}
                                render={({ field }) => (
                                    <RadioGroup {...field} aria-labelledby="wholesale-price-visibility-group" row sx={{
                                        display: 'contents'
                                    }}>
                                        <FormControlLabel
                                            value={true}
                                            control={<Radio size="small" />}
                                            label={
                                                <Box component="div">
                                                    Yes
                                                </Box>
                                            }
                                        />
                                        <FormControlLabel
                                            value={false}
                                            control={<Radio size="small" />}
                                            label={
                                                <Box component="div">
                                                    No
                                                </Box>
                                            }
                                        />
                                    </RadioGroup>
                                )}
                            />

                    </div>
                }
                {
                    Globals.adminOrOwnerOrEditor(props.profile) &&
                    <div className='flex items-center'>
                        <div className="mx-6 text-[16px]">Do you want to quick edit from the inventory page?</div>
                            <Controller
                                name="quick_edit_visible"
                                control={control}
                                defaultValue={false}
                                render={({ field }) => (
                                    <RadioGroup {...field} aria-labelledby="wholesale-price-visibility-group" row>
                                        <FormControlLabel
                                            value={true}
                                            control={<Radio size="small" />}
                                            label={
                                                <Box component="div">
                                                    Yes
                                                </Box>
                                            }
                                        />
                                        <FormControlLabel
                                            value={false}
                                            control={<Radio size="small" />}
                                            label={
                                                <Box component="div">
                                                    No
                                                </Box>
                                            }
                                        />
                                    </RadioGroup>
                                )}
                            />
                    </div>
                }
            </div>
            {props.profile.role === 'owner' &&
                 <>
                    <div className='mt-[55px] text-[16px] font-bold text-[#5D6E81]'>Daily Report</div>
                    <div className="flex items-center mt-[17px]">
                        <div className="mx-6 text-[16px]">Would you like a daily activity report emailed to the owners of this space?</div>
                            <Controller
                                name="daily_visible"
                                control={control}
                                defaultValue={false}
                                render={({ field }) => (
                                    <RadioGroup {...field} aria-labelledby="wholesale-price-visibility-group" row>
                                        <FormControlLabel
                                            value={true}
                                            control={<Radio size="small" />}
                                            label={
                                                <Box component="div">
                                                    Yes
                                                </Box>
                                            }
                                        />
                                        <FormControlLabel
                                            value={false}
                                            control={<Radio size="small" />}
                                            label={
                                                <Box component="div">
                                                    No
                                                </Box>
                                            }
                                        />
                                    </RadioGroup>
                                )}
                            />
                    </div>
                 </>
            }
            {
                  Globals.adminOrOwnerOrEditor(props.profile) &&
                  <>
                    <div className='mt-[55px] text-[16px] font-bold text-[#5D6E81]'>Email</div>
                    <div className="flex items-center mt-[17px]">
                        <div className="mx-6 text-[16px]">Would you like emails for Memo/Invoices to: </div>
                            <Controller
                                name="email_visible"
                                control={control}
                                defaultValue={true}
                                render={({ field }) => (
                                    <RadioGroup {...field} aria-labelledby="wholesale-price-visibility-group" row>
                                        <FormControlLabel
                                            value={true}
                                            control={<Radio size="small" />}
                                            label={
                                                <Box component="div">
                                                    Open your default mail application
                                                </Box>
                                            }
                                        />
                                        <FormControlLabel
                                            value={false}
                                            control={<Radio size="small" />}
                                            label={
                                                <Box component="div">
                                                    Send automatically via Elefta
                                                </Box>
                                            }
                                        />
                                    </RadioGroup>
                                )}
                            />
                    </div>
                  </>

            }
            {
                <div className='flex flex-row justify-center mt-[63px]'>
                    <div className='bg-[#fff] border-[#2C79E0] border-[1px] w-[68px] h-[30px] rounded-[5px] flex items-center justify-center text-[#2C79E0] cursor-pointer'>
                        Apply
                    </div>
                </div>
            }
        </div>
    )
}

const mapStateToProps = (state, props) => {
	return {}
}

const mapActionsToProps = {
}

export default connect(mapStateToProps, mapActionsToProps)(General)
