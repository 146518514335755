import React, {useEffect, useState} from 'react'

const WatchBoxDropdown = ({product, handleUpdateItemProduct}) => {
	const [box, setBox] = useState(product?.box || false)

	const onChangeBox = (event) => {
		event.stopPropagation()
		setBox(!box)
		product.box = !box
		handleUpdateItemProduct(product)
	}

	return (
		<div onClick={(event) => onChangeBox(event)} className='flex flex-col justify-center items-center w-max p-1 border-[1px] rounded-[5px] w-[38px] !important h-[23px]'>
			<p class="py-2 text-[14px] font-normal tracking-worderer">{box ? "Yes" : "No"}</p>
		</div>
	)
}

export default WatchBoxDropdown
