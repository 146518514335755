import Classnames from 'classnames'
import Tooltip from '@mui/material/Tooltip'

import React from 'react'

import {Globals} from '../../Context'

class ImagePreview extends React.Component {
	constructor() {
		super()
		this.state = {
			loaded: false,
			items: [],
			activeItem: 0,
		}
	}

	componentDidMount() {
		window.addEventListener('keydown', this.handleKeyPress);

		try {
			this.setState({
				items: this.props.images,
			})
		} catch (error) {
			return this.props.addToast({
				text: 'Something went wrong please try again later.',
				type: 'error',
				duration: 100000,
			})
		}
	}

	componentWillUnmount() {
		window.removeEventListener('keydown', this.handleKeyPress);
	}

	handleKeyPress = (event) => {
        switch (event.key) {
            case Globals.keyboardButton.arrowRight:
                this.navigate('next');
                break;
            case Globals.keyboardButton.arrowLeft:
                this.navigate('previous');
                break;
            default:
                break;
        }
    };

	navigate = direction => {
		if (direction === 'next') {
			return this.setState({
				activeItem: Math.min(this.state.items.length - 1, this.state.activeItem + 1),
			})
		}

		return this.setState({
			activeItem: Math.max(0, this.state.activeItem - 1),
		})
	}

	downloadImage = async imgUrl => {
		const imageUrl = imgUrl // Replace with your image URL
		const response = await fetch(imageUrl)
		const blob = await response.blob()

		const link = document.createElement('a')
		link.href = URL.createObjectURL(blob)
		link.download = 'downloaded_image.jpg' // Change this to the desired file name

		document.body.appendChild(link)
		link.click()
		if (link && link.parentNode) {
			document.body.removeChild(link)
		}
	}

	render() {
		const state = this.state

		return (
			<section>
				<React.Fragment>
					<div className="w-full max-w-screen h-screen max-h-screen">
						<div className="max-w-7xl w-full h-full m-auto p-5">
							{state.items.map((item, index) => {
								return (
									<React.Fragment key={index}>
										{index === state.activeItem && (
											<>
												<div className="upload-media-lightbox-inner w-full h-full top-0 right-0 left-0 bottom-0 z-50 p-11" key={index}>
													<div className="upload-media-lightbox-image  w-full h-full flex justify-center items-center p-4">
														<div className="relative h-full content-center">
															<Tooltip title="Close preview" arrow>
															<button onClick={e => this.props.setPreviewVisible(null)} className="bg-black rounded-full p-2 absolute top-4 right-4 z-10">
																<svg fill="white" width="18" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
																	<path d="M14.8672 0.194897C14.625 0.200615 14.3949 0.301995 14.2271 0.47688L8.00026 6.70377L1.77337 0.47688C1.68784 0.38896 1.58554 0.319098 1.47252 0.271431C1.35951 0.223764 1.23807 0.199261 1.11541 0.199373C0.933136 0.199599 0.755062 0.254158 0.60394 0.35608C0.452819 0.458003 0.335512 0.602661 0.267004 0.771577C0.198497 0.940492 0.181898 1.126 0.21933 1.30439C0.256762 1.48278 0.346524 1.64597 0.47715 1.7731L6.70404 7.99999L0.47715 14.2269C0.389174 14.3113 0.318936 14.4125 0.27055 14.5245C0.222163 14.6364 0.196601 14.7569 0.19536 14.8789C0.194119 15.0008 0.217224 15.1218 0.263323 15.2347C0.309421 15.3476 0.377586 15.4502 0.463825 15.5364C0.550064 15.6227 0.652643 15.6908 0.765556 15.7369C0.878469 15.783 0.999445 15.8061 1.1214 15.8049C1.24335 15.8037 1.36383 15.7781 1.47578 15.7297C1.58774 15.6813 1.68891 15.6111 1.77337 15.5231L8.00026 9.29622L14.2271 15.5231C14.3116 15.6111 14.4128 15.6813 14.5247 15.7297C14.6367 15.7781 14.7572 15.8037 14.8791 15.8049C15.0011 15.8061 15.1221 15.783 15.235 15.7369C15.3479 15.6908 15.4505 15.6227 15.5367 15.5364C15.6229 15.4502 15.6911 15.3476 15.7372 15.2347C15.7833 15.1218 15.8064 15.0008 15.8052 14.8789C15.8039 14.7569 15.7784 14.6364 15.73 14.5245C15.6816 14.4125 15.6113 14.3113 15.5234 14.2269L9.29648 7.99999L15.5234 1.7731C15.6568 1.6452 15.7484 1.47997 15.7862 1.29908C15.824 1.11819 15.8063 0.93009 15.7354 0.759448C15.6644 0.588807 15.5436 0.443591 15.3886 0.342846C15.2337 0.2421 15.052 0.19053 14.8672 0.194897Z" />
																</svg>
															</button>
															</Tooltip>
															{item.type.includes('video') ? (
																<video controls className="max-h-full max-w-full">
																	<source src={item.video_url} type="video/mp4" />
																</video>
															) : (
																<img key={item} src={item.url} alt="." className="h-full w-full object-contain" />
															)}
													</div>
												</div>
													{/*
													<button
														className="bg-blue-600 capitalize font-medium hover:opacity-90 inline-flex items-center px-4 py-1.5 rounded text-center text-sm text-white absolute z-40 left-72"
														onClick={() => {
															this.downloadImage(`${Globals.wsurl}/media/assets/pictures/${item}`)
														}}
													>
														Download Image
													</button>
										*/}

												</div>
											</>
										)}
									</React.Fragment>
								)
							})}
						</div>
						{state.items.length > 1 && (
							<React.Fragment>
								<div className="absolute top-0 left-0 flex w-full h-full justify-between items-center p-5 z-5">
									<button className="bg-blue-500 hover:bg-blue-600 opacity-80 p-2 rounded-lg text-white transition" onClick={this.navigate.bind(this, 'prev')}>
										<svg className="w-6 h-6" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
											<path
												fillRule="evenodd"
												d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
												clipRule="evenodd"
											/>
										</svg>
									</button>
									<button className="bg-blue-500 hover:bg-blue-600 opacity-80 p-2 rounded-lg text-white transition" onClick={this.navigate.bind(this, 'next')}>
										<svg className="w-6 h-6" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
											<path
												fillRule="evenodd"
												d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
												clipRule="evenodd"
											/>
										</svg>
									</button>
								</div>

								<div className="absolute left-0 bottom-0 flex gap-3 w-full justify-center items-center p-5 z-10">
									{state.items.map((item, index) => (
										<button
											className={Classnames('bg-blue-500 hover:bg-blue-600 opacity-80 transition-all duration-500 p-2 rounded-full', {
												'px-4': index === state.activeItem,
											})}
											key={index}
										></button>
									))}
								</div>
							</React.Fragment>
						)}
					</div>
				</React.Fragment>
			</section>
		)
	}
}
export default ImagePreview
