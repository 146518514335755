import {Chart as ChartJS, CategoryScale, LinearScale, BarElement, Tooltip, Legend} from 'chart.js'
import {Bar} from 'react-chartjs-2'

ChartJS.register(CategoryScale, LinearScale, BarElement, Tooltip, Legend)

export const options = {
	responsive: true,
	plugins: {
		legend: {
		  position: 'top',
		},
	},
	scales: {
		x: {
			stacked: true,
			display: true,
			grid: {
				display: false,
			},
		},
		y: {
			stacked: true,
			display: true,
		},
		yAxis: {
			display: false,
		},
	},
}


const BarChart2 = ({totalCost, totalSale, time}) => {
	const labels = time
	const data = {
		labels,
		datasets: [
			{
				label: 'Total Sale',
				minBarLength: 2,
				maxBarThickness: 25,
				data: totalSale,
				backgroundColor: '#855CF8',
			},
			{
				label: 'Total Cost',
				maxBarThickness: 25,
				minBarLength: 2,
				data: totalCost,
				backgroundColor: 'rgba(44, 121, 224, 0.3)',
			},
		],
	}
	return (
		<div className="relative">
			<Bar data={data} options={options} width={'250%'} />
		</div>
	)
}

export default BarChart2
