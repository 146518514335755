import React from 'react'
import Autosuggest from 'react-autosuggest'
import uniqueId from 'lodash/uniqueId'
import debounce from 'lodash/debounce'
import classNames from 'classnames'

import './autosuggestaddress.css'
import {Globals} from '../Context'
import {isEmpty, isFunction} from 'lodash'

class ServerAutoSuggestAddress extends React.Component {
	constructor(props) {
		super()

		this.state = {
			value: '',
			suggestions: [],
			autosuggestId: undefined,
		}

		// Debounce the getSuggestions function
		this.debouncedGetSuggestions = debounce(this.fetchSuggestions, 300)
	}

	componentDidMount() {
		this.setState({
			autosuggestId: uniqueId('server-auto-suggest-'),
		})
		if(this.props.formData?.full_address) {
			this.setState({
				value: this.props.formData?.full_address,
			})
		}
	}

	static getDerivedStateFromProps(nextProps, prevState) {
		if (String(nextProps.value) !== String(prevState.value)) {
			return {value: !!nextProps.value ? String(nextProps.value) : prevState.value}
		}
		return null
	}

	// Fetch suggestions asynchronously
	fetchSuggestions = async value => {
		const inputValue = value.trim().toLowerCase()
		if (!inputValue) {
		  this.setState({ suggestions: [] });
		  return;
		}
	  
		try {
			const response = await Globals.New_Axios().get(
				`${Globals.NEW_API_URL}/${Globals.ENDPOINTVERSION}/places?keyword=${inputValue}`,
				{
					headers: {
						Authorization: Globals.authKey
					}
				}
			)

			if (response.status !== 200) {
				throw new Error("Error fetching suggestions");
			}

			const suggestions = isEmpty(response?.data?.predictions) ? [] : response.data.predictions;
		
			this.setState({ suggestions });
			} catch (error) {
			console.error("Error fetching suggestions:", error);
			this.setState({ suggestions: [] });
		}
	};

	// Trigger suggestions
	getSuggestionValue = suggestion => {
		this.props.handleChangeGoogleAddress(suggestion.place_detail)
		return suggestion.place_detail.formatted_address
	}

	renderSuggestion = suggestion => (
		<div className={classNames("relative w-full border-b-[1px] hover:bg-[#ccc] cursor-pointer", {
			'p-2': this.props.view !== 'additional_customer',
			'p-1': this.props.view === 'additional_customer',
		})}>
			<span className="text-[14px]">{suggestion.formatted_address}</span>
		</div>
	)

	// OnChange event handler
	onChange = (event, {newValue}) => {
		this.setState({
			value: newValue,
		})

		if (isFunction(this.props.setValue)) {
			this.props.setValue(newValue)
		}
	}

	// Suggestion rerender when user types
	onSuggestionsFetchRequested = ({value}) => {
		this.debouncedGetSuggestions(value)
	}

	// Triggered on clear
	onSuggestionsClearRequested = () => {
		this.setState({
			suggestions: [],
		})
	}

	renderInputComponent = inputProps => {
		if(this.props.view === 'register') {
			return (
				<div className="mt-2 2xl:block lg:block">
					<label htmlFor="company address" className="hidden text-base bg-transparent font-normal py-2"></label>
					<div className="outlin relative w-full">
						<input placeholder=" " {...inputProps} />
						<label htmlFor="company address" className="pointer-events-none absolute top-1  text-sm bg-transparent p-4 duration-300 origin-0 opacity-60">
							Company Address
						</label>
					</div>
				</div>
			)
		} else if (this.props.view === 'customer') {
			return (
				<div className="outlin relative w-full">
					<input placeholder=" " {...inputProps} />
					<label className="pointer-events-none absolute top-0 bg-transparent p-[12px] duration-300 origin-0 font-semibold text-[#D1D3D4]">Address</label>
				</div>
			)

		} else if(this.props.view === 'additional_customer') {
			return (
				<div className="outlin relative w-full h-[49px]">
					<input placeholder=" " {...inputProps} />
					<label htmlFor="address" className="pointer-events-none absolute top-0 bg-transparent p-[12px] duration-300 origin-0 font-semibold text-[#D1D3D4]">
						Address
					</label>
				</div>
			)
		} else if(this.props.view === 'memo') {
			return (
				<input placeholder="Address" {...inputProps} />
			)
		}
	}

	render() {
		const {value, suggestions, autosuggestId} = this.state
		const inputProps = {
			value,
			onChange: this.onChange,
			className: !!this.props.className ?  this.props.className : 'custom-input',
		}

		return (
			<div className='auto-success-wrapper relative'>
				<Autosuggest
					id={autosuggestId}
					containerProps={{ className: "" }} /* Customize height here */
					suggestions={suggestions}
					onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
					onSuggestionsClearRequested={this.onSuggestionsClearRequested}
					getSuggestionValue={this.getSuggestionValue}
					renderSuggestion={this.renderSuggestion}
					inputProps={inputProps}
					renderInputComponent={this.renderInputComponent}
					 theme={{
                        suggestionsList: {
							position: 'absolute',
							top: this.props.view === 'memo' ?' 30px' : '50px',
							'background-color': '#ffff',
							display: 'inline-block',
							width: '100%',
							'z-index': '100',
							right: '0px',
							'overflow-x': 'hidden',
							border: '1px solid #ccc',
						},
                    }}
				/>
			</div>
		)
	}
}

export default ServerAutoSuggestAddress
