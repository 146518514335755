import React, {useEffect, useState, useMemo} from 'react'
import {connect} from 'react-redux'
import { useLocation } from 'react-router-dom';
import qs from 'qs';

import TopTitlePage from '../TopTitlePage'
import Contactinfo from './Contactinfo'
import Settings from './settings/index'
import ManageTeam from './ManageTeam'
import ImportAndExport from './ImportAndExport'
import Integrations from './Integrations'
import Support from './Support'
import WebStoreSetting from './WebStoreSetting/index'
import {Globals} from '../../Context'

function useQuery() {
	const { search } = useLocation();
	return useMemo(() => qs.parse(search, { ignoreQueryPrefix: true }), [search]);
}

const TopElements = (props) => {
	const query = useQuery();

	const [ContactInfo, setContactInfo] = useState(true)
	const [manageTeam, setManageTeam] = useState(false)
	const [settings, setSettings] = useState(false)
	const [isImportAndExport, setIsImportAndExport] = useState(false)
	const [isIntegrations, setIsIntegrations] = useState(false)
	const [isSupport, setIsSupport] = useState(false)
	const [isWebstore, setIsWebstore] = useState(false)

	const openContact = () => {
		setContactInfo(true)
		setSettings(false)
		setIsImportAndExport(false)
		setIsIntegrations(false)
		setManageTeam(false)
		setIsSupport(false)
		setIsWebstore(false)
	}
	const openManageTeam = () => {
		setContactInfo(false)
		setManageTeam(true)
		setSettings(false)
		setIsImportAndExport(false)
		setIsIntegrations(false)
		setIsSupport(false)
		setIsWebstore(false)
	}
	const openImportAndExport = () => {
		setContactInfo(false)
		setSettings(false)
		setManageTeam(false)
		setIsImportAndExport(true)
		setIsIntegrations(false)
		setIsSupport(false)
		setIsWebstore(false)
	}
	const openSetting = () => {
		setContactInfo(false)
		setSettings(true)
		setManageTeam(false)
		setIsImportAndExport(false)
		setIsIntegrations(false)
		setIsSupport(false)
		setIsWebstore(false)
	}
	const openIntegrations = () => {
		setContactInfo(false)
		setSettings(false)
		setManageTeam(false)
		setIsImportAndExport(false)
		setIsIntegrations(true)
		setIsSupport(false)
		setIsWebstore(false)
	}
	const openSupport = () => {
		setContactInfo(false)
		setSettings(false)
		setManageTeam(false)
		setIsImportAndExport(false)
		setIsIntegrations(false)
		setIsSupport(true)
		setIsWebstore(false)
	}
	const openWebstore = () => {
		setContactInfo(false)
		setSettings(false)
		setManageTeam(false)
		setIsImportAndExport(false)
		setIsIntegrations(false)
		setIsWebstore(true)
		setIsSupport(false)
	}

	useEffect(() => {
		const { tab } = query;

		if (tab === 'integrations') {
			openIntegrations();
		}
	}, [query])

	return (
		<div className="flex flex-col lg:items-center items-start relative Whitecontainer lg:bg-white px-[44px]">
			<div className="flex relative w-full">
				<TopTitlePage title="Account" />
			</div>
			<div className="flex border-b-2 w-full">
				<button
					className="cursor-pointer flex flex-row justify-center items-center border-b-2 mr-3 sm:mr-10"
					style={{
						fontWeight: ContactInfo ? '700' : '500',
						borderColor: ContactInfo ? '#2C79E0' : 'transparent',
					}}
					onClick={openContact}
				>
					<span className="lg:text-sm text-xxs w-full">Contact information</span>
				</button>
				<button
					className="cursor-pointer flex flex-row justify-center items-center border-b-2 mx-3 sm:mx-10 py-3"
					style={{
						fontWeight: manageTeam ? '700' : '500',
						borderColor: manageTeam ? '#2C79E0' : 'transparent',
					}}
					onClick={openManageTeam}
				>
					<span className="lg:text-sm text-xxs w-full">Manage Team</span>
				</button>
				<button
					className="cursor-pointer flex flex-row justify-center items-center border-b-2 mx-3 sm:mx-10 py-3"
					style={{
						fontWeight: settings ? '700' : '500',
						borderColor: settings ? '#2C79E0' : 'transparent',
					}}
					onClick={openSetting}
				>
					<span className="lg:text-sm text-xxs w-full">Settings</span>
				</button>
				{
					Globals.adminOrOwner(props.profile) && (
						<button
							className="cursor-pointer flex flex-row justify-center items-center border-b-2 mx-3 sm:mx-10 py-3"
							style={{
								fontWeight: isWebstore ? '700' : '500',
								borderColor: isWebstore ? '#2C79E0' : 'transparent',
							}}
							onClick={openWebstore}
						>
							<span className="lg:text-sm text-xxs w-full">Web Store</span>
						</button>
					)
				}
				<button
					className="cursor-pointer flex flex-row justify-center items-center border-b-2 mx-3 sm:mx-10 py-3"
					style={{
						fontWeight: isImportAndExport ? '700' : '500',
						borderColor: isImportAndExport ? '#2C79E0' : 'transparent',
					}}
					onClick={openImportAndExport}
				>
					<span className="lg:text-sm text-xxs w-full">Import/Export</span>
				</button>
				<button
					className="cursor-pointer flex flex-row justify-center items-center border-b-2 mx-3 sm:mx-10 py-3"
					style={{
						fontWeight: isIntegrations ? '700' : '500',
						borderColor: isIntegrations ? '#2C79E0' : 'transparent',
					}}
					onClick={openIntegrations}
				>
					<span className="lg:text-sm text-xxs w-full">Integrations</span>
				</button>
				<button
					className="cursor-pointer flex flex-row justify-center items-center border-b-2 mx-3 sm:mx-10 py-3"
					style={{
						fontWeight: isSupport ? '700' : '500',
						borderColor: isSupport ? '#2C79E0' : 'transparent',
					}}
					onClick={openSupport}
				>
					<span className="lg:text-sm text-xxs w-full">Support</span>
				</button>
			</div>
			{ContactInfo && <Contactinfo />}
			{manageTeam && <ManageTeam />}
			{settings && <Settings />}
			{isWebstore && <WebStoreSetting />}
			{isImportAndExport && <ImportAndExport />}
			{isIntegrations && <Integrations />}
			{isSupport && <Support />}
		</div>
	)
}

const mapStateToProps = (state, props) => {
	return {
		profile: state.profile,
	}
}

const mapActionsToProps = {}

export default connect(mapStateToProps, mapActionsToProps)(TopElements)

