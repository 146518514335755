import React, { useEffect } from 'react'
import Privacy from '../Privacy'
import { useForm, Controller } from 'react-hook-form'
import { useLocation, useNavigate } from 'react-router-dom'

import {Globals} from '../../../Context'
import TextField from '@mui/material/TextField'

const Shopify = (props) => {
	const { currentSpace, query, addToast } = props

	const location = useLocation()
	const navigate = useNavigate()

    const { handleSubmit, control, reset } = useForm({});

	const integrateShopify = async (formData) => {
		Globals.New_Axios()
			.post(`${Globals.NEW_API_URL}/${Globals.ENDPOINTVERSION}/spaces/${Globals.SELECTEDSPACEID}/shopify_auth/login`, {
				store_domain: formData.store_domain
			})
			.then(response => {
				const {data} = response
				window.location = data.auth_route
			})
			.catch(error => {
				console.log(error)
			})
	}

	useEffect(() => {
		reset({
			store_domain: currentSpace.shopify_integration?.store_domain
		});
	}, [currentSpace])

	useEffect(() => {
		const { success } = query
		if (success === 'true') {
			addToast({
				text: 'Shopify integration successful',
				type: 'success',
			})
		} else if (success === 'false') {
			addToast({
				text: 'Shopify integration failed',
				type: 'error',
			})
		}

		removeAllQueryParams()
	}, [query])

	const removeAllQueryParams = () => {
		const { pathname } = location
		navigate(pathname)
	}

	return (
		<div className="relative pb-24 w-full">
			<div className="flex mt-16 justify-center">
				<form onSubmit={handleSubmit(integrateShopify)}>
					<div className='w-[400px] mb-2'>
						<Controller
							name="store_domain"
							control={control}
							defaultValue={''}
							render={({ field }) => {
								return(
									<TextField
										{...field}
										sx={{ width: 100}}
										label="Store Domain"
										variant="outlined"
										size="small"
										required
                                        fullWidth
										className='!w-full'
										onChange={(event) => {
											field.onChange(event.target.value)
										}}
									/>
								)
							}}
						/>
						<span className='text-xs'>
							Example: myshop.myshopify.com
						</span>
					</div>
					<div className='text-center'>
						<button type="submit" className='text-white bg-blue-600 hover:opacity-90 font-medium rounded text-sm px-4 py-1.5 pr-3 text-center inline-flex items-center capitalize'>
							Integrate Shopify
						</button>
					</div>
				</form>
			</div>

			<div className='absolute bottom-0 w-full'>
				<Privacy />
			</div>
		</div>
	)
}

export default Shopify
