import {Chart, ArcElement} from 'chart.js'

import TitleCard from './TitleCard'
import DonutChart from '../../Components/Main/DonutChart'
import isEmpty from 'lodash/isEmpty'

Chart.register(ArcElement)

const MarketPlaceStat = ({dataList}) => {
	const data = {
		labels: ['', ''],
		datasets: [
			{
				data: [dataList?.market_place_statistics?.total_invisible, dataList?.market_place_statistics?.total_visible],
				backgroundColor: ['#E7E7E7', '#5D6E81'],
				borderWidth: 1,
				cutout: '80%',
			},
		],
	}
	const plugins = [{
		beforeDraw: function (chart) {
			var width = chart.width,
				height = chart.height,
				ctx = chart.ctx
			ctx.restore()
			ctx.font = ctx.font = '700 250% Arial'
			ctx.textBaseline = 'top'
			ctx.fillStyle = '#5D6E81'
			var text = `${dataList?.market_place_statistics.percent_uploaded}%`,
				textX = Math.round((width - ctx.measureText(text).width) / 2),
				textY = height / 3
			ctx.fillText(text, textX, textY)
			ctx.save()
		},
	}, {
		beforeDraw: function (chart) {
			var width = chart.width,
				height = chart.height,
				ctx = chart.ctx
			ctx.restore()
			ctx.font = ctx.font = '700 90% Arial'
			ctx.textBaseline = 'top'
			ctx.fillStyle = '#5D6E81'
			var text = 'OF INVENTORY',
				textX = Math.round((width - ctx.measureText(text).width) / 2),
				textY = height / 1.9
			ctx.fillText(text, textX, textY)
			ctx.save()
		},
	},{
		beforeDraw: function (chart) {
			var width = chart.width,
				height = chart.height,
				ctx = chart.ctx
			ctx.restore()
			ctx.font = ctx.font = '700 90% Arial '
			ctx.textBaseline = 'top'
			ctx.fillStyle = '#5D6E81'
			var text = 'UPLOADED',
				textX = Math.round((width - ctx.measureText(text).width) / 2),
				textY = height / 1.6
			ctx.fillText(text, textX, textY)
			ctx.save()
		},
	}]

	return (
		<div className="relative grid md:grid-cols-3 grid-cols-1 justify-items-center bg-white py-5 px-4 rounded-xl drop-shadow-md lg:mr-5">
			{isEmpty(dataList) &&
				<div className="z-10 absolute top-0 left-0 right-0 bottom-0 bg-gray-100 opacity-90 flex items-center justify-center">
					<div className="absolute top-0 left-0 right-0 bottom-0 flex justify-center items-center z-50">
						<button
							type="button"
							className="inline-flex items-center px-4 py-2 leading-6 text-sm shadow rounded-md text-white bg-blue transition ease-in-out duration-150 cursor-not-allowed"
							disabled
						>
							<svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
								{' '}
								<circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4" />
								<path
									className="opacity-75"
									fill="currentColor"
									d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
								/>
							</svg>
							<span>Loading... </span>
						</button>
					</div>
				</div>
			}
			<div className="flex flex-col md:col-span-2 w-full mr-10 px-4">
				<div className="w-full mb-5 pb-5 border-b">
					<TitleCard title={'Marketplace Statistics'} />
				</div>
				<div className="flex flex-col w-full text-lg justify-center px-4">
					<div className="relative flex py-2">
						<h1 className="font-medium" style={{fontSize: '10px'}}>
							Watches In Marketplace
						</h1>
						<h1 className="font-medium absolute right-0 mx-2">{dataList?.market_place_statistics?.total_visible}</h1>
					</div>

					<div className="relative flex py-2" style={{color: '#F5A900'}}>
						<h1 className="font-medium" style={{fontSize: '10px'}}>
							Watches Not In Marketplace
						</h1>
						<h1 className="font-medium absolute right-0 mx-2">{dataList?.market_place_statistics?.total_invisible}</h1>
					</div>

					<div className="relative flex py-2">
						<h1 className="font-medium " style={{fontSize: '10px'}}>
							Unread messages (selling)
						</h1>
						<h1 className="font-medium absolute right-0 mx-2">{dataList?.market_place_statistics?.unread_sell}</h1>
					</div>

					<div className="relative flex py-2">
						<h1 className="font-medium " style={{fontSize: '10px'}}>
							Unread messages (buying)
						</h1>
						<h1 className="font-medium absolute right-0 mx-2">{dataList?.market_place_statistics?.unread_buy}</h1>
					</div>
				</div>
			</div>
			<div className=" mt-2 md:w-56 relative -left-5 flex justify-center items-center">
			{dataList?.market_place_statistics?.percent_uploaded >= 0 &&
				<DonutChart data={data} plugins={plugins} />}
			</div>
		</div>
	)
}

export default MarketPlaceStat
