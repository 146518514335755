import {useState, useEffect} from 'react'
import moment from 'moment'

import {Globals} from '../../Context'
import DatePickerFlat from '../Inventory/DatePickerFlat'
import { focusElement } from '../../utils/keyboardHandler'
import {onPressEscape} from '../../utils/keyboardHandler'
import sumBy from 'lodash/sumBy'

const PartialPayment = ({hidePartialPayment, props, singleOrderItem, loadSingleOrder, currentSpace, handleOpenPerformanceLimitAccessModal, handleStatusUpdate ,singlePartialPayment, isAddPartialPayment =  true, shippingPrice}) => {
	const currentDate = () => {
		const defaultDate = new Date()
		const day = String(defaultDate.getDate()).padStart(2, '0')
		const month = String(defaultDate.getMonth() + 1).padStart(2, '0')
		const year = defaultDate.getFullYear()
		return `${month}-${day}-${year}`
	}
	const [partialPayment, setPartialPayment] = useState({payment: singlePartialPayment?.amount ? Globals.formatCurrency(singlePartialPayment?.amount) :  0, paymentDate: singlePartialPayment?.date ? moment(singlePartialPayment?.date).format('MM-DD-YYYY') : moment().format("MM-DD-YYYY"), balance: 0})
	const [partialPaymentValue, setPartialPaymentValue] = useState(Globals.formatCurrency(partialPayment?.payment))
	const handlechangePicker = (date, name) => {
		setPartialPayment({...partialPayment, paymentDate: moment(date).format("MM-DD-YYYY")})
	}
	//submit payment
	const handleSubmitPayment = () => {
		if(isAddPartialPayment) {
			if ((!!partialPayment?.payment ? partialPayment?.payment : 0) > singleOrderItem.balance || partialPayment?.payment === 0) {
				return false
			}
			return Globals.New_Axios()
				.post(`${Globals.NEW_API_URL}/${Globals.ENDPOINTVERSION}/spaces/${Globals.SELECTEDSPACEID}/sales/${singleOrderItem.id}/payments`, {
					payment: {
						amount: partialPayment.payment,
						date: moment(partialPayment.paymentDate, 'MM-DD-YYYY').format("DD-MM-YYYY")
					},
				})
				.then(response => {
					if (response.status && response.status >= 200 && response.status < 300) {
						if (parseInt(partialPayment?.payment) === parseInt(singleOrderItem.balance)) {
							handleStatusUpdate('payment_received')
						}

						loadSingleOrder(singleOrderItem.id)
						props.addToast({
							text: 'Payment Added Successfully',
							type: 'success',
						})
						closePartialPayment()
					}
				})
				.catch(error => {_
					props.addToast({
						text: 'Something went wrong please try again later.',
						type: 'error',
					})
				})
		} else {
			if (Number((caculateNewBalance().replaceAll(',', '')).replace('.00', '').replace('$', '')) < 0) {
				return false
			}
			const patmentsExceptCurrentPayment = singleOrderItem.payments.filter((p) => p.id !== singlePartialPayment.id)
			const totalPaymentsExceptCurrentPayment = sumBy(patmentsExceptCurrentPayment, (p) => p.amount)
			return Globals.New_Axios()
				.patch(`${Globals.NEW_API_URL}/${Globals.ENDPOINTVERSION}/spaces/${Globals.SELECTEDSPACEID}/sales/${singleOrderItem.id}/payments/${singlePartialPayment.id}`, {
					payment: {amount: caculatePayment(), date: moment(partialPayment.paymentDate).format("DD-MM-YYYY")},
				})
				.then(response => {
					if (parseInt(partialPayment?.payment) === parseInt(singleOrderItem.total - totalPaymentsExceptCurrentPayment)) {
						handleStatusUpdate('payment_received')
					}

					loadSingleOrder(singleOrderItem.id)
					props.addToast({
						text: 'Payment Edit Successfully',
						type: 'success',
					})
					closePartialPayment()
				})
				.catch(error => {_
					props.addToast({
						text: 'Something went wrong please try again later.',
						type: 'error',
					})
				})
		}
	}

	const caculateNewBalance = () => {
		let currentPayment = !!partialPayment?.payment ? partialPayment?.payment : 0
		if(typeof partialPayment?.payment === 'string') {
			currentPayment = !!partialPayment?.payment ? partialPayment?.payment.replaceAll(',', '') : 0
		}

		if(!!singlePartialPayment) {
			let newShippingPrice = singleOrderItem.shipping_price
			if(singleOrderItem.shipping_price !== Number(shippingPrice) && !!shippingPrice) {
				newShippingPrice = Number(shippingPrice)
			}
			const patmentsExceptCurrentPayment = singleOrderItem.payments.filter((p) => p.id !== singlePartialPayment.id)
			const totalPaymentsExceptCurrentPayment = sumBy(patmentsExceptCurrentPayment, (p) => p.amount)
			return Globals.moneyFormatter.format((singleOrderItem.sub_total + newShippingPrice) - (totalPaymentsExceptCurrentPayment + Number(currentPayment)))
		} else {
			return 	Globals.moneyFormatter.format(singleOrderItem.balance - Number(currentPayment))
		}
	}

	const caculatePayment = () => {
		let currentPayment = !!partialPayment?.payment ? partialPayment?.payment : 0
		if(typeof partialPayment?.payment === 'string') {
			currentPayment = !!partialPayment?.payment ? partialPayment?.payment.replaceAll(',', '') : 0
		}
		return Number(currentPayment)
	}

	const closePartialPayment = () => {
		hidePartialPayment()
		setPartialPayment({payment: 0, paymentDate: currentDate(), balance: 0})
	}

	const showErorAmount = () => {
		if(isAddPartialPayment) {
			return (!!(partialPayment?.payment) ? partialPayment?.payment : 0) > singleOrderItem.balance && 'Payment Cannot Exceed Balance'
		} else {
			return Number((caculateNewBalance().replaceAll(',', '')).replace('.00', '').replace('$', '')) < 0 && 'Payment Cannot Exceed Balance'

		}
	}

	useEffect(() => {
		focusElement('.partial-payment-view')
	}, [])

	useEffect(() => {
		if(partialPayment?.payment) {
			let formattedValue = Globals.formatCurrency(partialPayment?.payment)
			if (formattedValue.includes('.') && formattedValue.endsWith('0')) {
				formattedValue = formattedValue.slice(0, -1)
			}

			setPartialPaymentValue(formattedValue)
		} else {
			setPartialPaymentValue('')
		}
	}, [partialPayment?.payment])

	return (
		<>
			<div
				onKeyDown={onPressEscape((event) => closePartialPayment())}
				className="hidden partial-payment-view bg-black/50 bottom-0 fixed flex items-center justify-center left-0 right-0 top-0 z-50 p-3 md:flex" tabIndex="-1"
			>
				<div className="max-w-[476px] w-full max-h-[374px]">
					<div className="bg-[#5D6E81] flex items-center px-8 py-4 relative rounded-t-[12px] text-white w-full max-h-[49px]">
						<span className="text-white font-bold text-[17px]">Partial Payment</span>
						<button className="absolute right-0 mr-5 cursor-pointer" onClick={closePartialPayment}>
							<svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28" fill="none">
							<path d="M11.4874 14.1231L6.06906 8.51228L8.47885 6.18518L13.8972 11.796L19.8677 6.03036L22.1948 8.44015L16.2243 14.2058L21.6774 19.8526L19.2676 22.1797L13.8145 16.5329L7.80802 22.3333L5.48091 19.9235L11.4874 14.1231Z" fill="white"/>
							</svg>
						</button>
					</div>
					<div className="bg-white text-gray-500 px-8 py-4 w-full">
						<div>
							<div className='flex justify-between'>
								<div className="mb-2">
									<input
										value={partialPaymentValue}
										onChange={e => {
											let currentValue = e.target.value

											setPartialPayment({...partialPayment, payment: parseFloat(currentValue.replaceAll(',', ''))})
										}}
										onKeyDown={(e) => {
											if(e.key === '.') {
												setPartialPaymentValue(partialPaymentValue + '.')
											}
										}}
										type="text"
										name="amount"
										placeholder="Amount"
										className="block py-3 p-4 w-full border rounded-[5px] text-sm max-w-[165px] max-h-[34px]"
									/>
									<span className="text-xs text-[#E13737] font-bold">
										{showErorAmount()}
									</span>
								</div>
								<div className='border-2 flex items-center max-w-[150px] max-h-[34px] rounded-[5px]'>
									<DatePickerFlat name="SoldDate" title="Sold Date" handlechangePicker={handlechangePicker} defaultValue={partialPayment.paymentDate} />
								</div>
							</div>
							<div className="flex  flex-col justify-between font-normal mx-12">
								<div className='flex justify-between w-full'>
									<div>Balance</div>
									<div>{Globals.moneyFormatter.format(singleOrderItem.balance).replace('$', '').replace('.00', '')}</div>
								</div>
								<div className='flex justify-between w-full mb-2'>
									<div>Payment</div>
									<div>{Globals.moneyFormatter.format(caculatePayment()).replace('$', '').replace('.00', '')}</div>
								</div>
								<div className='border-b border-[2px] border-[#5D6E81]'></div>
								<div className='flex justify-between w-full mt-2'>
									<div>New Balance</div>
									<div>{caculateNewBalance().replace('$', '').replace('.00', '')}</div>
								</div>
							</div>
							<div className="flex flex-row mt-4 justify-center space-x-4">
								<button
									className="border-2 cursor-pointer font-bold leading-5 my-2 px-8 py-2 rounded-md text[#000000] text-xs"
									onClick={closePartialPayment}
								>
									Cancel
								</button>
								<button className="bg-blue cursor-pointer font-bold leading-5 my-2 px-8 py-2 rounded-md text-white text-xs uppercase" onClick={() => handleSubmitPayment()} type="submit">
									Save
								</button>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className="bg-black/50 bottom-0 fixed flex items-center justify-center left-0 right-0 top-0 z-50 md:hidden">
				<div className="max-w-xl w-full">
					<div className="bg-[#4B7CBE] flex items-center px-8 py-4 relative rounded-t-xl text-white w-full max-h-[43px]">
						<span className="text-white font-bold text-[22px]">Partial Payment</span>
						<button className="absolute right-0 mr-5 cursor-pointer" onClick={closePartialPayment}>
							<svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28" fill="none">
							<path d="M11.4874 14.1231L6.06906 8.51228L8.47885 6.18518L13.8972 11.796L19.8677 6.03036L22.1948 8.44015L16.2243 14.2058L21.6774 19.8526L19.2676 22.1797L13.8145 16.5329L7.80802 22.3333L5.48091 19.9235L11.4874 14.1231Z" fill="white"/>
							</svg>
						</button>
					</div>
					<div className="bg-white text-gray-500 px-8 py-4 w-full">
						<div>
							<div className="grid grid-cols-2 gap-3 mt-5">
								<div className="mb-2">
									<input
										value={partialPayment?.payment ? Globals.formatCurrency(partialPayment?.payment) : ''}
										onChange={e => {
											if (parseFloat(e.target.value.replaceAll(',', '')) != partialPayment) {
												setPartialPayment({...partialPayment, payment: parseFloat(e.target.value.replaceAll(',', ''))})
											}
										}}
										type="text"
										name="amount"
										placeholder="Amount"
										className="block py-3 p-4 w-full border rounded-[5px] text-sm max-w-[163px] max-h-[34px]"
									/>
									<span className="text-xs text-red-800">
										{showErorAmount()}
									</span>
								</div>
								<div className='border-2 flex items-center max-w-[124px] max-h-[34px] rounded-[5px]'>
									<DatePickerFlat name="SoldDate" title="Sold Date" handlechangePicker={handlechangePicker} defaultValue={isAddPartialPayment ? '' : partialPayment.paymentDate} isMobile={true}/>
								</div>
							</div>
							<div className="mb-2 grid grid-cols-2">
								<div className='ml-8 text-[#000000] text-xs font-normal'>
									<div>Balance</div>
									<div>Payment</div>
									<div className='text-[#4B7CBE] font-bold'>New Balance</div>

								</div>
								<div className='flex flex-col items-center text-[#000000] text-xs font-normal'>
									<div>{Globals.moneyFormatter.format(singleOrderItem.balance)}</div>
									<div className='w-2/5 flex items-center justify-center border-b-2'>
										{Globals.moneyFormatter.format(caculatePayment()).replace('$', '')}
									</div>
									<div className='text-[#4B7CBE]'>
										{caculateNewBalance()}
									</div>
								</div>
							</div>
							<div className="flex flex-row mt-4 justify-center space-x-4">
								<button
									className="border-2 cursor-pointer font-bold leading-5 my-2 px-8 py-2 rounded-md text[#000000] text-xs"
									onClick={closePartialPayment}
								>
									Cancel
								</button>
								<button className="bg-blue cursor-pointer font-bold leading-5 my-2 px-8 py-2 rounded-md text-white text-xs uppercase" onClick={() => handleSubmitPayment()} type="submit">
									Save
								</button>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	)
}

export default PartialPayment
