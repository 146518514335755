import {Doughnut} from 'react-chartjs-2'
import {Chart, ArcElement} from 'chart.js'

Chart.register(ArcElement)
const options = {
	responsive: true,
	maintainAspectRatio: false,
	width: '150',
	height: '150',
	plugins: {
		legend: {
			display: false,
		},
	},
}


const DonutChart = ({data, plugins}) => {
	return (
		<div className="relative">
				<Doughnut data={data} options={options} plugins={plugins}  width={200} height={200}/>
		</div>
	)
}

export default DonutChart
