import React, { useState } from 'react';
import { isFunction } from 'lodash';
import {connect} from 'react-redux'
import { bindActionCreators } from 'redux'

import { Globals } from '../../Context';
import {addToast} from '../../app/appActions'
import PlanUpgradeModal from './PlanUpgradeModal'

const PerformanceLimitAccessModal = (props) => {
    const [isOpenWarning, setIsOpenWarning] = useState(true);
    const [isOpenUpgrade, setIsOpenUpgrade] = useState(false);

    const openBillingPortal = () => {
		Globals.New_Axios().post(`${Globals.NEW_API_URL}/${Globals.ENDPOINTVERSION}/spaces/${Globals.SELECTEDSPACEID}/internal_subscriptions/billing_portal`)
			.then(response => {
				const url = response.data.url
                window.open(url, '_blank')
			}
		)
	}

    const openUpgradeModal = () => {
        setIsOpenUpgrade(true);
        setIsOpenWarning(false);
    }

    const handleCloseModal = () => {
        const { closeModal } = props;
        if ( isFunction(closeModal) ) {
            setIsOpenWarning(false);
            closeModal();
        }
    }

    return (
        <>
            {
                isOpenWarning && (
                    <div className="fixed top-0 left-0 w-screen h-screen bg-black/50 flex items-center justify-center z-50 py-10">
                        <div className="bg-white drop-shadow-xl rounded-xl w-full overflow-hidden" style={{ maxWidth: '1045px' }}>
                            <div className="w-full h-[82px] borderCardChrono relative py-[30px] ps-[22px] pe-[29px] flex items-center justify-between">
                                <span className="text-white font-bold text-2xl">Warning</span>
                                <button onClick={handleCloseModal} className="cursor-pointer">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" fill="none">
                                        <path d="M17.8669 3.19484C17.6247 3.20055 17.3946 3.30193 17.2269 3.47682L11 9.70371L4.7731 3.47682C4.68757 3.3889 4.58527 3.31904 4.47225 3.27137C4.35923 3.2237 4.2378 3.1992 4.11514 3.19931C3.93286 3.19954 3.75479 3.2541 3.60367 3.35602C3.45254 3.45794 3.33524 3.6026 3.26673 3.77152C3.19822 3.94043 3.18162 4.12593 3.21906 4.30433C3.25649 4.48272 3.34625 4.64591 3.47688 4.77304L9.70376 10.9999L3.47688 17.2268C3.3889 17.3113 3.31866 17.4125 3.27027 17.5244C3.22189 17.6364 3.19633 17.7568 3.19509 17.8788C3.19384 18.0008 3.21695 18.1217 3.26305 18.2346C3.30915 18.3476 3.37731 18.4501 3.46355 18.5364C3.54979 18.6226 3.65237 18.6908 3.76528 18.7369C3.87819 18.783 3.99917 18.8061 4.12112 18.8048C4.24308 18.8036 4.36356 18.778 4.47551 18.7296C4.58746 18.6813 4.68863 18.611 4.7731 18.523L11 12.2962L17.2269 18.523C17.3113 18.611 17.4125 18.6813 17.5245 18.7296C17.6364 18.778 17.7569 18.8036 17.8788 18.8048C18.0008 18.8061 18.1218 18.783 18.2347 18.7369C18.3476 18.6908 18.4502 18.6226 18.5364 18.5364C18.6227 18.4501 18.6908 18.3476 18.7369 18.2346C18.783 18.1217 18.8061 18.0008 18.8049 17.8788C18.8036 17.7568 18.7781 17.6364 18.7297 17.5244C18.6813 17.4125 18.6111 17.3113 18.5231 17.2268L12.2962 10.9999L18.5231 4.77304C18.6565 4.64514 18.7481 4.47991 18.7859 4.29902C18.8238 4.11813 18.806 3.93003 18.7351 3.75939C18.6642 3.58875 18.5433 3.44353 18.3884 3.34278C18.2334 3.24204 18.0517 3.19047 17.8669 3.19484Z" fill="white"/>
                                    </svg>
                                </button>
                            </div>
                            <div className="h-full w-full">
                                <div className="h-[100px] flex items-center justify-center">
                                    <div className="flex items-center justify-center text-sm rounded-lg ps-[10px] py-[5px] mb-[14px] mt-[17px]" role="alert">
                                        <div className='flex items-center'>
                                            <img src="/Images/information_icon.png" alt="" />
                                            <div className="font-medium text-base ms-[11px]">
                                                <span className="me-1">
                                                    This is a platinum feature, please&nbsp;
                                                    <span className="text-[#2C79E0] cursor-pointer" onClick={openUpgradeModal}>upgrade&nbsp;</span>
                                                    to enable this.
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )
            }
            {
                isOpenUpgrade && <PlanUpgradeModal currentSpace={props.currentSpace} handleCloseModal={handleCloseModal}/>
            }
        </>
    );
};

const mapStateToProps = (state, props) => {
	return {
		profile: state.profile,
		currentSpace: state.currentSpace,
	}
}


const mapDispatchToProps = dispatch => {
	return bindActionCreators({
		addToast
	}, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(PerformanceLimitAccessModal)

