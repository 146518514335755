import React, {useState} from 'react'
import Import from './Import'
import Export from './Export'

const ImportAndExport = () => {
	const [isVisibleImport, setIsVisibleImport] = useState(true)
	const [isVisibleExport, setIsVisibleExport] = useState(false)

	const handleOpenExport = () => {
		setIsVisibleExport(true)
		setIsVisibleImport(false)
	}
	const handleOpenImport = () => {
		setIsVisibleExport(false)
		setIsVisibleImport(true)
	}
	return (
		<div className="w-full">
			<div className="relative w-full">
				<div className="flex border-b-2 w-full pl-5">
					<button
						className="cursor-pointer flex flex-row justify-center items-center border-b-2 mr-2 sm:mr-4"
						style={{
							fontWeight: isVisibleImport ? '700' : '500',
							borderColor: isVisibleImport ? '#2C79E0' : 'transparent',
						}}
						onClick={handleOpenImport}
					>
						<span className="lg:text-sm text-xxs w-full">Import</span>
					</button>
					<button
						className="cursor-pointer flex flex-row justify-center items-center border-b-2 mx-2 sm:mx-4 py-3"
						style={{
							fontWeight: isVisibleExport ? '700' : '500',
							borderColor: isVisibleExport ? '#2C79E0' : 'transparent',
						}}
						onClick={handleOpenExport}
					>
						<span className="lg:text-sm text-xxs w-full">Export</span>
					</button>
				</div>
			</div>
			{isVisibleImport && <Import/>}
			{isVisibleExport && <Export/>}
		</div>
	)
}
export default ImportAndExport
