import React, {useEffect, useRef, useState} from 'react'
import Moment from 'moment'

import {Globals} from '../../../Context'
import AddRepair from './AddRepair'
import EditRepair from './EditRepair'
import DetailRepair from './DetailRepair'
import isEmpty from 'lodash/isEmpty'

export const ListRepairs = React.forwardRef(({
	data, onDone, handleVisibleRepairsDropDown,
	visibleRepairsDropDown, handleVisibleAddRepair, isOpenRepair,
	handleVisibleEditRepair, isOpenEditRepair,
	addToast,
	role,
}, ref) => {
	const refRepairsFormText = useRef()
	const refRepairsEditFormText = useRef()
	const refButtonAddRepair = useRef()
	const [selectedRepair, setSelectedRepair] = useState(null)
	const [repairs, setRepairs] = useState([])
	const [repairErrors, setRepairErrors] = useState([])
	const selectButtonRef = useRef(null)

	React.useImperativeHandle(ref, () => (selectButtonRef.current))

	const loadRepairs = async () => {
		await Globals.New_Axios()
			.get(`${Globals.NEW_API_URL}/${Globals.ENDPOINTVERSION}/spaces/${Globals.SELECTEDSPACEID}/watches/${data?.id}/repair_watches?q[s]=created_at DESC`)
			.then(async response => {
				if(response.status !== 200) return
				setRepairs(response.data.repair_watches)
			}).catch(error => {
				console.log(error)
			})
	}

	const handleHideAddRepairs = e => {
        handleVisibleAddRepair(false)
		setRepairErrors([])
    }

	const handleHideEditRepair = () => {
		handleVisibleEditRepair(false)
		setSelectedRepair(null)
		setRepairErrors([])
	}

	const handleOpenEditRepair = repair => {
		handleVisibleEditRepair(true)
		setSelectedRepair(repair)

	}

	const handleKeyDown = (event) => {
		if(!!refRepairsFormText.current || !!refRepairsEditFormText.current) return
		switch (event.key) {
			case Globals.keyboardButton.arrowDown:
				event.preventDefault();
				if(selectButtonRef.current === document.activeElement) {
					handleVisibleRepairsDropDown(true)
				}
				if(!!refButtonAddRepair.current) {
					refButtonAddRepair.current.focus()
				}
				break;
			case Globals.keyboardButton.arrowUp:
				if(refButtonAddRepair.current === document.activeElement) {
					selectButtonRef.current.focus()
					handleVisibleRepairsDropDown(false)

				}
				break;
			case Globals.keyboardButton.escape:
				event.preventDefault();
				handleVisibleRepairsDropDown(false);
				break;
			}
		};

	  useEffect(() => {
		window.addEventListener('keydown', handleKeyDown);
		return () => {
		  window.removeEventListener('keydown', handleKeyDown);
		};
	  }, []);

	const handleRepairDelete = repairId => {
		if (!repairId) return
		Globals.New_Axios()
			.delete(`${Globals.NEW_API_URL}/${Globals.ENDPOINTVERSION}/spaces/${Globals.SELECTEDSPACEID}/watches/${data.id}/repair_watches/${repairId}`)
			.then(async response => {
				const newRepairs = repairs.filter(repair => repair.id !== repairId)
				await onDone(response.data.watch)
				setRepairs(newRepairs)
			}).catch(error => {
				const message = error.response?.data?.message || 'Something went wront please try again later.'
				addToast({
					text: message,
					type: 'error',
				})
			})
	}

	const handleSubmitRepair = e => {
		e.preventDefault()
		if (!isEmpty(refRepairsFormText.current.refRepairsFormText())) {
			let errors = []
			const formDataObject = new FormData(refRepairsFormText.current.refRepairsFormText())
			let formData = {}
			for (var pair of formDataObject.entries()) {
				formData[pair[0]] = pair[1]
			}
			//Validate all the data for a repair.
			if (isEmpty(formData.description)) errors = [...errors, 'Description is required']
			if (isEmpty(formData.repair_date)) errors = [...errors, 'Repair date is required']
			if (isEmpty(formData.cost)) errors = [...errors, 'Repair cost is required']
			// if (isEmpty(formData.markupsaleprice)) errors = [...errors, 'Repair markup sale price is required']
			if (!isEmpty(errors)) return setRepairErrors(errors)
			Globals.New_Axios()
				.post(`${Globals.NEW_API_URL}/${Globals.ENDPOINTVERSION}/spaces/${Globals.SELECTEDSPACEID}/watches/${data.id}/repair_watches`, {
					repair_watch: {description: formData.description, repair_date: Moment(formData.repair_date).format('DD/MM/YYYY'), cost: formData.cost, mark_up_sale_price: formData.markupsaleprice},
				})
				.then(async response => {
					if (response.status && response.status >= 200 && response.status < 300) {
						let repair = response.data
						setRepairErrors([])
						handleVisibleAddRepair(false)
						setRepairs([repair, ...repairs])
						onDone(response.data.watch)
					}
				})
				.catch(error => {
					setRepairErrors([])
					return 	addToast({
						text:  error.response?.data?.message || 'Something went wrong please try again later.',
						type: 'error',
					})
				})
		}


	}

	const handleEditRepairSubmit = e => {
		e.preventDefault()
		let errors = []
		if (!isEmpty(refRepairsEditFormText.current.refRepairsEditFormText())) {
			const formDataObject = new FormData(e.target)
			let formData = {}
			for (var pair of formDataObject.entries()) {
				formData[pair[0]] = pair[1]
			}

			//Validate all the data for a repair.
			if (isEmpty(formData.description)) errors = [...errors, 'Description is required']
			if (isEmpty(formData.repair_date)) errors = [...errors, 'Repair date is required']
			if (isEmpty(formData.cost)) errors = [...errors, 'Repair cost is required']
			// if (isEmpty(formData.markupsaleprice)) errors = [...errors, 'Repair markup sale price is required']

			if (!isEmpty(errors)) return setRepairErrors(errors)
				Globals.New_Axios()
					.patch(`${Globals.NEW_API_URL}/${Globals.ENDPOINTVERSION}/spaces/${Globals.SELECTEDSPACEID}/watches/${data.id}/repair_watches/${selectedRepair.id}`, {
						repair_watch: {description: formData.description, repair_date: Moment(formData.repair_date).format('DD/MM/YYYY'), cost: formData.cost, mark_up_sale_price: formData.markupsaleprice},
					})

					.then(async response => {
						if (response.status && response.status >= 200 && response.status < 300) {
							const newRepairs = repairs.map(repair => {
								if (repair.id === response.data.id) {
									repair.description = response.data.description
									repair.repair_date = response.data.repair_date
									repair.cost = response.data.cost
									repair.mark_up_sale_price = response.data.mark_up_sale_price
								}
								return repair
							})
							handleHideEditRepair()
							setRepairs(newRepairs)
						}
						onDone(response.data.watch)
					})
					.catch(error => {
						setRepairErrors([])
						return 	addToast({
							text:  error.response?.data?.message || 'Something went wrong please try again later.',
							type: 'error',
						})
					})
		}
	}

	useEffect(() => {
		loadRepairs()
	}, [])

	return (
		<>
			<div className="relative flex gap-1.5">
				<button
					ref={selectButtonRef}
					className="text-sm Button flex  items-center w-96 lg:h-1/2 mx-5 bg-white px-1 py-2"
					style={{
						background: visibleRepairsDropDown && '#2C79E0',
						color: visibleRepairsDropDown && 'white',
					}}
					onClick={
						() => {handleVisibleRepairsDropDown(!visibleRepairsDropDown)}
					}
				>
					<svg className="mr-2" width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path
							d="M12.8515 2.58433C11.5258 2.56889 10.1967 3.06428 9.18691 4.07441C7.85518 5.40614 7.45434 7.28876 7.90192 8.9914C6.14058 10.7531 3.06935 13.824 2.83112 14.0622C1.84015 15.0532 1.84015 16.6788 2.83112 17.6698C3.82209 18.6608 5.44773 18.6608 6.4387 17.6698L11.5095 12.599C13.2121 13.0465 15.0948 12.6457 16.4265 11.314C17.9652 9.77525 18.309 7.49427 17.4722 5.61493C17.4309 5.5223 17.3677 5.44111 17.288 5.37837C17.2084 5.31562 17.1146 5.27321 17.0149 5.25478C16.9152 5.23636 16.8124 5.24248 16.7156 5.27262C16.6188 5.30275 16.5307 5.356 16.459 5.42776L13.7263 8.16051C13.3385 8.54828 12.7287 8.54852 12.3404 8.16051C11.9528 7.77293 11.953 7.1629 12.3404 6.7746V6.77379L15.0731 4.04104C15.1447 3.96931 15.1978 3.88126 15.2277 3.78448C15.2577 3.68769 15.2637 3.58507 15.2452 3.48545C15.2267 3.38583 15.1843 3.29221 15.1215 3.21263C15.0588 3.13306 14.9777 3.06993 14.8852 3.02867C14.4154 2.81947 13.9208 2.68399 13.4187 2.62258C13.2304 2.59956 13.0408 2.58654 12.8515 2.58433ZM12.8409 3.83271C12.9838 3.83466 13.1268 3.84433 13.2689 3.862C13.3266 3.86918 13.38 3.89945 13.4374 3.9092L11.4566 5.89C11.4563 5.89027 11.4561 5.89054 11.4558 5.89081C10.5905 6.75671 10.591 8.17873 11.4566 9.0443C12.3225 9.90962 13.7445 9.90986 14.6101 9.0443L16.5917 7.06269C16.7941 8.25187 16.4639 9.509 15.5427 10.4302C14.4485 11.5244 12.8725 11.8191 11.5095 11.3351C11.3982 11.2956 11.278 11.2883 11.1628 11.3142C11.0476 11.3402 10.9421 11.3982 10.8585 11.4816L5.55491 16.786C5.04172 17.2992 4.2281 17.2992 3.71491 16.786C3.20171 16.2728 3.20171 15.4592 3.71491 14.946C3.96407 14.6968 7.31088 11.3504 9.01846 9.64244C9.10202 9.55893 9.16021 9.45345 9.18628 9.33823C9.21235 9.223 9.20524 9.10274 9.16575 8.9914C8.68175 7.62837 8.97646 6.05244 10.0707 4.95819C10.8377 4.19098 11.8404 3.81901 12.8409 3.83271Z"
							fill={visibleRepairsDropDown ? 'white' : '#5D6E81'}
						/>
					</svg>
					<span className="font-normal">Repairs</span>
					<svg className={visibleRepairsDropDown  ? 'rotate-180 ml-2' : 'ml-2'} width="10" height="6" viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path
							fillRule="evenodd"
							clipRule="evenodd"
							d="M0.292893 0.292893C0.683416 -0.097631 1.31658 -0.097631 1.7071 0.292893L4.99999 3.58579L8.29288 0.292893C8.6834 -0.0976311 9.31657 -0.0976311 9.70709 0.292893C10.0976 0.683417 10.0976 1.31658 9.70709 1.70711L5.7071 5.70711C5.31657 6.09763 4.68341 6.09763 4.29289 5.70711L0.292893 1.70711C-0.0976309 1.31658 -0.0976309 0.683417 0.292893 0.292893Z"
							fill={visibleRepairsDropDown  ? 'white' : '#5D6E81'}
						/>
					</svg>
				</button>
				{visibleRepairsDropDown && (
					<div className="absolute bg-white divide-gray-100 rounded shadow top-11 w-[500px] overflow-hidden z-10 right-0 top-[45px]">
						<div className="px-3">
							<div className='flex justify-between flex items-center py-4 mx-5 border-b-2'>
								<span className="font-bold text-base ">Repair Recordings</span>
								{
									['owner', 'admin', 'editor'].includes(role) && (
										<button
											ref={refButtonAddRepair}
											className="flex ml-3 items-center text-sm" onClick={() => {handleVisibleAddRepair(true)}}
										>
											<svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
												<path
													d="M9 1.5C4.86452 1.5 1.5 4.86453 1.5 9C1.5 13.1355 4.86452 16.5 9 16.5C13.1355 16.5 16.5 13.1355 16.5 9C16.5 4.86453 13.1355 1.5 9 1.5ZM9 2.625C12.5275 2.625 15.375 5.47252 15.375 9C15.375 12.5275 12.5275 15.375 9 15.375C5.47252 15.375 2.625 12.5275 2.625 9C2.625 5.47252 5.47252 2.625 9 2.625ZM8.99121 5.24194C8.84215 5.24427 8.70012 5.30567 8.5963 5.41265C8.49248 5.51963 8.43536 5.66344 8.4375 5.8125V8.4375H5.8125C5.73796 8.43645 5.66396 8.45022 5.59479 8.47801C5.52562 8.50581 5.46266 8.54707 5.40958 8.59941C5.3565 8.65175 5.31435 8.71411 5.28557 8.78288C5.2568 8.85165 5.24199 8.92545 5.24199 9C5.24199 9.07455 5.2568 9.14835 5.28557 9.21712C5.31435 9.28589 5.3565 9.34825 5.40958 9.40059C5.46266 9.45293 5.52562 9.49419 5.59479 9.52199C5.66396 9.54978 5.73796 9.56355 5.8125 9.5625H8.4375V12.1875C8.43645 12.262 8.45022 12.336 8.47801 12.4052C8.50581 12.4744 8.54707 12.5373 8.59941 12.5904C8.65175 12.6435 8.71411 12.6857 8.78288 12.7144C8.85165 12.7432 8.92545 12.758 9 12.758C9.07455 12.758 9.14835 12.7432 9.21712 12.7144C9.28589 12.6857 9.34825 12.6435 9.40059 12.5904C9.45293 12.5373 9.49419 12.4744 9.52199 12.4052C9.54978 12.336 9.56355 12.262 9.5625 12.1875V9.5625H12.1875C12.262 9.56355 12.336 9.54978 12.4052 9.52199C12.4744 9.49419 12.5373 9.45293 12.5904 9.40059C12.6435 9.34825 12.6857 9.28589 12.7144 9.21712C12.7432 9.14835 12.758 9.07455 12.758 9C12.758 8.92545 12.7432 8.85165 12.7144 8.78288C12.6857 8.71411 12.6435 8.65175 12.5904 8.59941C12.5373 8.54707 12.4744 8.50581 12.4052 8.47801C12.336 8.45022 12.262 8.43645 12.1875 8.4375H9.5625V5.8125C9.56358 5.73721 9.54953 5.66248 9.5212 5.59272C9.49286 5.52296 9.45081 5.4596 9.39753 5.40639C9.34426 5.35318 9.28084 5.31121 9.21105 5.28296C9.14125 5.25472 9.0665 5.24077 8.99121 5.24194Z"
													fill="#2C79E0"
												/>
											</svg>
											<span className="text-blue ml-1">add recording</span>
										</button>
									)
								}
							</div>
						</div>
						<div className="flex flex-col h-[700px] max-h-[70dvh] overflow-y-scroll">
							<div>
								{isEmpty(repairs) && <div className="m-5 text-center text-gray-600 text-sm font-medium">Nothing to show yet!</div>}
									{repairs?.map((repair, index) => {
										return (
											<DetailRepair
												index={index}
												repair={repair}
												role={role}
												handleOpenEditRepair={handleOpenEditRepair}
												handleRepairDelete={handleRepairDelete}
											/>
										)
									})}
								</div>
							</div>
						</div>
				)}
			</div>
			{isOpenRepair && <AddRepair ref={refRepairsFormText} handleHideAddRepairs={handleHideAddRepairs} handleSubmitRepair={handleSubmitRepair} repairErrors={repairErrors} />}
			{isOpenEditRepair && <EditRepair ref={refRepairsEditFormText} selectedRepair={selectedRepair} handleHideEditRepair={handleHideEditRepair} handleEditRepairSubmit={handleEditRepairSubmit} repairErrors={repairErrors}/>}
		</>
	)
})
