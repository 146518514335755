import React, { useState, useRef, useEffect } from 'react'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import Classnames from 'classnames'
import {connect} from 'react-redux'

import ConfirmPopUp from '../../ConfirmPopUp'
import {addToast} from '../../../app/appActions'
import { Globals } from '../../../Context'
import useOutsideClick from '../../../hooks/useOutsideClick'
import {focusElement} from '../../../utils/keyboardHandler'
import find from 'lodash/find'
import isEmpty from 'lodash/isEmpty'

const paperSide = [
	{
		label: 'US Letter (8.5in x 11in)',
		value: 'us_letter'
	},
	{
		label: 'A4 Sheet (21.0cm x 29.7cm)',
		value: 'a4'
	},
	{
		label: 'Label Printer',
		value: 'label_printer'
	},
]

const labelSizeUsLetter = [
	{
		label: 'Extra Large (5 1/2 in x 8 1/2 in)',
		value: 'extra_large'
	},
	{
		label: 'Large (3 1/3in x 4in)',
		value: 'large'
	},
	{
		label: 'Medium (4in x 2in)',
		value: 'medium'
	},
	{
		label: 'Medium Tall (2in x 4in)',
		value: 'medium_tall'
	},
	{
		label: 'Small (4in x 1 1/3in)',
		value: 'small'
	},
	{
		label: 'Extra Small (2 5/8in x 1in)',
		value: 'extra_small'
	},
	{
		label: 'Micro (1in x 1in)',
		value: 'micro'
	},
]

const labelSizeA4Sheet = [
	{
		label: 'Extra Large (14.35cm x 19.96cm)',
		value: 'extra_large'
	},
	{
		label: 'Large (9.31cm x 9.9cm)',
		value: 'large'
	},
	{
		label: 'Medium (9.9cm x 5.7cm)',
		value: 'medium'
	},
	{
		label: 'Medium Tall (5.7cm x 9.9cm)',
		value: 'medium_tall'
	},
	{
		label: 'Small (9.9cm x 3.81cm)',
		value: 'small'
	},
	{
		label: 'Extra Small (6.35cm x 3.38cm)',
		value: 'extra_small'
	},
	{
		label: 'Micro (3.5cm x 3.5cm)',
		value: 'micro'
	},
]

const labelSizeLabelPrinter = [
	{
		label: 'Large (4in x 6in)',
		value: 'large'
	},
	{
		label: 'Medium (4in x 2 1/4in)',
		value: 'medium'
	},
	{
		label: 'Medium Long (4in x 1in)',
		value: 'medium_long'
	},
	{
		label: 'Medium Tall (2in x 4in)',
		value: 'medium_tall'
	},
	{
		label: 'Small (2in x 1in)',
		value: 'small'
	},
]

const QRCode = ({ handleVisibleQRCode ,data, addToast }) => {
	const [dropdownPaperSizeOpen, setDropdownPaperSizeOpen] = useState(false)
	const [dropdownLabelSizeOpen, setDropdownLabelSizeOpen] = useState(false)
	const [paperSizeValue, setPaperSizeValue] = useState('')
	const [labelSizeValue, setLabelSizeValue] = useState('')
	const [labelSizes, setlabelSizes] = useState([])
	const [loading, setLoading] = useState(false)
	const dropdownPaperSize = useRef(null)
	const dropdownLabelSize = useRef(null)
	useOutsideClick(dropdownPaperSize, () => setDropdownPaperSizeOpen(false));
	useOutsideClick(dropdownLabelSize, () => setDropdownLabelSizeOpen(false));


	const [watch, setWatch] = useState(data)
	const [qrCodeURL, setQRCodeURL] = useState()

	const textDropdownPaperSize = () => {
		if(paperSizeValue) {
			const paper =  find(paperSide, (paper) => paper.value === paperSizeValue)
			return paper.label
		} else {
			return 'Paper Size'
		}
	}

	const textDropdownLabelSize= () => {
		if(labelSizeValue) {
			const label =  find(labelSizes, (paper) => paper.value === labelSizeValue)
			return label.label
		} else {
			return 'Label Size'
		}
	}

	const handleChangePaperSize = (value) => {
		if(value === 'us_letter') {
			setlabelSizes(labelSizeUsLetter)
		} else if(value === 'a4') {
			setlabelSizes(labelSizeA4Sheet)
		} else if(value === 'label_printer') {
			setlabelSizes(labelSizeLabelPrinter)
		}
		if(paperSizeValue !== value) {
			setLabelSizeValue('')
		}

		setPaperSizeValue(value);
		setDropdownPaperSizeOpen(false);
	}

	const handleDeleteQRCode =  () => {
		Globals.New_Axios()
		.delete(`${Globals.NEW_API_URL}/${Globals.ENDPOINTVERSION}/spaces/${Globals.SELECTEDSPACEID}/watches/${data.id}/qr_code_settings`)
		.then(response => {
			addToast({
				title: 'QR Code Delete Successfully',
				text: response?.data?.message,
				type: 'success',
			})
			handleVisibleQRCode(false)
		})
		.catch(error => {
			console.log(error)
		})
	}

	const handleGenerateQRCode = () => {
		setLoading(true)
		let errors = {}
		if (isEmpty(paperSizeValue)) errors = {...errors, paper_size: 'Paper Size is required'}
		if (isEmpty(labelSizeValue)) errors = {...errors, label_size: 'Label Size is required'}
		if (!isEmpty(errors)) {
			return Object.values(errors)
				.reverse()
				.map(error => {
					setLoading(false)
					return addToast({
						title: 'Cannot generate QR Code',
						text: error,
						type: 'error',
					})
				})
		}

		const params = {
			watch_qr_code_setting: {
				label_size: labelSizeValue,
				page_size: paperSizeValue
			}
		}

		Globals.New_Axios().post(`${Globals.baseUri}/watches/${data.id}/qr_code_settings`, params).then((response) => {
			const { data } = response
			setWatch(data.watch)
			setQRCodeURL(data.qr_code_url)
		})
	}

	useEffect(() => {
		if(watch.has_qr_code) {
			setLoading(true)
			Globals.New_Axios().get(`${Globals.baseUri}/watches/${data.id}/qr_code_settings`, {
			}).then((response) => {
				const { data } = response
				setQRCodeURL(data.qr_code_url)
			})
		}
	}, [watch])

	useEffect(() => {
		focusElement('.watch-qr-view')
	}, [])

	const handlePrintQR = () => {
		window.location.href = `${Globals.NEW_API_URL}/watch_qr_code_printing/print/${watch.id}`
	}

	return (
		<div
			tabIndex="-1"
			aria-hidden="true"
			className="watch-qr-view bg-black/50 fixed flex h-screen items-center justify-center left-0 overflow-hidden inset-0 w-full z-50 "
			onKeyDown={(event)=> {
				if(event.key === 'Escape' && event.target.classList.contains('watch-qr-view')) {
					handleVisibleQRCode(false)
				}
			}}
		>
			<div className="relative max-h-[529px] max-w-[519px] w-full">
				<div className="relative bg-white rounded-xl shadow h-full">
					<div className="flex justify-between items-center h-[60px] rounded-t-[8px] border-b bg-slate-600 text-white px-[13px]">
						<h3 className="text-white font-bold text-lg">{watch.has_qr_code ? 'QR Code' : 'Create QR Code'}</h3>
						<button
							onClick={() => {
								handleVisibleQRCode(false)
							}}
							type="button"
							className="bg-transparent rounded-xl text-sm py-1.5 ml-auto inline-flex items-center"
						>
							<svg className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
								<path
									fillRule="evenodd"
									d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
									clipRule="evenodd"
								></path>
							</svg>
						</button>
					</div>
					{loading &&
						<div className="absolute top-0 left-0 right-0 bottom-0 flex justify-center items-center z-50">
							<button
								type="button"
								className="inline-flex items-center px-4 py-2 leading-6 text-sm shadow rounded-md text-white bg-blue transition ease-in-out duration-150 cursor-not-allowed"
								disabled
							>
								<svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
									{' '}
									<circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4" />
									<path
										className="opacity-75"
										fill="currentColor"
										d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
									/>
								</svg>
								<span>Loading... </span>
							</button>
						</div>
					}
					{watch.has_qr_code ?
						<div className='w-full h-full flex flex-col justify-center py-[24px] px-[84px]'>
							<div className='w-[350px] h-[350px] p-[30px]'>
								{qrCodeURL && <img src={qrCodeURL} onLoad={() => setLoading(false)} />}
							</div>
							<div className='flex justify-center gap-[29px] mt-[20px]'>
								<ConfirmPopUp
									onConfirm={handleDeleteQRCode}
									title="Confirm deleting QR Code"
                            		content="Are you sure you want to delete it?"
								>
									<div className='px-[14px] py-[12px] border-[1px] rounded-[5px] text-[#5D6E81] font-bold text-[14px] cursor-pointer'>
										Delete
									</div>
								</ConfirmPopUp>
								<div className='px-[24px] py-[12px] border-[1px] rounded-[5px] text-[#FFFFFF] font-bold text-[14px] bg-[#4B7CBE] cursor-pointer' onClick={handlePrintQR}>
									Download
								</div>
							</div>
						</div> :
						<div className='flex flex-col justify-between w-full h-[469px] pl-[16px] pr-[43px] py-[24px]'>
							<div>
								<div className='w-full flex items-center'>
									<span className='font-semibold text-[20px] leading-[6px] text-[#5D6E81]'>Label Options</span>
									<div className='relative group'>
										<span className="ml-1">
											<InfoOutlinedIcon fontSize="small" />
										</span>
										<div className='hidden group-hover:block'>
											<div className="absolute w-0 h-0 border-solid border-t-0 border-r-transparent z-[9999999999] border-b-[#D9E2EE] border-l-transparent left-[4px]"
												style={{
													borderRightWidth: '10.5px',
													borderBottomWidth: '21px',
													borderLeftWidth: '10.5px',
												}}>
											</div>
											<div
												className='flex items-center justify-center absolute bg-[#D9E2EE] z-[9999999999] w-[496px] h-[74px] text-[12px] font-normal rounded-[6px] top-[40px] left-[-150px] text-[#5D6E81]'
											>
												Labels per sheet: 2 (Compatible with: Avery J8168, L7068, L7768, L7168, L7168B)
												<br />
												Printer Type: Laser / Inkjet
											</div>
										</div>
									</div>
								</div>
								<div className='relative'>
									<button
										className={Classnames("flex items-center mt-[24px] px-[12px] w-full h-[49px] inputLogin  !font-semibold text-[14px] border-[#D9E2EE] text-[#5D6E81] cursor-pointer",
											{ 'text-[#D1D3D4]': !paperSizeValue, 'text-[#5D6E81]': !paperSizeValue})
										}
										onClick={() => {setDropdownPaperSizeOpen(!dropdownPaperSizeOpen), setDropdownLabelSizeOpen(false)}}
									>
										{textDropdownPaperSize()}
										<img className='absolute right-[12px] top-1/2 transform -translate-y-1/2 text-[#5D6E81] pointer-events-none' src="/Images/icon_dropdown.svg" alt="dropdown-icon" />
									</button>
									{dropdownPaperSizeOpen && (
										<ul
											style={{boxShadow: '1px 1px 1px #9E9E9E'}}
											ref={dropdownPaperSize}
											className="absolute z-10 bg-white border border-[#D9E2EE] w-full text-[14px] rounded-[5px]">
											{paperSide.map(value => (
												<li
													key={value.value}
													onClick={() => {handleChangePaperSize(value.value)}}
													className={Classnames("px-[12px] py-[8px] flex justify-between items-center cursor-pointer hover:bg-[#F1F5F9]",
														{'text-[#4B7CBE]': value.value === paperSizeValue })}
													>
													<div className='flex'>
														{value.label}
														{value.value === 'label_printer' &&
															<div className='relative group'>
																<span className="ml-1">
																	<InfoOutlinedIcon fontSize="small" />
																</span>
																<div className='hidden group-hover:block'>
																	<div className="absolute w-0 h-0 border-solid border-t-0 border-r-transparent z-[9999999999] border-b-[#D9E2EE] border-l-transparent left-[4px]"
																		style={{
																			borderRightWidth: '10.5px',
																			borderBottomWidth: '21px',
																			borderLeftWidth: '10.5px',
																		}}>
																	</div>
																	<div
																		className='flex items-center justify-center absolute bg-[#D9E2EE] z-[9999999999] w-[265px] h-[53px] text-[12px] font-normal rounded-[6px] top-[40px] left-[-40px] text-[#5D6E81]'
																	>
																		Compatible with singe / thermal printers
																		<br />
																		(DYMO LabelWriter Series)
																	</div>
																</div>
															</div>
														}
													</div>
													{ value.value === paperSizeValue &&
														<svg xmlns="http://www.w3.org/2000/svg" width="13" height="11" viewBox="0 0 13 11" fill="none">
															<path d="M11.4725 0L13 1.56281L4.33333 10.3674L0 5.97611L1.53833 4.4243L4.33333 7.25278L11.4725 0Z" fill="#4B7CBE"/>
														</svg>
													}
												</li>
											))}
										</ul>
									)}
								</div>
								<div className='relative'>
									<button
										className={Classnames("flex items-center mt-[24px] px-[12px] w-full h-[49px] inputLogin  !font-semibold text-[14px] border-[#D9E2EE] text-[#5D6E81] cursor-pointer",
											{ 'text-[#D1D3D4]': !labelSizeValue, 'text-[#5D6E81]': !labelSizeValue})
										}
										onClick={() => {setDropdownLabelSizeOpen(!dropdownLabelSizeOpen), setDropdownPaperSizeOpen(false)}}
									>
										{textDropdownLabelSize()}
										<img className='absolute right-[12px] top-1/2 transform -translate-y-1/2 text-[#5D6E81] pointer-events-none' src="/Images/icon_dropdown.svg" alt="dropdown-icon" />
									</button>
									{dropdownLabelSizeOpen && (
										<ul
											ref={dropdownLabelSize}
											className="absolute z-10 bg-white border border-[#D9E2EE] w-full text-[14px] rounded-[5px]"
											style={{boxShadow: '1px 1px 1px #9E9E9E'}}
										>
										{labelSizes.map(value => (
											<li
												key={value.value}
												onClick={() => {
													setLabelSizeValue(value.value);
													setDropdownLabelSizeOpen(false);
												}}
												className={Classnames("px-[12px] py-[8px] flex items-center justify-between cursor-pointer hover:bg-[#F1F5F9]",
													{'text-[#4B7CBE]': value.value == labelSizeValue })}
												>
												{value.label}
												{ value.value == labelSizeValue &&
													<svg xmlns="http://www.w3.org/2000/svg" width="13" height="11" viewBox="0 0 13 11" fill="none">
														<path d="M11.4725 0L13 1.56281L4.33333 10.3674L0 5.97611L1.53833 4.4243L4.33333 7.25278L11.4725 0Z" fill="#4B7CBE"/>
													</svg>
												}
											</li>
										))}
										</ul>
									)}
								</div>
							</div>
							<div className='flex justify-center gap-[29px] mt-[20px]'>
								<ConfirmPopUp
									onConfirm={() => {
										handleVisibleQRCode(false)
									}}
									title="Confirm cancellation of QR code creation."
                            		content="Are you sure you want to cancel it?"
								>
									<div
										className='px-[14px] py-[12px] border-[1px] rounded-[5px] text-[#5D6E81] font-bold text-[14px] cursor-pointer'
									>
										Cancel
									</div>
								</ConfirmPopUp>
								<button disabled={loading} className='px-[24px] py-[12px] border-[1px] rounded-[5px] text-[#FFFFFF] font-bold text-[14px] bg-[#4B7CBE] cursor-pointer' onClick={handleGenerateQRCode}>
									Generate QR Code
								</button>
							</div>
						</div>
					}
				</div>
			</div>
		</div>
	)
}

const mapStateToProps = (state, props) => {
	return {
	}
}

const mapActionsToProps = {
	addToast,
}

export default connect(mapStateToProps, mapActionsToProps)(QRCode)

