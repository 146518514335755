import sanitizer from 'sanitizer'
import Classnames from 'classnames'
import React, {useState, useEffect, useRef} from 'react'
import {useSelector, useDispatch} from 'react-redux'
import Tooltip from '@mui/material/Tooltip'

import {inventoryUpdateFilters, toggleTableDropDown} from './../app/appActions'
import useOutsideClick from '../hooks/useOutsideClick'
import isEmpty from 'lodash/isEmpty'
import find from 'lodash/find'
import head from 'lodash/head'
import isNaN from 'lodash/isNaN'
import map from 'lodash/map'
import orderBy from 'lodash/orderBy'
import uniq from 'lodash/uniq'
import includes from 'lodash/includes'
import findIndex from 'lodash/findIndex'
import isFunction from 'lodash/isFunction'

const displayFilterText = {
	warranty: {
		'(Blanks)': '(Blanks)',
	}
}

const ColumnFilterDropDown = props => {
	const dispatch = useDispatch()
	const checkedFilterValuesRef = React.useRef([])
	let [filterOperators, setFilterOperators] = useState([])

	const [visibility, setVisibility] = useState(false),
		[dropdownVisibility, setDropdownVisibility] = useState(false),
		[selectedFilter, setSelectedFilter] = useState(null),
		[selectedColumn, setSelectedColumn] = useState(null),
		[checkedFilterValues, setCheckedFilterValues] = useState([]),
		[filterValues, setFilterValues] = useState([]),
		[visibleFilterValues, setVisibleFilterValues] = useState([]),
		// [isActiveUse, setIsActiveUse] = useState(false),
		{visibleTableDropDown} = useSelector(state => state),
		{inventoryFilters} = useSelector(state => state)

	const buttonRef = useRef(null)
	const dropdownRef = useRef(null)

	const isPrimaryFilter = !isEmpty(inventoryFilters.filter(item => item.key === props.columnKey && item.isPrimary))

	useOutsideClick(dropdownRef, () => {
		cancelOperation()
	}, {exceptObjects: [buttonRef.current]});

	const { dataSource } = props

	useEffect(() => {
		const columnHeader = find(dataSource, { column_key: props.columnKey})

		if (!columnHeader) {
			return
		}

	}, [dataSource, props.columnKey])

	const cancelOperation = () => {
		setVisibility(false)
		setSelectedFilter(null)
		toggleDropdownVisibility()
		setCheckedFilterValues([...checkedFilterValuesRef.current])
		return setVisibleFilterValues([...filterValues])
	}

	const triggerOperation = async () => {
		//Use redux instead of the endless callback functions.
		// setIsActiveUse(false);

		checkedFilterValuesRef.current = [...checkedFilterValues]
		setSelectedColumn(props.columnKey)
		if (filterValues.length !== checkedFilterValues.length) {
			// setIsActiveUse(true);
		}

		if (!isEmpty(selectedFilter) && selectedFilter.operator !== 'none') {
			// setIsActiveUse(true);

			if (selectedFilter.requiredInput && isEmpty(selectedFilter.values)) {
				//put the selected filter condition back to none.
				setSelectedFilter({
					label: 'None',
					operator: 'none',
					requiredInput: false,
				})
			}
		}

		toggleDropdownVisibility()

		//Right here user dispatch to trigger actions.
		//const isFull = checkedFilterValues.length === uniq(map(props.dataPackage, props.columnKey)).length
		const isFull = checkedFilterValues.length === head(props.dataSource.filter(data => data.column_key === props.columnKey)).values.length
		await dispatch(
			inventoryUpdateFilters({
				key: props.columnKey,
				request: {
					filter_values: checkedFilterValues,
					selectedFilter: selectedFilter,
				},
				isPrimary: isPrimaryFilter,
				isFull: isFull,
			})
		)

		if (isPrimaryFilter) {
			const _filterValues = orderBy(orderBy(uniq(map(props.dataPackage, props.columnKey))), [
				(a, b) => {
					if (isNaN(Number(a))) return a
					return Number(a)
				},
			])
		}
	}

	const toggleVisibility = () => {
		return setVisibility(!visibility)
	}

	const toggleDropdownVisibility = () => {
		// if the visible dropdown is matching the column key then inject null to toggle the dropdown.
		if (visibleTableDropDown === props.columnKey) {
			dispatch(toggleTableDropDown(null))
		} else { // if the visible dropdown is different from the column key then inject the name of it in the state.
			dispatch(toggleTableDropDown(props.columnKey))
		}
	}

	const pickFilterOption = filter => {
		setVisibility(!visibility)
		setSelectedFilter(filter)
	}

	const toggleFilterValue = value => {
		const exist = includes(checkedFilterValues, value)
		if (!exist) {
			return setCheckedFilterValues([...checkedFilterValues, value])
		}

		return setCheckedFilterValues(checkedFilterValues.filter(fv => fv !== value))
	}

	const searchFilterValue = el => {
		if (el.target.value) {
			const input = sanitizer.escapeAttrib(el.target.value?.toLowerCase())?.replaceAll('\\', '')
			const data = filterValues.filter(value => String(value)?.toLowerCase()?.match(input))

			return setVisibleFilterValues(data)
		}

		return setVisibleFilterValues(filterValues)
	}

	const selectAllValues = ({firstInit} = {firstInit: false}) => {
		if (firstInit) {
			let filters = head(props.dataSource?.filter(data => data.column_key === props.columnKey))
			filters = filters?.values ? filters.values : []

			checkedFilterValuesRef.current = [...filters]
			setCheckedFilterValues([...filters])
		} else {
			const filters = filterValues.filter(item => checkedFilterValues.includes(item) || visibleFilterValues.includes(item))
			setCheckedFilterValues(filters)
		}
		//const selectAllValue = uniq(map(props.dataPackage, props.columnKey))
		//setCheckedFilterValues(selectAllValue.filter(item => visibleFilterValues.includes(item)))
	}

	const clearAllValues = () => {
		setCheckedFilterValues(checkedFilterValues.filter(item => !visibleFilterValues.includes(item)))
	}

	/* eslint-disable */
	useEffect(() => {
		const currentFilter = head(inventoryFilters.filter(filter => filter.key === props.columnKey))
		if (!isEmpty(currentFilter) && !isEmpty(currentFilter.request.filter_values) && JSON.stringify(currentFilter.request.filter_values) !== JSON.stringify(checkedFilterValues)) {
			setTimeout(() => {
				checkedFilterValuesRef.current = [...currentFilter.request.filter_values]
				setCheckedFilterValues(currentFilter.request.filter_values)
				// setIsActiveUse(true)
			}, 500)
		}

		if (!isEmpty(currentFilter) && !isEmpty(currentFilter.request.selectedFilter) && currentFilter.request.selectedFilter.operator !== 'none') {
			setTimeout(() => {
				let _filterOperators = head(filterOperators.filter(filter => filter.operator === currentFilter.request.selectedFilter.operator))
				if (!isEmpty(_filterOperators)) {
					_filterOperators.values = currentFilter.request.selectedFilter.values
					setSelectedFilter(_filterOperators)
					// setIsActiveUse(true)
				}

			}, 500)
		}

		if (isEmpty(inventoryFilters)) {
			//Then reset everything.
			selectAllValues({firstInit: true})
			setSelectedFilter(null)
			// setIsActiveUse(false)
		}

		// setIsActiveUse(!isEmpty(inventoryFilters.filter(t => t.key === props.columnKey)))
	}, [inventoryFilters, props.columnKey, filterOperators])
	/* eslint-enable */

	useEffect(() => {
		setTimeout(() => {
			const filters = find(props.dataSource, { column_key: props.columnKey })
			const currentFiltersValues = filters?.values ? filters.values : []

			// When you filter for a value, and then open the filter back up for that column, whichever value(s) you selected should be on the top of the filter by value list | https://www.notion.so/swareco/Filters-TO-DO-1effa57bbaae4f00bb310942fa3c092d
			const notCheckedFiltersValues = currentFiltersValues.filter(item => !checkedFilterValues.includes(item))
			const checkedFiltersValues = currentFiltersValues.filter(item => checkedFilterValues.includes(item))
			setFilterValues([...checkedFiltersValues, ...notCheckedFiltersValues])
			setVisibleFilterValues([...checkedFiltersValues, ...notCheckedFiltersValues])
			// setCheckedFilterValues(filters?.values ? filters.values : [])
		}, 1000)
	}, [props.dataSource, props.dataPackage, props.columnKey, inventoryFilters])

	useEffect(() => {
		if (visibleTableDropDown === props.columnKey) {
			setDropdownVisibility(true)
		} else {
			setDropdownVisibility(false)
		}
	}, [visibleTableDropDown, props.columnKey])

	let selectedColumnKey = false
	let indexStatus = findIndex(inventoryFilters, value => value.key === 'display_status')
	let indexCondition = findIndex(inventoryFilters, value => value.key === 'display_condition')
	if(indexStatus > -1) {
		inventoryFilters[indexStatus] = {
			...inventoryFilters[indexStatus],
			key: 'status',
		}
	}

	if(indexCondition > -1) {
		inventoryFilters[indexCondition] = {
			...inventoryFilters[indexCondition],
			key: 'condition',
		}
	}

	const { filterObject, columnKey } = props
	const currentFilter = inventoryFilters.find(filter => filter.key === columnKey)

	if (filterObject && find(filterObject.q_parameter, (o) => o.column_name.includes(columnKey))) {
		selectedColumnKey = true
	} else if (currentFilter?.request?.filter_values) {
		selectedColumnKey = (currentFilter.request.filter_values.length !== filterValues.length)
	}

	const _triangleIcon = (
		<svg
			version="1.0"
			xmlns="http://www.w3.org/2000/svg"
			width="12pt"
			height="12"
			viewBox="0 0 512.000000 512.000000"
			preserveAspectRatio="xMidYMid meet"
			className={Classnames('fill-black-300', {
				// 'fill-blue-500': isActiveUse,
				'fill-blue-500': selectedColumnKey,
			})}
		>
			<g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)" stroke="none">
				<path d="M640 3625 l0 -215 1920 0 1920 0 0 215 0 215 -1920 0 -1920 0 0 -215z M1280 2560 l0 -210 1280 0 1280 0 0 210 0 210 -1280 0 -1280 0 0 -210z M2137 1703 c-4 -3 -7 -100 -7 -215 l0 -208 430 0 430 0 -2 213 -3 212 -421 3 c-231 1 -424 -1 -427 -5z" />
			</g>
		</svg>
	)

	const priceCoulmns = ['msrp', 'marketplace price', 'online price', 'cost']

	return (
		<div className="relative">
			<button ref={buttonRef} className="flex w-full text-left items-center justify-between z-30" onClick={(event) => {event.stopPropagation(); cancelOperation()}}>
				<Tooltip title={props.title} placement="bottom" arrow>
					<span className={Classnames('column-name line-clamp-2 w-full', {'text-blue-500': selectedColumnKey})}>{props.title}</span>
				</Tooltip>
				{isFunction(props.sortAction) && (
					<span
						className={Classnames('order-button order-button table-order-icon', {
							'_active-ascending-order': !isEmpty(props.orderby) && props.orderby === props.columnKey && props.order === 'ASC',
						})}
					>
						{_triangleIcon}
					</span>
				)}
			</button>

			<div className="relative z-20">
				{dropdownVisibility && isFunction(props.triggerSort) && isFunction(props.sortAction) && (
					<div
						className={Classnames('absolute bg-white border border-gray-200 divide-gray-100 divide-y drop-shadow-xl font-medium rounded w-72 z-30 top-4', {
							'-left-48': props.isLast || props.isSecondLast,
						})}
					>
						<div>
							<ul ref={dropdownRef} className="py-1 text-sm">
								<li className="hover:bg-gray-100">
									<button
										className="block p-4 w-full text-left"
										onClick={() => {
											setVisibility(false)
											setDropdownVisibility(false)
											props.triggerSort('ASC')
										}}
									>
										Sort A → Z
									</button>
								</li>
								<li className="hover:bg-gray-100">
									<button
										className="block p-4 w-full text-left"
										onClick={() => {
											setVisibility(false)
											setDropdownVisibility(false)
											props.triggerSort('DESC')
										}}
									>
										Sort Z → A
									</button>
								</li>
								<li className="p-4 pt-2">
									{/* TODO: hide this for the first release, we deal with this later */}
									{/* <div className="mb-5">
										<div className="mb-5">
											<div className="mb-2">Filter by condition</div>
											<div className="relative">
												<button
													className="text-gray-600 w-full bg-white hover:bg-gray-100 rounded border text-sm px-4 py-2.5 text-center inline-flex items-center relative"
													type="button"
													onClick={toggleVisibility}
												>
													<span>{selectedFilter ? selectedFilter.label : 'None'}</span>
													<svg
														className="w-3 h-3 absolute right-1 rotate-180 top-2"
														aria-hidden="true"
														fill="none"
														stroke="currentColor"
														viewBox="0 0 24 24"
														xmlns="http://www.w3.org/2000/svg"
													>
														<path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 9l-7 7-7-7" />
													</svg>
													<svg
														className="w-3 h-3 absolute right-1 top-4"
														aria-hidden="true"
														fill="none"
														stroke="currentColor"
														viewBox="0 0 24 24"
														xmlns="http://www.w3.org/2000/svg"
													>
														<path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 9l-7 7-7-7" />
													</svg>
												</button>
												{visibility && (
													<div className="absolute bg-white divide-gray-100 divide-y font-medium max-h-96 overflow-y-scroll overflow-x-hidden rounded shadow top-12 w-full z-10">
														<ul className="px-4 py-1 text-sm">
															{filterOperators.map(filter => {
																return (
																	<li key={filter.label} className="border-b border-gray-100 hover:bg-gray-100">
																		<button className="p-4" onClick={pickFilterOption.bind(this, filter)}>
																			{filter.label}
																		</button>
																	</li>
																)
															})}
														</ul>
													</div>
												)}
											</div>
										</div>
										<div className="mt-2">{selectedFilter && isFunction(selectedFilter.render) ? selectedFilter.render() : null}</div>
									</div> */}
									<div className="mb-5">
										<div className="mb-5">
											<div className="mb-2">Filter by values</div>
											<div className="relative">
												<div className="my-4 text-blue-500 text-xs">
													<button className="cursor-pointer" onClick={selectAllValues}>
														Select all
													</button>
													<span> - </span>
													<button className="cursor-pointer" onClick={clearAllValues}>
														Clear
													</button>
												</div>
												<div className="relative">
													<input
														type="text"
														placeholder="Search"
														className="bg-white border hover:bg-gray-100 inline-flex items-center placeholder-gray-600 px-4 py-2.5 rounded text-gray-600 text-sm w-full"
														onChange={searchFilterValue}
													/>
													<span>
														<svg className="w-4 h-4 absolute right-1 top-3" viewBox="0 0 22 22" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
															<path
																fillRule="evenodd"
																clipRule="evenodd"
																d="M14.347 13.6781H15.0923L19.7998 18.3951L18.3941 19.8008L13.6772 15.0932V14.348L13.4224 14.0838C12.347 15.0083 10.9507 15.5649 9.43188 15.5649C6.04509 15.5649 3.2998 12.8196 3.2998 9.43286C3.2998 6.04606 6.04509 3.30078 9.43188 3.30078C12.8187 3.30078 15.564 6.04606 15.564 9.43286C15.564 10.9517 15.0074 12.348 14.0828 13.4234L14.347 13.6781ZM5.1866 9.43286C5.1866 11.7819 7.08282 13.6781 9.43188 13.6781C11.7809 13.6781 13.6772 11.7819 13.6772 9.43286C13.6772 7.0838 11.7809 5.18757 9.43188 5.18757C7.08282 5.18757 5.1866 7.0838 5.1866 9.43286Z"
															/>
														</svg>
													</span>
												</div>
											</div>
										</div>
										<div className="border-b border-gray-100 border-solid h-28 max-h-64 mb-5 overflow-hidden overflow-y-scroll pb-4">
											{visibleFilterValues.map((fv, index) => {
												return (
													<div className="overflow-hidden" key={index}>
														<button className="text-left p-2 cursor-pointer bg-transparent hover:bg-gray-100 w-full" onClick={toggleFilterValue.bind(this, fv)}>
															<span className="break-all">
																<svg
																	xmlns="http://www.w3.org/2000/svg"
																	viewBox="0 0 270 270"
																	width="12"
																	className={Classnames('float-left mt-1 mr-1.5', [
																		{
																			'stroke-gray-600': includes(checkedFilterValues, fv),
																		},
																		{
																			'stroke-transparent': !includes(checkedFilterValues, fv),
																		},
																	])}
																	fill="none"
																	strokeWidth="30"
																>
																	<path d="M 30,180 90,240 240,30" />
																</svg>
																<span>{(displayFilterText[props.columnKey] || {})[fv] || fv}</span>
															</span>
														</button>
													</div>
												)
											})}
										</div>
									</div>

									<div className="flex gap-3 justify-end mt-6">
										<button className="border font-semibold hover:bg-gray-100 p-2 rounded" onClick={cancelOperation}>
											Cancel
										</button>
										<button className="bg-blue-500 border border-blue-500 font-semibold hover:bg-blue-600 p-2 px-3 rounded text-white" onClick={triggerOperation}>
											OK
										</button>
									</div>
								</li>
							</ul>
						</div>
					</div>
				)}
				{dropdownVisibility && isFunction(props.triggerSort) && isFunction(props.sortAction) && (
					<div className="bg-transparent absolute h-screen w-screen top-0 right-0 left-0 bottom-0 z-20" onClick={toggleDropdownVisibility}></div>
				)}
			</div>
		</div>
	)
}
export default ColumnFilterDropDown
