import React, {useEffect, useState} from 'react'
import DatePicker from 'react-datepicker'
import ClassNames from 'classnames'

import 'react-datepicker/dist/react-datepicker.css'
import './styles.css'
import {handleKeyDownPickerDate} from '../../../../utils/keyboardHandler'
import isEmpty from 'lodash/isEmpty'
import isFunction from 'lodash/isFunction'


const DatePickerFlatMobile = ({name, title, defaultValue, handlechangePicker, isMobile}) => {
	const [startDate, setStartDate] = useState(null);

	useEffect(() => {
		if (!isEmpty(defaultValue) && defaultValue !== 'null' && !isMobile) {
			const _defaultValue = new Date(defaultValue)
			if (_defaultValue instanceof Date && !isNaN(_defaultValue)) {
				setStartDate(_defaultValue)
			}
		}
	}, [defaultValue, isMobile])

	return (
		<React.Fragment>
			<div className={ClassNames("relative date-flat-picker-custom date-flat-picker-custom-mobile w-full md:hidden", {'date-flat-picker-custom-background-mobile': name === 'date_purchased'})}>
				<DatePicker
					selected={startDate}
					onChange={date => {
						setStartDate(date)
						if (isFunction(handlechangePicker)) handlechangePicker(date, name)
					}}
					showPopperArrow={false}
					name={name}
					value={startDate}
					placeholderText={title}
					onKeyDown={(event, name) => {
                        handleKeyDownPickerDate(event)
                    }}
				/>
			</div>
		</React.Fragment>
	)
}

export default DatePickerFlatMobile
