import React, {Fragment} from 'react'
import moment from 'moment'
import {NavLink, useNavigate} from 'react-router-dom'



import {Globals} from '../Context'
import isFunction from 'lodash/isFunction'
import isEmpty from 'lodash/isEmpty'

const UserProfileModal = ({handleCloseUserProfileModal, singleDataProvider, handleFilterBySpace, props, isMessage=false, isMarketPlace=false}) => {
    const isVisible = ['owner', 'admin', 'editor'].includes(props.profile?.role)
    const navigate = useNavigate()

    const handleViewAllListings = () => {
        if (isMessage) {
            navigate('/marketplace')
        }

        if(isFunction(handleFilterBySpace)) {
            handleFilterBySpace({spaceId: singleDataProvider.space.id, name: singleDataProvider.space.name, type: 'space'})
        }

        handleCloseUserProfileModal()
    }

    const textCountry = (singleDataProvider) =>  {
        const country = Globals.countries.find(value => value.code === singleDataProvider.space.country)
        if(!!country) {
            return country?.name
        }
        return ''
    }

	return (
		<>
			<div className="h-screen bg-black/50 fixed flex items-center justify-center inset-0 z-40 p-3">
				<div className="max-w-[719px] w-full h-[408px]">
					<div className="bg-slate-600 flex items-center px-8 py-4 relative rounded-t-[5px] text-white w-full">
						<span className="text-white font-bold text-lg">{singleDataProvider?.space?.name}</span>
						<button className="absolute right-0 mr-5 cursor-pointer" onClick={handleCloseUserProfileModal}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                <path d="M18.7977 0.000328173C18.4873 0.00765314 18.1925 0.137543 17.9776 0.361605L9.99973 8.33953L2.02182 0.361605C1.91224 0.248962 1.78117 0.159455 1.63637 0.0983833C1.49157 0.0373118 1.33599 0.00591852 1.17884 0.00606273C0.945302 0.00635198 0.717153 0.076253 0.523536 0.206836C0.329919 0.33742 0.179625 0.522757 0.0918521 0.739172C0.00407956 0.955588 -0.0171861 1.19326 0.0307715 1.42182C0.0787291 1.65038 0.193733 1.85945 0.361091 2.02233L8.33901 10.0003L0.361091 17.9782C0.248376 18.0864 0.158387 18.216 0.0963941 18.3594C0.0344011 18.5029 0.0016507 18.6572 6.08072e-05 18.8135C-0.00152909 18.9697 0.0280733 19.1247 0.0871348 19.2694C0.146196 19.4141 0.233529 19.5455 0.344019 19.656C0.454509 19.7665 0.585934 19.8538 0.730598 19.9129C0.875263 19.9719 1.03026 20.0015 1.18651 19.9999C1.34275 19.9983 1.49711 19.9656 1.64055 19.9036C1.78398 19.8416 1.9136 19.7516 2.02182 19.6389L9.99973 11.661L17.9776 19.6389C18.0859 19.7516 18.2155 19.8416 18.3589 19.9036C18.5024 19.9656 18.6567 19.9983 18.813 19.9999C18.9692 20.0015 19.1242 19.9719 19.2689 19.9129C19.4135 19.8538 19.545 19.7665 19.6555 19.656C19.7659 19.5455 19.8533 19.4141 19.9123 19.2694C19.9714 19.1247 20.001 18.9697 19.9994 18.8135C19.9978 18.6572 19.9651 18.5029 19.9031 18.3594C19.8411 18.216 19.7511 18.0864 19.6384 17.9782L11.6605 10.0003L19.6384 2.02233C19.8093 1.85846 19.9267 1.64677 19.9751 1.41501C20.0236 1.18325 20.0009 0.94226 19.91 0.723634C19.8191 0.505007 19.6642 0.318956 19.4658 0.18988C19.2673 0.0608051 19.0344 -0.00526735 18.7977 0.000328173Z" fill="white"/>
                            </svg>
						</button>
					</div>
					<div className="bg-white text-gray-500 rounded-b-[5px] px-8 py-4 w-full h-[350px]">
                        <div class="flex gap-2 justify-between">
                            <div className="flex items-center">
                                <img className='mr-2' src="/Images/user-profile1.svg" alt="user-profile-1" />
                                <span className='text-sm font-normal'>Member Since {moment(singleDataProvider?.space.created_at).format("YYYY")}</span>
                            </div>
                            <div className="flex items-center">
                                <img className='mr-2' src="/Images/user-profile2.svg" alt="user-profile-2" />
                                <div className='flex flex-col'>
                                    <span className='text-sm font-normal pt-2'>{singleDataProvider.space.active_listing_in_marketplaces} Listings in Marketplace</span>
                                        <button
                                            className='text-[11px] text-[#6C727F] font-semibold underline w-max'
                                            onClick={handleViewAllListings}
                                        >
                                            View All Listings
                                        </button>
                                </div>
                            </div>
                            <div className="flex items-center">
                                <img className='mr-2' src="/Images/user-profile3.svg" alt="user-profile-3" />
                                <span className='text-sm font-normal'>5/5 Rating</span>
                            </div>
                        </div>
						<div className='flex justify-center items-center pt-6'>
                            <div className='grid grid-cols-2 gap-2'>
                                <div>
                                    <img
                                        src={ !isEmpty(singleDataProvider.space.logo.url) ? singleDataProvider.space.logo.url : '/image17.png'}
                                        style={{
                                            width: 304,
                                            height: 209,
                                            backgroundSize: 'cover',
                                            objectFit: 'contain',
                                        }}
                                        alt="space-logo"
                                    />
                                </div>
                                <div className='pt-6 ml-8'>
                                    <div className='flex items-start max-w-[220px]'>
                                        <img className='mr-2 mt-2' src="/Images/location2.svg" alt="location"/>
                                        <span className='text-base font-normal'>{singleDataProvider.space.street} {singleDataProvider.space.city}, {singleDataProvider.space.zip_code} {textCountry(singleDataProvider)}</span>
                                    </div>
                                    <div className='flex items-center mt-4'>
                                        <img className='mr-2' src="/Images/phone2.svg" alt="phone" />
                                        <p className='text-base font-normal'><a href={`tel:${singleDataProvider.space.phone_number}`}>{singleDataProvider.space.phone_number}</a></p>
                                    </div>
                                    {singleDataProvider.space.email &&
                                        <div className='flex items-center mt-4'>
                                            <img className='mr-2' src="/Images/mail2.svg" alt="mail" />
                                            <a className='text-base font-normal cursor-pointer' href={`mailto:${singleDataProvider.space.email}`}>{singleDataProvider.space.email}</a>
                                        </div>
                                    }
                                    {!isMessage ?
                                        singleDataProvider.space_id !== props.currentSpace.id && isVisible &&
                                            <NavLink to={`/messages/reachout/${singleDataProvider.id}`} className="text-base font-normal cursor-pointer">
                                                <div className='flex items-center mt-4'>
                                                        <img className='mr-2' src="/Images/chat.svg" alt="chat" />
                                                        <span className='text-base font-normal  cursor-pointer text-[#6B7280]'>Chat with Dealer</span>
                                                </div>
                                            </NavLink>
                                        :   <div className='flex items-center mt-4'>
                                            <img className='mr-2' src="/Images/chat.svg" alt="chat"/>
                                            <span onClick={handleCloseUserProfileModal} className='text-base font-normal  cursor-pointer text-[#6B7280]'>Chat with Dealer</span>
                                        </div>
                                    }
                                </div>
                            </div>
						</div>
					</div>
				</div>
			</div>
		</>
	)
}
export default UserProfileModal
