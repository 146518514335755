import React, { useRef, useEffect } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react';
import { Scrollbar } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/scrollbar';
import isFunction from 'lodash/isFunction'


// const images = ["/Images/img13.png","/Images/img13.png","/Images/img13.png"];

const WatchDetailImagesMobile = ({ data, openPreviewModal}) => {
    const { images } = data;
    const swiperElRef = useRef(null);

    useEffect(() => {
        if (swiperElRef.current) {
            swiperElRef.current.swiper.update();
        }
    }
    , []);

    const handleOpenImage = () => {
        if(isFunction(openPreviewModal)) {
            openPreviewModal()
        }
        return
    }

  return (
    <div className="relative mb-4 w-3/4 mx-auto">
        <Swiper
            scrollbar={{
                hide: false,
                el: '.swiper-scrollbar',
                draggable: true,
            }}
            modules={[Scrollbar]}
            className="mySwiper"
            onClick={handleOpenImage}
        >
            {images.map((image, index) => (
                <SwiperSlide key={index}>
                    {image.type.includes('video') ? (
                        <video controls className="w-[178px] h-[270px] mx-auto object-contain">
                            <source src={image.url} type="video/mp4" />
                        </video>
                    ) : (
                        <img src={image.url} alt="watch" className="w-[178px] h-[270px] mx-auto object-contain" />
                    )}
                </SwiperSlide>
            ))}
        </Swiper>
        <div className="swiper-scrollbar"></div>
    </div>
  )
}

export default WatchDetailImagesMobile
