import {useEffect, useState} from 'react'
import {useSelector, useDispatch} from 'react-redux'
import {toggleSidebar} from './../app/appActions'
import {useNavigate} from 'react-router-dom'
import ActionCable from "actioncable";
import {connect} from 'react-redux'


import {setCurrentSpace} from '../app/appActions'
import Aged from '../Components/Main/Aged'
import MarketPlaceStat from '../Components/Main/MarketPlaceStat'
import TopSelling from '../Components/Main/TopSelling'
import TotalRevenue from '../Components/Main/TotalRevenue'
import TotalValue from '../Components/Main/TotalValue'
import WatchStatus from '../Components/Main/WatchStatus'
import Privacy from '../Components/Privacy'
import SideBar from '../Components/SideBar'
import TopBar from '../Components/TopBar'
import {Globals} from '../Context'
import isEmpty from 'lodash/isEmpty'
let CableApp = {}

const Main = (props) => {
	const dispatch = useDispatch(),
		navigate = useNavigate(),
		{isOpenSideBar, loggedin} = useSelector(state => state),
		toggleSideBar = () => {
			dispatch(toggleSidebar(!isOpenSideBar))
		}
	const [dataAverage, setDataAverage] = useState({})
	const [dataValueOnHandChart, setDataValueOnHandChart] = useState({})
	const [dataSaleCostChart, setDataSaleCostChart] = useState({})
	const [dataMarketplaceStatistics, setDataMarketplaceStatistics] = useState({})
	const [dataTopSellingItems, setDataTopSellingItems] = useState({})

	useEffect(() => {
		if (!loggedin) return navigate('/login')
	})

	const getDataAverage = async  () => {
		await Globals.New_Axios()
		.get(`${Globals.NEW_API_URL}/${Globals.ENDPOINTVERSION}/spaces/${Globals.SELECTEDSPACEID}/analytics/average`)
		.then(response => {
		}).catch(error => {
			console.log(error)
		})
	}

	const getDataValueOnHandChart = async  () => {
		await Globals.New_Axios()
		.get(`${Globals.NEW_API_URL}/${Globals.ENDPOINTVERSION}/spaces/${Globals.SELECTEDSPACEID}/analytics/value_on_hand_chart`)
		.then(response => {
		}).catch(error => {
			console.log(error)
		})
	}

	const getDataSaleCostChart = async  () => {
		await Globals.New_Axios()
		.get(`${Globals.NEW_API_URL}/${Globals.ENDPOINTVERSION}/spaces/${Globals.SELECTEDSPACEID}/analytics/sale_vs_cost_chart`)
	.then(response => {
		}).catch(error => {
			console.log(error)
		})
	}

	const getDataMarketplaceStatistics = async  () => {
		await Globals.New_Axios()
		.get(`${Globals.NEW_API_URL}/${Globals.ENDPOINTVERSION}/spaces/${Globals.SELECTEDSPACEID}/analytics/marketplace_statistics`)
		.then(response => {
		}).catch(error => {
			console.log(error)
		})
	}

	const getDataTopSellingItems = async  () => {
		await Globals.New_Axios()
		.get(`${Globals.NEW_API_URL}/${Globals.ENDPOINTVERSION}/spaces/${Globals.SELECTEDSPACEID}/analytics/top_selling_items`)
		.then(response => {
		}).catch(error => {
			console.log(error)
		})
	}

	useEffect(() => {
		getSpaceDetail()
		CableApp.cable = ActionCable.createConsumer(`${Globals.actionCableURL}?access_token=${Globals.getAccessToken()}`)

		CableApp.cable.subscriptions.create(
			{channel: `AnalyticsChannel`},
			{
				connected: () => {
					getDataAverage()
					getDataValueOnHandChart()
					getDataSaleCostChart()
					getDataMarketplaceStatistics()
					getDataTopSellingItems()
				},
				received: data => {
					if(!isEmpty(data.average)) {
						setDataAverage(data)
					} else if(!isEmpty(data.value_on_hand_chart)) {
						setDataValueOnHandChart(data)
					} else if(!isEmpty(data.sale_vs_cost_chart)) {
						setDataSaleCostChart(data)
					} else if(!isEmpty(data.market_place_statistics)) {
						setDataMarketplaceStatistics(data)
					} else if((data.top_selling_items)) {
						setDataTopSellingItems(data)
					}
				},
			}
		)
		return () => {
			CableApp?.cable.disconnect()
		}
	}, [])

	const getSpaceDetail = () => {
		return Globals.New_Axios()
			.get(`${Globals.NEW_API_URL}/${Globals.ENDPOINTVERSION}/spaces/${Globals.SELECTEDSPACEID}`)
			.then(response => {
				props.setCurrentSpace(response.data);
			})
			.catch(error => {
			})
	}

	return (
		<section className="relative">

			{loggedin && (
				<>
					<TopBar isOpen={isOpenSideBar} toggleSideBar={toggleSideBar}/>
					<div className="flex relative">
						<SideBar isOpen={isOpenSideBar} />
						<main className="flex flex-col relative w-full md:h-screen md:overflow-y-hidden">
							<div className='bg-white md:px-5 pt-12 md:h-screen md:overflow-y-scroll md:overflow-x-hidden'>
								<div className="grid xl:grid-cols-10 grid-cols-1  mt-5">
									<WatchStatus data={props?.currentSpace?.watches_counter} />
									<Aged data={dataAverage}/>
								</div>
								<div className="grid lg:grid-cols-2 mt-5">
									<TotalValue data={dataValueOnHandChart}/>
									<TotalRevenue  data={dataSaleCostChart}/>
								</div>
								<div className="grid lg:grid-cols-2 mt-5">
									<MarketPlaceStat dataList={dataMarketplaceStatistics}/>
									<TopSelling dataList={dataTopSellingItems}/>
								</div>
								<Privacy />
							</div>
						</main>
					</div>
				</>
			)}

		</section>
	)
}


const mapStateToProps = (state, props) => {
	return {
		currentSpace: state.currentSpace,
	}
}

const mapActionsToProps = {
	setCurrentSpace
}

export default connect(mapStateToProps, mapActionsToProps)(Main)
