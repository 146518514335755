import React, {useEffect, useState} from 'react'
import {Globals} from '../../../Context'
import moment from 'moment'
import isEmpty from 'lodash/isEmpty'

const Import = () => {
	const [watchesImports, setWatchesImports] = useState([])


	useEffect(() => {
		getWatchesImports()
	}, [])

	const getWatchesImports = async  (value) => {
		await Globals.New_Axios()
		.get(`${Globals.NEW_API_URL}/${Globals.ENDPOINTVERSION}/spaces/${Globals.SELECTEDSPACEID}/watch_imports?page=1&per_page=10000`)
		.then(response => {
			setWatchesImports(response.data.watch_imports)
		})
	}

	const downloadImportErrorReport = (importId) => {
		let requestHeader = {responseType: 'arraybuffer', headers: {Accept: 'text/csv'}}

		return Globals.New_Axios()
			.post(`${Globals.NEW_API_URL}/${Globals.ENDPOINTVERSION}/spaces/${Globals.SELECTEDSPACEID}/watch_imports/${importId}/download_error_report`, '', requestHeader)
			.then(async response => {
					const blob = new Blob([response.data], {type: 'text/csv'})
					const url = window.URL.createObjectURL(blob)
					const link = document.createElement('a')
					link.href = url
					link.setAttribute('download', 'CSV import error.csv')
					document.body.appendChild(link)
					link.click()
					link.parentNode.removeChild(link)
			})
	}

	return (
		<div className="relative pb-24 w-full h-full">
			<div className="h-screen ">
				<div>
					<h1 className="pt-5 pb-2 text-black lg:pt-8 lg:pb-5 text-base lg:text-2xl font-bold leading-7 pl-5">
						Import Files List
					</h1>
				</div>
				<table className="bg-white rounded-2xl text-gray-500 text-left text-sm border-[1px]">
					<thead className="border-b">
						<tr className="text-gray-500 text-sm font-bold">
							<th className="px-4 py-2 align-top font-semibold whitespace-nowrap capitalize elefta-bordered-table-x max-w-24">
								<div>Date</div>
							</th>
							<th className="px-4 py-2 align-top font-semibold whitespace-nowrap capitalize elefta-bordered-table-x w-3/5">
								<div>File</div>
							</th>
							<th className="px-4 py-2 align-top font-semibold whitespace-nowrap capitalize elefta-bordered-table-x max-w-24">
								<div>Status</div>
							</th>
						</tr>
					</thead>
					<tbody className='divide-y'>
						{!isEmpty(watchesImports) && watchesImports.map((watchImport, index) => (
							<tr key={index}>
								<td className="px-4 py-2 whitespace-nowrap elefta-bordered-table-x">
									<div>{moment(watchImport.created_at).format('MM/DD/YYYY')}</div>
								</td>
								<td className="px-4 py-2 whitespace-nowrap elefta-bordered-table-x">
									<div>
										<a className='border-none hover:text-blue-500' href={watchImport.download_file_url}>
											{watchImport.file_name}
										</a>
									</div>
								</td>
								<td className="px-4 py-2 whitespace-nowrap elefta-bordered-table-x">
									<div>
										{watchImport.total_row_invalid === 0 ? (
											<span>Success</span>
										) : (
											<>
												{watchImport.total_row_invalid === watchImport.total_row ? "Unsuccessful " : "Partial Success "}
												<button className='border-none hover:text-blue-500 underline' onClick={() => downloadImportErrorReport(watchImport.id)} >
													(Download Error File)
												</button>
											</>
										)}
									</div>
								</td>
							</tr>
						))}
					</tbody>
				</table>
			</div>
		</div>
	)
}
export default Import
