import React, {useState, useRef, useEffect} from 'react'
import classNames from 'classnames'

import {Globals} from '../../Context'
import useOutsideClick from '../../hooks/useOutsideClick'

const TermsDropdown = ({termOptions, selectedTerm, onSelect, termCustomField, handleChangeTermCustomField, termCustomSelect, handleSelectCustomTerm, className, disabled}) => {
	const [isOpen, setIsOpen] = useState(false)
	const [isOpenCustomSelect, setIsOpenCustomSelect] = useState(false)
	const [selectOptionCustom, setSelectOptionCustom] = useState([{value: 'days', label: 'Days'}, {value: 'months', label: 'Months'}, ...termOptions])
	const [focusedItemIndex, setFocusedItemIndex] = useState(-1);

	const dropdownTerms = useRef(null)

	useOutsideClick(dropdownTerms, () => setIsOpen(false))

	useEffect(() => {
		if (termCustomSelect.value !== 'days' && termCustomSelect.value !== 'months') {
			setSelectOptionCustom([...termOptions])
		} else {
			setSelectOptionCustom([{value: 'days', label: 'Days'}, {value: 'months', label: 'Months'}, ...termOptions].slice(0, -1))
		}
	}, [isOpenCustomSelect])


	const goToNextOption = (event, options) => {
		setFocusedItemIndex((prevIndex) => Math.min(prevIndex + 1, options.length -1));
		event.stopPropagation();
	}

	const goToPreviousOption = (event) => {
		setFocusedItemIndex((prevIndex) => Math.max(prevIndex - 1, 0));
		event.stopPropagation();
	}

	const handleKeyDown = (event) => {
		switch (event.key) {
			case Globals.keyboardButton.arrowDown:
				event.preventDefault();
				if(isOpenCustomSelect) {
					goToNextOption(event, selectOptionCustom)
				} else {
					goToNextOption(event, termOptions)
				}
				break;
			case Globals.keyboardButton.arrowUp:
				event.preventDefault();
				goToPreviousOption(event)
				break;
			case Globals.keyboardButton.enter:
				event.preventDefault();
				if(focusedItemIndex === -1) return
				handleEnterKeyBoard()
				break;
			default:
			break;
		}
	};

	const handleEnterKeyBoard = () => {
		if(focusedItemIndex === -1) return
		if(isOpenCustomSelect) {
			setIsOpenCustomSelect(false)
			handleSelectCustomTerm(selectOptionCustom[focusedItemIndex])
		} else {
			setIsOpen(false)
			onSelect(termOptions[focusedItemIndex])
		}
		setFocusedItemIndex(-1)
	}

	return (
		<div tabIndex="0" onKeyDown={(event) => handleKeyDown(event)} className="relative">
			{selectedTerm.value !== 'custom' && (
				<>
					<div
						onClick={() => {
							setIsOpen(!isOpen)
						}}
						className={className ? className : 'bg-white relative cursor-pointer rounded-[4px] border-[1px] border-[#5D6E81] w-[184px] text-center'}
					>
						<span className="px-2 mr-2">{selectedTerm.label}</span>
						<img src="./Images/dropdown.png" alt="" className="w-[13px] h-[20px] absolute top-0 right-[4px]" />
					</div>
					{isOpen && (
						<ul className="rounded-[5px] border-[1px] border-[#5D6E81] absolute z-[999] bottom-[-50px] bg-white min-w-[158px] text-[#5D6E81] text-[12px] right-0" ref={dropdownTerms}>
							{termOptions?.map((term, index) => {
								return (
									<li
										className={classNames("text-[#5D6E81] cursor-pointer px-[16px] py-[2px] whitespace-nowrap relative hover:bg-[#F1F2F4]",
											{
												'border-[2px] border-black': index === focusedItemIndex
											}
										)}
										key={index}
										onClick={() => {
											setIsOpen(false)
											onSelect(term)
										}}
										ref={el => {
											if (index === focusedItemIndex && el) el.focus();
										}}
									>
										{term.label}
									</li>
								)
							})}
						</ul>
					)}
				</>
			)}
			{selectedTerm.value === 'custom' && (
				<div className="flex w-[184px] justify-between">
					{termCustomSelect.value === 'days' || termCustomSelect.value === 'months' ? (
						<input
							disabled={disabled}
							value={termCustomField}
							onChange={handleChangeTermCustomField}
							className="bg-white relative cursor-pointer rounded-[4px] border-[1px] border-[#5D6E81] max-w-[94px] mr-1 px-[2px] text-center"
							type="number"
						/>
					) : (
						<></>
					)}
					<div
						onClick={() => {
							if(disabled) return
							setIsOpenCustomSelect(!isOpenCustomSelect)
						}}
						className={classNames('bg-white relative cursor-pointer rounded-[4px] border-[1px] border-[#5D6E81] text-center truncate w-[184px] pr-[10px]', {
							'w-[85px]': termCustomSelect.value === 'days' || termCustomSelect.value === 'months',
						})}
					>
						<span>{termCustomSelect.label}</span>
						<img src="./Images/dropdown.png" alt="" className="w-[13px] h-full absolute top-[-1px] right-[5px]" />
					</div>
					{isOpenCustomSelect && (
						<ul className="rounded-[5px] border-[1px] border-[#5D6E81] absolute z-[999] bottom-[-50px] bg-white max-w-[100px] text-[#5D6E81] text-[12px] right-0">
							{selectOptionCustom?.map((option, index) => {
								return (
									<>
										<li
											className={classNames("text-[#5D6E81] cursor-pointer px-[16px] py-[2px] whitespace-nowrap relative hover:bg-[#F1F2F4]" ,{
												'border-[2px] border-black': index === focusedItemIndex
											})}
											key={index}
											onClick={() => {
												setIsOpenCustomSelect(false)
												handleSelectCustomTerm(option)
											}}
											ref={el => {
												if (index === focusedItemIndex && el) el.focus();
											}}
										>
											{option.label}
										</li>
										{index === 1 && option.value === 'months' && <div className="text-[#5D6E81] px-[16px] py-[2px]">--</div>}
									</>
								)
							})}
						</ul>
					)}
				</div>
			)}
		</div>
	)
}

export default TermsDropdown
