import React, {useState, useEffect, useRef} from 'react'
import classnames from 'classnames'

import CustomerAutoSuggest from './CustomerAutoSuggest'
import {Globals} from '../../Context'
import find from 'lodash/find'
import isNil from 'lodash/isNil'
import useOutsideClick from '../../hooks/useOutsideClick'

const SelectOptionsFlat = ({name, options, selectedValue, extraValue, extraValueErrors, handleChangeSelect, handleChange, handleChangeAutoComplete, showCustomerAutoSuggest = false, isMobile}) => {
	const [showDropDown, setshow] = useState(false)
	const [showInput, setshowInput] = useState(false)
	const [selectedVal, setselectedVal] = useState(selectedValue)
	const [focusedItemIndex, setFocusedItemIndex] = useState(-1);
	const selectRef = useRef(null)
	const selectDropdownRef = useRef(null)

	useOutsideClick(selectDropdownRef, () => setshow(false));

	useEffect(() => {
		if (!['on_hand'].includes(selectedVal) && selectedVal) return setshowInput(true)
		return setshowInput(false)
	}, [selectedVal])

	const goToNextOption = (event, options) => {
		setFocusedItemIndex((prevIndex) => Math.min(prevIndex + 1, options.length - 1));
		event.stopPropagation();
	}

	const goToPreviousOption = (event) => {
		setFocusedItemIndex((prevIndex) => Math.max(prevIndex - 1, 0));
		event.stopPropagation();
	}

	const findOptionByCharacter = (event) => {
		event.preventDefault();
		const key = event?.key?.toLowerCase();
		const foundIndex = options.findIndex(option =>
			option.option.toLowerCase().startsWith(key)
		);
		setFocusedItemIndex(foundIndex);
	}

	const closeDropDown = (event) => {
		setshow(false)
		event.stopPropagation();
	}

	const handleKeyDown = (event) => {
		switch (event.key) {
			case Globals.keyboardButton.arrowDown:
				event.preventDefault();
				goToNextOption(event, options)
				break;
				case Globals.keyboardButton.arrowUp:
				event.preventDefault();
				goToPreviousOption(event)
				break;
				case Globals.keyboardButton.escape:
				event.preventDefault();
				closeDropDown(event)
				break;
			default:
				findOptionByCharacter(event)
			break;
		}
	};

	const textFieldNameMobile = () => {
		if(name === 'status') {
			return 'Status*'
		} else if(name === 'condition') {
			return 'Condition'
		} else if(name === 'warranty_papers') {
			return 'Warranty Paper/Card'
		}
	}

	const selectedOption = find(options, {value: selectedVal})

	return (
		<div className="flex flex-col" ref={selectRef}>
			<div ref={selectDropdownRef} tabIndex="0" onKeyDown={(event) => handleKeyDown(event)}  className="relative outlin w-full block">
				<ul
					onClick={() => {
						setshow(!showDropDown)
					}}
					className={classnames('text-[14px] cursor-pointer w-full bg-white border-[#D9E2EE] text-[#5D6E81] p-[14px] border-[1px] rounded-[5px] md:p-0 md:border-0 md:rounded-[px]', {
						'absolute h-48 overflow-y-scroll z-10  px-2 py-2 shadow-2xl rounded border': showDropDown,
					})}
				>
					{!showDropDown && !selectedVal && <li className={classnames({'pb-2 truncate': showDropDown})}>
							<span>{isMobile ? textFieldNameMobile() :  'Please select'}</span>
						</li>}
					{!showDropDown && (
						<li className={classnames({'pb-2 ': showDropDown})}>{selectedOption?.option || selectedValue}</li>
					)}
					{showDropDown && (
						<React.Fragment>
							{options.map((item, index) => {
								return (
									<li
										className="hover:text-gray-900 pb-4 pt-0 py-2 truncate transition px-2"
										onClick={e => {
											handleChangeSelect(e)
											setselectedVal(item.value)
										}}
										onKeyDown={(event) => {
											if(event.key === Globals.keyboardButton.enter) {
												setselectedVal(item.value)
												handleChangeSelect(event)
												setshow(false)
											}
										}}
										name={name}
										value={item.value}
										key={index}
										tabIndex={-1}
										ref={el => {
										  if (index === focusedItemIndex && el) el.focus();
										}}
									>
										{item.option ? item.option : item.value}
									</li>
								)
							})}
						</React.Fragment>
					)}
				</ul>
				<svg
					className={classnames('absolute bg-white pointer-events-none right-2 top-[18px] md:right-0 md:top-0', {
						'opacity-0': showDropDown,
					})}
					width="20"
					height="20"
					viewBox="0 0 20 20"
					fill="none"
				>
					<path
						fillRule="evenodd"
						clipRule="evenodd"
						d="M5.29289 7.29289C5.68342 6.90237 6.31658 6.90237 6.7071 7.29289L9.99999 10.5858L13.2929 7.29289C13.6834 6.90237 14.3166 6.90237 14.7071 7.29289C15.0976 7.68342 15.0976 8.31658 14.7071 8.70711L10.7071 12.7071C10.3166 13.0976 9.68341 13.0976 9.29289 12.7071L5.29289 8.70711C4.90237 8.31658 4.90237 7.68342 5.29289 7.29289Z"
						fill="#5D6E81"
					/>
				</svg>
			</div>
			{handleChangeAutoComplete && showInput && (
				<div className="w-full h-[51px] md:h-auto mt-2 md:mt-[0px]  md:-bottom-10 md:absolute md:left-0 md:p-0 md:border-0">
					{showCustomerAutoSuggest && selectedVal === 'memo_to' || showCustomerAutoSuggest && selectedVal === 'pending_sale_to' ? (
						<div className="flex relative h-full">
							<CustomerAutoSuggest
								handleChangeAutoComplete={handleChangeAutoComplete}
								extraValueErrors={extraValueErrors}
								extraValue={extraValue}
								selectedVal={selectedVal}
								handleChange={handleChange}
								isMobile={isMobile}
							/>
						</div>
					) : (
						<input
							onChange={handleChange}
							type="text"
							name="status_details"
							placeholder="Name"
							defaultValue={extraValue}
							className={`rounded-[5px] h-full px-[14px] py-[16px] w-full border-[1px] outlin
								e-blue-600 md:px-[0px] md:py-2 md:rounded-[0px] md:border-[0px] placeholder:text-[#5D6E81] placeholder:text-[14px] md:outline-black md:border-b ${!isNil(extraValueErrors) && 'border-red-500'}`}
						/>
					)}
				</div>
			)}
		</div>
	)
}

export default SelectOptionsFlat
