import React, { useRef, useEffect } from "react";

const DropdownMenu = ({label, items, containerStyles}) => {
    const wrapperRef = useRef(null);
	const [open, setOpen] = React.useState(false)

    useEffect(() => {
        /**
         * Alert if clicked on outside of element
         */
        function handleClickOutside(event) {
          if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
            setOpen(false)
          }
        }
        // Bind the event listener
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
          // Unbind the event listener on clean up
          document.removeEventListener("mousedown", handleClickOutside);
        };
      }, [wrapperRef]);


    const onClickedItem = (item) => {
        setOpen(false)
        item.onClick()
    }


	return (
		<div
            className={containerStyles}
            onClick={(e) => {
                e.stopPropagation()
                setOpen(!open)
            }}
            ref={wrapperRef}
        >
			<div>
                {label}
			</div>
            {
                open && items && items.map((item, index) => {
                    return (
                        <div id="dropdown" className="z-10 bg-white divide-y divide-gray-100 rounded-lg shadow w-44 dark:bg-gray-700 right-0 absolute" key={index} >
                            <ul className="py-2 text-sm text-gray-700 dark:text-gray-200" aria-labelledby="dropdownDefaultButton">
                                <li onClick={() => onClickedItem(item)} className="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">
                                    {item.label}
                                </li>
                            </ul>
                        </div>
                        // <div key={index} onClick={(item) => onClickedItem(item)} className='absolute'>
                        //     {item.label}
                        // </div>
                    )
                })
            }
		</div>
	)
}

export default DropdownMenu
