import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';

export const renderValidationItem = (label, isValid) => (
    <div className='mt-2 max-w-[316px]'>
        {isValid ? (
        <CheckCircleOutlinedIcon color='success' className='mr-1' />
        ) : (
        <CancelOutlinedIcon color='error' className='mr-1' />
        )}
        <span className='text-[11px]'>{label}</span>
    </div>
);