import React from 'react'
import { styled } from '@mui/material/styles';
import CloseIcon from '@mui/icons-material/Close';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import find from 'lodash/find'
import upperCase from 'lodash/upperCase'
import Stack from '@mui/material/Stack'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import Divider from '@mui/material/Divider'
import Button from '@mui/material/Button'

const CustomMobileModal = styled(Dialog)(({ theme }) => ({
    '& .MuiDialog-container': {
        height: '100%',
        display: 'flex',
        alignItems: 'flex-end',
    },
    '& .MuiDialog-paper': {
        width: '100%',
        maxWidth: '100%',
        margin: 0,
    },
    '& .MuiDialogContent-root': {
        padding: 0
	},
}));

const FilterModal = ({
    open,
    onClose,
    filterList,
    onChooseFilter,
    handleApplyFilter,
    multipleFilterOptions,
    handleClearAllFilter,
    inventoryFilters,
    changeMultipleFilterOptions,
    handleCheckedFilterValues
}) => {
    const textItem = (item) => {
        const itemColumn =find(multipleFilterOptions, (value) => {return value.key === item.key})
        if(itemColumn) {
            if(itemColumn.request.filter_values.length > 0) {
                if(itemColumn.request.filter_values.length > 2) {
                    return `${itemColumn.request.filter_values.length} Selected`
                } else {
                    return itemColumn.request.filter_values.toString()
                }
            }
        }
    }

    const handleCloseModal = () => {
        onClose()
        changeMultipleFilterOptions(inventoryFilters)
        handleCheckedFilterValues([])
    }

    return (
        <CustomMobileModal
            open={open}
            onClose={onClose}
            sx={{ '& .MuiDialog-paper': { height: '60vh', borderTopLeftRadius: '12px',  borderTopRightRadius: '12px' } }}
        >
            <DialogTitle
                id="alert-dialog-title"
                className="bg-[#4B7CBE] rounded-t-[12px]"
            >
                <div className="flex justify-between items-center">
                    <span className="font-bold text-white uppercase">Filters</span>
                    <CloseIcon className="cursor-pointer font-bold text-white" onClick={handleCloseModal} />
                </div>
            </DialogTitle>
            <DialogContent className="mt-2 !p-[1px 1px]">
                <DialogContentText id="alert-dialog-description">
                    <Stack
                        divider={<Divider sx={{ mt: 0 }} orientation="horizontal" flexItem />}
                        spacing={2}
                    >
                        {filterList.map((item, index) => {
                            return (
                                <div
                                    key={index}
                                    className="flex items-center justify-between active:bg-[#F1F1F1] px-2 rounded-[5px] cursor-pointer"
                                    onClick={onChooseFilter.bind(this, item)}
                                >
                                    <div>
                                        <div className='text-[14px] font-normal	'>{upperCase(item.label)}</div>
                                        <div className="text-[10px] text-[#2C79E0] font-normal">{textItem(item)}</div>
                                    </div>
                                    <div>
                                        <ChevronRightIcon />
                                    </div>
                                </div>
                            )
                        })}
                    </Stack>
                </DialogContentText>
            </DialogContent>
            <Stack direction="row" className="mx-auto mb-8" spacing={2}>
                <Button
                    onClick={handleClearAllFilter}
                    variant="outlined"
                    color="inherit"
                    sx={{ textTransform: 'none' }}
                    className="w-[106px] h-[40px] whitespace-nowrap normal-case"
                >
                    Clear all
                </Button>
                <Button
                    onClick={handleApplyFilter}
                    color="primary"
                    variant="contained"
                    sx={{ textTransform: 'none' }}
                    className="w-[106px] h-[40px] normal-case"
                    autoFocus
                >
                    Apply
                </Button>
            </Stack>
        </CustomMobileModal>
    )
}

export default FilterModal
