import React, {useEffect, useState, useRef} from 'react'
import Classnames from 'classnames'
import { flushSync } from 'react-dom'

import {Globals} from '../../Context'
import {focusElement, onPressEscape} from '../../utils/keyboardHandler'
import 'react-intl-tel-input/dist/main.css'
import 'react-phone-number-input/style.css'
import './AddNewCustomer.scss'
import ConfirmPopUp from '../../Components/ConfirmPopUp'
import ServerAutoSuggestAddress from '../ServerAutoSuggestAddress'

import Grid from '@mui/material/Grid'

import {
	formatPhoneNumber,
	checkAndSetDefaultAddressIfMissing,
	showAddressName,
	AddressModal,
	handleCheckCountries
} from './AddNewCustomer'
import cloneDeep from 'lodash/cloneDeep'
import findIndex from 'lodash/findIndex'
import find from 'lodash/find'
import assignIn from 'lodash/assignIn'
import isEmpty from 'lodash/isEmpty'
import isFunction from 'lodash/isFunction'
import upperFirst from 'lodash/upperFirst'
import xor from 'lodash/xor'
import toLower from 'lodash/toLower'

const initAddress = {
	company_name: '',
	contact_name: '',
	address: '',
	city: '',
	state: '',
	country: '',
	zip_code: '',
	full_address: '',
	is_billing_default: true,
	is_shipping_default: true,
	primary: true,
	_destroy: false,
	isManual: false
}

const phoneTypeListInit = [
	{label: 'Mobile', phone_type: 'mobile'},
	{label: 'Office', phone_type: 'office'},
	{label: 'Home', phone_type: 'home'},
]

const capitalizeFirstLetter = string => {
	if (typeof string !== 'string') return ''
	return upperFirst(toLower(string))
}

const renderedPhoneNumbers = (phoneNumbers) => {
	if (phoneNumbers.length === 0) {
		return [{phone_number: '+1', phone_type: 'custom'}]
	} else {
		return phoneNumbers.filter(phone => !phone._destroy)
	}
}

const EditCustomer = ({handleCloseEditForm, props, singleCustomerView, uploadCustomerListWhenEdit, handleDeleteCustomer, handleLoadCustomers, handlePrimaryTab}) => {
	const customer_addresses_list = isEmpty(singleCustomerView.customer_addresses) ? [initAddress] : singleCustomerView.customer_addresses
	const [form, setForm] = useState(singleCustomerView)
	const [formErrors, setFormErrors] = useState({})
	const [sendingData, setSendingData] = useState(false)
	const [defaultShippingAddress, setDefaultShippingAddress] = useState(true)
	const [currentAddressIndex, setCurrentAddressIndex] = useState(-1)
	const [addresses, setAddresses] = useState(customer_addresses_list)
	const [newAddresses, setNewAddresses] = useState(customer_addresses_list || [])
	const [isModalOpen, setIsModalOpen] = useState(false)
	const [phoneNumbers, setPhoneNumbers] = useState(isEmpty(singleCustomerView.customer_phone_numbers) ? [{phone_number: '+1', phone_type: 'custom'}] : singleCustomerView.customer_phone_numbers)
	const [phoneTypeList, setPhoneTypeList] = useState(phoneTypeListInit)
	const [customType, setCustomType] = useState('')
	const [isOpenDropdownPhoneType, setIsOpenDropdownPhoneType] = useState({})
	const [isCustomType, setIsCustomType] = useState(false)
	const [isManual, setIsManual] = useState(customer_addresses_list[0].is_manual || false)

	const dropdownRef = useRef(null)

	useEffect(() => {
		focusElement('.add-new-customer')

		const handleClickOutside = event => {
			if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
				setIsOpenDropdownPhoneType({})
			}
		}

		document.addEventListener('mousedown', handleClickOutside)
		return () => {
			document.removeEventListener('mousedown', handleClickOutside)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	const addressInputRef = useRef(null)

	const handleChangeGoogleAddress = (place) => {
		const tmpAddress = []

		if (!place.address_components) return

		(place.address_components || []).forEach(value => {
			if (value.types.includes('street_number') || value.types.includes('route')) {
				if (value.types.includes('street_number')) {
					tmpAddress.push(value.long_name)
				} else {
					tmpAddress.push(value.long_name)
				}
			}
			const isCheckCountries = handleCheckCountries(value)
			if(!isCheckCountries) {
				const part =  place.formatted_address.split(", ")
				tmpAddress.push(part[0])
			}
		})

		let city = place.address_components.find(component => component.types.includes('locality'))?.long_name || ''
		if(!city) {
			city = place.address_components.find(component => component.types.includes('administrative_area_level_1'))?.long_name || ''
		}

		//Note: Check Japan Country


		const searchedAddress = {
			full_address: place.formatted_address,
			address: tmpAddress?.toString().replace(',', ' ').trimStart(),
			street: place.address_components.find(component => component.types.includes('route'))?.long_name || '',
			city: city,
			state: place.address_components.find(component => component.types.includes('administrative_area_level_1'))?.short_name || '',
			country: place.address_components.find(component => component.types.includes('country'))?.long_name || '',
			zip_code: place.address_components.find(component => component.types.includes('postal_code'))?.long_name || '',
		}
		const newAddresses = cloneDeep(addresses)
		const primaryAddressIndex = findIndex(addresses, {primary: true})
		const newPrimaryAddress = assignIn({}, newAddresses[primaryAddressIndex], searchedAddress)
		newAddresses[primaryAddressIndex] = newPrimaryAddress
		const undeletedAddresses = newAddresses.filter(address => !address._destroy)
		setNewAddresses(undeletedAddresses)
		setAddresses(newAddresses)
	}


	useEffect(() => {
		addresses.map((value, index) => {
			const fullContactName = `${form.first_name} ${form.last_name}`
			const {company_name, contact_name} = value
			const isDifferentPrimaryContact = company_name !== form.company_name || contact_name !== fullContactName
			if(value.primary && !value._destroy && isDifferentPrimaryContact) {
				addresses[index].company_name = form.company_name
				addresses[index].contact_name = fullContactName
			}
		})
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [form.company_name, form.first_name, form.last_name])

	const handleChange = $event => {
		if ($event.target.name === 'phonenumber' || $event.target.name === 'secondary_shipping_phone_number') {
			setForm({
				...form,
				[$event.target.name]: formatPhoneNumber($event.target.value.trim()),
			})
		} else if ($event.target.name === 'use_company_address_as_default') {
			setDefaultShippingAddress($event.target.checked)
			setForm({
				...form,
				[$event.target.name]: $event.target.checked,
			})
		} else if ($event.target.name === 'email') {
			setForm({
				...form,
				[$event.target.name]: $event.target.value.trim(),
			})
		} else if ($event.target.name === 'company_name' && $event.target.value.trim().length > 50) {
			props.addToast({
				title: 'Add Customer',
				text: 'Company name should not exceed 50 characters',
				type: 'error',
			})
		} else {
			setForm({
				...form,
				[$event.target.name]: $event.target.value,
			})
		}
	}

	const handleBlur = $event => {
		setForm({
			...form,
			[$event.target.name]: $event.target.value.trim(),
		})
	}

	const handleSubmit = $event => {
		$event.preventDefault()
		if (sendingData) return

		let errors = []

		const requiredAddressFields = ['address', 'city', 'state', 'country', 'zip_code']
		const requiredPhoneFields = ['phone_type']

		form.customer_addresses_attributes = addresses
		form.customer_phone_numbers_attributes = phoneNumbers
		// Validate addresses
		if (addresses.length > 0) {
			const defaultBillingAddress = addresses.find(address => address.is_billing_default && !address._destroy)
			const defaultShippingAddress = addresses.find(address => address.is_shipping_default && !address._destroy)

			if (!defaultBillingAddress) {
				errors = {...errors, defaultBillingAddress: 'Default billing address is required'}
			}

			if (!defaultShippingAddress) {
				errors = {...errors, defaultShippingAddress: 'Default shipping address is required'}
			}

			addresses.forEach((value, index) => {
				if (value.primary && !value._destroy) {
					if (!value.phone_number && phoneNumbers.length > 0) {
						addresses[index].phone_number = phoneNumbers[0];
					}
				}
			});

			form.customer_addresses_attributes = addresses.map(address => ({
				...address,
				phone_number: address.phone_number
			}));

			addresses.forEach((address, index) => {
				requiredAddressFields.forEach(field => {
					if (!address[field]) {
						errors[`address_${index}_${field}`] = `${field.replace('_', ' ')} is required`
					}
				})
			})
		}
		// Validate phone numbers
		if (phoneNumbers.length > 0) {
			phoneNumbers.forEach((phone, index) => {
				requiredPhoneFields.forEach(field => {
					if (!phone[field]) {
						errors[`phone_${index}_${field}`] = `${field.replace('_', ' ')} is required`
					}
				})
			})

			const phoneNumberSubmitted = phoneNumbers.map(phone => ({...phone, _destroy: isEmpty(phone.phone_number) || phone._destroy || phone.phone_number === '+1'}))
			form.customer_phone_numbers_attributes = phoneNumberSubmitted
		}


		if(!!form.company_name) {
			form.company_name = form.company_name.trim()
		}

		setFormErrors(errors)
		if (!isEmpty(errors)) {
			return Object.values(errors)
				.reverse()
				.map(error => {
					return props.addToast({
						title: 'Cannot update customer',
						text: error,
						type: 'error',
					})
				})
		}
		if (isFunction(handlePrimaryTab)) handlePrimaryTab()
		setSendingData(true)
		return Globals.New_Axios()
			.put(`${Globals.NEW_API_URL}/${Globals.ENDPOINTVERSION}/spaces/${Globals.SELECTEDSPACEID}/customers/${form.id}`, {
				customer: {...form},
			})
			.then(response => {
				setSendingData(false)
				setForm({})
				uploadCustomerListWhenEdit(response.data)

				setTimeout(() => {
					setSendingData(false)
					setForm({})
					setFormErrors({})
					handleCloseEditForm($event)
				}, 1000)

				return props.addToast({
					title: 'Inventory Customers',
					text: 'Customer has been added successfully',
					type: 'success',
				})
			})
			.catch(error => {
				setSendingData(false)
				const errorMessages = error?.response?.data?.messages || 'Something went wrong please try again later.'
				return props.addToast({
					text: errorMessages.replaceAll("Customer phone numbers phone number", ''),
					type: 'error',
				})
			})
	}

	// Action for address

	const handleOpenModal = index => {
		setCurrentAddressIndex(index)
		setIsModalOpen(true)
	}

	const handleModalClose = () => {
		setIsModalOpen(false)
		setCurrentAddressIndex(-1)
	}

	const handleAddAdditionalAddressField = () => {
		if (!isAllAddressesValid()) {
			return props.addToast({
				text: 'Please fill out the current address before adding a new one',
				type: 'error',
			})
		}

		handleOpenModal(-1)
	}

	const onChangePrimaryAddress = (e) => {
		const newAddresses = cloneDeep(addresses)
		const primaryAddressIndex = findIndex(addresses, {primary: true})
		newAddresses[primaryAddressIndex].full_address = e.target.value
		const undeletedAddresses = newAddresses.filter(address => !address._destroy)
		setNewAddresses(undeletedAddresses)
		setAddresses(newAddresses)
	}

	const handleSaveAddress = newAddress => {
		let newAddresses = cloneDeep(addresses)
		if (newAddress.is_billing_default) {
			newAddresses.map((address, index) => {
				address.is_billing_default = false
				return address
			})
		}

		if (newAddress.is_shipping_default) {
			newAddresses.map((address, index) => {
				address.is_shipping_default = false
				return address
			})
		}

		if (currentAddressIndex > -1) {
			// Note: Add a new address
			newAddresses[currentAddressIndex] = newAddress
		} else {
			// Edit a exiting address
			newAddresses.push(newAddress)
		}


		newAddresses = checkAndSetDefaultAddressIfMissing(newAddresses)
		setAddresses(newAddresses)
		setNewAddresses(newAddresses)
		setIsModalOpen(false)
		setCurrentAddressIndex(-1)
	}

	const handleRemoveNewAddress = (addressIndex) => {
		if (addressIndex < 0) {
			// Note: While adding new and then user cancel
			return
		}

		let newAddresses = cloneDeep(addresses)
		newAddresses[addressIndex]['_destroy'] = true
		newAddresses = checkAndSetDefaultAddressIfMissing(newAddresses, addressIndex)
		const undeletedAddresses = newAddresses.filter(address => !address._destroy)
		setNewAddresses(undeletedAddresses)
		setAddresses(newAddresses)
	}

	// Action for phone number

	const handleAddPhoneNumberField = () => {
		const selectedTypes = renderedPhoneNumbers(phoneNumbers).map(phone => phone.phone_type)
		const phoneTypes = phoneTypeList.map(phoneType => phoneType.phone_type)

		let availablePhoneTypes = xor(phoneTypes, selectedTypes);
		let newType = availablePhoneTypes[0] || 'custom'

		setPhoneNumbers([...phoneNumbers, {phone_number: '+1', phone_type: newType}])
	}

	const handleRemovePhoneNumberField = index => {
		const updatedPhoneNumbers = cloneDeep(phoneNumbers)
		updatedPhoneNumbers[index]['_destroy'] = true

		const sortedPhoneNumbers = updatedPhoneNumbers.sort((a, b) =>
			a._destroy === b._destroy ? 0 : a._destroy ? 1 : -1
		);

		setPhoneNumbers(sortedPhoneNumbers)
	}

	const handlePhoneTypeChange = (index, type, label) => {
		let updatedPhoneNumbers = []
		if (type === 'custom') {
			updatedPhoneNumbers = phoneNumbers.map((phone, i) => (i === index ? {...phone, phone_type: type, phone_custom_type: label} : phone))
		} else {
			updatedPhoneNumbers = phoneNumbers.map((phone, i) => (i === index ? {...phone, phone_type: type} : phone))
		}
		setPhoneNumbers(updatedPhoneNumbers)
		setIsOpenDropdownPhoneType(false)
		setIsCustomType(false)
	}

	const handlePhoneNumberChange = (index, event) => {
		const input = event.target.value
		const updatedPhoneNumbers = cloneDeep(phoneNumbers)
		let formattedNumber = formatPhoneNumber(input)
		updatedPhoneNumbers[index].phone_number = formattedNumber
		const currentSection = event.target.selectionStart
		let newSection = formattedNumber.length - (input.length - currentSection)
		if(input.length  <  formattedNumber.length && formattedNumber.length === 6) {
			newSection = formattedNumber.length + 2
		}

		flushSync(() => {
			setPhoneNumbers(updatedPhoneNumbers)
		});

		event.target.setSelectionRange(newSection, newSection)
	}

	const handleAddCustomType = (index) => {
		const newCustomType = {label: customType, phone_type: 'custom'}
		setIsCustomType(false)
		setCustomType('')
		setPhoneTypeList(pre => {
			const newPhoneTypeList = [...pre]
			newPhoneTypeList.push(newCustomType)
			return newPhoneTypeList
		})
		handlePhoneTypeChange(index, newCustomType.phone_type, newCustomType.label)
	}

	const handleCancelCustomType = index => {
		setIsCustomType(false)
		setCustomType('')
		setIsOpenDropdownPhoneType(prevState => ({...prevState, [index]: false}))
	}

	const toggleDropdownPhoneType = index => {
		setIsOpenDropdownPhoneType(prevState => ({...prevState, [index]: !prevState[index]}))
	}

	const isAllAddressesValid = () => {
		const emptyAddresses = addresses.filter(item => !item.address && !item._destroy)
		return emptyAddresses.length === 0
	}

	const unselectedPhoneTypeList = () => {
		return phoneTypeList.filter((item) => {
			return (item.phone_type !== "custom" && !phoneNumbers.some((phoneNumber) => phoneNumber.phone_type === item.phone_type)) ||
					(item.phone_type === "custom" && !phoneNumbers.some((phoneNumber) => phoneNumber.phone_custom_type === item.phone_label))
		})
	}

	const handleVisibleManualForm = () => {
		addresses[0] = {
			...addresses[0],
			is_manual: !isManual
		}
		setIsManual(!isManual)
	}

	const handleChangeAddress = event => {
		event.preventDefault()
		let newAddresses =cloneDeep(addresses)[0]
		newAddresses = {
			...newAddresses,
			[event.target.name]: event.target.value,
		}
		addresses[0] = newAddresses
		setAddresses([...addresses])
		setNewAddresses([...addresses])
	}

	return (
		<>
			<div
				onKeyDown={onPressEscape(event => handleCloseEditForm(event))}
				className="add-new-customer bg-black/50 bottom-0 fixed flex items-center justify-center left-0 right-0 top-0 z-50 md:grid overflow-hidden"
				tabIndex="-1"
			>
				<div className="max-w-[670px] w-full h-screen flex flex-col justify-center">
				<div className="w-full mt-auto md:mt-[unset]">
						<div className="bg-[#4B7CBE] md:bg-[#5D6E81] flex items-center px-8 py-4 relative rounded-t-xl text-white w-full md:h-[67px]">
							<span className="text-white font-bold text-[24px]">Edit Customer</span>
							<button
								className="absolute right-0 mr-5 cursor-pointer"
								onClick={e => {
									handleCloseEditForm(e)
								}}
							>
								<svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
									<path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
								</svg>
							</button>
						</div>
						<div className="h-[80vh]  bg-white text-gray-500 rounded-b-xl md:px-[28px] max-h-[700px]  px-8 md:py-[unset] overflow-y-scroll">
							<form onSubmit={handleSubmit} className="bg-white mt-[26px]">
								{/* Company Information */}
								<h3 className="mt-[16px] mb-[16px] text-[20px] font-semibold text-[#5D6E81]">Company Information</h3>
								<Grid container columnSpacing={1.5} rowSpacing={1.5}>
									<Grid item xs={12}>
										<div className="">
											<div className="outlin relative w-full h-[49px]">
												<input
													defaultValue={form?.company_name}
													value={form?.company_name}
													type="text"
													name="company_name"
													placeholder=" "
													className="block px-[12px] pt-5 pb-3 w-full h-[49px] inputLogin appearance-none outline-none text-sm border-[#D9E2EE] text-[#5D6E81] !font-semibold text-[14px]"
													onChange={handleChange}
													onBlur={handleBlur}
												/>
												<label className="pointer-events-none absolute top-0 bg-transparent p-[12px] duration-300 origin-0 font-semibold text-[#D1D3D4]">Company Name</label>
											</div>
										</div>
									</Grid>
									<Grid item xs={12} sm={6}>
										<div className="">
											<div className="outlin relative w-full h-[49px]">
												<input
													defaultValue={form?.first_name}
													value={form?.first_name}
													type="text"
													name="first_name"
													placeholder=" "
													className="block px-[12px] pt-5 pb-3 w-full h-[49px] inputLogin appearance-none outline-none border-[#D9E2EE] text-[#5D6E81] !font-semibold text-[14px]"
													onChange={handleChange}
													onBlur={handleBlur}
												/>
												<label className="pointer-events-none absolute top-0 bg-transparent p-[12px] duration-300 origin-0 font-semibold text-[#D1D3D4]">First Name</label>
											</div>
										</div>
									</Grid>
									<Grid item xs={12} sm={6}>
										<div className="">
											<div className="outlin relative w-full h-[49px]">
												<input
													defaultValue={form?.last_name}
													value={form?.last_name}
													type="text"
													name="last_name"
													placeholder=" "
													className="block px-[12px] pt-5 pb-3 w-full h-[49px] inputLogin appearance-none outline-none text-[#5D6E81] !font-semibold text-[14px] border-[#D9E2EE]"
													onChange={handleChange}
													onBlur={handleBlur}
												/>
												<label className="pointer-events-none absolute top-0 bg-transparent p-[12px] duration-300 origin-0 font-semibold text-[#D1D3D4]">Last Name</label>
											</div>
										</div>
									</Grid>
									<Grid item xs={12} sm={6}>
										<div className="">
											<div className="outlin relative w-full h-[49px]">
												<input
													defaultValue={form?.email}
													value={form?.email}
													type="text"
													name="email"
													placeholder=" "
													className="block px-[12px] pt-5 pb-3 w-full h-[49px] inputLogin appearance-none outline-none text-[#5D6E81] !font-semibold text-[14px] border-[#D9E2EE]"
													onChange={handleChange}
												/>
												<label className="pointer-events-none absolute top-0 bg-transparent p-[12px] duration-300 origin-0 font-semibold text-[#D1D3D4]">Email</label>
											</div>
										</div>
									</Grid>
									<Grid item xs={12} sm={6}>
										<div className="">
											<div className="outlin relative w-full h-[49px]">
												<input
													defaultValue={form?.certificate}
													value={form?.certificate}
													type="text"
													name="certificate"
													placeholder=" "
													className="block px-[12px] pt-5 pb-3 w-full h-[49px] inputLogin appearance-none outline-none text-[#5D6E81] !font-semibold text-[14px] border-[#D9E2EE]"
													onChange={handleChange}
													onBlur={handleBlur}
												/>
												<label className="pointer-events-none absolute top-0 bg-transparent p-[12px] duration-300 origin-0 font-semibold text-[#D1D3D4]">Resale Certificate</label>
											</div>
										</div>
									</Grid>

									{renderedPhoneNumbers(phoneNumbers).map((phoneNumber, index) => {
										const originalIndex = phoneNumbers.findIndex(phone => phone === phoneNumber);

										return (
											<Grid key={index} item xs={12} sm={index > 1 ? 12 : 6} sx={{display: 'flex', justifyContent: index > 1 ? 'flex-end' : 'flex-start'}}>
												<div className={`w-full flex ${index > 1 && "md:!w-1/2 md:pl-[6px]"}`} >
													<div className="outlin relative h-[49px] flex-grow">
														<input
															value={formatPhoneNumber(phoneNumber?.phone_number)}
															type="text"
															name={`phone_number_${index}`}
															placeholder=" "
															className="block pl-[12px] pr-[70px] pt-[12px] pb-3 w-full h-[49px] inputLogin appearance-none outline-none text-[#5D6E81] !font-semibold text-[14px] border-[#D9E2EE]"
															onChange={event => handlePhoneNumberChange(originalIndex, event)}
														/>
														<label className="pointer-events-none absolute top-0 bg-transparent p-[12px] duration-300 origin-0 font-semibold text-[#D1D3D4]">Phone Number</label>
														<div className="absolute top-0 right-0" onClick={() => toggleDropdownPhoneType(index)}>
															<div className="h-[49px] relative flex items-center cursor-pointer">
																<div className="text-[14px] text-[#5D6E81BF] font-semibold">
																	{phoneNumber?.phone_type === 'custom' ? phoneNumber.phone_custom_type : capitalizeFirstLetter(phoneNumber?.phone_type)}
																</div>
																<img src="./Images/arrow_up_down.png" alt="" />
															</div>
														</div>
														{isOpenDropdownPhoneType[index] && (
															<div
																ref={dropdownRef}
																className="absolute top-[49px] right-0 w-auto max-w-[400px] bg-white border-[#D9E2EE] border-t-0 rounded-b-[12px] shadow-md z-10"
															>
																{unselectedPhoneTypeList().map((phoneType, i) => (
																	<div
																		key={i}
																		className="py-2 px-4 text-[14px] text-[#5D6E81] font-semibold cursor-pointer hover:bg-[#F7F8FA]"
																		onClick={() => handlePhoneTypeChange(originalIndex, phoneType.phone_type, phoneType.label)}
																	>
																		{phoneType.label}
																	</div>
																))}
																{isCustomType ? (
																	<div className="relative">
																		<input
																			value={customType}
																			type="text"
																			name={customType?.toLowerCase()}
																			placeholder=""
																			className="py-2 pl-4 pr-[66px] h-[35px] text-[14px] text-[#5D6E81] font-semibold border-t"
																			onChange={event => setCustomType(event.target.value)}
																			onKeyDown={(e) => (e.key === Globals.keyboardButton.enter && handleAddCustomType(index))}
																		/>
																		<div className="absolute h-full top-0 right-0 cursor-pointer flex">
																			<span className="p-2" onClick={() => handleAddCustomType(originalIndex)}>
																				<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" className="w-[16px] h-[16px]">
																					<path
																						fill="#005c0f"
																						d="M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L160 338.7 393.4 105.4c12.5-12.5 32.8-12.5 45.3 0z"
																					/>
																				</svg>
																			</span>
																			<span className="p-2" onClick={() => handleCancelCustomType(index)}>
																				<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512" className="w-[16px] h-[16px]">
																					<path
																						fill="#595959"
																						d="M376.6 84.5c11.3-13.6 9.5-33.8-4.1-45.1s-33.8-9.5-45.1 4.1L192 206 56.6 43.5C45.3 29.9 25.1 28.1 11.5 39.4S-3.9 70.9 7.4 84.5L150.3 256 7.4 427.5c-11.3 13.6-9.5 33.8 4.1 45.1s33.8 9.5 45.1-4.1L192 306 327.4 468.5c11.3 13.6 31.5 15.4 45.1 4.1s15.4-31.5 4.1-45.1L233.7 256 376.6 84.5z"
																					/>
																				</svg>
																			</span>
																		</div>
																	</div>
																) : (
																	<div className="py-2 px-4 text-[14px] text-[#5D6E81] font-semibold cursor-pointer hover:bg-[#F7F8FA]" onClick={() => setIsCustomType(true)}>
																		Custom
																	</div>
																)}
															</div>
														)}
													</div>
													<button className="ml-2" type="button" onClick={() => (index !== 0 ? handleRemovePhoneNumberField(originalIndex) : handleAddPhoneNumberField())}>
														<img src={`/Images/${index !== 0 ? 'minus_circle' : 'plus_circle'}.png`} alt="" height={23} width={26} />
													</button>
												</div>
											</Grid>
										)
									})}
								</Grid>

								{/* Address */}
								<h3 className="mt-[30px] mb-[16px] text-[20px] font-semibold text-[#5D6E81]">Address</h3>
								{addresses.map((address, index) => {
									if (!address._destroy) {
										return(
											<div className="w-full flex mt-[10px]" key={index}>
												<div className="outlin relative h-auto flex-grow">
													{!isManual ?
														<>
															<Grid container columnSpacing={1.5} rowSpacing={1.5}>
																<Grid item xs={12} sm={9}>
																	<ServerAutoSuggestAddress
																		formData={{full_address: showAddressName(form, address) || ''}}
																		handleChangeGoogleAddress={handleChangeGoogleAddress}
																		className={'block pl-[12px] pr-[130px] pt-5 pb-3 w-full h-[49px] inputLogin appearance-none outline-none text-[#5D6E81] !font-semibold text-[14px] border-[#D9E2EE] nowrap truncate overflow-hidden hover:text-clip'}
																		view={'customer'}
																	/>
																</Grid>
																
															</Grid>
															{newAddresses.length > 1 &&
																<>
																	<input
																		onClick={() => newAddresses.length > 1 && handleOpenModal(index)}
																		className={`absolute top-[0] block pl-[12px] pr-[130px] pt-5 pb-3 w-full h-[49px] inputLogin appearance-none outline-none text-[#5D6E81] !font-semibold text-[14px] border-[#D9E2EE] nowrap truncate overflow-hidden ${ !address.primary ? 'hover:text-clip' : '' } cursor-pointer`}
																		readOnly={true}
																		value={showAddressName(form, address) || ''}
																	/>
																	<label className="pointer-events-none absolute top-0 bg-transparent p-[12px] duration-300 origin-0 font-semibold text-[#D1D3D4]">Address</label>
																</>
															}
															<div>
																{index == 0 &&
																	<span
																		onClick={handleVisibleManualForm}
																		className='text-xs mt-[1px] cursor-pointer underline'
																	>
																		Add Manually
																	</span>
																}
															</div>
														</>
														:
														index === 0 ?
														<Grid container columnSpacing={1.5} rowSpacing={1.5}>
															<Grid item xs={12} sm={9}>
																<div className="">
																	<div className="outlin relative w-full h-[49px]">
																		<input
																			type="text"
																			name={'address'}
																			defaultValue={address?.address}
																			placeholder=" "
																			className="block px-[12px] pt-5 pb-3 w-full h-[49px] inputLogin appearance-none outline-none border-[#D9E2EE] text-[#5D6E81] !font-semibold text-[14px]"
																			onChange={handleChangeAddress}
																		/>
																		<label className="pointer-events-none absolute top-0 bg-transparent p-[12px] duration-300 origin-0 font-semibold text-[#D1D3D4]">Street *</label>
																	</div>
																	<span
																		onClick={handleVisibleManualForm}
																		className='text-xs mt-[1px] cursor-pointer underline'
																	>
																		Use Google autocomplete'
																	</span>
																</div>
															</Grid>
															<Grid item xs={12} sm={3}>
																<div className="">
																	<div className="outlin relative w-full h-[49px]">
																	<input
																		type="text"
																		name="apt_unit_number"
																		placeholder=" "
																		defaultValue={address?.apt_unit_number || ''}
																		onChange={handleChangeAddress}
																		className="block px-[12px] pt-5 pb-3 w-full h-[49px] inputLogin appearance-none outline-none border-[#D9E2EE] text-[#5D6E81] !font-semibold text-[14px]"
																	/>
																	<label className="pointer-events-none absolute top-0 bg-transparent p-[12px] duration-300 origin-0 font-semibold text-[#D1D3D4]">
																		Apt/Unit #
																	</label>
																	</div>
																</div>
														    </Grid>
															<Grid item xs={12} sm={6}>
																<div className="">
																	<div className="outlin relative w-full h-[49px]">
																		<input
																			defaultValue={address?.city}
																			type="text"
																			name="city"
																			placeholder=" "
																			className="block px-[12px] pt-5 pb-3 w-full h-[49px] inputLogin appearance-none outline-none border-[#D9E2EE] text-[#5D6E81] !font-semibold text-[14px]"
																			onChange={handleChangeAddress}
																		/>
																		<label className="pointer-events-none absolute top-0 bg-transparent p-[12px] duration-300 origin-0 font-semibold text-[#D1D3D4]">City *</label>
																	</div>
																</div>
															</Grid>
															<Grid item xs={12} sm={6}>
																<div className="">
																	<div className="outlin relative w-full h-[49px]">
																		<input
																			defaultValue={address.state}
																			type="text"
																			name="state"
																			placeholder=" "
																			className="block px-[12px] pt-5 pb-3 w-full h-[49px] inputLogin appearance-none outline-none text-[#5D6E81] !font-semibold text-[14px] border-[#D9E2EE]"
																			onChange={handleChangeAddress}
																		/>
																		<label className="pointer-events-none absolute top-0 bg-transparent p-[12px] duration-300 origin-0 font-semibold text-[#D1D3D4]">State *</label>
																	</div>
																</div>
															</Grid>
															<Grid item xs={12} sm={6}>
																<div className="">
																	<div className="outlin relative w-full h-[49px]">
																		<input
																			defaultValue={address?.zip_code}
																			type="text"
																			name="zip_code"
																			placeholder=" "
																			className="block px-[12px] pt-5 pb-3 w-full h-[49px] inputLogin appearance-none outline-none text-[#5D6E81] !font-semibold text-[14px] border-[#D9E2EE]"
																			onChange={handleChangeAddress}
																		/>
																		<label className="pointer-events-none absolute top-0 bg-transparent p-[12px] duration-300 origin-0 font-semibold text-[#D1D3D4]">Zip Code *</label>
																	</div>
																</div>
															</Grid>
															<Grid item xs={12} sm={6}>
																<div className="">
																	<div className="outlin relative w-full h-[49px]">
																		<select
																			id="country"
																			name="country"
																			className="block px-[12px] w-full h-[49px] inputLogin appearance-none outline-none text-[#5D6E81] !font-semibold text-[14px] border-[#D9E2EE]"
																			defaultValue={find(Globals.countries, (value) => value.name === address?.country)?.code || ''}
																			onChange={handleChangeAddress}
																		>
																			<option value="" disabled>
																				Choose a country *
																			</option>
																			{Globals.countries.map(c => (
																				<option key={c.code} value={c.code}>
																					{c.name}
																				</option>
																			))}
																		</select>
																	</div>
																</div>
															</Grid>
														</Grid> :
														<input
															value={showAddressName(form, address) || ''}
															type="text"
															name={`address_${index}`}
															onClick={() => !address.primary && handleOpenModal(index)}
															placeholder=" "
															readOnly={!address.primary}
															className="block pl-[12px] pr-[130px] pt-5 pb-3 w-full h-[49px] inputLogin appearance-none outline-none text-[#5D6E81] !font-semibold text-[14px] border-[#D9E2EE] nowrap truncate overflow-hidden hover:text-clip"
															onChange={address.primary ? onChangePrimaryAddress : handleChange}
														/>
													}
													<>
														<div className={`absolute top-[5px] right-[${isManual ? '160' : '10'}px] flex flex-col items-center min-w-[112px] leading-normal`}>
															{address.is_billing_default || address.is_shipping_default ? (
																<>
																	<div className="font-bold text-[10px] nowrap text-[#D1D3D4]">Default</div>
																	<div className="font-bold text-[12px] nowrap text-[#5D6E81]">
																		{address.is_billing_default && address.is_shipping_default ? 'Billing & Shipping' : address.is_billing_default ? 'Billing' : 'Shipping'}
																	</div>
																</>
															) : null}
														</div>
													</>
												</div>
											</div>
										)
									}

								})}

								{/* Add New Address Modal */}
								{
									isModalOpen && (
										<AddressModal
											formData={form}
											setForm={setForm}
											currentAddress={addresses[currentAddressIndex]}
											currentAddressIndex={currentAddressIndex}
											open={isModalOpen}
											handleClose={handleModalClose}
											onSaveAddress={handleSaveAddress}
											onRemoveNewAddress={handleRemoveNewAddress}
											addToast={props.addToast}
											phoneNumbers={phoneNumbers}
											handlePhoneNumberChange={handlePhoneNumberChange}
											addresses={addresses}
										/>
									)
								}
								<div
									className="flex items-center text-[#5D6E81BF] text-[12px] font-semibold underline underline-offset-2 mt-[11px] ms-[21px] cursor-pointer w-auto"
									onClick={handleAddAdditionalAddressField}
								>
									Add Additional Address
								</div>
								<div className={Classnames('flex flex-row justify-center space-x-4 mt-[20px]', {'pt-8': defaultShippingAddress})}>
								</div>
								<div className={Classnames('flex flex-row justify-center space-x-4 mb-[20px]', {'pt-8': !defaultShippingAddress})}>
									<button
										type="button"
										className="border-[1px] border-[#FF000080] cursor-pointer text-[#FF000080] rounded px-[14px] py-[12px] w-[93px] h-[49px] hover:bg-[#FF000080] hover:text-white font-semibold"
										onClick={e => {
											handleCloseEditForm(e)
										}}
									>
										Cancel
									</button>
									{(props.profile.role === "owner" || props.profile.role === "admin") && (
										<ConfirmPopUp
											onConfirm={handleDeleteCustomer}
											title="Confirm deleting customer"
											content={
												<div>
													<div>Are you sure you want to delete this customer? This cannot be undone.</div>
													<div className="bg-gray-100 my-2 px-2 py-3 rounded-md text-gray-500 text-sm">
														<svg
															aria-hidden="true"
															className="flex-shrink-0 h-5 inline mr-1 w-5"
															fill="currentColor"
															viewBox="0 0 20 20"
															xmlns="http://www.w3.org/2000/svg"
														>
															<path
																fillRule="evenodd"
																d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z"
																clipRule="evenodd"
															></path>
														</svg>
														<span className="font-medium">Notice some of the customer information may not be removed.</span>
													</div>
												</div>
											}
										>
											<button type="button" className="border-2 border-red-500 cursor-pointer leading-5 px-[14px] py-[12px] w-[93px] h-[49px] rounded-md text-white bg-red-500 font-semibold hover:bg-red-600">
												Delete
											</button>
										</ConfirmPopUp>
									)}
									<button className="bg-[#4B7CBE] text-white rounded px-[14px] py-[12px] w-[93px] h-[49px] hover:bg-[#2C79E0] hover:text-white font-semibold" type="submit">
										Save
									</button>
								</div>
							</form>
						</div>
					</div>
				</div>
			</div>
		</>
	)
}

export default EditCustomer
