import React, {useEffect, useRef, useState} from 'react';
import Classnames from 'classnames';
import {connect} from 'react-redux'
import SearchIcon from '@mui/icons-material/Search';
import CheckIcon from '@mui/icons-material/Check';

import useOutsideClick from '../../hooks/useOutsideClick';
import { getConditionLabel } from '../../utils/LabelsHelper'
import PriceSlider from '../../Components/Marketplace/PriceSlider'
import cloneDeep from 'lodash/cloneDeep'
import findIndex from 'lodash/findIndex'
import compact from 'lodash/compact'
import uniq from 'lodash/uniq'
import filter from 'lodash/filter'
import indexOf from 'lodash/indexOf'
import sortBy from 'lodash/sortBy'
import find from 'lodash/find'
import startCase from 'lodash/startCase'
import {Globals} from '../../Context'

const defaultFiltersConfigData = [
    { id: 'brand', label: 'Brand', options: [] },
    { id: 'reference_number', label: 'Reference Number', options: [] },
    { id: 'dial', label: 'Dial', options: [] },
    { id: 'bracelet', label: 'Bracelet', options: [] },
    { id: 'condition', label: 'Condition', options: ['Unworn', 'Like New', 'Pre-Owned'] },
    { id: 'box_papers', label: 'Box & Papers', options: ['None', 'Box', 'Papers', 'Box & Papers'] },
    { id: 'wholesale_price', label: 'Price', options: [] },
];

const FilterOption = React.memo(({ item, selected, onSelect, viewGrid }) => (
  <div
    className={Classnames('flex justify-between items-center px-3 py-1 cursor-pointer text-[14px] hover:bg-slate-100', {
      'text-[#2C79E0]': selected
    })}
    onClick={() => onSelect(item)}
  >
    <span className={Classnames({'text-[#5D6E81]': !viewGrid})}>{getConditionLabel(item)}</span>
    {selected && <CheckIcon sx={{ color: "#2C79E0", fontSize: '18px', fontWeight: '700' }} />}
  </div>
));

const FilterDropdown = ({ id, label, options, filterDataSelected, onChangeFilterData, currentDropdownFilter, handleSetCurrentDropdownFilter, viewType }) => {
  const isActive = currentDropdownFilter === id;
  const isFiltering = filterDataSelected[id]?.length > 0;
  const viewGrid = viewType !== 'card';
  const dropdownRef = useRef(null);
  const [keySearchInDropdown, setKeySearchInDropdown] = useState('')
  const [filteredOptions, setFilteredOptions] = useState(options)
  useOutsideClick(dropdownRef, () => {
    if (isActive) {
      handleSetCurrentDropdownFilter('');
      setKeySearchInDropdown('')
    }
  });

  const handleSearchInFilter = (e) => {
    setKeySearchInDropdown(e.target.value)
  }

  const handleSelectOption = (option) => {
    onChangeFilterData(id, option);
    handleSetCurrentDropdownFilter('');
  };

  useEffect(() => {
    if(keySearchInDropdown === '') {
      setFilteredOptions(options)
      return
    } else {
      setFilteredOptions(options.filter(option => option?.toLowerCase().includes(keySearchInDropdown?.toLowerCase())))
    }
  } , [keySearchInDropdown, options])

  return (
    <>
      {
        id !== 'wholesale_price' &&
        <div className="relative min-w-[145px] xl:w-7/12" ref={dropdownRef}>
          <div
            className={Classnames('cursor-pointer text-sm border h-[34px] rounded flex justify-between items-center p-3', {
              ' text-[#2C79E0] border-[#2C79E0]': isActive,
              'bg-transparent text-[#5D6E81]': !isActive && viewGrid,
              'border-[#2C79E0] bg-[#fff]': isFiltering && !isActive,
              'border-[#5D6E81] bg-[#fff] text-[#5D6E81]': !isFiltering && !isActive && !viewGrid
            })}
            onClick={() => {
              handleSetCurrentDropdownFilter(isActive ? '' : id)
              setFilteredOptions(options)
            }}
          >
            <span
              className={Classnames('', {
                'text-[#2C79E0]': isActive || isFiltering && !isActive,
              })}
            >{label}</span>
              <svg
                className={Classnames('', {
                  'text-[#2C79E0]': isActive,
                })}
                xmlns="http://www.w3.org/2000/svg" width="12" height="5" viewBox="0 0 12 5" fill="none">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M1.23888 0.304635C1.66676 0.0312677 2.36048 0.0312677 2.78836 0.304635L6.39622 2.60966L10.0041 0.304635C10.432 0.0312676 11.1257 0.0312676 11.5536 0.304635C11.9814 0.578002 11.9814 1.02122 11.5536 1.29458L7.17096 4.09458C6.74309 4.36795 6.04936 4.36795 5.62148 4.09458L1.23888 1.29458C0.810999 1.02122 0.810999 0.578002 1.23888 0.304635Z" fill={isActive ? "#2C79E0" : "#5D6E81"}/>
              </svg>
          </div>
          {isActive && (
            <div className={Classnames("absolute lg:w-[100%] right-0 bg-white border border-1 rounded-[5px] z-10", {'border-[1px] border-[#2C79E0]':  !viewGrid})}>
              <div className={Classnames("relative", {'border-b-[1px] border-b-[#2C79E0]': !viewGrid})}>
                <SearchIcon className={Classnames("absolute top-1/2 left-3 transform -translate-y-1/2 rotate-90", {'text-[#5D6E81]':  !viewGrid})} />
                <input
                  type="text"
                  placeholder="Search here..."
                  className="w-full border border-1 h-[34px] rounded-[5px] pl-10 pr-2 text-[14px]"
                  onChange={handleSearchInFilter}
                />
              </div>

              <div className="max-h-[160px] overflow-y-auto">
                {filteredOptions.map((filteredOption, index) => (
                  <FilterOption
                    key={index}
                    item={filteredOption}
                    selected={filterDataSelected[id].includes(filteredOption)}
                    onSelect={handleSelectOption}
                    viewGrid={viewGrid}
                  />
                ))}
              </div>
            </div>
          )}
        </div>
      }
    </>
  );
};

const FilterMarketplace = ({ onChangeFilterData, currentDropdownFilter, handleSetCurrentDropdownFilter, viewType, marketplaceFilters, filterData, firstLoadFilterData, isShowSliderPriceFilter, handleShowPriceSlider, globalColumnConfigs}) => {
  const [filtersConfigData, setFiltersConfigData] = useState(defaultFiltersConfigData);
  const [minPrice, setMinPrice] = useState(0);
  const [maxPrice, setMaxPrice] = useState(0);
  const handleChangeFilters = (id, selectedValue) => {
    onChangeFilterData(id, selectedValue);
  }
  const isActive = currentDropdownFilter === 'price';
  const isFiltering = marketplaceFilters.filterDataSelected['wholesale_price']?.length > 0;

  useEffect(() => {
    if (filterData) {
      let previousFilterData = cloneDeep(firstLoadFilterData)
      let newFiltersConfigData = cloneDeep(filtersConfigData)
      marketplaceFilters.filterPriorities.forEach((filterItem) => {
          const options = cleanupOptions(filterItem, previousFilterData)
          const index = findIndex(newFiltersConfigData, { id: filterItem })
          const newfilterData = newFiltersConfigData[index]
          newFiltersConfigData[index].options = options
          newFiltersConfigData[index] = newfilterData

          previousFilterData = filter(previousFilterData, (item) => {
            switch(filterItem) {
              case 'condition':
                return marketplaceFilters.filterDataSelected[filterItem].includes(getConditionLabel(item))
              case 'box_papers':
                return marketplaceFilters.filterDataSelected[filterItem].includes(item.box && (item.warranty_papers && item.warranty_papers.toLowerCase() !== 'no') ? 'Box & Papers' : item.box ? 'Box' : item.warranty_papers && item.warranty_papers.toLowerCase() !== 'no' ? 'Papers' : 'None')
              default:
                return marketplaceFilters.filterDataSelected[filterItem].includes(item[filterItem])
            }
          })
      })
      filtersConfigData.forEach((item) => {
        if (indexOf(marketplaceFilters.filterPriorities, item.id) > -1) {
          return
        }

        const index = findIndex(newFiltersConfigData, { id: item.id })
        const options = cleanupOptions(item.id, filterData)
        const sortOptions =  sortBy(options, [(value)=> { return value; }])
        if(item.id === 'wholesale_price') {
          setMinPrice(sortOptions[0])
          setMaxPrice(sortOptions[sortOptions.length - 1])
        }
        newFiltersConfigData[index].options =  sortOptions
      })

      setFiltersConfigData(newFiltersConfigData);
    }
  }, [filterData, marketplaceFilters.filterPriorities, firstLoadFilterData]);

  const cleanupOptions = (id, data) => {
    switch (id) {
      case 'brand':
        data.map(data => {
          if (!!Globals.watchBrands[data.brand]) {
            data.brand = Globals.watchBrands[data.brand]
          }

          data.brand = startCase(data.brand.toLowerCase())
        })

        return compact(uniq(data.map(data => data.brand))).sort()
      case 'condition':
        return compact(uniq(data.map(data => {
            switch (data.condition) {
              case "unworn": return "Unworn";
              case "like_new": return "Like New";
              case "pre_owned":
              case "good":
              case "very_good":
              case "excellent":
              case "fair": return "Pre-Owned";
              default: return data.condition;
            }
          }))).sort()

      case 'box_papers':
        const boxPapersOptions = [
          data.some(data => data.box && (data.warranty_papers && data.warranty_papers.toLowerCase() !== 'no')) ? 'Box & Papers' : null,
          data.some(data => data.box) ? 'Box' : null,
          data.some(data => (data.warranty_papers && data.warranty_papers.toLowerCase() !== 'no')) ? 'Papers' : null,
          data.some(data => (!data.warranty_papers || data.warranty_papers.toLowerCase() === 'no')) ? 'None' : null
        ];
        return compact(boxPapersOptions)
      default:
        return compact(uniq(data.map(data => data[id]))).sort()
    }
  }

  return (
    <>
      <div className={Classnames('flex w-full my-[15px] xl:justify-around gap-[15px] px-[20px] flex-wrap xl:flex-nowrap')}>
        {filtersConfigData.map(filter => {
          return (
            <FilterDropdown
              key={filter.id}
              id={filter.id}
              label={filter.label}
              options={filter.options}
              filterDataSelected={marketplaceFilters.filterDataSelected}
              onChangeFilterData={handleChangeFilters}
              currentDropdownFilter={currentDropdownFilter}
              handleSetCurrentDropdownFilter={handleSetCurrentDropdownFilter}
              viewType={viewType}
            />
          )
        })}

        {
          filtersConfigData.length > 0 && (
            <div className="relative min-w-[145px] xl:w-7/12">
              <div
                className={Classnames('cursor-pointer text-sm border h-[34px] rounded flex justify-between items-center p-3', {
                  ' text-[#2C79E0] border-[#2C79E0]': (!isActive && isFiltering) || (isShowSliderPriceFilter),
                })}
                onClick={() => {
                  handleSetCurrentDropdownFilter(currentDropdownFilter === 'price'? '' : 'price')
                  handleShowPriceSlider()
                }}
              >
              <span
              >{'Price'}</span>
                <svg
                  xmlns="http://www.w3.org/2000/svg" width="12" height="5" viewBox="0 0 12 5" fill="none">
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M1.23888 0.304635C1.66676 0.0312677 2.36048 0.0312677 2.78836 0.304635L6.39622 2.60966L10.0041 0.304635C10.432 0.0312676 11.1257 0.0312676 11.5536 0.304635C11.9814 0.578002 11.9814 1.02122 11.5536 1.29458L7.17096 4.09458C6.74309 4.36795 6.04936 4.36795 5.62148 4.09458L1.23888 1.29458C0.810999 1.02122 0.810999 0.578002 1.23888 0.304635Z"  fill={isShowSliderPriceFilter ? "#2C79E0" : "#5D6E81"}/>
                </svg>
              </div>
            </div>
          )
        }

      </div>
      {isShowSliderPriceFilter &&
        <PriceSlider filtersConfigData={find(filtersConfigData, (value) => value.id === 'wholesale_price')} onChangeFilterData={onChangeFilterData} minPrice={minPrice} maxPrice={maxPrice}/>
      }
    </>
  );
};


const mapStateToProps = (state, props) => {
	return {
    marketplaceFilters: state.marketplaceFilters,
		globalColumnConfigs: state.globalColumnConfigs,
	}
}

const mapActionsToProps = {

}
export default connect(mapStateToProps, mapActionsToProps)(FilterMarketplace)

