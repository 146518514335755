import React, { useEffect, useRef, useState } from 'react'
import Classnames from 'classnames'

import { Globals } from '../../../Context'
import useOutsideClick from '../../../hooks/useOutsideClick';
import TextareaAutosize from '@mui/material/TextareaAutosize'
import Switch from '@mui/material/Switch'

const InputTitle = ({isFocused, item, inputRef, handlePressEnter, setTitleChanged, handleChangeInput, handleOnClickInput}) => {
    const [disableInput, setDisableInput] = useState(false)

    return (
        <>
            {!disableInput && <div className='absolute w-full h-full'></div>}
            <TextareaAutosize
                type="text"
                ref={inputRef}
                defaultValue='(Add Title)'
                onClick={handleOnClickInput}
                className={Classnames('w-full font-bold px-1',  {'text-[#2C79E0]': isFocused, 'text-[#5D6E81]': !isFocused})}
                maxLength={70}
                value={item.title}
                onFocus={() => setDisableInput(true)}
                onBlur={() => setDisableInput(false)}
                onChange={(event) => {
                    setTitleChanged(true)
                    handleChangeInput(event, item)
                }}
                onKeyDown={(e) => (e.key === Globals.keyboardButton.enter && handlePressEnter())}
            />
        </>
    )
};

const SideBar = ({index, handleSelectItem, isFocused, item, handleChangeSwitch, handleChangeInput, handleSubmitTitle}) => {
    const inputRef = useRef(null);
    const inputRef111 = useRef(null);
    const [active, setActive] = useState(false)
    const [titleChanged, setTitleChanged] = useState(false)

    useEffect(() => {
        if(inputRef?.current && !item?.title) {
            inputRef.current.value = '(Add Title)'
        }
    }, [item?.title]);

    useOutsideClick(inputRef, () => {
        if (active && item.title) {
            setActive(false)
            if (titleChanged) {
                handleSubmitTitle(item)
                setTitleChanged(false)
            }
        }
    });

    const handleOnClickInput = () => {
        setActive(true)
    }

    const handlePressEnter = () => {
        inputRef.current.blur()
        handleSubmitTitle(item)
    }

    const onDoubleClick = () => {
        setActive(true)
        if (inputRef.current) {
            const length = inputRef.current.value.length;
            inputRef.current.focus();
            inputRef.current.setSelectionRange(length, length);
        }
    }

    return (
        <div
            className='py-6 border-b-[1px]'
            tabIndex={0}
            ref={inputRef111}
            onDoubleClick={onDoubleClick}
            onClick={() => {
                handleSelectItem(index)
            }}
        >
            <p className={Classnames('italic font-light	text-[10px]',  {'text-[#2C79E0]': isFocused, 'text-[#5D6E81]': !isFocused})}>Link {index + 1}</p>
            <div className='flex relative'>
                <InputTitle
                    isFocused={isFocused}
                    item={item}
                    inputRef={inputRef}
                    handlePressEnter={handlePressEnter}
                    setTitleChanged={setTitleChanged}
                    handleChangeInput={handleChangeInput}
                    handleOnClickInput={handleOnClickInput}
                />
                <Switch
                    color="primary"
                    checked={item.enabled}
                    size="small"
                    onChange={event => handleChangeSwitch(event, item)}
                    sx={{
                        "&.MuiSwitch-root .MuiSwitch-input": {
                            height: "-webkit-fill-available"
                        },
                        fontSize: 10
                    }}
                />
            </div>
        </div>
    )
}

export default SideBar
