import React, { useState } from 'react'

import ConfirmPopUp from '../../ConfirmPopUp';

const ListModalMobile = ({note, formatDate, handleEditNote, handleDeleteNote}) => {
    const [isVisibleHistory, setIsVisibleHistory] = useState(false)
    return (
          <>
            {
                note.children.length === 0 ?
                <div className="flex flex-col w-full">
                    <div className="flex font-normal">
                        <div className="text-[#5D6E81] w-full">
                            <p className="text-[15px] ">{note.content}</p>
                        </div>
                    </div>
                    <div className="flex items-center text-[#5D6E81]">
                        <p className="text-[8px] text-[#5D6E81]">{formatDate(note.created_at)} - {`${note?.user?.first_name} ${note?.user?.last_name}`}</p>
                        <div className="text-[#5D6E81] text-[6px] ml-2">
                            <span
                                className="text-[#F00]"
                                onClick={() => {
                                    handleEditNote(note)
                                }}
                            >
                                Edit
                            </span>
                            <ConfirmPopUp onConfirm={() => handleDeleteNote(note)} title="Delete Note" content="Are you sure you want to delete it?">
                                <span
                                    className="ms-2 text-[#F00] text-[6px]"
                                >
                                    Delete
                                </span>
                            </ConfirmPopUp>
                        </div>
                    </div>
                </div> :
                note.children?.map((history, index) => (
                    index === 0 &&
                    <div className=''>
                        <div className="text-[#5D6E81]">
                            <p className="text-[15px]">{history?.content}</p>
                        </div>

                        <div className='flex'>
                            <p className="text-[8px] text-[#5D6E81]">{formatDate(history?.updated_at)} - {`${history?.user?.first_name} ${history?.user?.last_name}`}</p>
                            <div className="text-[#5D6E81] text-[6px] ml-2">
                                <span
                                    className="text-[#F00]"
                                    onClick={() => {
                                        handleEditNote(note.children[0])
                                    }}
                                >
                                    Edit
                                </span>
                                <span
                                    className="ms-2 text-[#F00]"
                                    onClick={() => handleDeleteNote(note)}
                                    >
                                    Delete
                                </span>
                                <span className="ms-2 text-[#4B7CBE] text-[6px]" onClick={() => {setIsVisibleHistory(!isVisibleHistory)}}>
                                    {isVisibleHistory ? 'Hide Prior' : 'Show Prior'} History
                                </span>
                            </div>
                        </div>
                    </div>
                ))
            }
            <div className="mt-2">
                {isVisibleHistory && note.children?.map((history, index) => (
                    index === 0 ?  <></>:
                    <div className="text-[#5D6E81]">
                        <p className="text-[15px] text-[#9C9C9C]">{history?.content}</p>
                        <p className="ml-8 text-[8px] text-[#9C9C9C]">{formatDate(history?.updated_at)} - {`${history?.user?.first_name} ${history?.user?.last_name}`}</p>
                    </div>
                ))}
                {isVisibleHistory &&
                    <div className="text-[#5D6E81]">
                        <p className="text-[15px] text-[#9C9C9C]">{note?.content}</p>
                        <p className="ml-8 text-[8px] text-[#9C9C9C]">{formatDate(note?.updated_at)} - {`${note?.user?.first_name} ${note?.user?.last_name}`}</p>
                    </div>
                }
            </div>
        </>
    )
}

export default ListModalMobile