import React, {useEffect, useState} from 'react'

import SingleItemDetail from '../Sales/SingleItemDetail'
import SingleMemoDetail from '../Memo/SingleMemoDetail'
import {Globals} from '../../Context'
import Moment from 'moment'
import isEmpty from 'lodash/isEmpty'
import startCase from 'lodash/startCase'

const CustomerTransactions = ({props, singleCustomerView}) => {
	const [ordersList, setOrdersList] = useState()
	const [singleOrderItem, setSingleOrderItem] = useState()
	const [isVisibleSingleOrder, setIsVisibleSingleOrder] = useState(false)

	useEffect(() => {
		handleLoadOrders()
	}, [])

	const handleLoadOrders = () => {
		return Globals.New_Axios()
			.get(`${Globals.NEW_API_URL}/${Globals.ENDPOINTVERSION}/spaces/${Globals.SELECTEDSPACEID}/customers/${singleCustomerView.id}/transactions`)
			.then(response => {
				if (response.status && response.status >= 200 && response.status < 300) {
					let orders = response.data.transactions

					setOrdersList([...orders])
				}
			})
			.catch(error => {
				return props.addToast({
					text: 'Something went wrong please try again later.',
					type: 'error',
				})
			})
	}

	const loadSingleOrder = order => {
		setSingleOrderItem({
			type: order.type,
			id: order.type === 'sale' ? order.sale_id : order.memo_id
		})
		setIsVisibleSingleOrder(true)
	}

	const closeSingleOrderItem = () => {
		setIsVisibleSingleOrder(false)
	}

	const handleClickOut = $event => {
		$event.stopPropagation()

		if($event.target.classList.contains('single-customer-view')) {
			setSingleOrderItem(null)
			setIsVisibleSingleOrder(false)
		}
	}

	return (
		<div>
			<h2 className="text-md font-bold tracking-wider py-2 border-b px-5">Transactions</h2>
			<div className="divide-gray-200 px-2 overflow-y-scroll" style={{maxHeight: 'calc(100vh - 200px)'}}>
				<table className="relative min-w-full border-b bg-transparent overflow-auto" style={{width: '100%', height: '100%'}}>
					<thead
						className="bg-white border-b"
						style={{
							position: 'sticky',
							top: '0',
							zIndex: 1,
						}}
					>
						<tr className="">
							<th className="py-2 pl-4 pr-2 text-sm font-semibold tracking-worderer">Date</th>
							<th className="py-2 pr-2 text-sm font-semibold tracking-worderer">SKU</th>
							<th className="w-2/4 py-2 pr-2 text-sm font-semibold tracking-worderer">Product</th>
							<th className="py-2 pr-2 text-sm font-semibold tracking-worderer">Type</th>
							<th className="py-2 pr-2 text-sm font-semibold tracking-worderer">Number</th>
							<th className="py-2 pr-2 text-sm font-semibold tracking-worderer">Price</th>
							<th className="py-2 pr-2 text-sm font-semibold tracking-worderer text-right">Status</th>
						</tr>
					</thead>

					{!isEmpty(ordersList) && (
						<tbody className="divide-y">
							{ordersList.map((order, index) => {
								return (
									<tr
										key={index}
										//	className={Classnames('cursor-pointer  hover:bg-gray-100 bg-gray-50', {
										///	'bg-white': (index + 1) % 2 === 0,
										//</tbody>	})}
										className="hover:bg-gray-100 cursor-pointer"
										onClick={() => {
											loadSingleOrder(order)
										}}
									>
										<td className="py-2 text-sm font-normal tracking-worderer">{Moment(order.created_at).format('MM/DD/YYYY')}</td>
										<td className="py-2 text-sm font-normal tracking-worderer">{order.sku}</td>

										<td className="py-2 pr-2 text-sm font-normal tracking-worderer">{order.product_name}</td>
										<td className="py-2 text-sm font-normal tracking-worderer">{order.type}</td>
										<td className="py-2 text-sm font-normal tracking-worderer">#{order.number}</td>
										<td className="py-2 text-sm font-normal tracking-worderer">{Globals.moneyFormatter.format(parseFloat(order.price)).replace('.00', '').replace('$', '')}</td>
										<td className="py-2 pr-2 text-sm font-normal tracking-worderer text-right">
											{
												order?.status === 'published' ? 'Open' : startCase(order?.status.replace("_", " "))
											}
										</td>
									</tr>
								)
							})}
						</tbody>
					)}
				</table>
				{isVisibleSingleOrder && singleOrderItem.type === 'sale' && <SingleItemDetail props={props} handleClickOut={handleClickOut} orderId={singleOrderItem.id} closeSingleOrderItem={closeSingleOrderItem} currentSpace={props.currentSpace} profile={props.profile}/>}
				{isVisibleSingleOrder && singleOrderItem.type === 'memo' && <SingleMemoDetail props={props} singleMemoItemId={singleOrderItem.id} closeSingleMemoDetail={closeSingleOrderItem} currentSpace={props.currentSpace} profile={props.profile}/>}
			</div>
		</div>
	)
}

export default CustomerTransactions
