import CardNumber from './CardNumber'
import TitleCard from './TitleCard'

const WatchStatus = ({data}) => {

	return (
		<div className="relative flex flex-col xl:col-span-6 col-span-1  bg-white py-5 px-5 rounded-xl drop-shadow-md lg:mr-3 overflow-hidden">
			<TitleCard title={'Watch Status'} />
			<div className="grid grid-cols-5 justify-items-start mt-5">
				<CardNumber number={data?.total} title={'Total watches'} color={'#0FAEE1'} />
				<CardNumber number={data?.total_on_hand} title={'On Hand'} color={'#2EC800'} />
				<CardNumber number={data?.total_in_transit} title={'In transit'} color={'#0FAEE1'} />
				<CardNumber number={data?.total_on_memo} title={'On memo'} color={'#5D6E81'} />
				<CardNumber number={data?.total_on_hold} title={'On Hold'} color={'#F5A900'} />
			</div>
		</div>
	)
}

export default WatchStatus
