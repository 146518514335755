import React from 'react'
import '../../Pages/Messages.scss'
const Info = ({title, detail}) => {
	let icon = null
	if (title === 'Message Number') {
		icon = (
			<svg className="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
				<path
					d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
					stroke="#222222"
					strokeWidth={2}
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
			</svg>
		)
	} else if (title === 'Views') {
		icon = (
			<svg width="1em" height="1em" viewBox="0 0 1024 1024" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
				<path d="M512 160c320 0 512 352 512 352S832 864 512 864 0 512 0 512s192-352 512-352zm0 64c-225.28 0-384.128 208.064-436.8 288 52.608 79.872 211.456 288 436.8 288 225.28 0 384.128-208.064 436.8-288-52.608-79.872-211.456-288-436.8-288zm0 64a224 224 0 110 448 224 224 0 010-448zm0 64a160.192 160.192 0 00-160 160c0 88.192 71.744 160 160 160s160-71.808 160-160-71.744-160-160-160z" />
			</svg>
		)
	}
	return (
		<div className="grid grid-cols-2 gap-2 py-1">
			<div className="flex items-center">
				<span className="mr-2">{icon}</span>
				<span className="detail-title">{title}</span>
			</div>
			<div className="whitespace-pre-line detail-detail">{detail ? detail : ''}</div>
		</div>
	)
}

export default Info
