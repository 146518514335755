import {useEffect, useState} from 'react'
import {useLocation} from 'react-router-dom'
import classNames from 'classnames'
import PaymentForm from './PaymentForm'
import find from 'lodash/find'
import map from 'lodash/map'
import CheckIcon from '@mui/icons-material/Check'

import {Globals} from '../Context'
import * as collections from '../utils/collections'

const MobileCheckOut = () => {
	const [checkoutSession, setCheckoutSession] = useState()
	const [currentChoosePlan, setCurrentChoosePlan] = useState()
	const [billingPeriod, setBillingPeriod] = useState()
	const [loading, setLoading] = useState(true)
	const location = useLocation()

	useEffect(() => {
		const queryParams = new URLSearchParams(location.search)
		const planType = queryParams.get('plan_type')
		const registrationUid = queryParams.get('registration_uid')
		const billing = queryParams.get('billing_period')
		setBillingPeriod(billing)
		const plan = find(collections.plansCollection, plans => {
			return plans.value === planType
		})

		setCurrentChoosePlan(plan)
		return Globals.New_Axios()
			.post(`${Globals.NEW_API_URL}/${Globals.ENDPOINTVERSION}/spaces/generate_payment_link`, {
				registration_uid: registrationUid,
				plan_type: planType,
				billing_period: billing,
				registering: true,
				return_url: `${window.location.origin}/thank-you?mobile-checkout=true`,
			})
			.then(response => {
				setCheckoutSession(response.data.client_secret)
				setLoading(false)
			})
			.catch(error => {})
	}, [])

	return (
		<>
			{loading ? (
				<div className="bg-white/50 bottom-0 fixed flex items-center justify-center left-0 right-0 rounded-lg top-0 z-50">
					<div role="status">
						<svg aria-hidden="true" className="w-8 h-8 mr-2 text-gray-200 animate-spin fill-blue-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path
								d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
								fill="currentColor"
							/>
							<path
								d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
								fill="currentFill"
							/>
						</svg>
					</div>
				</div>
			) : (
				<>
					<div className="pb-4 relative w-full">
						<div className="flex flex-col font-bold items-center md:items-start justify-center text-xl w-full">
							<div className="pt-7 mb-7 w-full">
								<h1 className="text-center text-2xl md:text-[35px] md:w-full">Checkout</h1>
							</div>
						</div>
					</div>
					<div className="flex gap-2 md:gap-10 w-full px-10 flex-col lg:flex-row pb-10 md:pb-0">
						<div
							className={classNames(
								'relative lg:max-w-auto lg:w-full flex flex-col rounded-[10px] text-[12px] lg:text-[16px] border-gray-300 border-[2px] bg-[#fff] order-2 lg:order-1',
								{
									'lg:h-[400px]': currentChoosePlan.value !== 'platinum',
									'lg:h-[550px]': currentChoosePlan.value === 'platinum',
								}
							)}
						>
							<div className="w-full h-full flex flex-col justify-between p-2">
								<div className="flex flex-col">
									<span className="text-black font-bold text-[12px] lg:text-[20px] text-center">{currentChoosePlan.title}</span>
									<span className="text-[#4b4b8c] text-[20px] font-bold text-center">
										{billingPeriod === 'monthly' ? currentChoosePlan.price.monthly.value : currentChoosePlan.price.yearly.value}
									</span>
									<div className="flex flex-col items-center pb-[15px]">
										<span className="text-[12px] text-gray-700 font-bold text-center">{currentChoosePlan.price[billingPeriod].subtitle}</span>
										{billingPeriod === 'yearly' && (
											<span className="text-[12px] text-gray-700 text-center italic">
												{currentChoosePlan.price.full_amount.value} to be billed, Renewal day will be 1 year after approved
											</span>
										)}
									</div>
									<div className="flex flex-col mt-3 text-[12px] border-t-[1px]">
										{map(currentChoosePlan.descriptions, (feature, index) => (
											<div className="flex items-start mt-3" key={index}>
												<CheckIcon style={{fontSize: '16px', color: '#ff6a39'}} />
												<span key={index} className="text-black ml-2">
													{feature}
												</span>
											</div>
										))}
									</div>
								</div>
								<div className="flex flex-col items-center pt-4">
									<span className="text-[12px] text-gray-700 font-bold text-center">You will not be charged until your account is approved</span>
								</div>
							</div>
						</div>
						{!!checkoutSession && (
							<div className="w-full order-1 lg:order-2">
								<PaymentForm checkoutSession={checkoutSession} />
							</div>
						)}
					</div>
				</>
			)}
		</>
	)
}

export default MobileCheckOut
