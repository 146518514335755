export const handleTrimInputOnBlur = (event, currentState, updateState) => {
    const { name, value } = event.target;
    const trimmedValue = value.trim();

    if (currentState.hasOwnProperty(name)) {
      updateState({
        ...currentState,
        [name]: trimmedValue,
      });
    }
  };
