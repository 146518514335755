import React from 'react';
import HourglassTwoTone from '@ant-design/icons/HourglassTwoTone'

const WebStoreExpired = () => {
    return(
        <section className="relative">
            <div id="controllersActions" className="flex flex-col h-screen items-center justify-center">
                <HourglassTwoTone className='hourglass-main'/>
                <h1 className='hourglass-header'>This link has expired</h1>
                <p>Please contact to owner for a new link</p>
            </div>
        </section>
    )
}

export default WebStoreExpired;
