import React, { useRef, useEffect, useState } from 'react'
import { styled } from '@mui/material/styles';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Scrollbar } from 'swiper/modules';
import { createGlobalStyle } from 'styled-components';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import {connect} from 'react-redux'

import {Globals} from '../../Context'
import * as collections from '../../utils/collections'
import SelectOptionsFlatMobile from '../../Components/Inventory/mobile/SelectOptionsFlatMobile'
import DatePickerFlatMobile from '../Inventory/mobile/DatePickerFlatMobile/index'
import UploadMediaMobile from '../../Components/Inventory/mobile/UploadMediaMobile'


import 'swiper/css';
import 'swiper/css/scrollbar';
import sortBy from 'lodash/sortBy'
import TextField from '@mui/material/TextField'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import Box from '@mui/material/Box'
import DialogContent from '@mui/material/DialogContent'
import Grid from '@mui/material/Grid'
import Divider from '@mui/material/Divider'
import Button from '@mui/material/Button'

const GlobalStyle = createGlobalStyle`
    .swiper-scrollbar {
        bottom: 15px !important;
    };
    .section-details:nth-child(odd) {
        background-color: #E9E9E9;
        width: 100%;
    };
`;

const CustomMobileModal = styled(Dialog)(({ theme }) => ({
	'& .MuiDialog-container': {
		height: '100%',
		display: 'flex',
		alignItems: 'flex-end',
	},
	'& .MuiDialog-paper': {
		width: '100%',
		maxWidth: '100%',
		margin: 0,
	},
}));

const basicInfo = (data) => {
    return [
        { label: 'Dealer SKU', key: 'sku', value: data.sku },
        { label: 'Reference Number', key: 'reference_number', value: data?.reference_number },
        { label: 'Brand', key: 'brand', value: data?.brand },
        { label: 'Series', key: 'series', value: data?.series },
        { label: 'Serial Number', key: 'serial_number', value: data?.serial_number },
        { label: 'Dial', key: 'dial', value: data?.dial },
        { label: 'Bezel', key: 'bezel', value: data?.bezel },
        { label: 'Bracelet', key: 'bracelet', value: data?.bracelet },
    ]
}

const scopeOfDelivery = (formData, data) => {
    return [
        { label: 'Condition', key: 'condition', value: data?.condition },
        { label: 'Warranty Paper/Card', key: 'warranty_papers', value: data?.warranty_paper_card },
        { label: 'Warranty Date', key: 'warranty', value: data?.warranty_date },
        { label: 'Box', key: 'box', value: data?.box },
        { label: 'Link Count', key: 'link_count', value: formData?.link_count },
        { label: 'Additional Details',key: 'addition_details', value: formData?.addition_details },
    ]
}

const priceInfo = (formData, data) => {
    return [
        { label: 'MSRP Price', key: 'msrp_price' ,value: formData?.msrp_price },
        { label: 'Wholesale Price', key: 'wholesale_price', value: formData?.wholesale_price },
        { label: 'Cost',key: 'cost', value: formData?.cost },
        { label: 'Date Purchased', key: 'date_purchased', value: data?.date_purchased },
        { label: 'Vendor/Partner', key: 'vendor', value: data?.vendor },
    ]
}

const marketplaceInfo = (formData, data) => {
    return [
        { label: 'Visible in Marketplace', key: 'visible_in_marketplace',  value: data?.visible_in_marketplace },
        { label: 'Online Price',key: 'online_price', value: formData?.online_price },
    ]
}

const EditWatchModal = ({
    isOpen,
    onClose,
    data,
    handleChangeSelect,
    selectedWarrantyPaperValue,
    warrantyCardsList,
    handleChange,
    handlechangePicker,
    formData,
    displayError,
    handleChangeAutoComplete,
    formErrors,
    onChangePrice,
    customColumns,
    handleChangeCustomColumn,
    handleChangePickerCustomColumn,
    handleSubmit,
    uploadUpdate,
    imagesMobile,
    handleDeleteProduct,
    globalColumnConfigs
}) => {
    const swiperElRef = useRef(null);
    const [image, setImage] = React.useState(null);
    const [visibleFields, setVisibleFields] = useState([])

    useEffect(() => {
        if (swiperElRef.current) {
            swiperElRef.current.swiper.update();
        }
        setVisibleFields(globalColumnConfigs.filter(item => item.visible).map(item => item.key))
    }
    , [isOpen]);

  return (
    <CustomMobileModal
        open={isOpen}
        onClose={onClose}
        sx={{
            '& .MuiDialog-paper': {
                width: '100%',
                maxWidth: '100%',
                height: '100%',
                maxHeight: '100%',
                color: '#5D6E81',
                overflowY: 'hidden'
            },
        }}
        className="text-[10px] md:hidden"
    >
        <GlobalStyle />
        <DialogTitle
            className="bg-[#DEDEDE] relative text-[#5D6E81] max-h-[63px] flex justify-between items-center rounded-tl-2xl w-full"
        >
            <div className="flex justify-between items-center text-[#5D6E81] w-full">
                <Box className="flex items-center">
                    <KeyboardArrowLeftIcon onClick={onClose} />
                    <span className="font-bold text-[#5D6E81] uppercase ms-2">EDIT SKU</span>
                </Box>
                <div className="flex items-center">
                    <img src="/Images/edit-mobile.svg" className="cursor-pointer text-black font-bold ms-3"/>
                </div>
            </div>
        </DialogTitle>
        <DialogContent sx={{padding: 0}}>
            <div className="relative mb-4 flex px-4 mt-2">
                <UploadMediaMobile uploadUpdate={uploadUpdate} defaultValue={data?.images ? data.images : []} image={image}/>
                <Swiper
                    scrollbar={{
                        hide: false,
                        el: '.cus-swiper-scrollbar ',
                    }}
                    modules={[Scrollbar]}
                    ref={swiperElRef}
                    slidesPerView={imagesMobile && (imagesMobile.length >= 3 ? 3  : imagesMobile.length)}
                    slidesPerGroup={3}
                    className="mySwiper relative"
                >
                    {imagesMobile && imagesMobile.length > 0 && imagesMobile.map((image, index) => (
                        <SwiperSlide className='!flex items-center justify-center' key={index}>
                            <div className="relative">
                                <img src={image.url} alt="watch" className="w-[99px] h-auto"/>
                                <div className="absolute top-[4px] right-[4px]">
                                    <CloseOutlinedIcon className="text-[#000]" sx={{fontSize: '16px'}} onClick={() => {setImage(image)}}/>
                                </div>
                            </div>
                        </SwiperSlide>
                    ))}
                </Swiper>
                <div className="cus-swiper-scrollbar w-3/4 absolute h-[2px] bottom-2 right-10 mx-auto z-10"></div>
            </div>
            <Grid container sx={{padding: '10px 20px'}} className="section-details">
                <Grid  item xs={12} className="flex">
                    <span className="text-[#4B7CBE] font-bold w-1/2 text-[16px]">Status</span>
                        {data?.status && (
                            <SelectOptionsFlatMobile
                                extraValueErrors={formErrors?.status_details}
                                extraValue={data?.status_details}
                                name={'status'}
                                handleChangeSelect={handleChangeSelect}
                                handleChange={handleChange}
                                handleChangeAutoComplete={handleChangeAutoComplete}
                                showCustomerAutoSuggest={true}
                                selectedValue={data ? data?.status : 'on_hand'}
                                options={collections.inventoryStatusesCollection}
                                sx={{
                                    padding: '0',
                                }}
                            />
						)}
                </Grid>
                {displayError('status')}
                {formData?.status !== 'on_hand' && displayError('status_details')}
                <Grid spacing={2}  item xs={12} className="flex justify-between">
                    <Divider className="w-10/12 text-[#8C8D8D]" />
                </Grid>
                <Grid item xs={12} className="flex">
                    {formData?.status === 'sold_to' && (
                        <>
                            <Grid item xs={12} className="flex justify-between items-center text-[#5D6E81] text-[14px]">
                                <span className="font-medium w-full">Sold Price</span>
                                <TextField
                                    size="small"
                                    variant="standard"
                                    margin="normal"
                                    fullWidth
                                    name="sold_price"
                                    placeholder="Sold price"
                                    defaultValue={formData?.sold_price}
                                    onSelect={handleChange}
                                    onChange={handleChange}
                                    InputProps={{
                                        disableUnderline: true,
                                        style: {fontSize: 14, color:'#5D6E81', fontWeight: 700, fontFamily: 'Poppins', padding: 0 }
                                    }}
                                    InputLabelProps={{
                                        style: {fontSize: 14, color:'#5D6E81'}
                                    }}
                                    sx={{
                                        padding: '0',
                                    }}
                                />
                            </Grid>
                        </>
                    )}
                </Grid>
                {formData?.status === 'sold_to' && (
                    <>
                        {displayError('sold_price')}
                        <Grid spacing={2}  item xs={12} className="flex justify-between">
                            <Divider className="w-10/12 text-[#8C8D8D]" />
                        </Grid>
                    </>
                )}
                <Grid item xs={12} className="flex">
                    {formData?.status === 'sold_to' && (
                        <>
                            <Grid item xs={12} className="flex justify-between items-center text-[#5D6E81] text-[14px] py-[14px]">
                                <span className="font-medium w-full">Sold Date</span>
                                    <DatePickerFlatMobile
                                        name="sold_date"
                                        title="Sold Date"
                                        handlechangePicker={handlechangePicker}
                                        defaultValue={formData.sold_date}
                                    />
                            </Grid>
                        </>
                    )}
                </Grid>
                {formData?.status === 'sold_to' && (
                    <>
                        {displayError('sold_date')}
                        <Grid spacing={2}  item xs={12} className="flex justify-between">
                            <Divider className="w-10/12 text-[#8C8D8D]" />
                        </Grid>
                    </>
                )}
            </Grid>
            <Grid container sx={{mt: 1, padding: '10px 20px'}} className="section-details">
                <Grid item xs={12} className="flex justify-between">
                    <span className="text-[#4B7CBE] font-bold text-[16px]">Basic Info</span>
                </Grid>
                {
                !!data &&
                    basicInfo(data).map((item, index) => {
                        return visibleFields.includes(item.key) && (
                            <React.Fragment key={index}>
                                <Grid item xs={12} className="flex justify-between items-center text-[#5D6E81] text-[14px]">
                                    <span className="font-medium w-full">{item.label}</span>
                                    <>
                                        <TextField
                                            onChange={handleChange}
                                            onSelect={handleChange}
                                            size="small"
                                            variant="standard"
                                            margin="normal"
                                            fullWidth
                                            name={item.key}
                                            placeholder={item.label}
                                            defaultValue={item.value}
                                            InputProps={{
                                                disableUnderline: true,
                                                style: {fontSize: 14, color:'#5D6E81', fontWeight: 700, fontFamily: 'Poppins', padding: 0 }
                                            }}
                                            InputLabelProps={{
                                                style: {fontSize: 14, color:'#5D6E81'}
                                            }}
                                            sx={{
                                                padding: '0',
                                            }}
                                        />
                                    </>
                                </Grid>
                                {displayError(item.key)}
                                <Grid item xs={12} className="flex justify-between">
                                    <Divider className="w-10/12 text-[#8C8D8D]" />
                                </Grid>
                            </React.Fragment>
                        )
                    })
                }
            </Grid>
            <Grid container sx={{mt: 1, padding: '10px 20px'}} className="section-details">
                <Grid item xs={12} className="flex justify-between">
                    {
                        !Globals.isAllScopeOfDeliveryShown(visibleFields) && <span className="text-[#4B7CBE] font-bold text-[16px]">Scope of Delivery</span>
                    }
                </Grid>
                {
                    scopeOfDelivery(formData, data).map((item, index) => {
                        return visibleFields.includes(item.key) && (
                            <React.Fragment key={index}>
                                {item.key == 'condition' &&
                                    <Grid item xs={12} className="flex items-center text-[#5D6E81] text-[14px] py-[14px]">
                                        <span className="font-medium w-1/2">{item.label}</span>
                                        <SelectOptionsFlatMobile
                                            name={'condition'}
                                            handleChangeSelect={handleChangeSelect}
                                            selectedValue={data?.condition ? data.condition : 'unworn'}
                                            options={sortBy(collections.inventoryConditionsCollection, (e) => e.option)}
                                            sx={{
                                                padding: '0',
                                            }}
                                            isMobile={true}
                                        />
                                    </Grid>
                                }
                                {item.key == 'warranty_papers' &&
                                    <Grid item xs={12} className="flex items-center text-[#5D6E81] text-[14px] py-[14px]">
                                        <span className="font-medium w-1/2">{item.label}</span>
                                        <SelectOptionsFlatMobile
                                            name="warranty_papers"
                                            selectedValue={!!data ? selectedWarrantyPaperValue : 'Card'}
                                            options={sortBy(warrantyCardsList, (e) => e.option)}
                                            handleChangeSelect={handleChangeSelect}
                                            sx={{
                                                padding: '0',
                                            }}
                                            isMobile={true}
                                        />
                                    </Grid>
                                }
                                {item.key == 'warranty' &&
                                    <Grid item xs={12} className="flex items-center text-[#5D6E81] text-[14px] py-[14px]">
                                        <span className="font-medium mr-5">{item.label}</span>
                                        <div className='flex items-center mr-15 max-w-[100px]'>
                                            {formData?.warranty_papers !== 'no' && (
                                                <>
                                                    {data?.warranty_no_date ? (
                                                        <>
                                                            <input
                                                                defaultValue={'No Date'}
                                                                value={'No Date'}
                                                                onChange={handleChange}
                                                                onSelect={handleChange}
                                                                type="text"
                                                                name="warranty"
                                                                readOnly={true}
                                                                placeholder="No Date"
                                                                className="border-0 m-0 p-0 w-full hidden"
                                                            />
                                                            <p  className="border-0 m-0 p-0 w-full" >00/00/00</p>
                                                        </>
                                                    ) : (
                                                        <DatePickerFlatMobile
                                                            name="warranty"
                                                            placeholder="Warranty Date"
                                                            title="Warranty Date"
                                                            handlechangePicker={handlechangePicker}
                                                            defaultValue={data ? data?.warranty : null}
                                                        />
                                                    )}
                                                </>
                                            )}
                                        </div>
                                        <div className='flex justify-start items-center ml-2 mb-1'>
                                            <label
                                                    htmlFor="warranty_no_date"
                                                >
                                                    <input
                                                        onChange={handleChange}
                                                        onSelect={handleChange}
                                                        type="checkbox"
                                                        id="warranty_no_date"
                                                        name="warranty_no_date"
                                                        defaultChecked={data?.warranty_no_date}
                                                        className="hidden peer"
                                                    />
                                                    <div className='flex items-center text-center'>
                                                        <span style={{color: 'rgb(59 130 246)'}} htmlFor="warranty_no_date" className="w-4 h-4 border border-gray-400 rounded-sm mr-2 flex items-center justify-center bg-white">
                                                            {data?.warranty_no_date === true && (
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="12" height="10" viewBox="0 0 12 10" fill="none">
                                                                    <path d="M10.59 0L12 1.42L4 9.42L0 5.43L1.42 4.02L4 6.59L10.59 0Z" fill="#2C79E0"/>
                                                                </svg>
                                                            )}
                                                        </span>
                                                        <span className='mt-1'>No Date</span>
                                                    </div>
                                                </label>
                                        </div>
                                    </Grid>
                                }
                                {item.key == 'box' &&
                                    <Grid item xs={12} className="flex items-center text-[#5D6E81] text-[14px] py-[14px] font-bold">
                                        <span className="font-medium w-1/2">{item.label}</span>
                                            <div className='flex items-center'>
                                                <label
                                                    htmlFor="yes"
                                                >
                                                    <input
                                                        onChange={handleChange}
                                                        type="checkbox"
                                                        id="yes"
                                                        value={true}
                                                        name="box"
                                                        checked={data?.box}
                                                        className="hidden peer "
                                                    />
                                                    <div className='flex items-center text-center'>
                                                        <span htmlFor="yes" className="w-4 h-4 border border-gray-400 rounded-sm mr-2 flex items-center justify-center bg-white text-blue-500 indeterminate:bg-gray-300">
                                                            {data?.box && (
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="12" height="10" viewBox="0 0 12 10" fill="none">
                                                                    <path d="M10.59 0L12 1.42L4 9.42L0 5.43L1.42 4.02L4 6.59L10.59 0Z" fill="#2C79E0"/>
                                                                </svg>
                                                            )}
                                                        </span>
                                                        <span className='mt-1'>Yes</span>
                                                    </div>
                                                </label>
                                            </div>
                                            <div className='flex items-center ml-2'>
                                                <label
                                                    htmlFor="no"
                                                >
                                                    <input
                                                        onChange={handleChange}
                                                        type="checkbox"
                                                        id="no"
                                                        value={false}
                                                        name="box"
                                                        checked={data?.box === false}
                                                        className="hidden peer"
                                                    />
                                                    <div className='flex items-center text-center'>
                                                        <span style={{color: 'rgb(59 130 246)'}} htmlFor="yes" className="w-4 h-4 border border-gray-400 rounded-sm mr-2 flex items-center justify-center bg-white peer-checked:bg-blue-500">
                                                            {data?.box === false && (
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="12" height="10" viewBox="0 0 12 10" fill="none">
                                                                    <path d="M10.59 0L12 1.42L4 9.42L0 5.43L1.42 4.02L4 6.59L10.59 0Z" fill="#2C79E0"/>
                                                                </svg>
                                                            )}
                                                        </span>
                                                        <span className='mt-1'>No</span>
                                                    </div>
                                                </label>
                                            </div>
                                    </Grid>
                                }
                                {item.key !== 'condition' &&  item.key !== 'warranty_papers' && item.key !== 'warranty' && item.key !== 'box' &&
                                    <Grid item xs={12} className="flex justify-between items-center text-[#5D6E81] text-[14px]">
                                        <span className="font-medium w-full">{item.label}</span>
                                        <TextField
                                            size="small"
                                            variant="standard"
                                            margin="normal"
                                            fullWidth
                                            name={item.key }
                                            onSelect={handleChange}
                                            onChange={handleChange}
                                            placeholder={item.label}
                                            defaultValue={data[item.key]}
                                            InputProps={{
                                                disableUnderline: true,
                                                style: {fontSize: 14, color:'#5D6E81', fontWeight:700, fontFamily: 'Poppins' }
                                            }}
                                            InputLabelProps={{
                                                style: {fontSize: 14, color: '#5D6E81'}
                                            }}
                                            sx={{
                                                padding: '0',
                                            }}
                                        />
                                    </Grid>
                                }
                                {displayError(item.key)}
                                <Grid item xs={12} className="flex justify-between">
                                    <Divider className="w-10/12 text-[#8C8D8D]" />
                                </Grid>
                            </React.Fragment>
                        )
                    })
                }
            </Grid>
            <Grid container sx={{mt: 1, padding: '10px 20px'}} className="section-details">
                {
                    !Globals.isAllPriceInfoShown(visibleFields) && (
                        <Grid item xs={12} className="flex justify-between">
                            <span className="text-[#4B7CBE] font-bold text-[16px]">Price Info</span>
                        </Grid>
                    )
                }
                {
                    priceInfo(formData, data).map((item, index) => {
                        return visibleFields.includes(item.key) && (
                            <React.Fragment key={index}>
                                {item.key == 'date_purchased' ?
                                    <Grid item xs={12} className="flex justify-between items-center text-[#5D6E81] text-[14px] py-[14px]">
                                        <span className="font-medium w-full">{item.label}</span>
                                        <DatePickerFlatMobile
                                            name="date_purchased"
                                            title="MM/DD/YYYY"
                                            handlechangePicker={handlechangePicker}
                                            defaultValue={data && data.date_purchased !== '1/1/1970' ? data.date_purchased : ' '}
                                        />
                                    </Grid> :
                                    item.key == 'wholesale_price' || item.key == 'cost' || item.key == 'msrp_price'  ?
                                        <Grid item xs={12} className="flex justify-between items-center text-[#5D6E81] text-[14px]">
                                            <span className="font-medium w-full">{item.label}</span>
                                            <TextField
                                                size="small"
                                                variant="standard"
                                                onChange={(e) => onChangePrice(e, item.key)}
                                                margin="normal"
                                                fullWidth
                                                name={item.key}
                                                value={item.value}
                                                placeholder={item.label}
                                                InputProps={{
                                                    disableUnderline: true,
                                                    style: {fontSize: 14, color:'#5D6E81', fontWeight:700, fontFamily: 'Poppins' }
                                                }}
                                                InputLabelProps={{
                                                    style: {fontSize: 14, color: '#5D6E81'}
                                                }}
                                                sx={{
                                                    padding: '0',
                                                }}
                                            />
                                        </Grid>
                                    : <Grid item xs={12} className="flex justify-between items-center text-[#5D6E81] text-[14px]">
                                        <span className="font-medium w-full">{item.label}</span>
                                        <TextField
                                            size="small"
                                            variant="standard"
                                            onChange={handleChange}
                                            onSelect={handleChange}
                                            margin="normal"
                                            fullWidth
                                            name={item.key}
                                            defaultValue={data[item.key]}
                                            placeholder={item.label}
                                            InputProps={{
                                                disableUnderline: true,
                                                style: {fontSize: 14, color:'#5D6E81', fontWeight:700, fontFamily: 'Poppins' }
                                            }}
                                            InputLabelProps={{
                                                style: {fontSize: 14, color: '#5D6E81'}
                                            }}
                                            sx={{
                                                padding: '0',
                                            }}
                                        />
                                    </Grid>
                                }
                                {displayError(item.key)}
                                <Grid item xs={12} className="flex justify-between">
                                    <Divider className="w-10/12 text-[#8C8D8D]" />
                                </Grid>
                            </React.Fragment>
                        )
                    })
                }
            </Grid>
            <Grid container sx={{mt: 1, padding: '10px 20px'}} className="section-details">
                <Grid item xs={12} className="flex justify-between items-center text-[#5D6E81] text-[14px]">
                    <span className="text-[#4B7CBE] font-bold text-[16px]">Marketplace Info</span>
                </Grid>
                {
                    marketplaceInfo(formData, data).map((item, index) => {
                        return visibleFields.includes(item.key) && (
                            <React.Fragment key={index}>
                                {item.key == 'visible_in_marketplace' ?
                                <Grid item xs={12} className="flex items-center text-[#5D6E81] text-[14px] py-[14px] font-bold">
                                    <span className="font-medium w-1/2">{item.label}</span>
                                        <div className='flex items-center'>
                                            <label
                                                htmlFor="yes_1"
                                            >
                                                <input
                                                    onChange={handleChange}
                                                    type="checkbox"
                                                    id="yes_1"
                                                    name="visible_in_marketplace"
                                                    checked={data?.visible_in_marketplace}
                                                    value={true}
                                                    className="hidden peer"
                                                />
                                                <div className='flex items-center text-center'>
                                                    <span htmlFor="yes_1" className="w-4 h-4 border border-gray-400 rounded-sm mr-2 flex items-center justify-center bg-white text-blue-500">
                                                        {data?.visible_in_marketplace && (
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="12" height="10" viewBox="0 0 12 10" fill="none">
                                                                <path d="M10.59 0L12 1.42L4 9.42L0 5.43L1.42 4.02L4 6.59L10.59 0Z" fill="#2C79E0"/>
                                                            </svg>
                                                        )}
                                                    </span>
                                                    <span>Yes</span>
                                                </div>
                                            </label>
                                        </div>
                                        <div className='flex items-center ml-2'>
                                            <label
                                                htmlFor="no_1"
                                            >
                                                <input
                                                    onChange={handleChange}
                                                    type="checkbox"
                                                    id="no_1"
                                                    name="visible_in_marketplace"
                                                    value={false}
                                                    checked={data?.visible_in_marketplace === false}
                                                    className="hidden peer"
                                                />
                                                <div className='flex items-center text-center'>
                                                    <span style={{color: 'rgb(59 130 246)'}} htmlFor="yes" className="w-4 h-4 border border-gray-400 rounded-sm mr-2 flex items-center justify-center bg-white peer-checked:bg-blue-500">
                                                        {data?.visible_in_marketplace === false && (
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="12" height="10" viewBox="0 0 12 10" fill="none">
                                                                <path d="M10.59 0L12 1.42L4 9.42L0 5.43L1.42 4.02L4 6.59L10.59 0Z" fill="#2C79E0"/>
                                                            </svg>
                                                        )}
                                                    </span>
                                                    <span>No</span>
                                                </div>
                                            </label>
                                        </div>
                                </Grid> :
                                <Grid item xs={12} className="flex justify-between items-center text-[#5D6E81] text-[14px]">
                                    <span className="font-medium w-full">{item.label}</span>
                                    <TextField
                                        size="small"
                                        variant="standard"
                                        onChange={(e) => onChangePrice(e, item.key)}
                                        margin="normal"
                                        fullWidth
                                        name={item.key}
                                        value={item.value}
                                        placeholder={item.label}
                                        InputProps={{
                                            disableUnderline: true,
                                            style: {fontSize: 14, color:'#5D6E81', fontWeight:700, fontFamily: 'Poppins' }
                                        }}
                                        InputLabelProps={{
                                            style: {fontSize: 14, color: '#5D6E81'}
                                        }}
                                        sx={{
                                            padding: '0',
                                        }}
                                    />
                                </Grid>}
                                {displayError(item.key)}
                                <Grid item xs={12} className="flex justify-between">
                                    <Divider className="w-10/12 text-[#8C8D8D]" />
                                </Grid>
                            </React.Fragment>
                        )
                    })
                }
            </Grid>
            <Grid container sx={{mt: 1, padding: '10px 20px'}} className="section-details">
                <Grid item xs={12} className="text-[14px]">
                    <span className="text-[#4B7CBE] font-bold w-full text-[16px]">Internal Notes</span>
                    <TextField
                        multiline
                        rows={4}
                        size="small"
                        variant="standard"
                        margin="normal"
                        fullWidth
                        type="number"
                        placeholder="Maximum of 20000 characters."
                        maxLength="20000"
                        defaultValue={data ? data?.internal_note : ''}
                        onSelect={handleChange}
                        onChange={handleChange}
                        name="internal_note"
                        InputProps={{
                            disableUnderline: true,
                            style: {fontSize: 14, color:'#5D6E81', fontWeight:700, fontFamily: 'Poppins' }
                        }}
                        InputLabelProps={{
                            style: {fontSize: 14, color: '#5D6E81'}
                        }}
                        sx={{
                            padding: '0',
                        }}
                    />
                </Grid>
            </Grid>
            <Grid container sx={{mt: 1, padding: '10px 20px'}} className="section-details text-[14px]">
                <Grid item xs={12} className="flex justify-between">
                    <span className="text-[#4B7CBE] font-bold text-[16px]">Custom Data</span>
                </Grid>
                {
                    !!customColumns &&  customColumns.length > 0 && customColumns?.map((customColumn, index) => (
                        <>
                            {
                                customColumn.type == 'boolean' &&
                                    <Grid item xs={12} key={index} className="flex items-center text-[#5D6E81] text-[14px] py-[14px] font-bold">
                                        <span className="font-medium w-1/2">{customColumn.label}</span>
                                            <div className='flex items-center'>
                                                <label
                                                    htmlFor={`${customColumn.key}-yes`}
                                                >
                                                    <input
                                                        onChange={handleChangeCustomColumn}
                                                        onSelect={handleChangeCustomColumn}
                                                        type="checkbox"
                                                        value={true}
                                                        className="hidden peer"
                                                        id={`${customColumn.key}-yes`}
                                                        name={customColumn.key}
                                                        checked={formData?.custom_column_values?.[customColumn.key] === true}
                                                    />
                                                    <div className='flex items-center text-center'>
                                                        <span htmlFor={`${customColumn.key}-yes`} className="w-4 h-4 border border-gray-400 rounded-sm mr-2 flex items-center justify-center bg-white text-blue-500 indeterminate:bg-gray-300">
                                                            {formData?.custom_column_values?.[customColumn.key] && (
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="12" height="10" viewBox="0 0 12 10" fill="none">
                                                                    <path d="M10.59 0L12 1.42L4 9.42L0 5.43L1.42 4.02L4 6.59L10.59 0Z" fill="#2C79E0"/>
                                                                </svg>
                                                            )}
                                                        </span>
                                                        <span className='mt-1'>Yes</span>
                                                    </div>
                                                </label>
                                            </div>
                                            <div className='flex items-center ml-2'>
                                                <label
                                                    htmlFor={`${customColumn.key}-no`}
                                                >
                                                    <input
                                                        onChange={handleChangeCustomColumn}
                                                        onSelect={handleChangeCustomColumn}
                                                        type="checkbox"
                                                        value={false}
                                                        id={`${customColumn.key}-no`}
                                                        name={customColumn.key}
                                                        checked={formData?.custom_column_values?.[customColumn.key] === false}
                                                        className="hidden peer"
                                                    />
                                                    <div className='flex items-center text-center'>
                                                        <span style={{color: 'rgb(59 130 246)'}} htmlFor={`${customColumn.key}-no`}className="w-4 h-4 border border-gray-400 rounded-sm mr-2 flex items-center justify-center bg-white peer-checked:bg-blue-500">
                                                            {formData?.custom_column_values?.[customColumn.key]  === false && (
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="12" height="10" viewBox="0 0 12 10" fill="none">
                                                                    <path d="M10.59 0L12 1.42L4 9.42L0 5.43L1.42 4.02L4 6.59L10.59 0Z" fill="#2C79E0"/>
                                                                </svg>
                                                            )}
                                                        </span>
                                                        <span className='mt-1'>No</span>
                                                    </div>
                                                </label>
                                            </div>

                                    </Grid>
                            }
                            {
                                customColumn.type == 'text' &&
                                    <Grid item xs={12} key={index} className="flex items-center text-[#5D6E81] text-[14px] font-bold">
                                        <span className="font-medium w-full truncate max-w-full">{customColumn.label}</span>
                                        <TextField
                                            key={customColumn.key}
                                            defaultValue={data.custom_column_values ? data?.custom_column_values?.[customColumn.key] : ''}
                                            onChange={handleChangeCustomColumn}
                                            type="text"
                                            name={customColumn.key}
                                            placeholder={customColumn.label}
                                            className="border-0 m-0 p-0 w-full"
                                            size="small"
                                            variant="standard"
                                            margin="normal"
                                            fullWidth
                                            InputProps={{
                                                disableUnderline: true,
                                                style: {fontSize: 14, color:'#5D6E81', fontWeight:700, fontFamily: 'Poppins' }
                                            }}
                                            InputLabelProps={{
                                                style: {fontSize: 14, color: '#5D6E81'}
                                            }}
                                            sx={{
                                                padding: '0',
                                            }}
                                        />

                                    </Grid>
                            }
                            {
                                customColumn.type == 'float' &&
                                    <Grid item xs={12} key={index} className="flex items-center text-[#5D6E81] text-[14px] font-bold">
                                        <span className="font-medium w-full truncate max-w-full">{customColumn.label}</span>
                                        <TextField
                                            key={customColumn.key}
                                            defaultValue={data.custom_column_values ? data?.custom_column_values?.[customColumn.key] : ''}
                                            onChange={handleChangeCustomColumn}
                                            type="number"
                                            name={customColumn.key}
                                            placeholder={customColumn.label}
                                            className="border-0 m-0 p-0 w-full"
                                            size="small"
                                            variant="standard"
                                            margin="normal"
                                            fullWidth
                                            InputProps={{
                                                disableUnderline: true,
                                                style: {fontSize: 14, color:'#5D6E81', fontWeight:700, fontFamily: 'Poppins' }
                                            }}
                                            InputLabelProps={{
                                                style: {fontSize: 14, color: '#5D6E81'}
                                            }}
                                            sx={{
                                                padding: '0',
                                            }}
                                        />

                                    </Grid>
                            }
                            {
                                customColumn.type == 'date' &&
                                    <Grid item xs={12} key={index} className="flex items-center text-[#5D6E81] text-[14px] font-bold py-[14px]">
                                        <span className="font-medium w-full truncate max-w-full">{customColumn.label}</span>
                                        <DatePickerFlatMobile
                                           name={customColumn.key}
                                           title={customColumn.label}
                                           handlechangePicker={handleChangePickerCustomColumn}
                                           defaultValue={data?.custom_column_values ? data?.custom_column_values?.[customColumn.key] : null}
                                        />

                                    </Grid>
                            }
                            {displayError(customColumn.key)}
                            <Grid item xs={12} className="flex justify-between">
                                <Divider className="w-10/12 text-[#8C8D8D]" />
                            </Grid>
                        </>
                    ))
                }
                <Grid item xs={12} className="flex justify-between pt-10 pb-10 gap-2">
                    <Button
                        variant="outlined"
                        onClick={onClose}
                        sx={{ textTransform: 'none', boxShadow: 'none', border: '1px solid #C5CAD0', color: '#000', borderRadius: '0px'}}
                        className="w-[80px] h-[40px] whitespace-nowrap normal-case text-[14px]"
                    >
                        Cancel
                    </Button>
                    <Button
                        onClick={handleDeleteProduct}
                        variant="outlined"
                        sx={{
                            textTransform: 'none',
                            boxShadow: 'none',
                            border: '1px solid #C5CAD0',
                            backgroundColor: '#EE8B8B',
                            color: '#fff',
                            borderRadius: '0px',
                            '&:hover, &:active, &:focus': {
                                backgroundColor: '#EE8B8B',
                                color: '#fff',
                                borderColor: '#C5CAD0'
                            }
                        }}
                        className="w-[135px] h-[40px] whitespace-nowrap normal-case text-[14px]"
                    >
                        Delete
                    </Button>
                    <Button
                        onClick={handleSubmit}
                        variant="outlined"
                        color="success"
                        sx={{
                            textTransform: 'none',
                            boxShadow: 'none',
                            border: '1px solid #C5CAD0',
                            backgroundColor: '#4B7CBE',
                            color: '#fff',
                            borderRadius: '0px',
                            '&:hover, &:active, &:focus': {
                                backgroundColor: '#4B7CBE',
                                color: '#fff',
                                borderColor: '#C5CAD0'
                            }
                        }}
                        className="w-[80px] h-[40px] whitespace-nowrap normal-case text-[14px]"
                                        >
                        Update
                    </Button>
                </Grid>
            </Grid>
        </DialogContent>
    </CustomMobileModal>
  )
}

const mapStateToProps = (state, props) => {
	return {
		globalColumnConfigs: state.globalColumnConfigs,
	}
}

export default connect(mapStateToProps, null)(EditWatchModal)
