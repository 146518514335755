import {useState} from 'react'
import {Globals} from '../Context'
import Privacy from '../Components/Privacy'
import {NavLink} from 'react-router-dom'
import isEmpty from 'lodash/isEmpty'

const PasswordSetting = () => {
	const [loading, setLoading] = useState(false)
	const [formErrors, updateFormErrors] = useState({})
	const [email, setEmail] = useState()
	const [emailSent, setEmailSent] = useState(false)
	const handleSubmit = (event) => {
		event.preventDefault()
		let errors = {}
		setLoading(true)
		if (isEmpty(email)) {
			const errors = {
				email: 'Email is required.',
			}
			setLoading(false)
			return updateFormErrors(errors)
		}
		Globals.New_Axios()
			.post(`${Globals.NEW_API_URL}/password`, {
				///token: token,
				user: {email: email},
			})
			.then(response => {
				setEmailSent(true)
				setLoading(false)
				if(!!errors) {
					updateFormErrors({})
				}
			})
			.catch(error => {
				errors = {
					...errors,
					error: 'Something went wrong please try again later.',
				}
				setLoading(false)
				return updateFormErrors(errors)
			})
	}
	const displayError = key => {
		if (!isEmpty(formErrors[key])) return <div className="my-2 rounded bg-red-200 p-1 px-4 font-medium text-red-700">{formErrors[key]}</div>
	}
	return (
		<div className="flex md:flex-row flex-col-reverse w-screen sm:h-auto mpt-5 lg:pt-0 lg:h-screen bgLinearBlue">
			{loading && (
				<div className="bg-white/50 bottom-0 fixed flex items-center justify-center left-0 right-0 rounded-lg top-0 z-50">
					<div role="status">
						<svg aria-hidden="true" className="w-8 h-8 mr-2 text-gray-200 animate-spin fill-blue-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path
								d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
								fill="currentColor"
							/>
							<path
								d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
								fill="currentFill"
							/>
						</svg>
					</div>
				</div>
			)}

			<div className="md:w-2/3 flex flex-col items-center justify-center bgLogin md:pb-0 pb-16 md:pt-0 pt-10">
				<div className="flex flex-col font-bold items-center md:items-start text-xl md:w-96 w-80">
					<div>
						<h3 className="font-normal md:pt-8 pt-4 text-sm tracking-wider">
							<NavLink to={'/login'}>
								{' '}
								<strong className="text-blue font-semibold">{'< Back'}</strong>
							</NavLink>
						</h3>
						<div>Reset Password!</div>
						<div className="font-normal  pt-4 text-sm tracking-wider">Enter the email associated with your account and we will send an email with instructions to reset your password.</div>
					</div>
				</div>
				<form className="mt-5 md:w-96 w-80" onSubmit={handleSubmit}>
					<div className="grid grid-cols-1 gap-2">
						{!emailSent && (
							<div className="mt-2">
								<label htmlFor="email" className="text-base bg-transparent font-normal py-2 block">
									Email*
								</label>
								<div className="outlin relative w-full">
									<input
										type="email"
										name="email"
										placeholder="Email Address"
										className="rounded border block p-4 w-full bg-white appearance-none outline-none text-black text-sm"
										value={email}
										onChange={e => {
											setEmail(e.target.value)
										}}
									/>
								</div>
								{displayError('email')}
							</div>
						)}

						{emailSent && (
							<div class="bg-teal-100 border-t-4 border-teal-500 rounded-b text-teal-900 px-4 py-3 shadow-md" role="alert">
								<div class="flex">
									<div class="py-1">
										<svg class="fill-current h-6 w-6 text-teal-500 mr-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
											<path d="M2.93 17.07A10 10 0 1 1 17.07 2.93 10 10 0 0 1 2.93 17.07zm12.73-1.41A8 8 0 1 0 4.34 4.34a8 8 0 0 0 11.32 11.32zM9 11V9h2v6H9v-4zm0-6h2v2H9V5z" />
										</svg>
									</div>
									<div>
										<p class="font-bold">Reset password email sent</p>
										<p class="text-sm">Please check your email for the reset link.</p>
									</div>
								</div>
							</div>
						)}
					</div>

					<div className="mt-4 text-center">{displayError('error')}</div>

					<div className="w-full mt-4">
						<>
							<button className="w-full p-4 rounded text-white cursor-pointer  font-medium bg-[#2C79E0] " type="button" onClick={handleSubmit}>
								{emailSent ? 'Resend Reset Password Link' : 'Send Reset Password Link'}
							</button>
						</>
					</div>
				</form>
				<Privacy />
			</div>
			<div className="md:w-1/3 md:flex hidden flex-col  justify-center text-white px-16">
				<h1 className="text-5xl font-bold">ELEFTA</h1>
				<p className="font-medium leading-7 text-sm pt-10">
					The most advanced watch-specific inventory system and software <br />
					<br />
					Built by watch dealers for watch dealers
				</p>
			</div>
			<div className="md:hidden flex items-center justify-center mt-3 mb-3">
				<img src="/logo-trans-white.png" className=" relative" width="200" height={'auto'} alt="Elefta"
					 loading="lazy" />
			</div>
		</div>
	)
}
export default PasswordSetting
