import React, { useEffect, useState } from 'react'
import {connect} from 'react-redux'

import { toYesNo } from '../../utils/LabelsHelper'
import WatchDetailImagesMobile from "./WatchDetailImagesMobile"
import * as collections from '../../utils/collections'
import {Globals} from '../../Context'
import isBoolean from 'lodash/isBoolean'
import find from 'lodash/find'
import lowerCase from 'lodash/lowerCase'
import map from 'lodash/map'

const WatchDetailMobile = props => {
  const [visibleFields, setVisibleFields] = useState([])
  const {selectedThread, globalColumnConfigs} = props
  const { watch } = selectedThread

  useEffect(() => {
    setVisibleFields(globalColumnConfigs.filter(item => item.visible).map(item => item.key))
  }, [])

  const renderBlockTitle = (label, value) => {
    return(
      <div className="grid grid-flow-row-dense grid-cols-2 mb-[5px]" key={label}>
        <div className="text-[#4B7CBE] font-bold text-[16px]">
          {label}
        </div>
        {value && (
          <div className="font-bold text-[16px]" >
            {value}
          </div>
        )}
      </div>
    )
  }

  const renderBlockItem = (label, value) => {
    return(
      <div className="grid grid-flow-row-dense grid-cols-2" key={label}>
        <div className="text-[14px]">
          {label}
        </div>
        {isBoolean(value) ? (
          <div className="font-bold text-[14px] text-[#5D6E81]" >
            {toYesNo(value)}
          </div>
        ) :
          <div className="font-bold text-[14px] text-[#5D6E81]" >
          {value}
        </div>
        }
      </div>
    )
  }

  const renderStatusProductDetail = (status, statusDetail) => {
    if(status === 'sold_to') return
    const watchStatus = find(collections.inventoryStatusesCollection, {value: status})
    if(!!watchStatus?.option)  return `${watchStatus.option} ${!!statusDetail ? statusDetail : ''}`
  }

  const renderConditionProductDetail = (condition) => {
		const watchCondition = find(collections.inventoryConditionsCollection, {value: condition})
    if(!!watchCondition) return `${watchCondition.option}`
  }

  const renderWarrantyPapersProductDetail = (brand, warrantyPapers) => {
    const warrantyCardsList = Globals.warrantyCardsList[lowerCase(brand)] || Globals.warrantyCardsList.other
		const watchWarrantyPapers = find(warrantyCardsList, {value: warrantyPapers})
    if(!!watchWarrantyPapers) return `${watchWarrantyPapers.option}`
  }

  return (
    <div className="bg-white w-full overflow-y-auto">
      <div className="col-span-5">
        {/* <ProductMessageGallery product={selectedThread.watch} mobileView={true}/> */}
        {!!watch && <WatchDetailImagesMobile data={watch} openPreviewModal={props.openPreviewModal}/>}

       {props.isVisibleProducDetail ?
          <div className="py-2 p-[15px]">
            { renderBlockTitle('Status', renderStatusProductDetail(watch?.status, watch?.status_details)) }
            {watch?.status === 'sold_to' &&
              <>
                { renderBlockItem('Sold To', watch?.status_details) }
                { renderBlockItem('Sold Date', watch?.sold_date_formated) }
                { renderBlockItem('Sold Price', watch?.sold_price) }
              </>
            }
          </div> : <div className="py-2 p-[15px]">
            { renderBlockTitle('Status', watch?.status) }
          </div>
       }
        {(selectedThread?.watch?.space?.id || selectedThread?.watch?.space_id) === props.currentSpace.id ?
            (
              <React.Fragment>
                <div className="py-2 bg-[#E9E9E9] p-[15px]">
                  { renderBlockTitle('Basic Info', '') }
                  { renderBlockItem('Dealer SKU', watch?.sku) }
                  { renderBlockItem('Reference Number', watch?.reference_number) }
                  { renderBlockItem('Brand', watch?.full_brand) }
                  { visibleFields.includes('series') && renderBlockItem('Series', watch?.series) }
                  { visibleFields.includes('series_number') && renderBlockItem('Series Number', watch?.series_number) }
                  { visibleFields.includes('dial') && renderBlockItem('Dial', watch?.dial) }
                  { visibleFields.includes('bezel') && renderBlockItem('Bezel', watch?.bezel) }
                  { visibleFields.includes('bracelet') && renderBlockItem('Bracelet', watch?.bracelet) }
                </div>

                <div className="py-2 p-[15px]">
                  { !Globals.isAllScopeOfDeliveryShown(visibleFields) && renderBlockTitle('Scope of Delivery', '') }
                  <>
                    {
                      visibleFields.includes('condition') && (
                        !props.isVisibleProducDetail ?
                        renderBlockItem('Condition', watch?.condition)  :  renderBlockItem('Condition', renderConditionProductDetail(watch?.condition))
                      )
                    }
                  </>
                  <>
                    {
                      visibleFields.includes('warranty_papers') && (
                        !props.isVisibleProducDetail ?
                        renderBlockItem('Warranty Paper/Card', watch?.warranty_papers)
                                        :  renderBlockItem('Warranty Paper/Card', renderWarrantyPapersProductDetail(watch?.brand, watch?.warranty_papers))
                      )
                    }
                  </>
                  <>
                    {
                      visibleFields.includes('warranty') && (
                        !props.isVisibleProducDetail ?
                        renderBlockItem('Warranty Date', watch?.warranty_date)
                          :
                        renderBlockItem('Warranty Date', watch?.display_warranty)
                      )
                    }
                  </>
                  { visibleFields.includes('box') && renderBlockItem('Box', toYesNo(watch?.box)) }
                  { visibleFields.includes('link_count') && renderBlockItem('Link Count', watch?.link_count) }
                  { visibleFields.includes('addition_details') && renderBlockItem('Additional Details', watch?.addition_details) }
                </div>

                <div className="py-2 bg-[#E9E9E9] p-[15px]">
                  { !Globals.isAllPriceInfoShown(visibleFields) && renderBlockTitle('Price Info', '') }
                  { visibleFields.includes('msrp_price') && renderBlockItem('MSRP Price', watch?.msrp_price || watch?.msrp_price == 0 ? `$${watch?.msrp_price_formatted}` : '')}
                  { visibleFields.includes('wholesale_price') && renderBlockItem('Wholesale Price', watch?.wholesale_price || watch?.wholesale_price == 0 ? `$${watch?.wholesale_price_formatted}` : '')}
                  { visibleFields.includes('cost') && renderBlockItem('Cost', watch?.cost || watch?.cost == 0 ? `$${watch?.cost_formatted}` : '') }
                  { visibleFields.includes('date_purchased') && renderBlockItem('Date Purchased', watch?.date_purchased_formatted) }
                  {/* TODO: need the detail of Vendor / Partner */}
                  { visibleFields.includes('vendor') && renderBlockItem('Vendor/Partner', watch?.vendor) }
                </div>

                <div className="py-2 p-[15px]">
                  { renderBlockTitle('Marketplace Info', '') }
                  { renderBlockItem('Visible in Marketplace', toYesNo(watch?.visible_in_marketplace)) }
                  { visibleFields.includes('online_price') && renderBlockItem('Online Price', watch?.online_price || watch?.online_price == 0 ? `$${watch?.online_price_formatted}` : '')}
                </div>

                <div className="py-2 bg-[#E9E9E9] p-[15px]">
                  { renderBlockTitle('Internal Notes', '') }
                  { renderBlockItem(watch?.internal_note, '') }
                </div>

                <div className="py-2 p-[15px]">
                  { renderBlockTitle('Custom Data', '') }
                  {
                    map(watch?.custom_column_values || {}, (value, key) => renderBlockItem(key, value))
                  }
                </div>
              </React.Fragment>
            ) :
            (
              <React.Fragment>
                <div className="py-2 bg-[#E9E9E9] p-[15px]">
                  { renderBlockTitle('Basic Info', '') }
                  { renderBlockItem('Dealer SKU', watch?.sku) }
                  { renderBlockItem('Reference Number', watch?.reference_number) }
                  { renderBlockTitle('Price', watch?.wholesale_price_formatted) }
                </div>

                <div className="py-2 p-[15px]">
                  { renderBlockTitle('Watch Details', '') }
                  { renderBlockItem('Brand', watch?.full_brand) }
                  { renderBlockItem('Series', watch?.series) }
                  { renderBlockItem('Series Number', watch?.series_number) }
                  { renderBlockItem('Dial', watch?.dial) }
                  { renderBlockItem('Bezel', watch?.bezel) }
                  { renderBlockItem('Bracelet', watch?.bracelet) }
                  { renderBlockItem('Condition', watch?.condition) }
                  { renderBlockItem('Warranty Paper/Card', watch?.warranty_papers) }
                  { renderBlockItem('Box', toYesNo(watch?.box)) }
                  { renderBlockItem('Link Count', watch?.link_count) }
                  { renderBlockItem('Additional Details', watch?.addition_details) }
                </div>
              </React.Fragment>
            )
          }
      </div>
    </div>
  )
}

const mapStateToProps = (state, props) => {
  return {
    profile: state.profile,
    currentSpace: state.currentSpace,
		globalColumnConfigs: state.globalColumnConfigs,
  }
}

const mapActionsToProps = {}

export default connect(mapStateToProps, mapActionsToProps)(WatchDetailMobile)
