import { useState, useEffect } from 'react'
import moment from 'moment'
import Slider from 'react-slick'

import CardWatch from './CardWatch'
import TitleCard from './TitleCard'
import {Globals} from '../../Context'
import isEmpty from 'lodash/isEmpty'


const TopSelling = ({dataList}) => {
	const [openDropDown, setOpenDropDown] = useState(false)
	const [topSellingYear, setTopSellingYear] = useState('current')

	var settings = {
		dots: false,
		infinite: true,
		speed: 500,
		slidesToShow: 5,
		slidesToScroll: 5,
	}

	const labelDropDown = () => {
		if(topSellingYear === 'current') {
			return "This Year"
		} else {
			return "Previous Year"
		}
	}

	const handleChangeDropDown = (value) => {
		setTopSellingYear(value)
		setOpenDropDown(false)
		getData(value)
	}

	const getData = async  (value) => {
		let year = 2024
		if(value === 'current') {
			year = moment().year()
		} else {
			year = (moment().year()) -1
		}
		await Globals.New_Axios()
		.get(`${Globals.NEW_API_URL}/${Globals.ENDPOINTVERSION}/spaces/${Globals.SELECTEDSPACEID}/analytics/top_selling_items?top_selling_year=${year}`)
		.then(response => {
		})
	}

	useEffect(() => {
		getData(topSellingYear)
	}, [topSellingYear])

	return (
		<div className="relative flex flex-col bg-white  py-5 px-4 rounded-xl drop-shadow-md xl:mt-0 mt-2 relative">
			{isEmpty(dataList) &&
				<div className="z-10 absolute top-0 left-0 right-0 bottom-0 bg-gray-100 opacity-90 flex items-center justify-center">
					<div className="absolute top-0 left-0 right-0 bottom-0 flex justify-center items-center z-50">
						<button
							type="button"
							className="inline-flex items-center px-4 py-2 leading-6 text-sm shadow rounded-md text-white bg-blue transition ease-in-out duration-150 cursor-not-allowed"
							disabled
						>
							<svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
								{' '}
								<circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4" />
								<path
									className="opacity-75"
									fill="currentColor"
									d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
								/>
							</svg>
							<span>Loading... </span>
						</button>
					</div>
				</div>
			}
			<div className="flex items-center pb-5 relative mb-5 w-full border-b">
				<TitleCard title={'Top Selling Items'} />
				<div className="flex justify-end items-center absolute right-0 text-blue cursor-pointer w-[150px]">
					<div>
						<div className='flex justify-between items-center w-[150px] border-[1px] p-2' onClick={() => {setOpenDropDown(!openDropDown)}}>
							<p className="mr-5 text-[15px]">{labelDropDown()}</p>
							<svg width="10" height="6" viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg">
								<path
									fillRule="evenodd"
									clipRule="evenodd"
									d="M0.292893 0.292893C0.683416 -0.097631 1.31658 -0.097631 1.7071 0.292893L4.99999 3.58579L8.29288 0.292893C8.6834 -0.0976311 9.31657 -0.0976311 9.70709 0.292893C10.0976 0.683417 10.0976 1.31658 9.70709 1.70711L5.7071 5.70711C5.31657 6.09763 4.68341 6.09763 4.29289 5.70711L0.292893 1.70711C-0.0976309 1.31658 -0.0976309 0.683417 0.292893 0.292893Z"
									fill="#2C79E0"
								/>
							</svg>
						</div>
						{openDropDown &&
							<div className={'absolute bg-[#fff] text-[14px] border-[1px] px-2 w-full z-[999999]'}>
								<div className='flex items-center border-b-[1px] p-2' onClick={() => handleChangeDropDown("current")}>
									<p>This Year</p>
								</div>
								<div className='flex items-center p-2' onClick={() => handleChangeDropDown("previous")}>
									<p>Previous Year</p>
								</div>
							</div>
						}
					</div>
				</div>
			</div>
			{
				!!dataList.top_selling_items &&
					<>
							{dataList.top_selling_items.length > 5 ?
						<Slider {...settings} className="inline-flex md:w-full w-80">
							{dataList.top_selling_items?.map((value, index) => (
								<CardWatch key={index} image={value.image} refNumber={value.ref_number} quantity={value.quantity}/>
							))}
						</Slider> :
						<div className='inline-flex md:w-full w-80 justify-around'>
							{dataList.top_selling_items.map((value, index) => (
								<CardWatch key={index} image={value.image} refNumber={value.ref_number} quantity={value.quantity}/>
							))}
						</div>
					}
					</>
			}
		</div>
	)
}

export default TopSelling
