import React, { useState } from 'react'
import { Globals } from '../../../Context'
import Classnames from 'classnames'
import lowerCase from 'lodash/lowerCase'
import find from 'lodash/find'

import * as collections from '../../../utils/collections'

const BuildItemField = ({ item, formData, openMarkUpPrice, handleVisibleField, setFormData, view }) => {
    const [visibleField, setVisibleField] = useState(item.visible)

    const handleOnClickHideField = (item) => {
        handleVisibleField(item, !visibleField)
        setVisibleField(!visibleField)
    }

    const maskText = (input) => {
        if (input?.length > 3) {
          return input.substring(0, 3) + "******";
        }
        return input;
      };

    const buildTextData = () => {
        if(item.key === "box") {
            return formData[item.key] === true ? "Yes" : "No"
        } else if(item.key === "condition") {
            const watchCondition = find(collections.inventoryConditionsCollection, {value: formData?.condition})
            return watchCondition?.option
        } else if(item.key === "warranty") {
            return formData['display_warranty']
        } else if(item.key === "serial_number") {
            return view === 'marketplace' ? formData[item.key] : maskText(formData[item.key])
        } else if(item.key === "warranty_papers") {
            const warrantyCardsList = Globals.warrantyCardsList[lowerCase(formData?.brand)] || Globals.warrantyCardsList.other
            const watchWarrantyPapers = find(warrantyCardsList, {value: formData?.warranty_papers})
            return watchWarrantyPapers?.option
        } else if(item.key === "wholesale_price") {
            return formData[item.key] ? `$${Globals.formatCurrency(formData[item.key])}` : ''
        } else {
            return formData[item.key]
        }
    }

    const handleItemPriceChange = (event) => {
        setFormData({
            ...formData,
            wholesale_price: Globals.formatCurrency(event.target.value),
        })
    }

    return (
        <div className='grid grid-cols-3 gap-2'>
            <div className={Classnames('flex font-normal text-[15px] leading-[30px] text-[#000000] w-[250px]', {'opacity-20': !visibleField})}>
                <p className='ml-16'>{item.label}</p>
            </div>
             {openMarkUpPrice  && item.label == "Price" ?
                <div className='flex justify-center'>
                    <input
                        onChange={e => handleItemPriceChange(e)}
                        type="text"
                        className="ml-16 border px-2 py-1 rounded w-[90px] h-[23px] text-[black] text-[15px]"
                        value={formData[item.key] ? Globals.formatCurrency(formData[item.key]) : ''}
                    />
                </div> :
                 <div className={Classnames('font-medium text-[15px] leading-[30px] text-[#000000] w-[250px]', {'opacity-20': !visibleField})}>
                    <p className='ml-16 truncate'>{buildTextData()}</p>
                </div>
            }
            {item.label !== "SKU" &&
                <div className='flex justify-end items-center mr-3 cursor-pointer'>
                    {visibleField ?
                        <img  onClick={() => handleOnClickHideField(item)} className='mr-1' src="/Images/watch.svg" /> :
                        <img  onClick={() => handleOnClickHideField(item)} className='' src="/Images/hide_watch.svg" />
                    }
                </div>
            }
        </div>
    );
}


export default BuildItemField
