import React, { useState, useEffect } from 'react'
import { styled } from '@mui/material/styles';
import CloseIcon from '@mui/icons-material/Close';
import ControlPointOutlinedIcon from '@mui/icons-material/ControlPointOutlined';
import moment from 'moment';

import {Globals} from '../../../Context'

import ListModalMobile from '../NoteModal/ListModalMobile'
import isEmpty from 'lodash/isEmpty'
import DialogContent from '@mui/material/DialogContent'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import TextField from '@mui/material/TextField'
import DialogActions from '@mui/material/DialogActions'
import Stack from '@mui/material/Stack'
import Button from '@mui/material/Button'

const CustomMobileModal = styled(Dialog)(({ theme }) => ({
    '& .MuiDialog-container': {
        height: '100%',
    },
    '& .MuiDialog-paper': {
        width: '100%',
        maxWidth: '100%',
        borderRadius: '20px 20px 0 0',
        margin: 0,

    },
}));

const formatDate = (date) => {
    return moment(date).format("M/D/YY @ HH:mm");
}

const actions = [
    {
        name: 'show',
        label: 'Notes',
    },
    {
        name: 'edit',
        label: 'Edit Note',
    },
    {
        name: 'add',
        label: 'Add Note',
    },
];

const NoteModal = ({isOpen, closeModal, data}) => {
    const [action, setAction] = useState(actions[0]);
    const [noteData, setNoteData] = useState([]);
    const [formErrors, setFormErrors] = useState([])
    const [selectedNote, setSelectedNote] = useState({
        id: null,
        note: '',
    });

	const loadNotes = () => {
		Globals.New_Axios()
			.get(`${Globals.NEW_API_URL}/${Globals.ENDPOINTVERSION}/spaces/${Globals.SELECTEDSPACEID}/watches/${data?.id}/notes`)
			.then(async response => {
                setNoteData(response.data.notes)
			}).catch(error => {
				console.log(error)
			})
	}

	useEffect(() => {
		loadNotes()
	}, [])

    const handleSubmit = () => {
        if (action.name === 'edit') {
            let errors = []
            if(selectedNote?.note == '') {
                errors = {...errors, note: 'Note is required'}
            }
            setFormErrors(errors)
            if(errors.length > 0) return
            Globals.New_Axios()
                .patch(`${Globals.NEW_API_URL}/${Globals.ENDPOINTVERSION}/spaces/${Globals.SELECTEDSPACEID}/watches/${data.id}/notes/${selectedNote.id}`, {
                    note: {content: selectedNote.note},
                })
                .then(async response => {
                        const newNotes = noteData.map(note => {
                            if (note.id === response.data.root_id) {
                                if (isEmpty(note.children)) note.children = []
                                note.children = [response.data, ...note.children]
                            }
                            return note
                        })
                        setAction(actions[0]);
                        setNoteData(newNotes)
                        setFormErrors([])
                })
        }
        if (action.name === 'add') {
            let errors = []
            if(selectedNote?.note == '') {
                errors = {...errors, note: 'Note is required'}
            }
            setFormErrors(errors)
            handleAddNote();
        }
    }

    const handleAddNote = () => {
        if (!isEmpty(selectedNote.note)) {
			Globals.New_Axios()
				.post(`${Globals.NEW_API_URL}/${Globals.ENDPOINTVERSION}/spaces/${Globals.SELECTEDSPACEID}/watches/${data.id}/notes`, {
					note: {content: selectedNote.note	},
				})
				.then(async response => {
					setNoteData([response.data, ...noteData])
                    setAction(actions[0])
                    setFormErrors([])
				})
				.catch(error => {
				})
		}
    }

    const handleEditNote = (note) => {
        setAction(actions[1]);
        setSelectedNote({
            id:  note.id,
            note: note.content,
        });
    }

    const handleDeleteNote = (note) => {
        if (!note.id) return
        Globals.New_Axios()
            .delete(`${Globals.NEW_API_URL}/${Globals.ENDPOINTVERSION}/spaces/${Globals.SELECTEDSPACEID}/watches/${data.id}/notes/${note.id}`)
            .then(async response => {
                const newNotes = noteData.filter(value => value.id !== note.id)
                setNoteData(newNotes)
            }).catch(error => {
                console.log(error)
            })
    }

    const handleCancelModal = () => {
        if (action.name === 'edit' || action.name === 'add' || action.name === 'delete') {
            setAction(actions[0]);
            setFormErrors([])
        } else {
            closeModal();
        }
    }

    const displayError = key => {
        if (!isEmpty(formErrors[key])) return <div className="text-[14px] md:[16px] my-2 rounded bg-red-200 p-1 px-4 font-medium text-red-700">{formErrors[key]}</div>
    }

    const handleCloseModal = () => {
        closeModal();
        setAction(actions[0]);
        setFormErrors([])
    }

    return (
        <CustomMobileModal
            open={isOpen}
            className='w-full'
            onClose={handleCloseModal}
            sx={{
                '& .MuiDialog-paper': {
                    width: '100%',
                    maxWidth: '100%',
                    maxHeight: 300,
                    minHeight: 289
                },
            }}
        >
            <DialogTitle
                className="bg-[#4B7CBE] text-white h-[49px] flex items-center justify-between rounded-t-[12px]"
            >
                <div className="flex justify-between items-center w-full">
                    <Box className="flex items-center">
                        <span className="font-bold uppercase ms-2 text-[22px]">{action.label}</span>
                        {action.name === 'show' &&
                            <ControlPointOutlinedIcon className="ms-2"
                                onClick={() => {
                                    setAction(actions[2]);
                                    setSelectedNote({
                                        id: null,
                                        note: '',
                                    });
                                }}
                            />
                        }
                    </Box>
                    <CloseIcon className="cursor-pointer font-bold" onClick={handleCancelModal} />
                </div>
            </DialogTitle>
            {/* ===================================================== */}
            {/* ====================== SHOW ========================= */}
            {/* ===================================================== */}

            {action.name === 'show' && (
                <React.Fragment>
                    <DialogContent className="mt-4">
                        <Grid container spacing={2} className="mt-12 mb-2">
                            {noteData.length === 0 && <Grid className='flex justify-center' item xs={12}>Nothing to show yet!</Grid>}
                            {noteData.map((note, index) => (
                                <Grid item xs={12} key={index}>
                                    <ListModalMobile note={note} formatDate={formatDate} handleEditNote={handleEditNote} handleDeleteNote={handleDeleteNote}/>
                                </Grid>
                            ))}
                        </Grid>
                    </DialogContent>
                </React.Fragment>
            )}
            {/* ===================================================== */}
            {/* ====================== CREATE/EDIT ========================= */}
            {/* ===================================================== */}
            {action.name !== 'show' && (
                <React.Fragment>
                    <DialogContent className="mt-4">
                        <Grid container className="mt-2">
                            <Grid item xs={12}>
                                <TextField
                                    placeholder="Add Details"
                                    multiline
                                    rows={4}
                                    value={selectedNote.note}
                                    fullWidth
                                    variant="outlined"
                                    InputLabelProps={{
                                        shrink: selectedNote.note ? true : false,
                                    }}
                                    onChange={(e) => setSelectedNote({
                                        ...selectedNote,
                                        note: e.target.value,
                                    })}
                                />
                                {displayError('note')}
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions className="mb-2">
                        <Stack
                            direction="row"
                            className="mx-auto"
                            spacing={2}
                            justifyContent={'center'}
                        >
                            <Button
                                variant="outlined"
                                sx={{ textTransform: 'none', boxShadow: 'none', border: '1px solid #C5CAD0', color: '#000'}}
                                className="w-[106px] h-[40px] whitespace-nowrap normal-case text-[14px]"
                                onClick={handleCancelModal}
                            >
                                Cancel
                            </Button>
                            <Button
                                variant="contained"
                                sx={{ textTransform: 'none', boxShadow: 'none' }}
                                className="w-[106px] h-[40px] normal-case whitespace-nowrap !bg-[#4B7CBE] text-[14]px"
                                onClick={handleSubmit}
                            >
                                Save
                            </Button>
                        </Stack>
                    </DialogActions>


                </React.Fragment>
            )}
        </CustomMobileModal>
    )
}

export default NoteModal
