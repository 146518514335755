import {Slate, Editable, withReact, useSlate} from 'slate-react'
import {Editor, Transforms, createEditor} from 'slate'
import {withHistory} from 'slate-history'
import ClassNames from 'classnames'
import EmojiPicker from 'emoji-picker-react'
import isHotkey from 'is-hotkey'
import React, {useEffect} from 'react'
import AttachmentIcon from '@mui/icons-material/Attachment';
import CloseIcon from '@mui/icons-material/Close';
import CircularProgress from '@mui/material/CircularProgress';
import map from 'lodash/map'
import isFunction from 'lodash/isFunction'
import isEmpty from 'lodash/isEmpty'

const SlateEditor = props => {
	const HOTKEYS = {
		'mod+b': 'bold',
		'mod+i': 'italic',
		'mod+u': 'underline',
		// 'mod+shift+c': 'code',
		'mod+shift+x': 'strike',
	}

	const isMarkActive = (editor, format) => {
		const marks = Editor.marks(editor)
		return marks ? marks[format] === true : false
	}

	const toggleMark = (editor, format) => {
		const isActive = isMarkActive(editor, format)
		if (isActive) return Editor.removeMark(editor, format)
		return Editor.addMark(editor, format, true)
	}

	const clearMark = () => {
		map(HOTKEYS, (mark, key) => {
			Editor.removeMark(editor, mark)
		})
	}

	//Element is to be removed.
	const Element = ({attributes, children, element}) => {
		const style = {textAlign: element.align}
		switch (element.type) {
			case 'block-quote':
				return (
					<blockquote style={style} {...attributes}>
						{children}
					</blockquote>
				)
			case 'bulleted-list':
				return (
					<ul style={style} {...attributes}>
						{children}
					</ul>
				)
			case 'heading-one':
				return (
					<h1 style={style} {...attributes}>
						{children}
					</h1>
				)
			case 'heading-two':
				return (
					<h2 style={style} {...attributes}>
						{children}
					</h2>
				)
			case 'list-item':
				return (
					<li style={style} {...attributes}>
						{children}
					</li>
				)
			case 'numbered-list':
				return (
					<ol style={style} {...attributes}>
						{children}
					</ol>
				)
			default:
				return (
					<p style={style} {...attributes}>
						{children}
					</p>
				)
		}
	}

	const Leaf = ({attributes, children, leaf}) => {
		if (leaf.bold) {
			children = <strong>{children}</strong>
		}

		if (leaf.italic) {
			children = <em>{children}</em>
		}

		if (leaf.underline) {
			children = <u>{children}</u>
		}

		if (leaf.code) {
			children = <code>{children}</code>
		}

		if (leaf.strike) {
			children = <strike>{children}</strike>
		}

		if (leaf.emoji) {
			children = <span>{leaf.emojiData.emoji}</span>
		}

		return <span {...attributes}>{children}</span>
	}

	const MarkButton = ({format, icon}) => {
		const editor = useSlate()
		return (
			<button
				className={ClassNames('text-gray-600 hover:opacity-80 transition-all flex font-bold items-center justify-center w-6 h-6 rounded', {'bg-gray-200': isMarkActive(editor, format)})}
				onMouseDown={event => {
					event.preventDefault()
					toggleMark(editor, format)
				}}
			>
				{icon}
			</button>
		)
	}
	const {resetFlag} = props
	const renderElement = React.useCallback(props => <Element {...props} />, [])
	const renderLeaf = React.useCallback(props => <Leaf {...props} />, [])
	const editor = React.useMemo(() => withHistory(withReact(createEditor())), [])
	const [isEmptyEditor, setIsEmptyEditor] = React.useState(true)
	const [isContentEmptyEditor, setIsContentEmptyEditor] = React.useState(true)
	const [emojiVisibility, setEmojiVisibility] = React.useState(false)
	const [editorContent, setEditorContent] = React.useState([])
	const [images, setImages] = React.useState([])
	const [loading, setLoading] = React.useState(false)

	const initialValue = [
		{
			type: 'paragraph',
			children: [{text: props.defaultText ? props.defaultText : ''}],
		},
	]

	const clearEditor = () => {
		return Transforms.delete(editor, {
			at: {
				anchor: Editor.start(editor, []),
				focus: Editor.end(editor, []),
			},
		})
	}

	useEffect(() => {
		// Get the last element in the editor
		Transforms.select(editor, Editor.end(editor, []))
	}, [editor, editorContent])

	useEffect(() => {
		if (resetFlag) {
			setImages([]);
			clearEditor()
			setLoading(false)
		}
	}, [resetFlag]);

	const handleKeyDownOperations = $event => {
		if(loading) {
			$event.preventDefault()
			return
		}

		const { isMobile } = props
		for (const hotkey in HOTKEYS) {
			if (isHotkey(hotkey)($event)) {
				$event.preventDefault()
				const mark = HOTKEYS[hotkey]
				toggleMark(editor, mark)
			}
		}

		if ($event.which === 13 && !$event.shiftKey) {
			$event.preventDefault()
			if ((isEmptyEditor && images.length === 0) || loading) return
			if (isFunction(props.hitEnter)) {
				setLoading(true)
				props.hitEnter(editorContent, null, images)
				setEmojiVisibility(false)
				clearMark(editor)
				setImages([]);
				clearEditor()
				if (isMobile && document.activeElement) {
					document.activeElement.blur();
				}
			}
		}
	}

	const handleSendMessage = () => {
		if (isEmptyEditor && images.length === 0) return

		if (isFunction(props.hitEnter)) {
			setLoading(true)
			props.hitEnter(editorContent, null, images)
			setImages([]);
			clearEditor()
			setEmojiVisibility(false)
			clearMark(editor)
		}
	}

	const handleAddImages = e => {
		const filesArray = Array.from(e.target.files);
		setImages(prevImages => [...prevImages, ...filesArray]);
	};

	return (
		// Add the editable component inside the context.
		<React.Fragment>
			<div className={`w-11/12 mx-auto overflow-x-auto ${loading ? 'opacity-50' : ''}`}>
                <div className={`flex gap-2 min-w-max ${loading ? 'blur-sm' : ''}`}>
                    {images.length > 0 && images.map((image, index) => (
                        <div key={index} className="relative">
                            <img src={URL.createObjectURL(image)} alt={`imagePreview ${index}`} className="w-24 h-36 object-cover rounded-lg" />
                            <button
                                onClick={() => {
                                    if (!loading) {
                                        const updatedImages = images.filter((_, i) => i !== index);
                                        setImages(updatedImages);
                                    }
                                }}
                                className="absolute top-0 right-0 p-1 bg-white rounded-full shadow-md"
                                disabled={loading}
                            >
                                <CloseIcon />
                            </button>
                        </div>
                    ))}
                </div>
            </div>
			<div className="relative elefta-editor-wrapper">
				<div className="absolute left-0 right-0 top-0 z-20">
					<div className="absolute bottom-2 left-0 right-0 pb-2">
						{emojiVisibility && (
							<EmojiPicker
								autoFocusSearch={false}
								previewConfig={{
									showPreview: false,
									defaultSkinTone: 'neutral',
									lazyLoadEmojis: true,
								}}
								onEmojiClick={(emojiData, $event) => {
									editor.insertNode({
										emoji: true,
										text: emojiData.unified,
										emojiData,
									})
									Transforms.select(editor, {
										offset: 0,
										path: [0, 0],
									})
								}}
								width="100%"
							/>
						)}
					</div>
				</div>
				<div className="px-5 elefta-editor-inner break-all">
					<Slate
						editor={editor}
						value={initialValue}
						onChange={event => {
							setIsEmptyEditor(event.map(t => !isEmpty(t.children.filter(tt => !isEmpty(tt.text.trim())))).includes(false))
							setIsContentEmptyEditor(event.map(t => !isEmpty(t.children.filter(tt => !isEmpty(tt.text)))).includes(false))
							setEditorContent(event)
							props.onChange(event)
						}}
					>
						<ul className="flex gap-2 py-1 relative -left-1.5">
							<li>
								<MarkButton
									format="bold"
									icon={
										<svg className="w-5 h-5" fill="currentColor" viewBox="0 0 16 16">
											{' '}
											<path d="M8.21 13c2.106 0 3.412-1.087 3.412-2.823 0-1.306-.984-2.283-2.324-2.386v-.055a2.176 2.176 0 0 0 1.852-2.14c0-1.51-1.162-2.46-3.014-2.46H3.843V13H8.21zM5.908 4.674h1.696c.963 0 1.517.451 1.517 1.244 0 .834-.629 1.32-1.73 1.32H5.908V4.673zm0 6.788V8.598h1.73c1.217 0 1.88.492 1.88 1.415 0 .943-.643 1.449-1.832 1.449H5.907z" />
										</svg>
									}
								/>
							</li>
							<li>
								<MarkButton
									format="italic"
									icon={
										<svg className="w-5 h-5" fill="currentColor" viewBox="0 0 16 16">
											{' '}
											<path d="M7.991 11.674 9.53 4.455c.123-.595.246-.71 1.347-.807l.11-.52H7.211l-.11.52c1.06.096 1.128.212 1.005.807L6.57 11.674c-.123.595-.246.71-1.346.806l-.11.52h3.774l.11-.52c-1.06-.095-1.129-.211-1.006-.806z" />
										</svg>
									}
								/>
							</li>
							<li>
								<MarkButton
									format="underline"
									icon={
										<svg className="w-5 h-5" fill="currentColor" viewBox="0 0 16 16">
											{' '}
											<path d="M5.313 3.136h-1.23V9.54c0 2.105 1.47 3.623 3.917 3.623s3.917-1.518 3.917-3.623V3.136h-1.23v6.323c0 1.49-.978 2.57-2.687 2.57-1.709 0-2.687-1.08-2.687-2.57V3.136zM12.5 15h-9v-1h9v1z" />
										</svg>
									}
								/>
							</li>
							<li>
								<MarkButton
									format="strike"
									icon={
										<svg className="w-5 h-5" fill="currentColor" viewBox="0 0 16 16">
											{' '}
											<path d="M6.333 5.686c0 .31.083.581.27.814H5.166a2.776 2.776 0 0 1-.099-.76c0-1.627 1.436-2.768 3.48-2.768 1.969 0 3.39 1.175 3.445 2.85h-1.23c-.11-1.08-.964-1.743-2.25-1.743-1.23 0-2.18.602-2.18 1.607zm2.194 7.478c-2.153 0-3.589-1.107-3.705-2.81h1.23c.144 1.06 1.129 1.703 2.544 1.703 1.34 0 2.31-.705 2.31-1.675 0-.827-.547-1.374-1.914-1.675L8.046 8.5H1v-1h14v1h-3.504c.468.437.675.994.675 1.697 0 1.826-1.436 2.967-3.644 2.967z" />
										</svg>
									}
								/>
							</li>
							<li>
								<label htmlFor="imagesMessage">
									<AttachmentIcon className="text-gray-600 hover:opacity-80 transition-all flex font-bold items-center justify-center w-6 h-6 rounded" />
								</label>
								<input onChange={handleAddImages} multiple type="file" className="hidden" id="imagesMessage"/>
							</li>
						</ul>
						<div className="relative py-2 max-h-24 overflow-auto overflow-x-hidden outline-none small-scrollbar">
							<Editable renderElement={renderElement} renderLeaf={renderLeaf} spellCheck autoFocus onKeyDown={handleKeyDownOperations} />
							{isContentEmptyEditor && <div className="absolute pointer-events-none text-gray-300 top-2">Type your message...</div>}
						</div>
						<div className="grid grid-cols-4">
							<ul className="relative -left-1 flex gap-2 col-span-3 py-1">
								<li className="hidden">
									<button
										onClick={e => setEmojiVisibility(!emojiVisibility)}
										className="text-gray-600 hover:text-blue-500 transition-all flex font-bold items-center justify-center w-6 h-6 rounded"
									>
										<svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
											<path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M14.828 14.828a4 4 0 01-5.656 0M9 10h.01M15 10h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
										</svg>{' '}
									</button>
								</li>
								<li className="hidden">
									<button className="text-gray-600 hover:text-blue-500 transition-all flex font-bold items-center justify-center w-6 h-6 rounded">
										<svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
											<path
												strokeLinecap="round"
												strokeLinejoin="round"
												strokeWidth={2}
												d="M16 12a4 4 0 10-8 0 4 4 0 008 0zm0 0v1.5a2.5 2.5 0 005 0V12a9 9 0 10-9 9m4.5-1.206a8.959 8.959 0 01-4.5 1.207"
											/>
										</svg>{' '}
									</button>
								</li>
								<li className="hidden">
									<button className="text-gray-600 hover:text-blue-500 transition-all flex font-bold items-center justify-center w-6 h-6 rounded">
										<svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
											<path
												strokeLinecap="round"
												strokeLinejoin="round"
												strokeWidth={2}
												d="M15.172 7l-6.586 6.586a2 2 0 102.828 2.828l6.414-6.586a4 4 0 00-5.656-5.656l-6.415 6.585a6 6 0 108.486 8.486L20.5 13"
											/>
										</svg>{' '}
									</button>
								</li>
								<li className="hidden">
									<button className="text-gray-600 hover:text-blue-500 transition-all flex font-bold items-center justify-center w-6 h-6 rounded">
										<svg className="w-5 h-5" fill="currentColor" stroke="none" viewBox="0 0 16 16">
											{' '}
											<path d="M3.5 6.5A.5.5 0 0 1 4 7v1a4 4 0 0 0 8 0V7a.5.5 0 0 1 1 0v1a5 5 0 0 1-4.5 4.975V15h3a.5.5 0 0 1 0 1h-7a.5.5 0 0 1 0-1h3v-2.025A5 5 0 0 1 3 8V7a.5.5 0 0 1 .5-.5z" />{' '}
											<path d="M10 8a2 2 0 1 1-4 0V3a2 2 0 1 1 4 0v5zM8 0a3 3 0 0 0-3 3v5a3 3 0 0 0 6 0V3a3 3 0 0 0-3-3z" />
										</svg>
									</button>
								</li>
							</ul>
							<ul className="relative -left-1 flex gap-2 py-1 justify-end">
								{loading ?
								(<li>
									<CircularProgress size={20} />
								</li>)


								:
								(<li>
									<button
										disabled={isEmptyEditor && images.length === 0}
										onClick={handleSendMessage}
										className={ClassNames('text-gray-400 hover:opacity-80 transition-all flex font-bold items-center justify-center w-6 h-6 rounded', {
											'!text-blue-500': !isEmptyEditor || images.length !== 0,
										})}
									>
										<svg className="rotate-45 w-5 h-5" fill="currentColor" viewBox="0 0 16 16">
											<path d="M15.964.686a.5.5 0 0 0-.65-.65L.767 5.855H.766l-.452.18a.5.5 0 0 0-.082.887l.41.26.001.002 4.995 3.178 3.178 4.995.002.002.26.41a.5.5 0 0 0 .886-.083l6-15Zm-1.833 1.89L6.637 10.07l-.215-.338a.5.5 0 0 0-.154-.154l-.338-.215 7.494-7.494 1.178-.471-.47 1.178Z" />
										</svg>
									</button>
								</li>)}
							</ul>
						</div>
					</Slate>
				</div>
			</div>
		</React.Fragment>
	)
}

export default SlateEditor
