import React from 'react';

const NoResultsFound = ({searchTerm, displayConciergeInquiryForm}) => {
  return (
    <div className="mt-[84px] h-full">
      <div className="flex flex-col items-center">
        <img src="/no_results_found.png" alt="No Results Found" className="w-[130px]" />
        <p className="text-lg text-[#5D6E81] mt-4">No Results Found</p>
        {searchTerm &&
          <>
            <div className="text-[#5D6E81] mt-[82px] text-center">
              <p className='text-3xl font-bold'>Can’t find what you are looking for?</p>
              <p className='text-xl'>Reach out to Elefta’s team of experts to help source your watch</p>
            </div>
            <button onClick={(e) => displayConciergeInquiryForm(true)} class="mt-[41px] bg-[#2463EB] rounded-lg text-white pl-3 pr-[26px] py-2.5">
              Help me find {searchTerm}
            </button>
          </>
        }
      </div>
    </div>
  );
};

export default NoResultsFound;
