import React, { useEffect, useState } from 'react'
import { styled } from '@mui/material/styles';
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';
import Classnames from 'classnames'
import find from 'lodash/find'
import includes from 'lodash/includes'
import DialogContentText from '@mui/material/DialogContentText'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import TextField from '@mui/material/TextField'
import InputAdornment from '@mui/material/InputAdornment'
import Stack from '@mui/material/Stack'
import Divider from '@mui/material/Divider'
import Button from '@mui/material/Button'

const CustomMobileModal = styled(Dialog)(({ theme }) => ({
	'& .MuiDialog-container': {
		height: '100%',
		display: 'flex',
		alignItems: 'flex-end',
	},
	'& .MuiDialog-paper': {
		width: '100%',
		maxWidth: '100%',
		margin: 0,
	},
}));

const FilterByOptionModal = ({
        open,
        onClose,
        titleModal,
        checkedFilterValues,
        toggleFilterValue,
        visibleFilterValues,
        clearAllValues,
        selectAllValues,
        handleApplyFilterColumn,
        multipleFilterOptions,
        filterOption,
        handleCheckedFilterValues,
        searchFilterValue
    }) => {
    const [filterValues, setFilterValues] = useState(checkedFilterValues)

    useEffect(() => {
        const option =find(multipleFilterOptions, (option) => option.key === filterOption.key)
        if(option) {
            if(checkedFilterValues.length === 0) {
                const optionFilterValue = [...option.request.filter_values]
                handleCheckedFilterValues(optionFilterValue)
                setFilterValues(optionFilterValue)
            }
        }
    }, [filterOption, open])

    useEffect(() => {
        setFilterValues([...checkedFilterValues])
    }, [checkedFilterValues])

    return (
        <CustomMobileModal
            open={open}
            onClose={onClose}
            sx={{ '& .MuiDialog-paper': { height: '80vh', borderTopLeftRadius: '12px',  borderTopRightRadius: '12px' } }}
        >
            <DialogTitle
                className="bg-[#4B7CBE] rounded-t-[12px]"
            >
                <div className="flex justify-between items-center">
                    <span className="font-bold text-white uppercase">{titleModal}</span>
                    <CloseIcon className="cursor-pointer font-bold text-white" onClick={onClose} />
                </div>
            </DialogTitle>

            <DialogContent className="mt-2">
                <TextField
                    label="Search"
                    variant="outlined"
                    placeholder="Search here"
                    onChange={(event) => {
                        searchFilterValue(event);
                      }}
                    sx={{
                        width: '100%',
                        textAlign: 'center',
                        margin: '12px auto 0px auto',
                        height: '40px',
                    }}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <SearchIcon />
                            </InputAdornment>
                        ),
                        style: { height: '40px' }
                    }}
                />
                <div className="mb-1">
                    <span onClick={selectAllValues} className="text-[12px] underline cursor-pointer font-medium">Select All</span>
                    <span onClick={clearAllValues} className="text-[12px] ms-4 underline cursor-pointer font-medium">Clear</span>
                </div>
                <DialogContentText>
                    <Stack
                        divider={<Divider orientation="horizontal" flexItem />}
                        spacing={2}
                    >
                        {visibleFilterValues.map((item, index) => {
                            return (
                                <div onClick={() => {toggleFilterValue(item)}} key={index} className="flex items-center justify-between text-[14px] font-normal" >
                                    <div>{item}</div>
                                    <svg
                                        className={Classnames('mr-[10px]', [
                                            {
                                                'block': includes(filterValues, item),
                                            },
                                            {
                                                'hidden': !includes(filterValues, item),
                                            },
                                        ])}
                                        xmlns="http://www.w3.org/2000/svg" width="13" height="11" viewBox="0 0 13 11" fill="none">
                                        <path d="M11.4416 0.97583L12.8921 2.37208L4.66227 10.2382L0.547363 6.31499L2.00816 4.92858L4.66227 7.45559L11.4416 0.97583Z" fill="#5D6E81"/>
                                    </svg>
                                </div>
                            )
                        })}
                    </Stack>
                </DialogContentText>
            </DialogContent>
            <Stack direction="row" className="mx-auto mb-8" spacing={2}>
                <Button
                    onClick={() => handleApplyFilterColumn(filterValues)}
                    color="primary"
                    variant="contained"
                    sx={{ textTransform: 'none' }}
                    className="w-[106px] h-[40px] normal-case bg-[#4B7CBE]"
                    autoFocus
                >
                    Apply
                </Button>
            </Stack>
        </CustomMobileModal>
    )
}

export default FilterByOptionModal
