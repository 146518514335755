import React, {useEffect, useState, useRef} from 'react'
import {connect} from 'react-redux'
import {useParams, useSearchParams} from 'react-router-dom'
import ClassNames from 'classnames'
import {useNavigate} from 'react-router-dom'
import IosShareIcon from '@mui/icons-material/IosShare';
import TuneIcon from '@mui/icons-material/Tune';
import {addToast, toggleSidebar} from './../../app/appActions'
import Box from "@mui/material/Box"
import EditNoteIcon from '@mui/icons-material/EditNote';
import BuildOutlinedIcon from '@mui/icons-material/BuildOutlined';
import MessageOutlinedIcon from '@mui/icons-material/MessageOutlined';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import head from 'lodash/head';
import isNil from 'lodash/isNil'
import {Globals} from '../../Context'


const useOutsideAlerter = (ref, callback) => {
  useEffect(() => {
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        callback();
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref, callback]);
}


const MessageHeaderMobile = props => {
  const pageNames = {
    MessageList: 'MessagesList',
    MessageDetail: 'MessageDetail',
    WatchDetail: 'WatchDetail',
  }

  const params = useParams()
  const [searchQueryParams] = useSearchParams()
  const [pageName, setPageName] = useState(pageNames.MessageList)
  const [openSettingMenu, setOpenSettingMenu] = useState(false)
  const navigate = useNavigate()
  const settingsMenuRef = useRef(null);
  const {selectedThread, setPageNameMobile, backToOriginState, openNoteModal, openRepairModal, openEditWatchModal, reachoutProduct, setSelectedThread} = props

  useEffect(() => {
    const {conversation_id, type, product, price} = params
    if (searchQueryParams.get('show_detail') === 'true') {
      setPageName(pageNames.WatchDetail)
      setPageNameMobile(pageNames.WatchDetail)
    } else if (conversation_id || product) {
      setPageName(pageNames.MessageDetail)
      setPageNameMobile(pageNames.MessageDetail)
    } else {
      setPageName(pageNames.MessageList)
      setPageNameMobile(pageNames.MessageList)
    }
  }, [params, searchQueryParams])

  const isSelectedSellItem = () => {
    const {selectedThread, currentSpace} = props
    return selectedThread?.receiver_space_id === currentSpace.id
  }

  useOutsideAlerter(settingsMenuRef, () => setOpenSettingMenu(false));

  const handleShareWatch = async () => {
    if (navigator.share) {
      try {
        await navigator.share({
          title: `${selectedThread.watch.space.name}`,
          url: `${Globals.shareDomain}/inventory/share/item/${selectedThread.watch.uid}`
        });
      } catch (err) {
        console.error("Share failed:", err.message);
      }
    } else {
      console.log("Web Share API not supported. Implementing fallback sharing method.");
      fallbackShareMethod();
    }
  };

  const fallbackShareMethod = () => {
    const url = `${Globals.shareDomain}`;
    if (navigator.clipboard && navigator.clipboard.writeText) {
      navigator.clipboard.writeText(url).then(() => {
      }, (err) => {
        console.error("Could not copy URL to clipboard:", err);
      });
    } else {
      console.error("Clipboard API not supported");
    }
  };


  if (pageName === pageNames.MessageDetail) {
    if (!reachoutProduct && !selectedThread) return

    const watch = selectedThread ? selectedThread.watch : reachoutProduct

    return (
      <React.Fragment>
        <div className="flex h-[70px] justify-between py-4 px-6 bg-[#E8E8E8] border rounded-t-2xl sticky top-0 z-20">
          <div className="flex items-center">
            <img src="/Images/back-icon.svg" alt="back-icon.svg" width={30} onClick={() => {
              setSelectedThread(null)
              navigate('/messages')
            }}/>
            <span className="font-semibold text-3xl	text-[#5D6E81]">{watch.space.name}</span>
          </div>
          <div className="flex items-center">
            <img src="/Images/info-icon.svg" alt="info-icon.svg" width={25} className="mx-auto" onClick={() => navigate(`/messages/${selectedThread.id}?show_detail=true`)} />
          </div>
        </div>
        <div className="grid border-b rounded-b-lg sticky top-[70px] z-20">
          <div className="grid grid-cols-12 bg-white z-10 px-6 py-3">
            <div className="col-span-8 flex items-center justify-center">
              <img
                src={!!head(watch.images) ? `${head(watch.images).url}` : `/watch_icon.png`}
                alt="watch"
                className={ClassNames('bg-blue-500 rounded-full w-[45px] h-[45px]', {
                  'p-2': !head(watch.images),
                })}
              />

              <div className="text-left ml-3">
                <div className="text-xs text-[#333333]">
                  <span className="font-semibold">{isSelectedSellItem() ? watch.short_name_sell : watch.short_name}</span>
                </div>
              </div>
            </div>
            <div className="col-span-4 text-right mt-2">
              <div className="text-[#1976D2] font-semibold text-xs break-words">
                {!isNil(watch.wholesale_price) ? watch.wholesale_price_formatted : 'Contact for Price'}
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    )
  }

  if (selectedThread && pageName === pageNames.WatchDetail) {
    return (
      <div className="flex justify-between py-4 px-6 bg-[#E8E8E8] border rounded-t-2xl">
        <div className="flex items-center">
          <img src="/Images/back-icon.svg" alt="back-icon.svg" width={30} onClick={() => navigate(`/messages/${selectedThread.id}`)} />
          {
            isSelectedSellItem() ? (
              <span className="font-semibold text-3xl	text-[#5D6E81]">SKU: {selectedThread.watch.sku}</span>
            ) : (
                <span className="font-semibold text-3xl	text-[#5D6E81]">{selectedThread.watch.space.name}</span>
            )
          }
        </div>
        <div className="flex items-center">
          {selectedThread.watch.space.id === props.currentSpace.id ?
            (
              <Box sx={{display: "flex", alignItems: "center"}}>
                <IosShareIcon
                  sx={{color: "#333"}}
                  onClick={handleShareWatch}
                />
                <TuneIcon
                  sx={{color: "#333", ml: 2}}
                  onClick={() => setOpenSettingMenu(!openSettingMenu)}
                />
              </Box>
            ) : <IosShareIcon   onClick={handleShareWatch} sx={{color: "#333"}} />
          }
        </div>

        {
          openSettingMenu && (
            <div ref={settingsMenuRef} className="absolute border border-[#D9E2EE] right-6 top-12 w-[200px] rounded-md shadow-[0px_4px_20px_0px_rgba(0,0,0,0.10)] bg-white z-20">
              <ul className="text-[#5D6E81] px-2">
                <li className="border-b" onClick={openEditWatchModal}>
                  <div className="flex py-2 justify-between items-center">
                      <p className="text-sm" >Edit</p>
                      <EditNoteIcon className="text-[#5D6E81]" sx={{width: '22px'}} />
                  </div>
                </li>
                <li className="border-b" onClick={openRepairModal}>
                  <div className="flex py-2 justify-between items-center">
                      <p className="text-sm">Repairs</p>
                      <BuildOutlinedIcon className="text-[#5D6E81] rotate-90" sx={{width: '18px'}} />
                  </div>
                </li>
                <li className="border-b" onClick={openNoteModal}>
                  <div className="flex py-2 justify-between items-center">
                      <p className="text-sm">Notes</p>
                      <MessageOutlinedIcon className="text-[#5D6E81]" sx={{width: '18px'}} />
                  </div>
                </li>
                <li>
                  <div className="flex py-2 justify-between items-center text-[#B71F23]" onClick={() => setOpenSettingMenu(!openSettingMenu)}>
                      <p className="text-sm">Cancel</p>
                      <CloseOutlinedIcon className="text-[#B71F23]" sx={{width: '18px'}} />
                  </div>
                </li>
              </ul>
            </div>
          )
        }
      </div>
    )
  }

  return (
    <div className="flex justify-between py-4 px-6 bg-[#E8E8E8] border rounded-t-2xl sticky top-0 h-[70px] z-999">
      <div className="flex items-center">
        <svg className="mr-3" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" onClick={() => props.toggleSidebar(!props.isOpenSideBar)}>
          <path d="M3 18H12V16H3V18ZM3 13H21V11H3V13ZM3 8H21V6H3V8Z" fill="#5D6E81" />
        </svg>
        <span className="font-semibold text-3xl	text-[#5D6E81]" onClick={backToOriginState}>Message</span>
      </div>
    </div>
  )
}

const mapStateToProps = (state, props) => {
  return {
    profile: state.profile,
    isOpenSideBar: state.isOpenSideBar,
    loggedin: state.loggedin,
    currentSpace: state.currentSpace,
  }
}

const mapActionsToProps = {
  addToast,
  toggleSidebar,
}

export default connect(mapStateToProps, mapActionsToProps)(MessageHeaderMobile)
