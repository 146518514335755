import React, {useEffect, useState, useRef} from 'react'
import Classnames from 'classnames'
import {SwipeableListItem,SwipeableList, SwipeAction, TrailingActions, Type as ListType} from 'react-swipeable-list'

import {Globals} from '../../Context'
import AddNewCustomer from './AddNewCustomer'
import TradeInDialog from '../../Components/Sales/TradeInDialog'
import SalesProductsHunter from '../../Components/Sales/SalesProductsHunter'
import AddNewMiscProduct from '../../Components/Memo/AddNewMiscProduct'
import sum from 'lodash/sum'
import isEmpty from 'lodash/isEmpty'
import head from 'lodash/head'
import isNull from 'lodash/isNull'

const CustomerList = (
    {
        pickCustomer,
        handleCloseCustomerList,
        handleSearchCustomer,
        loadedCustomers,
        originalLoadedCustomers,
        loadingCustomers,
        props,
        handleLoadCustomers,
        loadedProducts,
        loadInventory,
        handleAddingItemToOrder,
        handleRemovingItemToOrder,
        orderedItems,
        handleItemPriceChange,
        updateTradeIn,
        handleMiscItemList,
        tradeInItems,
        miscItems,
        handleOrderItemPriceChange,
        handleSearchProducts,
        handleRemovingMiscItemMemo,
        handleRemovetradeIn,
        addSaleTax,
        taxRate,
        isTaxExempt,
        handleMemoMiscPriceChange,
        handleTradeInPriceChange,
        isCustomerLabeled,
        shippingPrice,
        handleChangeShippingPrice,
        handleSubmitOrder,
        handleCloseAddSale,
        selectedCustomer,
        handleChangeCustomerLabel,
        handleChangeTaxExempt,
        handleCalculateTax
    }) => {
    const [isVisibleSearchInput , setIsVisibleSearchInput] = useState(false)
    const [customerFormVisible , setCustomerFromVisible] = useState(false)
    const [isVisibleSingleOrder , setIsVisibleSingleOrder] = useState(false)
	const [isVisibleDropdown, setIsVisibleDropdown] = useState(false)
	const [showProductsHunter, setShowProductsHunter] = useState(false)
	const [addNewMiscProduct, setAddNewMiscProduct] = useState(false)
	const [showTradeIn, setShowTradeIn] = useState(false)
	const [productItem, setProductItem] = useState()
    const selectRef = useRef(null)


    useEffect(() => {
		const handleClickOutside = event => {
			if (isVisibleDropdown && selectRef.current && !selectRef.current.contains(event.target)) {
				setIsVisibleDropdown(false)
			}
		}
		if (isVisibleDropdown) {
			document.addEventListener('mousedown', handleClickOutside)
		}
		return () => {
			document.removeEventListener('mousedown', handleClickOutside)
		}
	}, [isVisibleDropdown])

    const handleCloseCustomerForm = $event => {
		$event.preventDefault()
		setCustomerFromVisible(false)
	}

    const handleVisibleSingleOrderItem = (customer) => {
        if (customer) {
            pickCustomer(customer)
        }

        setIsVisibleSingleOrder(!isVisibleSingleOrder)
    }

    const hideTradeInDialog = () => {
        setShowTradeIn(false)
    }

    const hideAddNewMiscProduct = () => {
        setAddNewMiscProduct(false)
    }

    const openAddNewMiscProduct = () => {
        setAddNewMiscProduct(true)
        setIsVisibleDropdown(false)
    }

    const openShowTradeInt = () => {
        setShowTradeIn(true)
        setIsVisibleDropdown(false)
    }

    const handleCloseProductsHunter = () => {
        setShowProductsHunter(false)
    }

    const handleOpenProductsHunter = () => {
        loadInventory()
        setShowProductsHunter(true)
        setIsVisibleDropdown(false)
    }

    handleCalculateTax = () => {
		if (isTaxExempt) {
			return 0
		}

		let total = 0
		total += sum(orderedItems.map(t => (t.wholesale_price ? parseFloat(t.wholesale_price) : 0)))
		total += sum(miscItems.map(t => (t.price ? parseFloat(t.price) : 0)))
		total -= sum(tradeInItems.map(t => parseFloat(t.price)))

		return total * taxRate
	}

    const calculateTotalIncludeTax = () => {
		return calculateTotalExcludeTax() + handleCalculateTax()
	}

	const calculateTotalExcludeTax = () => {
		let total = 0
		total += sum(orderedItems.map(t => (t.wholesale_price ? parseFloat(t.wholesale_price) : 0)))
		total += sum(miscItems.map(t => (t.price ? parseFloat(t.price) : 0)))
		total += (isCustomerLabeled ? 0 : parseFloat(shippingPrice))
		total -= sum(tradeInItems.map(t => parseFloat(t.price)))

		return total
	}

    const trailingActions = (handleDelete) => {
		return (
			<TrailingActions>
				<SwipeAction className="bg-[#E1373794]" onClick={handleDelete}>
					<img src="/Images/delete.svg" alt="delete.svg" width={40} className="mx-auto px-[5px]" />
				</SwipeAction>
			</TrailingActions>
		)
	}

	return (
        <>
            <div className="bg-white single-customer-view bg-black/50 fixed top-0 right-0 left-0 bottom-0 z-30 md:hidden" style={{maxWidth: '1100px', width: '100%'}}>
                <div className="bg-white overflow-hidden rounded-tl-lg w-full h-full flex flex-col">
                    <div className="max-h-[63px] flex justify-between py-4 px-2 bg-[#DEDEDE] border rounded-tl-2xl">
                        <div className="flex items-center">
                            <button onClick={handleCloseCustomerList}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="34" height="24" viewBox="0 0 34 24" fill="none">
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M21.6021 6.35147C22.266 6.8201 22.266 7.5799 21.6021 8.04853L16.0042 12L21.6021 15.9515C22.266 16.4201 22.266 17.1799 21.6021 17.6485C20.9382 18.1172 19.8618 18.1172 19.1979 17.6485L12.3979 12.8485C11.734 12.3799 11.734 11.6201 12.3979 11.1515L19.1979 6.35147C19.8618 5.88284 20.9382 5.88284 21.6021 6.35147Z" fill="#5D6E81"/>
                                </svg>
                            </button>
                            <span className="font-semibold text-[25px] text-[#5D6E81]">Select Customer</span>
                        </div>
                        <div className="flex items-center mr-2">
                            <button onClick={() => setIsVisibleSearchInput(!isVisibleSearchInput)}>
                                <svg className='mt-[5px]' xmlns="http://www.w3.org/2000/svg" width="33" height="33" viewBox="0 0 33 33" fill="none">
                                    <path d="M25.6805 26.9598L17.8224 19.1003C14.3267 21.5855 9.50971 20.9776 6.74106 17.7018C3.97241 14.426 4.1757 9.57507 7.20873 6.54238C10.241 3.50837 15.0923 3.3042 18.3688 6.0727C21.6452 8.84121 22.2535 13.6586 19.768 17.1546L27.6261 25.0141L25.6819 26.9584L25.6805 26.9598ZM13.0415 6.87511C10.4341 6.87453 8.18456 8.70481 7.65488 11.2579C7.12521 13.8109 8.46096 16.385 10.8534 17.4217C13.2459 18.4584 16.0375 17.6728 17.5381 15.5405C19.0387 13.4081 18.8358 10.5152 17.0524 8.61313L17.8842 9.43813L16.9465 8.50313L16.93 8.48663C15.9012 7.4515 14.5009 6.87118 13.0415 6.87511Z"fill={isVisibleSearchInput ? "#4B7CBE" :"black"}/>
                                </svg>
                            </button>
                            <button onClick={() => {setCustomerFromVisible(true)}}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="19" height="21" viewBox="0 0 19 21" fill="none">
                                    <path d="M7.8 11.9H1.19209e-07V8.55H7.8V0.249998H11.15V8.55H19V11.9H11.15V20.25H7.8V11.9Z" fill="black"/>
                                </svg>
                            </button>
                        </div>
                    </div>
                    <div className={Classnames("flex justify-center text-sm font-medium text-center text-gray-500",{
                            'items-center': isVisibleSearchInput
                    })}>
                        {isVisibleSearchInput &&
                            <div className='flex justify-center text-sm font-medium text-center text-gray-500 items-center'>
                                <div className="relative mt-2 mb-2 max-w-[320px]">
                                    <svg className='absolute top-[8px] left-[8px] bg-transparent' xmlns="http://www.w3.org/2000/svg" width="27" height="25" viewBox="0 0 27 25" fill="none">
                                        <path d="M20.361 20.1069L14.2853 14.3909C11.5825 16.1984 7.85809 15.7562 5.71743 13.3738C3.57676 10.9914 3.73394 7.46349 6.07902 5.2579C8.42349 3.05134 12.1745 2.90285 14.7077 4.91631C17.241 6.92977 17.7113 10.4333 15.7896 12.9759L21.8653 18.6919L20.3621 20.1059L20.361 20.1069ZM10.5888 5.49988C8.57279 5.49946 6.83351 6.83057 6.42398 8.68733C6.01445 10.5441 7.04722 12.4162 8.89702 13.1701C10.7468 13.9241 12.9052 13.3527 14.0655 11.8019C15.2257 10.2512 15.0689 8.14721 13.6899 6.7639L14.3331 7.3639L13.6081 6.6839L13.5953 6.6719C12.7999 5.91907 11.7172 5.49702 10.5888 5.49988Z" fill="black"/>
                                    </svg>
                                    <input
                                        type="search"
                                        className="p-2 pl-8 max-w-[320px]  border-2 rounded"
                                        onChange={handleSearchCustomer}
                                        placeholder="Search Inventory"
                                    />
                                </div>
                            </div>
                        }
                    </div>
                    {!isEmpty(originalLoadedCustomers) && !loadingCustomers && (
                        <div className="relative min-w-full border-b bg-transparent h-screen overflow-y-scroll ">
                            <div className='min-w-full table-fixed'>
                                    {loadedCustomers.map((customer, index) => {
                                        return (
                                            <div
                                                key={index}
                                                className={Classnames('', {
                                                    'bg-[#F4F4F4]': (index + 1) % 2 === 0,
                                                })}
                                                onClick={() => handleVisibleSingleOrderItem(customer)}
                                            >
                                                <div className="p-4 flex justify-between items-center h-full gap-2">
                                                    <div className="text-lg	font-semibold text-[#4B7CBE]">
                                                        <p className="line-clamp-2">{customer.company_name}</p>
                                                    </div>
                                                    <div className='flex flex-col font-medium text-[10px] text-[#5D6E81] justify-center w-[120px] shrink-0'>
                                                        <span>{customer.name}</span>
                                                        <span>{customer.phone_number}</span>
                                                    </div>
                                                </div>
                                                <div className="flex items-start text-sm font-semibold tracking-worderer mt-2 mr-2">
                                                </div>
                                            </div>
                                        )
                                    })}
                            </div>
                        </div>
                    )}
                </div>
            </div>
            {customerFormVisible &&
                <AddNewCustomer
                    handleCloseForm={handleCloseCustomerForm}
                    props={props}
                    handleLoadCustomers={handleLoadCustomers}
                    handleVisibleSingleOrderItem={handleVisibleSingleOrderItem}
                />
            }
            {isVisibleSingleOrder && (
                	<div className="max-h-screen flex flex-col bg-white single-customer-view bg-black/50 fixed top-0 right-0 left-0 bottom-0 z-30 md:hidden" style={{maxWidth: '1100px', width: '100%'}}>
                        <div className="max-h-[63px] flex items-center gap-3 bg-[#DEDEDE] rounded-tl-2xl py-4 px-4 md:max-h-none md:py-6 md:px-10 md:border-b md:bg-white md:rounded-none">
                            <div className="flex flex-col md:flex-row md:items-center justify-between w-full">
                                <div className="flex gap-1 justify-between">
                                    <button
                                        onClick={() => handleVisibleSingleOrderItem()}
                                    >
                                        <svg xmlns="http://www.w3.org/2000/svg" width="27" height="24" viewBox="0 0 27 24" fill="none">
                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M14.6022 6.35147C15.2661 6.8201 15.2661 7.5799 14.6022 8.04853L9.00426 12L14.6022 15.9515C15.2661 16.4201 15.2661 17.1799 14.6022 17.6485C13.9383 18.1172 12.8619 18.1172 12.198 17.6485L5.39801 12.8485C4.73412 12.3799 4.73412 11.6201 5.39801 11.1515L12.198 6.35147C12.8619 5.88284 13.9383 5.88284 14.6022 6.35147Z" fill="#5D6E81"/>
                                        </svg>
                                    </button>
                                    {<h2 className="absolute left-10 top-[11px] font-semibold text-3xl text-[#5D6E81]">New Order</h2>}
                                    <div className="flex text-sm">
                                        <button onClick={() => {setIsVisibleDropdown(!isVisibleDropdown)}}>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="19" height="21" viewBox="0 0 19 21" fill="none">
                                                <path d="M7.8 11.9H1.19209e-07V8.55H7.8V0.249998H11.15V8.55H19V11.9H11.15V20.25H7.8V11.9Z" fill="black"/>
                                            </svg>
                                        </button>
                                    </div>
                                </div>
                                {isVisibleDropdown &&
                                    <div ref={selectRef} className="absolute bg-white divide-gray-100 divide-y rounded shadow top-11 w-44 z-10 right-4">
                                        <ul className="py-1 text-xs	text-gray-700">
                                            <li>
                                                <button onClick={handleOpenProductsHunter} className="flex justify-between items-center w-full text-left block py-2 px-4 hover:bg-gray-100 border-b-2">
                                                    <span>
                                                        Add Product
                                                    </span>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
                                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M7 0C7.55228 0 8 0.447715 8 1V6H13C13.5523 6 14 6.44772 14 7C14 7.55228 13.5523 8 13 8H8V13C8 13.5523 7.55228 14 7 14C6.44772 14 6 13.5523 6 13V8H1C0.447715 8 0 7.55228 0 7C0 6.44771 0.447715 6 1 6L6 6V1C6 0.447715 6.44772 0 7 0Z" fill="#5D6E81"/>
                                                    </svg>
                                                </button>
                                                    <button onClick={openAddNewMiscProduct} className="flex justify-between items-center w-full text-left block py-2 px-4 hover:bg-gray-100 border-b-2">
                                                        <span>
                                                            Add Misc Product
                                                        </span>
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
                                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M7 0C7.55228 0 8 0.447715 8 1V6H13C13.5523 6 14 6.44772 14 7C14 7.55228 13.5523 8 13 8H8V13C8 13.5523 7.55228 14 7 14C6.44772 14 6 13.5523 6 13V8H1C0.447715 8 0 7.55228 0 7C0 6.44771 0.447715 6 1 6L6 6V1C6 0.447715 6.44772 0 7 0Z" fill="#5D6E81"/>
                                                        </svg>
                                                    </button>
                                                <button onClick={openShowTradeInt} className="flex justify-between items-center w-full text-left block py-2 px-4 hover:bg-gray-100">
                                                    <span>
                                                        Add Trade-In
                                                    </span>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
                                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M7 0C7.55228 0 8 0.447715 8 1V6H13C13.5523 6 14 6.44772 14 7C14 7.55228 13.5523 8 13 8H8V13C8 13.5523 7.55228 14 7 14C6.44772 14 6 13.5523 6 13V8H1C0.447715 8 0 7.55228 0 7C0 6.44771 0.447715 6 1 6L6 6V1C6 0.447715 6.44772 0 7 0Z" fill="#5D6E81"/>
                                                    </svg>
                                                </button>
                                            </li>
                                        </ul>
                                    </div>
                                }
                            </div>
                        </div>
                        <div className="py-2 md:px-10 md:py-5 h-full overflow-y-auto">
                            {!isEmpty(tradeInItems) || !isEmpty(orderedItems) || !isEmpty(miscItems) ?
                                <div className="min-w-full table-fixed">
                                    {!isEmpty(orderedItems) && <SwipeableList fullSwipe={false} threshold={0.5} type={ListType.IOS}>
                                        {
                                            orderedItems.map((orderItem, index) => {
                                            return (
                                                <SwipeableListItem
                                                    trailingActions={trailingActions(()=> handleRemovingItemToOrder(orderItem))}
                                                    maxSwipe={0.2}
                                                    type={ListType.IOS}
                                                    actionDelay={10000}
                                                    destructiveCallbackDelay={10000}
                                                    {...props}
                                                >
                                                    {!orderItem._destroy &&
                                                        <div
                                                            key={index}
                                                            className='rounded-[5px] grid grid-cols-4 gap-2 border-[1px] cursor-pointer bg-white'
                                                        >
                                                            <div className="flex items-center py-4 px-2 text-sm font-normal tracking-worderer">
                                                                <div
                                                                    className="bg-center bg-contain bg-white bg-no-repeat cursor-pointer h-16 md:h-24 m-auto w-16 md:w-24"
                                                                    style={{
                                                                        backgroundImage: head(orderItem?.images) !== undefined ? `url('${head(orderItem?.images).url}')` : `url('/watch_icon.png')`,
                                                                    }}
                                                                ></div>
                                                            </div>
                                                            <div className="flex flex-col justify-center text-[10px] font-normal tracking-worderer col-span-2">
                                                                    <div>
                                                                        <span className="font-semibold mr-1">
                                                                            SKU:
                                                                        </span>
                                                                        { orderItem?.sku}
                                                                    </div>
                                                                    <span className='min-w-[180px] tracking-worderer'>
                                                                        {!isNull(orderItem) && orderItem?.name}
                                                                    </span>
                                                            </div>
                                                            <div className="flex items-start justify-end text-sm font-semibold tracking-worderer mt-2 mr-2">
                                                                <label className="absolute right-[58px] top-[10px]">$</label>
                                                                <input
                                                                    onChange={event => handleOrderItemPriceChange(orderItem, event)}
                                                                    type="text"
                                                                    className="border rounded max-w-[63px] pl-3 rounded-[19px]"
                                                                    value={orderItem.wholesale_price ? Globals.formatCurrency(orderItem.wholesale_price) : ''}
                                                                />
                                                            </div>
                                                        </div>}
                                                </SwipeableListItem>
                                            )})
                                        }
                                    </SwipeableList>}
                                    {!isEmpty(tradeInItems) && <div>
                                        <SwipeableList fullSwipe={false} threshold={0.5} type={ListType.IOS}>
                                            {tradeInItems.map((item, index) => {
                                                return (
                                                    <SwipeableListItem
                                                        trailingActions={trailingActions(()=> handleRemovetradeIn(index))}
                                                        maxSwipe={0.2}
                                                        type={ListType.IOS}
                                                        actionDelay={10000}
                                                        destructiveCallbackDelay={10000}
                                                        {...props}
                                                    >
                                                        <div
                                                            key={index + item.productName}
                                                            className='rounded-[5px] grid grid-cols-4 gap-2 border-[1px] cursor-pointer bg-white'
                                                        >
                                                            <div className="flex items-center py-4 px-2 text-sm font-normal tracking-worderer">
                                                                <div className="flex flex-col items-center">
                                                                    <div
                                                                        className="bg-center bg-contain bg-white bg-no-repeat cursor-pointer h-16 md:h-24 m-auto w-16 md:w-24"
                                                                        style={{
                                                                            backgroundImage: `url('/Images/swap_arrows_icon.png')`,
                                                                        }}
                                                                    ></div>
                                                                    <span className="font-semibold text-[10px]">Trade-In</span>
                                                                </div>
                                                            </div>
                                                            <div className="flex flex-col col-span-2 justify-center text-[10px] font-normal tracking-worderer">
                                                                <div className=" mb-[25px]">
                                                                    <div className="text-[10px">
                                                                        <span className="font-semibold mr-1">
                                                                            SKU:
                                                                        </span>
                                                                        <span>
                                                                            { item?.sku}
                                                                        </span>
                                                                    </div>
                                                                    <div className="text-[10px]">
                                                                        <span className="font-semibold mr-1">
                                                                            Product:
                                                                        </span>
                                                                        <span>
                                                                            {!isNull(item.product_name) && item.product_name}
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="flex items-start justify-end text-sm font-semibold tracking-worderer mt-2 mr-2">
                                                                <div>
                                                                <>-</>
                                                                <label className="absolute right-[58px] top-[10px]">$</label>
                                                                    <input
                                                                        onChange={e => handleTradeInPriceChange(index, e.target.value.replaceAll(',', ''))}
                                                                        type="text"
                                                                        className="border rounded max-w-[63px] pl-3 rounded-[19px]"
                                                                        value={Globals.formatCurrency(item?.price) || 0}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </SwipeableListItem>
                                                )
                                        })}
                                        </SwipeableList>
                                    </div>}
                                    {!isEmpty(miscItems) &&
                                        <div>
                                            <SwipeableList fullSwipe={false} threshold={0.5} type={ListType.IOS}>
                                                {miscItems.map((orderItem, index) => {
                                                        return (
                                                            <SwipeableListItem
                                                            trailingActions={trailingActions(()=> handleRemovingMiscItemMemo(index))}
                                                            maxSwipe={0.2}
                                                                type={ListType.IOS}
                                                                actionDelay={10000}
                                                                destructiveCallbackDelay={10000}
                                                                {...props}
                                                            >
                                                                <div
                                                                    key={index}
                                                                    className='rounded-[5px] grid grid-cols-4 gap-2 border-[1px] cursor-pointer bg-white'
                                                                >
                                                                    <div className="py-4 px-2 text-sm font-normal tracking-worderer">
                                                                        <div className="flex flex-col items-center">
                                                                            <div
                                                                                className="bg-center bg-contain bg-white bg-no-repeat cursor-pointer h-16 md:h-24 m-auto w-16 md:w-24"
                                                                                style={{
                                                                                    backgroundImage: `url('/Images/timer_watch_icon.png')`,
                                                                                }}
                                                                            ></div>
                                                                            <span className="font-semibold text-[10px]">Misc</span>
                                                                        </div>
                                                                    </div>
                                                                    <div className="flex flex-col col-span-2 justify-center text-[10px] font-normal tracking-worderer">
                                                                        <div className=" mb-[25px]">
                                                                            <div className="text-[10px">
                                                                                <span className="font-semibold mr-1">
                                                                                    SKU:
                                                                                </span>
                                                                                <span>
                                                                                    { orderItem?.sku}
                                                                                </span>
                                                                            </div>
                                                                            <div className="text-[10px]">
                                                                                <span className="font-semibold mr-1">
                                                                                    Product:
                                                                                </span>
                                                                                <span>
                                                                                    {!isNull(orderItem.product_name) && orderItem.product_name}
                                                                                </span>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="flex items-start justify-end text-sm font-semibold tracking-worderer mt-2 mr-2">
                                                                        <div>
                                                                            <label className="absolute right-[58px] top-[10px]">$</label>
                                                                            <input
                                                                                onChange={e => handleMemoMiscPriceChange(e, index)}
                                                                                type="text"
                                                                                className="border rounded max-w-[63px] pl-3 rounded-[19px]"
                                                                                value={Globals.formatCurrency(orderItem?.price) || 0}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </SwipeableListItem>
                                                        )
                                                })}
                                            </SwipeableList>
                                        </div>
                                    }
                                    	<div className="flex px-8 py-8 gap-2">
                                            <div className="flex flex-1 flex-col justify-between text-[#000000]">
                                                <div className='flex justify-between'>
                                                    <div>SubtoTal</div>
                                                    <div className='flex justify-end break-all'>
                                                        {Globals.moneyFormatter
                                                            .format(
                                                                (sum(orderedItems.map(t => (t.wholesale_price ? parseFloat(t.wholesale_price) : 0))) +
                                                                    sum(miscItems.map(t => (t.price ? parseFloat(t.price) : 0)))) -
                                                                    sum(tradeInItems.map(t => (t.price ? parseFloat(t.price) : 0)))
                                                        )}
                                                    </div>

                                                </div>
                                                <div className='flex justify-between'>
                                                    <div>Shipping</div>
                                                        <div className={Classnames('flex justify-center ', {'items-start': isCustomerLabeled, 'items-center': !isCustomerLabeled})}>
                                                            <div className='flex justify-center items-center'>
                                                                <button
                                                                    onClick={handleChangeCustomerLabel}
                                                                    className={Classnames(' max-w-auto h-[21px] border rounded-[5px] px-1 flex items-center justify-center mr-[-6px]', {'bg-[#4B7CBE]': isCustomerLabeled})}
                                                                >
                                                                    <span className={Classnames("w-max text-[14px] text-[#4B7CBE]", {'text-white': isCustomerLabeled})}>Customer Label</span>
                                                                </button>
                                                                {!isCustomerLabeled
                                                                    ?
                                                                    <div className="relative flex justify-end items-center max-w-[120px]">
                                                                        <label className="text-sm absolute left-[12px]">$</label>
                                                                        <input
                                                                            onChange={e => handleChangeShippingPrice(e)}
                                                                            type="text"
                                                                            className="border rounded max-w-[63px] pl-[10px] rounded-[19px] ml-2.5"
                                                                            value={shippingPrice ? shippingPrice : ''}
                                                                        />
                                                                    </div> :
                                                                    <div className="flex justify-end break-all ml-2 ">
                                                                        <span className='text-slate-400'>
                                                                            {Globals.moneyFormatter.format(shippingPrice)}
                                                                        </span>
                                                                    </div>
                                                                }
                                                            </div>
                                                        </div>
                                                </div>
                                                {addSaleTax &&
                                                    <div className="flex justify-between items-center padding-y-1">
                                                        <div className="text-black text-base font-normal">Tax</div>
                                                        <div className="text-black text-base font-normal">
                                                            <div className="text-sm font-normal flex">
                                                                {isTaxExempt ? (
                                                                    <div
                                                                        onClick={handleChangeTaxExempt}
                                                                        className="border h-[26px] pl-[13px] pr-[14px] ml-[7px] text-right cursor-pointer text-[#DFE2E6] rounded rounded-[19px]"
                                                                    >
                                                                        Exempt
                                                                    </div>
                                                                ) : (
                                                                    <div
                                                                        onClick={handleChangeTaxExempt}
                                                                        className="border h-[26px] min-w-[63px] pl-[13px] pr-1 ml-[7px] text-right cursor-pointer rounded rounded-[19px]"
                                                                    >
                                                                        {Globals.formatCurrency(handleCalculateTax())}
                                                                    </div>
                                                                )}
                                                            </div>
                                                        </div>
                                                    </div>
                                                }
                                                <div className='flex justify-between'>
                                                    <div>Total</div>
                                                    <div className='flex justify-end break-all  border-t-[1px] border-[#000000] min-w-[80px]'>
                                                        {Globals.moneyFormatter.format(
                                                            calculateTotalIncludeTax()
                                                        )}

                                                    </div>
                                                </div>
                                                <div className='flex justify-between'>
                                                    <div>Balance</div>
                                                    <div className='flex justify-end break-all text-[#4B7CBE]'>
                                                        {Globals.moneyFormatter.format(
                                                            calculateTotalIncludeTax()
                                                        )}
                                                    </div>
                                                </div>

                                            </div>
                                    </div>
                                </div> :
                                <div className='bg-white flex justify-center items-center h-28 text-lg font-semibold text-[#4B7CBE]'>
                                    <span>
                                        No ordered Items
                                    </span>
                                </div>
                            }

                            {!isEmpty(selectedCustomer) &&
                                <div className="p-2 bg-[#F4F4F4]">
                                    <div className="flex justify-between items-center">
                                        <span className="text-xl font-bold text-[#000000]">Customer Detail</span>
                                    </div>
                                    <div className="pt-2">
                                        <div className="flex">
                                            <img className="mr-2" src="/Images/company.svg"/>
                                            <div className="text-[13px] font-normal max-w-[150px]">{selectedCustomer?.company_name}</div>
                                        </div>
                                    </div>
                                    <div className="pt-2">
                                        <div className="flex">
                                            <div>
                                                <img className="mr-2" src="/Images/location.svg"/>
                                            </div>
                                            <div className="text-[13px] font-normal max-w-[150px]">
                                                {`${selectedCustomer.company_address} ${selectedCustomer.city} ${selectedCustomer.state} ${selectedCustomer.zip_code}`}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="pt-2">
                                        <div className="flex items-center">
                                            <div>
                                                <img className="mr-2" src="/Images/phone.svg"/>
                                            </div>
                                            <div className="text-[13px] font-normal max-w-[150px]">{selectedCustomer.phone_number}</div>
                                        </div>
                                    </div>
                                    <div className="pt-2">
                                        <div className="flex items-center">
                                            <div>
                                                <img className="mr-2" src="/Images/mail.svg"/>
                                            </div>
                                            <div className="text-[13px] font-normal max-w-[150px]">{selectedCustomer.email}</div>
                                        </div>
                                    </div>
                                </div>
                            }

                            <div className="px-2 py-4">
                                <div className="flex justify-between items-center">
                                    <span className="text-xl font-bold text-[#000000]">Payments</span>
                                </div>
                                <span className='text-[13px] pl-6'>N/A</span>
                            </div>
                            <div className="px-2 py-4">
                                <div className="flex justify-between items-center">
                                    <span className="text-xl font-bold text-[#000000]">Comments</span>
                                </div>
                                <span className='text-[13px] pl-6'>N/A</span>
                            </div>
                            {!isEmpty(tradeInItems) || !isEmpty(orderedItems) || !isEmpty(miscItems) ?
                                <div className="flex flex-row mt-4 justify-center space-x-4">
                                        <button
                                            onClick={handleCloseAddSale}
                                            className="border-2 cursor-pointer font-bold leading-5 my-2 px-8 py-2 rounded-md text[#000000] text-xs"
                                        >
                                            Cancel
                                        </button>
                                        <button onClick={handleSubmitOrder} className="bg-blue cursor-pointer font-bold leading-5 my-2 px-8 py-2 rounded-md text-white text-xs uppercase"  type="submit">
                                            Save
                                        </button>
                                </div> : <></>
                            }

                        </div>
                </div>
            )}
            {showProductsHunter && (
                <SalesProductsHunter
                    loadedProducts={loadedProducts}
                    handleCloseProductsHunter={handleCloseProductsHunter}
                    memoedItems={orderedItems}
                    handleAddingItemToOrder={handleAddingItemToOrder}
                    handleRemovingItemToOrder={handleRemovingItemToOrder}
                    handleSearchProducts={handleSearchProducts}
                    handleItemPriceChange={handleItemPriceChange}
                    props={props}
                />
			)}
			{showTradeIn && <TradeInDialog hideTradeInDialog={hideTradeInDialog} props={props} updateTradeIn={updateTradeIn} />}
			{addNewMiscProduct && <AddNewMiscProduct props={props} hideAddNewMiscProduct={hideAddNewMiscProduct} handleMiscItemList={handleMiscItemList} />}
		</>
	)
}
export default CustomerList
