import React from 'react'
import {connect} from 'react-redux'
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';
import {toggleSidebar, toggleDetailsCardInventory, toggleNote, toggleRepairs, toggleDataProvider} from './../../app/appActions'
import {store} from '../../app/store'
import {storeProfile} from '../../app/appActions'
import Title from './Title'
import Input from './Input'
import Button from './Button'
import Privacy from './Privacy'
import {Globals} from '../../Context'
import isEmpty from 'lodash/isEmpty'
import isEqual from 'lodash/isEqual'
import upperFirst from 'lodash/upperFirst'

import {renderValidationItem} from '../../utils/validationItem'
import {formatText} from '../../utils/LabelsHelper'

class Personal extends React.Component {
	constructor() {
		super()
		this.state = {
			formData: {},
			formErrors: {},
			SuccessUpdateUser: false,
			SuccessUpdateEmail: false,
			SuccessUpdatePassword: false,
			SuccessUpdate2FA: false,
			showPassword: {
				NewEmail: false,
				NewPassword: false,
				CurrentPassword: false,
				VerifyNewPassword: false,
			},
			validations: {
				length: false,
				uppercase: false,
				lowercase: false,
				digit: false,
				specialChar: false,
			},
			disableButtonUpdatePassword: true,
		}
	}

	componentDidMount() {
		const formData = this.state.formData
		formData.FirstName = this.props.profile.first_name
		formData.LastName = this.props.profile.last_name
		formData.TwoFactorAuth = this.props.profile.otp_required_for_login
		this.setState({formData: formData})
	}

	onChange = e => {
		if(e.target.name === 'NewPassword') {
			this.validatePassword(e.target.value);

		}
		this.setState({
			formData: {
				...this.state.formData,
				[e.target.name]: e.target.value.trim(),
			},
		})
	}

	toogle2FAEnabled = (event) => {
		this.setState({
			formData: {
				...this.state.formData,
				[event.target.name]: event.target.checked,
			},
		})
	}

	handleDisableButton = () => {
		const {validations} = this.state
		if (!validations.length || !validations.uppercase || !validations.lowercase || !validations.digit || !validations.specialChar) {
			return true
		}

		return false
	}

	Update2FA = e => {
		e.preventDefault()
		this.setState({SuccessUpdateUser: false})
		const state = this.state
		let errors = {}

		return Globals.New_Axios()
			.patch(`${Globals.NEW_API_URL}/v1/users/update/2fa`, {
				otp_required_for_login: state.formData.TwoFactorAuth,
			})
			.then(async response => {
				this.setState({SuccessUpdate2FA: true})

				await Globals.getProfile().then(profile => {
					store.dispatch(storeProfile(profile))
				}).catch(error => {
					console.warn(error)
				})

				setTimeout(() => {
					this.setState({SuccessUpdate2FA: false})
				}, 5000)

				return this.setState({formErrors: {}})
			})
			.catch(error => {
				errors = {
					...errors,
					error2FA: error.response?.data.message || 'Something went wrong please try again later.',
				}

				return this.setState({formErrors: errors})
			})
	}

	UpdateProfile = e => {
		e.preventDefault()
		this.setState({SuccessUpdateUser: false})
		const state = this.state
		let errors = {}

		if (isEmpty(state.formData.FirstName)) errors = {...errors, FirstName: 'First Name is required'}
		if (isEmpty(state.formData.LastName)) errors = {...errors, LastName: 'Last Name is required'}
		this.setState({formErrors: errors})

		if (!isEmpty(errors)) return //Skip the rest.

		return Globals.New_Axios()
			.patch(`${Globals.NEW_API_URL}/v1/users/update/profile`, {
				first_name: state.formData.FirstName,
				last_name: state.formData.LastName,
			})
			.then(async response => {
				this.setState({SuccessUpdateUser: true})

				await Globals.getProfile().then(profile => {
					store.dispatch(storeProfile(profile))
				}).catch(error => {
					console.warn(error)
				})

				return this.setState({formErrors: {}})
			})
			.catch(error => {
				errors = {
					...errors,
					errorProfile: error.response?.data.message || 'Something went wrong please try again later.',
				}

				return this.setState({formErrors: errors})
			})
	}

	validatePassword = (password) => {
		const validationRules = {
			length: /.{7,}/,
			uppercase: /[A-Z]/,
			lowercase: /[a-z]/,
			digit: /[0-9]/,
			specialChar: /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/,
		};
		this.setState({
			validations: {
				length: validationRules.length.test(password),
				uppercase: validationRules.uppercase.test(password),
				lowercase: validationRules.lowercase.test(password),
				digit: validationRules.digit.test(password),
				specialChar: validationRules.specialChar.test(password),
			}
		}, () => {
			this.setState({disableButtonUpdatePassword: this.handleDisableButton()});
		});
	};


	UpdateEmail = e => {
		e.preventDefault()
		this.setState({SuccessUpdateEmail: false})

		const state = this.state
		let errors = {}
		if (isEmpty(state.formData.NewEmail) || !Globals.validateEmail(state.formData.NewEmail)) errors = {...errors, NewEmail: 'Your email is required'}
		if (isEmpty(state.formData.CurrentPassword))
			errors = {
				...errors,
				CurrentPassword: 'Current Password is required',
			}
		this.setState({formErrors: errors})

		if (!isEmpty(errors)) return //Skip the rest.

		return Globals.New_Axios()
			.patch(`${Globals.NEW_API_URL}/v1/users/update/email`, {
				email: state.formData.NewEmail,
				current_password: state.formData.CurrentPassword,
			})
			.then(async response => {
				this.setState({SuccessUpdateEmail: true})
				await Globals.getProfile().then(profile => {
					store.dispatch(storeProfile(profile))
				}).catch(error => {
					console.warn(error)
				})
				return this.setState({formErrors: {}})
			})
			.catch(error => {
				errors = {
					...errors,
					errorEmail: error.response?.data?.message || 'Something went wrong please try again later.',
				}
				return this.setState({formErrors: errors})
			})
	}

	UpdatePassword = e => {
		e.preventDefault()
		this.setState({SuccessUpdatePassword: false})

		const state = this.state
		let errors = {}
		if (isEmpty(state.formData.NewPassword)) errors = {...errors, NewPassword: 'New Password is required'}
		if (isEmpty(state.formData.CurrentPassword))
			errors = {
				...errors,
				CurrentPassword2: 'Current Password is required',
			}
		if (isEmpty(state.formData.VerifyNewPassword))
			errors = {
				...errors,
				VerifyNewPassword: 'Verify New Password is required',
			}
		if (!isEqual(state.formData.VerifyNewPassword, state.formData.NewPassword))
			errors = {
				...errors,
				VerifyNewPassword: 'Passwords do not match',
			}
		this.setState({formErrors: errors})

		if (!isEmpty(errors)) return //Skip the rest.

		return Globals.New_Axios()
			.patch(`${Globals.NEW_API_URL}/v1/users/update/password`, {
				current_password: state.formData.CurrentPassword,
				password: state.formData.NewPassword,
				password_confirmation: state.formData.NewPassword,
			})
			.then(async response => {
				this.setState({SuccessUpdatePassword: true})
				await Globals.getProfile().then(profile => {
					store.dispatch(storeProfile(profile))
				}).catch(error => {
					console.warn(error)
				})
				return this.setState({formErrors: {}})
			})
			.catch(error => {
				errors = {
					...errors,
					errorPassword: formatText(error.response?.data?.message) || 'Something went wrong please try again later.',
				}
				return this.setState({formErrors: errors})
			})
	}

	handleShowPassword = (key) => {
		const showPassword = this.state.showPassword
		showPassword[key] = !showPassword[key]
		this.setState({showPassword: showPassword})
	}

	render() {
		const props = this.props
		const state = this.state
		const displayError = key => {
			if (!isEmpty(state.formErrors[key])) return <div className="pt-3 text-red-700">{state.formErrors[key]}</div>
		}

		return (
			<div className="relative pb-24">
				<Title title="Personal" />
				<div className="flex flex-row pl-5 mb-3">
					<h3 className="text-xs lg:text-sm">
						<span>Your email is currently: </span>
						<span className="font-bold ml-1">{props.profile.email}</span>
					</h3>
				</div>
				{props.profile.friendly_id && !isEmpty(props.profile.friendly_id) && (
					<div className="flex flex-row pl-5 mb-3">
						<h3 className="text-xs lg:text-sm">
							<span>Your Friendly ID is: </span>
							<span className="font-bold ml-1">{props.profile.friendly_id}</span>
						</h3>
					</div>
				)}
				<div className="flex flex-row pl-5 mb-3">
					<h3 className="text-xs lg:text-sm">
						<span>Your role is currently: </span>
						{Globals.isOwner && <span className="font-bold ml-1">Owner</span>}
                        {!Globals.isOwner && <span className="font-bold ml-1">{upperFirst(props.profile.role)}</span>}
					</h3>
				</div>
				<div className="border-b-2 pb-6 lg:pb-10">
					<Title title="Profile" />
					<form action="" onSubmit={this.UpdateProfile}>
						<div className="grid lg:grid-cols-2 px-4">
							<div className="flex flex-col">
								<Input onchange={this.onChange} name="FirstName" title="First Name" type="text" defaultValue={props.profile.first_name} />
								{displayError('FirstName')}
							</div>

							<div className="flex flex-col">
								<Input onchange={this.onChange} name="LastName" title="Last Name" type="text" defaultValue={props.profile.last_name} />
								{displayError('LastName')}
							</div>
						</div>
						{state.SuccessUpdateUser && <div className="bg-green-100 font-medium m-5 p-3 rounded text-green-900 text-sm">First name & last name been updated</div>}
						<div className="text-center">{displayError('errorProfile')}</div>
						<Button type="submit" value="Update Profile" />
					</form>
				</div>
				<div className="border-b-2 pb-6 lg:pb-10">
					<Title title="Email address" />
					<form action="" onSubmit={this.UpdateEmail}>
						<h1 className="px-5 pb-3 lg:pb-7 mr-1 text-xs font-medium leading-5 lg:text-sm">To make changes to your email address, please verify your current password</h1>
						<div className="grid lg:grid-cols-2 px-4">
							<div className="flex flex-col">
								<Input onchange={this.onChange} name="NewEmail" title="New Email" type="email" />
								{displayError('NewEmail')}
							</div>
							<div className="flex flex-col">
								<Input
									onchange={this.onChange}
									name="CurrentPassword"
									title="Current Password"
									type="password"
									isPassword={true}
									showPassword={state.showPassword.NewEmail}
									handleSetShowPassWord={() => this.handleShowPassword('NewEmail')}
								/>
								{displayError('CurrentPassword')}
							</div>
						</div>
						{state.SuccessUpdateEmail && <div className="pt-3 text-center text-green-500">Email Updated successfully</div>}
						<div className="text-center">{displayError('errorEmail')}</div>
						<Button type="submit" value="Update Email" />
					</form>
				</div>
				<div className="pb-6 lg:pb-10">
					<Title title="Password" />
					<form action="" onSubmit={this.UpdatePassword}>
						<h1 className="px-5 pb-3 lg:pb-7 mr-1 text-xs font-medium leading-5 lg:text-sm">To make changes to your password, please verify your current password</h1>
						<div className="grid lg:grid-cols-2 px-4">
							<div className="flex flex-col">
								<Input
									onchange={this.onChange}
									name="NewPassword"
									title="New Password"
									type="password"
									isPassword={true}
									showPassword={state.showPassword.NewPassword}
									handleSetShowPassWord={() => this.handleShowPassword('NewPassword')}
								/>
								{displayError('NewPassword')}
								{state.formData.NewPassword && state.formData.NewPassword.length > 0 && (
									<>
										{renderValidationItem('Minimum Length (7 characters)', state.validations.length)}
										{renderValidationItem('Must include at least one uppercase letter (A-Z).', state.validations.uppercase)}
										{renderValidationItem('Must include at least one lowercase letter (a-z).', state.validations.lowercase)}
										{renderValidationItem('Must include at least one numeric digit (0-9).', state.validations.digit)}
										{renderValidationItem('Must include at least one special character (e.g., !@#$%^&*()_+-=~`{}[]|:;"<>,.?/)', state.validations.specialChar)}
									</>
								)}
							</div>

							<div className="flex flex-col">
								<Input
									onchange={this.onChange}
									name="CurrentPassword"
									title="Current Password"
									type="password"
									isPassword={true}
									showPassword={state.showPassword.CurrentPassword}
									handleSetShowPassWord={() => this.handleShowPassword('CurrentPassword')}
								/>
								{displayError('CurrentPassword2')}
							</div>
						</div>
						<div className="grid lg:grid-cols-2 px-4">
							<div className="flex flex-col">
								<Input
									onchange={this.onChange}
									name="VerifyNewPassword"
									title="Verify New Password"
									type="password"
									isPassword={true}
									showPassword={state.showPassword.VerifyNewPassword}
									handleSetShowPassWord={() => this.handleShowPassword('VerifyNewPassword')}
								/>
								{displayError('VerifyNewPassword')}
							</div>
						</div>

						{state.SuccessUpdatePassword && <div className="pt-3 text-center text-green-500">Password Updated successfully</div>}
						<div className="text-center">{displayError('errorPassword')}</div>
						<Button
							disabled={this.state.disableButtonUpdatePassword}
							type="submit"
							value="Update password"
						/>
					</form>
				</div>

				<div className="pb-6 lg:pb-10">
					<Title title="2FA" />
					<form action="" onSubmit={this.Update2FA}>
						<h1 className="px-5 pb-3 lg:pb-7 mr-1 text-xs font-medium leading-5 lg:text-sm">Enable or disable 2FA</h1>
						<div className="grid lg:grid-cols-2 px-4">
							<div className="flex flex-col items-start">
								<FormControlLabel
									control={
										<Switch
											checked={state.formData.TwoFactorAuth || false}
											onChange={this.toogle2FAEnabled}
											name="TwoFactorAuth"
											color="primary"
										/>
									}
									label="2FA"
								/>
								{displayError('TwoFactorAuth')}
								{this.state.SuccessUpdate2FA &&  <div className="bg-green-100 font-medium mt-4 p-3 rounded text-green-900 text-sm">2FA Updated successfully</div>}
							</div>
						</div>
						<div className="text-center">{displayError('error2FA')}</div>
						<Button type="submit" value="Update 2FA" />
					</form>
				</div>
				<div>
					<Privacy />
				</div>
			</div>
		)
	}
}

const mapStateToProps = (state, props) => {
	return {
		profile: state.profile,
		isOpenSideBar: state.isOpenSideBar,
		loggedin: state.loggedin,
		isOpenDetailsInventory: state.isOpenDetailsInventory,
		isOpenNote: state.isOpenNote,
		isOpenRepairs: state.isOpenRepairs,
		dataProvider: state.dataProvider,
	}
}

const mapActionsToProps = {
	toggleSidebar,
	toggleDetailsCardInventory,
	toggleNote,
	toggleRepairs,
	toggleDataProvider,
}

export default connect(mapStateToProps, mapActionsToProps)(Personal)
