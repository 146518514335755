import React, {useEffect, useState} from 'react'
import DatePicker from 'react-datepicker'

import 'react-datepicker/dist/react-datepicker.css'
import './styles.css'
import {handleKeyDownPickerDate} from '../../../utils/keyboardHandler'
import isEmpty from 'lodash/isEmpty'
import isFunction from 'lodash/isFunction'

const DatePickerModalRepair = ({name, title, defaultValue, handlechangePicker, isMobile, isMobileInventory}) => {
	const [startDate, setStartDate] = useState(null);

	useEffect(() => {
		if (!isEmpty(defaultValue) && defaultValue !== 'null' && !isMobile) {
			const _defaultValue = new Date(defaultValue)
			if (_defaultValue instanceof Date && !isNaN(_defaultValue)) {
				setStartDate(_defaultValue)
			}
		}
	}, [defaultValue, isMobile])

	return (
		<React.Fragment>
		    <div className="relative date-flat-picker-custom-repair-mobile md:hidden border-1">
                <DatePicker
                    selected={startDate}
                    onChange={date => {
                        setStartDate(date)
                        if (isFunction(handlechangePicker)) handlechangePicker(date, name)
                    }}
                    showPopperArrow={false}
                    name={name}
                    value={startDate}
                    onKeyDown={(event, name) => {
                        handleKeyDownPickerDate(event)
                    }}
                />
            </div>
		</React.Fragment>
	)
}

export default DatePickerModalRepair
