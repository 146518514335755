import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import {useNavigate} from 'react-router-dom'
import {connect} from 'react-redux'

import SideBar from './SideBar';
import WebStoreContent from './WebStoreContent';
import { Globals } from '../../../Context'
import { addToast } from '../../../app/appActions'
import findIndex from 'lodash/findIndex'

const WebStoreSetting = ({ profile }) => {
    const dispatch = useDispatch();
    const [webstores, setWebstores] = useState([])
    const [webstore, setWebstore] = useState({})
    const [webstoreDefault, setWebstoreDefault] = useState({})
    const [focusedIndex, setFocusedIndex] = React.useState(0);
    const [showPassword, setShowPassword] = useState(false)

	const navigate = useNavigate()

    useEffect(() => {
        if (!Globals.adminOrOwner(profile)) {
			navigate('/inventory')
		}
        const getLastedSpaceData = async () => {
            try {
                const response = await Globals.New_Axios().get(`${Globals.baseUri}/web_store_settings`);
                if(response.data.web_store_settings.length === 0) {
                    handleSetDefaultWebstore()
                } else {
                    setWebstores(response.data.web_store_settings)
                    setWebstore(response.data.web_store_settings[0])
                }
            } catch (error) {
            }
        };

        getLastedSpaceData();
    }, []);

    const handleSelectItem = (index) => {
        setFocusedIndex(index);
        setWebstore(webstores[index])
    };

    const handleSetWebStoresAfterUpdate = (value) => {
        if(webstores.length === 1) {
            webstores[0] = value
            setWebstores(webstores)
            setWebstore(value)
        } else {
            const index = findIndex(webstores, (webstore) => webstore.id === value.id)
            if(index > -1) {
                webstores[index] = value
                setWebstores([...webstores])
                setWebstore(value)
            } else {
                webstores[webstores.length -1] = value
                setWebstores([...webstores])
                setWebstore(value)
            }

        }
    }

    const handleSetWebStoresRegenerate = (oldWebstore, newWebstore) => {
        const index = findIndex(webstores, (webstore) => webstore.id === oldWebstore.id)
        if(index > -1) {
            webstores[index] = newWebstore
            setWebstores([...webstores])
            setWebstore(newWebstore)
        }
    }

    const handleSetWebStoresAfterDelete = async (value) => {
        if(webstores.length === 1) {
            const response = await Globals.New_Axios().post(`${Globals.baseUri}/web_store_settings/regenerate`);
            const newWebstores = []
            newWebstores.push(response.data.new_web_store_setting)
            setWebstores([...newWebstores])
            setWebstore(response.data.new_web_store_setting)
            setFocusedIndex(newWebstores.length -1);
        } else {
            const index = findIndex(webstores, (webstore) => webstore.id === value.id)
            if(index > -1) {
                const buildWebstore = webstores.filter(webstore => webstore.id !== value.id)
                setWebstores([...buildWebstore])
                setWebstore(buildWebstore[buildWebstore.length-1])
                setFocusedIndex(buildWebstore.length-1);
            }
        }
    }

    const handleSetDefaultWebstore = async () => {
        const response = await Globals.New_Axios().post(`${Globals.baseUri}/web_store_settings/regenerate`);
        webstores.push(response.data.new_web_store_setting)
        setWebstores([...webstores])
        setWebstoreDefault(response.data.new_web_store_setting)
        setWebstore(response.data.new_web_store_setting)
        setFocusedIndex(webstores.length -1);
    }

    const handleCancelWebstore = async (value) => {
        if(webstores.length === 1) {
            setWebstore({...webstoreDefault})
        } else {
            const buildWebstore = webstores.filter(webstore => webstore.id !== value.id)
            setWebstores([...buildWebstore])
            setWebstore(buildWebstore[buildWebstore.length-1])
            setFocusedIndex(buildWebstore.length-1);
        }
    }

    const addRows = () => {
        if(!!webstore?.id) {
            handleSetDefaultWebstore()
            setShowPassword(false)
        } else {
            dispatch(
                addToast({
                    title: 'Error',
                    text: 'Please fill out the current web store setting before creating a new one.',
                    type: 'error',
                })
            )
        }
    };

    const handleShowPassword = () => {
        setShowPassword(!showPassword)
    }

    const handleChangeSwitch = async (event, item) => {
        const index = findIndex(webstores, (value) => value.id === item.id)
        if(index > -1) {

            webstores[index].enabled = event.target.checked
            setWebstores([...webstores])
            const webStoreSettingData = {
                web_store_setting: {
                    enabled: event.target.checked,
                }
            }
            try {
                if(item.id) {
                    await Globals.New_Axios().patch(`${Globals.baseUri}/web_store_settings/${item.id}`, webStoreSettingData)
                    const titlePrefix = item.title ? item.title : 'link ' + (index + 1)
                    dispatch(
                        addToast({
                            title: 'Success',
                            text: `${titlePrefix} has been ${event.target.checked ? 'enabled' : 'disabled'} successfully`,
                            type: 'success',
                        }))
                }
            } catch (error) {
                dispatch(
                    addToast({
                        title: 'Error',
                        text: error.response?.data?.message || 'Something went wront please try again later.',
                        type: 'error',
                    }))
            }
        }
    }

    const handleSubmitTitle = async (item) => {
        const index = findIndex(webstores, (value) => value.id === item.id)
        if(index > -1) {
            webstores[index].title = !!item.title ? item.title : '(Add Title)',
            setWebstores([...webstores])
            const webStoreSettingData = {
                web_store_setting: {
                    title: !!item.title ? item.title : '(Add Title)',
                }
            }
            try {
                if(item.id) {
                    await Globals.New_Axios().patch(`${Globals.baseUri}/web_store_settings/${item.id}`, webStoreSettingData)
                    dispatch(
                        addToast({
                            title: 'Success',
                            text: `Title has been updated successfully`,
                            type: 'success',
                        }))
                }
            } catch (error) {
                dispatch(
                    addToast({
                        title: 'Error',
                        text: error.response?.data?.message || 'Something went wront please try again later.',
                        type: 'error',
                    }))
            }
        }
    }

    const handleChangeInput = (event, item) => {
        const index = findIndex(webstores, (value) => value.id === item.id)
        if(index > -1) {
            webstores[index].title = event.target.value
            setWebstores([...webstores])
        }
    }

    return (
        <div className="flex w-full h-full pb-20">
            <div className='w-1/6 border-r-[1px] mt-6 px-2'>
                {
                    webstores.map((item, index) => (
                        <SideBar
                            key={index}
                            index={index}
                            handleSelectItem={handleSelectItem}
                            isFocused={focusedIndex === index}
                            item={item}
                            handleChangeSwitch={handleChangeSwitch}
                            handleChangeInput={handleChangeInput}
                            handleSubmitTitle={handleSubmitTitle}
                        />
                    ))
                }
                <div onClick={addRows} className='flex mt-6 cursor-pointer'>
                    <img className='mr-2' src="/Images/add_button.svg" alt="add-button" />
                    <span>New Link</span>
                </div>
            </div>
            <div className='w-5/6 pl-20' >
                {
                    <WebStoreContent
                        item={webstore}
                        profile={profile}
                        handleSetWebStoresAfterUpdate={handleSetWebStoresAfterUpdate}
                        handleSetWebStoresAfterDelete={handleSetWebStoresAfterDelete}
                        handleSetWebStoresRegenerate={handleSetWebStoresRegenerate}
                        handleShowPassword={handleShowPassword}
                        showPassword={showPassword}
                        handleCancelWebstore={handleCancelWebstore}
                    />
                }
            </div>
        </div>
    )
}

const mapStateToProps = (state, props) => {
	return {
		profile: state.profile,
	}
}

const mapActionsToProps = {
}

export default connect(mapStateToProps, mapActionsToProps)(WebStoreSetting)

