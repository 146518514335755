import classNames from 'classnames'
import CheckIcon from '@mui/icons-material/Check'
import map from 'lodash/map'

const PricingCard = ({plan, handleChangePlan, billingPeriod}) => {
	return (
		<div
			className='relative w- lg:max-w-auto lg:w-full lg:h-[600px] flex flex-col rounded-[10px] text-[12px] lg:text-[16px] border-[2px] bg-[#fff]'
		>
			{plan.value === 'platinum' && <div className="bg-black w-full rounded-t-[10px] flex justify-center text-[14px] h-[22px] text-[#fff]">BEST VALUE</div>}
			<div className="w-full h-full flex flex-col justify-between p-2">
				<div
					className={classNames('flex flex-col', {
						'mt-[22px]': plan.value !== 'platinum',
					})}
				>
					<span className="text-black font-bold text-[12px] lg:text-[20px] text-center">{plan.title}</span>
					<span className="text-[#4b4b8c] text-[20px] font-bold text-center">
						{billingPeriod === 'monthly' ? plan.price.monthly.value : plan.price.yearly.value}
					</span>
					<div className="flex flex-col items-center pb-[15px]">
						<span className="text-[12px] text-gray-700 font-bold text-center">
							{plan.price[billingPeriod].subtitle}
						</span>
					</div>
					<div className="flex flex-col mt-3 text-[12px] border-t-[1px]">
						{map(plan.descriptions, (feature, index) => (
							<div className="flex items-start mt-3" key={index}>
								<CheckIcon style={{fontSize: '16px', color: '#ff6a39'}} />
								<span key={index} className={classNames('text-black ml-2', {})}>
									{feature}
								</span>
							</div>
						))}
					</div>
				</div>

				<div
					onClick={event => {
						handleChangePlan(event, plan)
					}}
					className={'w-full rounded-[50px] bg-[#f4f5fd] text-[#4b4b8c] flex justify-center h-[40px] items-center mb-5 cursor-pointer font-semibold'}
				>
					GET STARTED
				</div>
			</div>
		</div>
	)
}

export default PricingCard
