import {useState, useContext, useEffect} from 'react'
import {useNavigate} from 'react-router-dom'
import TopElements from '../Components/Account/TopElements'
import SideBar from '../Components/SideBar'
import TopBar from '../Components/TopBar'
import {SettingVar} from '../Context/SettingsVariable'
import {Globals} from '../Context'
import {useSelector, useDispatch} from 'react-redux'
import {toggleSidebar} from './../app/appActions'
import isEmpty from 'lodash/isEmpty'

const Account = () => {
	const dispatch = useDispatch(),
		navigate = useNavigate(),
		{isOpenSideBar, loggedin} = useSelector(state => state),
		toggleSideBar = () => dispatch(toggleSidebar(!isOpenSideBar))

	const [formData, updateFormData] = useState({}),
		[successfullyAdded, updateSuccessfullyAdded] = useState(false),
		[formErrors, updateFormErrors] = useState({})

	let {OpenAddUser, setOpenAddUser} = useContext(SettingVar)

	const options = [
		{
			option: 'Admin',
		},
		{
			option: 'Manager',
		},
		{
			option: 'Editor',
		},
	]

	const handleChange = e => {
		updateFormData({
			...formData,
			[e.target.name]: e.target.value.trim(),
		})
	}

	const handleSubmit = async e => {
		e.preventDefault()
		let errors = {}

		if (isEmpty(formData.email) || !Globals.validateEmail(formData.email)) errors = {...errors, email: 'Email address is required'}
		if (isEmpty(formData.role)) errors = {...errors, role: 'Role is required'}

		updateFormErrors(errors)

		if (!isEmpty(errors)) return

		//Make sure all the data is being correct.
		return Globals.Axios()
			.post(`${Globals.wsurl}/user/space/add/user`, {
				data: formData,
				deviceId: Globals.deviceId,
			})
			.then(response => {
				updateFormData({})
				updateSuccessfullyAdded(true)

				setTimeout(() => {
					updateSuccessfullyAdded(false)
				}, 2000)
				setTimeout(() => {
					setOpenAddUser(false)
				}, 4000)
			})
			.catch(error => {
				errors = {
					...errors,
					error: 'Something went wrong please try again later.',
				}
				updateFormErrors(errors)
			})
	}

	const displayError = key => {
		if (!isEmpty(formErrors[key])) return <div className="pt-3 text-red-700">{formErrors[key]}</div>
	}

	useEffect(() => {
		if (!loggedin) return navigate('/login')
	})

	return (
		<>
			{OpenAddUser && (
				<div className="fixed top-0 w-screen h-screen bg-black/50 flex items-center justify-center z-50">
					<div className="bg-white drop-shadow-xl rounded-xl sm:w-1/2">
						<button className="w-full borderCardChrono relative px-8 py-4 flex items-center">
							<span className="text-white font-bold text-lg">Add New User</span>
							<span
								className="absolute right-0 mr-5 cursor-pointer"
								onClick={() => {
									setOpenAddUser(false)
								}}
							>
								<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
									<path
										d="M14.8672 0.194897C14.625 0.200615 14.3949 0.301995 14.2271 0.47688L8.00026 6.70377L1.77337 0.47688C1.68784 0.38896 1.58554 0.319098 1.47252 0.271431C1.35951 0.223764 1.23807 0.199261 1.11541 0.199373C0.933136 0.199599 0.755062 0.254158 0.60394 0.35608C0.452819 0.458003 0.335512 0.602661 0.267004 0.771577C0.198497 0.940492 0.181898 1.126 0.21933 1.30439C0.256762 1.48278 0.346524 1.64597 0.47715 1.7731L6.70404 7.99999L0.47715 14.2269C0.389174 14.3113 0.318936 14.4125 0.27055 14.5245C0.222163 14.6364 0.196601 14.7569 0.19536 14.8789C0.194119 15.0008 0.217224 15.1218 0.263323 15.2347C0.309421 15.3476 0.377586 15.4502 0.463825 15.5364C0.550064 15.6227 0.652643 15.6908 0.765556 15.7369C0.878469 15.783 0.999445 15.8061 1.1214 15.8049C1.24335 15.8037 1.36383 15.7781 1.47578 15.7297C1.58774 15.6813 1.68891 15.6111 1.77337 15.5231L8.00026 9.29622L14.2271 15.5231C14.3116 15.6111 14.4128 15.6813 14.5247 15.7297C14.6367 15.7781 14.7572 15.8037 14.8791 15.8049C15.0011 15.8061 15.1221 15.783 15.235 15.7369C15.3479 15.6908 15.4505 15.6227 15.5367 15.5364C15.6229 15.4502 15.6911 15.3476 15.7372 15.2347C15.7833 15.1218 15.8064 15.0008 15.8052 14.8789C15.8039 14.7569 15.7784 14.6364 15.73 14.5245C15.6816 14.4125 15.6113 14.3113 15.5234 14.2269L9.29648 7.99999L15.5234 1.7731C15.6568 1.6452 15.7484 1.47997 15.7862 1.29908C15.824 1.11819 15.8063 0.93009 15.7354 0.759448C15.6644 0.588807 15.5436 0.443591 15.3886 0.342846C15.2337 0.2421 15.052 0.19053 14.8672 0.194897Z"
										fill="white"
									/>
								</svg>
							</span>
						</button>

						<div className="flex flex-col p-8">
							<form className="flex flex-col" onSubmit={handleSubmit}>
								<div className="my-2">
									<label htmlFor="email" className="font-normal py-2 block">
										Email
									</label>
									<input
										id="email"
										type="email"
										placeholder="Email address*"
										name="email"
										className="h-12 rounded-md w-full px-3 text-xs font-normal leading-5 lg:text-sm"
										style={{
											border: '1px solid rgb(217, 226, 238)',
										}}
										onChange={handleChange}
										required
									/>
									{displayError('email')}
								</div>

								<div className="my-2">
									<label htmlFor="role" className="font-normal py-2 block">
										Role
									</label>
									<select
										id="role"
										name="role"
										className="h-12 rounded-md w-full px-3 text-xs font-normal leading-5 lg:text-sm"
										style={{border: '1px solid #D9E2EE'}}
										defaultValue=""
										onChange={handleChange}
										required
									>
										<option value="" disabled>
											Select role*
										</option>
										{options.map(c => (
											<option key={c.option} value={c.option}>
												{c.option}
											</option>
										))}
									</select>
									{displayError('role')}
									<div className="flex flex-row py-3 px-1">
										<p className="text-xs font-medium">
											Learn more about{' '}
											<span className="leading-4 text-xs font-normal underline underline-offset-3 cursor-pointer" style={{color: '#2C79E0'}}>
												Elefta User Roles
											</span>
										</p>
									</div>
								</div>

								<div className="pt-6">
									{successfullyAdded && (
										<div className="text-xs text-green-600 bg-green-50 p-4 rounded-lg text-center">{`An invitation has been sent. this invitation will be only valid for 12 hours.`}</div>
									)}
								</div>

								<div className="flex flex-row place-content-end	">
									<button
										onClick={() => {
											setOpenAddUser(false)
										}}
										style={{Color: '#5D6E81'}}
										className="p-2 lg:p-3 mt-2 lg:mt-5 rounded-md text-xs font-normal leading-5 lg:text-sm cursor-pointer border"
									>
										Cancel
									</button>
									<button
										style={{backgroundColor: '#2C79E0'}}
										className="ml-4 mr-1 p-2 lg:p-3 mt-2 lg:mt-5 rounded-md text-xs font-bold leading-5 lg:text-sm text-white cursor-pointer uppercase"
									>
										add new user
									</button>
								</div>
							</form>
						</div>
					</div>
				</div>
			)}

			<section className="relative">
				<TopBar toggleSideBar={toggleSideBar} />
				<div className="flex relative">
					<SideBar isOpen={isOpenSideBar} />
					<main className="flex flex-col relative w-full md:h-screen md:overflow-y-hidden">
						<div className='bg-white md:px-5 pt-12 md:h-screen md:overflow-y-scroll md:overflow-x-hidden'>
							<TopElements />
						</div>
					</main>
				</div>
			</section>
		</>
	)
}

export default Account
