import React from 'react'
import { styled } from '@mui/material/styles';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Scrollbar } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/scrollbar';
import { createGlobalStyle } from 'styled-components';
import IosShareIcon from '@mui/icons-material/IosShare';
import CloseIcon from '@mui/icons-material/Close';
import DialogContent from '@mui/material/DialogContent'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'

const GlobalStyle = createGlobalStyle`
    .swiper-scrollbar-child  {
        bottom: 15px !important;
    };
    .section-details:nth-child(odd) {
        background-color: #E9E9E9;
        width: 100%;
    };
    .swiper-wrapper {
        display: flex !important;
        align-items: center !important;
    }
`;

const CustomMobileModal = styled(Dialog)(({ theme }) => ({
	'& .MuiDialog-container': {
		height: '100%',
		display: 'flex',
		alignItems: 'flex-end',
	},
	'& .MuiDialog-paper': {
		width: '100%',
		maxWidth: '100%',
		margin: 0,
	},
}));


const PreviewModal = ({
        Images,
        onClose,
        handleShareImage
    }) => {
    return (
        <CustomMobileModal
            className="md:hidden text-[10px]"
            open={true}
            sx={{
                '& .MuiDialog-paper': {
                    width: '100%',
                    maxWidth: '100%',
                    height: '100%',
                    maxHeight: '100%',
                    color: '#5D6E81',
                },
            }}
        >
            <GlobalStyle />
            <DialogTitle
                sx={{
                    height: '56px'
                }}
            >
                <div className="flex justify-end items-center text-[#5D6E81]">
                    <CloseIcon sx={{ color:'black' }} onClick={onClose}/>
                </div>
            </DialogTitle>
            <DialogContent sx={{padding: 0, height: '100%'}}>
                <Swiper
                    scrollbar={{
                        hide: false,
                        el: '.swiper-scrollbar-child',
                    }}
                    modules={[Scrollbar]}
                    className="mySwiper w-full max-h-[660px] relative"
                >
                     <IosShareIcon onClick={handleShareImage} sx={{ color:'#aab2bc' }} className="absolute top-0 right-6 cursor-pointer text-black font-bold z-[9999999999999999]" />

                    {
                        Images.length > 0 && Images.map((data, index) => {
                            return (
                                <SwiperSlide className='w-full h-full flex items-center'>
                                    <div className='w-full h-full grid place-items-center max-h-[660px] relative'>
                                        <img src={data.url} alt="product" className="w-auto h-auto max-w-[393px] max-h-[660px]" />
                                    </div>
                                </SwiperSlide>

                            )})
                    }
                    <div className="swiper-scrollbar swiper-scrollbar-child max-w-3/4 absolute z-[9999999999999]"></div>
                </Swiper>
            </DialogContent>
        </CustomMobileModal>

    )
}

export default PreviewModal
