import {connect} from 'react-redux'
import {addToast} from './../app/appActions'

import React from 'react'
import Moment from 'moment'
import {useParams} from 'react-router-dom'
import {Globals} from '../Context'
import HourglassTwoTone from '@ant-design/icons/HourglassTwoTone'

const withParams = Component => {
	return props => <Component {...props} params={useParams()} />
}
class InventoryShare extends React.Component {
	constructor() {
		super()
		this.state = {
			loaded: false,
			sharedInfo: {},
			expiryTime: 0,
		}

		this.timer = null
		this.tableTarget = React.createRef()
	}

	componentDidMount() {
		try {
			const {
				params: {id},
			} = this.props
			this.setState(
				{
					id: id,
				},
				this.loadData
			)
			this.startTimer()
		} catch (error) {}
    }

	componentWillUnmount() {
        if (this.timer) {
            clearInterval(this.timer)
        }
    }

	loadData = () => {
		Globals.New_Axios()
			.get(`${Globals.NEW_API_URL}/v1/sharing_watches/info`, {
				params: { sharing_code: this.state.id }
			})
			.then(async response => {
				this.setState({
					loaded: true,
					sharedInfo: response.data,
					expiryTime: 60 - Moment().utc().diff(response.data.created_at, 'minutes')
				})
			}).catch(error => {
				console.warn(error)
			})
	}

	printPdf = (time) => {
		const { sharedInfo } = this.state
		if(time > 0){
		Globals.New_Axios()
			.post(`${Globals.NEW_API_URL}/v1/spaces/${sharedInfo.space_id}/sharing_watches/download.pdf`, {
				sharing_code: this.state.id
			}, {
				responseType: 'blob'
			}).then((response) => {
				const url = window.URL.createObjectURL(new Blob([response.data]));
				const link = document.createElement('a');
				link.href = url;
				link.setAttribute('download', 'Elefta-Inventory-Report.pdf'); // or any other filename
				document.body.appendChild(link);
				link.click();
			  }).catch(error => {
				console.log(error)
			  })
			}
		}

	downloadCSV = (time) => {
		const { sharedInfo } = this.state
		if(time > 0){
		Globals.New_Axios()
			.post(`${Globals.NEW_API_URL}/v1/spaces/${sharedInfo.space_id}/sharing_watches/download.csv`, {
				sharing_code: this.state.id
			}, {
				responseType: 'blob'
			}).then((response) => {
				const url = window.URL.createObjectURL(new Blob([response.data]));
				const link = document.createElement('a');
				link.href = url;
				link.setAttribute('download', 'Elefta.csv'); // or any other filename
				document.body.appendChild(link);
				link.click();
			}).catch(error => {
				console.log(error)
			})

		}
	}

    startTimer = () => {
        this.timer = setInterval(() => {
            const { sharedInfo } = this.state
            if (sharedInfo.created_at) {
                const expiryTime = 60 - Moment().utc().diff(sharedInfo.created_at, 'minutes')
                this.setState({ expiryTime })
            }
        }, 1000)
    }

	render() {
		const state = this.state

		const { sharedInfo, expiryTime} = this.state
		return (
			<section className="relative">
				<div id="controllersActions" className="flex flex-col h-screen items-center justify-center">
					{!state.loaded &&
						Array.from({length: 2}, (v, i) => i).map(index => {
							return (
								<div
									role="status"
									className="bg-white w-full max-w-md m-2 p-4 space-y-4 max-w-md rounded border border-gray-200 divide-y divide-gray-200 shadow animate-pulse md:p-6"
									key={index}
								>
									<div className="flex gap-2">
										<div className="bg-gray-300 flex h-10 w-10 items-center justify-center rounded-lg">
											<svg
												aria-hidden="true"
												className="animate-spin fill-gray-600 h-8 p-1.5 text-gray-200 w-8"
												viewBox="0 0 100 101"
												fill="none"
												xmlns="http://www.w3.org/2000/svg"
											>
												<path
													d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
													fill="currentColor"
												/>
												<path
													d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
													fill="currentFill"
												/>
											</svg>
										</div>
										<div>
											<div className="h-3 bg-gray-300 rounded-full w-24 mb-2.5"></div>
											<div className="w-32 h-2 bg-gray-200 rounded-full mb-2.5"></div>
											<div className="w-60 h-2 bg-gray-200 rounded-full"></div>
										</div>
									</div>
								</div>
							)
						})}

					{state.loaded && (
						<React.Fragment>
							{
								expiryTime > 0 ?
								<>
								<div
								className="flex items-start m-4 p-4 w-full max-w-md text-gray-500 bg-white rounded-lg shadow shadow-md border cursor-pointer hover:bg-red-50 transition duration-300"
								onClick={() =>this.printPdf(expiryTime)}
							>
								<div className="inline-flex flex-shrink-0 justify-center items-center w-8 h-8 text-red-500 bg-red-100 rounded-lg">
									<svg className="w-6 h-6" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
										<path
											fillRule="evenodd"
											d="M3 17a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zm3.293-7.707a1 1 0 011.414 0L9 10.586V3a1 1 0 112 0v7.586l1.293-1.293a1 1 0 111.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 010-1.414z"
											clipRule="evenodd"
										/>
									</svg>
								</div>
								<div className="ml-3 text-sm font-normal">
									<div className="text-gray-400 flex items-center">
										<small className="inline-flex mr-1.5 text-orange-300">
											<svg className="w-3.5 h-3.5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
												<path
													fillRule="evenodd"
													d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-12a1 1 0 10-2 0v4a1 1 0 00.293.707l2.828 2.829a1 1 0 101.415-1.415L11 9.586V6z"
													clipRule="evenodd"
												/>
											</svg>
										</small>
										<small> Expires in {60 - Moment().utc().diff(sharedInfo.created_at, 'minutes')} minutes</small> :
									</div>
									<span className="mb-1 text-md font-semibold text-gray-900">Download PDF File</span>
									<div>
									Inventory from {sharedInfo.space_name} - {Moment(sharedInfo.created_at).format('Do of MMMM YYYY')}
								</div>
								</div>
							</div>

							<div
								className="flex items-start m-4 p-4 w-full max-w-md text-gray-500 bg-white rounded-lg shadow shadow-md border cursor-pointer hover:bg-green-50 transition duration-300"
								onClick={() =>this.downloadCSV(expiryTime)}
							>
								<div className="inline-flex flex-shrink-0 justify-center items-center w-8 h-8 text-green-500 bg-green-100 rounded-lg">
									<svg className="w-6 h-6" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
										<path
											fillRule="evenodd"
											d="M3 17a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zm3.293-7.707a1 1 0 011.414 0L9 10.586V3a1 1 0 112 0v7.586l1.293-1.293a1 1 0 111.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 010-1.414z"
											clipRule="evenodd"
										/>
									</svg>
								</div>
								<div className="ml-3 text-sm font-normal">
									<div className="text-gray-400 flex items-center">
										<small className="inline-flex mr-1.5 text-orange-300">
											<svg className="w-3.5 h-3.5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
												<path
													fillRule="evenodd"
													d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-12a1 1 0 10-2 0v4a1 1 0 00.293.707l2.828 2.829a1 1 0 101.415-1.415L11 9.586V6z"
													clipRule="evenodd"
												/>
											</svg>
										</small>
										<small>Expires in {60 - Moment().utc().diff(sharedInfo.created_at, 'minutes')} minutes</small>
									</div>
									<span className="mb-1 text-md font-semibold text-gray-900">Download CSV File</span>
									<div>
										Inventory from {sharedInfo.space_name} - {Moment(sharedInfo.created_at).format('Do of MMMM YYYY')}
									</div>
								</div>
							</div>
							</> :
							<>
								<HourglassTwoTone className='hourglass-main'/>
								<h1 className='hourglass-header'>This link has expired</h1>
								<p>Please contact to owner for a new link</p>
							</>
						}
						</React.Fragment>
					)}
				</div>

				{expiryTime >0 &&<button onClick={() =>this.printPdf(expiryTime)}>print pdf</button>}
			</section>
		)
	}
}

const mapStateToProps = (state, props) => {
	return {
		profile: state.profile,
	}
}

const mapActionsToProps = {
	addToast,
}

export default connect(mapStateToProps, mapActionsToProps)(withParams(InventoryShare))
