import classNames from "classnames"

const Button = ({value, type, disabled=false, className}) => {
	return (
		<button
			disabled={disabled}
			style={{backgroundColor: '#2C79E0'}}
			type={type}
			className={classNames("ml-5 p-2 lg:p-3 my-2 lg:my-6 rounded-md text-xs font-bold leading-5 lg:text-sm text-white cursor-pointer", {
				'opacity-[0.8]': disabled
			})}
		>
			{value}
		</button>
	)
}
export default Button
