import React from 'react'

const DoubleEditModal = ({editedColumns, handleVisibleDuplicateEditModal, handleSubmit}) => {

	return (
        <div className="h-screen bg-black/50 fixed flex items-center justify-center inset-0 z-40 p-3">
            <div className="max-w-[632px] w-full h-[401px]">
                <div className="bg-[#5D6E81] flex items-center px-[15px] py-2 relative rounded-t-[5px] text-white w-full">
                    <img className='mr-2' src="/Images/warning_icon.svg" alt="user-profile-2" />
                    <span className="font-semibold text-[18px] leading-[27px] text-[#FFFFFF]">Duplicate Edits</span>
                </div>
                <div className="  bg-white text-[#5D6E81] rounded-b-[5px] px-[43px] py-[24px] w-full h-[350px] overflow-y-scroll">
                    <div className='flex items-center text-center justify-center'>
                        <span className='font-medium text-[14px] leading-[21px] text-center'>The following attributes were changed by another user within your
                        <br/>space:</span>
                    </div>
                    <div className='flex flex-col items-center justify-center py-[20px] min-h-[170px]'>
                        {editedColumns.map((value, index) => (
                                <span className='font-medium text-[14px] leading-[21px] text-center text-[#2C79E0]'>{value}</span>
                            ))}
                    </div>
                    <div className='flex items-center text-center justify-center'>
                        <span className='font-medium text-[14px] leading-[21px] text-center'>Do you want to overwrite the previous edits with your edits?</span>
                    </div>
                    <div className='flex items-center text-center justify-center mt-4 gap-[9px]'>
                        <div onClick={handleVisibleDuplicateEditModal} className='px-[12px] py-[14px] font-bold text-[14px] leading-[21px] border-[1px] rounded-[5px] cursor-pointer'>
                            Cancel
                        </div>
                        <div onClick={handleSubmit} className='px-[12px] py-[14px] font-bold text-[14px] leading-[21px] border-[1px] rounded-[5px] cursor-pointer'>
                            Overwrite
                        </div>
                    </div>
                </div>
            </div>
        </div>
	)
}
export default DoubleEditModal
