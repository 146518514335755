import React, { useState } from 'react'
import classNames from 'classnames'
import CheckIcon from '@mui/icons-material/Check'
import map from 'lodash/map'
import { useDispatch } from 'react-redux'

import * as collections from '../../utils/collections'
import {addToast} from '../../app/appActions'
import { Globals } from '../../Context';

const PlanUpgradeModal = ({currentSpace, handleCloseModal}) => {
	const dispatch = useDispatch()
	const currentSubscription = currentSpace?.internal_subscription
	const [billingPeriod, setBillingPeriod] = useState(currentSubscription.billing_period || 'monthly')
	const [currentPlan, setCurrentPlan] = useState(currentSubscription?.plan_type)
	const [loading, setLoading] = useState(false)

	const upgradeSubscriptionToPlatinum = (selectedPlan) => {
		setLoading(true)
		Globals.New_Axios().post(`${Globals.NEW_API_URL}/${Globals.ENDPOINTVERSION}/spaces/${Globals.SELECTEDSPACEID}/internal_subscriptions/upgrade`, {
			plan_type: selectedPlan,
			billing_period: billingPeriod
		}).then(response => {
			dispatch(addToast({text: 'Your account has been successfully upgraded to the Platinum plan.', type: 'success'}))
			setTimeout(() => {
				window.location.reload()
				setLoading(false)
			}, 2000)
		}).catch(error => {
			console.log(error)
			setLoading(false)
			dispatch(addToast({text: error.response.data.message, type: 'error'}))
		})
	}

	return (
		<div className="fixed top-0 left-0 w-screen h-screen bg-black/50 flex items-center justify-center z-50 py-10">
			{loading &&
				<div className="absolute top-0 left-0 right-0 bottom-0 flex justify-center items-center z-50">
					<button
						type="button"
						className="inline-flex items-center px-4 py-2 leading-6 text-sm shadow rounded-md text-white bg-blue transition ease-in-out duration-150 cursor-not-allowed"
						disabled
					>
						<svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
							{' '}
							<circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4" />
							<path
								className="opacity-75"
								fill="currentColor"
								d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
							/>
						</svg>
						<span>Loading... </span>
					</button>
				</div>
			}
			<div className="bg-white drop-shadow-xl rounded-xl w-full overflow-hidden" style={{maxWidth: '1045px'}}>
				<div className="w-full h-[82px] borderCardChrono relative py-[30px] ps-[22px] pe-[29px] flex items-center justify-between">
					<span className="text-white font-bold text-2xl">Upgrade</span>
					<button onClick={handleCloseModal} className="cursor-pointer">
						<svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" fill="none">
							<path
								d="M17.8669 3.19484C17.6247 3.20055 17.3946 3.30193 17.2269 3.47682L11 9.70371L4.7731 3.47682C4.68757 3.3889 4.58527 3.31904 4.47225 3.27137C4.35923 3.2237 4.2378 3.1992 4.11514 3.19931C3.93286 3.19954 3.75479 3.2541 3.60367 3.35602C3.45254 3.45794 3.33524 3.6026 3.26673 3.77152C3.19822 3.94043 3.18162 4.12593 3.21906 4.30433C3.25649 4.48272 3.34625 4.64591 3.47688 4.77304L9.70376 10.9999L3.47688 17.2268C3.3889 17.3113 3.31866 17.4125 3.27027 17.5244C3.22189 17.6364 3.19633 17.7568 3.19509 17.8788C3.19384 18.0008 3.21695 18.1217 3.26305 18.2346C3.30915 18.3476 3.37731 18.4501 3.46355 18.5364C3.54979 18.6226 3.65237 18.6908 3.76528 18.7369C3.87819 18.783 3.99917 18.8061 4.12112 18.8048C4.24308 18.8036 4.36356 18.778 4.47551 18.7296C4.58746 18.6813 4.68863 18.611 4.7731 18.523L11 12.2962L17.2269 18.523C17.3113 18.611 17.4125 18.6813 17.5245 18.7296C17.6364 18.778 17.7569 18.8036 17.8788 18.8048C18.0008 18.8061 18.1218 18.783 18.2347 18.7369C18.3476 18.6908 18.4502 18.6226 18.5364 18.5364C18.6227 18.4501 18.6908 18.3476 18.7369 18.2346C18.783 18.1217 18.8061 18.0008 18.8049 17.8788C18.8036 17.7568 18.7781 17.6364 18.7297 17.5244C18.6813 17.4125 18.6111 17.3113 18.5231 17.2268L12.2962 10.9999L18.5231 4.77304C18.6565 4.64514 18.7481 4.47991 18.7859 4.29902C18.8238 4.11813 18.806 3.93003 18.7351 3.75939C18.6642 3.58875 18.5433 3.44353 18.3884 3.34278C18.2334 3.24204 18.0517 3.19047 17.8669 3.19484Z"
								fill="white"
							/>
						</svg>
					</button>
				</div>
				<div className="h-full w-full">
					<div className="h-full flex items-center justify-center">
						<div className="flex items-center justify-center text-sm rounded-lg ps-[10px] py-[5px] mb-[14px] mt-[17px]" role="alert">
							<div className="">
								<div className="flex items-center justify-center py-8">
									<div className="flex border-2 border-[#e0c789] rounded-full">
										<div
											className={classNames('px-6 py-3 rounded-full transition-colors duration-300 cursor-pointer', {
												'bg-[#5D6E81] text-white cursor-pointer': billingPeriod === 'monthly',
												'bg-transparent text-black cursor-pointer': billingPeriod !== 'monthly',
											})}
											onClick={() => {
												setBillingPeriod('monthly')
											}}
										>
											Monthly Billing
										</div>
										<div
											className={classNames('px-6 py-3 rounded-full transition-colors duration-300 cursor-pointer relative', {
												'bg-[#5D6E81] text-white cursor-pointer': billingPeriod === 'yearly',
												'bg-transparent text-black cursor-pointer': billingPeriod !== 'yearly',
											})}
											onClick={() => {
												setBillingPeriod('yearly')
											}}
										>
											Yearly Billing
											<div className="absolute z-[999] w-full text-center left-0 bottom-[-12px]">
												<span className="bg-[#ffc800] pl-[10px] pr-[10px] rounded-[10px] font-[13px]">Save 20%</span>
											</div>
										</div>
									</div>
								</div>
								<div className="grid grid-cols-2 gap-10">
									{collections.plansCollection.map((plan, index) => {
										if (plan.value === 'starter') return
										return (
											<div
												key={index}
												className={classNames('relative w- lg:max-w-auto lg:w-full lg:h-[550px] flex flex-col rounded-[10px] text-[12px] lg:text-[16px] border-[2px]', {
													'bg-[#fff] border-blue-600': currentPlan === plan.value,
													'border-gray-300': currentPlan != plan.value,
												})}
											>
												{plan.value === 'platinum' && <div className="bg-black w-full rounded-t-[10px] flex justify-center text-[14px] h-[22px] text-[#fff]">BEST VALUE</div>}
												<div className="w-full h-full flex flex-col justify-between p-2">
													<div
														className={classNames('flex flex-col', {
															'mt-[22px]': plan.value !== 'platinum',
														})}
													>
														<span className="text-black font-bold text-[12px] lg:text-[20px] text-center">{plan.title}</span>
														<span className="text-[#4b4b8c] text-[20px] font-bold text-center">
															{billingPeriod === 'monthly' ? plan.price.monthly.value : plan.price.yearly.value}
														</span>
														<div className="flex flex-col items-center pb-[15px]">
															<span className="text-[12px] text-gray-700 font-bold text-center">{plan.price[billingPeriod].subtitle}</span>
														</div>
														<div className="flex flex-col mt-3 text-[12px] border-t-[1px]">
															{map(plan.descriptions, (feature, index) => (
																<div className="flex items-start mt-3" key={index}>
																	<CheckIcon style={{fontSize: '16px', color: '#ff6a39'}} />
																	<span key={index} className={classNames('text-black ml-2', {})}>
																		{feature}
																	</span>
																</div>
															))}
														</div>
													</div>
													<>
														{currentPlan === plan.value ? (
															<div className={'w-full rounded-[50px] bg-[#f4f5fd] text-[#4b4b8c] flex justify-center h-[40px] items-center mb-5 font-semibold'}>
																CURRENT PLAN
															</div>
														) : (
															<div
																className={
																	'w-full rounded-[50px] bg-[#f4f5fd] text-[#4b4b8c] flex justify-center h-[40px] items-center mb-5 font-semibold cursor-pointer'
																}
																onClick={() => {
																	upgradeSubscriptionToPlatinum(plan.value)
																}}
															>
																Upgrade
															</div>
														)}
													</>
												</div>
											</div>
										)
									})}
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

export default PlanUpgradeModal
