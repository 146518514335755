import {useEffect, useState} from 'react'
import {connect} from 'react-redux'

import SensitiveInformation from './SensitiveInformation'
import {Globals} from '../../../Context'
import PaymentReminder from './PaymentReminders'
import Notifications from './Notifications'
import Invoice from './Invoice'
import WatchDetailSetting from './WatchDetailSetting'
import General from './General'

const Settings = props => {
	const [isGeneral, setIsGeneral] = useState(true)
	const [isSensitiveInformation, setIsSensitiveInformation] = useState(false)
	const [isPaymentReminders, setIsPaymentReminders] = useState(false)
	const [isNotification, setIsNotifications] = useState(false)
	const [isInvoice, setIsInvoice] = useState(false)
	const [isWatchDetailSetting, setIsWatchDetailSetting] = useState(false)

	useEffect(() => {
		if(props.profile.role === 'manager') {
			openPaymentReminders()
		}
	}, [])

	const openGeneral = () => {
		setIsGeneral(true)
		setIsSensitiveInformation(false)
		setIsPaymentReminders(false)
		setIsNotifications(false)
		setIsInvoice(false)
		setIsWatchDetailSetting(false)
	}

	const openSensitiveInformation = () => {
		setIsSensitiveInformation(true)
		setIsGeneral(false)
		setIsPaymentReminders(false)
		setIsNotifications(false)
		setIsInvoice(false)
		setIsWatchDetailSetting(false)
	}

	const openPaymentReminders = () => {
		setIsPaymentReminders(true)
		setIsGeneral(false)
		setIsSensitiveInformation(false)
		setIsNotifications(false)
		setIsInvoice(false)
		setIsWatchDetailSetting(false)
	}

	const openNotification = () => {
		setIsNotifications(true)
		setIsGeneral(false)
		setIsSensitiveInformation(false)
		setIsPaymentReminders(false)
		setIsInvoice(false)
		setIsWatchDetailSetting(false)
	}

	const openInvoice = () => {
		setIsGeneral(false)
		setIsSensitiveInformation(false)
		setIsPaymentReminders(false)
		setIsNotifications(false)
		setIsWatchDetailSetting(false)
		setIsInvoice(true)
	}

	const openWatchDetailSetting = () => {
		setIsGeneral(false)
		setIsSensitiveInformation(false)
		setIsPaymentReminders(false)
		setIsNotifications(false)
		setIsWatchDetailSetting(true)
		setIsInvoice(false)
	}

	return (
		<div className="w-full">
			<div className="flex border-b-2 w-full">
				{ props.profile.role !== 'manager' &&
					<button
						className="cursor-pointer flex flex-row justify-center items-center border-b-2 mr-2 sm:mr-4"
						style={{
							fontWeight: isGeneral ? '700' : '500',
							borderColor: isGeneral ? '#2C79E0' : 'transparent',
						}}
						onClick={openGeneral}
					>
						<span className="lg:text-sm text-xxs w-full">General</span>
					</button>
				}
				<button
					className="cursor-pointer flex flex-row justify-center items-center border-b-2 mx-2 sm:mx-4 py-3"
					style={{
						fontWeight: isPaymentReminders ? '700' : '500',
						borderColor: isPaymentReminders ? '#2C79E0' : 'transparent',
					}}
					onClick={openPaymentReminders}
				>
					<span className="lg:text-sm text-xxs w-full">Payment Reminders</span>
				</button>
				<button
					className="cursor-pointer flex flex-row justify-center items-center border-b-2 mx-2 sm:mx-4 py-3"
					style={{
						fontWeight: isNotification ? '700' : '500',
						borderColor: isNotification ? '#2C79E0' : 'transparent',
					}}
					onClick={openNotification}
				>
					<span className="lg:text-sm text-xxs w-full">Notifications</span>
				</button>
				{
					Globals.adminOrOwner(props.profile) && (
						<button
							className="cursor-pointer flex flex-row justify-center items-center border-b-2 mx-2 sm:mx-4 py-3"
							style={{
								fontWeight: isInvoice ? '700' : '500',
								borderColor: isInvoice ? '#2C79E0' : 'transparent',
							}}
							onClick={openInvoice}
						>
							<span className="lg:text-sm text-xxs w-full">Invoice</span>
						</button>
					)
				}
				{
					props.profile?.role === 'owner' && (
						<button
							className="cursor-pointer flex flex-row justify-center items-center border-b-2 mx-2 sm:mx-4 py-3"
							style={{
								fontWeight: isWatchDetailSetting ? '700' : '500',
								borderColor: isWatchDetailSetting ? '#2C79E0' : 'transparent',
							}}
							onClick={openWatchDetailSetting}
						>
							<span className="lg:text-sm text-xxs w-full">Watch Detail Page</span>
						</button>
					)
				}
				<button
					className="cursor-pointer flex flex-row justify-center items-center border-b-2 mr-2 sm:mr-4"
					style={{
						fontWeight: isSensitiveInformation ? '700' : '500',
						borderColor: isSensitiveInformation ? '#2C79E0' : 'transparent',
					}}
					onClick={openSensitiveInformation}
				>
					<span className="lg:text-sm text-xxs w-full">Sensitive Information</span>
				</button>
			</div>
			{isGeneral &&  props.profile.role !== 'manager' &&  <General  profile={props.profile}/>}
			{isSensitiveInformation && <SensitiveInformation profile={props.profile} />}
            {isPaymentReminders && <PaymentReminder profile={props.profile} />}
            {isNotification && <Notifications profile={props.profile} />}
            {isInvoice && <Invoice/>}
            {isWatchDetailSetting && <WatchDetailSetting/>}
		</div>
	)
}

const mapStateToProps = (state, props) => {
	return {
		profile: state.profile,
	}
}


const mapActionsToProps = {}

export default connect(mapStateToProps, mapActionsToProps)(Settings)
