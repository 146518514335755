import React from 'react'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import Box from '@mui/material/Box';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import Tooltip from '@mui/material/Tooltip'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { styled } from '@mui/material/styles';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import 'swiper/css';
import 'swiper/css/scrollbar';
import { createGlobalStyle } from 'styled-components';
import IosShareIcon from '@mui/icons-material/IosShare';
import TuneIcon from '@mui/icons-material/Tune';
import EditNoteIcon from '@mui/icons-material/EditNote';
import BuildOutlinedIcon from '@mui/icons-material/BuildOutlined';
import MessageOutlinedIcon from '@mui/icons-material/MessageOutlined';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import { isNumeric } from 'lodash-contrib';

import TopCardDetails from './TopCardDetails'
import {Globals} from '../../Context'
import EditInventoryComponent from './EditInventoryComponent'
import {toggleSidebar, toggleDetailsCardInventory, toggleNote, toggleRepairs, toggleDataProvider} from './../../app/appActions'
import {connect} from 'react-redux'
import {addToast, handleGetGlobalColumnConfigs} from './../../app/appActions'
import * as collections from '../../utils/collections'
import { toYesNo } from '../../utils/LabelsHelper'
import PreviewImageMobile from "../../Components/PreviewImageMobile"
import WatchHistory from "../Inventory/WatchHistory/index"
import QRCode from "../Inventory/QRCode/index"
import WatchDetailMobile from "../../Components/Messages/WatchDetailMobile"
import PreviewModal from "../../Components/Inventory/mobile/PreviewModal"
import NoteModal from '../common/NoteModal';
import RepairModal from "../../Components/common/RepairModal/index"
import useOutsideClick from '../../hooks/useOutsideClick'
import find from 'lodash/find'
import isBoolean from 'lodash/isBoolean'
import isFunction from 'lodash/isFunction'
import isObject from 'lodash/isObject'
import findIndex from 'lodash/findIndex'
import lowerCase from 'lodash/lowerCase'
import isEmpty from 'lodash/isEmpty'


const Info = ({
	title, detail, columns, keyColumn, profile, isHiddenImportantDetails, data,
	watchWarrantyPapers, watchCondition, isCustom
}) => {
	const renderWholesalePrice = () => {
		const column = find(columns, {key: 'wholesale_price'})
		if(profile.role == 'manager' && column?.sensitive) return ''
		if(column?.sensitive && isHiddenImportantDetails) {
			return '*******'
		} else {
			return data?.wholesale_price_formatted === '0' ? '' : data?.wholesale_price_formatted
		}
	}

	if (keyColumn === 'wholesale_price') {
		return(
			<>
				{
					profile.role === 'manager' && (find(columns, {key: 'wholesale_price'})?.sensitive)
					?
					<></>
					:
					<div className="border-b grid grid-cols-2 gap-2 py-2 text-sm">

						<div className="flex">
							<div className="break-all whitespace-pre-line">{"Wholesale/Marketplace Price"}</div>
							<Tooltip
								title="This is the price that will be shown in the Elefta Marketplace for other dealers to see" placement="bottom"
								arrow
							>
								<span className="ml-1 inline-block">
									<InfoOutlinedIcon fontSize="small" />
								</span>
							</Tooltip>
						</div>

						<div className="break-all whitespace-pre-line">
							{renderWholesalePrice()}
						</div>
					</div>
				}
			</>
		)
	}

	const column = find(columns, {key: keyColumn})

	let value
	if (isCustom) {
		value = data?.custom_column_values?.[keyColumn]

		if (isBoolean(value)) {
			value = toYesNo(value)
		}

		if (isNumeric(value)) {
			value = Globals.formatCurrency(value)
		}
	} else if (keyColumn === 'condition') {
		value = watchCondition?.option
	} else if (keyColumn === 'box') {
		value = toYesNo((data || {})['box'])
	} else if (keyColumn === 'warranty_papers') {
		value = watchWarrantyPapers?.option
	} else if (keyColumn === 'warranty') {
		value = (data || {})['display_warranty']
	} else if (keyColumn === 'msrp_price') {
		value = (data || {})['msrp_price_formatted']
	} else if (keyColumn === 'cost') {
		value = (data || {})['cost_formatted']
	} else if (keyColumn === 'date_purchased') {
		value = (data || {})['date_purchased_formatted']
	} else if (keyColumn === 'visible_in_marketplace') {
		value = toYesNo(data?.visible_in_marketplace)
	} else if (keyColumn === 'online_price') {
		value = data?.online_price_formatted
	} else {
		value = (data || {})[keyColumn]
	}

	return (
		<>
			{
				profile !== 'manager' && column?.sensitive ?
					<div className="border-b grid grid-cols-2 gap-2 py-2 text-sm">
						<div>{title}</div>
						{column?.sensitive && isHiddenImportantDetails ?
							<div className="whitespace-pre-line">*******</div> :
							<div className="whitespace-pre-line">{value === '0' ? '' : value}</div>
						}
					</div> :
					<>
						{!column?.sensitive &&
							<div className="border-b grid grid-cols-2 gap-2 py-2 text-sm">
								<div>{title}</div>
								{!column?.sensitive ?
									<div className="whitespace-pre-line max-w-[300] overflow-x-scroll">{value === '0' ? '' : value}</div> :
									<div className="whitespace-pre-line max-w-[300] overflow-x-scroll">*******</div>
								}
							</div>
						}
					</>
			}

			{
				keyColumn === 'visible_in_marketplace' && data?.visible_in_marketplace && (
					<>
						<Info isHiddenImportantDetails={isHiddenImportantDetails} title="Views" data={data} columns={columns} keyColumn='views_count' profile={profile.role}/>
						<Info isHiddenImportantDetails={isHiddenImportantDetails} title="Messages" data={data} columns={columns} keyColumn='messages_count' profile={profile.role}/>
					</>
				)
			}
		</>
	)
}

const PreviewImageInventory = ({images, previewVisible, closePreview, paginateSlider}) => {
	const warpperImageRef = React.useRef(null)

	useOutsideClick(warpperImageRef, (event) => {
		if(event.target.getAttribute('text') === 'button' ) return
        closePreview()
    });

	return (
		<div className="hidden upload-media-lightbox-inner w-full h-full md:block">
			<div className="upload-media-lightbox-image relative w-full h-full flex justify-center items-center p-4">
				<div ref={warpperImageRef} className="relative h-full content-center">
					<Tooltip title="Close preview" arrow>
						<button
							onClick={closePreview}
							className="bg-black rounded-full p-2 absolute top-4 right-4 z-10"
						>
							<svg fill="white" width="18" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
								<path d="M14.8672 0.194897C14.625 0.200615 14.3949 0.301995 14.2271 0.47688L8.00026 6.70377L1.77337 0.47688C1.68784 0.38896 1.58554 0.319098 1.47252 0.271431C1.35951 0.223764 1.23807 0.199261 1.11541 0.199373C0.933136 0.199599 0.755062 0.254158 0.60394 0.35608C0.452819 0.458003 0.335512 0.602661 0.267004 0.771577C0.198497 0.940492 0.181898 1.126 0.21933 1.30439C0.256762 1.48278 0.346524 1.64597 0.47715 1.7731L6.70404 7.99999L0.47715 14.2269C0.389174 14.3113 0.318936 14.4125 0.27055 14.5245C0.222163 14.6364 0.196601 14.7569 0.19536 14.8789C0.194119 15.0008 0.217224 15.1218 0.263323 15.2347C0.309421 15.3476 0.377586 15.4502 0.463825 15.5364C0.550064 15.6227 0.652643 15.6908 0.765556 15.7369C0.878469 15.783 0.999445 15.8061 1.1214 15.8049C1.24335 15.8037 1.36383 15.7781 1.47578 15.7297C1.58774 15.6813 1.68891 15.6111 1.77337 15.5231L8.00026 9.29622L14.2271 15.5231C14.3116 15.6111 14.4128 15.6813 14.5247 15.7297C14.6367 15.7781 14.7572 15.8037 14.8791 15.8049C15.0011 15.8061 15.1221 15.783 15.235 15.7369C15.3479 15.6908 15.4505 15.6227 15.5367 15.5364C15.6229 15.4502 15.6911 15.3476 15.7372 15.2347C15.7833 15.1218 15.8064 15.0008 15.8052 14.8789C15.8039 14.7569 15.7784 14.6364 15.73 14.5245C15.6816 14.4125 15.6113 14.3113 15.5234 14.2269L9.29648 7.99999L15.5234 1.7731C15.6568 1.6452 15.7484 1.47997 15.7862 1.29908C15.824 1.11819 15.8063 0.93009 15.7354 0.759448C15.6644 0.588807 15.5436 0.443591 15.3886 0.342846C15.2337 0.2421 15.052 0.19053 14.8672 0.194897Z" />
							</svg>
						</button>
					</Tooltip>
					{
						images.length > 1 &&
						<button
							className="border-black border-2 rounded-full p-2 absolute z-10 bottom-1/2 transform left-[-60px] translate-y-1/2 active:scale-90 bg-white"
							onClick={paginateSlider.bind(this, 'previous')}
						>
							<ChevronLeftIcon sx={{ fontSize: '32px', color: 'black' }} />
						</button>
					}
					{previewVisible && previewVisible.type.includes('video') ? (
						<video className="w-full h-full" controls>
							<source src={previewVisible.video_url} type='video/mp4'/>
						</video>
					) : (
						<img src={previewVisible.url} className="h-full w-full object-contain" alt="preview" />
					)}
					{
						images.length > 1 &&
						<button
							className="border-black border-2 rounded-full p-2 absolute z-10 right-[-60px] bottom-1/2 transform translate-y-1/2 active:scale-90 bg-white"
							onClick={paginateSlider.bind(this, 'next')}
						>
							<ChevronRightIcon sx={{ fontSize: '32px', color: 'black' }} />
						</button>
					}
				</div>
			</div>
		</div>
	)
}

const GlobalStyle = createGlobalStyle`
    .swiper-scrollbar-child  {
        bottom: 15px !important;
    };
    .section-details:nth-child(odd) {
        background-color: #E9E9E9;
        width: 100%;
    };
`;

const CustomMobileModal = styled(Dialog)(({ theme }) => ({
	'& .MuiDialog-container': {
		height: '100%',
		display: 'flex',
		alignItems: 'flex-end',
	},
	'& .MuiDialog-paper': {
		width: '100%',
		maxWidth: '100%',
		margin: 0,
	},
}));

class ProductDetails extends React.Component {
	constructor(props) {
		super(props)
		this.settingsMenuRef = React.createRef(null)
		this.state = {
			previewVisible: null,
			openEditInventory: false,
			urlCopy: false,
			isVisibleWatchHistory: false,
			openSettingMenu: false,
			openShareMenu: false,
			openPreviewModal: false,
			isOpenNoteModal: false,
			openRepairModal: false,
			dataItem: {},
			isVisibleQRCode: false,
		}
	}

	componentDidMount() {
		window.addEventListener('keydown', this.handleKeyPress);
		this.singleProductView.focus()
		this.props.handleGetGlobalColumnConfigs(this.props.currentSpace)
	}

	componentWillUnmount() {
		window.removeEventListener('keydown', this.handleKeyPress);
	}

	handleKeyPress = (event) => {
		if(!this.state.previewVisible) return
        switch (event.key) {
            case Globals.keyboardButton.arrowRight:
                this.paginateSlider('next');
                break;
            case Globals.keyboardButton.arrowLeft:
                this.paginateSlider('previous');
                break;
            default:
                break;
        }
    };

	handleVisibleWachtHistory = () => {
		const { isVisibleWatchHistory } = this.state
		Globals.New_Axios()
		.get(`${Globals.NEW_API_URL}/${Globals.ENDPOINTVERSION}/spaces/${Globals.SELECTEDSPACEID}/watches/${this.props.data.id}`)
		.then(async response => {
			this.setState(
				{
					dataItem: response.data,
					isVisibleWatchHistory: !isVisibleWatchHistory
				},
			)
		})
		.catch(error => {
			addToast({
				text: 'Something went wrong. Please try again later.',
				type: 'error',
			});
		})
	}

	handleVisibleQRCode = async () => {
		const { isVisibleQRCode } = this.state
		await Globals.New_Axios()
		.get(`${Globals.NEW_API_URL}/${Globals.ENDPOINTVERSION}/spaces/${Globals.SELECTEDSPACEID}/watches/${this.props.data.id}`)
		.then(async response => {
			this.setState(
				{
					dataItem: response.data,
					isVisibleQRCode: !isVisibleQRCode
				},
			)
		})
		.catch(error => {
		})
	}

	handlePreview = file => {
		this.setState(
			{
				previewVisible: file,
			},
			() => {
				this.previewPictureContainer.focus()
			}
		)
	}

	handleClickOut = $event => {
		if ($event.target.classList.contains('single-product-view')) this.props.toggleDetailsCardInventory(false)
	}

	handlePostEdit = async () => {
		await this.handleGetWatch()
		this.setState(
			{
				openEditInventory: true
			},
		)
	}

	handleGetWatch = async () => {
		await Globals.New_Axios()
		.get(`${Globals.NEW_API_URL}/${Globals.ENDPOINTVERSION}/spaces/${Globals.SELECTEDSPACEID}/watches/${this.props.data.id}`)
		.then(async response => {
			this.setState(
				{
					dataItem: response.data,
				},
			)
		})
		.catch(error => {
		})
	}

	handleInventoryUploadDone = (response, forceClose = false) => {
		this.props.handleInventoryUploadDone(response, forceClose)
		this.setState({openEditInventory: false})
		if(isFunction(this.props.handleGetDataCheckedRows)) {
			this.props.handleGetDataCheckedRows(response)
		}
	}

	copySuccess = () => {
        this.setState({ urlCopy: true });
        this.props.addToast({
            text: 'Image address copied successfully.',
            type: 'success',
        });
    };

    copyError = (errorText) => {
        this.props.addToast({
            text: errorText,
            type: 'error',
        });
    };

    copyUrl = (uid) => {
        const urlToCopy = `${Globals.shareDomain}/inventory/share/gallery/${uid}`;
        if (navigator.clipboard && navigator.clipboard.writeText) {
            navigator.clipboard.writeText(urlToCopy)
                .then(this.copySuccess)
                .catch(() => this.copyError('Failed to copy image address.'));
        } else {
            this.copyError('Failed to copy image address because your browser does not support it.');
        }
    };

	closeEditInventory = () => {
		this.setState({ openEditInventory: false })
	}

	paginateSlider = (direction) => {
		let images = null
		if (this.props.data.images) {
			if (isObject(this.props.data.images)) {
				images = this.props.data.images
			} else {
				images = JSON.parse(this.props.data.images)
			}
		}
		const index = findIndex(images, (image) => image.url === this.state.previewVisible.url)
		if (direction === 'previous') {
			this.setState({previewVisible: index === 0 ? images[images.length -1] : images[index - 1]})
		} else {
			this.setState({previewVisible: index === images.length -1 ?  images[0] : images[index + 1]})
		}
    };

	handleShareImage = async () => {
		this.setState({openShareMenu: false})
		if (navigator.share) {
		  try {
			await navigator.share({
			  title: `${this.props.currentSpace.name}`,
			  url: `${Globals.shareDomain}/inventory/share/gallery/${this.props.data.uid}`
			});
		  } catch (err) {
			console.error("Share failed:", err.message);
		  }
		} else {
		  console.log("Web Share API not supported. Implementing fallback sharing method.");
		  this.fallbackShareMethod();
		}
	  };

	fallbackShareMethod = () => {
		const url = `${Globals.shareDomain}/inventory/share/gallery/${this.props.data.uid}`;
		if (navigator.clipboard && navigator.clipboard.writeText) {
		  navigator.clipboard.writeText(url).then(() => {
		  }, (err) => {
			console.error("Could not copy URL to clipboard:", err);
		  });
		} else {
		  console.error("Clipboard API not supported");
		}
	  };

	handleShareDetailWatchImage = async () => {
		this.setState({openShareMenu: false})
		const selectedAttributes = [
			'sku',
			'wholesale_price',
			'reference_number',
			'brand',
			'series',
			'serial_number',
			'condition',
			'dial',
			'bezel',
			'bracelet',
			'warranty_papers',
			'warranty',
			'box',
			'link_count',
			'addition_details',
		]

		Globals.New_Axios()
			.post(`${Globals.NEW_API_URL}/${Globals.ENDPOINTVERSION}/spaces/${Globals.SELECTEDSPACEID}/marketplaces/${this.props.data.id}/share`, {
				selected_attributes: selectedAttributes,
				share_method: 'whatsapp'
			})
			.then(async res => {
					this.setState({openShareMenu: false})
					if (navigator.share) {
						try {
							await navigator.share({
								title: `${this.props.currentSpace.name}`,
								url: `${Globals.shareDomain}/inventory/share/item/${res.data.code}?share=true`
							});
						} catch (err) {
							console.error("Share failed:", err.message);
						}
						} else {
							console.log("Web Share API not supported. Implementing fallback sharing method.");
							this.fallbackShareDetailWatchImage(res.data.code);
						}
			})
	  };

	fallbackShareDetailWatchImage = (code) => {
		const url = `${Globals.shareDomain}/inventory/share/item/${code}?share=true`;
		if (navigator.clipboard && navigator.clipboard.writeText) {
		  navigator.clipboard.writeText(url).then(() => {
		  }, (err) => {
			console.error("Could not copy URL to clipboard:", err);
		  });
		} else {
		  console.error("Clipboard API not supported");
		}
	  };

	renderInternalNoteText =() => {
		const { columns, data, profile, isHiddenImportantDetails } = this.props
		const column = find(columns, {key: 'internal_note'})
		if(profile.role == 'manager' && column?.sensitive) return ''
		if(column?.sensitive && isHiddenImportantDetails) {
			return '*******'
		} else {
			return data?.internal_note
		}
	}

	renderStatus =() => {
		const { columns, data, profile, isHiddenImportantDetails } = this.props
		const column = find(columns, {key: 'status'})
		if(profile.role == 'manager' && column?.sensitive) return ''
		if(column?.sensitive && isHiddenImportantDetails) {
			return '*******'
		} else {
			return data?.display_status
		}
	}

	renderSoldDate =() => {
		const { columns, data, profile, isHiddenImportantDetails } = this.props
		const column = find(columns, {key: 'sold_date'})
		if(profile.role == 'manager' && column?.sensitive) return ''
		if(column?.sensitive && isHiddenImportantDetails) {
			return '*******'
		} else {
			return `Sold Date ${data?.sold_date_formatted ? data?.sold_date_formatted : ''}`
		}
	}

	renderSoldPrice =() => {
		const { columns, data, profile, isHiddenImportantDetails } = this.props
		const column = find(columns, {key: 'sold_price'})
		if(profile.role == 'manager' && column?.sensitive) return ''
		if(column?.sensitive && isHiddenImportantDetails) {
			return '*******'
		} else {
			return `Sold Price ${data?.sold_price_formatted}`
		}
	}

	renderProfit =() => {
		const { columns, data, profile, isHiddenImportantDetails } = this.props
		const column = find(columns, {key: 'profit'})
		if(profile.role === 'manager' && column?.sensitive) return ''
		if(column?.sensitive && isHiddenImportantDetails) {
			return '*******'
		} else {
			return `Profit ${data?.profit_formatted}`
		}
	}

	handleEscapeKeyDown = (event) => {
		const { state } = this
		if(state.isVisibleWatchHistory || state.isVisibleQRCode) return
		if(event.key === 'Escape' && event.target.classList.contains('single-product-view')) {
			this.props.toggleDetailsCardInventory(false)
		}
	}

	render() {
		const state = this.state
		const props = this.props
		const { globalColumnConfigs } = props

		const space = JSON.parse(Globals.Cache.get(Globals.USERSELECTEDSPACE))
		let Images = null
		let customColumns = null

		const watchStatus = find(collections.inventoryStatusesCollection, {value: props.data?.status})
		const watchCondition = find(collections.inventoryConditionsCollection, {value: props.data?.condition})
		const warrantyCardsList = Globals.warrantyCardsList[lowerCase(props.data?.brand)] || Globals.warrantyCardsList.other
		const watchWarrantyPapers = find(warrantyCardsList, {value: props.data?.warranty_papers})

		const basicInfo = globalColumnConfigs.filter(column => column.block === 'basic_info' && column.visible)
		const scopeOfDelivery = globalColumnConfigs.filter(column => column.block === 'scope_of_delivery' && column.visible)
		const priceInfo = globalColumnConfigs.filter(column => column.block === 'price_info' && column.visible)
		const marketplaceInfo = globalColumnConfigs.filter(column => column.block === 'marketplace_info' && column.visible)
		const customData = globalColumnConfigs.filter(column => column.block === 'custom_data' && column.visible)

		try {
			if (props.data.images) {
				if (isObject(props.data.images)) {
					Images = props.data.images
				} else {
					Images = JSON.parse(props.data.images)
				}
			}

			customColumns = props.columns.filter(header => header.is_custom_column)

		} catch (error) { }

		return (
			<React.Fragment>
				<div
					ref={singleProductView => {
						this.singleProductView = singleProductView
					}}
					className="single-product-view bg-black/50 fixed top-0 right-0 left-0 bottom-0 z-40"
					onClick={this.handleClickOut}
					tabIndex="-1"
					onKeyDown={this.handleEscapeKeyDown}
				>
					{state.isVisibleWatchHistory &&
						<WatchHistory handleVisibleWachtHistory={this.handleVisibleWachtHistory} data={this.state.dataItem}/>
					}
					{state.isVisibleQRCode &&
						<QRCode handleVisibleQRCode={this.handleVisibleQRCode} data={this.state.dataItem}/>
					}
					<div className="bg-white rounded-tl-xl absolute top-0 right-0 shadow-xl overflow-hidden" style={{ maxWidth: '950px', width: '100%' }}>
						<TopCardDetails
							toggleDetailsCardInventory={props.toggleDetailsCardInventory}
							post={props.data}
							openEditPost={this.handlePostEdit}
							onDone={this.handleInventoryUploadDone}
							profile={this.props.profile}
							closeEditInventory={this.closeEditInventory}
							openEditInventory={state.openEditInventory}
							previewVisible={this.state.previewVisible}
							handlePreview={this.handlePreview}
						/>
						<div className="single-product-details grid grid-cols-1 h-screen pb-40 overflow-y-scroll ">
							<div className="px-10 py-4">
								<div className="grid grid-flow-row-dense grid-cols-3 gap-6">
									<div className="col-span-2">
										<div className="sticky top-0">
											<div>
												{
													basicInfo.length > 0 && (
														<h2 className="text-md font-bold tracking-wider py-2 border-b">Basic Info</h2>
													)
												}
												{
													basicInfo.map(config => {
														return (
															<Info
																isHiddenImportantDetails={props.isHiddenImportantDetails}
																title={config.label}
																data={props.data}
																columns={props.columns}
																keyColumn={config.key}
																watchWarrantyPapers={watchWarrantyPapers}
																watchCondition={watchCondition}
																profile={props.profile.role}
																isCustom={config.is_custom_column}
															/>
														)
													})
												}
											</div>

											<div>
												{
													scopeOfDelivery.length > 0 && (
														<h2 className="text-md font-bold tracking-wider py-2 border-b">Scope of Delivery</h2>
													)
												}
												{
													scopeOfDelivery.map(config => {
														return (
															<Info
																isHiddenImportantDetails={props.isHiddenImportantDetails}
																title={config.label}
																columns={props.columns}
																keyColumn={config.key}
																data={props.data}
																watchWarrantyPapers={watchWarrantyPapers}
																watchCondition={watchCondition}
																profile={props.profile.role}
																isCustom={config.is_custom_column}
															/>
														)
													})
												}
											</div>

											<div>
												{
													priceInfo.length > 0 && (
														<h2 className="text-md font-bold tracking-wider py-2 border-b">Price Info</h2>
													)
												}
												{
													priceInfo.map(config => {
														return (
															<Info
																isHiddenImportantDetails={props.isHiddenImportantDetails}
																title={config.label}
																columns={props.columns}
																keyColumn={config.key}
																data={props.data}
																watchWarrantyPapers={watchWarrantyPapers}
																watchCondition={watchCondition}
																profile={props.profile.role}
																isCustom={config.is_custom_column}
															/>
														)
													})
												}
											</div>

											<div>
												{
													marketplaceInfo.length > 0 && (
														<h2 className="text-md font-bold tracking-wider py-2 border-b">Marketplace Info</h2>
													)
												}
												{
													marketplaceInfo.map(config => {
														return (
															<Info
																isHiddenImportantDetails={props.isHiddenImportantDetails}
																title={config.label}
																columns={props.columns}
																keyColumn={config.key}
																data={props.data}
																watchWarrantyPapers={watchWarrantyPapers}
																watchCondition={watchCondition}
																profile={props.profile.role}
																isCustom={config.is_custom_column}
															/>
														)
													})
												}
											</div>

											{!isEmpty(customData) && (
												<div>
													<h2 className="text-md font-bold tracking-wider py-2 border-b">Custom Data</h2>
													{
														customData.map(config => {
															return (
																<Info
																	isHiddenImportantDetails={props.isHiddenImportantDetails}
																	data={props.data}
																	title={config.label}
																	watchWarrantyPapers={watchWarrantyPapers}
																	watchCondition={watchCondition}
																	columns={props.columns}
																	keyColumn={config.key}
																	profile={props.profile.role}
																	isCustom={config.is_custom_column}
																/>
															);
														})
													}

												</div>
											)}
										</div>
									</div>

									<div className="col-span-1">
										<div className="sticky top-0">
											<div className="mb-4">
												<h2 className="text-md font-bold tracking-wider py-2">Status</h2>
													<p className="text-sm">
														{this.renderStatus()}
													</p>
												{props.data?.status === 'sold_to' && (

													<p className="text-sm">{this.renderSoldDate()}</p>
												)}

												{!!props.data?.sold_price && watchStatus.value === 'sold_to' && (
													<p className="text-sm">{this.renderSoldPrice()}</p>
												)}

												{!!props.data?.profit && watchStatus.value === 'sold_to' && (
													<p className="text-sm">{this.renderProfit()}</p>
												)}
											</div>

											{!isEmpty(Images) && (
												<div className="mb-4">
													<div
														style={{
															display: 'flex',
															justifyContent: 'space-between',
														}}
													>
														<h2 className="text-md font-bold tracking-wider py-2">Media</h2>
														{!(find(this.props.columns, {key: 'images'})?.sensitive) && this.props.profile.role !== 'manager' &&
															<button onClick={() => this.copyUrl(props.data?.uid)}>
																<svg
																	version="1.0"
																	xmlns="http://www.w3.org/2000/svg"
																	width="20.000000pt"
																	height="20.000000pt"
																	viewBox="0 0 1200.000000 1200.000000"
																	preserveAspectRatio="xMidYMid meet"
																>
																	<g transform="translate(0.000000,1200.000000) scale(0.100000,-0.100000)" fill="blue" stroke="none">
																		<path
																			d="M5195 9850 l-800 -800 168 -167 167 -168 515 515 515 515 0 -2233 0
																-2232 240 0 240 0 0 2232 0 2233 515 -515 515 -515 167 168 168 167 -800 800
																c-440 440 -802 800 -805 800 -3 0 -365 -360 -805 -800z M3450 8145 c-291 -58 -514 -291 -560 -585 -8 -55 -10 -661 -8 -2325
																3 -2135 4 -2253 21 -2310 76 -251 259 -433 507 -502 62 -17 180 -18 2590 -18
																2410 0 2528 1 2590 18 248 69 431 251 507 502 17 57 18 175 21 2320 2 2036 1
																2268 -13 2339 -59 293 -265 500 -557 560 -67 14 -191 16 -953 16 l-875 0 0
																-240 0 -240 856 0 c622 0 868 -3 902 -12 64 -16 134 -86 150 -150 17 -67 17
																-4409 0 -4476 -16 -64 -86 -134 -150 -150 -67 -17 -4889 -17 -4956 0 -64 16
																-134 86 -150 150 -17 67 -17 4409 0 4476 16 64 86 134 150 150 34 9 280 12
																902 12 l856 0 0 240 0 240 -882 -1 c-680 -1 -898 -4 -948 -14z"
																		/>
																	</g>
																</svg>
															</button>
														}
													</div>
													{find(this.props.columns, {key: 'images'})?.sensitive && props.isHiddenImportantDetails ?
														this.props.profile.role !== 'manager' ?  '*******' : <></>
														:
														<div className="grid grid-cols-2 gap-5">
															{Images.map((data, index) => {
																const isVideo = data.type.includes('video')
																return (
																	<div
																		className="relative rounded-xl"
																		key={index}
																		style={{
																			backgroundImage: `url('${data.url}')`,
																			width: '100%',
																			paddingBottom: '100%',
																			backgroundSize: 'cover',
																			backgroundPosition: 'center',
																		}}
																	>
																		<div className="preview absolute top-0 left-0 right-0 bottom-0 flex items-center justify-center">
																			<Tooltip title="View media" placement="bottom" arrow>
																				<button onClick={this.handlePreview.bind(this, data)} className=" preview-btn bg-blue p-2 rounded-full">
																					{isVideo ? (
																						<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" fill="white" height="20" width="20" version="1.1" id="Layer_1" viewBox="0 0 512 512" xml:space="preserve">
																							<g>
																								<g>
																									<path d="M256,0C114.51,0,0,114.497,0,256c0,141.49,114.495,256,256,256c141.491,0,256-114.497,256-256    C512,114.509,397.503,0,256,0z M256,478.609c-122.746,0-222.609-99.862-222.609-222.609S133.252,33.391,256,33.391    S478.609,133.254,478.609,256S378.746,478.609,256,478.609z"/>
																								</g>
																							</g>
																							<g>
																								<g>
																									<path d="M358.185,241.544l-155.778-90.01c-11.128-6.426-25.048,1.63-25.048,14.456V346.01c0,12.922,14.01,20.833,25.048,14.456    l155.778-90.01C369.301,264.032,369.287,247.96,358.185,241.544z M210.75,317.081V194.919L316.461,256L210.75,317.081z"/>
																								</g>
																							</g>
																						</svg>
																					) : (
																						<svg width="20" height="20" viewBox="0 0 22 15" xmlns="http://www.w3.org/2000/svg" fill="white">
																							<path d="M11 2C14.79 2 18.17 4.13 19.82 7.5C18.17 10.87 14.79 13 11 13C7.21 13 3.83 10.87 2.18 7.5C3.83 4.13 7.21 2 11 2ZM11 0C6 0 1.73 3.11 0 7.5C1.73 11.89 6 15 11 15C16 15 20.27 11.89 22 7.5C20.27 3.11 16 0 11 0ZM11 5C12.38 5 13.5 6.12 13.5 7.5C13.5 8.88 12.38 10 11 10C9.62 10 8.5 8.88 8.5 7.5C8.5 6.12 9.62 5 11 5ZM11 3C8.52 3 6.5 5.02 6.5 7.5C6.5 9.98 8.52 12 11 12C13.48 12 15.5 9.98 15.5 7.5C15.5 5.02 13.48 3 11 3Z" />
																						</svg>
																					)}
																				</button>
																			</Tooltip>
																		</div>
																	</div>
																)
															})}
														</div>
													}
												</div>
											)}

											<div>
												<h2 className="text-md font-bold tracking-wider py-2">Internal Note</h2>
													<div className="text-sm">{this.renderInternalNoteText()}</div>
											</div>
											<div className='flex'>
												<div onClick={this.handleVisibleQRCode} className='cursor-pointer max-w-[130px] max-w-full mr-[30px]'>
													<h2 className="text-md font-bold tracking-wider py-2 underline">QR Code</h2>
												</div>
												{
													space?.role == 'owner' || space?.role == 'admin' ?
													<div onClick={this.handleVisibleWachtHistory} className='cursor-pointer w-[130px] max-w-full'>
														<h2 className="text-md font-bold tracking-wider py-2 underline">Watch History</h2>
													</div> : <></>
												}
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					{state.previewVisible && (
						<div
							ref={previewPictureContainer => {
								this.previewPictureContainer = previewPictureContainer
							}}
							className="bg-black flex items-center md:upload-media-lightbox fixed top-0 right-0 left-0 bottom-0 z-50 md:p-11 md:bg-inherit"
							tabIndex="-1"
							onKeyDown={e => (e.key === 'Escape' ? this.setState({previewVisible: null}) : '')}
						>
							<button
								onClick={e =>
									this.setState({
										previewVisible: null,
									})
								}
								className="bg-black rounded-full p-2 absolute top-4 right-2 z-10 md:hidden"
							>
								<svg fill="white" width="18" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
									<path d="M14.8672 0.194897C14.625 0.200615 14.3949 0.301995 14.2271 0.47688L8.00026 6.70377L1.77337 0.47688C1.68784 0.38896 1.58554 0.319098 1.47252 0.271431C1.35951 0.223764 1.23807 0.199261 1.11541 0.199373C0.933136 0.199599 0.755062 0.254158 0.60394 0.35608C0.452819 0.458003 0.335512 0.602661 0.267004 0.771577C0.198497 0.940492 0.181898 1.126 0.21933 1.30439C0.256762 1.48278 0.346524 1.64597 0.47715 1.7731L6.70404 7.99999L0.47715 14.2269C0.389174 14.3113 0.318936 14.4125 0.27055 14.5245C0.222163 14.6364 0.196601 14.7569 0.19536 14.8789C0.194119 15.0008 0.217224 15.1218 0.263323 15.2347C0.309421 15.3476 0.377586 15.4502 0.463825 15.5364C0.550064 15.6227 0.652643 15.6908 0.765556 15.7369C0.878469 15.783 0.999445 15.8061 1.1214 15.8049C1.24335 15.8037 1.36383 15.7781 1.47578 15.7297C1.58774 15.6813 1.68891 15.6111 1.77337 15.5231L8.00026 9.29622L14.2271 15.5231C14.3116 15.6111 14.4128 15.6813 14.5247 15.7297C14.6367 15.7781 14.7572 15.8037 14.8791 15.8049C15.0011 15.8061 15.1221 15.783 15.235 15.7369C15.3479 15.6908 15.4505 15.6227 15.5367 15.5364C15.6229 15.4502 15.6911 15.3476 15.7372 15.2347C15.7833 15.1218 15.8064 15.0008 15.8052 14.8789C15.8039 14.7569 15.7784 14.6364 15.73 14.5245C15.6816 14.4125 15.6113 14.3113 15.5234 14.2269L9.29648 7.99999L15.5234 1.7731C15.6568 1.6452 15.7484 1.47997 15.7862 1.29908C15.824 1.11819 15.8063 0.93009 15.7354 0.759448C15.6644 0.588807 15.5436 0.443591 15.3886 0.342846C15.2337 0.2421 15.052 0.19053 14.8672 0.194897Z" />
								</svg>
							</button>
 							<PreviewImageMobile data={this.props.data} />
							<PreviewImageInventory
								images={Images}
								previewVisible={this.state.previewVisible}
								closePreview={() => this.setState({previewVisible: null})}
								paginateSlider={this.paginateSlider}
							/>
						</div>
					)}
				</div>
				{state.openEditInventory && (
					<div className="bg-black/50 fixed top-0 right-0 left-0 bottom-0 z-50">
						<div
						 	className="edit-detail-view bg-white rounded-tl-xl absolute top-0 right-0 shadow-xl overflow-hidden h-full" style={{maxWidth: '850px'}}
						 >
							<div className="border-b flex items-center px-8 py-6 relative w-full">
								<span className="font-bold text-2xl uppercase">Edit Inventory</span>
								<button
									onClick={() =>
										this.setState({
											openEditInventory: false,
										})
									}
									className="absolute right-0 mr-8 cursor-pointer"
								>
									<svg className="fill-current" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
										<path d="M14.8672 0.194897C14.625 0.200615 14.3949 0.301995 14.2271 0.47688L8.00026 6.70377L1.77337 0.47688C1.68784 0.38896 1.58554 0.319098 1.47252 0.271431C1.35951 0.223764 1.23807 0.199261 1.11541 0.199373C0.933136 0.199599 0.755062 0.254158 0.60394 0.35608C0.452819 0.458003 0.335512 0.602661 0.267004 0.771577C0.198497 0.940492 0.181898 1.126 0.21933 1.30439C0.256762 1.48278 0.346524 1.64597 0.47715 1.7731L6.70404 7.99999L0.47715 14.2269C0.389174 14.3113 0.318936 14.4125 0.27055 14.5245C0.222163 14.6364 0.196601 14.7569 0.19536 14.8789C0.194119 15.0008 0.217224 15.1218 0.263323 15.2347C0.309421 15.3476 0.377586 15.4502 0.463825 15.5364C0.550064 15.6227 0.652643 15.6908 0.765556 15.7369C0.878469 15.783 0.999445 15.8061 1.1214 15.8049C1.24335 15.8037 1.36383 15.7781 1.47578 15.7297C1.58774 15.6813 1.68891 15.6111 1.77337 15.5231L8.00026 9.29622L14.2271 15.5231C14.3116 15.6111 14.4128 15.6813 14.5247 15.7297C14.6367 15.7781 14.7572 15.8037 14.8791 15.8049C15.0011 15.8061 15.1221 15.783 15.235 15.7369C15.3479 15.6908 15.4505 15.6227 15.5367 15.5364C15.6229 15.4502 15.6911 15.3476 15.7372 15.2347C15.7833 15.1218 15.8064 15.0008 15.8052 14.8789C15.8039 14.7569 15.7784 14.6364 15.73 14.5245C15.6816 14.4125 15.6113 14.3113 15.5234 14.2269L9.29648 7.99999L15.5234 1.7731C15.6568 1.6452 15.7484 1.47997 15.7862 1.29908C15.824 1.11819 15.8063 0.93009 15.7354 0.759448C15.6644 0.588807 15.5436 0.443591 15.3886 0.342846C15.2337 0.2421 15.052 0.19053 14.8672 0.194897Z" />
									</svg>
								</button>
							</div>
							<div>
								<div>
									<EditInventoryComponent
										columns={props.columns}
										userSelectedFilter={props.userSelectedFilter}
										loadSelectedFilter={props.loadSelectedFilter}
										handleFilterAction={props.handleFilterAction}
										getConditions={props.getConditions}
										filter_values_overall={props.filter_values_overall}
										onDone={this.handleInventoryUploadDone}
										post={this.state.dataItem}
										handleSearchByValue={props.handleSearchByValue}
										searchKey={props.searchKey}
										handleUpdateInventoriesAfterAddQuickMemoOrSale={this.props.handleUpdateInventoriesAfterAddQuickMemoOrSale}
										closeEditInventory={this.closeEditInventory}
										openEditInventory={state.openEditInventory}
										handleGetWatch={this.handleGetWatch}
										handlePrimaryTab={props.handlePrimaryTab}
									/>
								</div>
							</div>
						</div>
					</div>
				)}

				<CustomMobileModal
					className="md:hidden text-[10px]"
					open={true}
					sx={{
						'& .MuiDialog-paper': {
							width: '100%',
							maxWidth: '100%',
							height: '100%',
							maxHeight: '100%',
							color: '#5D6E81',
						},
					}}
				>
					<GlobalStyle />
					<DialogTitle
						className="bg-[#DEDEDE] relative text-[#5D6E81] max-h-[63px] flex justify-between items-center rounded-tl-2xl w-full"
					>
						<Box className="flex items-center">
							<KeyboardArrowLeftIcon onClick={() => {this.props.toggleDetailsCardInventory(false)}}/>
							<h2 className="font-semibold text-[30px] w-auto uppercase truncate max-w-[250px]">SKU: {props.data?.sku}</h2>
						</Box>
						<div className="flex items-center">
							<IosShareIcon sx={{ color: state.openShareMenu ? '#4b7bbd' : 'black' }} onClick={() => this.setState({openShareMenu: !state.openShareMenu})} className="cursor-pointer text-black font-bold" />
							<TuneIcon  sx={{ color: state.openSettingMenu ? '#4b7bbd' : 'black' }}  color={state.openSettingMenu ? 'primary' : ''} onClick={() => this.setState({openSettingMenu: !state.openSettingMenu})} className="cursor-pointer text-red font-bold ms-3"/>
						</div>
					</DialogTitle>
					{
						state.openShareMenu &&
							<div className="absolute border border-[#D9E2EE] right-[60px] top-[45px] w-[200px] rounded-md shadow-[0px_4px_20px_0px_rgba(0,0,0,0.10)] bg-white z-20">
								<ul className="text-[#5D6E81] px-2">
									<li className="border-b">
										<div onClick={this.handleShareImage} className="flex py-2 justify-between items-center">
											<p className="text-sm text-[#5D6E81]" >Share Images Only</p>
										</div>
									</li>
									<li className="border-b">
										<div onClick={this.handleShareDetailWatchImage} className="flex py-2 justify-between items-center">
											<p className="text-sm text-[#5D6E81]" >Share Details and Images</p>
										</div>
									</li>
								</ul>
							</div>
					}
					{
						state.openSettingMenu &&
							<div className="absolute border border-[#D9E2EE] right-[25px] top-[45px] w-[200px] rounded-md shadow-[0px_4px_20px_0px_rgba(0,0,0,0.10)] bg-white z-20">
								<ul className="text-[#5D6E81] px-2">
									<li className="border-b">
									<div
										onClick={() => {
											this.setState({
												openSettingMenu: !state.openSettingMenu
											});
											this.handlePostEdit()
										}}
										className="flex py-2 justify-between items-center"
									>
										<p className="text-sm" >Edit</p>
										<EditNoteIcon className="text-[#5D6E81]" sx={{width: '22px'}} />
									</div>
									</li>
									<li className="border-b">
									<div onClick={() => this.setState({openRepairModal: true, openSettingMenu: false})} className="flex py-2 justify-between items-center">
										<p className="text-sm">Repairs</p>
										<BuildOutlinedIcon className="text-[#5D6E81] rotate-90" sx={{width: '18px'}} />
									</div>
									</li>
									<li className="border-b">
									<div onClick={() => { this.setState({isOpenNoteModal: true, openSettingMenu: false})}} className="flex py-2 justify-between items-center">
										<p className="text-sm">Notes</p>
										<MessageOutlinedIcon className="text-[#5D6E81]" sx={{width: '18px'}} />
									</div>
									</li>
									<li>
									<div className="flex py-2 justify-between items-center text-[#B71F23]" onClick={() => this.setState({openSettingMenu: !state.openSettingMenu})}>
										<p className="text-sm">Cancel</p>
										<CloseOutlinedIcon className="text-[#B71F23]" sx={{width: '18px'}} />
									</div>
									</li>
								</ul>
							</div>
					}
					<DialogContent className="" sx={{padding: 0, height: '100%'}}>
						<WatchDetailMobile
							selectedThread={{watch: props.data}}
							isVisibleProducDetail={true}
							openPreviewModal={() => this.setState({openPreviewModal: true})}
							isScanned={props.isScanned}
						/>
					</DialogContent>
				</CustomMobileModal>
				{state.openPreviewModal &&
					<PreviewModal Images={Images} onClose={() => this.setState({openPreviewModal: false})} handleShareImage={this.handleShareImage}/>
				}
				{state.isOpenNoteModal &&
					<NoteModal
						isOpen={state.isOpenNoteModal}
						closeModal={() => this.setState({isOpenNoteModal: !state.isOpenNoteModal})}
						data={props.data}
					/>
				}
				{state.openRepairModal &&
					<RepairModal
						isOpen={state.openRepairModal}
						closeModal={() => this.setState({openRepairModal: !state.openRepairModal})}
						data={props.data}
						addToast={props.addToast}
					/>
				}
			</React.Fragment>
		)
	}
}

const mapStateToProps = (state, props) => {
	return {
		profile: state.profile,
		isOpenSideBar: state.isOpenSideBar,
		loggedin: state.loggedin,
		isOpenDetailsInventory: state.isOpenDetailsInventory,
		isOpenNote: state.isOpenNote,
		isOpenRepairs: state.isOpenRepairs,
		dataProvider: state.dataProvider,
		globalColumnConfigs: state.globalColumnConfigs,
		currentSpace: state.currentSpace,
	}
}

const mapActionsToProps = {
	toggleSidebar,
	toggleDetailsCardInventory,
	toggleNote,
	toggleRepairs,
	toggleDataProvider,
	addToast,
	handleGetGlobalColumnConfigs
}

export default connect(mapStateToProps, mapActionsToProps)(ProductDetails)
