import React, {useState} from 'react'
import { useDispatch } from 'react-redux'

import {Globals} from '../../Context'
import { addToast } from '../../app/appActions'

const AddNewQuickMemo = ({formData, hideAddNewQuickMemo, customer, handleUpdateInventoriesAfterAddQuickMemoOrSale, closeEditInventory, handleSubmit}) => {
	const [productItem, setProductItem] = useState({product_name: formData.name, sku: formData.sku, price: formData.wholesale_price, shipping: 0})
	const dispatch = useDispatch()
	const handleFormSubmit = (event) => {
        return Globals.New_Axios()
			.post(`${Globals.NEW_API_URL}/${Globals.ENDPOINTVERSION}/spaces/${Globals.SELECTEDSPACEID}/memos`, {
				memo: {
					customer_id: customer.id,
					memo_items_attributes: [
                        {
                            quantity: 1,
                            price: parseFloat(productItem.price),
                            memoable_id: formData.id,
                            memoable_type: "Watch"
                        },
                    ],
					shipping_price: parseFloat(productItem.shipping),
                    comments_attributes: null,
                    misc_items: [],
					status: 'published',
				},
			})
			.then(response => {
				if (response.status && response.status === 201) {
                    hideAddNewQuickMemo()
                    Globals.New_Axios()
                    .get(`${Globals.NEW_API_URL}/${Globals.ENDPOINTVERSION}/spaces/${Globals.SELECTEDSPACEID}/watches/${formData.id}`)
                    .then(res => {
                        if(res.data) {
                            handleSubmit(event)
                        }
                        closeEditInventory()
                    })
				}
			})
			.catch(error => {
                dispatch(
                    addToast({
                        title: 'Error',
                        text: error.response?.data?.message || 'Something went wront please try again later.',
                        type: 'error',
                    }))
			})
	}

	return (
		<div className="bg-black/50 bottom-0 fixed flex items-center justify-center left-0 right-0 top-0 z-50 p-3">
			<div className="w-[719px]">
				<div className="bg-slate-600 flex items-center px-8 py-4 relative rounded-tl-[5px] rounded-tr-[5px] text-white w-full">
					<span className="text-xl font-extrabold">{`Create Quick Memo to ${customer.company_name}`}</span>
				</div>
                <div className="bg-white text-gray-500 rounded-bl-[5px] rounded-br-[5px] px-8 py-4 w-full">
                    <div className="grid grid-cols-2 gap-4">
                        <div className="text-[#6C727F]">
                            <div className="text-base font-semibold">
                                Product Name
                            </div>
                            <div className="font-normal text-base">
                                {productItem.product_name}
                            </div>
                        </div>
                        <div className='grid grid-cols-2 border-b-2'>
                            <div className="text-[#6C727F]">
                                <div className="text-base font-semibold">
                                    SKU
                                </div>
                                <div className="font-normal text-base">
                                    {productItem.sku}
                                </div>
                            </div>
                            <div className="text-[#6C727F]">
                                <div className="text-base font-semibold">
                                    Price
                                </div>
                                <div className="font-normal text-base">
                                    <input
                                        value={productItem.price}
                                        onChange={e => {
                                            if(!!e.target.value) {
                                                setProductItem({...productItem, price: e.target.value})
                                            } else {
                                                setProductItem({...productItem, price: ''})
                                            }
                                        }}
                                        type="number"
                                        name="price"
                                        className="w-[121px] border-[1px] rounded-[5px] text-base"
                                    />
                                </div>

                            </div>
                        </div>
                    </div>
                    <div className="grid grid-cols-2 pt-2">
                        <div></div>
                        <div className="flex justify-center">
                            <div className="grid grid-cols-2 gap-4 text-base">
                                <div>
                                    <div className='pt-2 font-semibold'>
                                        Subtotal
                                    </div>
                                    <div className='pt-2 font-semibold'>
                                        Shipping
                                    </div>
                                    <div className='pt-2 font-semibold'>
                                        Total
                                    </div>
                                </div>
                                <div>
                                    <div className='pt-2 font-normal'>
                                        { Globals.formatCurrency(productItem.price ? parseFloat(productItem.price) : 0)}
                                    </div>
                                    <div className='pt-2 font-normal'>
                                        <input
                                            value={productItem.shipping}
                                            onChange={e => {
                                                if(!!e.target.value) {
                                                    setProductItem({...productItem, shipping: e.target.value})
                                                } else {
                                                    setProductItem({...productItem, shipping: ''})
                                                }
                                            }}
                                            type="number"
                                            name="shipping"
                                            className="w-[121px] border-[1px] rounded-[5px] text-base"
                                        />
                                    </div>
                                    <div className='pt-2 font-normal'>
                                    {Globals.formatCurrency((productItem.price ? parseFloat(productItem.price) : 0) + (productItem.shipping ? parseFloat(productItem.shipping) : 0)
											)}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="flex justify-center">
                        <div className='grid grid-cols-2 gap-2 pt-12'>
                            <button className='w-[150px] h-[39px] bg-[#B91B20] rounded-[5px] text-[#fff] font-bold text-base' onClick={hideAddNewQuickMemo}>
                                CANCEL
                            </button>
                            <button className='w-[150px] h-[39px] bg-[#004094] rounded-[5px] text-[#fff] font-bold text-base' onClick={handleFormSubmit}>
                                CREATE MEMO
                            </button>
                        </div>
                    </div>
                </div>
			</div>
		</div>
	)
}
export default AddNewQuickMemo
