import React from 'react'
import {connect} from 'react-redux'
import Classnames from 'classnames'
import moment from 'moment'

import { addToast } from './../app/appActions'
import * as collections from '../utils/collections'
import { Globals } from '../Context'
import isNull from 'lodash/isNull'
import find from 'lodash/find'
import debounce from 'lodash/debounce'
import isFunction from 'lodash/isFunction'
import isEmpty from 'lodash/isEmpty'
import remove from 'lodash/remove'

class BulkEditor extends React.Component {
	constructor() {
		super()
		this.state = {
			checkedRows: [],
			showRows: [],
			numberOfItems: 50,
			activeDropDown: null,
			sendingData: false,
			bulkOptions: {
				status: null,
				status_details: null,
				visible_in_marketplace: null,
				sold_price: null,
			},
			formErrors: {},
			offset: 0,
		}
	}

	componentDidMount() {
		const props = this.props
		if(props.kind === 'Sold Inventory') {
			const bulkOptions = this.state.bulkOptions
			bulkOptions.status = 'sold_to'
			this.setState({
				bulkOptions:{...this.state.bulkOptions,
					status: 'sold_to'
				}
			})
		}
		const { checkedRows } = props
		this.setState({
			checkedRows: JSON.parse(JSON.stringify(checkedRows)),
			offset: 1,
			showRows: checkedRows.slice(0, this.state.numberOfItems),
		})
	}

	loadMoreData = () => {
		const state = this.state
		const {scrollTop, scrollHeight, clientHeight} = this.InventoryTable
		if (Math.round(scrollTop + clientHeight + 4) >= scrollHeight && state.checkedRows.length >= state.numberOfItems) {
			this.handleLoadFirstData()
		}
	}

	handleLoadFirstData = (checkedRows) => {
		const state = this.state
		this.setState({
			offset: state.offset + 1,
			showRows: state.checkedRows.slice(0, state.numberOfItems * state.offset),
		})
	}

	handleToggleDropdown = (dropdown, $event) => {
		$event.preventDefault()
		if (this.state.activeDropDown === dropdown) return this.setState({activeDropDown: null})

		this.setState({
			activeDropDown: dropdown,
		})
	}

	handleOptionChange = (value, key, $event) => {
		const bulkOptions = this.state.bulkOptions
		bulkOptions[key] = value === 'none' ? null : value

		this.setState({
			activeDropDown: null,
		})

		let checkedRows = this.state.checkedRows
		if(key === 'status') {
			this.props.checkedRows.map(value => {
				if(!(isNull(value.sale_id) && isNull(value.memo_id)) && (value.status === 'pending_sale_to' || value.status === 'memo_to' || value.status === 'sold_to') && this.state.bulkOptions.status != value.status) {
					const watchStatus = find(collections.inventoryStatusesCollection, {value: value.status})
					this.props.addToast({
						title: 'Inventory Status',
						text: `Item #${value.sku} is ${watchStatus.option}. Changing the status will remove this item from the memo/invoice. Are you sure you want to proceed?`,
						type: 'warning',
					})
				}
			})
			checkedRows = checkedRows.map(item => {
				if(value === 'on_hand') {
					item.status_details = ''
				}
				item[key] = value
				return item
			})
		} else {
			bulkOptions['visible_in_marketplace'] = value === 'none' ? null : value
			checkedRows = checkedRows.map(item => {
				item[key] = value === 'yes' ? true : false
				return item
			})
		}

		this.setState({
			checkedRows: checkedRows,
			showRows: checkedRows.slice(0, this.state.numberOfItems * this.state.offset),
		})
	}

	handleStatusDetailsChange = debounce($event => {
		let checkedRows = this.state.checkedRows
		checkedRows = checkedRows.map(item => {
			item.status_details = $event.target.value
			return item
		})

		const bulkOptions = this.state.bulkOptions
		bulkOptions.status_details = $event.target.value
		this.setState({
			checkedRows,
			bulkOptions,
		})
	}, 500)

	handleSalePriceChange = debounce($event => {
		let checkedRows = this.state.checkedRows
		checkedRows = checkedRows.map(item => {
			item.sold_price = $event.target.value
			return item
		})

		const bulkOptions = this.state.bulkOptions
		bulkOptions.sold_price = $event.target.value
		this.setState({
			checkedRows,
			bulkOptions,
		})
	}, 500)

	handleSingleItemPrice = (product, $event) => {
		let checkedRows = this.state.checkedRows
		checkedRows = checkedRows.map(item => {
			if (item.id === product.id) item.sold_price = $event.target.value
			return item
		})
		this.setState({
			checkedRows,
		})
	}

	handleCloseBulkEditor = () => {
		if (isFunction(this.props.closeEditor)) this.props.closeEditor()
		this.setState({checkedRows: []})
	}

	handleSaveEdits = $event => {
		$event.preventDefault()

		if (this.state.sendingData) return
		if (isFunction(this.props.handlePrimaryTab)) this.props.handlePrimaryTab()

		const checkedRows = this.state.checkedRows
		const targets = checkedRows.map(item => item.id)
		const targetsPrices = checkedRows.map(item => {
			return {id: item.id, sold_price: item.sold_price, sold_date: moment().format('YYYY-MM-DD')}
		})

		this.setState({sendingData: true})
		let errors = []
		if (this.state.bulkOptions.status?.toLowerCase() === 'sold_to') {
			checkedRows.map((value, index) => {
				if(!value.sold_price) {
					errors = {...errors, sold_price: 'Sold Price is required'}
				}
			})
		} else {
			this.state.bulkOptions.sold_price = null
		}

		if (!!this.state.bulkOptions.status && this.state.bulkOptions.status?.toLowerCase() !== 'on_hand' && this.state.bulkOptions.status?.toLowerCase() !== 'other') {
			if (isEmpty(this.state.bulkOptions.status_details)) {
				errors = {...errors, status_details: 'Name is required'}
			}
		}
		this.setState({formErrors: errors})

		const options = {...this.state.bulkOptions}
		const keysBukOption = Object.keys(options)
		keysBukOption.map(key => {
			if(options[key] === null) {
				delete options[key]
			}
		})

		const data = {
			targets,
			bulk_options: options,
		}

		if(options?.status === 'sold_to') {
			data.targets_prices = targetsPrices
		}

		if(errors.length === 0) {
			Globals.New_Axios()
				.post(`${Globals.NEW_API_URL}/${Globals.ENDPOINTVERSION}/spaces/${Globals.SELECTEDSPACEID}/watches/bulk_edit`, data)
				.then(async response => {
					this.setState({sendingData: false})
					if (isFunction(this.props.doneEditor)) {
						this.props.doneEditor(response.data.watches)

						this.setState({checkedRows: []})
					}
				})
				.catch(error => {
					this.setState({sendingData: false})
					this.props.addToast({
						text: error.response.data.message,
						type: 'error',
					})
				})
		}
		this.setState({sendingData: false})
	}

	inventoryStatusesCollection = () => {
		let inventoryStatusesCollection = [...collections.inventoryStatusesCollection];
		if(this.props?.profile.role === "editor"){
			remove(inventoryStatusesCollection, (status) => status.value === "sold_to");
		}
		return inventoryStatusesCollection;
	}


	render() {
		const state = this.state
		const { isFetchingAllWatchesData } = this.props
		const displayError = (key) => {
			if (!isEmpty(state.formErrors[key])) return <div className="my-2 rounded bg-red-200 p-1 px-4 font-medium text-red-700">{state.formErrors[key]}</div>
		}

		return (
			isFetchingAllWatchesData  ?
				<div className="absolute top-0 left-0 right-0 bottom-0 flex justify-center items-center z-50">
					<button
						type="button"
						className="inline-flex items-center px-4 py-2 leading-6 text-sm shadow rounded-md text-white bg-blue transition ease-in-out duration-150 cursor-not-allowed"
						disabled
					>
						<svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
							{' '}
							<circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4" />
							<path
								className="opacity-75"
								fill="currentColor"
								d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
							/>
						</svg>
						<span>Loading... </span>
					</button>
				</div> :
				<div className="fixed top-0 bottom-0 left-0 right-0 z-50 bg-white">
					<div className="flex flex-col h-full justify-between max-h-full">
						<div className="flex gap-10 justify-between relative w-full border-b">
							<div className="flex justify-center items-center">
								<div className="border-r px-5 py-3">
									<button onClick={this.handleCloseBulkEditor} className="text-gray-500">
										<svg className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
											<path
												clipRule="evenodd"
												fillRule="evenodd"
												d="M3 4.25A2.25 2.25 0 015.25 2h5.5A2.25 2.25 0 0113 4.25v2a.75.75 0 01-1.5 0v-2a.75.75 0 00-.75-.75h-5.5a.75.75 0 00-.75.75v11.5c0 .414.336.75.75.75h5.5a.75.75 0 00.75-.75v-2a.75.75 0 011.5 0v2A2.25 2.25 0 0110.75 18h-5.5A2.25 2.25 0 013 15.75V4.25z"
											/>
											<path
												clipRule="evenodd"
												fillRule="evenodd"
												d="M19 10a.75.75 0 00-.75-.75H8.704l1.048-.943a.75.75 0 10-1.004-1.114l-2.5 2.25a.75.75 0 000 1.114l2.5 2.25a.75.75 0 101.004-1.114l-1.048-.943h9.546A.75.75 0 0019 10z"
											/>
										</svg>
									</button>
								</div>
								<div className="px-5 py-3 font-medium">Editing {state.checkedRows.length} items</div>
							</div>
							<div className="flex gap-10">
								<div className="px-5 py-3">
									<button
										onClick={this.handleSaveEdits}
										className="bg-blue-500 font-medium px-5 py-2.5 rounded text-sm text-white hover:bg-blue-600 transition-all"
										disabled={isEmpty(Object.values(state.bulkOptions).filter(t => t))}
									>
										Save
									</button>
								</div>
							</div>
						</div>
						<div className="relative  max-h-full h-full">
							<div
								ref={InventoryTable => {
									this.InventoryTable = InventoryTable
								}}
								className="absolute top-0 left-0 w-full h-full bg-gray-100 overflow-y-scroll"
								onScroll={this.loadMoreData}>
								<div className="p-3 rounded w-full">
									<table className="bg-white rounded-2xl text-gray-500 text-left text-sm w-full">
										<thead className="divide-gray-100 divide-y">
											<tr className="divide-gray-100 divide-x text-gray-500">
												<th className="px-6 py-4 align-top font-semibold whitespace-nowrap capitalize">
													<div className="text-xs mb-1.5">Dealer SKU</div>
												</th>
												<th className="px-6 py-4 align-top font-semibold whitespace-nowrap capitalize" style={{maxWidth: 130}}>
													<div className="text-xs mb-1.5">Reference Number</div>
												</th>
												<th className="px-6 py-4 align-top font-semibold whitespace-nowrap capitalize">
													<div className="text-xs mb-1.5">Status</div>
													<div className="relative">
														<button
															onClick={this.handleToggleDropdown.bind(this, 'status')}
															className="capitalize bg-blue-700 focus:outline-none focus:ring-4 focus:ring-blue-300 font-medium hover:bg-blue-800 inline-flex items-center px-3.5 py-1.5 rounded-md text-center text-sm text-white"
														>
															<span>
																<span>{isNull(state.bulkOptions.status) ? 'None' : find(collections.inventoryStatusesCollection, {value:  state.bulkOptions.status})?.option}</span>
															</span>
															<svg className="w-4 h-4 ml-2" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
																<path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 9l-7 7-7-7" />
															</svg>
														</button>
														{state.activeDropDown === 'status' && (
															<React.Fragment>
																<div className="absolute bg-white divide-gray-100 divide-y rounded-lg shadow top-10 w-44 z-50">
																	<ul className="divide-y text-gray-700 text-sm">
																		{this.inventoryStatusesCollection().map((item, index) => (
																			<li
																				className="cursor-pointer block px-4 py-3 hover:bg-gray-100"
																				key={index}
																				onClick={this.handleOptionChange.bind(this, item.value, 'status')}
																			>
																				{item.option}
																			</li>
																		))}
																	</ul>
																</div>
																<div
																	className="fixed top-0 left-0 right-0 bottom-0 bg-transparent z-40"
																	onClick={$event =>
																		this.setState({
																			activeDropDown: null,
																		})
																	}
																></div>
															</React.Fragment>
														)}
													</div>
												</th>
												<th className="px-6 py-4 align-top font-semibold whitespace-nowrap">
													<div className="text-xs mb-1.5">Status Details</div>
													{!['on_hand', null].includes(state.bulkOptions.status) && (
														<div className="relative">
															<input
																onChange={this.handleStatusDetailsChange}
																type="text"
																placeholder="Status Details"
																className="border border-blue-700 focus:outline-none focus:ring-4 focus:ring-blue-300 font-medium px-3.5 py-1.5 rounded-md text-sm"
															/>
															{displayError('status_details')}
														</div>
													)}
												</th>
												<th className={Classnames("px-6 py-4 align-top font-semibold whitespace-nowrap", {'hidden': !['sold_to'].includes(state.bulkOptions.status) })}>
												{['sold_to'].includes(state.bulkOptions.status) && <div className="text-xs mb-1.5">Sold Price</div>}
													{['sold_to'].includes(state.bulkOptions.status) && (
														<div className="relative">
															<div>
																{['sold_to'].includes(state.bulkOptions.status) && (
																	<input
																		onChange={this.handleSalePriceChange}
																		type="text"
																		placeholder="Sold Price"
																		className="ml-1.5 w-1/3 border border-blue-700 focus:outline-none focus:ring-4 focus:ring-blue-300 font-medium px-3.5 py-1.5 rounded-md text-sm"
																	/>
																)}
															</div>
														</div>
													)}
												</th>
												<th className="px-6 py-4 align-top font-semibold whitespace-nowrap capitalize">
													<div className="text-xs mb-1.5">Visible In Marketplace</div>
													<div className="relative">
														<button
															onClick={this.handleToggleDropdown.bind(this, 'visible_in_marketplace')}
															className="capitalize bg-blue-700 focus:outline-none focus:ring-4 focus:ring-blue-300 font-medium hover:bg-blue-800 inline-flex items-center px-3.5 py-1.5 rounded-md text-center text-sm text-white"
														>
															<span>
																<span>{isNull(state.bulkOptions.visible_in_marketplace) ? 'None' : state.bulkOptions.visible_in_marketplace}</span>
															</span>
															<svg className="w-4 h-4 ml-2" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
																<path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 9l-7 7-7-7" />
															</svg>
														</button>
														{state.activeDropDown === 'visible_in_marketplace' && (
															<React.Fragment>
																<div className="absolute bg-white divide-gray-100 divide-y rounded-lg shadow top-10 w-44 z-50">
																	<ul className="divide-y text-gray-700 text-sm">
																		{['yes', 'no'].map((item, index) => (
																			<li
																				className="cursor-pointer block px-4 py-3 hover:bg-gray-100"
																				key={index}
																				onClick={this.handleOptionChange.bind(this, item, 'visible_in_marketplace')}
																			>
																				{item}
																			</li>
																		))}
																	</ul>
																</div>
																<div
																	className="fixed top-0 left-0 right-0 bottom-0 bg-transparent z-40"
																	onClick={$event =>
																		this.setState({
																			activeDropDown: null,
																		})
																	}
																></div>
															</React.Fragment>
														)}
													</div>
												</th>
											</tr>
										</thead>
										<tbody className="divide-gray-100 divide-y">
											{state.showRows.map((item, index) => {
												const watchStatus = find(collections.inventoryStatusesCollection, {value: item?.status})
												return (
													<tr
														key={index}
														className={Classnames('divide-gray-100 divide-x text-gray-500', {
															'bg-gray-50': index % 2 === 0,
															'bg-white': index % 2 !== 0,
														})}
													>
														<td className="px-6 py-4 font-medium break-words whitespace-pre-wrap max-w-[200px]">
															<span className="line-clamp-4">
																{item.sku}
															</span>
														</td>
														<td className="px-6 py-4 font-medium whitespace-nowrap">{item.reference_number}</td>
														<td className="px-6 py-4 font-medium whitespace-nowrap">{watchStatus?.option}</td>
														<td className="px-6 py-4 font-medium whitespace-nowrap">
															{item?.status === 'on_hand' ? '' : item.status_details}
														</td>
														<td className={Classnames("px-6 py-4 font-medium whitespace-nowrap", {'hidden': item.status !== 'sold_to'})}>
															{item.status === 'sold_to' &&
																(() => {
																	return (
																		<div>
																			<input
																				onChange={this.handleSingleItemPrice.bind(this, item)}
																				value={item.sold_price}
																				type="text"
																				placeholder="Sold Price"
																				className="ml-1.5 w-1/3 border border-blue-700 focus:outline-none focus:ring-4 focus:ring-blue-300 font-medium px-3.5 py-1.5 rounded-md text-sm"
																			/>
																			{!item.sold_price && displayError('sold_price')}
																		</div>

																	)
																})()}
														</td>
														<td className="px-6 py-4 font-medium whitespace-nowrap">{item.visible_in_marketplace ? 'Yes' : 'No'}</td>
													</tr>
												)
											})}
										</tbody>
									</table>
								</div>
							</div>
						</div>
					</div>
					{state.sendingData && (
						<div className="bottom-0 fixed flex items-center justify-center left-0 right-0 top-0 z-50">
							<svg className="animate-spin fill-blue-600 flex h-11 mr-2 text-gray-200 w-11" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
								<path
									fill="currentColor"
									d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
								/>
								<path
									fill="currentFill"
									d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
								/>
							</svg>
						</div>
					)}
				</div>
		)
	}
}

const mapStateToProps = (state, props) => {
	return {
		profile: state.profile,
	}
}

const mapActionsToProps = {
	addToast,
}

export default connect(mapStateToProps, mapActionsToProps)(BulkEditor)
