import React, { useEffect, useMemo, useState } from 'react';
import { bindActionCreators } from 'redux'
import {connect} from 'react-redux'
import Box from '@mui/material/Box';
import Slider from '@mui/material/Slider';

import {
	setMarketplaceFilters,
} from '../../app/appActions'
import debounce from 'lodash/debounce'

const PriceSlider = ({onChangeFilterData, minPrice, maxPrice}) => {
  const [value, setValue] = useState([minPrice, maxPrice]);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleSearchDebounce = useMemo(() => {
    return debounce((prices) => {
      onChangeFilterData('wholesale_price', prices);
    }, 400);
  }, []);

  const handleChangeInput = (event, type) => {
    let prices = value
    const data = Number(event.target.value) === 0 ? '' : Number(event.target.value)
    if(type === 'min') {
      prices = [data, value[1]]
    } else {
      prices = [value[0], data]
    }
    setValue(prices)
    handleSearchDebounce(prices)
  };


  useEffect(() => {
    setValue([minPrice, maxPrice])
  }, [minPrice, maxPrice])

  const valuetext = (value) => {
    return `${value}`;
  };

  const handleSetMarketplaceFilters = () => {
    onChangeFilterData('wholesale_price', value)
  }

  const handleChangeCommitted = (event, newValue) => {
    handleSetMarketplaceFilters()
  };

  return (
    <div className="flex flex-col items-center">
      <h2 className="text-2xl font-semibold">Price</h2>
      <Box sx={{ width: '50%' }}>
        <Slider
          getAriaLabel={() => 'Temperature range'}
          value={value}
          min={minPrice}
          max={maxPrice}
          getAriaValueText={valuetext}
          onChangeCommitted={handleChangeCommitted}
          onChange={handleChange}
          sx={{
            '& .MuiSlider-thumb': {
              width: 16,
              height: 16,
            },
          }}
        />
        <div className="flex justify-between items-center m-2">
          <div className="flex items-center">
            <span className="mr-2">$</span>
            <input
              type="number"
              className="w-20 border border-gray-300 rounded-md text-center"
              value={value[0]}
              disabled={value[0] === undefined}
              onChange={(event) => handleChangeInput(event, 'min')}
            />
          </div>
          <div className="flex items-center">
            <span className="mr-2">$</span>
            <input
              type="number"
              className="w-20 border border-gray-300 rounded-md text-center"
              value={value[1]}
              disabled={value[1] === undefined}
              onChange={(event) => handleChangeInput(event, 'max')}
            />
          </div>
        </div>
      </Box>
    </div>
  );
};


const mapStateToProps = (state, props) => {
	return {
		marketplaceFilters: state.marketplaceFilters,
	}
}

const mapDispatchToProps = dispatch => {
	return bindActionCreators({
		setMarketplaceFilters,
	}, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(PriceSlider)

