import {Globals} from '../../Context'
import {addToast} from './../../app/appActions'
import Privacy from './Privacy'
import Title from './Title'
import {useState, useEffect} from 'react'
import {connect} from 'react-redux'
import UploadMedia from '../UploadMedia'
import { setCurrentSpace } from '../../app/appActions'
import isEmpty from 'lodash/isEmpty'

const Organization = props => {
	const { profile, currentSpace } = props;

	const [loading, setLoading] = useState(false),
		[formData, updateFormData] = useState({}),
		[formErrors, updateFormErrors] = useState({}),
		[loadingUploadImage, setLoadingUploadImage] = useState(false)

	const handleChange = e => {
		updateFormData({
			...formData,
			[e.target.name]: e.target.value.trim(),
		})
	}
	const handleSubmit = _event => {
		_event.preventDefault()
		if (loading) return

		let errors = {}

		//Make sure all the data is being correct.
		if (isEmpty(formData.company_name)) errors = {...errors, company_name: 'Company name is required'}
		if (isEmpty(formData.company_address)) errors = {...errors, company_address: 'Company address is required'}
		if (isEmpty(formData.city)) errors = {...errors, city: 'Company city is required'}
		if (isEmpty(formData.state)) errors = {...errors, state: 'Company state is required'}
		if (isEmpty(formData.zip_code)) errors = {...errors, zip_code: 'Company zip code is required'}
		if (isEmpty(formData.country)) errors = {...errors, country: 'Company country is required'}
		if (isEmpty(formData.phone)) errors = {...errors, phone: 'Company phone is required'}
		if (loadingUploadImage) return props.addToast({
			title: 'Space details',
			text: 'Image is being uploaded',
			type: 'error',
		})

		updateFormErrors(errors)
		if (!isEmpty(errors)) return

		setLoading(true)
		const formDataPost = new FormData()
		formDataPost.append('space[name]', formData.company_name)
		formDataPost.append('space[street]', formData.company_address)
		formDataPost.append('space[apt_unit_number]', formData.apt_unit_number)
		formDataPost.append('space[city]', formData.city)
		formDataPost.append('space[state]', formData.state)
		formDataPost.append('space[country]', formData.country)
		formDataPost.append('space[zip_code]', formData.zip_code)
		formDataPost.append('space[phone_number]', formData.phone)
		formDataPost.append('space[bank_name]', formData.bankName)
		formDataPost.append('space[bank_city_state_zip]', formData.bankAddress2)
		formDataPost.append('space[bank_address]', formData.bankAddress)
		formDataPost.append('space[account_name]', formData.accountName)
		formDataPost.append('space[account_routing_number]', formData.routingNumber)
		formDataPost.append('space[account_number]', formData.accountNumber)
		formDataPost.append('space[resale_certificate]', formData.certificate)
		formDataPost.append('space[logo_id]', formData.logo_id)
		formDataPost.append('space[email]', formData.email)

		return Globals.New_Axios()
			.patch(`${Globals.NEW_API_URL}/${Globals.ENDPOINTVERSION}/spaces/${Globals.SELECTEDSPACEID}`, formDataPost)
			.then(async response => {
				setLoading(false)
				props.setCurrentSpace(response.data);
				updateFormData({
					phone: response.data.phone_number_formatted
				})

				props.addToast({
					title: 'Space details',
					text: 'Your space details has been updated successfully',
					type: 'success',
				})

				return updateFormErrors({})
			})
			.catch(error => {
				setLoading(false)
				if(error.response?.data?.message === 'Phone number is invalid') {
					errors = {
						...errors,
						phone: error.response?.data?.message || 'Something went wrong please try again later.',
					}
				} else {
					errors = {
						...errors,
						error: error.response?.data?.message || 'Something went wrong please try again later.',
					}
					console.log(errors, 'errors')
				}

				updateFormErrors(errors)
			})
	}

	useEffect(() => {
		getSpaceDetail()
	}, [])

	useEffect(() => {
		updateFormData({
			company_name: currentSpace.name || "",
			company_address: currentSpace.street || "",
			apt_unit_number: currentSpace.apt_unit_number || "",
			city: currentSpace.city || "",
			state: currentSpace.state || "",
			country: currentSpace.country || "",
			zip_code: currentSpace.zip_code || "",
			phone: currentSpace.phone_number_formatted || "",
			certificate: currentSpace.resale_certificate || "",
			bankName: currentSpace.bank_name || "",
			bankAddress: currentSpace.bank_address || "",
			bankAddress2: currentSpace.bank_city_state_zip || "",
			accountName: currentSpace.account_name || "",
			routingNumber: currentSpace.account_routing_number || "",
			accountNumber: currentSpace.account_number || "",
			logo: currentSpace.logo,
			logo_id: currentSpace.logo.id || "",
			email: currentSpace.email || "",
		})
	}, [currentSpace])

	const handleLoadingUploadImage = (visible) => {
		setLoadingUploadImage(visible)
	}

	const getSpaceDetail = () => {
		let errors= {}
		return Globals.New_Axios()
			.get(`${Globals.NEW_API_URL}/${Globals.ENDPOINTVERSION}/spaces/${Globals.SELECTEDSPACEID}`)
			.then(response => {
				props.setCurrentSpace(response.data);
				updateFormErrors({})
			})
			.catch(error => {
				errors = {
					...errors,
					error: 'Something went wrong please try again later.',
				}
				updateFormErrors(errors)
			})
	}

	const displayError = key => {
		if (!isEmpty(formErrors[key])) return <div className="inline-block text-sm my-2 rounded bg-red-200 p-1 px-4 font-medium text-red-700">{formErrors[key]}</div>
	}

	const uploadImages = pictures => {
		const lastPicture = pictures[pictures.length - 1]?.post_id

		updateFormData({
			...formData,
			logo_id: lastPicture?.id || "",
		})
	}

	return (
		<div className="relative pb-24">
			<Title title="Organization" />
			<p className="px-5 pb-5 text-xs font-medium leading-5 lg:text-sm">
				Enter your organization’s contact information below. We use this information in the footer of your emails to comply with the CAN-SPAM act.
			</p>
			<div className="px-5">
				<form className="flex flex-col" onSubmit={handleSubmit}>
					<div className="mt-8">
						<h2>
							<strong>Company Details</strong>
						</h2>
						<div className="grid gap-2 sm:grid-cols-1 lg:grid-cols-2">
							<div className="mt-2 col-span-2">
								<label htmlFor="company_name" className="hidden text-base bg-transparent font-normal py-2">
									Company Name*
								</label>
								<div className="outlin relative w-full">
									<input
										defaultValue={formData?.company_name}
										type="text"
										name="company_name"
										placeholder=" "
										className="block px-4 pt-5 pb-3 w-full inputLogin appearance-none outline-none bg-transparent text-black text-sm font-medium"
										onChange={handleChange}
									/>
									<label htmlFor="company_name" className="pointer-events-none absolute top-1 text-sm bg-transparent p-4 duration-300 origin-0 opacity-60">
										Company name
									</label>
								</div>
								{displayError('company_name')}
							</div>
							<div className="mt-2">
								<label htmlFor="company address" className="hidden text-base bg-transparent font-normal py-2">
									Company Address*
								</label>
								<div className="outlin relative w-full">
									<input
										defaultValue={formData?.company_address}
										type="text"
										name="company_address"
										role="presentation"
										autocomplete="off"
										placeholder=" "
										className="block px-4 pt-5 pb-3 w-full inputLogin appearance-none outline-none bg-transparent text-black text-sm font-medium"
										onChange={handleChange}
									/>
									<label htmlFor="company address" className="pointer-events-none absolute top-1  text-sm bg-transparent p-4 duration-300 origin-0 opacity-60">
										Company Address
									</label>
								</div>
								{displayError('company_address')}
							</div>
							<div className="mt-2">
								<label htmlFor="apt_unit_number" className="hidden text-base bg-transparent font-normal py-2">
									Apt/Unit #
								</label>
								<div className="outlin relative w-full">
									<input
										defaultValue={formData?.apt_unit_number}
										type="text"
										name="apt_unit_number"
										role="presentation"
										autocomplete="off"
										placeholder=" "
										className="block px-4 pt-5 pb-3 w-full inputLogin appearance-none outline-none bg-transparent text-black text-sm font-medium"
										onChange={handleChange}
									/>
									<label htmlFor="apt_unit_number" className="pointer-events-none absolute top-1  text-sm bg-transparent p-4 duration-300 origin-0 opacity-60">
										Apt/Unit #
									</label>
								</div>
								{displayError('apt_unit_number')}
							</div>
							<div className="mt-2">
								<label htmlFor="city" className="hidden text-base bg-transparent font-normal py-2">
									City*
								</label>
								<div className="outlin relative w-full">
									<input
										defaultValue={formData?.city}
										type="text"
										name="city"
										placeholder=" "
										className="block px-4 pt-5 pb-3 w-full inputLogin appearance-none outline-none bg-transparent text-black text-sm font-medium"
										onChange={handleChange}
									/>
									<label htmlFor="city" className="pointer-events-none absolute top-1 text-sm bg-transparent p-4 duration-300 origin-0 opacity-60">
										City*
									</label>
								</div>
								{displayError('city')}
							</div>
							<div className="mt-2">
								<label htmlFor="state" className="hidden text-base bg-transparent font-normal py-2">
									State*
								</label>
								<div className="outlin relative w-full">
									<input
										defaultValue={formData?.state}
										type="text"
										name="state"
										placeholder=" "
										className="block px-4 pt-5 pb-3 w-full inputLogin appearance-none outline-none bg-transparent text-black text-sm font-medium"
										onChange={handleChange}
									/>
									<label htmlFor="state" className="pointer-events-none absolute top-1 text-sm bg-transparent p-4 duration-300 origin-0 opacity-60">
										State*
									</label>
								</div>
								{displayError('state')}
							</div>
							<div className="mt-2">
								<label htmlFor="country" className="hidden text-base bg-transparent font-normal py-2">
									Choose a country*
								</label>
								<div className="outlin relative w-full">
									<select
										defaultValue={formData?.country}
										id="country"
										name="country"
										className="block px-4 pt-5 pb-3 w-full inputLogin appearance-none outline-none bg-transparent text-black text-sm font-medium"
										onChange={handleChange}
									>
										<option value="" disabled>
											Choose a country*
										</option>
										{Globals.countries.map(c => (
											<option key={c.code} value={c.code}>
												{c.name}
											</option>
										))}
									</select>
								</div>

								{displayError('country')}
							</div>
							<div className="mt-2">
								<label htmlFor="zip_code" className="hidden text-base bg-transparent font-normal py-2">
									Zip Code*
								</label>
								<div className="outlin relative w-full">
									<input
										defaultValue={formData?.zip_code}
										type="text"
										name="zip_code"
										placeholder=" "
										className="block px-4 pt-5 pb-3 w-full inputLogin appearance-none outline-none bg-transparent text-black text-sm font-medium"
										onChange={handleChange}
									/>
									<label htmlFor="zip_code" className="pointer-events-none absolute top-1 text-sm bg-transparent p-4 duration-300 origin-0 opacity-60">
										Zip Code*
									</label>
								</div>
								{displayError('zip_code')}
							</div>
							<div className="mt-2">
								<label htmlFor="phone" className="hidden text-base bg-transparent font-normal py-2">
									Phone Number*
								</label>
								<div className="outlin relative w-full">
									<input
										defaultValue={formData?.phone}
										value={formData?.phone}
										type="tel"
										name="phone"
										placeholder=" "
										className="block px-4 pt-5 pb-3 w-full inputLogin appearance-none outline-none bg-transparent text-black text-sm font-medium"
										onChange={handleChange}
									/>
									<label htmlFor="phone" className="pointer-events-none absolute top-1 text-sm bg-transparent p-4 duration-300 origin-0 opacity-60">
										Phone Number*
									</label>
								</div>
								{displayError('phone')}
							</div>
							<div className="mt-2">
								<label htmlFor="Resale Certificate*" className="hidden text-base bg-transparent font-normal py-2">
									Resale Certificate
								</label>
								<div className="outlin relative w-full">
									<input
										value={formData?.certificate}
										type="text"
										name="certificate"
										placeholder=" "
										className="block px-4 pt-5 pb-3 w-full inputLogin appearance-none outline-none bg-transparent text-black text-sm font-medium"
										onChange={handleChange}
									/>
									<label htmlFor="certificate" className="pointer-events-none absolute top-1 text-sm bg-transparent p-4 duration-300 origin-0 opacity-60">
										Resale Certificate
									</label>
								</div>
								{displayError('certificate')}
							</div>

							{(profile?.role === 'admin' || profile?.role === 'owner') && (
								<>
									<div className="mt-2">
										<label htmlFor="Resale Certificate*" className="hidden text-base bg-transparent font-normal py-2">
											Company Email
										</label>
										<div className="outlin relative w-full">
											<input
												value={formData?.email}
												type="text"
												name="email"
												placeholder=" "
												className="block px-4 pt-5 pb-3 w-full inputLogin appearance-none outline-none bg-transparent text-black text-sm font-medium"
												onChange={handleChange}
											/>
											<label htmlFor="certificate" className="pointer-events-none absolute top-1 text-sm bg-transparent p-4 duration-300 origin-0 opacity-60">
												Company Email
											</label>
										</div>
										{displayError('certificate')}
									</div>
									<div className="mt-2"></div>
								</>
							)}
							<div className="mt-2">
								<label htmlFor="Resale Certificate*" className=" text-base bg-transparent font-normal py-2">
									Logo
								</label>
								<div className="outlin relative w-full">
									{!formData?.logo?.id && (
										<UploadMedia
											innerClassNames="gap-5 grid grid-cols-2 my-5 upload-media-container"
											text="Logo"
											uploadTo="uploadlogo"
											maximum={1}
											uploadUpdate={uploadImages}
											allowedTypes={['image/jpeg', 'image/jpg', 'image/png']}
											multipe={false}
											handleLoadingUploadImage={handleLoadingUploadImage}
										/>
									)}
									{formData?.logo?.id && (
										<UploadMedia
											innerClassNames="gap-5 grid grid-cols-2 my-5 upload-media-container"
											text="Upload"
											maximum={1}
											uploadUpdate={uploadImages}
											uploadTo="uploadlogo"
											defaultValue={[formData.logo]}
											allowedTypes={['image/jpeg', 'image/jpg', 'image/png']}
											multipe={false}
											handleLoadingUploadImage={handleLoadingUploadImage}
										/>
									)}
								</div>
							</div>
						</div>

					</div>
					<div className="mt-4 text-center">{displayError('error')}</div>

					<button className="bg-blue-500 cursor-pointer font-medium mt-4 p-4 rounded text-white uppercase w-fit text-sm" type="submit">
						Update Details
					</button>
				</form>
			</div>

			<Privacy />
		</div>
	)
}

const mapStateToProps = (state, props) => {
	return {
		profile: state.profile,
		currentSpace: state.currentSpace,
	}
}

const mapActionsToProps = {
	addToast,
	setCurrentSpace
}

export default connect(mapStateToProps, mapActionsToProps)(Organization)
