const CardWatch = ({image, refNumber, quantity}) => {

	return (
		<div
			className="flex flex-col justify-center items-center font-medium relative"
			style={{fontSize: '10px'}}
		>
			<img src={image} alt="" style={{height: '104px'}} />
			<div className="flex flex-col justify-center items-center">
				<h1>Rolex</h1>
				<h1 className="font-normal" style={{color: '#C5CBD2'}}>
					{quantity} Qty
				</h1>
				<h1>{refNumber}</h1>
			</div>
		</div>
	)
}

export default CardWatch
