import React, {useState, useCallback, useEffect} from 'react'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import { useDispatch } from 'react-redux'

import { addToast } from '../../app/appActions'
import CustomListOptions from './CustomListOptions'
import {focusElement, onPressEscape} from '../../utils/keyboardHandler'
import {Globals} from '../../Context'
import isObject from 'lodash/isObject'
import isEmpty from 'lodash/isEmpty'
import toLower from 'lodash/toLower'
import isUndefined from 'lodash/isUndefined'

const columnTypes = [
	{value: 'text', label: 'Text'},
	{value: 'float', label: 'Number'},
	{value: 'date', label: 'Date'},
	{value: 'boolean', label: 'Checkbox'},
	{value: 'list', label: 'Dropdown'},
]

const AddCustomColumn = ({kind, handleCloseCustomColumn, setColumnAdded=()=>{}, dataList, getColumList, getColumListInv}) => {
	const dispatch = useDispatch()
	const [formErrors, setFormErrors] = useState([])
	const [openListOptions, setOpenListOptions] = useState(false)
	const [newColumn, setNewColumn] = useState({
		NewColumnKey: '',
		NewColumnLabel: '',
		NewColumnType: ''
	})
	const [options, setOptions] = useState([])

	const handleSaveAddColumns = () => {
		let errors = {}
		const _dataLists = !isObject(dataList) ? JSON.parse(dataList) : dataList

		if (newColumn.NewColumnType === "list" && options.length === 0) {
			errors = {...errors, newColType: 'Need at least one options to continue'}
		}

		if (isEmpty(newColumn.NewColumnKey)) errors = {...errors, newColKey: 'Key is required'}
		if (isEmpty(newColumn.NewColumnLabel)) errors = {...errors, newColLabel: 'Label is required'}
		if (isEmpty(newColumn.NewColumnType)) errors = {...errors, newColType: 'Type is required'}
		if (!isEmpty(errors)) return setFormErrors({...errors})
		if (!isEmpty(_dataLists.filter(_d => toLower(_d.key) === toLower(newColumn.NewColumnKey))))
			errors = {
				...errors,
				newColExist: `There is a column with the key ${newColumn.NewColumnKey} already exists`,
			}
		if (!isEmpty(_dataLists.filter(_d => toLower(_d.label) === toLower(newColumn.NewColumnLabel))))
			errors = {
				...errors,
				newColLabel: `There is a column with the label ${newColumn.NewColumnLabel} already exists`,
			}
		if (!isEmpty(errors)) return setFormErrors({...errors})





		const body = newColumn.NewColumnType === "list" ?
		{custom_column: {key: newColumn.NewColumnKey, label: newColumn.NewColumnLabel, kind: kind, field_type: newColumn.NewColumnType, list_options: options}} :
		{custom_column: {key: newColumn.NewColumnKey, label: newColumn.NewColumnLabel, kind: kind, field_type: newColumn.NewColumnType}}


		Globals.New_Axios()
			.post(`${Globals.NEW_API_URL}/${Globals.ENDPOINTVERSION}/spaces/${Globals.SELECTEDSPACEID}/custom_columns`, body)
			.then(async response => {
				if (response.status && response.status >= 200 && response.status < 300) {
					getColumList()
					setColumnAdded({
						db_column: response.data.key,
						label: response.data.label,
						map_to_csv: "",
						is_custom_column: true
					})
					handleCloseCustomColumn(false)
					getColumListInv()
				}
			})
			.then(() => {
				dispatch(
					addToast({
							title: 'Column',
							text: 'Column added successfully.',
							type: 'success',
					}))
			})
			.catch(error => console.log(error))
	}

	const displayError = key => {
		if (!isUndefined(formErrors[key])) return <div className="pt-3 text-red-700">{formErrors[key]}</div>
	}

	const dataColKeySuggestion = useCallback((key) => {
        return key.toLowerCase().replace(/[^a-zA-Z0-9]/g, '_');
    }, []);

	const handleDataTypeSuggestion = (key) => {
		setNewColumn(prevState => ({
			...prevState,
			NewColumnLabel: key,
			NewColumnKey: dataColKeySuggestion(key),
		}));
	};

	useEffect(() => {
		focusElement('.add-custom-column')
	}, [])

	return (
		<div
			tabIndex="-1"
			onKeyDown={
				onPressEscape(event => {
					handleCloseCustomColumn(false)
					focusElement('.list-custom-column')
				})
			}
			className="add-custom-column fixed top-0 w-screen h-screen bg-black/50 flex items-center justify-center z-50"
		>
			<div className="bg-white drop-shadow-xl rounded-xl sm:w-1/2 " style={{maxWidth: '500px'}}>
				<div className="w-full borderCardChrono relative px-8 py-4 flex items-center">
					<span className="text-white font-bold text-lg">Add Column</span>
					<button onClick={() => handleCloseCustomColumn(false)} className="absolute right-0 mr-5 cursor-pointer">
						<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path
								d="M14.8672 0.194897C14.625 0.200615 14.3949 0.301995 14.2271 0.47688L8.00026 6.70377L1.77337 0.47688C1.68784 0.38896 1.58554 0.319098 1.47252 0.271431C1.35951 0.223764 1.23807 0.199261 1.11541 0.199373C0.933136 0.199599 0.755062 0.254158 0.60394 0.35608C0.452819 0.458003 0.335512 0.602661 0.267004 0.771577C0.198497 0.940492 0.181898 1.126 0.21933 1.30439C0.256762 1.48278 0.346524 1.64597 0.47715 1.7731L6.70404 7.99999L0.47715 14.2269C0.389174 14.3113 0.318936 14.4125 0.27055 14.5245C0.222163 14.6364 0.196601 14.7569 0.19536 14.8789C0.194119 15.0008 0.217224 15.1218 0.263323 15.2347C0.309421 15.3476 0.377586 15.4502 0.463825 15.5364C0.550064 15.6227 0.652643 15.6908 0.765556 15.7369C0.878469 15.783 0.999445 15.8061 1.1214 15.8049C1.24335 15.8037 1.36383 15.7781 1.47578 15.7297C1.58774 15.6813 1.68891 15.6111 1.77337 15.5231L8.00026 9.29622L14.2271 15.5231C14.3116 15.6111 14.4128 15.6813 14.5247 15.7297C14.6367 15.7781 14.7572 15.8037 14.8791 15.8049C15.0011 15.8061 15.1221 15.783 15.235 15.7369C15.3479 15.6908 15.4505 15.6227 15.5367 15.5364C15.6229 15.4502 15.6911 15.3476 15.7372 15.2347C15.7833 15.1218 15.8064 15.0008 15.8052 14.8789C15.8039 14.7569 15.7784 14.6364 15.73 14.5245C15.6816 14.4125 15.6113 14.3113 15.5234 14.2269L9.29648 7.99999L15.5234 1.7731C15.6568 1.6452 15.7484 1.47997 15.7862 1.29908C15.824 1.11819 15.8063 0.93009 15.7354 0.759448C15.6644 0.588807 15.5436 0.443591 15.3886 0.342846C15.2337 0.2421 15.052 0.19053 14.8672 0.194897Z"
								fill="white"
							/>
						</svg>
					</button>
				</div>

				<div className="flex flex-col h-full p-7">
					<div className="flex flex-col relative my-4">
						<label htmlFor="column_label" className="mb-2 text-base bg-transparent">
							Label
						</label>
						<div className="outlin relative w-full">
							<input
								id="column_label"
								type="text"
								name="column_label"
								placeholder="Column label"
								className="block p-4 w-full inputLogin appearance-none outline-none text-sm font-medium"
								onChange={e => {
									handleDataTypeSuggestion(e.target.value);
								}}
								value={newColumn.NewColumnLabel}
							/>
						</div>
						{displayError('newColLabel')}
					</div>
					<div className="flex flex-col relative my-4">
						<label htmlFor="column_key" className="mb-2 text-base bg-transparent">
							<div>
								<span>Key</span>
							</div>
						</label>
						<div className="outlin relative w-full">
							<input
								id="column_key"
								type="text"
								name="column_key"
								placeholder="Column key"
								className="block p-4 w-full inputLogin appearance-none outline-none text-sm font-medium"
								onChange={e => {
									setNewColumn({...newColumn, NewColumnKey: e.target.value})
								}}
								value={newColumn.NewColumnKey}
							/>
						</div>
						{displayError('newColKey')}
					</div>
					<div className="flex flex-col relative my-4">
						<label htmlFor="column_key" className="mb-2 text-base bg-transparent">
							<div>
								<span>Type</span>
							</div>
							<div>
								{/* <small>(This field must exactly match the column name in your CSV file)</small> */}
							</div>
						</label>
						<div className="outlin relative w-full">
							<FormControl fullWidth>
								<Select
									value={newColumn.NewColumnType}
									displayEmpty
									inputProps={{ 'aria-label': 'Without label' }}
									onChange={e => {
										setNewColumn({...newColumn, NewColumnType: e.target.value})
									}}
								>
									{columnTypes.map((col, index) => (
										<MenuItem key={index} value={col.value}>
											<div className="flex flex-row justify-between">
												<span>{col.label}</span>
											</div>
										</MenuItem>
									))}
								</Select>
							</FormControl>
						</div>
						{newColumn.NewColumnType === "list" ? <button onClick={() => {setOpenListOptions(true)}} className='text-start my-4 underline text-black cursor-pointer'>Dropdown Option(s)</button> : null }
						{displayError('newColType')}
					</div>
					<div className="flex flex-row w-full place-content-end mt-4">{displayError('newColExist')}</div>
					<div className="flex flex-row w-full place-content-end mt-4">
						<button onClick={() => handleCloseCustomColumn(false)} className="py-3 px-4 mt-2 mr-6 rounded font-medium text-sm cursor-pointer border bg-white">
							Cancel
						</button>
						<button onClick={handleSaveAddColumns} type="submit" className="py-3 px-4 mt-2 rounded font-medium text-sm text-white cursor-pointer uppercase bg-blue">
							Save
						</button>
					</div>
				</div>
			</div>
			{openListOptions && <CustomListOptions setOpenListOptions={setOpenListOptions} options={options} setOptions={setOptions} mode="add"/>}
		</div>
	)
}
export default AddCustomColumn
