import Classnames from 'classnames'
import React from 'react'
import { bindActionCreators } from 'redux'
import MarketPlaceView from '../Components/Marketplace/MarketPlaceView'
import ActiveListingsView from '../Components/Marketplace/ActiveListingsView'
import SavedListingsView from '../Components/Marketplace/SavedListingsView'
import SideBar from '../Components/SideBar'
import TopBar from '../Components/TopBar'
import PerformanceLimitAccessModal from '../Components/common/PerformanceLimitAccessModal'
import {connect} from 'react-redux'
import {
	toggleSidebar,
	setMarketplaceFilters,
	setMarketplaceActiveTab,
	setMarketplaceSearchKeywords
} from './../app/appActions'
import AddInventoryComponent from '../Components/Inventory/AddInventoryComponent'
import { Globals } from '../Context'
import {onPressFocusSearchInput} from '../utils/keyboardHandler'
import MarketPlaceHome from '../Components/Marketplace/MarketPlaceHome'
import ConfirmPopUp from '../Components/ConfirmPopUp'
import debounce from 'lodash/debounce'
import cloneDeep from 'lodash/cloneDeep'
import isEqual from 'lodash/isEqual'
import uniqueId from 'lodash/uniqueId'

class Marketplace extends React.Component {
	constructor() {
		super()
		this.child = React.createRef();
		this.searchInputRef = React.createRef()
		this.confirmPopupRef = React.createRef()
		this.state = {
			filterButtonShown: false,
			productCount: 0,
		}
		this.addInventoryRef = React.createRef();
		this.state = {
			openAdd: false,
			openPerformanceLimitAccessModal: false,
			addProductCount: 0,
			homePageIdx: 0,
			disabledInput: false,
			currentDropdownFilter: '',
			isSilerPrice: false,
			isFirstPressEscape: false,
			isCheckedAddInventoryForm: false,
		}
		this.handleChangeFilterData = this.handleChangeFilterData.bind(this)
	}

	componentDidMount() {
		if (!this.props.loggedin) return window.location.replace('/login')
		window.addEventListener('keydown', this.handleKeyDown);
	}

	componentDidUpdate(prevProps) {
		const { marketplaceFilters } = this.props;

		if (!marketplaceFilters.searchKeywords && marketplaceFilters.searchKeywords !== prevProps.marketplaceFilters.searchKeywords) {
			if (this.searchInputRef.current) {
			  this.searchInputRef.current.value = '';
			}
		}
	}

	handleSetCurrentDropdownFilter = (value) => {
		this.setState({currentDropdownFilter: value})
	}

	handleKeyDown = (event) => {
		if(this.state.openAdd && event.key === Globals.keyboardButton.escape) {
			if(this.state.isCheckedAddInventoryForm) {
				this.confirmPopupRef.current.open()
			} else {
				this.setState({openAdd: false})
			}
		} else {
			if(this.state.openAdd) return
			onPressFocusSearchInput(event, this.searchInputRef.current)
		}
	}

	handleFilterButtonShow = () => {
		this.setState({filterButtonShown: !this.state.filterButtonShown})
	}
	handleProductCount = count => {
		this.setState({productCount: count})
	}

	goToViewMarketPlace = (item) => {
		const { setMarketplaceActiveTab } = this.props
		setMarketplaceActiveTab('marketplace')
	}

	handleMarketplaceUploadDone = async (response) => {
		this.setState({openAdd: false})
		this.setState({addProductCount: this.state.addProductCount + 1})
	}

	handleClearAllFilter = () => {
		const { setMarketplaceFilters } = this.props
		setMarketplaceFilters(
			{
				filterDataSelected:
					{
						brand: [],
						reference_number: [],
						series: [],
						condition: [],
						box_papers: [],
						dial: [],
						bracelet: [],
						wholesale_price: [],
					},
				filterPriorities: [],
				specialFilters: {
					type: null,
					title: null,
					watchId: null,
					spaceId: null,
				},
				searchKeywords: '',
				unwornPreOwned: null,
				papers: false,
				box: false,
			}
		)
    }

	componentWillUnmount() {
		const { setMarketplaceFilters } = this.props
		window.removeEventListener('keydown', this.handleKeyDown);
		const newMarketplaceFilters = {
			specialFilters: {
				type: null,
				title: null,
				watchId: null,
				spaceId: null,
			},
			searchKeywords: '',
			activeTab: 'home',
			unwornPreOwned: null,
			papers: false,
			box: false,
		}
		setMarketplaceFilters(newMarketplaceFilters)
	}

	handleSearch = debounce($event => {
		const {
			marketplaceFilters: { activeTab, specialFilters },
			setMarketplaceSearchKeywords,
			setMarketplaceActiveTab
		} = this.props
		if (activeTab !== 'home') {
			this.handleClearAllFilter();
		}

		setMarketplaceSearchKeywords($event.target.value)

		if (activeTab === 'home' && !specialFilters.type) {
			setMarketplaceActiveTab('marketplace')
		}
	}, 500)

	handleCloseAddInventory = () => {
		this.setState({openAdd: false})
	}

	handleDisableInputSearch = (value) => {
		this.setState({disabledInput: value})
	}

	handleCheckAddInventoryForm = (value) => {
		if(this.state.isCheckedAddInventoryForm !== value) {
			this.setState({isCheckedAddInventoryForm: value})
		}
	}

	handlFirstPressEscape = () => {
		this.setState({isFirstPressEscape: false})
	}

	handleChangeFilterData(type, value, isClearWholesalePrice) {
		const { marketplaceFilters: { filterDataSelected, filterPriorities }, setMarketplaceFilters } = this.props;
        const indexOfFilter = filterPriorities.indexOf(type);
        let newFilterPriorities = cloneDeep(filterPriorities);

		if(isClearWholesalePrice) {
			newFilterPriorities = newFilterPriorities.filter(item => item !== 'wholesale_price');
		}

		if (indexOfFilter < 0) {
			newFilterPriorities.push(type);
		} else if (isEqual(filterDataSelected[type], [value])) {
			newFilterPriorities = filterPriorities.filter((filter) => filter !== type);
		}

		setMarketplaceFilters({filterPriorities: newFilterPriorities})

		if (filterDataSelected[type]?.includes(value)) {
			setMarketplaceFilters({filterDataSelected: {
				...filterDataSelected,
				[type]: type === 'wholesale_price' ? [] : filterDataSelected[type].filter(item => item !== value)
			}})
		} else {
			if(type === 'wholesale_price') {
				setMarketplaceFilters(
					{
						filterDataSelected: {
						...filterDataSelected,
						[type]: value
					}
				})
			} else {
				setMarketplaceFilters({filterDataSelected: {
					...filterDataSelected,
					[type]: [...(filterDataSelected[type] || []), value]
				}})
			}
		}
    }

	isPresentFilterDataSelected = () => {
		const { marketplaceFilters: { filterDataSelected } } = this.props;
        return filterDataSelected.brand.length > 0 ||
        filterDataSelected.reference_number.length > 0 ||
        filterDataSelected.dial.length > 0 ||
        filterDataSelected.bracelet.length > 0 ||
        filterDataSelected.condition.length > 0 ||
        filterDataSelected.box_papers.length > 0 ||
		filterDataSelected.wholesale_price.length > 0
    }

	handleOpenAddInventory = () => {
		const { currentSpace } = this.props
		const state = this.state

		if ( currentSpace?.internal_subscription.status === 'active' && currentSpace?.internal_subscription.plan_type === 'performance') {
			this.setState({openPerformanceLimitAccessModal: true})
		} else {
			this.setState({
				openAdd: !state.openAdd,
			})
		}
	}

	render() {
		const state = this.state
		const props = this.props

		const {
			marketplaceFilters,
			setMarketplaceActiveTab,
			setMarketplaceFilters,
			marketplaceFilters: {
				activeTab,
				searchKeywords,
			},
		} = props
		return (
			<React.Fragment>
				{state.openPerformanceLimitAccessModal && (
					<PerformanceLimitAccessModal
						closeModal={() => this.setState({openPerformanceLimitAccessModal: false})}
					/>
				)}

				{state.openAdd && (
					<div ref={this.addInventoryRef} className="bg-black/50 fixed top-0 right-0 left-0 bottom-0 z-50">
						<div className="bg-white rounded-tl-xl absolute top-0 right-0 shadow-xl screen-fit h-full" style={{maxWidth: '850px'}}>
							<div className="border-b flex items-center px-8 py-6 w-full">
								<span className="font-bold text-2xl uppercase">CREATE A MARKETPLACE LISTING</span>

								{
									state.isCheckedAddInventoryForm ?
									<ConfirmPopUp
										ref={this.confirmPopupRef}
										onConfirm={()=> this.setState({openAdd: false})}
										title="Add Inventory"
										content={
											<div>
												Are you sure you want to close this inventory?
											</div>
										}
										isFirstPressEscape={state.isFirstPressEscape}
										handlFirstPressEscape={this.handlFirstPressEscape}
									>
										<button onClick={() => this.setState({openAdd: false})} className="absolute right-0 mr-5 cursor-pointer">
											<svg className="fill-current" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
												<path d="M14.8672 0.194897C14.625 0.200615 14.3949 0.301995 14.2271 0.47688L8.00026 6.70377L1.77337 0.47688C1.68784 0.38896 1.58554 0.319098 1.47252 0.271431C1.35951 0.223764 1.23807 0.199261 1.11541 0.199373C0.933136 0.199599 0.755062 0.254158 0.60394 0.35608C0.452819 0.458003 0.335512 0.602661 0.267004 0.771577C0.198497 0.940492 0.181898 1.126 0.21933 1.30439C0.256762 1.48278 0.346524 1.64597 0.47715 1.7731L6.70404 7.99999L0.47715 14.2269C0.389174 14.3113 0.318936 14.4125 0.27055 14.5245C0.222163 14.6364 0.196601 14.7569 0.19536 14.8789C0.194119 15.0008 0.217224 15.1218 0.263323 15.2347C0.309421 15.3476 0.377586 15.4502 0.463825 15.5364C0.550064 15.6227 0.652643 15.6908 0.765556 15.7369C0.878469 15.783 0.999445 15.8061 1.1214 15.8049C1.24335 15.8037 1.36383 15.7781 1.47578 15.7297C1.58774 15.6813 1.68891 15.6111 1.77337 15.5231L8.00026 9.29622L14.2271 15.5231C14.3116 15.6111 14.4128 15.6813 14.5247 15.7297C14.6367 15.7781 14.7572 15.8037 14.8791 15.8049C15.0011 15.8061 15.1221 15.783 15.235 15.7369C15.3479 15.6908 15.4505 15.6227 15.5367 15.5364C15.6229 15.4502 15.6911 15.3476 15.7372 15.2347C15.7833 15.1218 15.8064 15.0008 15.8052 14.8789C15.8039 14.7569 15.7784 14.6364 15.73 14.5245C15.6816 14.4125 15.6113 14.3113 15.5234 14.2269L9.29648 7.99999L15.5234 1.7731C15.6568 1.6452 15.7484 1.47997 15.7862 1.29908C15.824 1.11819 15.8063 0.93009 15.7354 0.759448C15.6644 0.588807 15.5436 0.443591 15.3886 0.342846C15.2337 0.2421 15.052 0.19053 14.8672 0.194897Z" />
											</svg>
										</button>
									</ConfirmPopUp> :
									<button onClick={() => this.setState({openAdd: false})} className="absolute right-0 mr-5 cursor-pointer">
										<svg className="fill-current" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
											<path d="M14.8672 0.194897C14.625 0.200615 14.3949 0.301995 14.2271 0.47688L8.00026 6.70377L1.77337 0.47688C1.68784 0.38896 1.58554 0.319098 1.47252 0.271431C1.35951 0.223764 1.23807 0.199261 1.11541 0.199373C0.933136 0.199599 0.755062 0.254158 0.60394 0.35608C0.452819 0.458003 0.335512 0.602661 0.267004 0.771577C0.198497 0.940492 0.181898 1.126 0.21933 1.30439C0.256762 1.48278 0.346524 1.64597 0.47715 1.7731L6.70404 7.99999L0.47715 14.2269C0.389174 14.3113 0.318936 14.4125 0.27055 14.5245C0.222163 14.6364 0.196601 14.7569 0.19536 14.8789C0.194119 15.0008 0.217224 15.1218 0.263323 15.2347C0.309421 15.3476 0.377586 15.4502 0.463825 15.5364C0.550064 15.6227 0.652643 15.6908 0.765556 15.7369C0.878469 15.783 0.999445 15.8061 1.1214 15.8049C1.24335 15.8037 1.36383 15.7781 1.47578 15.7297C1.58774 15.6813 1.68891 15.6111 1.77337 15.5231L8.00026 9.29622L14.2271 15.5231C14.3116 15.6111 14.4128 15.6813 14.5247 15.7297C14.6367 15.7781 14.7572 15.8037 14.8791 15.8049C15.0011 15.8061 15.1221 15.783 15.235 15.7369C15.3479 15.6908 15.4505 15.6227 15.5367 15.5364C15.6229 15.4502 15.6911 15.3476 15.7372 15.2347C15.7833 15.1218 15.8064 15.0008 15.8052 14.8789C15.8039 14.7569 15.7784 14.6364 15.73 14.5245C15.6816 14.4125 15.6113 14.3113 15.5234 14.2269L9.29648 7.99999L15.5234 1.7731C15.6568 1.6452 15.7484 1.47997 15.7862 1.29908C15.824 1.11819 15.8063 0.93009 15.7354 0.759448C15.6644 0.588807 15.5436 0.443591 15.3886 0.342846C15.2337 0.2421 15.052 0.19053 14.8672 0.194897Z" />
										</svg>
									</button>
								}
							</div>
							<div>
								<div>
									<AddInventoryComponent
										columns={[]}
										onDone={this.handleMarketplaceUploadDone}
										view={'marketplace'}
										handleCloseAddInventory={this.handleCloseAddInventory}
										handleCheckAddInventoryForm={this.handleCheckAddInventoryForm}
									/>
								</div>
							</div>
						</div>
					</div>
				)}

				{props.loggedin && (
					<section className="relative">
						<TopBar toggleSideBar={() => props.toggleSidebar(!props.isOpenSideBar)} />
						<div className="flex relative">
							<SideBar isOpen={props.isOpenSideBar} />
							<main className="pt-12 flex flex-col relative w-full h-screen overflow-y-hidden">
								<div className="2xl:pt-10 lg:pt-10 md:pt-10 2xl:bg-white lg:bg-white md:bg-white border border-r-0 2xl:rounded-tl-2xl lg:rounded-tl-2xl md:rounded-tl-2xl sm:border-0 xs:border-0 xxs:border-0 section-head sm:bgPrimary xs:bgPrimary xxs:bgPrimary">
									<div className="flex justify-between my-3 px-3 relative w-full flex-col lg:flex-row">
										<div className="mb-3 flex justify-center items-center md:mb-0">
											<div className="flex flex-col items-center mx-2 2xl:block lg:block md:block sm:hidden xs:hidden xxs:hidden">
												<h1 className="mr-4 font-semibold 2xl:text-2xl lg:text-2xl md:text-3xl sm:text-3xl xs:text-3xl xxs:text-3xl">MarketPlace</h1>
											</div>
											{
												['owner', 'admin', 'editor'].includes(props.profile?.role) && (
													<button className="bg-[#2F85C3] text-[#FFFFFF] rounded-[5px] py-[1.5px] px-2.5 w-max" onClick={this.handleOpenAddInventory}>
														<span className='text-[14px] font-normal leading-[21px]'>
															Create a Listing
														</span>
													</button>
												)
											}
										</div>
										<div className="flex lg:justify-center md:justify-center">
											{
												((activeTab === 'home' && !!marketplaceFilters.specialFilters.type)) && (
													<div className="flex px-1 2xl:block lg:block md:block sm:hidden xs:hidden xxs:hidden">
														<button
															className={Classnames(
																"border border-gray-300 text-gray-600 bg-white rounded-lg px-3 py-1 ml-1 leading-7",
																{
																	'!border-blue-600 !text-blue-600': marketplaceFilters.unwornPreOwned === 'unworn',
																}
															)}
															onClick={() => {
																setMarketplaceFilters({unwornPreOwned: marketplaceFilters.unwornPreOwned === 'unworn' ? null : 'unworn'})
															}}
															disabled={this.state.disabledInput}
														>
															Unworn
														</button>
														<button
															className={Classnames(
																"border border-gray-300 text-gray-600 bg-white rounded-lg px-3 py-1 ml-1 leading-7",
																{
																	'!border-blue-600 !text-blue-600': marketplaceFilters.unwornPreOwned === 'pre-owned',
																}
															)}
															onClick={() => {
																setMarketplaceFilters({unwornPreOwned: marketplaceFilters.unwornPreOwned === 'pre-owned' ? null : 'pre-owned'})
															}}
															disabled={this.state.disabledInput}
														>
															Pre-Owned
														</button>
														<button
															className={Classnames(
																"border border-gray-300 text-gray-600 bg-white rounded-lg px-4 py-1 ml-1 leading-7 lg:mt-1 xl:mt-0",
																{
																	'!border-blue-600 !text-blue-600': marketplaceFilters.papers,
																}
															)}
															disabled={this.state.disabledInput}
															onClick={() => {
																setMarketplaceFilters({papers: !marketplaceFilters.papers})
															}}
														>
															Papers
														</button>
														<button
															className={Classnames(
																"border border-gray-300 text-gray-600 bg-white rounded-lg px-4 py-1 ml-1 leading-7 lg:mt-1 xl:mt-0",
																{
																	'!border-blue-600 !text-blue-600': marketplaceFilters.box,
																}
															)}
															onClick={() => {
																setMarketplaceFilters({box: !marketplaceFilters.box})
															}}
															disabled={this.state.disabledInput}
														>
															Box
														</button>
													</div>
												)
											}

											<div className="flex relative md:space-x-2 justify-center gap-2 sm:mx-2 xs:mx-2 xxs:mx-2 sm:w-full xs:w-full xxs:w-full md:w-60 2xl:w-60 lg:w-60">
												<div className="text-gray-400 relative sm:w-full xs:w-full xxs:w-full">
													<div className='relative lg:mt-1 xl:mt-0'>
														<input
															className="text-gray-700 text-xs bg-white border flex items-center 2xl:max-w-xs lg:max-w-xs outline-blue-300 px-3 pr-9 relative rounded sm:w-full xs:w-full xxs:w-full"
															ref={this.searchInputRef}
															style={{height: '38px'}}
															type="text"
															name="search"
															placeholder="Search Here..."
															onChange={$event =>
																this.handleSearch($event)
															}
														/>
														<button className="absolute right-0 top-0 h-full px-3">
															<svg className="w-6 h-6" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
																<path
																	fillRule="evenodd"
																	d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
																	clipRule="evenodd"
																/>
															</svg>
														</button>
													</div>
												</div>
												<button
													onClick={() => {
														this.handleFilterButtonShow()
													}}
													className={Classnames(
														'border  bg-white rounded-lg px-2 2xl:hidden lg:hidden md:hidden sm:block xs:block xxs:block',

														{'border-blue-500 active': state.filterButtonShown},
														{'border-gray-300': !state.filterButtonShown}
													)}
												>
													<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
														<path
															strokeLinecap="round"
															strokeLinejoin="round"
															d="M10.5 6h9.75M10.5 6a1.5 1.5 0 11-3 0m3 0a1.5 1.5 0 10-3 0M3.75 6H7.5m3 12h9.75m-9.75 0a1.5 1.5 0 01-3 0m3 0a1.5 1.5 0 00-3 0m-3.75 0H7.5m9-6h3.75m-3.75 0a1.5 1.5 0 01-3 0m3 0a1.5 1.5 0 00-3 0m-9.75 0h9.75"
														/>
													</svg>
												</button>
											</div>
										</div>
									</div>
									{state.filterButtonShown && (
										<div className="flex px-5 my-3 2xl:hidden lg:hidden md:hidden sm:block xs:block xxs:block">
											<button
												className={Classnames(
													"border border-gray-300 text-gray-600 bg-white rounded-lg px-2 py-2",
													{
														'!border-blue-600 !text-blue-600': marketplaceFilters.unwornPreOwned === 'unworn',
													}
												)}
												onClick={() => {
													setMarketplaceFilters({unwornPreOwned: marketplaceFilters.unwornPreOwned === 'unworn' ? null : 'unworn'})
												}}
												disabled={this.state.disabledInput}
											>
												Unworn
											</button>
											<button
												className={Classnames(
													"border border-gray-300 text-gray-600 bg-white rounded-lg px-2 py-2 ml-1",
													{
														'!border-blue-600 !text-blue-600': marketplaceFilters.unwornPreOwned === 'pre-owned',
													}
												)}
												onClick={() => {
													setMarketplaceFilters({unwornPreOwned: marketplaceFilters.unwornPreOwned === 'pre-owned' ? null : 'pre-owned'})
												}}
												disabled={this.state.disabledInput}
											>
												Pre-Owned
											</button>
											<button
												className={Classnames(
													"border border-gray-300 text-gray-600 bg-white rounded-lg px-3 py-2 ml-1",
													{
														'!border-blue-600 !text-blue-600': marketplaceFilters.papers,
													}
												)}
												onClick={() => {
													setMarketplaceFilters({papers: !marketplaceFilters.papers})
												}}
												disabled={this.state.disabledInput}
											>
												Papers
											</button>
											<button
												className={Classnames(
													"border border-gray-300 text-gray-600 bg-white rounded-lg px-3 py-2 ml-1",
													{
														'!border-blue-600 !text-blue-600': marketplaceFilters.box,
													}
												)}
												disabled={this.state.disabledInput}
												onClick={() => {
													setMarketplaceFilters({box: !marketplaceFilters.box})
												}}
											>
												Box
											</button>
										</div>
									)}
								</div>

								<div className="flex flex-col relative w-full h-full overflow-hidden 2xl:bg-white lg:bg-white md:bg-white border-x">
									<div className="sm:bg-white xs:bg-white xxs:bg-white flex 2xl:gap-16 lg:gap-16 md:gap-16 sm:gap-2 xs:gap-2 xxs:gap-2 border-b-4 w-full -mb-0.5 2xl:pl-6 lg:pl-6 md:pl-6 sm:pl-0 xs:pl-0 xxs:pl-0">
										<button
											onClick={() => {
												if (activeTab === 'home') {
													this.setState({
														homePageIdx: uniqueId('marketplace_'),
													});
												} else {
													if(this.searchInputRef.current) {
														this.searchInputRef.current.value = ''
													}
													setMarketplaceActiveTab('home')
													this.handleSetCurrentDropdownFilter('')
													this.handleClearAllFilter()
													this.setState({
														productCount: 0,
													});
												}
											  }}
											className={Classnames(
												'sm:bg-white xs:bg-white xxs:bg-white border-r-custom 2xl:font-bold lg:font-bold text-sm flex justify-center items-center 2xl:py-3 lg:py-3 md:py-3 2xl:pb-1 lg:pb-1 md:pb-1 sm:py-4 xs:py-4 xxs:py-4 sm:text-sm xs:text-sm xxs:text-sm sm:font-normal xs:font-normal xxs:font-normal sm:px-3 xs:px-2 xxs:px-2 top-0.5',
												{
													'text-{#5D6E81} border-b-2 border-blue-600': activeTab === 'home',
												}
											)}
										>
											Home
										</button>
										<button
											onClick={() => {
												this.handleClearAllFilter()
												setMarketplaceActiveTab('marketplace')
												this.handleSetCurrentDropdownFilter('')
												this.setState({
													productCount: 0
												})
											}
											}
											className={Classnames(
												'sm:bg-white xs:bg-white xxs:bg-white border-r-custom 2xl:font-bold lg:font-bold text-sm flex justify-center items-center 2xl:py-3 lg:py-3 md:py-3 2xl:pb-1 lg:pb-1 md:pb-1 sm:py-4 xs:py-4 xxs:py-4 sm:text-sm xs:text-sm xxs:text-sm sm:font-normal xs:font-normal xxs:font-normal sm:px-3 xs:px-2 xxs:px-2 top-0.5',
												{
													'text-{#5D6E81} border-b-2 border-blue-600': activeTab === 'marketplace',
												}
											)}
										>
											View Marketplace
										</button>
										<button
											onClick={() => {
												this.handleClearAllFilter()
												this.handleSetCurrentDropdownFilter('')
												setMarketplaceActiveTab('activelistings')
												this.setState({
													productCount: 0,
												})
											}
											}
											className={Classnames(
												'sm:bg-white xs:bg-white xxs:bg-white border-r-custom 2xl:font-bold lg:font-bold text-sm flex justify-center items-center 2xl:py-3 lg:py-3 md:py-3 2xl:pb-1 lg:pb-1 md:pb-1 sm:py-4 xs:py-4 xxs:py-4 sm:text-sm xs:text-sm xxs:text-sm sm:font-normal xs:font-normal xxs:font-normal sm:px-3 xs:px-2 xxs:px-2 top-0.5',
												{
													'text-{#5D6E81} border-b-2 border-blue-600': activeTab === 'activelistings',
												}
											)}
										>
											My Active Listings
										</button>
										<button
											onClick={() => {
												this.handleClearAllFilter()
												this.handleSetCurrentDropdownFilter('')
												setMarketplaceActiveTab('saved')
												this.setState({
													productCount: 0,
												})
											}
											}
											className={Classnames(
												'sm:bg-white xs:bg-white xxs:bg-white border-r-custom 2xl:font-bold lg:font-bold text-sm flex justify-center items-center 2xl:py-3 lg:py-3 md:py-3 2xl:pb-1 lg:pb-1 md:pb-1 sm:py-4 xs:py-4 xxs:py-4 sm:text-sm xs:text-sm xxs:text-sm sm:font-normal xs:font-normal xxs:font-normal sm:px-3 xs:px-2 xxs:px-2 top-0.5',
												{
													'text-{#5D6E81} border-b-2 border-blue-600': activeTab === 'saved',
												}
											)}
										>
											Saved
										</button>
									</div>

									<div className="2xl:hidden lg:hidden md:hidden sm:hidden xs:hidden xxs:hidden text-xs text-{#5D6E81} px-4 w-full py-4">{this.state.productCount} listings</div>

									{activeTab === 'home' && (
										<MarketPlaceHome
										homePageIdx={state.homePageIdx}
											setMarketplaceFilters={setMarketplaceFilters}
											handleProductCount={this.handleProductCount}
											marketplaceFilters={marketplaceFilters}
											addProductCount={this.state.addProductCount}
											goToViewMarketPlace={this.goToViewMarketPlace}
											handleDisableInputSearch={this.handleDisableInputSearch}
											activeTab={activeTab}
											filterDataSelected={this.state.filterDataSelected}
											handleSetCurrentDropdownFilter={this.handleSetCurrentDropdownFilter}
											currentDropdownFilter={state.currentDropdownFilter}
											onChangeFilterData={this.handleChangeFilterData}
											filterPriorities={state.filterPriorities}
											isPresentFilterDataSelected={this.isPresentFilterDataSelected}
											handleClearAllFilter={this.handleClearAllFilter}
											/>
									)}

									{activeTab === 'marketplace' && (
										<MarketPlaceView
											searchKey={searchKeywords}
											handleProductCount={this.handleProductCount}
											marketplaceFilters={marketplaceFilters}
											setMarketplaceFilters={setMarketplaceFilters}
											addProductCount={this.state.addProductCount}
											goToViewMarketPlace={this.goToViewMarketPlace}
											handleDisableInputSearch={this.handleDisableInputSearch}
											activeTab={activeTab}
											handleSetCurrentDropdownFilter={this.handleSetCurrentDropdownFilter}
											currentDropdownFilter={state.currentDropdownFilter}
											onChangeFilterData={this.handleChangeFilterData}
											isPresentFilterDataSelected={this.isPresentFilterDataSelected}
											handleClearAllFilter={this.handleClearAllFilter}
										/>
									)}

									{activeTab === 'activelistings' && (
										<ActiveListingsView
											searchKey={searchKeywords}
											handleProductCount={this.handleProductCount}
											marketplaceFilters={marketplaceFilters}
											setMarketplaceFilters={setMarketplaceFilters}
											addProductCount={this.state.addProductCount}
											goToViewMarketPlace={this.goToViewMarketPlace}
											handleDisableInputSearch={this.handleDisableInputSearch}
											activeTab={activeTab}
											handleSetCurrentDropdownFilter={this.handleSetCurrentDropdownFilter}
											currentDropdownFilter={state.currentDropdownFilter}
											onChangeFilterData={this.handleChangeFilterData}
											isPresentFilterDataSelected={this.isPresentFilterDataSelected}
											handleClearAllFilter={this.handleClearAllFilter}
										/>
									)}

									{activeTab === 'saved' &&
										<SavedListingsView
											searchKey={searchKeywords}
											handleProductCount={this.handleProductCount}
											marketplaceFilters={marketplaceFilters}
											setMarketplaceFilters={setMarketplaceFilters}
											goToViewMarketPlace={this.goToViewMarketPlace}
											handleDisableInputSearch={this.handleDisableInputSearch}
											activeTab={activeTab}
											handleSetCurrentDropdownFilter={this.handleSetCurrentDropdownFilter}
											currentDropdownFilter={state.currentDropdownFilter}
											onChangeFilterData={this.handleChangeFilterData}
											isPresentFilterDataSelected={this.isPresentFilterDataSelected}
											handleClearAllFilter={this.handleClearAllFilter}
										/>
									}

								</div>

								{state.loadingMoreData && (
									<div className="absolute top-0 left-0 right-0 bottom-0 flex justify-center items-center z-50">
										<button
											type="button"
											className="inline-flex items-center px-4 py-2 leading-6 text-sm shadow rounded-md text-white bg-blue transition ease-in-out duration-150 cursor-not-allowed"
											disabled
										>
											<svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
												{' '}
												<circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4" />
												<path
													className="opacity-75"
													fill="currentColor"
													d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
												/>
											</svg>
											<span>Loading... </span>
										</button>
									</div>
								)}
							</main>
						</div>
					</section>
				)}
			</React.Fragment>
		)
	}
}

const mapStateToProps = (state, props) => {
	return {
		profile: state.profile,
		currentSpace: state.currentSpace,
		isOpenSideBar: state.isOpenSideBar,
		loggedin: state.loggedin,
		marketplaceFilters: state.marketplaceFilters,
	}
}

const mapDispatchToProps = dispatch => {
	return bindActionCreators({
		toggleSidebar,
		setMarketplaceFilters,
		setMarketplaceActiveTab,
		setMarketplaceSearchKeywords,
	}, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(Marketplace)
