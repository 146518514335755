import React, {useEffect, useState} from 'react'
import Moment from 'moment'
import Tooltip from '@mui/material/Tooltip'

import SingleItemDetail from '../Sales/SingleItemDetail'
import {Globals} from '../../Context'
import isEmpty from 'lodash/isEmpty'

const CustomerLedger = ({props, singleCustomerView}) => {
	const [ordersList, setOrdersList] = useState()
	const [singleOrderItemId, setSingleOrderItemId] = useState()
	const [isVisibleSingleOrder, setIsVisibleSingleOrder] = useState(false)
	const [loading, setLoading] = useState(false)

	useEffect(() => {
		handleLoadOrders()
	}, [])
	const handleLoadOrders = () => {
		return Globals.New_Axios()
			.get(`${Globals.NEW_API_URL}/${Globals.ENDPOINTVERSION}/spaces/${Globals.SELECTEDSPACEID}/customers/${singleCustomerView.id}/ledger`)
			.then(response => {
				if (response.status && response.status >= 200 && response.status < 300) {
					let orders = response.data.sales

					setOrdersList([...orders])
				}
			})
			.catch(error => {
				return props.addToast({
					text: 'Something went wrong please try again later.',
					type: 'error',
				})
			})
	}

	const loadSingleOrder = order => {
		setSingleOrderItemId(order.id)
		setIsVisibleSingleOrder(true)
	}
	const closeSingleOrderItem = () => {
		setIsVisibleSingleOrder(false)
	}

	const handleDownLoadPdf = async => {
		let requestHeader = {responseType: 'arraybuffer', headers: {Accept: 'application/pdf'}}
		let isLoader = 'download'
		let urlParm = 'download_ledger.pdf'
		return Globals.New_Axios()
			.post(`${Globals.NEW_API_URL}/${Globals.ENDPOINTVERSION}/spaces/${Globals.SELECTEDSPACEID}/customers/${singleCustomerView.id}/${urlParm}`, '', requestHeader)
			.then(async response => {
				setLoading(false)
				const blob = new Blob([response.data], {type: 'application/pdf'})
				const url = window.URL.createObjectURL(blob)
				const link = document.createElement('a')
				link.href = url
				link.setAttribute('download', `Ledger  ${singleCustomerView.name} - ${Moment().format('MM-DD-YYYY')}.pdf`)
				document.body.appendChild(link)
				link.click()
				link.parentNode.removeChild(link)
			})
			.catch(error => {
				console.log(error, 'error')
				setLoading(false)
				return props.addToast({
					text: 'Something went wrong please try again later.',
					type: 'error',
				})
			})
	}

	const handleClickOut = $event => {
		$event.stopPropagation()

		if($event.target.classList.contains('single-customer-view')) {
			setSingleOrderItemId(null)
			setIsVisibleSingleOrder(false)
		}
	}

	return (
		<div>
			<div className='border-b flex justify-between pr-[10px]'>
				<h2 className="text-md font-bold tracking-wider py-2 px-5">Ledger</h2>
				<Tooltip title="Download Ledger" placement="bottom" arrow>
					<button
						className="bg-blue-600 hover:opacity-90 px-4 py-1.5 rounded text-white transition-all"
						onClick={handleDownLoadPdf}
					>
						{loading ? (
							<svg xmlns="http://www.w3.org/2000/svg" className="animate-spin h-6 w-6" stroke-width="1.5" stroke="currentColor" viewBox="0 0 24 24">
								<path
									strokeLinecap="round"
									strokeLinejoin="round"
									d="M8.625 12a.375.375 0 11-.75 0 .375.375 0 01.75 0zm0 0H8.25m4.//////125 0a.375.375 0 11-.75 0 .375.375 0 01.75 0zm0 0H12m4.125 0a.375.375 0 11-.75 0 .375.375 0 01.75 0zm0 0h-.375M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
								/>
							</svg>
						) : (
							<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
								<path
									strokeLinecap="round"
									strokeLinejoin="round"
									d="M6.72 13.829c-.24.03-.48.062-.72.096m.72-.096a42.415 42.415 0 0110.56 0m-10.56 0L6.34 18m10.94-4.171c.24.03.48.062.72.096m-.72-.096L17.66 18m0 0l.229 2.523a1.125 1.125 0 01-1.12 1.227H7.231c-.662 0-1.18-.568-1.12-1.227L6.34 18m11.318 0h1.091A2.25 2.25 0 0021 15.75V9.456c0-1.081-.768-2.015-1.837-2.175a48.055 48.055 0 00-1.913-.247M6.34 18H5.25A2.25 2.25 0 013 15.75V9.456c0-1.081.768-2.015 1.837-2.175a48.041 48.041 0 011.913-.247m10.5 0a48.536 48.536 0 00-10.5 0m10.5 0V3.375c0-.621-.504-1.125-1.125-1.125h-8.25c-.621 0-1.125.504-1.125 1.125v3.659M18 10.5h.008v.008H18V10.5zm-3 0h.008v.008H15V10.5z"
								/>
							</svg>
						)}
					</button>
				</Tooltip>
			</div>
			<div className="divide-gray-200 px-2 pb-28 overflow-y-scroll" style={{maxHeight: 'calc(100vh - 200px)'}}>
				<table className="relative min-w-full border-b bg-transparent overflow-auto" style={{width: '100%', height: '100%'}}>
					<thead
						className="bg-white border-b"
						style={{
							position: 'sticky',
							top: '0',
							zIndex: 1,
						}}
					>
						<tr>
							<th className="py-2 pl-4 pr-2 text-sm font-semibold tracking-worderer">Order #</th>
							<th className="py-2 pr-2 text-sm font-semibold tracking-worderer">Date</th>
							<th className="py-2 pr-2 text-sm font-semibold tracking-worderer">Items</th>
							<th className="py-2 pr-2 text-sm font-semibold tracking-worderer">Invoice Amount</th>
							<th className="py-2 pr-2 text-sm font-semibold tracking-worderer">Payment</th>
							<th className="py-2 pr-2 text-sm font-semibold tracking-worderer text-right">Running Balance</th>
						</tr>
					</thead>

					{!isEmpty(ordersList) && (
						<tbody className="divide-y">
							{ordersList.map((order, index) => {
								return (
									<tr
										key={index}
										//className={Classnames('cursor-pointer divide-x hover:bg-gray-100 bg-gray-50', {
										//	'bg-white': (index + 1) % 2 === 0,
										//	})}
										className="hover:bg-gray-100 cursor-pointer"
										onClick={() => {
											loadSingleOrder(order)
										}}
									>
										<td className="py-2 pr-2 pl-4 text-sm font-normal tracking-worderer">#{order.order_number}</td>
										<td className="py-2 text-sm font-normal tracking-worderer">{Moment(order.created_at).format('MM/DD/YYYY')}</td>

										<td className="py-2 text-sm font-normal tracking-worderer">{order.items}</td>
										<td className="py-2 text-sm font-normal tracking-worderer">{Globals.moneyFormatter.format(parseFloat(order.total)).replace('.00', '').replace('$', '')}</td>
										<td className="py-2 text-sm font-normal tracking-worderer">{Globals.moneyFormatter.format(parseFloat(order.payment)).replace('.00', '').replace('$', '')}</td>
										<td className="py-2 text-sm font-normal tracking-worderer text-right pr-2">
											{Globals.moneyFormatter.format(parseFloat(order.balance)).replace('.00', '').replace('$', '')}
										</td>
									</tr>
								)
							})}
						</tbody>
					)}
				</table>
				{isVisibleSingleOrder && <SingleItemDetail props={props} handleClickOut={handleClickOut} orderId={singleOrderItemId} closeSingleOrderItem={closeSingleOrderItem}  currentSpace={props.currentSpace} profile={props.profile}/>}
			</div>
		</div>
	)
}

export default CustomerLedger
