import React, {useEffect, useState} from 'react'
import Switch from '@mui/material/Switch';
import {useDispatch} from 'react-redux'

import Privacy from '../Privacy'
import Input from '../Input'
import Button from '../Button'
import {Globals} from '../../../Context'
import {addToast} from '../../../app/appActions'
import findIndex from 'lodash/findIndex'
import isEmpty from 'lodash/isEmpty'

const REMINDER_ENABLED_IN_ORDER = ['first_payment_reminder_enabled', 'second_payment_reminder_enabled', 'third_payment_reminder_enabled']

const PaymentReminders = (props) => {
	const dispatch = useDispatch()
	const [formData, setFormData] = useState({
		first_payment_reminder_enabled: false,
		second_payment_reminder_enabled: false,
		third_payment_reminder_enabled: false,
		first_payment_reminder_frequency: '',
		second_payment_reminder_frequency: '',
		third_payment_reminder_frequency: ''
	});
	const [formErrors, setFormErrors] = useState({});

	const getPaymentReminders = async () => {
		const response = await Globals.New_Axios().get(`${Globals.NEW_API_URL}/v1/spaces/${Globals.SELECTEDSPACEID}/payment_email_reminder_settings`)
		setFormData(response.data)
	}

	useEffect(() => {
		getPaymentReminders()
	}, [])

	const toggleEnableDisableRemiderNotification = (event) => {
		const inputName = event.target.name;
		const indexOfReminderEnabled = findIndex(REMINDER_ENABLED_IN_ORDER, (reminderEnabled) => reminderEnabled === inputName);
		const leftReminderEnabledInOrder = REMINDER_ENABLED_IN_ORDER.slice(0, indexOfReminderEnabled);
		const rightReminderEnabledInOrder = REMINDER_ENABLED_IN_ORDER.slice(indexOfReminderEnabled, REMINDER_ENABLED_IN_ORDER.length);
		if(event.target.checked) {
			let buildReminderEnabled = {}
			leftReminderEnabledInOrder.forEach((reminderEnabled) => {
				buildReminderEnabled = {
					...buildReminderEnabled,
					[reminderEnabled]: event.target.checked
				}
			})
			setFormData({...formData, ...buildReminderEnabled, [event.target.name]: event.target.checked})
		} else {
			let buildReminderEnabled = {}
			rightReminderEnabledInOrder.forEach((reminderEnabled) => {
				buildReminderEnabled = {
					...buildReminderEnabled,
					[reminderEnabled]: event.target.checked
				}
			})
			setFormData({...formData, ...buildReminderEnabled})
		}
	}

	const handleSubmit = (event) => {
		event.preventDefault()
		let errors = {}

		if (formData.first_payment_reminder_enabled && !formData.first_payment_reminder_frequency) errors = {...errors, first_payment_reminder_frequency: 'First Payment Reminder is required'}
		if (formData.second_payment_reminder_enabled && !formData.second_payment_reminder_frequency) errors = {...errors, second_payment_reminder_frequency: 'Second Payment Reminder is required'}
		if (formData.third_payment_reminder_enabled && !formData.third_payment_reminder_frequency) errors = {...errors, third_payment_reminder_frequency: 'Third Payment Reminder is required'}
		setFormErrors(errors)

		if (!isEmpty(errors)) return //Skip the rest.
		return Globals.New_Axios()
			.patch(`${Globals.NEW_API_URL}/v1/spaces/${Globals.SELECTEDSPACEID}/payment_email_reminder_settings`, formData)
			.then(async response => {
				setFormData(response.data)
				dispatch(
					addToast({
					title: 'Payment Reminders',
					text: 'Payment Reminders has been updated successfully',
					type: 'success',
				}))
			})
			.catch(error => {
				dispatch(
					addToast({
					title: 'Payment Reminders',
					text: error.response?.data?.message || 'Something went wrong please try again later.',
					type: 'error',
				}))
			})
	}

	const handleChangeInput = event => {
		setFormData({...formData, [event.target.name]: event.target.value.trim()})
	}

	const displayError = key => {
		if (!isEmpty(formErrors[key])) return <div className="pt-3 text-red-700">{formErrors[key]}</div>
	}

	return (
		<div className="relative pb-24 w-full h-full">
			<div className="h-screen ">
				<div>
					<h1 className="pt-5 pb-2 text-black lg:pt-8 lg:pb-5 text-base lg:text-2xl font-bold leading-7 pl-5">
						Payment Reminds
					</h1>
					<p className="pl-14 text-sm text-black">Keep track of unpaid invoices effortlessly and ensure nothing slips through the cracks with personalized reminder options tailored to your preferences!</p>
					<p className="mt-2 pl-14 text-sm text-black">Tweak the frequency and number of reminder for customers with ourstanding balances. Decide wheher to send a single friendly nudge or a series of</p>
					<p className="pl-14 text-sm text-black">reminders, including a final recurring email if needed</p>
				</div>
				<form className="pl-14" action="" onSubmit={handleSubmit}>
					<div className='pt-10'>
						<label>First Payment Reminder</label>
						<div className="flex items-center">
							<Switch
							 	disabled={!Globals.adminOrOwner(props.profile)}
								name="first_payment_reminder_enabled"
								checked={formData.first_payment_reminder_enabled}
								onChange={(event) => toggleEnableDisableRemiderNotification(event)}
								sx={{
									"&.MuiSwitch-root .MuiSwitch-input": {
										height: "-webkit-fill-available"
									},
								}}
							/>
							<Input className={'input h-8 lg:h-12 rounded-md w-[38px] px-3 text-xs font-normal leading-5 lg:text-sm'} onchange={handleChangeInput} name="first_payment_reminder_frequency" value={formData.first_payment_reminder_frequency} type="number" disableTitle={true} disabled={!Globals.adminOrOwner(props.profile)}/>
							<p className='ml-2'>Days from invoice creation</p>
						</div>
						<div className='ml-16'>
							{displayError('first_payment_reminder_frequency')}
						</div>

					</div>
					<div className='pt-10'>
						<label>Second Payment Reminder </label>
						<div className="flex items-center">
							<Switch
								disabled={!Globals.adminOrOwner(props.profile)}
								name="second_payment_reminder_enabled"
								checked={formData.second_payment_reminder_enabled}
								onChange={(event) => toggleEnableDisableRemiderNotification(event)}
								sx={{
									"&.MuiSwitch-root .MuiSwitch-input": {
										height: "-webkit-fill-available"
									},
								}}
							/>
							<Input className={'input h-8 lg:h-12 rounded-md w-[38px] px-3 text-xs font-normal leading-5 lg:text-sm'} onchange={handleChangeInput} name="second_payment_reminder_frequency" value={formData.second_payment_reminder_frequency} type="number" disableTitle={true} disabled={!Globals.adminOrOwner(props.profile)}/>
							<p className='ml-2'>Days after first e-mail</p>
						</div>
						<div className='ml-16'>
							{displayError('second_payment_reminder_frequency')}
						</div>
					</div>
					<div className='pt-10'>
						<label>Third Payment Reminder</label>
						<div className="flex items-center">
							<Switch
								disabled={!Globals.adminOrOwner(props.profile)}
								name="third_payment_reminder_enabled"
								checked={formData.third_payment_reminder_enabled}
								onChange={(event) => toggleEnableDisableRemiderNotification(event)}
								sx={{
									"&.MuiSwitch-root .MuiSwitch-input": {
										height: "-webkit-fill-available"
									},
								}}
							/>
							<span>Every 7 days starting after second e-mail</span>
						</div>
					</div>
					{
						Globals.adminOrOwner(props.profile) && (
							<div className='flex flex-row justify-center py-4'>
								<Button type="submit" value="Update Payment Reminders" />
							</div>
						)
					}
				</form>
			</div>
			<Privacy />
		</div>
	)
}

export default PaymentReminders
