import React from 'react'
import Tooltip from '@mui/material/Tooltip'
import '../../Pages/Messages.scss'
import ImagePreview from '../Messages/ImagePreview'
import isEmpty from 'lodash/isEmpty'
import isObject from 'lodash/isObject'
import head from 'lodash/head'

const ProductMessageGallery = ({product, mobileView=false}) => {
	const [previewVisible, setPreviewVisible] = React.useState(null)

	const handlePreview = file => {
		setPreviewVisible(file)
	}

	if (isEmpty(product?.images)) return

	try {
		let images = product?.images
		if (!isObject(images)) return

		return (
			<React.Fragment>
				<div className="mb-2">
					<div className="grid grid-cols-1 gap-2">
						<div
							className="relative flex items-center justify-center"
							style={{
								width: '100%',
							}}
						>
							<div
								className="preview"
								style={{
									backgroundImage: `url('${head(images).url}')`,
									width: mobileView ? '200px' : '68px',
									height: mobileView ? '200px' : '68px',
									borderRadius: '50%',
									backgroundSize: 'cover',
									backgroundPosition: 'center',
								}}
							>
								<Tooltip title="View picture" placement="bottom" arrow>
									<button onClick={handlePreview.bind(this, head(images))} className="opacity-0 preview-btn bg-blue p-2 rounded-full">
										<svg
											width={mobileView ? '200px' : '68px'}
											height={mobileView ? '200px' : '68px'}
											style={{
												borderRadius: '50%',
											}}
											viewBox="0 0 22 15"
											xmlns="http://www.w3.org/2000/svg"
											fill="white"
										>
											<path d="M11 2C14.79 2 18.17 4.13 19.82 7.5C18.17 10.87 14.79 13 11 13C7.21 13 3.83 10.87 2.18 7.5C3.83 4.13 7.21 2 11 2ZM11 0C6 0 1.73 3.11 0 7.5C1.73 11.89 6 15 11 15C16 15 20.27 11.89 22 7.5C20.27 3.11 16 0 11 0ZM11 5C12.38 5 13.5 6.12 13.5 7.5C13.5 8.88 12.38 10 11 10C9.62 10 8.5 8.88 8.5 7.5C8.5 6.12 9.62 5 11 5ZM11 3C8.52 3 6.5 5.02 6.5 7.5C6.5 9.98 8.52 12 11 12C13.48 12 15.5 9.98 15.5 7.5C15.5 5.02 13.48 3 11 3Z" />
										</svg>
									</button>
								</Tooltip>
							</div>
						</div>
					</div>
				</div>

				{previewVisible && !mobileView && (
					<div className="upload-media-lightbox fixed top-0 left-0 w-3/4 h-full z-50 p-11" tabIndex="-1" onKeyDown={e => (e.key === 'Escape' ? setPreviewVisible(null) : '')}>
						<div className="upload-media-lightbox-inner w-full h-full">
							<div className="upload-media-lightbox-image relative w-full h-full flex justify-center items-center p-4">
								<ImagePreview images={images} setPreviewVisible={setPreviewVisible} />
								{/*
								<img src={previewVisible} className="max-h-full max-w-full" alt="preview" />
								*/}
							</div>
						</div>
					</div>
				)}
			</React.Fragment>
		)
	} catch (error) {
		return
	}
}

export default ProductMessageGallery
