import React, {useEffect} from 'react'
import {connect} from 'react-redux'
import Privacy from '../Components/Privacy'
import {addToast} from './../app/appActions'
import {NavLink} from 'react-router-dom'

const Register = props => {

	useEffect(() => {
		const queryParams = new URLSearchParams(location.search)
		const mobileCheckOut = queryParams.get('mobile-checkout')
		if(mobileCheckOut === 'true') {
			openApp()
		}
	}, [])


    const openApp = () => {
        const customUrlScheme = `com.elefta.app://login`;
        try {
            // Attempt to open the app using the custom URL scheme
            window.location.href = customUrlScheme;
        } catch (error) {
            console.log(error)
        }
    };

	return (
		<div className="flex md:flex-row flex-col-reverse w-screen bgLinearBlue md:!bg-none md:!bg-[#2C79E0] min-h-screen">
			<div className="md:w-2/3 flex flex-col items-center justify-center min-h-screen bgLogin bg-white px-4" style={{background: '#EDF2F8'}}>
				<div className="pt-7 pb-7">
					<h1 className="text-center w-96 text-2xl">Thank you for registering! </h1>
					<h3 className="font-normal text-center pt-2 text-sm tracking-wider  w-96">
						<strong className="text-blue-brand font-semibold">Please verify your email by clicking the confirmation link we just sent. Once confirmed, we’ll review your account and update you when it’s approved.</strong>
					</h3>
				</div>

				<Privacy />
			</div>

			<div className="md:w-1/3 md:flex hidden flex-col  justify-center mt-14 text-white px-16">
				{/* <h1 className="text-5xl font-bold">ELEFTA</h1> */}

				<h1 className="text-5xl font-bold">ELEFTA</h1>
				<p className="font-medium leading-7 text-sm pt-10">
					The most advanced watch-specific inventory system and software <br />
					<br /> Built by watch dealers for watch dealers
				</p>
			</div>

			<div className="md:hidden flex items-center justify-center mt-3 mb-3 sm:h-auto mpt-5 lg:pt-0 lg:h-screen">
				<img src="/logo-trans-white.png" className=" relative" width="200" height={'auto'} alt="Elefta"
					 loading="lazy" />
			</div>
		</div>
	)
}

const mapStateToProps = (state, props) => {
	return {}
}

const mapActionsToProps = {
	addToast
}

export default connect(mapStateToProps, mapActionsToProps)(Register)
