import React from "react";
import {loadStripe} from '@stripe/stripe-js';
import {Globals} from '../Context'
import {
  EmbeddedCheckoutProvider,
  EmbeddedCheckout
} from '@stripe/react-stripe-js';

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
// This test secret API key is a placeholder. Don't include personal details in requests with this key.
// To see your test secret API key embedded in code samples, sign in to your Stripe account.
// You can also find your test secret API key at https://dashboard.stripe.com/test/apikeys.

const stripePromise = (async () => {
  try {
    return await loadStripe(Globals.PUBLIC_KEY_STRIPE);
  } catch (err) {
    console.error(err);
    window.location.reload();
  }
})();

const CheckoutForm = ({checkoutSession}) => {
  return (
    <EmbeddedCheckoutProvider
      stripe={stripePromise}
      options={{
        clientSecret: checkoutSession
      }}
    >
      <EmbeddedCheckout
        className="relative lg:max-w-auto lg:w-full pb-[35px] p-2 bg-[#fff] border-gray-300 border-[2px] rounded-[10px] mb-[50px]"
        buttonLabel="Save Your Card" // Custom button label
      />
    </EmbeddedCheckoutProvider>
  )
}

export default CheckoutForm;
