import React, { useCallback, useState, useRef, useMemo, useEffect } from 'react'
import useOutsideClick from '../../hooks/useOutsideClick'

import {Globals} from '../../Context'
import classNames from 'classnames'

const AddressDropdown = ({ addressOptions, selectedAddress, onAddNew, onSelect, addressType }) => {
	const [isAddNew, setIsAddNew] = useState(false)
	const [isOpen, setIsOpen] = useState(false)
	const [focusedItemIndex, setFocusedItemIndex] = useState(-1);

	const dropdownAddress = useRef(null)

	useOutsideClick(dropdownAddress, (event) =>{
		if(event.target.getAttribute('text') === 'dropdown') return
		 setIsOpen(false)
		 setFocusedItemIndex(-1)
	});

	const isDefault = useCallback((customerAddress) => {
		if (addressType === 'billing') {
			return customerAddress.is_billing_default
		} else if (addressType === 'shipping') {
			return customerAddress.is_shipping_default
		}
	}, [addressType])

	const goToNextOption = (event, options) => {
		setFocusedItemIndex((prevIndex) => Math.min(prevIndex + 1, options.length -1));
		event.stopPropagation();
	}

	const goToPreviousOption = (event) => {
		setFocusedItemIndex((prevIndex) => Math.max(prevIndex - 1, 0));
		event.stopPropagation();
	}

	const handleKeyDown = (event) => {
		switch (event.key) {
			case Globals.keyboardButton.arrowDown:
				event.preventDefault();
				goToNextOption(event, [...addressOptions, {address: '+ New Address'}])
				break;
			case Globals.keyboardButton.arrowUp:
				event.preventDefault();
				goToPreviousOption(event)
				break;
			case Globals.keyboardButton.enter:
				event.preventDefault();
				if(focusedItemIndex === -1) return
				handleEnterKeyBoard()
				break;
			default:
			break;
		}
	};

	const handleEnterKeyBoard = () => {
		if(focusedItemIndex === -1) return
		if(addressOptions.length === focusedItemIndex) {
			setIsOpen(false)
			setIsAddNew(true)
			onAddNew(addressType)
			setFocusedItemIndex(-1)
		} else {
			onSelect(addressType, addressOptions[focusedItemIndex])
			setIsOpen(false)
			setIsAddNew(false)
			setFocusedItemIndex(-1)
		}
	}

	const showTitle = useMemo(() => {
		if (isAddNew) {
		  return 'New Address';
		}

		const isDefault = (addressType === 'billing' && selectedAddress?.is_billing_default) || (addressType === 'shipping' && selectedAddress?.is_shipping_default);

		if (isDefault) {
		  return 'Default';
		}

		return selectedAddress?.address || 'Select Address';
	  }, [selectedAddress, addressType, isAddNew]);


	return (
		<div tabIndex="0" onKeyDown={(event) => handleKeyDown(event)} className="relative inline-block">
			<div
				onClick={() => setIsOpen(!isOpen)}
				className="bg-white w-auto pr-[32px] pl-[20px] relative cursor-pointer rounded-[4px] border-[1px] border-[#5D6E81] text-center"
				text='dropdown'
			>
				{showTitle}
				<img src="./Images/dropdown.png" alt="" className="w-[13px] h-[20px] absolute top-0 right-[4px]" />
			</div>
			{
				isOpen && (
					<div
						className="absolute z-[999] top-[26px] bg-white w-auto border-[1px] border-[#5D6E81] rounded-[5px]"
						ref={dropdownAddress}
					>
						<ul className="text-[#5D6E81] text-[12px] w-full min-w-[200px]">
							{
								addressOptions?.map((customerAddress, index) => (
									<li
										key={index}
										className={classNames("text-[#5D6E81] cursor-pointer pl-[16px] py-[2px] whitespace-nowrap relative hover:bg-[#F1F2F4]", {
											'border-[2px] border-black': index === focusedItemIndex
										})}
										onClick={() => {
											setIsOpen(false)
											setIsAddNew(false)
											onSelect(addressType, customerAddress)
										}}
										ref={el => {
											if (index === focusedItemIndex && el) el.focus();
										}}
									>
										{customerAddress.address} {isDefault(customerAddress) ? '(Default)' : ''}

										{addressType === 'billing' && selectedAddress?.id === customerAddress.id &&
											<div className="absolute top-[7px] left-[6px]">
												<svg xmlns="http://www.w3.org/2000/svg" width="7" height="7" viewBox="0 0 7 7" fill="none">
													<path d="M6.1775 0L7 1.04124L2.33333 6.90735L0 3.98163L0.828333 2.94772L2.33333 4.83221L6.1775 0Z" fill="#5D6E81"/>
												</svg>
											</div>
										}

										{addressType === 'shipping' && selectedAddress?.id === customerAddress.id &&
											<div className="absolute top-[7px] left-[6px]">
												<svg xmlns="http://www.w3.org/2000/svg" width="7" height="7" viewBox="0 0 7 7" fill="none">
													<path d="M6.1775 0L7 1.04124L2.33333 6.90735L0 3.98163L0.828333 2.94772L2.33333 4.83221L6.1775 0Z" fill="#5D6E81"/>
												</svg>
											</div>
										}
									</li>
								))
							}
							<li
								className={classNames("border-t-[1px] border-[#5D6E81] pl-[16px] cursor-pointer whitespace-nowrap", {
									'border-[2px] border-black': addressOptions.length  === focusedItemIndex
								})}
								onClick={() => {
									setIsOpen(false)
									setIsAddNew(true)
									onAddNew(addressType)
								}}
								ref={el => {
									if (addressOptions.length === focusedItemIndex && el) el.focus();
								}}
							>
								+ New Address
							</li>
						</ul>
					</div>
				)
			}
		</div>
	)
}

export default AddressDropdown
