import React, {useState} from 'react'
import {Globals} from '../../Context'
import { addToast } from '../../app/appActions'
import { useDispatch } from 'react-redux'
import isEmpty from 'lodash/isEmpty'
import isUndefined from 'lodash/isUndefined'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormControl from '@mui/material/FormControl'
import FormGroup from '@mui/material/FormGroup'
import Checkbox from '@mui/material/Checkbox'

const requirementsData = [
	{label: 'Unworn', value: 'Unworn'},
	{label: 'Papers', value: 'Papers'},
	{label: 'Box', value: 'Box'},
	{label: 'Pre-Owned', value: 'Pre-Owned'},
	{label: 'No Papers', value: 'No Papers'},
	{label: 'Retail Ready', value: 'Retail Ready'}
]

const ConciergeInquiryForm = ({searchTerm, displayModal}) => {
	const [formErrors, setFormErrors] = useState([])
	const [formData, setFormData] = useState({
		search_term: searchTerm,
		additional_details: '',
		requirements: []
	})
	const dispatch = useDispatch()
	const [isSuccess, setIsSuccess] = useState(false)
	const handleSubmit = () => {
		let errors = {}

		if (!isEmpty(errors)) return setFormErrors({...errors})

		Globals.New_Axios()
			.post(`${Globals.NEW_API_URL}/${Globals.ENDPOINTVERSION}/spaces/${Globals.SELECTEDSPACEID}/marketplaces/send_concierge_inquiry`, {
				concierge_inquiry: formData
			})
			.then(async response => {
				setIsSuccess(true)
			})
			.catch(error => {
				dispatch(
					addToast({
						title: 'Error',
						text: error.response?.data?.message || 'Something went wrong please try again later.',
						type: 'error',
					}))
			})
	}

	const displayError = key => {
		if (!isUndefined(formErrors[key])) return <div className="pt-3 text-red-700">{formErrors[key]}</div>
	}

	const handleChange = (event) => {
		setFormData(prevState => ({
			...prevState,
			[event.target.name]: event.target.value
		}))
	};

	const handleChangeRequirements = (event) => {
		let value = event.target.value
		let checked = event.target.checked
		if (checked) {
			setFormData(prevState => ({
				...prevState,
				requirements: [...prevState.requirements, value]
			}))
		} else {
			setFormData(prevState => ({
				...prevState,
				requirements: prevState.requirements.filter(item => item !== value)
			}))
		}
	}

	return (
		<div className="fixed top-0 left-0 w-screen h-screen bg-black/50 flex items-center justify-center z-50">
			<div className="bg-white drop-shadow-xl rounded-[5px] sm:w-1/2 md:w-[719px]">
				<div className="w-full bg-[#4A5567] rounded-t-[5px] relative p-[19px_18px_12px_15px] flex items-center">
					<span className="text-white font-bold text-lg">Elefta Concierge Inquiry</span>
					<button onClick={() => displayModal(false)} className="absolute right-0 mr-5 cursor-pointer">
						<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path
								d="M14.8672 0.194897C14.625 0.200615 14.3949 0.301995 14.2271 0.47688L8.00026 6.70377L1.77337 0.47688C1.68784 0.38896 1.58554 0.319098 1.47252 0.271431C1.35951 0.223764 1.23807 0.199261 1.11541 0.199373C0.933136 0.199599 0.755062 0.254158 0.60394 0.35608C0.452819 0.458003 0.335512 0.602661 0.267004 0.771577C0.198497 0.940492 0.181898 1.126 0.21933 1.30439C0.256762 1.48278 0.346524 1.64597 0.47715 1.7731L6.70404 7.99999L0.47715 14.2269C0.389174 14.3113 0.318936 14.4125 0.27055 14.5245C0.222163 14.6364 0.196601 14.7569 0.19536 14.8789C0.194119 15.0008 0.217224 15.1218 0.263323 15.2347C0.309421 15.3476 0.377586 15.4502 0.463825 15.5364C0.550064 15.6227 0.652643 15.6908 0.765556 15.7369C0.878469 15.783 0.999445 15.8061 1.1214 15.8049C1.24335 15.8037 1.36383 15.7781 1.47578 15.7297C1.58774 15.6813 1.68891 15.6111 1.77337 15.5231L8.00026 9.29622L14.2271 15.5231C14.3116 15.6111 14.4128 15.6813 14.5247 15.7297C14.6367 15.7781 14.7572 15.8037 14.8791 15.8049C15.0011 15.8061 15.1221 15.783 15.235 15.7369C15.3479 15.6908 15.4505 15.6227 15.5367 15.5364C15.6229 15.4502 15.6911 15.3476 15.7372 15.2347C15.7833 15.1218 15.8064 15.0008 15.8052 14.8789C15.8039 14.7569 15.7784 14.6364 15.73 14.5245C15.6816 14.4125 15.6113 14.3113 15.5234 14.2269L9.29648 7.99999L15.5234 1.7731C15.6568 1.6452 15.7484 1.47997 15.7862 1.29908C15.824 1.11819 15.8063 0.93009 15.7354 0.759448C15.6644 0.588807 15.5436 0.443591 15.3886 0.342846C15.2337 0.2421 15.052 0.19053 14.8672 0.194897Z"
								fill="white"
							/>
						</svg>
					</button>
				</div>

				{isSuccess ? (
					<div className='flex flex-col h-full items-center pt-16 px-[150px] text-[#5D6E81]'>
						<img src="/checked_gray_outline.png" alt="checked" className="w-[85px] mx-auto" />
						<p className='text-[22px] font-semibold mt-[40px]'>Message Sent!</p>
						<p className='mt-[37px] mb-[110px] text-center text-lg'>A member from the Elefta Concierge will reach out to you shortly. </p>
					</div>
				) :(
					<div className="flex flex-col h-full px-7 pt-4 pb-5">
						<div className="flex flex-col relative mt-2.5">
							<label htmlFor="search_term" className="mb-2 pl-3 text-sm font-semibold bg-transparent">
								Watch
							</label>
							<div className="outlin relative w-full">
								<input
									id="search_term"
									type="text"
									name="search_term"
									placeholder="Reference Number, Model, Series, Etc."
									className="block p-3 w-full inputLogin appearance-none outline-none text-sm font-medium placeholder:text-sm placeholder:text-[#cccccc]"
									onChange={handleChange}
									value={formData.search_term}
								/>
							</div>
							{displayError('search_term')}
						</div>
						<div className="flex flex-col relative mt-4">
							<label htmlFor="additional_details" className="mb-2 pl-3 text-sm font-semibold bg-transparent">
								Additional Details
							</label>
							<div className="outlin relative w-full">
								<textarea
									id="additional_details"
									type="text"
									name="additional_details"
									rows={2}
									placeholder="Any details to help with the search"
									className="block p-3 w-full inputLogin appearance-none outline-none text-sm font-medium placeholder:text-sm placeholder:text-[#cccccc]"
									onChange={handleChange}
									value={formData.additionalDetails}
								/>
							</div>
							{displayError('additional_details')}
						</div>
						<div className="flex flex-col relative mt-4">
							<label htmlFor="additionalDetails" className="mb-1 pl-3 text-sm font-semibold bg-transparent">
								Requirements
							</label>
							<FormControl component="fieldset" className='!mx-[30px]'>
								<FormGroup className='!grid !grid-cols-[120px_120px_120px] !justify-between'>
									{requirementsData.map((item, index) => (
										<FormControlLabel
											key={index}
											control={
												<Checkbox
													onChange={handleChangeRequirements}
													name={"requirements[]"}
													value={item.value}
													size={"16"}
													icon={
														<svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
															<rect x="0.5" y="0.5" width="14" height="14" rx="2.5" fill="white" stroke="#5D6E81"/>
														</svg>
													}
													checkedIcon={
														<svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
															<rect x="0.5" y="0.5" width="14" height="14" rx="2.5" fill="#2463EB" stroke="#2463EB"/>
															<path d="M4.5 7.5L6.5 9.5L10.5 5.5" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
														</svg>
													}
												/>
											}
											classes={{label: "!text-[13px] !font-['Poppins']"}}
											label={item.label}
										/>
									))}
								</FormGroup>
							</FormControl>
							{displayError('additionalDetails')}
						</div>
						<div className="mt-4 mx-auto">
							<button onClick={handleSubmit} type="submit" className="p-3 mr-6 rounded-lg font-semibold text-base leading-4 text-white cursor-pointer bg-[#5D6E81]">
								Send Message
							</button>
						</div>
					</div>
				)}
			</div>
		</div>
	)
}
export default ConciergeInquiryForm
