import { AsYouType } from 'libphonenumber-js';

export const handlePhoneNumberChange = ({
    event,
    formErrors,
    formData,
    updateFormData,
    region = 'US',
}) => {
    const e = event;

    if(!!e.target.value) {
        if(formErrors[e.target.name]) formErrors[e.target.name] = ''
    }

    e.preventDefault();
    let input = e.target.value;
    let cursorPos = e.target.selectionStart;
    if (input.length >= 14 && !input.startsWith('+')) {
        input = `+1${input}`;
        cursorPos += 1; // Adjust cursor position for the added "+"
    }
    let startsWithPlus = input.startsWith('+');

    if (startsWithPlus) {
        input = input.slice(1);
    }

    // Detect unbalanced parentheses
    const hasUnbalancedParentheses = str =>
        str.indexOf('(') !== -1 &&
        str.indexOf('(') === str.lastIndexOf('(') &&
        str.indexOf(')') === -1;

    if (hasUnbalancedParentheses(input)) {
        input = input.slice(0, -1); // Remove the extra digit causing unbalanced parentheses
    }

    try {
        let formattedNumber = input;

        // Use AsYouType formatter for dynamic formatting
        const asYouType = new AsYouType(region);
        formattedNumber = asYouType.input(input);

        // Ensure "+" prefix if necessary
        if (startsWithPlus) {
            formattedNumber = `+${formattedNumber}`;
        }

        // Calculate new cursor position based on the formatting changes
        const newPos = cursorPos + (formattedNumber.length - input.length);

        // Update the form data and set the cursor position
        updateFormData({
            ...formData,
            [e.target.name]: formattedNumber,
        });

        // Use a timeout to ensure cursor position is set after DOM updates
        setTimeout(() => {
            e.target.setSelectionRange(newPos, newPos);
        }, 0);
    } catch (error) {
        console.warn('Invalid phone number:', error);

        // Fallback: Use the unformatted input
        updateFormData({
            ...formData,
            [e.target.name]: input,
        });
    }
};
