import sanitizer from 'sanitizer'
import Divider from '@mui/material/Divider'
import {NavLink} from 'react-router-dom'
import Classnames from 'classnames'
import Tooltip from '@mui/material/Tooltip'
import React from 'react'
import {connect} from 'react-redux'
import qs from 'qs'
import ActionCable from 'actioncable'

import {withRouter} from '../../utils/withRouter'
import {Globals} from '../../Context'
import {addToast} from './../../app/appActions'
import ConfirmPopUp from '../ConfirmPopUp'
import * as collections from '../../utils/collections'
import {toYesNo} from '../../utils/LabelsHelper'
import UserProfileModal from '../UserProfileModal'
import {onPressEscape} from '../../utils/keyboardHandler'
import ShareWhatAppEmailDialog from '../Inventory/ShareWhatAppEmailDialog'
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft'
import CloseIcon from '@mui/icons-material/Close'
import NoResultsFound from './NoResultsFound'
import ConciergeInquiryForm from './ConciergeInquiryForm'
import PreviewImage from '../PreviewImage'
import FilterMarketplace from '../../Components/Marketplace/FilterMarketplace'
import findIndex from 'lodash/findIndex'
import includes from 'lodash/includes'
import isEmpty from 'lodash/isEmpty'
import head from 'lodash/head'
import lowerCase from 'lodash/lowerCase'
import find from 'lodash/find'
import isNaN from 'lodash/isNaN'
import isNull from 'lodash/isNull'
import InfiniteScroll from 'react-infinite-scroll-component'

export const buildSearchQuery = (marketplaceFilters) => {
	const searchValue = sanitizer.escapeAttrib(marketplaceFilters.searchKeywords?.toLowerCase())?.replaceAll('\\', '')
	let params = {
		q: {[Globals.marketplaceSearchParams]: searchValue}
	}

	if (marketplaceFilters.unwornPreOwned === 'unworn') {
		params['q']['condition_in'] = ['0']
	} else if (marketplaceFilters.unwornPreOwned === 'pre-owned') {
		params['q']['condition_in'] = ['1', '2', '3', '4', '5']
	}

	if (marketplaceFilters.box) {
		params['q']['box_eq'] = '1'
	}

	if (marketplaceFilters.papers) {
		params['q']['warranty_papers_not_eq'] = '0'
	}

	if (marketplaceFilters.specialFilters.type === 'brand') {
		params['q']['brand_i_cont'] = marketplaceFilters.specialFilters.brand
	}

	if (marketplaceFilters.specialFilters.type === 'newArrivals') {
		// nothing to do here, just get the list of watches and scroll to the selected watch
	}

	if (marketplaceFilters.specialFilters.type === 'popular') {
		params['q']['brand_i_cont'] = marketplaceFilters.specialFilters.brand
		params['q']['series_i_cont'] = marketplaceFilters.specialFilters.series
	}

	if (marketplaceFilters.specialFilters.type === 'space') {
		params['q']['space_id_eq'] = marketplaceFilters.specialFilters.spaceId
	}

	if (marketplaceFilters.filterPriorities.length > 0) {
		marketplaceFilters.filterPriorities.forEach((key) => {
			if (key === 'wholesale_price') {
				if (marketplaceFilters.filterDataSelected[key].length === 0) return
				params['q'][`${key}_min`] = marketplaceFilters.filterDataSelected[key][0]
				params['q'][`${key}_max`] = marketplaceFilters.filterDataSelected[key][1]
			} else {
				const value = marketplaceFilters.filterDataSelected[key]
				if (value && value.length > 0) {
					params['q'][key] = value
				}
			}
		})
	}

	const queryStr = qs.stringify(params, {arrayFormat: 'brackets'})

	return queryStr
}

let cableApp = {}

class MarketPlaceView extends React.Component {
	constructor() {
		super()
		this.addProductCountRef = React.createRef()
		this.state = {
			activeShareDropdow: null,

			openRows: [],
			checkedRows: [],
			loadingData: false,
			orderBy: 'created_at',
			order: 'DESC',
			offset: 1,
			dataProvider: [],
			originalDataProvider: [],
			visibleGallery: false,
			activeGalleryItem: null,

			visibleSendEmail: false,
			visibleContactSeller: false,
			sendingSendEmail: false,
			sendingContactSeller: false,
			mobileStatus: [],
			mobileButtonDisplay: [],

			selectedItemToShare: null,
			shareViaEmailForm: {
				emailAddress: '',
				emailContent: ''
			},
			contactSellerForm: {
				emailContent: '',
				product: null
			},

			searchString: '',
			isVisibleUserProfile: false,
			singleDataProvider: null,
			loadingMoreData: false,
			pagination: {
				totalEntries: 0
			},
			titleHeader: '',
			openDialogShare: false,
			shareMethod: 'email',
			dataEmail: {},
			visibleConciergeInquiryForm: false,
			image: null,
			loadingImage: false,
			loadingImageFirst: false,
			firstLoadFilterData: [],
			isSetFirstLoadFilterData: false,
			isShowSliderPriceFilter: false,
			filterData: []
		}
		this.listWrapperRef = React.createRef()
		this.MarketplaceList = React.createRef()
	}


	handleListWrapperResize = () => {
		this.setState({height: 0}, () => {
			setTimeout(() => {
				this.setState({height: this.listWrapperRef.current.clientHeight})
			}, 0)
		})
	}

	async componentDidMount() {
		this.handleSearch()
		window.addEventListener('resize', this.handleListWrapperResize)
		this.addProductCountRef.current = this.props.addProductCount
		window.addEventListener('keydown', onPressEscape((event) => this.setState({visibleSendEmail: false})))
		cableApp?.cable?.disconnect()
		cableApp.cable = ActionCable.createConsumer(`${Globals.actionCableURL}?access_token=${Globals.getAccessToken()}`)
		cableApp.cable.subscriptions.create({channel: 'MarketplaceChannel'}, {
			received: (data) => {
				if (data.action === 'create') {
					Globals.New_Axios()
						.get(`${Globals.NEW_API_URL}/${Globals.ENDPOINTVERSION}/spaces/${Globals.SELECTEDSPACEID}/watches/${data.watch_id}?marketplaces=true`)
						.then(async response => {
							if (this.props.activeTab === 'marketplace') {
								this.setState({
									dataProvider: [response.data, ...this.state.dataProvider],
									originalDataProvider: [response.data, ...this.state.dataProvider]
								})
							} else if (this.props.activeTab === 'home') {
								this.handleSearch()
							}
						}).catch(error => {
						console.log(error)
					})
				} else if (data.action === 'update') {
					Globals.New_Axios()
						.get(`${Globals.NEW_API_URL}/${Globals.ENDPOINTVERSION}/spaces/${Globals.SELECTEDSPACEID}/watches/${data.watch_id}?marketplaces=true`)
						.then(async response => {
							const index = findIndex(this.state.dataProvider, {id: response.data.id})
							if (index > -1) {
								this.state.dataProvider[index] = response.data
								this.state.dataProvider = this.state.dataProvider.filter(inventory => inventory.status !== 'sold_to' && !inventory._destroy)
								this.setState(
									{
										dataProvider: [...this.state.dataProvider],
										originalDataProvider: [...this.state.dataProvider]
									}
								)
							}
						}).catch(error => {
						console.log(error)
					})
				} else if (data.action === 'delete') {
					Globals.New_Axios()
						.get(`${Globals.NEW_API_URL}/${Globals.ENDPOINTVERSION}/spaces/${Globals.SELECTEDSPACEID}/watches/${data.watch_id}?marketplaces=true`)
						.then(async response => {
							const index = findIndex(this.state.dataProvider, {id: response.data.id})
							if (index > -1) {
								this.state.dataProvider = this.state.dataProvider.filter(inventory => inventory.id !== response.data.id)
								this.setState(
									{
										dataProvider: [...this.state.dataProvider],
										originalDataProvider: [...this.state.dataProvider]
									}
								)
							}
						}).catch(error => {
						console.log(error)
					})
				}
			}
		})
	}

	handleShowPriceSlider = () => {
		this.setState({isShowSliderPriceFilter: !this.state.isShowSliderPriceFilter})
	}

	async componentDidUpdate(prevProps) {
		if (this.state.height !== this.listWrapperRef.current.clientHeight) {
			this.setState({height: this.listWrapperRef.current.clientHeight})
		}

		const {marketplaceFilters: previousMarketplaceFilters} = prevProps
		const {marketplaceFilters} = this.props

		if (marketplaceFilters.triggerRenderer !== previousMarketplaceFilters.triggerRenderer) {
			this.handleSearch()
		}
	}

	handleSearch = async () => {
		if (this.state.loadingData) return
		const {marketplaceFilters} = this.props

		this.setState(
			{
				searchString: buildSearchQuery(marketplaceFilters),
				offset: 1
			},
			() => {
				this.handleLoadFirstData()
			}
		)
	}

	handleFilterBySpace = (item) => {
		const {setMarketplaceFilters} = this.props
		const newState = {
			activeTab: 'marketplace',
			specialFilters: {
				type: 'space',
				title: item.name,
				spaceId: item.spaceId
			}
		}
		setMarketplaceFilters(newState)
	}

	toggleCollapsedRow = itemId => {
		let openRows = this.state.openRows
		if (includes(openRows, itemId)) {
			return this.setState({
				openRows: openRows.filter(row => row !== itemId)
			})
		}

		// track the item that is open
		this.trackingUserAction('expand_row', itemId)

		return this.setState({
			openRows: [...openRows, itemId]
		})
	}

	handleContactSellerNavigation = (data, event) => {
		event.preventDefault()

		const {navigate} = this.props
		this.trackingUserAction('contact_seller', data.id)

		navigate(`/messages/reachout/${data.id}`)
	}

	handleCheckAvailabilityNavigation = (data, event) => {
		event.preventDefault()

		const {navigate} = this.props
		this.trackingUserAction('check_availability', data.id)

		navigate(`/messages/reachout/${data.id}/availability`)
	}

	handleContactForPriceNavigation = (data, event) => {
		event.preventDefault()

		const {navigate} = this.props
		this.trackingUserAction('contact_for_price', data.id)

		navigate(`/messages/reachout/${data.id}/price`)
	}

	trackingUserAction = (action, itemId) => {
		Globals.New_Axios()
			.post(`${Globals.NEW_API_URL}/${Globals.ENDPOINTVERSION}/spaces/${Globals.SELECTEDSPACEID}/marketplace_trackings`, {
				action: action,
				watch_id: itemId
			})
			.then(response => {
			})
			.catch(error => {
			})
	}

	handleInjectingRow = itemId => {
		let checkedRows = this.state.checkedRows
		if (checkedRows.includes(itemId)) {
			//remove it from the rows checked.
			return this.setState({
				checkedRows: checkedRows.filter(row => row !== itemId)
			})
		}

		return this.setState({
			checkedRows: [...checkedRows, itemId]
		})
	}

	handleCheckboxAllRows(event) {
		const isChecked = event.target.checked
		if (isChecked) {
			return this.setState({
				checkedRows: this.state.dataProvider.map(data => data.id)
			})
		}

		return this.setState({checkedRows: []})
	}

	handleLoadFirstData = async (isScroll = false) => {
		const state = this.state
		const {marketplaceFilters} = this.props

		if (state.loadingData || state.offset === null) return

		if (isScroll) this.setState({loadingMoreData: true})
		else this.setState({loadingData: true})
		this.props.handleDisableInputSearch(true)

		let sortOrder = `q[s]=${this.state.orderBy} ${this.state.order}&page=${state.offset}&per_page=${50}`

		try {
			const response = await Globals.New_Axios().get(
				`${Globals.NEW_API_URL}/${Globals.ENDPOINTVERSION}/spaces/${Globals.SELECTEDSPACEID}/marketplaces?${sortOrder}&${state.searchString}&active_tab=${marketplaceFilters.activeTab}`
			)

			this.setState({
				loadingMoreData: false,
				loadingData: false,
				showEmptyResult: isEmpty(response.data.watches)
			}, () => {
				this.props.handleDisableInputSearch(false)
			})

			const {filters_data, watches, current_page, next_page, total_entries} = response.data

			if (!state.isSetFirstLoadFilterData) {
				this.setState({isSetFirstLoadFilterData: true, firstLoadFilterData: filters_data})
			}

			this.setState({filterData: filters_data || []})

			if (watches.length > 0) {
				if (current_page === 1) {
					this.setState({
						dataProvider: watches,
						originalDataProvider: watches,
						offset: next_page,
						pagination: {
							totalEntries: total_entries
						}
					}, () => {
						this.scrollToWatch(isScroll)
					})
				} else {
					this.setState({
						dataProvider: [...state.dataProvider, ...watches],
						originalDataProvider: [...state.originalDataProvider, ...watches],
						offset: next_page,
						pagination: {
							totalEntries: total_entries
						}
					}, () => {
						this.scrollToWatch(isScroll)
					})
				}
			} else {
				this.setState({
					loadingData: false,
					showEmptyResult: isEmpty(watches),
					dataProvider: [],
					originalDataProvider: []
				})
			}
		} catch (error) {
			console.log(error)
		}
	}

	scrollToWatch = (isScroll) => {
		const {marketplaceFilters: {specialFilters}} = this.props

		if (isScroll) return

		if (specialFilters.type === 'newArrivals' && specialFilters.watchId) {
			const watchElement = document.getElementById(`item-${specialFilters.watchId}`)
			const scrollableElement = this.MarketplaceList.current.el
			if (watchElement && scrollableElement && watchElement.offsetTop > 130) {
				scrollableElement.scrollTo({
					top: watchElement.offsetTop - 130,
					behavior: 'smooth'
				})

				this.toggleCollapsedRow(specialFilters.watchId)
			}
		}
	}

	loadMoreData = async () => {
		this.handleLoadFirstData(true)
	}

	openShareWhatsAppDialog = async (data) => {
		this.setState({
			openDialogShare: true,
			selectedItemToShare: data,
			titleHeader: 'Send Via Whatsapp-Preview',
			shareMethod: 'whatapp'
		})
	}

	displayConciergeInquiryForm = (value) => {
		this.setState({visibleConciergeInquiryForm: value})
	}

	shareWhatsApp = async (data, element) => {
		element.preventDefault()
		element.stopPropagation()
		window.open(`https://api.whatsapp.com/send/?text=ELEFTA | Dealer SKU:${data.sku} Click For Full Details: ${Globals.shareDomain}/inventory/share/item/${data.uid}`, '_blank')
	}

	saveItem = async (data, element) => {
		element.preventDefault()
		element.stopPropagation()

		let dataProvider = this.state.dataProvider

		dataProvider = dataProvider.map(_data => {
			if (_data.id === data.id) {
				_data.saved = !data.saved
			}

			return _data
		})

		this.setState({
			dataProvider: dataProvider
		})

		await Globals.New_Axios()
			.post(`${Globals.NEW_API_URL}/${Globals.ENDPOINTVERSION}/spaces/${Globals.SELECTEDSPACEID}/watches/${data.id}/toggle_saved_watch`)
			.then(response => {
				if (response.data.is_created) {
					return this.props.addToast({
						title: 'Item saved to your list',
						type: 'success'
					})
				}

				return this.props.addToast({
					title: 'Item removed from your list',
					type: 'success'
				})
			})
			.catch(error => {
				return this.props.addToast({
					text: 'Something went wrong please try again later.',
					type: 'error'
				})
			})
	}

	openGalleryItems = async data => {
		if (head(data.images) !== undefined) {
			this.setState({
				activeGalleryItem: data.images,
				visibleGallery: true,
				image: data.images[0],
				loadingImage: true,
				loadingImageFirst: true
			})
		}
	}

	closeGallery = () => {
		this.setState({
			visibleGallery: false,
			activeGalleryItem: null,
			image: null,
			loadingImage: false,
			loadingImageFirst: false
		})
	}

	paginateSlider = direction => {
		let images = this.state.activeGalleryItem
		const index = findIndex(images, (image) => image.url === this.state.image.url)
		if (direction === 'previous') {
			this.setState({
				image: index === 0 ? images[images.length - 1] : images[index - 1],
			})
		} else {
			this.setState({
				image: index === images.length - 1 ? images[0] : images[index + 1],
			})
		}
	}

	openEmailDialog = data => {
		this.setState({
			openDialogShare: true,
			selectedItemToShare: data,
			activeShareDropdow: null,
			titleHeader: 'Send Via Email-Preview',
			shareMethod: 'email'
		})
	}

	openShareViaEmail = data => {
		this.setState({
			visibleSendEmail: true,
			selectedItemToShare: data,
			activeShareDropdow: null
		})
	}

	shareViaEmail = () => {
		const sendingSendEmail = this.state.sendingSendEmail,
			selectedItemToShare = this.state.selectedItemToShare,
			shareViaEmailForm = this.state.shareViaEmailForm

		if (sendingSendEmail) return

		if (!Globals.validateEmail(shareViaEmailForm.emailAddress)) {
			return this.props.addToast({
				text: 'Invalid email address',
				type: 'error'
			})
		}

		if (shareViaEmailForm.emailContent?.length > 2000) {
			return this.props.addToast({
				text: 'Your additional details field reached the maximum limit',
				type: 'error'
			})
		}

		this.setState({sendingSendEmail: true})

		Globals.New_Axios()
			.post(`${Globals.NEW_API_URL}/${Globals.ENDPOINTVERSION}/spaces/${Globals.SELECTEDSPACEID}/marketplaces/${selectedItemToShare.id}/share`, {
				email: shareViaEmailForm.emailAddress,
				//product: selectedItemToShare.id,
				additional_detail: shareViaEmailForm.emailContent,
				...this.state.dataEmail,
				share_method: 'email'
			})
			.then(response => {
				this.setState({sendingSendEmail: false})
				if (response.status && response.status >= 200 && response.status < 300) {
					this.setState({
						visibleSendEmail: false,
						sendingSendEmail: false,
						selectedItemToShare: null,
						shareViaEmailForm: {
							emailAddress: '',
							emailContent: ''
						}
					})
				} else {
					return this.props.addToast({
						text: 'Something went wrong please try again later.',
						type: 'error'
					})
				}

				return this.props.addToast({
					title: 'Email has been sent',
					type: 'success'
				})
			})
			.catch(error => {
				this.setState({sendingSendEmail: false})
				return this.props.addToast({
					text: 'Something went wrong please try again later.',
					type: 'error'
				})
			})
	}

	contactSeller = () => {
		if (this.state.sendingContactSeller) return

		let {emailContent, product} = this.state.contactSellerForm

		if (isEmpty(emailContent)) return

		this.setState({
			sendingContactSeller: true
		})

		Globals.Axios()
			.post(`${Globals.wsurl}/inventory/share/marketplace/contact/seller`, {
				content: emailContent,
				product
			})
			.then(res => {
				this.setState({sendingContactSeller: false})

				if (res.data.status) {
					this.setState({
						visibleContactSeller: false,
						contactSellerForm: {
							emailContent: '',
							product: null
						}
					})
					return this.props.addToast({
						title: 'Email has been sent',
						type: 'success'
					})
				}

				return this.props.addToast({
					text: 'Something went wrong please try again later.',
					type: 'error'
				})
			})
			.catch(error => {
				this.setState({sendingSendEmail: false})
				return this.props.addToast({
					text: 'Something went wrong please try again later.',
					type: 'error'
				})
			})
	}

	handleClearSpecialFilter = () => {
		const {setMarketplaceFilters, marketplaceFilters, searchInputRef} = this.props
		this.setState(
			{
				offset: 1
			},
			() => {
				const newMarketplaceFilters = {
					specialFilters: {
						type: null,
						title: null,
						watchId: null,
						spaceId: null
					}
				}
				if (marketplaceFilters.activeTab === 'home') {
					// in case user searching by brand, and then goes back to home.
					// then they visit marketplace again tab, then they will see the filter buttons are active
					// this code is for clearing the filter buttons
					newMarketplaceFilters['searchKeywords'] = ''
					newMarketplaceFilters['unwornPreOwned'] = null
					newMarketplaceFilters['papers'] = false
					newMarketplaceFilters['box'] = false
					newMarketplaceFilters['searchKeywords'] = ''
				}
				setMarketplaceFilters(newMarketplaceFilters)
			}
		)
	}

	handleDropDownShare = data => {
		this.setState({
			activeShareDropdow: data === this.state.activeShareDropdow ? null : data
		})
	}

	handleMobileHideShow = itemId => {
		let mobileButtonDisplay = this.state.mobileButtonDisplay
		if (includes(mobileButtonDisplay, itemId)) {
			return this.setState({
				mobileButtonDisplay: mobileButtonDisplay.filter(row => row !== itemId)
			})
		}

		return this.setState({
			mobileButtonDisplay: [...mobileButtonDisplay, itemId]
		})
	}

	truncateString = (str, maxLength) => {
		if (str) {
			if (str.length <= maxLength) {
				return str
			} else {
				return str.substring(0, maxLength) + '...'
			}
		}
	}

	setMobileStatus = itemId => {
		let mobileButtonDisplay = this.state.mobileStatus
		if (includes(mobileButtonDisplay, itemId)) {
			return this.setState({
				mobileStatus: mobileButtonDisplay.filter(row => row !== itemId)
			})
		}

		return this.setState({
			mobileStatus: [...mobileButtonDisplay, itemId]
		})
	}

	watchWarrantyPapers = watch => {
		const warrantyCardsList = Globals.warrantyCardsList[lowerCase(watch.brand)] || Globals.warrantyCardsList.other
		return find(warrantyCardsList, {value: watch.warranty_papers})?.option
	}

	watchCondition = watch => {
		const watchCondition = find(collections.inventoryConditionsCollection, {value: watch.condition})
		return watchCondition?.option
	}

	handleCloseUserProfileModal = () => {
		this.setState({isVisibleUserProfile: false})
	}

	handleOpenUserProfileModal = (data) => {
		this.setState({isVisibleUserProfile: true})
		this.setState({singleDataProvider: data})
	}

	handleVisibleDialog = (isVisible) => {
		this.setState({openDialogShare: isVisible})
	}

	buildMessageWhatApp = (imageUrl, data) => {
		const spaceData = JSON.parse(Globals.Cache.get(Globals.USERSELECTEDSPACE))
		const {selectedItemToShare} = this.state
		let string = `${spaceData.name} shared a watch with you! %0A
		Dealer SKU: ${selectedItemToShare.sku}%0A`
		if (data.selected_attributes.indexOf('reference_number') > -1) {
			string = string + `Reference: ${selectedItemToShare.reference_number}%0A`
		}
		if (data.selected_attributes.indexOf('wholesale_price') > -1) {
			const price = data.custom_price ? data.custom_price : ''
			string = string + `Price: ${Globals.formatCurrency(price)}%0A`
		}
		return string = string + `Full Details: ${encodeURIComponent(imageUrl)}`
	}


	handleSubmitShare = (buildDatas, formData) => {
		const {shareMethod, selectedItemToShare} = this.state
		let selectedAttributes = []
		buildDatas.map(value => {
			if (value.visible) {
				selectedAttributes.push(value.key)
			}
		})
		if (shareMethod === 'email') {
			this.setState({
				dataEmail: {
					selected_attributes: selectedAttributes,
					custom_price: !!formData.wholesale_price ? parseFloat(Globals.formatCurrency(formData.wholesale_price).replaceAll(',', '')) : null
				}
			})
			this.setState({visibleSendEmail: true})
		} else {
			Globals.New_Axios()
				.post(`${Globals.NEW_API_URL}/${Globals.ENDPOINTVERSION}/spaces/${Globals.SELECTEDSPACEID}/marketplaces/${selectedItemToShare.id}/share`, {
					selected_attributes: selectedAttributes,
					custom_price: !!formData.wholesale_price ? parseFloat(Globals.formatCurrency(formData.wholesale_price).replaceAll(',', '')) : null,
					share_method: 'whatsapp'
				})
				.then(res => {
					const imageUrl = `${Globals.NEW_API_URL}/${Globals.ENDPOINTVERSION}/marketplaces/shared_watch_preview?sharing_code=${res.data.code}`
					const message = this.buildMessageWhatApp(imageUrl, res.data)
					window.open(`https://api.whatsapp.com/send/?text=${message}`, '_blank')
				})
				.catch(error => {
				})
		}
		this.handleVisibleDialog(false)
	}

	componentWillUnmount() {
		window.removeEventListener('keydown', onPressEscape((event) => this.setState({visibleSendEmail: false})))
		window.removeEventListener('resize', this.handleListWrapperResize)

	}

	handleLoadingImage = () => {
		this.setState({loadingImage: false, loadingImageFirst: false})
	}
	visibleFields = (data) => {
		return data.space.global_watch_column_configs.filter(item => item.visible).map(item => item.key)
	}

	setHeightMarkteplaceList = () => {
		if(this.state.loadingData || isEmpty(this.state.dataProvider)) {
			return '0px'
		}

		return `${this.state.height || 0}px`
	}

	render() {
		const state = this.state
		const props = this.props
		// const selectedItem = props.selectedItem
		const {
			marketplaceFilters
		} = this.props

		const isVisible = ['owner', 'admin', 'editor'].includes(props.profile?.role)
		props.handleProductCount(state.dataProvider.length)

		return (
			<div style={{flex: 1, display: 'flex', flexDirection: 'column'}}>
				{marketplaceFilters && !isEmpty(marketplaceFilters.specialFilters.type) &&
					<div className="px-[50px] bg-white mb-[20px]">
						<div className="mt-[40px]">
							{marketplaceFilters.specialFilters.type === 'space' ? (
								<div className="flex items-center">
									<KeyboardArrowLeftIcon className="cursor-pointer"
														   onClick={this.handleClearSpecialFilter} />
									<h3 className="text-[24px] font-bold">{marketplaceFilters.specialFilters.title}</h3>
								</div>
							) : (
								<div className="flex justify-between items-center gap-4">
									<h3 className="text-[24px] font-bold">Shop {marketplaceFilters.specialFilters.title}</h3>
									<CloseIcon className="cursor-pointer" onClick={this.handleClearSpecialFilter} />
								</div>
							)}
							<Divider className="my-4 h-1" />
						</div>
					</div>
				}
				<React.Fragment>
					{props.loggedin && (
						<React.Fragment>
							{
								this.props.activeTab === 'marketplace' &&
								<FilterMarketplace
									handleSetCurrentDropdownFilter={this.props.handleSetCurrentDropdownFilter}
									currentDropdownFilter={this.props.currentDropdownFilter}
									onChangeFilterData={this.props.onChangeFilterData}
									handleShowPriceSlider={this.handleShowPriceSlider}
									filterData={this.state.filterData}
									firstLoadFilterData={this.state.firstLoadFilterData}
									isShowSliderPriceFilter={this.state.isShowSliderPriceFilter}
								/>
							}
							{
								<div className="flex flex-wrap items-center max-w-full px-20 mt-2 gap-2">
									{this.props.isPresentFilterDataSelected() && (
										<>
											<div className="italic text-xs flex-shrink-0">Filtered By:</div>
											{Object.keys(this.props.marketplaceFilters.filterDataSelected).flatMap(key => (
												key === 'wholesale_price' ? (
														<>
															{this.props.marketplaceFilters.filterDataSelected[key].length > 0 &&
																<div className="flex items-center italic text-xs">
																	<span
																		className="mr-1">{`$${this.props.marketplaceFilters.filterDataSelected[key].join('-')}`}</span>
																	<button
																		className="font-bold italic ml-2"
																		onClick={() => {
																			this.props.handleSetCurrentDropdownFilter()
																			this.setState({isShowSliderPriceFilter: false})
																			this.props.onChangeFilterData(key, this.props.marketplaceFilters.filterDataSelected[key][0], true)
																		}}
																	>
																		X
																	</button>
																</div>
															}
														</>
													) :
													this.props.marketplaceFilters.filterDataSelected[key].map((item, index) => (
														<div key={`${key}-${item}-${index}`}
															 className="flex items-center px-3 py-1 rounded-md italic text-xs">
															<span className="mr-1">{item}</span>
															<button
																className="font-bold italic ml-2"
																onClick={() => this.props.onChangeFilterData(key, item)}
															>
																X
															</button>
														</div>
													))
											))}
											<div
												className="italic text-[10px] flex-shrink-0 cursor-pointer hover:font-bold underline"
												onClick={() => {
													this.setState({isShowSliderPriceFilter: false})
													this.props.handleSetCurrentDropdownFilter()
													this.props.handleClearAllFilter()
												}}
											>
												RESET FILTERS
											</div>
										</>
									)}
								</div>
							}
							<div
								className="overflow-x-auto 2xl:mx-0 lg:mx-0 md:mx-0 2xl:mb-0 sm:mx-4 xs:mx-4 xxs:mx-4 h-max"
								style={{flex: 1, display: 'flex', flexDirection: 'column'}}
							>
								<div
									style={{flex: 1}}
									ref={this.listWrapperRef}
								>
									<InfiniteScroll
										key={this.state.height}
										ref={this.MarketplaceList}
										loader={() => null}
										dataLength={state.dataProvider.length} //This is important field to render the next data
										next={this.loadMoreData}
										height={this.setHeightMarkteplaceList()}
										scrollThreshold={'50%'}
										hasMore={true}
									>
										{state.dataProvider.map((data, index) => {
											return (
												<React.Fragment key={data.id}>
													<div id={`item-${data.id}`}
														 className={`2xl:block lg:block md:block sm:hidden xs:hidden xxs:hidden item-${data.id}`}
														 key={data.id}>
														<div className={
															Classnames('flex p-5 text-sm font-medium border-b',
																{
																	'bg-gray-50': index % 2
																}
															)}
														>
															<div className="px-2">
																{' '}
																{/*Items left side*/}
																<div
																	className="bg-center bg-contain bg-gray-100 bg-no-repeat cursor-pointer h-16 md:h-24 m-auto w-16 md:w-24"
																	style={{
																		backgroundImage: head(data.images) !== undefined ? `url('${head(data.images).service_url}')` : `url('/watch_icon.png')`
																	}}
																	onClick={this.openGalleryItems.bind(this, data)}
																></div>
															</div>
															{' '}
															{/*End left item side*/}
															<div className="w-full cursor-pointer">
																{' '}
																{/*Items right side*/}
																<div className="grid grid-cols-4 gap-2">
																	<div
																		className="col-span-3 px-4 border-gray-100 border-r"
																		onClick={this.toggleCollapsedRow.bind(this, data.id)}>
																		<div
																			className="font-bold mb-1 text-xs md:text-base">{data.marketplace_name}</div>
																		<div id="price" className="flex align-top">
																			{
																				this.visibleFields(data).includes('wholesale_price') && (
																					<div>
																						{data.wholesale_price_formatted ?
																							data.wholesale_price_formatted
																							:
																							data.space_id !== props.currentSpace.id ?
																								(
																									['owner', 'admin', 'editor'].includes(this.props.profile.role) ?
																										<Tooltip
																											placement="left-start"
																											arrow>
																											<div
																												className="mt-1 font-bold">
																												<NavLink
																													to={`/messages/reachout/${data.id}/price`}
																													className="align-text-top text-blue-500 text-xs"
																													onClick={this.handleContactForPriceNavigation.bind(this, data)}
																												>
																													Contact
																													for
																													price
																												</NavLink>
																											</div>
																										</Tooltip> :
																										<div
																											className="mt-1 font-bold text-xs">
																											Contact for
																											price
																										</div>

																								)
																								:
																								(
																									<div
																										className="mt-1 font-bold text-xs">
																										Contact for price
																									</div>
																								)
																						}
																					</div>
																				)
																			}
																			{data.status === 'on_hold_for' && (
																				<div>
																					<Tooltip
																						title="This item is on hold"
																						placement="bottom" arrow>
																					<span
																						className="inline mx-1 text-orange-500">
																						<svg
																							xmlns="http://www.w3.org/2000/svg"
																							fill="none"
																							viewBox="0 0 24 24"
																							strokeWidth={1.5}
																							stroke="currentColor"
																							className="w-6 h-6"
																							style={{
																								display: 'initial',
																								verticalAlign: 'top'
																							}}
																						>
																							<path
																								strokeLinecap="round"
																								strokeLinejoin="round"
																								d="M11.25 11.25l.041-.02a.75.75 0 011.063.852l-.708 2.836a.75.75 0 001.063.853l.041-.021M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9-3.75h.008v.008H12V8.25z"
																							/>
																						</svg>
																					</span>
																					</Tooltip>
																				</div>
																			)}

																			{data.status === 'pending_sale_to' && (
																				<div>
																					<Tooltip
																						title="This item is pending sale"
																						placement="bottom" arrow>
																					<span
																						className="inline mx-1 text-red-800">
																						<svg
																							xmlns="http://www.w3.org/2000/svg"
																							fill="none"
																							viewBox="0 0 24 24"
																							strokeWidth={1.5}
																							stroke="currentColor"
																							className="w-6 h-6"
																							style={{
																								display: 'initial',
																								verticalAlign: 'top'
																							}}
																						>
																							<path
																								strokeLinecap="round"
																								strokeLinejoin="round"
																								d="M11.25 11.25l.041-.02a.75.75 0 011.063.852l-.708 2.836a.75.75 0 001.063.853l.041-.021M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9-3.75h.008v.008H12V8.25z"
																							/>
																						</svg>
																					</span>
																					</Tooltip>
																				</div>
																			)}
																		</div>

																		<div
																			className="lg:mt-8 text-xs grid grid-cols-1 md:grid-cols-2 md:gap-1 lg:grid-cols-3 lg:gap-2 visible">
																			{
																				this.visibleFields(data).includes('reference_number') && (
																					<div>
																						<span className="mr-1.5">Reference Number:</span>
																						<span
																							className="font-bold">{data.reference_number}</span>
																					</div>
																				)
																			}
																			{
																				this.visibleFields(data).includes('brand') && (
																					<div>
																						<span
																							className="mr-1.5">Brand:</span>
																						<span
																							className="font-bold">{Globals.getBrandName(data.brand)}</span>
																					</div>
																				)
																			}
																			{
																				this.visibleFields(data).includes('series') && (
																					<div>
																						<span
																							className="mr-1.5">Series:</span>
																						<span
																							className="font-bold">{data.series}</span>
																					</div>
																				)
																			}
																			{
																				this.visibleFields(data).includes('serial_number') && (
																					<div>
																						<span className="mr-1.5">Serial Number:</span>
																						<span
																							className="font-bold">{`${data.serial_number ? data.serial_number.slice(0, 3) : ''}*****`}</span>
																					</div>
																				)
																			}
																			{
																				this.visibleFields(data).includes('condition') && (
																					<div>
																						<span
																							className="mr-1.5">Condition:</span>
																						<span
																							className="font-bold">{this.watchCondition(data)}</span>
																					</div>
																				)
																			}
																			{
																				this.visibleFields(data).includes('dial') && (
																					<div>
																						<span
																							className="mr-1.5">Dial:</span>
																						<span
																							className="font-bold">{data.dial}</span>
																					</div>
																				)
																			}
																		</div>

																		<div
																			className={Classnames('mt-3 text-xs grid grid-cols-3 gap-2', {
																				hidden: !includes(state.openRows, data.id)
																			})}
																		>
																			{
																				this.visibleFields(data).includes('bezel') && (
																					<div>
																						<span
																							className="mr-1.5">Bezel:</span>
																						<span
																							className="font-bold">{data.bezel}</span>
																					</div>
																				)
																			}
																			{
																				this.visibleFields(data).includes('bracelet') && (
																					<div>
																						<span
																							className="mr-1.5">Bracelet:</span>
																						<span
																							className="font-bold">{data.bracelet}</span>
																					</div>
																				)
																			}
																			{
																				this.visibleFields(data).includes('warranty_papers') && (
																					<div>
																						<span className="mr-1.5">Warranty Paper:</span>
																						<span
																							className="font-bold">{this.watchWarrantyPapers(data)}</span>
																					</div>
																				)
																			}
																			{
																				this.visibleFields(data).includes('warranty') && (
																					<div>
																						<span className="mr-1.5">Warranty Date:</span>
																						<span
																							className="font-bold">{data.display_warranty}</span>
																					</div>
																				)
																			}
																			{
																				this.visibleFields(data).includes('box') && (
																					<div>
																						<span className="mr-1.5">Box:</span>
																						<span
																							className="font-bold">{toYesNo(data.box)}</span>
																					</div>
																				)
																			}
																			{
																				this.visibleFields(data).includes('link_count') && (
																					<div>
																						<span
																							className="mr-1.5">Link Count:</span>
																						<span
																							className="font-bold">{data.link_count}</span>
																					</div>
																				)
																			}
																			{
																				this.visibleFields(data).includes('addition_details') && (
																					<div
																						className="border-t col-span-3 mt-2 pt-2">
																						<h3 className="text-lg font-bold">Additional
																							Details</h3>
																						<p>{!isEmpty(data.addition_details) ? data.addition_details : 'N/A'}</p>
																					</div>
																				)
																			}
																			{this.visibleFields(data).includes('sku') && !isEmpty(data.sku) &&
																				<div>
																					<span
																						className="mr-1.5">DealerSKU:</span>
																					<span
																						className="font-bold">{data.sku}</span>
																				</div>
																			}
																		</div>
																	</div>
																	<div className="grid grid-cols-5 pl-4">
																		<div className="col-span-4">
																			<div className="seller-info">
																				<span>From: </span>
																				<span
																					onClick={() => this.handleOpenUserProfileModal(data)}
																					className="text-blue-500">{data?.space?.name}
																			</span>
																			</div>
																			<div className="mt-2 space-y-2">
																				{isVisible && (
																					<div>
																						<Tooltip title="Save this item"
																								 placement="left-start"
																								 arrow>
																							<div className="space-x-2"
																								 onClick={this.saveItem.bind(this, data)}>
																								<button
																									className={Classnames('p-1 rounded transition-all', {
																										'bg-red-500 text-red-100': data?.saved,
																										'bg-red-100 text-red-500': !data?.saved
																									})}
																								>
																								<span>
																									<svg
																										className="w-3.5 h-3.5"
																										fill="currentColor"
																										viewBox="0 0 20 20"
																										xmlns="http://www.w3.org/2000/svg">
																										<path
																											d="M5 4a2 2 0 012-2h6a2 2 0 012 2v14l-5-2.5L5 18V4z" />
																									</svg>
																								</span>
																								</button>
																								<span
																									className="align-text-top text-blue-500 text-xs">{data?.saved ? 'Saved' : 'Save'}</span>
																							</div>
																						</Tooltip>
																					</div>
																				)}
																				{isVisible && (
																					<div className="relative">
																						<Tooltip title="Share"
																								 placement="left-start"
																								 arrow>
																							<div className="space-x-2"
																								 onClick={this.handleDropDownShare.bind(this, data.id)}>
																								<button
																									className="bg-blue-100 p-1 rounded text-blue-500">
																								<span>
																									<svg
																										className="w-3.5 h-3.5"
																										fill="currentColor"
																										viewBox="0 0 20 20"
																										xmlns="http://www.w3.org/2000/svg">
																										<path
																											fillRule="evenodd"
																											d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
																											clipRule="evenodd"
																										/>
																									</svg>
																								</span>
																								</button>
																								<span
																									className="align-text-top text-blue-500 text-xs">Share</span>
																							</div>
																						</Tooltip>
																						{state.activeShareDropdow === data.id && (
																							<React.Fragment>
																								<div
																									className="fixed top-0 bottom-0 left-0 right-0 bg-transparent z-10"
																									onClick={this.handleDropDownShare.bind(this, data.id)}
																								></div>
																								<div
																									className="absolute z-20 w-44 bg-white rounded divide-y divide-gray-100 shadow p-2">
																									<div
																										className="py-1">
																										<Tooltip
																											title="Share via email"
																											placement="left-start"
																											arrow>
																											<div
																												onClick={this.openEmailDialog.bind(this, data)}
																												className="space-x-2">
																												<button
																													className="bg-blue-100 p-1 rounded text-blue-500">
																													<svg
																														className="w-3.5 h-3.5"
																														fill="currentColor"
																														viewBox="0 0 20 20"
																														xmlns="http://www.w3.org/2000/svg"
																													>
																														<path
																															fillRule="evenodd"
																															d="M14.243 5.757a6 6 0 10-.986 9.284 1 1 0 111.087 1.678A8 8 0 1118 10a3 3 0 01-4.8 2.401A4 4 0 1114 10a1 1 0 102 0c0-1.537-.586-3.07-1.757-4.243zM12 10a2 2 0 10-4 0 2 2 0 004 0z"
																															clipRule="evenodd"
																														/>
																													</svg>
																												</button>
																												<span
																													className="align-text-top text-blue-500 text-xs">Email</span>
																											</div>
																										</Tooltip>
																									</div>
																									<div
																										className="py-1">
																										<Tooltip
																											title="Share via WhatsApp"
																											placement="left-start"
																											arrow>
																											<div
																												className="space-x-2"
																												onClick={this.openShareWhatsAppDialog.bind(this, data)}>
																												<button
																													className="bg-green-100 p-1 rounded text-green-500">
																													<svg
																														className="w-3.5 h-3.5"
																														viewBox="0 0 15.96 16.1"
																														fill="currentColor"
																														xmlns="http://www.w3.org/2000/svg"
																													>
																														<path
																															d="M15.74,6.18C14.86,2.53,11.74,.04,7.96,0c-.5,0-1.02,.05-1.53,.16C1.16,1.23-1.57,7.1,1,11.83c.07,.13,.07,.23,.03,.36C.69,13.44,.35,14.69,.01,15.93c-.04,.14,.01,.19,.15,.15,1.29-.34,2.58-.68,3.87-1.02,.11-.03,.19-.02,.29,.03,1.66,.85,3.41,1.1,5.24,.75,4.37-.85,7.23-5.32,6.19-9.66h0Zm-6.66,8.38c-.36,.06-.72,.09-.91,.09-1.4,0-2.54-.32-3.6-.96-.13-.08-.25-.1-.41-.06-.71,.2-1.42,.37-2.13,.56-.15,.05-.16,.01-.13-.12,.19-.68,.36-1.35,.56-2.01,.05-.19,.03-.33-.07-.5-1.45-2.35-1.39-5.27,.23-7.44C4.32,1.86,6.62,.93,9.37,1.52c2.71,.57,4.44,2.33,5.07,5.03,.87,3.7-1.63,7.38-5.36,8Z" />
																														<path
																															d="M10.05,11.84c-.28,.01-.56-.05-.83-.13-1.83-.53-3.2-1.68-4.31-3.19-.42-.57-.8-1.16-.93-1.87-.15-.85,.09-1.57,.72-2.16,.2-.18,.81-.27,1.06-.15,.09,.05,.15,.13,.19,.21,.23,.55,.46,1.1,.69,1.65,.04,.11,.02,.21-.02,.32-.12,.25-.3,.46-.49,.66-.14,.15-.15,.28-.04,.46,.65,1.08,1.54,1.87,2.71,2.34,.17,.07,.31,.05,.42-.1,.19-.24,.4-.48,.58-.73,.13-.18,.27-.2,.45-.12,.39,.18,.77,.36,1.16,.54,.09,.04,.17,.08,.26,.12,.39,.2,.39,.2,.35,.63-.07,.85-.7,1.27-1.45,1.47-.17,.04-.34,.05-.51,.04h0Z" />
																													</svg>
																												</button>
																												<span
																													className="align-text-top text-blue-500 text-xs">WhatsApp</span>
																											</div>
																										</Tooltip>
																									</div>
																								</div>
																							</React.Fragment>
																						)}
																					</div>
																				)}
																				{data.space_id !== props.currentSpace.id && isVisible && (
																					<div>
																						<Tooltip
																							title="Check Availability"
																							placement="left-start"
																							arrow>
																							<NavLink
																								to={`/messages/reachout/${data.id}/availability`}
																								className="space-x-2 align-text-top text-blue-500 text-xs"
																								onClick={this.handleCheckAvailabilityNavigation.bind(this, data)}
																							>
																								<button
																									className="bg-blue-100 p-1 rounded text-blue-500">
																									<svg
																										className="w-3.5 h-3.5"
																										fill="currentColor"
																										viewBox="0 0 20 20"
																										xmlns="http://www.w3.org/2000/svg">
																										<path
																											fillRule="evenodd"
																											d="M14.243 5.757a6 6 0 10-.986 9.284 1 1 0 111.087 1.678A8 8 0 1118 10a3 3 0 01-4.8 2.401A4 4 0 1114 10a1 1 0 102 0c0-1.537-.586-3.07-1.757-4.243zM12 10a2 2 0 10-4 0 2 2 0 004 0z"
																											clipRule="evenodd"
																										/>
																									</svg>
																								</button>
																								<span>
																								Check Availability
																							</span>
																							</NavLink>
																						</Tooltip>
																					</div>
																				)}
																				{data.space_id !== props.currentSpace.id && isVisible && (
																					<div>
																						<Tooltip title="Contact seller"
																								 placement="left-start"
																								 arrow>
																							<NavLink
																								to={`/messages/reachout/${data.id}`}
																								className="space-x-2 align-text-top text-blue-500 text-xs"
																								onClick={this.handleContactSellerNavigation.bind(this, data)}
																							>
																								<button
																									className="bg-blue-100 p-1 rounded text-blue-500">
																									<svg
																										className="w-3.5 h-3.5"
																										fill="currentColor"
																										viewBox="0 0 20 20"
																										xmlns="http://www.w3.org/2000/svg">
																										<path
																											fillRule="evenodd"
																											d="M6 2a1 1 0 00-1 1v1H4a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V6a2 2 0 00-2-2h-1V3a1 1 0 10-2 0v1H7V3a1 1 0 00-1-1zm0 5a1 1 0 000 2h8a1 1 0 100-2H6z"
																											clipRule="evenodd"
																										/>
																									</svg>
																								</button>
																								<span>
																								Contact seller
																							</span>
																							</NavLink>
																						</Tooltip>
																					</div>
																				)}
																			</div>
																		</div>
																		<div className="text-right">
																			<button
																				onClick={this.toggleCollapsedRow.bind(this, data.id)}
																				className={Classnames({
																					rotate: includes(state.openRows, data.id)
																				})}
																			>
																				<svg
																					className="w-4 h-4"
																					aria-hidden="true"
																					fill="none"
																					stroke="currentColor"
																					viewBox="0 0 24 24"
																					xmlns="http://www.w3.org/2000/svg"
																				>
																					<path strokeLinecap="round"
																						  strokeLinejoin="round"
																						  strokeWidth="2"
																						  d="M19 9l-7 7-7-7" />
																				</svg>
																			</button>
																		</div>
																	</div>
																</div>
															</div>
															{' '}
															{/*End right item side*/}
														</div>
													</div>
													<div
														className="2xl:hidden lg:hidden md:hidden sm:block xs:block xxs:block">
														<div
															className="relative text-[#5D6E81] p-5 text-sm font-medium border-t mb-3 bg-white rounded-md shadow-md break-words">
															<div className="absolute top-1 right-0"
																 onClick={this.handleMobileHideShow.bind(this, data.id)}>
																<svg xmlns="http://www.w3.org/2000/svg" fill="none"
																	 viewBox="0 0 24 24" strokeWidth="1.5"
																	 stroke="currentColor" className="w-6 h-6">
																	<path
																		strokeLinecap="round"
																		strokeLinejoin="round"
																		d="M12 6.75a.75.75 0 110-1.5.75.75 0 010 1.5zM12 12.75a.75.75 0 110-1.5.75.75 0 010 1.5zM12 18.75a.75.75 0 110-1.5.75.75 0 010 1.5z"
																	/>
																</svg>
															</div>
															{includes(state.mobileButtonDisplay, data.id) && (
																<div
																	className="rounded-md border border-gray-200 bg-white shadow-lg shadow-gray-200 z-50 absolute top-7 w-52 right-0">
																	<div className="grid grid-cols-1 divide-y">
																		<div
																			onClick={this.openShareViaEmail.bind(this, data)}
																			className="flex justify-between p-2 items-center">
																			<span>Share via Email</span>
																			<span>
																			<svg
																				xmlns="http://www.w3.org/2000/svg"
																				fill="none"
																				viewBox="0 0 24 24"
																				strokeWidth="1.5"
																				stroke="currentColor"
																				className="w-3.5 h-3.5"
																			>
																				<path
																					strokeLinecap="round"
																					strokeLinejoin="round"
																					d="M21.75 6.75v10.5a2.25 2.25 0 01-2.25 2.25h-15a2.25 2.25 0 01-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25m19.5 0v.243a2.25 2.25 0 01-1.07 1.916l-7.5 4.615a2.25 2.25 0 01-2.36 0L3.32 8.91a2.25 2.25 0 01-1.07-1.916V6.75"
																				/>
																			</svg>
																		</span>
																		</div>
																		<div
																			className="flex justify-between p-2 items-center"
																			onClick={this.shareWhatsApp.bind(this, data)}>
																			<span>Share via Whatsapp</span>
																			<span>
																			<svg className="w-3.5 h-3.5"
																				 viewBox="0 0 15.96 16.1"
																				 fill="currentColor"
																				 xmlns="http://www.w3.org/2000/svg">
																				<path
																					d="M15.74,6.18C14.86,2.53,11.74,.04,7.96,0c-.5,0-1.02,.05-1.53,.16C1.16,1.23-1.57,7.1,1,11.83c.07,.13,.07,.23,.03,.36C.69,13.44,.35,14.69,.01,15.93c-.04,.14,.01,.19,.15,.15,1.29-.34,2.58-.68,3.87-1.02,.11-.03,.19-.02,.29,.03,1.66,.85,3.41,1.1,5.24,.75,4.37-.85,7.23-5.32,6.19-9.66h0Zm-6.66,8.38c-.36,.06-.72,.09-.91,.09-1.4,0-2.54-.32-3.6-.96-.13-.08-.25-.1-.41-.06-.71,.2-1.42,.37-2.13,.56-.15,.05-.16,.01-.13-.12,.19-.68,.36-1.35,.56-2.01,.05-.19,.03-.33-.07-.5-1.45-2.35-1.39-5.27,.23-7.44C4.32,1.86,6.62,.93,9.37,1.52c2.71,.57,4.44,2.33,5.07,5.03,.87,3.7-1.63,7.38-5.36,8Z"></path>
																				<path
																					d="M10.05,11.84c-.28,.01-.56-.05-.83-.13-1.83-.53-3.2-1.68-4.31-3.19-.42-.57-.8-1.16-.93-1.87-.15-.85,.09-1.57,.72-2.16,.2-.18,.81-.27,1.06-.15,.09,.05,.15,.13,.19,.21,.23,.55,.46,1.1,.69,1.65,.04,.11,.02,.21-.02,.32-.12,.25-.3,.46-.49,.66-.14,.15-.15,.28-.04,.46,.65,1.08,1.54,1.87,2.71,2.34,.17,.07,.31,.05,.42-.1,.19-.24,.4-.48,.58-.73,.13-.18,.27-.2,.45-.12,.39,.18,.77,.36,1.16,.54,.09,.04,.17,.08,.26,.12,.39,.2,.39,.2,.35,.63-.07,.85-.7,1.27-1.45,1.47-.17,.04-.34,.05-.51,.04h0Z"></path>
																			</svg>
																		</span>
																		</div>
																		{data.space_id !== props.currentSpace.id && isVisible && (
																			<NavLink
																				to={`/messages/reachout/${data.id}/availability`}>
																				<div
																					className="flex justify-between p-2 items-center">
																					<span>Check Availability</span>
																					<span>
																					<svg className="w-3.5 h-3.5"
																						 fill="currentColor"
																						 viewBox="0 0 20 20"
																						 xmlns="http://www.w3.org/2000/svg">
																						<path
																							fillRule="evenodd"
																							d="M14.243 5.757a6 6 0 10-.986 9.284 1 1 0 111.087 1.678A8 8 0 1118 10a3 3 0 01-4.8 2.401A4 4 0 1114 10a1 1 0 102 0c0-1.537-.586-3.07-1.757-4.243zM12 10a2 2 0 10-4 0 2 2 0 004 0z"
																							clipRule="evenodd"
																						></path>
																					</svg>
																				</span>
																				</div>
																			</NavLink>
																		)}
																		{data.space_id !== props.currentSpace.id && isVisible && (
																			<NavLink
																				to={`/messages/reachout/${data.id}`}>
																				<div
																					className="flex justify-between p-2 items-center">
																					<span>Contact Seller</span>
																					<span>
																					<svg className="w-3.5 h-3.5"
																						 fill="currentColor"
																						 viewBox="0 0 20 20"
																						 xmlns="http://www.w3.org/2000/svg">
																						<path
																							fillRule="evenodd"
																							d="M6 2a1 1 0 00-1 1v1H4a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V6a2 2 0 00-2-2h-1V3a1 1 0 10-2 0v1H7V3a1 1 0 00-1-1zm0 5a1 1 0 000 2h8a1 1 0 100-2H6z"
																							clipRule="evenodd"
																						></path>
																					</svg>
																				</span>
																				</div>
																			</NavLink>
																		)}
																		{isVisible && (
																			<div
																				className="flex justify-between p-2 items-center"
																				onClick={this.saveItem.bind(this, data)}>
																				<span>{data?.saved ? 'Saved' : 'Save'}</span>
																				<span>
																				<svg className="w-3.5 h-3.5"
																					 fill="currentColor"
																					 viewBox="0 0 20 20"
																					 xmlns="http://www.w3.org/2000/svg">
																					<path
																						d="M5 4a2 2 0 012-2h6a2 2 0 012 2v14l-5-2.5L5 18V4z"></path>
																				</svg>
																			</span>
																			</div>
																		)}
																	</div>
																</div>
															)}
															<div className="flex">
																<div className="flex-none">
																	{' '}
																	{/*Items left side*/}
																	<div
																		className="bg-center bg-contain bg-gray-100 bg-no-repeat cursor-pointer w-14 h-14 m-auto mr-3"
																		style={{
																			backgroundImage: head(data.images) !== undefined ? `url('${head(data.images).url}')` : `url('/watch_icon.png')`
																		}}
																		onClick={this.openGalleryItems.bind(this, data)}
																	></div>
																</div>

																<div className="flex-initial w-64">
																	<div
																		className="font-bold text-sm text-[#5D6E81]">{data.short_name}</div>
																	<div
																		className={Classnames(
																			'text-xs  flex items-center gap-1',
																			{
																				'mt-1': data.short_name.length > 35
																			},
																			{
																				'mt-5': data.short_name.length < 35
																			}
																		)}
																	>
																		{!isNull(data.wholesale_price) && data.wholesale_price > 0 && isVisible ?
																			<span>{data.wholesale_price && Globals.moneyFormatter.format(data.wholesale_price).replace('.00', '')}</span>
																			: (
																				<div className="font-bold">
																					<NavLink
																						to={`/messages/reachout/${data.id}/price`}
																						className="align-text-top text-blue-500 text-xs"
																						onClick={this.handleContactForPriceNavigation.bind(this, data)}
																					>
																						Contact for price
																					</NavLink>
																				</div>
																			)}

																		<div
																			className={Classnames(
																				'rounded-full 2xl:hidden lg:hidden md:hidden sm:block xs:block xxs:block',
																				{
																					'bg-red-600': data.status === 'pending_sale_to'
																				},
																				{
																					'bg-yellow-600': data.status === 'on_hold_for'
																				},
																				{
																					'w-auto  p-1 text-white': includes(state.mobileStatus, data.id)
																				},
																				{
																					'w-2 h-2': !includes(state.mobileStatus, data.id)
																				}
																			)}
																			onClick={this.setMobileStatus.bind(this, data.id)}
																		>
																			{includes(state.mobileStatus, data.id) && data.status}
																		</div>
																	</div>
																</div>
															</div>

															<div className="flex mt-1 text-xxs leading-4">
																<div className="flex-1">
																	SKU: <strong>{includes(state.openRows, data.id) ? data.sku : this.truncateString(data.sku, 25)}</strong>
																</div>
																<div className="flex-1">
																	Dial: <strong>{includes(state.openRows, data.id) ? data.dial : this.truncateString(data.dial, 25)}</strong>
																</div>
															</div>
															<div className="flex text-xxs leading-4">
																<div className="flex-1">
																	Bracelet: <strong>{includes(state.openRows, data.id) ? data.bracelet : this.truncateString(data.bracelet, 25)}</strong>
																</div>
																<div className="flex-1">
																	Year:{' '}
																	<strong>
																		{!includes(state.openRows, data.id) && !isNaN(new Date(data?.warranty).getFullYear())
																			? new Date(data?.warranty).getFullYear()
																			: data?.warranty}
																	</strong>
																</div>
															</div>
															{!includes(state.openRows, data.id) && (
																<div className="flex text-xxs leading-4 mb-5">
																	<div className="w-full">
																		Condition: <strong>{includes(state.openRows, data.id) ? data.condition : this.truncateString(data.condition, 45)} </strong>
																	</div>
																</div>
															)}
															{includes(state.openRows, data.id) && (
																<>
																	<div className="flex text-xxs leading-4">
																		<div className="flex-1">
																			Condition: <strong>{includes(state.openRows, data.id) ? data.condition : this.truncateString(data.condition, 25)}</strong>
																		</div>
																		<div className="flex-1">
																			Box: <strong>{includes(state.openRows, data.id) ? data.box : this.truncateString(data.box, 25)}</strong>
																		</div>
																	</div>
																	<div className="flex text-xxs leading-4">
																		<div className="flex-1">
																			Bezel: <strong>{includes(state.openRows, data.id) ? data.bezel : this.truncateString(data.bezel, 25)}</strong>
																		</div>
																		<div className="flex-1">
																			Link
																			Count: <strong>{includes(state.openRows, data.id) ? data.link_count : this.truncateString(data.link_count, 25)}</strong>
																		</div>
																	</div>
																	<div className="flex text-xxs leading-4 mb-5">
																		<div className="w-full">
																			Paper: <strong>{includes(state.openRows, data.id) ? data.warranty_papers : this.truncateString(data.warranty_papers, 25)} </strong>
																		</div>
																	</div>
																	<hr></hr>
																	<div className="leading-4 mt-3">
																		<div
																			className="w-full font-bold text-sm">Additional
																			Details
																		</div>
																		<div
																			className="text-xxs">{!isEmpty(data.addition_details) ? data.addition_details : 'N/A'}</div>
																	</div>
																</>
															)}

															<div
																className="2xl:hidden lg:hidden md:hidden sm:block xs:block xxs:block absolute bottom-2 right-2 w-full">
																<div className="flex justify-end items-end gap-2">
																	{!includes(state.openRows, data.id) && (
																		<>
																			{data.warranty_papers !== 'No' && (
																				<div className="">
																					<svg
																						xmlns="http://www.w3.org/2000/svg"
																						fill="none"
																						viewBox="0 0 24 24"
																						strokeWidth="1.5"
																						stroke="currentColor"
																						className="w-6 h-6"
																					>
																						<path
																							strokeLinecap="round"
																							strokeLinejoin="round"
																							d="M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m0 12.75h7.5m-7.5 3H12M10.5 2.25H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9z"
																						/>
																					</svg>
																				</div>
																			)}
																			{(data.box === 'yes' || data.box) && (
																				<div className="">
																					<svg
																						xmlns="http://www.w3.org/2000/svg"
																						fill="none"
																						viewBox="0 0 24 24"
																						strokeWidth="1.5"
																						stroke="currentColor"
																						className="w-6 h-6"
																					>
																						<path
																							strokeLinecap="round"
																							strokeLinejoin="round"
																							d="M20.25 7.5l-.625 10.632a2.25 2.25 0 01-2.247 2.118H6.622a2.25 2.25 0 01-2.247-2.118L3.75 7.5M10 11.25h4M3.375 7.5h17.25c.621 0 1.125-.504 1.125-1.125v-1.5c0-.621-.504-1.125-1.125-1.125H3.375c-.621 0-1.125.504-1.125 1.125v1.5c0 .621.504 1.125 1.125 1.125z"
																						/>
																					</svg>
																				</div>
																			)}
																		</>
																	)}

																	<div
																		className={Classnames({
																			rotate: includes(state.openRows, data.id)
																		})}
																		onClick={this.toggleCollapsedRow.bind(this, data.id)}
																	>
																		<svg xmlns="http://www.w3.org/2000/svg"
																			 fill="none" viewBox="0 0 24 24"
																			 strokeWidth="1.5" stroke="currentColor"
																			 className="w-6 h-6">
																			<path strokeLinecap="round"
																				  strokeLinejoin="round"
																				  d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
																		</svg>
																	</div>
																</div>
															</div>
														</div>
													</div>
												</React.Fragment>
											)
										})}
									</InfiniteScroll>
								</div>
							</div>


							{state.loadingData ? (
								<div
									className="absolute top-0 left-0 right-0 bottom-0 flex justify-center items-center z-50">
									<button
										type="button"
										className="inline-flex items-center px-4 py-2 leading-6 text-sm shadow rounded-md text-white bg-blue transition ease-in-out duration-150 cursor-not-allowed"
										disabled
									>
										<svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
											 xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
											{' '}
											<circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor"
													strokeWidth="4" />
											<path
												className="opacity-75"
												fill="currentColor"
												d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
											/>
										</svg>
										<span>Loading... </span>
									</button>
								</div>
							) : isEmpty(state.dataProvider) && (
								<>
									<NoResultsFound searchTerm={this.props.searchKey}
													displayConciergeInquiryForm={this.displayConciergeInquiryForm} />
								</>
							)}
							{state.openDialogShare &&
								<ShareWhatAppEmailDialog
									titleHeader={state.titleHeader}
									data={state.selectedItemToShare}
									handleVisibleDialog={this.handleVisibleDialog}
									handleSubmit={this.handleSubmitShare}
									openGalleryItems={this.openGalleryItems}
									view={'marketplace'}
								/>
							}

							{state.visibleSendEmail && (
								<div
									tabIndex="-1"
									aria-hidden="true"
									className="bg-black/50 fixed flex h-screen items-center justify-center left-0 overflow-x-hidden overflow-y-auto p-4 right-0 top-0 w-full z-50"
								>
									<div className="relative w-full max-w-2xl h-full md:h-auto m-auto">
										<div className="relative bg-white rounded-xl shadow">
											<div
												className="flex justify-between items-start px-8 py-4 rounded-t-xl border-b bg-slate-600 text-white">
												<h3 className="text-white font-bold text-lg">Share via email</h3>
												<button
													onClick={() =>
														this.setState({
															visibleSendEmail: false
														})
													}
													type="button"
													className="bg-transparent rounded-xl text-sm py-1.5 ml-auto inline-flex items-center"
												>
													<svg className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20"
														 xmlns="http://www.w3.org/2000/svg">
														<path
															fillRule="evenodd"
															d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
															clipRule="evenodd"
														></path>
													</svg>
												</button>
											</div>

											<div className="px-8 py-4 space-y-6">
												<div>
													<label htmlFor="email-address-icon"
														   className="block text-base bg-transparent font-normal py-2">
														Email address
													</label>
													<div className="relative">
														<div
															className="flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none">
															<svg aria-hidden="true" className="w-5 h-5 text-gray-500"
																 fill="currentColor" viewBox="0 0 20 20"
																 xmlns="http://www.w3.org/2000/svg">
																<path
																	d="M2.003 5.884L10 9.882l7.997-3.998A2 2 0 0016 4H4a2 2 0 00-1.997 1.884z"></path>
																<path
																	d="M18 8.118l-8 4-8-4V14a2 2 0 002 2h12a2 2 0 002-2V8.118z"></path>
															</svg>
														</div>
														<input
															onChange={$elem => {
																const shareViaEmailForm = state.shareViaEmailForm
																shareViaEmailForm.emailAddress = $elem.target.value
																this.setState({
																	shareViaEmailForm
																})
															}}
															type="text"
															id="email-address-icon"
															className="block pl-10 py-3 p-4 md:w-full w-80 inputLogin appearance-none outline-none bg-transparent text-black text-sm font-medium"
															placeholder="Email address"
														/>
													</div>
												</div>

												<div>
													<label htmlFor="AdditionalDetails"
														   className="block text-base bg-transparent font-normal py-2">
														Additional Details
													</label>
													<textarea
														onChange={$elem => {
															const shareViaEmailForm = state.shareViaEmailForm
															shareViaEmailForm.emailContent = $elem.target.value
															if (shareViaEmailForm.emailContent?.length >= 2001) return
															this.setState({
																shareViaEmailForm
															})
														}}
														value={state.shareViaEmailForm.emailContent}
														id="AdditionalDetails"
														name="AdditionalDetails"
														className="block p-4 w-full inputLogin appearance-none outline-none text-black text-sm font-medium"
														placeholder="Maximum of 20000 characters."
														maxLength="20000"
														cols="30"
														rows="4"
													></textarea>
													<div>
														<small>{state.shareViaEmailForm.emailContent?.length}</small>
														<small>
															<small>/</small>
														</small>
														<small>
															<small>2000</small>
														</small>
													</div>
												</div>
											</div>

											<div
												className="flex justify-end items-center px-8 py-4 space-x-2 rounded-b-xl border-t border-gray-200">
												<ConfirmPopUp
													onConfirm={this.shareViaEmail}
													title="Confirm share via email"
													content="Are you sure you want to share this product via email?"
												>
													<button
														type="button"
														className="px-8 py-3 rounded-md text-xs font-bold leading-5 lg:text-sm text-white cursor-pointer bg-blue uppercase"
														disabled={state.sendingSendEmail}
													>
														{!state.sendingSendEmail && <span>Send</span>}
														{state.sendingSendEmail && (
															<span role="status">
																<svg
																	className="inline w-5 h-5 animate-spin fill-blue-400"
																	viewBox="0 0 100 101" fill="none"
																	xmlns="http://www.w3.org/2000/svg">
																	<path
																		d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
																		fill="currentColor"
																	/>
																	<path
																		d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
																		fill="currentFill"
																	/>
																</svg>
															</span>
														)}
													</button>
												</ConfirmPopUp>
												<button
													onClick={() =>
														this.setState({
															visibleSendEmail: false,
															selectedItemToShare: null
														})
													}
													type="button"
													className="px-8 py-3 rounded-md text-xs font-bold leading-5 lg:text-sm text-white cursor-pointer bg-red-700 uppercase"
												>
													Cancel
												</button>
											</div>
										</div>
									</div>
								</div>
							)}

							{state.visibleContactSeller && (
								<div
									tabIndex="-1"
									aria-hidden="true"
									className="bg-black/50 fixed flex h-screen items-center justify-center left-0 overflow-x-hidden overflow-y-auto p-4 right-0 top-0 w-full z-50"
								>
									<div className="relative w-full max-w-2xl h-full md:h-auto m-auto">
										<div className="relative bg-white rounded-xl shadow">
											<div
												className="flex justify-between items-start px-8 py-4 rounded-t-xl border-b bg-slate-600 text-white">
												<h3 className="text-white font-bold text-lg">Contact seller</h3>
												<button
													onClick={() =>
														this.setState({
															visibleContactSeller: false
														})
													}
													type="button"
													className="bg-transparent rounded-xl text-sm py-1.5 ml-auto inline-flex items-center"
												>
													<svg className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20"
														 xmlns="http://www.w3.org/2000/svg">
														<path
															fillRule="evenodd"
															d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
															clipRule="evenodd"
														></path>
													</svg>
												</button>
											</div>

											<div className="px-8 py-4 space-y-6">
												<div>
													<label htmlFor="AdditionalDetails"
														   className="block text-base bg-transparent font-normal py-2">
														Additional Details
													</label>
													<textarea
														onChange={$elem => {
															const contactSellerForm = state.contactSellerForm
															contactSellerForm.emailContent = $elem.target.value
															if (contactSellerForm.emailContent?.length >= 2001) return
															this.setState({
																contactSellerForm
															})
														}}
														value={state.contactSellerForm.emailContent}
														id="AdditionalDetails"
														name="AdditionalDetails"
														className="block p-4 w-full inputLogin appearance-none outline-none text-black text-sm font-medium"
														placeholder="Maximum of 20000 characters."
														maxLength="20000"
														cols="30"
														rows="6"
													></textarea>
													<div>
														<small>{state.contactSellerForm.emailContent?.length}</small>
														<small>
															<small>/</small>
														</small>
														<small>
															<small>2000</small>
														</small>
													</div>
												</div>
											</div>

											<div
												className="flex justify-end items-center px-8 py-4 space-x-2 rounded-b-xl border-t border-gray-200">
												<button
													type="button"
													className="px-8 py-3 rounded-md text-xs font-bold leading-5 lg:text-sm text-white cursor-pointer bg-blue uppercase"
													onClick={this.contactSeller}
													disabled={state.sendingContactSeller}
												>
													{!state.sendingContactSeller && <span>Send</span>}
													{state.sendingContactSeller && (
														<span role="status">
															<svg className="inline w-5 h-5 animate-spin fill-blue-400"
																 viewBox="0 0 100 101" fill="none"
																 xmlns="http://www.w3.org/2000/svg">
																<path
																	d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
																	fill="currentColor"
																/>
																<path
																	d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
																	fill="currentFill"
																/>
															</svg>
														</span>
													)}
												</button>
												<button
													onClick={() => {
														this.setState({
															visibleContactSeller: false,
															contactSellerForm: {
																emailContent: '',
																product: null
															}
														})
													}}
													type="button"
													className="px-8 py-3 rounded-md text-xs font-bold leading-5 lg:text-sm text-white cursor-pointer bg-red-700 uppercase"
												>
													Cancel
												</button>
											</div>
										</div>
									</div>
								</div>
							)}
							{this.state.image &&
								<PreviewImage
									loadingImage={this.state.loadingImage}
									loadingImageFirst={this.state.loadingImageFirst}
									closeGallery={this.closeGallery}
									activeGalleryItem={this.state.activeGalleryItem}
									image={this.state.image}
									paginateSlider={this.paginateSlider}
									handleLoadingImage={this.handleLoadingImage}
								/>
							}
						</React.Fragment>
					)}
					{this.state.isVisibleUserProfile &&
						<UserProfileModal
							singleDataProvider={this.state.singleDataProvider}
							handleCloseUserProfileModal={this.handleCloseUserProfileModal}
							openShareViaEmail={this.openShareViaEmail}
							isMarketPlace={true}
							handleFilterBySpace={this.handleFilterBySpace}
							props={this.props}
						/>
					}
					{this.state.visibleConciergeInquiryForm && (
						<ConciergeInquiryForm
							displayModal={this.displayConciergeInquiryForm}
							singleDataProvider={this.state.singleDataProvider}
							searchTerm={this.props.searchKey}
							props={this.props}
						/>
					)}
				</React.Fragment>
			</div>
		)
	}
}

const mapStateToProps = (state, props) => {
	return {
		profile: state.profile,
		currentSpace: state.currentSpace,
		isOpenSideBar: state.isOpenSideBar,
		loggedin: state.loggedin,
		globalColumnConfigs: state.globalColumnConfigs
	}
}

const mapActionsToProps = {
	addToast
}

export default connect(mapStateToProps, mapActionsToProps)(withRouter(MarketPlaceView))
