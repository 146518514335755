import React from 'react'
import {connect} from 'react-redux'
import Tooltip from '@mui/material/Tooltip'
import Classnames from 'classnames'

import EleftaTable from './EleftaTable/EleftaTable'
import {Globals} from './../Context'
import {addToast} from './../app/appActions'
import isNull from 'lodash/isNull'
import isEmpty from 'lodash/isEmpty'
import find from 'lodash/find'

class SendDataView extends React.Component {
	constructor() {
		super()
		this.state = {
			loading: false,
			sending: false,
			selectedColumns: [],
			isVisibleMarkup: false,
			checkedRows: [],
			checkedRowsIds: [],
			showPriceChange: false,
			data: [],
			priceSettings: {
				percentMarkup: [],
				percentVal: '',
				valMarkup: [],
				markVal: '',
			},
		}
	}

	componentDidMount() {
		const defaultColumns = [
			'images',
			'sku',
			'reference_number',
			'brand',
			'series',
			'condition',
			'dial',
			'bezel',
			'bracelet',
			'warranty_papers',
			'warranty',
			'box',
			'addition_details',
			'wholesale_price',
		]
		const allColumns = this.props.columns
		allColumns.forEach(column => {
			if (!defaultColumns.includes(column.key)) {
				column.selected = false
			}
		})
		this.setState({selectedColumns: allColumns, data: this.props.data})
	}

	handleInjectColumn = (column, $event) => {
		let selectedColumns = this.state.selectedColumns
		this.setState({
			selectedColumns: selectedColumns.map(selectedColumn => {
				//toggle selected key value.
				if (selectedColumn.key === column.key) selectedColumn.selected = !selectedColumn.selected
				return selectedColumn
			}),
		})
	}

	handleCheckboxAllRows =(isChecked) => {
		const selectedColumns = this.state.selectedColumns

		if (isChecked) {
			return this.setState({
				selectedColumns: selectedColumns.map(selectedColumn => {
					selectedColumn.selected = true
					return selectedColumn
				}),
			})
		}

		return this.setState({
			selectedColumns: selectedColumns.map(selectedColumn => {
				selectedColumn.selected = false
				return selectedColumn
			}),
		})
	}

	handleRowChecked = rows => {
		const dataProvider = this.state.data
		this.setState({
			checkedRows: dataProvider.filter(data => rows.includes(data.id)),
			checkedRowsIds: [...rows],
		})
	}

	handlePercentChange = $event => {
		if(!this.state.priceSettings.percentVal)  return this.props.addToast({
			title: 'Mark Up Not Applied',
			text: 'You must select an item',
			type: 'error',
		})
		const val = parseFloat(this.state.priceSettings.percentVal)
		let percentageItems = []

		if (this.state.priceSettings?.percentMarkup && this.state.priceSettings?.percentMarkup.length > 0) {
			this.state.priceSettings?.percentMarkup.forEach(ItemExist => {
				if (!this.state.checkedRowsIds.includes(ItemExist.item)) {
					percentageItems.push(ItemExist)
				}
			})
		}

		const allItems = this.state.data

		if (!isNull(val)) {
			let markedUp = allItems.map(item => {
				if (this.state.checkedRowsIds.includes(item.id)) {
					let wholesale_price_formatted = item.wholesale_price ? Globals.formatCurrency(Math.round(parseFloat(item.wholesale_price) + (parseFloat(item.wholesale_price) * val) / 100)) : ''
					//wholesale_price = wholesale_price.toString()
					percentageItems.push({item: item.id, price: val})

					return {...item, wholesale_price_formatted}
				} else {
					return item
				}
			})

			this.setState({
				data: markedUp,
				priceSettings: {
					percentMarkup: percentageItems,
					percentVal: val,
					valMarkup: this.state.priceSettings?.valMarkup,
					markVal: '',
				},
			})
		}
	}
	handlePercentChangeval = $event => {
		let inpVal = $event.target.value
		if (isNaN(inpVal)) {
			return this.props.addToast({
				title: 'Input Error',
				text: 'Please enter a valid value',
				type: 'error',
			})
		}
		this.setState({
			priceSettings: {
				percentVal: $event.target.value,
				percentMarkup: this.state.priceSettings?.percentMarkup,
				valMarkup: this.state.priceSettings?.valMarkup,
				markVal: '',
			},
		})
	}
	handleValMarkupval = $event => {
		let inpVal = $event.target.value
		if (isNaN(inpVal)) {
			return this.props.addToast({
				title: 'Input Error',
				text: 'Please enter a valid value',
				type: 'error',
			})
		}
		this.setState({
			priceSettings: {
				percentVal: '',
				percentMarkup: this.state.priceSettings?.percentMarkup,
				valMarkup: this.state.priceSettings?.valMarkup,
				markVal: $event.target.value,
			},
		})
	}

	handleValMarkup = $event => {
		if(!this.state.priceSettings.markVal) return this.props.addToast({
			title: 'Mark Up Not Applied',
			text: 'You must select an item',
			type: 'error',
		})
		const val = parseFloat(this.state.priceSettings.markVal)
		let fixedValMarkup = []

		if (this.state.priceSettings?.valMarkup && this.state.priceSettings?.valMarkup.length > 0) {
			this.state.priceSettings?.valMarkup.forEach(ItemExist => {
				if (!this.state.checkedRowsIds.includes(ItemExist.item)) {
					fixedValMarkup.push(ItemExist)
				}
			})
		}

		const allItems = this.state.data

		if (!isNull(val)) {
			let markedUp = allItems.map(item => {
				if (this.state.checkedRowsIds.includes(item.id)) {
					let wholesale_price_formatted = item.wholesale_price ? Globals.formatCurrency(parseFloat(item.wholesale_price) + val) : ''

					fixedValMarkup.push({item: item.id, price: val})
					return {...item, wholesale_price_formatted}
				} else {
					return item
				}
			})

			this.setState({
				data: markedUp,
				priceSettings: {
					percentMarkup: this.state.priceSettings?.percentMarkup,
					percentVal: '',
					valMarkup: fixedValMarkup,
					markVal: val,
				},
			})
		}
	}

	buildFormData = (isSendEmail) => {
		let errors = []

		const selectedColumns = this.state.selectedColumns.filter(selectedColumn => selectedColumn.selected).map(selectedColumn => selectedColumn.key)
		const posts = this.props.data

		if (isSendEmail && (isEmpty(this.EmailAddress.value) || !Globals.validateEmail(this.EmailAddress.value))) {
			errors = [
				...errors,
				{
					title: 'Cannot send items',
					text: 'Please fill in the recipient email address.',
					type: 'warning',
				},
			]
		}

		if (isEmpty(selectedColumns)) {
			errors = [
				...errors,
				{
					title: 'Cannot send items',
					text: 'At least one column must be selected.',
					type: 'warning',
				},
			]
		}

		if (isEmpty(posts)) {
			errors = [
				...errors,
				{
					title: 'Cannot send items',
					text: 'Something went wrong please try again later.',
					type: 'warning',
				},
			]
		}

		if (!isEmpty(errors)) {
			errors.map(error => {
				return this.props.addToast(error)
			})

			return
		}

		let incrementalVal = ''
		const markupWatchesId = []
		let incrementalType = ''
		if (this.state.priceSettings.markVal) {
			incrementalVal = this.state.priceSettings.markVal
			this.state.priceSettings.valMarkup.forEach(item => markupWatchesId.push(item.item))
			incrementalType = 'val'
		} else {
			incrementalVal = this.state.priceSettings.percentVal
			this.state.priceSettings.percentMarkup.forEach(item => markupWatchesId.push(item.item))
			incrementalType = 'per'
		}

		let sharingWatchAndMarkupsAttributes = posts.map(post => {
			let data = {
				watch_id: post.id,
			}

			let markupData
			markupData = find(this.state.priceSettings.valMarkup, {item: post.id})
			if (markupData) {
				data['markup_value'] = markupData.price
				data['markup_type'] = 'fixed_value'
			}

			markupData = find(this.state.priceSettings.percentMarkup, {item: post.id})
			if (markupData) {
				data['markup_value'] = markupData.price
				data['markup_type'] = 'percentage'
			}

			return data
		})

		let postData = {
			selected_attributes: selectedColumns,
			recipient_emails: [this.EmailAddress.value],
			sharing_watch_and_markups_attributes: sharingWatchAndMarkupsAttributes
		}

		return postData
	}

	handleSending = $event => {
		//Add blocker here if the sending status is true already.

		if (this.state.sending) return

		const formData = this.buildFormData(true)

		this.setState({sending: true})
		Globals.New_Axios()
			.post(`${Globals.NEW_API_URL}/${Globals.ENDPOINTVERSION}/spaces/${Globals.SELECTEDSPACEID}/sharing_watches`, formData)
			.then(response => {
				if (response.status && response.status >= 200 && response.status < 300) {
					this.setState({sending: false})
					this.props.onClose()
					this.props.handleResetRow()
					this.props.addToast({
						title: 'Inventory',
						text: 'Items have been sent successfully',
						type: 'success',
					})

					return
				}

				this.props.addToast({
					title: 'Cannot send items',
					text: 'Something went wrong please try again later.',
					type: 'error',
				})

				this.setState({sending: false})

				return
			})
			.catch(error => {
				this.setState({sending: false})
				this.props.addToast({
					title: 'Cannot send items',
					text: 'Something went wrong please try again later.',
					type: 'error',
				})
			})
	}

	printPdf = () => {
		const formData = this.buildFormData(false)
		this.setState({loading: true})
		Globals.New_Axios()
			.post(`${Globals.NEW_API_URL}/${Globals.ENDPOINTVERSION}/spaces/${Globals.SELECTEDSPACEID}/sharing_watches/download.pdf`, formData, {
				responseType: 'blob'
			}).then((response) => {
				const url = window.URL.createObjectURL(new Blob([response.data]));
				const link = document.createElement('a');
				link.href = url;
				link.setAttribute('download', 'Elefta-Inventory-Report.pdf'); // or any other filename
				document.body.appendChild(link);
				link.click();
				this.setState({loading: false})
			  }).catch(error => {
				console.log(error)
				this.setState({loading: false})
			  })
	}

	render() {
		const props = this.props
		const state = this.state
		const selectedColumns = state.selectedColumns.filter(selectedColumn => selectedColumn.selected)

		return (
			<div className="flex flex-col fixed top-0 right-0 left-0 bottom-0 w-screen h-screen bg-black/50 flex items-center justify-center z-50 p-8">
				{
					state.loading && (
						<div className="absolute top-0 left-0 right-0 bottom-0 flex justify-center items-center z-50">
							<button
								type="button"
								className="inline-flex items-center px-4 py-2 leading-6 text-sm shadow rounded-md text-white bg-blue transition ease-in-out duration-150 cursor-not-allowed"
								disabled
							>
								<svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
									{' '}
									<circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4" />
									<path
										className="opacity-75"
										fill="currentColor"
										d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
									/>
								</svg>
								<span>Loading... </span>
							</button>
						</div>
					)
				}
				<div className="drop-shadow-xl rounded-[10px] w-full h-full overflow-hidden" style={{maxWidth: '1440px'}}>
					<div className="w-full h-[82px] borderCardChrono relative py-[30px] ps-8 pe-8 flex items-center justify-between">
						<span className="text-white font-bold text-[24px]">Send</span>
						<button className="absolute right-0 mr-8 cursor-pointer" onClick={props.onClose}>
							<svg xmlns="http://www.w3.org/2000/svg" width="21" height="22" viewBox="0 0 21 22" fill="none">
								<path d="M19.6008 0.000348506C19.2772 0.0081273 18.9697 0.146064 18.7457 0.384009L10.4269 8.85621L2.1082 0.384009C1.99393 0.264387 1.85726 0.169334 1.70628 0.104479C1.55529 0.0396235 1.39307 0.00628522 1.2292 0.00643836C0.985688 0.00674552 0.747791 0.0809774 0.545903 0.219651C0.344014 0.358325 0.187299 0.555145 0.0957762 0.784969C0.00425385 1.01479 -0.0179204 1.26719 0.0320861 1.50991C0.0820926 1.75263 0.20201 1.97466 0.376518 2.14763L8.69527 10.6198L0.376518 19.092C0.258987 19.207 0.165154 19.3446 0.100512 19.4969C0.0358708 19.6493 0.00172122 19.8132 6.34051e-05 19.9791C-0.00159441 20.145 0.0292727 20.3096 0.0908574 20.4633C0.152442 20.6169 0.243506 20.7565 0.358716 20.8738C0.473927 20.9911 0.610967 21.0839 0.761811 21.1466C0.912656 21.2093 1.07427 21.2407 1.2372 21.2391C1.40012 21.2374 1.56107 21.2026 1.71063 21.1368C1.86019 21.0709 1.99535 20.9754 2.1082 20.8557L10.4269 12.3835L18.7457 20.8557C18.8585 20.9754 18.9937 21.0709 19.1433 21.1368C19.2928 21.2026 19.4538 21.2374 19.6167 21.2391C19.7796 21.2408 19.9412 21.2093 20.0921 21.1466C20.2429 21.0839 20.38 20.9911 20.4952 20.8738C20.6104 20.7565 20.7015 20.6169 20.763 20.4633C20.8246 20.3096 20.8555 20.145 20.8538 19.9791C20.8522 19.8132 20.818 19.6493 20.7534 19.4969C20.6887 19.3446 20.5949 19.207 20.4774 19.092L12.1586 10.6198L20.4774 2.14763C20.6556 1.97361 20.778 1.74879 20.8285 1.50268C20.8791 1.25656 20.8554 1.00064 20.7606 0.768468C20.6658 0.536296 20.5044 0.338717 20.2974 0.201645C20.0904 0.0645724 19.8476 -0.0055937 19.6008 0.000348506Z" fill="white"/>
							</svg>
						</button>
					</div>
					<div className="bg-white flex flex-col h-[calc(100vh-146px)]">
						<div className='h-auto'>
							<div className="bg-white p-5 shadow-lg sticky top-0 z-20">
								<div className='flex items-center px-3 gap-2'>
									<input
										type="email"
										ref={EmailAddress => {
											this.EmailAddress = EmailAddress
										}}
										placeholder="Email Address(es)"
										name="email_address"
										className="outline-0 border-b w-full text-[15px] font-bold h-[40px]"
									/>
									<button onClick={this.printPdf}>
										<svg xmlns="http://www.w3.org/2000/svg" width="56" height="40" viewBox="0 0 56 40" fill="none">
											<rect x="0.5" y="0.5" width="55" height="39" rx="4.5" stroke="#5D6E81" stroke-opacity="0.28"/>
											<path d="M28 25.75L20.5 18.5625L22.6 16.4781L26.5 20.2156V8.5H29.5V20.2156L33.4 16.4781L35.5 18.5625L28 25.75ZM19 31.5C18.175 31.5 17.4688 31.2185 16.8813 30.6555C16.2937 30.0924 16 29.4156 16 28.625V24.3125H19V28.625H37V24.3125H40V28.625C40 29.4156 39.7063 30.0924 39.1187 30.6555C38.5312 31.2185 37.825 31.5 37 31.5H19Z" fill="#5D6E81"/>
										</svg>
									</button>
									<button
										onClick={() => {this.setState({isVisibleMarkup: !this.state.isVisibleMarkup})}}
										className={Classnames('flex justify-center items-center h-[40px] min-w-[80px] px-[12px] py-[14px] whitespace-nowrap	border-[1px] rounded-[5px]',
										{'bg-[#5D6E81] text-[#fff]': this.state.isVisibleMarkup},
										{'bg-[#fff] text-[#5D6E81]': !this.state.isVisibleMarkup}
									)}
									>
										<span className='font-bold text-[16px]'>Mark Up</span>
									</button>
									<button
										className='flex justify-center items-center  h-[40px] min-w-[80px]  px-[12px] py-[14px] border-[1px] rounded-[5px] bg-[#2C79E0] text-[#fff] font-bold text-[16px]'
										disabled={state.sending}
										onClick={this.handleSending}
									>
										{!state.sending && <span>Send</span>}
										{state.sending && <span>Sending...</span>}
									</button>
								</div>
								{
									this.state.isVisibleMarkup &&
									<div className='flex justify-center py-2 pt-4'>
										<div style={{backgroundColor:"rgba(217, 217, 217, 0.3)"}} className='max-w-[840px] flex justify-items w-full items-center p-2'>
											<div>
												<p className='text-sm font-semibold font-poppins leading-[21px]'>
												Select the item(s) you would like to adjust, enter the mark up, then click apply. Mark up only changes Wholesale Price
												</p>
											</div>
											<input
												type="text"
												placeholder="% Mark Up"
												className="font-poppins placeholder-[#5D6E81] placeholder:font-bold placeholder:text-[15px] border rounded w-[137px] h-[47px] mr-2 text-center text-[15px] font-bold outline-none"
												value={this.state.priceSettings.percentVal}
												onChange={this.handlePercentChangeval}
											/>
											<input
												type="text"
												placeholder="$ Mark Up"
												className="placeholder-[#5D6E81] placeholder:font-bold placeholder:text-[15px] border rounded w-[137px] h-[47px] mr-2 text-center text-[15px] font-bold outline-none"
												value={this.state.priceSettings.markVal}
												onChange={this.handleValMarkupval}
											/>
											<button
												onClick={!isEmpty(this.state.priceSettings.markVal) ? this.handleValMarkup : this.handlePercentChange}
												className='h-[47px] min-w-[80px] p-1 border-[1px] rounded-[5px] text-[#2C79E0] bg-[#fff] border-[#2C79E0]'
											>
												<span className='font-bold text-base'>Apply</span>
											</button>
										</div>
									</div>
								}

								<div className="p-4 text-sm font-semibold">
									<div className='flex text-xs gap-2'>
										<div
											onClick={() => {this.handleCheckboxAllRows(true)}}
											className='underline underline-offset-1 cursor-pointer	'>
												Select All
										</div>
										<div
											onClick={() => {this.handleCheckboxAllRows(false)}}
											className='underline underline-offset-1 cursor-pointer'>
												Clear
										</div>
									</div>
								</div>

								<div className='px-4 overflow-y-scroll max-h-[131px] custom-scroll'>
									<div className="grid grid-cols-8">
										{props.columns.map((column, index) => {
											return (
												<div className="__checkbox my-1 whitespace-pre" key={index}>
													<input
														id={`checkbox-data-adjust-${index}`}
														type="checkbox"
														name="email_columns"
														onChange={this.handleInjectColumn.bind(this, column)}
														checked={
															!isEmpty(selectedColumns.filter(selectedColumn => selectedColumn.selected).filter(selectedColumn => selectedColumn.key === column.key))
														}
													/>
													<label className="align-middle border-[#5d6e81] border-[1px]" htmlFor={`checkbox-data-adjust-${index}`}></label>
													<span className="ml-2 text-sm font-medium relative">
														<Tooltip title={column.label} placement="bottom" arrow>
															<span className="text-xs">{column.label}</span>
														</Tooltip>
														{column.is_custom_column && (
															<Tooltip title="This is a custom column" placement="bottom" arrow>
																<span className="cursor-pointer text-xs absolute -bottom-3 text-red-300 left-0">!Custom</span>
															</Tooltip>
														)}
													</span>
												</div>
											)
										})}
									</div>
								</div>
							</div>
						</div>
						{!isEmpty(selectedColumns) && props.data && (
							<div
								className={'border-t-[1px] w-full relative overflow-x-scroll overflow-y-hidden h-full z-[999999]' }
							>
								<div className="flex flex-col relative h-full">
									<div className=" sm:rounded-lg relative h-full">
										<div className="inline-block w-full relative h-full">
											<div className='h-full'>
												<EleftaTable
													separation
													disableFlow={true}
													checkbox={this.state.isVisibleMarkup}
													columns={state.selectedColumns.filter(selectedColumn => selectedColumn.selected)}
													dataSource={this.state.data}
													tableId={'elefta-table'}
													checkboxId={'checkbox-thread-all-grid'}
													onRowChecked={this.handleRowChecked}
													boxShadow={true}
													checkedRows={state.checkedRows}
													kind={'send_grid'}
												/>
											</div>
										</div>
									</div>
								</div>
							</div>
						)}
					</div>
				</div>
			</div>
		)
	}
}

const mapStateToProps = (state, props) => {
	return {
		profile: state.profile,
		loggedin: state.loggedin,
	}
}

const mapActionsToProps = {
	addToast,
}

export default connect(mapStateToProps, mapActionsToProps)(SendDataView)
