import React, {useState, useEffect, useRef} from 'react'
import classnames from 'classnames'
import TextField from '@mui/material/TextField'

import CustomerAutoSuggest from '../../Inventory/CustomerAutoSuggest/index'
import {Globals} from '../../../Context'
import find from 'lodash/find'

const SelectOptionsFlatMobile = ({name, options, selectedValue, extraValue, extraValueErrors, handleChangeSelect, handleChange, handleChangeAutoComplete, showCustomerAutoSuggest = false, isMobile}) => {
	const [showDropDown, setshow] = useState(false)
	const [showInput, setshowInput] = useState(false)
	const [selectedVal, setselectedVal] = useState(selectedValue)
	const [focusedItemIndex, setFocusedItemIndex] = useState(-1);
	const selectRef = useRef(null)

	useEffect(() => {
		const handleClickOutside = event => {
		if (showDropDown && selectRef.current && !selectRef.current.contains(event.target)) {
				setshow(false)
			}
		}
		if (showDropDown) {
			document.addEventListener('mousedown', handleClickOutside)
		}
		return () => {
			document.removeEventListener('mousedown', handleClickOutside)
		}
	}, [showDropDown])

	useEffect(() => {
		if (!['on_hand'].includes(selectedVal) && selectedVal) return setshowInput(true)
		return setshowInput(false)
	}, [selectedVal])

	const goToNextOption = (event, options) => {
		setFocusedItemIndex((prevIndex) => Math.min(prevIndex + 1, options.length - 1));
		event.stopPropagation();
	}

	const goToPreviousOption = (event) => {
		setFocusedItemIndex((prevIndex) => Math.max(prevIndex - 1, 0));
		event.stopPropagation();
	}

	const findOptionByCharacter = (event) => {
		event.preventDefault();
		const key = event?.key?.toLowerCase();
		const foundIndex = options.findIndex(option =>
			option.option.toLowerCase().startsWith(key)
		);
		setFocusedItemIndex(foundIndex);
	}

	const closeDropDown = (event) => {
		setshow(false)
		event.stopPropagation();
	}

	const handleKeyDown = (event) => {
	  switch (event.key) {
		case Globals.keyboardButton.arrowDown:
			goToNextOption(event, options)
			break;
		case Globals.keyboardButton.arrowUp:
			goToPreviousOption(event)
			break;
		case Globals.keyboardButton.escape:
			closeDropDown(event)
			break;
		  default:
			findOptionByCharacter(event)
		  break;
	  }
	};

	const textFieldNameMobile = () => {
		if(name === 'status') {
			return 'Status*'
		} else if(name === 'condition') {
			return 'Condition'
		} else if(name === 'warranty_papers') {
			return 'Warranty Paper/Card'
		}
	}

	const selectedOption = find(options, {value: selectedVal})

	return (
		<div className="flex flex-col" ref={selectRef}>
			<div tabIndex="0" onKeyDown={(event) => handleKeyDown(event)}  className="flex items-center relative outlin w-full block">
				{
					showDropDown && <div
						onClick={() => {
							setshow(!showDropDown)
						}}
						className={classnames('max-w-[150px] truncate text-[14px] font-bold cursor-pointer bg-white border-[#D9E2EE] text-[#5D6E81] rounded-[5px]',
						{
							'py-[0px]:': name == 'status',
							'py-[14px]:': name !== 'status',
						})}
					>
					{selectedOption?.option || selectedValue}
				</div>
				}
				<ul
					onClick={() => {
						setshow(!showDropDown)
					}}
					className={classnames('text-[14px] font-bold cursor-pointer bg-white border-[#D9E2EE] text-[#5D6E81] rounded-[5px] top-[0px]', {
						'absolute h-48 overflow-y-scroll z-10  px-2 py-[14px] shadow-2xl rounded border': showDropDown,
						'py-[0px] pb-[14px]:': name == 'status' && !showDropDown,
						'py-[14px]:': name !== 'status'
					})}
				>
					{!showDropDown && !selectedVal && <li className={classnames({'pb-2 truncate': showDropDown})}>
							<p className='truncate max-w-[150px]'>{isMobile ? textFieldNameMobile() :  'Please select'}</p>
						</li>}
					{!showDropDown && (
						<li className={classnames('truncate max-w-[100px]', {'pb-2': showDropDown})}>{selectedOption?.option || selectedValue}</li>
					)}
					{showDropDown && (
						<React.Fragment>
							{options.map((item, index) => {
								return (
									<li
										className="hover:text-gray-900 pb-4 pt-0 py-[14px] truncate transition px-2 truncate max-w-[150px]"
										onClick={e => {
											handleChangeSelect(e)
											setselectedVal(item.value)
										}}
										onKeyDown={(event) => {
											if(event.key === Globals.keyboardButton.enter) {
												setselectedVal(item.value)
												handleChangeSelect(event)
												setshow(false)
											}
										}}
										name={name}
										value={item.value}
										key={index}
										tabIndex={-1}
										ref={el => {
										  if (index === focusedItemIndex && el) el.focus();
										}}
									>
										{item.option ? item.option : item.value}
									</li>
								)
							})}
						</React.Fragment>
					)}
				</ul>
				<svg	className={classnames('bg-white pointer-events-none ml-2', {
						'opacity-0': showDropDown,
					})} xmlns="http://www.w3.org/2000/svg" width="10" height="6" viewBox="0 0 10 6" fill="none">
				<path fill-rule="evenodd" clip-rule="evenodd" d="M0.292893 0.292893C0.683416 -0.097631 1.31658 -0.097631 1.7071 0.292893L4.99999 3.58579L8.29288 0.292893C8.6834 -0.0976311 9.31657 -0.0976311 9.70709 0.292893C10.0976 0.683417 10.0976 1.31658 9.70709 1.70711L5.7071 5.70711C5.31657 6.09763 4.68341 6.09763 4.29289 5.70711L0.292893 1.70711C-0.0976309 1.31658 -0.0976309 0.683417 0.292893 0.292893Z" fill="#5D6E81"/>
				</svg>
			</div>
			{handleChangeAutoComplete && showInput && (
				<div className="max-w-[120px]">
					{showCustomerAutoSuggest && selectedVal === 'memo_to' || showCustomerAutoSuggest && selectedVal === 'pending_sale_to' ? (
						<div className="flex relative h-full">
							<CustomerAutoSuggest
								handleChangeAutoComplete={handleChangeAutoComplete}
								extraValueErrors={extraValueErrors}
								extraValue={extraValue}
								selectedVal={selectedVal}
								handleChange={handleChange}
								isMobile={isMobile}
								className={'rounded-[5px] h-full py-[16px] w-full outline-none text-[#5D6E81] text-[14px] font-bold'}
							/>
						</div>
					) : (

						<TextField
							size="small"
							variant="standard"
							margin="normal"
							fullWidth
							onChange={handleChange}
							type="text"
							name="status_details"
							placeholder={'Name'}
							defaultValue={extraValue}
							InputProps={{
								disableUnderline: true,
								style: {fontSize: 14, color:'#5D6E81', fontWeight:700, fontFamily: 'Poppins' }
							}}
							InputLabelProps={{
								style: {fontSize: 14, color:'#5D6E81'}
							}}
							sx={{
								padding: '0',
							}}
						/>
					)}
				</div>
			)}
		</div>
	)
}

export default SelectOptionsFlatMobile
