import {useState, useEffect} from 'react'

import {Globals} from '../../Context'
import { focusElement } from '../../utils/keyboardHandler'
import {onPressEscape} from '../../utils/keyboardHandler'
import isEmpty from 'lodash/isEmpty'
import isNaN from 'lodash/isNaN'
import toNumber from 'lodash/toNumber'
import compact from 'lodash/compact'

const TradeInDialog = ({hideTradeInDialog, props, updateTradeIn}) => {
	const [tradeInItem, setTradeInItem] = useState({product_name: '', sku: 'Trade-In', price: 0})
	const [error, setError] = useState({})

	const handleSubmit = () => {
		let buildError = {}
		if (isEmpty(tradeInItem.product_name)) buildError = {...buildError, product_name: 'Product Name is required'}
		if (isEmpty(tradeInItem.sku)) buildError = {...buildError, sku: 'Sku is required'}
		if (isNaN(toNumber(tradeInItem.price))) buildError = {...buildError, price: 'Price is required'}
		if (tradeInItem.price === 0) buildError = {...buildError, price: 'Price must be greater than 0'}
		setError(buildError)
		if(!isEmpty(buildError)) return
		updateTradeIn(tradeInItem)
		hideTradeInDialog()
		props.addToast({
			text: 'Trade-In item added',
			type: 'success',
		})
	}

	const displayError = key => {
		if (!isEmpty(error[key])) return <span className=" text-red-800 text-sm">{error[key]}</span>
	}

	useEffect(() => {
		focusElement('.trade-in-view')
	}, [])

	return (
		<div
			onKeyDown={onPressEscape((event) => hideTradeInDialog())}
			className="trade-in-view bg-black/50 bottom-0 fixed flex items-center justify-center left-0 right-0 top-0 z-50 md:p-3"
			tabIndex="-1"
		>
			<div className="max-w-xl w-full">
				<div className="bg-[#4B7CBE] max-h-[43px] flex items-center px-8 py-4 relative rounded-t-xl text-white w-full md:bg-slate-600 md:max-h-none md:rounded-t-xl">
					<span className="hidden text-white font-bold text-lg md:block">Add Trade-In Product</span>
					<span className="text-white font-bold text-[22px] uppercase	md:normal-case md:hidden">Add Trade-In</span>
					<button className="absolute right-0 mr-5 cursor-pointer" onClick={hideTradeInDialog}>
						<img className='hidden md:block' src="/Images/close.svg" alt="close" width={22} height={22} />
						<svg className='md:hidden' xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28" fill="none">
							<path d="M11.4874 14.1231L6.06906 8.51228L8.47885 6.18518L13.8972 11.796L19.8677 6.03036L22.1948 8.44015L16.2243 14.2058L21.6774 19.8526L19.2676 22.1797L13.8145 16.5329L7.80802 22.3333L5.48091 19.9235L11.4874 14.1231Z" fill="white"/>
						</svg>
					</button>
				</div>
				<div className="bg-white text-gray-500 px-8 py-4 w-full md:rounded-b-xl md:max-h-[320px] overflow-y-scroll">
					<div>
						<div className="md:grid md:grid-cols-2 md:gap-3 md:mt-5">
							<div className="flex justify-center mb-2 md:block">
								<label htmlFor="product_name" className="hidden font-normal md:block">
									Product Name
								</label>
								<input
									value={tradeInItem?.product_name}
									onChange={e => {
										if(isEmpty(compact(e.target.value.split(" "))) && !!e.target.value) return
										setTradeInItem({...tradeInItem, product_name: e.target.value})
									}}
									type="text"
									name="product_name"
									className="hidden py-3 p-4 w-full border rounded text-sm md:block"
								/>
								{/* mobile */}
								<div>
									<input
										value={tradeInItem?.product_name}
										onChange={e => {
											setTradeInItem({...tradeInItem, product_name: e.target.value})
										}}
										type="text"
										name="product_name"
										placeholder="Product Name"
										className="block py-3 p-4 w-full border rounded text-sm max-h-[34px] max-w-[270px] md:hidden"
									/>
									{displayError('product_name')}
								</div>
							</div>
						</div>
						<div className="md:grid md:grid-cols-2 md:gap-3 md:mt-5">
							<div className="flex justify-center mb-2 md:block">
								<label htmlFor="sku" className="hidden font-normal md:block">
									SKU
								</label>
								<input
									value={tradeInItem?.sku}
									onChange={e => {
										if(isEmpty(compact(e.target.value.split(" "))) && !!e.target.value) return
										setTradeInItem({...tradeInItem, sku: e.target.value})
									}}
									type="text"
									name="sku"
									className="hidden py-3 p-4 w-full border rounded text-sm md:block"
								/>
								{/* mobile */}
								<div>
									<input
										value={tradeInItem?.sku}
										onChange={e => {
											setTradeInItem({...tradeInItem, sku: e.target.value})
										}}
										type="text"
										name="sku"
										className="block py-3 p-4 w-full border rounded text-sm max-h-[34px] max-w-[270px] md:hidden"
									/>
									{displayError('sku')}
								</div>
							</div>
							<div className="flex justify-center mb-2 md:block">
								<label htmlFor="price" className="hidden font-normal md:block">
									Price
								</label>
								<input
									value={Globals.formatCurrency(tradeInItem?.price)}
									onChange={e => {
										setTradeInItem({...tradeInItem, price: parseFloat(e.target.value.replaceAll(',', ''))})
									}}
									type="text"
									name="price"
									className="hidden py-3 p-4 w-full border rounded text-sm md:block"
								/>
								<div>
									<input
										value={tradeInItem?.price ? Globals.formatCurrency(tradeInItem?.price) : ''}
										onChange={e => {
											setTradeInItem({...tradeInItem, price: parseFloat(e.target.value.replaceAll(',', ''))})
										}}
										type="text"
										name="price"
										placeholder="Price"
										className="block py-3 p-4 w-full border rounded text-sm max-h-[34px] max-w-[270px] md:hidden"
									/>
									{displayError('price')}
								</div>
							</div>
						</div>
						<div className="flex flex-row mt-4 md:mt-9 justify-center space-x-4 md:justify-end">
							<button
								className="border-2 cursor-pointer font-bold leading-5 my-2 px-8 py-2 rounded-md text-gray text-xs md:border-red-500 md:text-red-500 md:uppercase"
								onClick={hideTradeInDialog}
							>
								Cancel
							</button>
							<button className="bg-blue cursor-pointer font-bold leading-5 my-2 px-8 py-2 rounded-md text-white text-xs uppercase" onClick={() => handleSubmit()} type="submit">
								Save
							</button>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

export default TradeInDialog
