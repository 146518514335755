const Privacy = () => {
	return (
		<div className="absolute bottom-0 w-full">
			<div className="flex flex-row justify-center py-4 lg:py-8">
				<h1 className="text-xs font-normal leading-4 px-2 lg:px-10">Privacy Policy</h1>
				<h1 className="text-xs font-normal leading-4 px-2 lg:px-10">Terms of Use</h1>
				<h1 className="text-xs font-normal leading-4 px-2 lg:px-10">2022© Elefta.</h1>
			</div>
		</div>
	)
}

export default Privacy
