import React, {useEffect, useState} from 'react'
import {Globals} from '../../Context'
import Moment from 'moment'
import SingleItemDetail from '../Sales/SingleItemDetail'
import isEmpty from 'lodash/isEmpty'

const CustomerInvoices = ({props, singleCustomerView}) => {
	const [ordersList, setOrdersList] = useState()
	const [singleOrderItemId, setSingleOrderItemId] = useState()
	const [isVisibleSingleOrder, setIsVisibleSingleOrder] = useState(false)
	useEffect(() => {
		handleLoadOrders()
	}, [])
	const handleLoadOrders = () => {
		return Globals.New_Axios()
			.get(`${Globals.NEW_API_URL}/${Globals.ENDPOINTVERSION}/spaces/${Globals.SELECTEDSPACEID}/sales?q[customer_id_eq]=${singleCustomerView.id}`)
			.then(response => {
				if (response.status && response.status >= 200 && response.status < 300) {
					let orders = response.data.sales

					setOrdersList([...orders])
				}
			})
			.catch(error => {
				return props.addToast({
					text: 'Something went wrong please try again later.',
					type: 'error',
				})
			})
	}

	const loadSingleOrder = order => {
		setSingleOrderItemId(order.id)
		setIsVisibleSingleOrder(true)
	}
	const closeSingleOrderItem = () => {
		setIsVisibleSingleOrder(false)
	}

	const handleClickOut = $event => {
		$event.stopPropagation()

		if($event.target.classList.contains('single-customer-view')) {
			setSingleOrderItemId(null)
			setIsVisibleSingleOrder(false)
		}
	}


	return (
		<div>
			<h2 className="text-md font-bold tracking-wider py-2 border-b px-5">Invoices</h2>
			<div className="divide-gray-200 px-2 pb-28 overflow-y-scroll" style={{maxHeight: 'calc(100vh - 200px)'}}>
				<table className="table-fixed relative min-w-full border-b bg-transparent overflow-auto" style={{width: '100%', height: '100%'}}>
					<thead
						className="bg-white border-b"
						style={{
							position: 'sticky',
							top: '0',
							zIndex: 1,
						}}
					>
						<tr className="">
							<th className="py-2 pl-4 pr-2 text-sm font-semibold tracking-worderer">Order #</th>
							<th className="py-2 pr-2 text-sm font-semibold tracking-worderer">Date</th>
							<th className="py-2 pr-2 text-sm font-semibold tracking-worderer">Items</th>
							<th className="py-2 pr-2 text-sm font-semibold tracking-worderer">Invoice Amount</th>
							<th className="py-2 pr-2 text-sm font-semibold tracking-worderer">Balance</th>
							<th className="py-2 pr-2 text-sm font-semibold tracking-worderer">Status</th>
						</tr>
					</thead>

					{!isEmpty(ordersList) && (
						<tbody className="divide-y">
							{ordersList.map((order, index) => {
								return (
									<tr
										key={index}
										//	className={Classnames('cursor-pointer divide-x hover:bg-gray-100 bg-gray-50', {
										//'bg-white': (index + 1) % 2 === 0,
										//	})}
										className="hover:bg-gray-100 cursor-pointer"
										onClick={() => {
											loadSingleOrder(order)
										}}
									>
										<td className="py-2 pr-2 pl-4 text-sm font-normal tracking-worderer">#{order.order_number}</td>
										<td className="py-2 text-sm font-normal tracking-worderer">{Moment(order.created_at).format('MM/DD/YYYY')}</td>

										<td className="py-2 text-sm font-normal tracking-worderer">{order.items}</td>
										<td className="py-2 text-sm font-normal tracking-worderer">{Globals.moneyFormatter.format(parseFloat(order.total)).replace('.00', '').replace('$', '')}</td>
										<td className="py-2 text-sm font-normal tracking-worderer">{Globals.moneyFormatter.format(parseFloat(order.balance)).replace('.00', '').replace('$', '')}</td>
										<td className="py-2 text-sm font-normal tracking-worderer text-right pr-2">
											<div className="w-28">
												{order.status === 'pending_payment' && (
													<small className="max-w-full truncate bg-blue-100 text-blue-700 font-medium inline-block leading-loose px-3 rounded-3xl flex-inline items-center">
														<span className="w-1.5 h-1.5 inline-flex rounded-full bg-blue-700  mr-0.5"></span> Pending Payment
													</small>
												)}
												{order.status === 'payment_received' && (
													<small className="max-w-full truncate bg-green-100 text-green-700 font-medium inline-block leading-loose px-3 rounded-3xl flex-inline items-center">
														<span className="w-1.5 h-1.5 inline-flex rounded-full bg-green-700  mr-0.5"></span> Complete
													</small>
												)}
												{order.status === 'cancel' && (
													<small className="max-w-full truncate bg-yellow-100 text-yellow-700 font-medium inline-block leading-loose px-3 rounded-3xl flex-inline items-center">
														<span className="w-1.5 h-1.5 inline-flex rounded-full bg-yellow-700  mr-0.5"></span> Cancelled
													</small>
												)}
											</div>
										</td>
									</tr>
								)
							})}
						</tbody>
					)}
				</table>
				{isVisibleSingleOrder && <SingleItemDetail props={props} handleClickOut={handleClickOut} orderId={singleOrderItemId} closeSingleOrderItem={closeSingleOrderItem} currentSpace={props.currentSpace} profile={props.profile}/>}
			</div>
		</div>
	)
}
export default CustomerInvoices
