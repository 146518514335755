import React, {useRef, useEffect} from 'react'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import useOutsideClick from '../hooks/useOutsideClick'

import {Globals} from '../Context'

const PreviewImage = ({loadingImage, loadingImageFirst, closeGallery, activeGalleryItem, image, paginateSlider, handleLoadingImage}) => {
    const warpperImageRef = useRef(null)

	useEffect(() => {
		window.addEventListener('keydown', handleKeyPress)

		return () => {
			window.removeEventListener('keydown', handleKeyPress)
		}
	}, [])

	useOutsideClick(warpperImageRef, (event) => {
		if(event.target.getAttribute('text') === 'button' || loadingImage ) return
        closeGallery()
    });

	const handleKeyPress = event => {
		if (!image) return
		switch (event.key) {
			case Globals.keyboardButton.arrowRight:
                if(activeGalleryItem?.length === 1) return
				paginateSlider('next')
				break
			case Globals.keyboardButton.arrowLeft:
                if(activeGalleryItem?.length === 1) return
				paginateSlider('previous')
				break
			case Globals.keyboardButton.escape:
				closeGallery()
				break
			default:
				event.preventDefault();
				break
		}
	}

	return (
		<div className="single-product-view bg-black/50 fixed top-0 right-0 left-0 bottom-0 z-40">
			<div className="flex items-center md:upload-media-lightbox fixed top-0 right-0 left-0 bottom-0 z-50 md:p-11 bg-inherit" tabIndex="-1">
				<div className="upload-media-lightbox-inner w-full h-full block">
					{loadingImage && (
						<div className="absolute top-0 left-0 right-0 bottom-0 flex justify-center items-center z-20">
							<button
								type="button"
								className="inline-flex items-center px-4 py-2 leading-6 text-sm shadow rounded-md text-white bg-blue transition ease-in-out duration-150 cursor-not-allowed"
								disabled
                                text='button'
							>
								<svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
									{' '}
									<circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4" />
									<path
										className="opacity-75"
										fill="currentColor"
										d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
									/>
								</svg>
								<span>Loading... </span>
							</button>
						</div>
					)}
					<div className="upload-media-lightbox-image relative w-full h-full flex justify-center items-center p-[64px] md:p-4">
						<div ref={warpperImageRef} className="relative content-center lg:h-full">
							{!loadingImageFirst && (
								<button className="bg-black rounded-full p-2 absolute top-4 right-4 z-10" onClick={closeGallery}>
									<svg fill="white" width="18" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
										<path d="M14.8672 0.194897C14.625 0.200615 14.3949 0.301995 14.2271 0.47688L8.00026 6.70377L1.77337 0.47688C1.68784 0.38896 1.58554 0.319098 1.47252 0.271431C1.35951 0.223764 1.23807 0.199261 1.11541 0.199373C0.933136 0.199599 0.755062 0.254158 0.60394 0.35608C0.452819 0.458003 0.335512 0.602661 0.267004 0.771577C0.198497 0.940492 0.181898 1.126 0.21933 1.30439C0.256762 1.48278 0.346524 1.64597 0.47715 1.7731L6.70404 7.99999L0.47715 14.2269C0.389174 14.3113 0.318936 14.4125 0.27055 14.5245C0.222163 14.6364 0.196601 14.7569 0.19536 14.8789C0.194119 15.0008 0.217224 15.1218 0.263323 15.2347C0.309421 15.3476 0.377586 15.4502 0.463825 15.5364C0.550064 15.6227 0.652643 15.6908 0.765556 15.7369C0.878469 15.783 0.999445 15.8061 1.1214 15.8049C1.24335 15.8037 1.36383 15.7781 1.47578 15.7297C1.58774 15.6813 1.68891 15.6111 1.77337 15.5231L8.00026 9.29622L14.2271 15.5231C14.3116 15.6111 14.4128 15.6813 14.5247 15.7297C14.6367 15.7781 14.7572 15.8037 14.8791 15.8049C15.0011 15.8061 15.1221 15.783 15.235 15.7369C15.3479 15.6908 15.4505 15.6227 15.5367 15.5364C15.6229 15.4502 15.6911 15.3476 15.7372 15.2347C15.7833 15.1218 15.8064 15.0008 15.8052 14.8789C15.8039 14.7569 15.7784 14.6364 15.73 14.5245C15.6816 14.4125 15.6113 14.3113 15.5234 14.2269L9.29648 7.99999L15.5234 1.7731C15.6568 1.6452 15.7484 1.47997 15.7862 1.29908C15.824 1.11819 15.8063 0.93009 15.7354 0.759448C15.6644 0.588807 15.5436 0.443591 15.3886 0.342846C15.2337 0.2421 15.052 0.19053 14.8672 0.194897Z"></path>
									</svg>
								</button>
							)}
							{activeGalleryItem?.length > 1 && !loadingImageFirst && (
								<button
                                    className="border-black border-2 rounded-full p-2 absolute z-10 bottom-1/2 transform left-[-60px] translate-y-1/2 active:scale-90 bg-white"
                                    onClick={paginateSlider.bind(this, 'previous')}
                                    text='button'
								>
									<ChevronLeftIcon sx={{fontSize: '32px', color: 'black'}} />
								</button>
							)}
							{image?.type.includes('video') ? (
								<video className="w-full h-full" controls onLoadedData={handleLoadingImage}>
									<source src={image?.video_url} type="video/mp4" />
								</video>
							) : (
								<img src={image?.url} className="h-full w-full object-contain" alt="preview" onLoad={handleLoadingImage} onError={handleLoadingImage} />
							)}
							{activeGalleryItem?.length > 1 && !loadingImageFirst && (
								<button
									className="border-black border-2 rounded-full p-2 absolute z-10 right-[-60px] bottom-1/2 transform translate-y-1/2 active:scale-90 bg-white"
									onClick={paginateSlider.bind(this, 'next')}
                                    text='button'
								>
									<ChevronRightIcon sx={{fontSize: '32px', color: 'black'}} />
								</button>
							)}
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

export default PreviewImage
