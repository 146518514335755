import React, { useState, useEffect } from 'react'
import {connect} from 'react-redux'
import Classnames from 'classnames'
import {useNavigate} from 'react-router-dom'

import UploadMedia from '../../../UploadMedia'
import TermsDropdown from '../../../Sales/TermsDropdown'
import UserDropdownInvoice from './UserDropdownInvoice'
import {Globals} from '../../../../Context'
import { setCurrentSpace, addToast } from '../../../../app/appActions'
import isEmpty from 'lodash/isEmpty'
import FormControlLabel from '@mui/material/FormControlLabel'
import Typography from '@mui/material/Typography'
import RadioGroup from '@mui/material/RadioGroup'
import Radio from '@mui/material/Radio'

const maxRows = 8;
const Invoice = ({currentSpace, profile, setCurrentSpace, addToast}) => {
	const [isShowLimitReached, setIsShowLimitReached] = useState(false)
	const [formData, updateFormData] = useState({
		add_sale_tax: null,
		visible_salesperson: null,
		visible_terms_and_conditions: null,
		terms_and_conditions_content: null
	})
	const [loadedSpaceUsers, setLoadedSpaceUsers] = useState([])
	const [selectedSalesPerson, setSelectedSalesPerson] = useState()
	const [termCustomSelect, setTermCustomSelect] = useState({value: 'days', label: 'Days'})
	const [termCustomField, setTermCustomField] = useState('')
	const [selectedTerm, setSelectedTerm] = useState(Globals.saleTerms[0])
	const [formErrors, updateFormErrors] = useState({})
	const navigate = useNavigate()

	const uploadImages = pictures => {
		const lastPicture = pictures[pictures.length - 1]?.post_id

		updateFormData({
			...formData,
			logo_id: lastPicture?.id || "",
		})
	}

	const displayError = key => {
		if (!isEmpty(formErrors[key])) return <div className="inline-block text-sm my-2 rounded bg-red-200 p-1 px-4 font-medium text-red-700">{formErrors[key]}</div>
	}

	const handleChange = e => {
		if (!Globals.adminOrOwner(profile)) {
			e.preventDefault()
			return
		}

		updateFormData({
			...formData,
			[e.target.name]: e.target.value,
		})
	}

	// const handleChangeTextArea = e => {
	// 	if (!Globals.adminOrOwner(profile)) {
	// 		e.preventDefault()
	// 		return
	// 	}
	// 	updateFormData({
	// 		...formData,
	// 		[e.target.name]: e.target.value,
	// 	})
	// }

	useEffect(() => {
		handleLoadUsers()
	}, [])

	const handleLoadUsers = () => {
		return Globals.New_Axios()
			.get(`${Globals.NEW_API_URL}/${Globals.ENDPOINTVERSION}/spaces/${Globals.SELECTEDSPACEID}/users`, {
				params: {
					q: {
						s: 'created_at desc',
					},
					per_page: 10000,
				},
			})
			.then(response => {
				let {users} = response.data
				setLoadedSpaceUsers(users)
				setSelectedSalesPerson(users.find(user => user.id === currentSpace.invoice_setting.saleperson_id))
			})
			.catch(error => {
				const message = error.response?.data?.message || 'Something went wrong please try again later.'
				return addToast({
					text: message,
					type: 'error',
				})
			})
	}

	const handleChangeRadioButton = (event) => {
		if (!Globals.adminOrOwner(profile)) {
			event.preventDefault()
			return
		}

		const value = event.target.value === "true";
		updateFormData({
			...formData,
			[event.target.name]: value,
		})
	}

	const handleChangeTermCustomField = (event) => {
		const value = event.target.value;
        if (!isNaN(value) || value === '') {
			setTermCustomField(value)
        }
	}

	const handleSelectTerms = value => {
		setSelectedTerm(value)
	}

	const handleSelectCustomTerm = selectedTermObject => {
		if(selectedTermObject.value === 'custom') {
			setSelectedTerm(selectedTermObject)
			setTermCustomSelect({value: 'days', label: 'Days'})
			setTermCustomField('')
		} else {
			setTermCustomSelect(selectedTermObject)
		}
	}

	const handleSelectSalesPerson = user => {
		setSelectedSalesPerson(user)
	}

	useEffect(() => {
		updateFormData({
			bankName: currentSpace?.bank_name || "",
			bankAddress: currentSpace?.bank_address || "",
			bankAddress2: currentSpace?.bank_city_state_zip || "",
			accountName: currentSpace?.account_name || "",
			routingNumber: currentSpace?.account_routing_number || "",
			accountNumber: currentSpace?.account_number || "",
			logo: currentSpace?.invoice_setting?.logo,
			logo_id: currentSpace?.invoice_setting?.logo?.id || "",
			zelle_email_of_phone: currentSpace?.zelle_email_of_phone || "",
			add_sale_tax: currentSpace?.invoice_setting.add_sale_tax,
			bank_ach_routing_number: currentSpace?.bank_ach_routing_number || "",
			visible_salesperson: currentSpace?.invoice_setting.visible_salesperson,
			visible_terms_and_conditions: currentSpace?.invoice_setting.visible_terms_and_conditions,
			bank_swift_code: currentSpace?.bank_swift_code || "",
			terms_and_conditions_content: currentSpace?.invoice_setting.terms_and_conditions_content || "",
		})
		handleBuildDataCustomTerms()
	}, [currentSpace])

	const handleBuildDataCustomTerms = () => {
		if(!currentSpace?.invoice_setting.payment_term_method) return
		if(currentSpace?.invoice_setting.payment_term_method === 'cod') {
			return setSelectedTerm({value: currentSpace?.invoice_setting.payment_term_method, label: 'C.O.D.'})
		} else if(currentSpace?.invoice_setting.payment_term_method === 'net_3') {
			return setSelectedTerm({value: currentSpace?.invoice_setting.payment_term_method, label: 'Net 3 Days'})
		} else if(currentSpace?.invoice_setting.payment_term_method === 'net_7') {
			return setSelectedTerm({value: currentSpace?.invoice_setting.payment_term_method, label: 'Net 7 Days'})
		}  else if(currentSpace?.invoice_setting.payment_term_method === 'net_30') {
			return setSelectedTerm({value: currentSpace?.invoice_setting.payment_term_method, label: 'Net 30 Days'})
		} else if(currentSpace?.invoice_setting.payment_term_method === 'days') {
			setTermCustomField(currentSpace?.invoice_setting.payment_term_days)
			setTermCustomSelect({value: 'days', label: 'Days'})
			setSelectedTerm({value: 'custom', label: 'Custom'})
		} else if(currentSpace?.invoice_setting.payment_term_method === 'months') {
			setTermCustomField(currentSpace?.invoice_setting.payment_term_days)
			setTermCustomSelect({value: 'months', label: 'Months'})
			setSelectedTerm({value: 'custom', label: 'Custom'})
		}
	}

	const handleSubmit = event => {
		event.preventDefault()
		let errors = {}

		const formDataPost = new FormData()
		formDataPost.append('space[bank_name]', formData.bankName)
		formDataPost.append('space[bank_city_state_zip]', formData.bankAddress2)
		formDataPost.append('space[bank_address]', formData.bankAddress)
		formDataPost.append('space[account_name]', formData.accountName)
		formDataPost.append('space[account_routing_number]', formData.routingNumber)
		formDataPost.append('space[account_number]', formData.accountNumber)
		formDataPost.append('space[resale_certificate]', formData.certificate)
		formDataPost.append('space[invoice_setting_attributes][logo_id]', formData.logo_id)
		formDataPost.append('space[invoice_setting_attributes][add_sale_tax]', formData.add_sale_tax)
		formDataPost.append('space[invoice_setting_attributes][visible_salesperson]', formData.visible_salesperson)
		formDataPost.append('space[invoice_setting_attributes][visible_terms_and_conditions]', formData.visible_terms_and_conditions)
		formDataPost.append('space[bank_ach_routing_number]', formData.bank_ach_routing_number)
		formDataPost.append('space[invoice_setting_attributes][terms_and_conditions_content]', formData.terms_and_conditions_content)
		formDataPost.append('space[bank_swift_code]', formData.bank_swift_code)
		formDataPost.append('space[zelle_email_of_phone]', formData.zelle_email_of_phone)

		if(selectedSalesPerson) {
			formDataPost.append('space[invoice_setting_attributes][saleperson_id]', selectedSalesPerson.id)
		} else {
			formDataPost.append('space[invoice_setting_attributes][saleperson_id]]', null)
		}

		if (formData.visible_salesperson && isEmpty(selectedSalesPerson)) errors = { ...errors, salesperson: "Salesperson can't be blank" };

		if (selectedTerm.value === 'custom') {
			formDataPost.append('space[invoice_setting_attributes][payment_term_days]', termCustomField)
			formDataPost.append('space[invoice_setting_attributes][payment_term_method]', termCustomSelect.value)
		} else {
			formDataPost.append('space[invoice_setting_attributes][payment_term_method]', selectedTerm.value)
		}

		updateFormErrors(errors)

		if (isEmpty(errors)) {
			return Globals.New_Axios()
				.patch(`${Globals.NEW_API_URL}/${Globals.ENDPOINTVERSION}/spaces/${Globals.SELECTEDSPACEID}`, formDataPost)
				.then(async response => {
					setCurrentSpace(response.data);
					addToast({
						title: 'Space details',
						text: 'Your space details has been updated successfully',
						type: 'success',
					})

					return updateFormErrors({})
				})
				.catch(error => {
					errors = {
						...errors,
						error: error.response?.data?.message || 'Something went wrong please try again later.',
					}

					const updatedErrors =  error.response?.data?.message.replace(
						"Phone number is invalid",
						"Please check phone number in company details"
					  );

					addToast({
						text: updatedErrors || 'Something went wrong please try again later.',
						type: 'error',
					})

					updateFormErrors(errors)
				})
		}
	}

	const onPressEnterTermsAndConditions = (e) => {
		const { terms_and_conditions_content } = formData;
		const lines = terms_and_conditions_content.split('\n');

		if (lines.length >= maxRows && e.key === 'Enter') {
			setIsShowLimitReached(true);
			e.preventDefault();
			return;
		}
	}

	const handleChangeTermsAndConditions = (e) => {
		const value = e.target.value;
		const processedText = processTermsAndConditions(value);

		if (!Globals.adminOrOwner(profile)) {
			e.preventDefault();
			return;
		}

		updateFormData({
			...formData,
			[e.target.name]: processedText,
		});
	};

	const processTermsAndConditions = (value) => {
		const maxCharactersPerLine = 80;

		let lines = value.split('\n');

		let resultLines = [];
		let isMaxCharacters = false;
		for (let line of lines) {
			while (line.length > maxCharactersPerLine) {
				const substring = line.substring(0, maxCharactersPerLine);
				resultLines.push(substring);
				line = line.substring(maxCharactersPerLine);
				if(lines.length >= maxRows) {
					isMaxCharacters = true
				}
			}

			resultLines.push(line);

			if (resultLines.length >= maxRows) {
				resultLines = resultLines.slice(0, maxRows);
				break;
			}
		}
		// value is update able
		if (resultLines.join('\n') === value) {
			setIsShowLimitReached(false);
		} else {
			if(isMaxCharacters)	setIsShowLimitReached(true);
		}

		return resultLines.join('\n')
	};

	useEffect(() => {
		if (!Globals.adminOrOwner(profile)) {
			navigate('/inventory')
		}
	}, [])

	return (
		<div className="relative pb-24 w-full h-full">
			<div className="h-screen ">
				<form className="flex flex-col" onSubmit={handleSubmit}>
					<div className="grid gap-2 sm:grid-cols-1 lg:grid-cols-2">
						<div className="outlin relative w-full mt-4">
							<label htmlFor="Resale Certificate*" className=" text-[14px] text-[#5D6E81] font-bold py-2">
								Logo
							</label>
							{!formData?.logo?.id && (
								<UploadMedia
									innerClassNames="gap-2 grid grid-cols-4 mb-5 mt-2"
									text="Logo"
									uploadTo="uploadlogo"
									maximum={1}
									disabled={!Globals.adminOrOwner(profile)}
									uploadUpdate={uploadImages}
									allowedTypes={ ['image/jpeg', 'image/jpg', 'image/png']}
								/>
							)}
							{formData?.logo?.id && (
								<UploadMedia
									innerClassNames="gap-2 grid grid-cols-4 mb-5 mt-2"
									text="Upload"
									maximum={1}
									disabled={!Globals.adminOrOwner(profile)}
									uploadUpdate={uploadImages}
									uploadTo="uploadlogo"
									allowedTypes={ ['image/jpeg', 'image/jpg', 'image/png']}
									defaultValue={[formData.logo]}
								/>
							)}
						</div>
					</div>

					<div className="outlin relative w-full mt-4">
						<label htmlFor="Resale Certificate*" className=" text-[14px] text-[#5D6E81] font-bold py-2">
							Order Details
						</label>
						<div className='flex items-center p-2'>
							<Typography sx={{ fontWeight: 400, fontSize: 14, color: '#5D6E81' }}>
								Default Payment Terms:
							</Typography>
							<div className='ml-6'>
								<TermsDropdown
									disabled={!Globals.adminOrOwner(profile)}
									termOptions={Globals.saleTerms}
									selectedTerm={selectedTerm}
									onSelect={handleSelectTerms}
									termCustomField={termCustomField}
									handleChangeTermCustomField={handleChangeTermCustomField}
									termCustomSelect={termCustomSelect}
									handleSelectCustomTerm={handleSelectCustomTerm}
								/>
							</div>
						</div>
						<div className='flex items-center p-2'>
							<Typography sx={{ fontWeight: 400, fontSize: 14, color: '#5D6E81' }}>
								Do you want the ability to add sales tax to an invoice?
							</Typography>
							<RadioGroup
								value={formData.add_sale_tax}
								onChange={handleChangeRadioButton}
								name="add_sale_tax"
								row
								sx={{ml: 6}}
							>
								<FormControlLabel
									value={true}
									control={
										<Radio
											sx={{ '& .MuiSvgIcon-root': {
												fontSize: 14,
											}, }}
										/>}
									label="Yes"
								/>
								<FormControlLabel
									value={false}
									control={
										<Radio
											sx={{ '& .MuiSvgIcon-root': {
												fontSize: 14,
											}, }}
										/>
									}
								label="No" />
							</RadioGroup>
						</div>
						<div className='w-max'>
							<div className='flex items-center p-2'>
								<Typography sx={{ fontWeight: 400, fontSize: 14, color: '#5D6E81' }}>
									Do you want your salesperson visible on the invoice?
								</Typography>
								<RadioGroup
									value={formData.visible_salesperson}
									onChange={handleChangeRadioButton}
									name="visible_salesperson"
									row
									sx={{ml: 6}}
								>
									<FormControlLabel
										value={true}
										control={
											<Radio
												sx={{ '& .MuiSvgIcon-root': {
													fontSize: 14,
												}, }}
											/>}
										label="Yes"
									/>
									<FormControlLabel
										value={false}
										control={
											<Radio
												sx={{ '& .MuiSvgIcon-root': {
													fontSize: 14,
												}, }}
											/>
										}
									label="No" />
								</RadioGroup>
								{
									formData.visible_salesperson && (
										<UserDropdownInvoice
											disabled={!Globals.adminOrOwner(profile)}
											userOptions={loadedSpaceUsers}
											selectedUser={selectedSalesPerson}
											onSelect={handleSelectSalesPerson}
											className={"bg-white relative cursor-pointer rounded-[4px] border-[1px] border-[#5D6E81] text-center min-w-[120px] truncate"}
										/>
									)
								}
							</div>
							<div className='ml-auto w-max'>
								{displayError('salesperson')}
							</div>
						</div>

						<div className='flex items-center p-2'>
							<Typography sx={{ fontWeight: 400, fontSize: 14, color: '#5D6E81' }}>
								Do you want your terms and condition visible on the invoice?
							</Typography>
							<RadioGroup
								value={formData.visible_terms_and_conditions}
								onChange={handleChangeRadioButton}
								name="visible_terms_and_conditions"
								row
								sx={{ml: 6}}
							>
								<FormControlLabel
									value={true}
									control={
										<Radio
											sx={{ '& .MuiSvgIcon-root': {
												fontSize: 14,
											}, }}
										/>}
									label="Yes"
								/>
								<FormControlLabel
									value={false}
									control={
										<Radio
											sx={{ '& .MuiSvgIcon-root': {
												fontSize: 14,
											}, }}
										/>
									}
								label="No" />
							</RadioGroup>
						</div>
					</div>

					<div className='p2'>
						<div className='p-5 md:w-[640px]'>
							<textarea
								name='terms_and_conditions_content'
								className={Classnames('w-full border-[1px] p-[5px] rounded-lg text-[#5D6E81] text-[12px]',
									{'text-opacity-75': !formData.visible_terms_and_conditions}
								)}
								rows={8}
								disabled={!formData.visible_terms_and_conditions}
								onKeyDown={onPressEnterTermsAndConditions}
								onChange={handleChangeTermsAndConditions}
								value={formData.terms_and_conditions_content ? formData.terms_and_conditions_content : ''}
								placeholder={`We are an independent watch company, not an authorized dealer of any brand we\nsell. All watches sold by ${currentSpace.name} are second hand watches. The merchandise on\nthis invoice will remain ${currentSpace.name} property until this invoice is paid in full.\nAny fees incurred to collect payment or property will be paid by the purchaser\nlisted on this invoice. All items are sold ‘as-is’ and all sales are final, no\nexchanges or returns. By accepting this merchandise, you agree to these terms.\nWe have the right to take our property back if the terms are not followed.`}
							/>
							<div className='text-[13px] text-right'>
								{isShowLimitReached && <span className='text-red-500'>Limit reached</span>}
							</div>
						</div>
					</div>

					<div className="outlin relative w-full mt-4">
						<label htmlFor="Resale Certificate*" className=" text-[14px] text-[#5D6E81] font-bold py-2">
							Payment Details (optional)
						</label>
						<div className='flex items-center p-2'>
							<div className="grid gap-2 sm:grid-cols-1 lg:grid-cols-2 w-full">
								<div className="mt-2">
									<label htmlFor="Resale Certificate*" className="hidden text-base bg-transparent font-normal py-2">
										Bank Name
									</label>
									<div className="outlin relative w-full">
										<input
											value={formData?.bankName}
											type="text"
											name="bankName"
											placeholder=" "
											className="block px-4 pt-5 pb-3 w-full inputLogin appearance-none outline-none bg-transparent text-black text-sm font-medium"
											onChange={handleChange}
											disabled={!Globals.adminOrOwner(profile)}
										/>
										<label htmlFor="certificate" className="pointer-events-none absolute top-1 text-sm bg-transparent p-4 duration-300 origin-0 opacity-60">
											Bank Name
										</label>
									</div>
									{displayError('bankName')}
								</div>

								<div className="mt-2">
									<label htmlFor="Resale Certificate*" className="hidden text-base bg-transparent font-normal py-2">
										Bank Address
									</label>
									<div className="outlin relative w-full">
										<input
											value={formData?.bankAddress}
											type="text"
											name="bankAddress"
											placeholder=" "
											className="block px-4 pt-5 pb-3 w-full inputLogin appearance-none outline-none bg-transparent text-black text-sm font-medium"
											onChange={handleChange}
											disabled={!Globals.adminOrOwner(profile)}
										/>
										<label htmlFor="certificate" className="pointer-events-none absolute top-1 text-sm bg-transparent p-4 duration-300 origin-0 opacity-60">
											Bank Address
										</label>
									</div>
									{displayError('bankAddress')}
								</div>

								<div className="mt-2">
									<label htmlFor="Resale Certificate*" className="hidden text-base bg-transparent font-normal py-2">
										City, State Zip
									</label>
									<div className="outlin relative w-full">
										<input
											value={formData?.bankAddress2}
											type="text"
											name="bankAddress2"
											placeholder=" "
											className="block px-4 pt-5 pb-3 w-full inputLogin appearance-none outline-none bg-transparent text-black text-sm font-medium"
											onChange={handleChange}
											disabled={!Globals.adminOrOwner(profile)}
										/>
										<label htmlFor="certificate" className="pointer-events-none absolute top-1 text-sm bg-transparent p-4 duration-300 origin-0 opacity-60">
											City, State Zip
										</label>
									</div>
									{displayError('bankAddress2')}
								</div>

								<div className="mt-2">
									<label htmlFor="Resale Certificate*" className="hidden text-base bg-transparent font-normal py-2">
										Account Name
									</label>
									<div className="outlin relative w-full">
										<input
											value={formData?.accountName}
											type="text"
											name="accountName"
											placeholder=" "
											className="block px-4 pt-5 pb-3 w-full inputLogin appearance-none outline-none bg-transparent text-black text-sm font-medium"
											onChange={handleChange}
											disabled={!Globals.adminOrOwner(profile)}
										/>
										<label htmlFor="certificate" className="pointer-events-none absolute top-1 text-sm bg-transparent p-4 duration-300 origin-0 opacity-60">
											Account Name
										</label>
									</div>
									{displayError('accountName')}
								</div>

								<div className="mt-2">
									<label htmlFor="Resale Certificate*" className="hidden text-base bg-transparent font-normal py-2">
										Routing Number
									</label>
									<div className="outlin relative w-full">
										<input
											value={formData?.routingNumber}
											type="text"
											name="routingNumber"
											placeholder=" "
											className="block px-4 pt-5 pb-3 w-full inputLogin appearance-none outline-none bg-transparent text-black text-sm font-medium"
											onChange={handleChange}
											disabled={!Globals.adminOrOwner(profile)}
										/>
										<label htmlFor="certificate" className="pointer-events-none absolute top-1 text-sm bg-transparent p-4 duration-300 origin-0 opacity-60">
											Routing Number
										</label>
									</div>
									{displayError('routingNumber')}
								</div>

								<div className="mt-2">
									<label htmlFor="Resale Certificate*" className="hidden text-base bg-transparent font-normal py-2">
										Account Number
									</label>
									<div className="outlin relative w-full">
										<input
											value={formData?.accountNumber}
											type="text"
											name="accountNumber"
											placeholder=" "
											className="block px-4 pt-5 pb-3 w-full inputLogin appearance-none outline-none bg-transparent text-black text-sm font-medium"
											onChange={handleChange}
											disabled={!Globals.adminOrOwner(profile)}
										/>
										<label htmlFor="certificate" className="pointer-events-none absolute top-1 text-sm bg-transparent p-4 duration-300 origin-0 opacity-60">
											Account Number
										</label>
									</div>
									{displayError('accountNumber')}
								</div>

								<div className="mt-2">
									<label className="hidden text-base bg-transparent font-normal py-2">
										ACH Routing
									</label>
									<div className="outlin relative w-full">
										<input
											value={formData?.bank_ach_routing_number}
											type="text"
											name="bank_ach_routing_number"
											placeholder=" "
											className="block px-4 pt-5 pb-3 w-full inputLogin appearance-none outline-none bg-transparent text-black text-sm font-medium"
											onChange={handleChange}
											disabled={!Globals.adminOrOwner(profile)}
										/>
										<label className="pointer-events-none absolute top-1 text-sm bg-transparent p-4 duration-300 origin-0 opacity-60">
											ACH Routing
										</label>
									</div>
									{displayError('bank_ach_routing_number}')}
								</div>

								<div className="mt-2">
									<label className="hidden text-base bg-transparent font-normal py-2">
										Swift Code
									</label>
									<div className="outlin relative w-full">
										<input
											value={formData?.bank_swift_code}
											type="text"
											name="bank_swift_code"
											placeholder=" "
											className="block px-4 pt-5 pb-3 w-full inputLogin appearance-none outline-none bg-transparent text-black text-sm font-medium"
											onChange={handleChange}
											disabled={!Globals.adminOrOwner(profile)}
										/>
										<label className="pointer-events-none absolute top-1 text-sm bg-transparent p-4 duration-300 origin-0 opacity-60">
										Swift Code
										</label>
									</div>
									{displayError('bank_swift_code')}
								</div>

								<div className="mt-2">
									<label className="hidden text-base bg-transparent font-normal py-2">
										Zelle Email of Phone (Leave Blank to Omit)
									</label>
									<div className="outlin relative w-full">
										<input
											value={formData?.zelle_email_of_phone}
											type="text"
											name="zelle_email_of_phone"
											placeholder=" "
											className="block px-4 pt-5 pb-3 w-full inputLogin appearance-none outline-none bg-transparent text-black text-sm font-medium"
											onChange={handleChange}
											disabled={!Globals.adminOrOwner(profile)}
										/>
										<label className="pointer-events-none absolute top-1 text-sm bg-transparent p-4 duration-300 origin-0 opacity-60">
										Zelle Email of Phone (Leave Blank to Omit)
										</label>
									</div>
									{displayError('zelle_email_of_phone')}
								</div>
							</div>
						</div>
					</div>
					<div className='flex items-center justify-center p-10'>
						<button className=" border-[1px] border-[#2C79E0] text-[#2C79E0] px-3 py-1.5 cursor-pointer rounded-[5px] w-fit text-sm font-bold" type="submit">
							Apply
						</button>
					</div>
				</form>
			</div>
		</div>
	)
}

const mapStateToProps = (state, props) => {
	return {
		profile: state.profile,
		currentSpace: state.currentSpace,
	}
}

const mapActionsToProps = {
	setCurrentSpace,
	addToast
}

export default connect(mapStateToProps, mapActionsToProps)(Invoice)

