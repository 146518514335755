import React, { useState, useEffect } from 'react'
import { styled } from '@mui/material/styles';
import CloseIcon from '@mui/icons-material/Close';
import ControlPointOutlinedIcon from '@mui/icons-material/ControlPointOutlined';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import moment from 'moment';

import DatePickerModalRepair from './DatePickerModalRepair'
import { Globals } from '../../../Context'
import ConfirmPopUp from '../../ConfirmPopUp';
import isEmpty from 'lodash/isEmpty'
import DialogContent from '@mui/material/DialogContent'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import TextField from '@mui/material/TextField'
import InputAdornment from '@mui/material/InputAdornment'
import DialogActions from '@mui/material/DialogActions'
import Stack from '@mui/material/Stack'
import Button from '@mui/material/Button'

const CustomMobileModal = styled(Dialog)(({ theme }) => ({
    '& .MuiDialog-container': {
        height: '100%',
    },
    '& .MuiDialog-paper': {
        width: '100%',
        maxWidth: '100%',
        borderRadius: '20px 20px 0 0',
        margin: 0,

    },
}));

const formatDate = (date) => {
    return moment(date).format("M/D/YY");
}

const actions = [
    {
        name: 'show',
        label: 'repairs',
    },
    {
        name: 'edit',
        label: 'Edit Repair',
    },
    {
        name: 'add',
        label: 'Add Repair',
    },
];

const RepairModal = ({isOpen, closeModal, data, addToast}) => {
    const [repairs, setRepairs] = useState([])
    const [action, setAction] = useState(actions[0]);
    const [showTooltip, setShowTooltip] = useState(false);
    const [formErrors, setFormErrors] = useState([])
    const [selectedRepair, setSelectedRepair] = useState({
        description: '',
        cost: '',
        mark_up_sale_price: '',
        repair_date: formatDate(new Date()),
    });

    const loadRepairs = () => {
		Globals.New_Axios()
			.get(`${Globals.NEW_API_URL}/${Globals.ENDPOINTVERSION}/spaces/${Globals.SELECTEDSPACEID}/watches/${data?.id}/repair_watches?q[s]=created_at DESC`)
			.then(async response => {
				setRepairs(response.data.repair_watches)
			}).catch(error => {
                console.log(error)
            })
	}


	useEffect(() => {
		loadRepairs()
	}, [])

    const handleSubmit = () => {
        if (action.name === 'edit') {
            handleEditRepair();
        }
        if (action.name === 'add') {
            handleAddRepair();
        }
    }

    const displayError = key => {
        if (!isEmpty(formErrors[key])) return <div className="text-[14px] md:[16px] my-2 rounded bg-red-200 p-1 px-4 font-medium text-red-700">{formErrors[key]}</div>
    }

    const handleAddRepair = () => {
        let errors = []
        if(selectedRepair?.repair_date == 'Invalid date') {
            errors = {...errors, repair_date: 'Repair Date is required'}
        }
        if(selectedRepair.description == '') {
            errors = {...errors, description: 'Description is required'}
        }
        if(selectedRepair.cost == '') {
            errors = {...errors, cost: 'Cost is required'}
        }

        setFormErrors(errors)
        if(errors.length > 0) return
        Globals.New_Axios()
            .post(`${Globals.NEW_API_URL}/${Globals.ENDPOINTVERSION}/spaces/${Globals.SELECTEDSPACEID}/watches/${data.id}/repair_watches`, {
                repair_watch: {description: selectedRepair.description, repair_date: selectedRepair.repair_date, cost: selectedRepair.cost, mark_up_sale_price: selectedRepair.markupsaleprice},
            })
            .then(async response => {
                if (response.status && response.status >= 200 && response.status < 300) {
                    let repair = response.data
                    setFormErrors([])
                    setAction(actions[0]);
                    setRepairs([repair, ...repairs])
                }
            })
            .catch(error => {
            })
    }

    const handleEditRepair = () => {
        let errors = []
        if(selectedRepair?.repair_date == 'Invalid date') {
            errors = {...errors, repair_date: 'Repair Date is required'}
        }
        if(selectedRepair.description == '') {
            errors = {...errors, description: 'Description is required'}
        }
        if(selectedRepair.cost == '') {
            errors = {...errors, cost: 'Cost is required'}
        }

        setFormErrors(errors)
        if(errors.length > 0) return
        Globals.New_Axios()
        .patch(`${Globals.NEW_API_URL}/${Globals.ENDPOINTVERSION}/spaces/${Globals.SELECTEDSPACEID}/watches/${data.id}/repair_watches/${selectedRepair.id}`, {
            repair_watch: {description: selectedRepair.description, repair_date: selectedRepair.repair_date, cost: selectedRepair.cost, mark_up_sale_price: selectedRepair.mark_up_sale_price},
        })
        .then(async response => {
            if (response.status && response.status >= 200 && response.status < 300) {
                const newRepairs = repairs.map(repair => {
                    if (repair.id === response.data.id) {
                        repair.description = response.data.description
                        repair.repair_date = response.data.repair_date
                        repair.cost = response.data.cost
                        repair.mark_up_sale_price = response.data.mark_up_sale_price
                    }
                    return repair
                })
                setAction(actions[0]);
                setRepairs(newRepairs)
            }
        })
        .catch(error => {
        })
    }

    const handleDeleteRepair = (repairId) => {
        if (!repairId) return
		Globals.New_Axios()
			.delete(`${Globals.NEW_API_URL}/${Globals.ENDPOINTVERSION}/spaces/${Globals.SELECTEDSPACEID}/watches/${data.id}/repair_watches/${repairId}`)
			.then(async response => {
				const newRepairs = repairs.filter(repair => repair.id !== repairId)
				setRepairs(newRepairs)
                setAction(actions[0]);
			}).catch(error => {
			})
    }

    const handleCancelModal = () => {
        if (action.name === 'edit' || action.name === 'add') {
            setAction(actions[0]);
            setFormErrors([])
        } else {
            closeModal();
        }
    }

    const handleCloseModal = () => {
        closeModal();
        setAction(actions[0]);
        setFormErrors([])
    }

    const handleFieldChange = (field, value) => {
        setSelectedRepair(prev => ({...prev, [field]: value}));
    };

    const handlechangePicker = (date, name) => {
        setSelectedRepair(prev => ({...prev, [name]: moment(date).format('DD/MM/YYYY')}));
    };

    const heightCustom =() => {
        if(action.name !== 'show') {
            return 393
        } else {
            return 283
        }
    }

    return (
        <CustomMobileModal
            open={isOpen}
            onClose={handleCloseModal}
            sx={{
                '& .MuiDialog-paper': {
                    width: '100%',
                    maxWidth: '100%',
                    maxHeight: heightCustom(),
                    minHeight: heightCustom()
                },
            }}
        >
            <DialogTitle
                className="bg-[#4B7CBE] text-white h-[49px] flex items-center justify-between rounded-t-[12px]"
            >
                <div className="flex justify-between items-center w-full">
                    <Box className="flex items-center">
                        <span className="font-bold uppercase ms-2 text-[22px]">{action.label}</span>
                        {action.name === 'show' &&
                            <ControlPointOutlinedIcon className="ms-2"
                                onClick={() => {
                                    setSelectedRepair({
                                        description: '',
                                        cost: '',
                                        mark_up_sale_price: '',
                                        repair_date: formatDate(new Date()),
                                    });
                                    setAction(actions[2]);
                                }}
                            />
                        }
                    </Box>
                    <CloseIcon className="cursor-pointer font-bold" onClick={handleCancelModal} />
                </div>
            </DialogTitle>
            {/* ===================================================== */}
            {/* ====================== SHOW ========================= */}
            {/* ===================================================== */}
            {action.name === 'show' && (
                <React.Fragment>
                    <DialogContent className="mt-4">
                        <Grid container spacing={2} className="mt-12 mb-[15px]">
                            {repairs.length === 0 && <Grid className='flex justify-center' item xs={12}>Nothing to show yet!</Grid>}
                            {repairs.map((repair, index) => (
                                <Grid item xs={12} key={index}>
                                    <div className="flex flex-col mb-[10px] text-[#5D6E81]">
                                        <div className="text-[15px] mb-2">{repair.description}</div>
                                        <div className="text-[8px]">Date: {formatDate(repair.repair_date)}</div>
                                        <div className="flex w-full">
                                            <div>
                                                <div className="text-[8px]">Cost: {repair.cost ? `$${repair.cost}` : ''}</div>
                                                <div className="text-[8px]">{repair.mark_up_sale_price ? `Mark up Sale Price: $${repair.mark_up_sale_price}` : ''}</div>
                                            </div>
                                            <div className="flex items-end pb-[2px] ml-2">
                                                <span
                                                    className="text-[#F00] text-[6px]"
                                                    onClick={() => {
                                                        setSelectedRepair(repair);
                                                        setAction(actions[1]);
                                                    }}
                                                >
                                                    Edit
                                                </span>
                                                <ConfirmPopUp onConfirm={() => handleDeleteRepair(repair.id)} title="Delete Repair" content="Are you sure you want to delete it?">
                                                    <span
                                                        className="ms-2 text-[#F00] text-[6px]"
                                                    >
                                                        Delete
                                                    </span>
												</ConfirmPopUp>
                                            </div>
                                        </div>
                                    </div>
                                </Grid>
                            ))}
                        </Grid>
                    </DialogContent>
                </React.Fragment>
            )}
            {/* ===================================================== */}
            {/* ====================== CREATE/EDIT ========================= */}
            {/* ===================================================== */}
            {action.name !== 'show' && (
                <React.Fragment>
                    <DialogContent className="mt-4">
                        <Grid container spacing={1} className="mb-2">
                            <Grid item xs={12}>
                                <div className="text-[#0000005C]  text-[14px]">Description *</div>
                                <TextField
                                    placeholder="Add Details"
                                    value={selectedRepair.description}
                                    fullWidth
                                    size="small"
                                    multiline
                                    rows={3}
                                    InputProps={{ style: { fontSize: 14 } }}
                                    variant="outlined"
                                    onChange={(e) => handleFieldChange('description', e.target.value)}
                                />
                                {displayError('description')}
                            </Grid>

                            <Grid item xs={6}>
                                <div className="text-[#0000005C]  text-[14px]">Repair Date *</div>
                                <DatePickerModalRepair name={'repair_date'} handlechangePicker={handlechangePicker} defaultValue={selectedRepair.repair_date}/>
                                {displayError('repair_date')}
                            </Grid>

                            <Grid item xs={6}>
                                <div className="text-[#0000005C] text-[14px]">Cost *</div>
                                <TextField
                                    fullWidth
                                    value={selectedRepair.cost}
                                    size="small"
                                    type="number"
                                    variant="outlined"
                                    InputProps={{ style: { fontSize: 14 } }}
                                    onChange={(e) => handleFieldChange('cost', e.target.value)}
                                />
                                {displayError('cost')}
                            </Grid>

                            <Grid item xs={12} className='relative'>
                                <div className="text-[#0000005C] text-[14px]">Mark up Sale Price</div>
                                <TextField
                                    fullWidth
                                    size="small"
                                    value={selectedRepair.mark_up_sale_price}
                                    type="number"
                                    variant="outlined"
                                    onChange={(e) => handleFieldChange('mark_up_sale_price', e.target.value)}
                                    InputLabelProps={{ style: { pointerEvents: "auto" } }}
                                    InputProps={{
                                        style: { fontSize: 14 },
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <span className="text-[#e0e0e0]">$</span>
                                            </InputAdornment>
                                        ),
                                        endAdornment: (
                                            <InputAdornment position="end" onClick={()=> setShowTooltip(!showTooltip) }>
                                                    <InfoOutlinedIcon className="text-[#e0e0e0]" />
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                                {showTooltip &&
                                    <div className='flex items-center absolute right-[20px] top-[-40px] text-[14px] bg-[#D9D9D9] text-[#5D6E81] w-[169px] h-[79px]  text-center rounded-[5px]'>
                                        <p className='w-full'>
                                            This amount will be added to the item’s marketplace price
                                        </p>
                                    </div>
                                }
                            </Grid>

                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Stack
                            direction="row"
                            className="mx-auto"
                            spacing={2}
                            justifyContent={'center'}
                        >
                            <Button
                                variant="outlined"
                                sx={{ textTransform: 'none', boxShadow: 'none', border: '1px solid #C5CAD0', color: '#000'}}
                                className="w-[106px] h-[40px] whitespace-nowrap normal-case"
                                onClick={handleCancelModal}
                            >
                                Cancel
                            </Button>
                            <Button
                                variant="contained"
                                sx={{ textTransform: 'none', boxShadow: 'none' }}
                                className="w-[106px] h-[40px] normal-case whitespace-nowrap !bg-[#4B7CBE]"
                                onClick={handleSubmit}
                            >
                                Save
                            </Button>
                        </Stack>
                    </DialogActions>
                </React.Fragment>
            )}
        </CustomMobileModal>
    )
}

export default RepairModal
