import ActionCable from "actioncable";

import Classnames from 'classnames'
import React from 'react'
import {connect} from 'react-redux'

import ProductDetails from './Inventory/ProductDetails.jsx'
import {addToast, inventoryUpdateFilters, toggleSidebar, toggleDetailsCardInventory} from '../app/appActions.jsx'
import Tooltip from '@mui/material/Tooltip'
import {Globals} from '../Context.jsx'
import ColumnFilterDropDown from './ColumnFilterDropDown.jsx'
import * as collections from '../utils/collections.js'
import isFunction from 'lodash/isFunction'
import isBoolean from 'lodash/isBoolean'
import isObject from 'lodash/isObject'
import isEmpty from 'lodash/isEmpty'
import head from 'lodash/head'
import isNull from 'lodash/isNull'
import isNil from 'lodash/isNil'
import uniqBy from 'lodash/uniqBy'
import flatten from 'lodash/flatten'
import values from 'lodash/values'
import find from 'lodash/find'
import omit from 'lodash/omit'
import unionBy from 'lodash/unionBy'
import findIndex from 'lodash/findIndex'
import filter from 'lodash/filter'

// reference_number_custom_sort, status_custom_sort
const customSortFields = [
	'reference_number', 'status'
]

const ResizableTableHeadElement = function (res) {
	const triggerSort = direction => {
		if (!isFunction(res.sortAction)) return null
		return res.sortAction(this, direction)
	}

	if (res.key === 'Status') {
		return (
			<div className="relative">
				<ColumnFilterDropDown
					title={this.label}
					sortAction={res.sortAction}
					orderby={res.orderby}
					order={res.order}
					key="StatusFull"
					columnKey="StatusFull"
					triggerSort={triggerSort}
					dataPackage={res.dataPackage}
					dataSource={res.dataSource}
					isLast={res.isLast}
					isSecondLast={res.isSecondLast}
				/>
			</div>
		)
	}

	return (
		<div className="relative">
			<ColumnFilterDropDown
				title={this.label}
				sortAction={res.sortAction}
				orderby={res.orderby}
				order={res.order}
				key={res.key}
				columnKey={res.key}
				triggerSort={triggerSort}
				dataPackage={res.dataPackage}
				dataSource={res.dataSource}
				isLast={res.isLast}
				isSecondLast={res.isSecondLast}
			/>
		</div>
	)
}

const _dataListRenders = [
	{
		resize: true,
		checked: true,
		fixed: false,
		key: 'unknown',
		isCustom: false,
		data: ResizableTableHeadElement,
		render: item => {
			if (isBoolean(item)) {
				return (
					<div className="break-words whitespace-pre-wrap max-h-20"
						style={{
							height: 80,
							display: 'flex',
							alignItems: 'center'
						}}>
						{item ? 'Yes' : 'No'}
					</div>
				)
			} else {
				return (
					<div className="break-words whitespace-pre-wrap max-h-20" style={{
						height: 80,
						display: 'flex',
						alignItems: 'center'
					}}>
						{item ? item : '-'}
					</div>
				)
			}
		},
	},
	{
		resize: true,
		checked: true,
		fixed: false,
		key: 'addition_details',
		width: 400,
		isCustom: false,
		data: ResizableTableHeadElement,
		render: item => (
			<div className="break-words whitespace-pre-wrap max-h-20" style={{maxHeight: 70}}>
				{item ? item : '-'}
			</div>
		),
	},
	{
		resize: true,
		checked: true,
		fixed: false,
		key: 'msrp_price',
		isCustom: false,
		data: ResizableTableHeadElement,
		render: (item, record) => {
			return <div className="whitespace-nowrap max-h-20">{item ? record?.msrp_price_formatted : '-'}</div>
		},
	},
	{
		resize: true,
		checked: true,
		fixed: false,
		key: 'wholesale_price', // this is  Marketplace price
		isCustom: false,
		data: ResizableTableHeadElement,
		render: (item, record) => {
			return <div className="whitespace-nowrap max-h-20">{item ? record?.wholesale_price_formatted : '-'}</div>
		},
	},
	{
		resize: true,
		checked: true,
		fixed: false,
		key: 'online_price',
		isCustom: false,
		data: ResizableTableHeadElement,
		render: (item, record) => {
			return <div className="whitespace-nowrap max-h-20">{item ? record?.online_price_formatted : '-'}</div>
		},
	},
	{
		resize: true,
		checked: true,
		fixed: false,
		key: 'cost',
		isCustom: false,
		data: ResizableTableHeadElement,
		render: (item, record) => {
			return <div className="whitespace-nowrap max-h-20">{item ? record?.cost_formatted : '-'}</div>
		},
	},
	{
		resize: true,
		checked: true,
		fixed: false,
		key: 'images',
		width: 100,
		isCustom: false,
		data: ResizableTableHeadElement,
		render: item => {
			if (item) {
				try {
					let images = !isObject(item) ? JSON.parse(item) : item
					let imagesArr = !isEmpty(images) ? images : []
					return (
						<div
							style={{
								backgroundImage: images && !isEmpty(images) && head(images) && !isNull(images) ? `url('${head(images).url}')` : `url('/watch_icon.png')`,
								width: 80,
								height: 80,
								backgroundSize: 'cover',
								backgroundPosition: 'center',
							}}
							data-custom={`${Globals.shareDomain}/inventory/share/gallery/${btoa(imagesArr.join(','))}`}
						></div>
					)
				} catch (error) {
					return <div style={{width: 80, height: 80}}>ni ima</div>
				}
			} else {
				return (
					<div
						style={{
							backgroundImage: `url('/watch_icon.png')`,
							width: 80,
							height: 80,
							backgroundSize: 'cover',
							backgroundPosition: 'center',
						}}
					></div>
				)
			}
		},
	},
	{
		resize: true,
		checked: true,
		fixed: false,
		key: 'status',
		isCustom: false,
		data: ResizableTableHeadElement,
		render: (item, data, orderDetail) => {
			let titleToolTip = ''
			if(data.status !== 'other') {
				titleToolTip = Globals.inventoryStatuses[item]
			}
			if(!isEmpty(data.status_details) && data.status !== 'on_hand') {
				titleToolTip = titleToolTip + ` ${data.status_details}`
			}

			/*
			if (isEmpty(data.StatusDetails))
				return (
					<div className="break-words whitespace-pre-wrap max-h-20 " style={{maxHeight: 70}}>
						{item}
					</div>
				)
				*/
			return (
				<div className={Classnames('grid', {'pb-3': (!isNil(data.sale_id) && Globals.inventorySaleStatus.includes(data.status)) || (!isNil(data.memo_id) && Globals.inventoryMemoStatus.includes(data.status))})}>
					{
						titleToolTip.length > 45 ? (
							<Tooltip title={titleToolTip} placement="top" arrow>
								<div className={Classnames('whitespace-pre-wrap h-overflow-hidden line-clamp-4')}>
									{data.status !== 'other' &&`${Globals.inventoryStatuses[item]}`}
									{!isEmpty(data.status_details) && data.status !== 'on_hand' && ` ${data.status_details}`}
								</div>
							</Tooltip>
						) : (
							<div className={Classnames('whitespace-pre-wrap h-overflow-hidden line-clamp-4')}>
								{data.status !== 'other' &&`${Globals.inventoryStatuses[item]}`}
								{!isEmpty(data.status_details) && data.status !== 'on_hand' && ` ${data.status_details}`}
							</div>
						)
					}
					{((!isNull(data.sale_id) && Globals.inventorySaleStatus.includes(data.status)) || (!isNull(data.memo_id) && Globals.inventoryMemoStatus.includes(data.status))) && (
						<div className={Classnames('place-self-end -mr-2 -mb-2 absolute', {'-mb-3.5': (!isNil(data.sale_id) && Globals.inventorySaleStatus.includes(data.status)) || (!isNil(data.memo_id) && Globals.inventoryMemoStatus.includes(data.status))})} onClick={orderDetail}>
							<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="blue" class="w-4 h-4">
								<path
									stroke-linecap="round"
									stroke-linejoin="round"
									d="M13.5 6H5.25A2.25 2.25 0 003 8.25v10.5A2.25 2.25 0 005.25 21h10.5A2.25 2.25 0 0018 18.75V10.5m-10.5 6L21 3m0 0h-5.25M21 3v5.25"
								/>
							</svg>
						</div>
					)}
				</div>
			)
		},
	},
	{
		resize: true,
		checked: true,
		fixed: false,
		key: 'warranty',
		isCustom: false,
		data: ResizableTableHeadElement,
		render: (item, data) => {
			return (
				<div className="break-words whitespace-pre-wrap max-h-20" style={{maxHeight: 70}}>
					{data.display_warranty}
				</div>
			)
		},
	},
	{
		resize: true,
		checked: true,
		fixed: false,
		key: 'warranty_papers',
		isCustom: false,
		data: ResizableTableHeadElement,
		render: (item, data) => {
			if (data.warranty_papers === 'no') {
				return (
					<div className="break-words whitespace-pre-wrap max-h-20" style={{maxHeight: 70}}>
						No
					</div>
				)
			}

			const warrantyCardsList = uniqBy(flatten(values(Globals.warrantyCardsList)), 'value')
			const warrantyPapers = find(warrantyCardsList, {value: item})

			return (
				<div className="break-words whitespace-pre-wrap max-h-20" style={{maxHeight: 70}}>
					{isNil(item) ? '-' : warrantyPapers.option}
				</div>
			)
		},
	},
	{
		resize: true,
		checked: true,
		fixed: false,
		key: 'brand',
		isCustom: false,
		data: ResizableTableHeadElement,
		render: item => {
			return (
				<div className="break-words whitespace-pre-wrap max-h-20" style={{maxHeight: 70}}>
					{item ? Globals.getBrandName(item) : '-'}
				</div>
			)
		},
	},
	{
		resize: true,
		checked: true,
		fixed: false,
		key: 'condition',
		isCustom: false,
		data: ResizableTableHeadElement,
		render: item => {
			const watchCondition = find(collections.inventoryConditionsCollection, {value: item})

			return (
				<div className="break-words whitespace-pre-wrap max-h-20 capitalize" style={{maxHeight: 70}}>
					{item ? watchCondition.option : '-'}
				</div>
			)
		},
	},
	{
		resize: true,
		checked: true,
		fixed: false,
		key: 'date_purchased',
		isCustom: false,
		data: ResizableTableHeadElement,
		render: (item, data) => {
			return (
				<div className="break-words whitespace-pre-wrap max-h-20" style={{maxHeight: 70}}>
					{data?.date_purchased_formatted ? data.date_purchased_formatted : '-'}
				</div>
			)
		},
	},
]

let cableApp = {}

class DrawnWatchDetail extends React.Component {
	constructor() {
		super()
		this.fetchAllWatchesCountRef = React.createRef(1)
		this.state = {
			searchKey: null,
			isHiddenImportantDetails: false,
			openAddColumn: false,
			_dataList: [], //Will contain the default columns it will be fetched from server all time.
			columns: [],
			dataList: [],
			formErrors: [],
			dataProvider: [],
			filterDataProvider: [],
			columnDataProvider: [],
			searchDataProvider: [],
			originalDataProvider: [],
			loadingMoreData: false,
			singleDataRow: null,
			visibleAddToInventory: false,
			offset: 1,
			filter_values: [],
			filter_values_overall: [],
			visibleFilterToolView: false,
			loadedFilters: [],
			filterObject: null,


			//new added
			headerData: [],
			inventoryColumnFilters: {},
			searchTerm: '',
		}
		this.promisedSetState = this.promisedSetState.bind(this)
	}

	async componentDidUpdate(prevProps, prevState) {
		if (JSON.stringify(prevState.filter_values_overall) !== JSON.stringify(this.state.filter_values_overall)) {
			const filter_values = this.state.filter_values
			let columnsFilters = {}

			this.state.filter_values_overall.forEach((filter, index) => {
				let filterKey = filter.key
				if (filterKey === 'status') {
					// We override the key to use string instead of enum (integer) in backend code
					filterKey = 'display_status'
				}

				if (filterKey === 'condition') {
					// We override the key to use string instead of enum (integer) in backend code
					filterKey = 'display_condition'
				}

				if (filterKey === 'warranty_papers') {
					// We override the key to use string instead of enum (integer) in backend code
					filterKey = 'display_warranty_papers'
				}

				columnsFilters['g'] ||= {}
				if (filter.request.selectedFilter) {
					columnsFilters['g'][`${index}_free_text`] = {
						[`${filterKey}_i_${filter.request.selectedFilter.operator}`]: filter.request.selectedFilter.requiredInput ? filter.request.selectedFilter.values : true
					}
				}

				let filterCondition = {}
				if (filter.request.filter_values.length === 0) {
					// if you click clear all, it “removes” all the checks from all the values, but it still shows all the results | https://www.notion.so/swareco/Filters-TO-DO-1effa57bbaae4f00bb310942fa3c092d
					filterCondition = {
						[`${filterKey}_in`]: ['unselect all']
					}
				} else {
					const filterConditions = filter.request.filter_values.filter((item) => item !== '(Blanks)' && item !== 'No Date')
					const hasBlanks = filter.request.filter_values.includes('(Blanks)')

					filterCondition = {
						[`${filterKey}_in`]: filterConditions,
						[`${filterKey}_blank`]: hasBlanks,
						m: 'or'
					}

					if (filterKey === 'warranty') {
						const hasNoDate = filter.request.filter_values.includes('No Date')
						if (hasNoDate) {
							filterCondition['warranty_no_date_true'] = true
						}
					} else if (!hasBlanks) {
						filterCondition = omit(filterCondition, [`${filterKey}_blank`, 'm'])
					}
				}
				columnsFilters['g'][`${index}_values`] = filterCondition
			})

			this.setState(
				{
					filter_values: filter_values,
					inventoryColumnFilters: columnsFilters,
					offset: 1,
				},
				async () => {
					await this.getConditions()
					this.handleLoadFirstData()
				}
			)
		}
	}

	async componentDidMount() {
		const { currentSpace, profile } = this.props
		cableApp.cable = ActionCable.createConsumer(`${Globals.actionCableURL}?access_token=${Globals.getAccessToken()}`)
		cableApp.cable.subscriptions.create({channel: "WatchChannel", space_id: currentSpace.id}, {
			received: (data) => {
				if (data.action === 'create' && data.created_by_id !== profile.id) {
					this.handleInventoryUploadDone(data.watch, false, true, false)
				}
			}
		})
		const urlParams = new URLSearchParams(window.location.search)
		const search = urlParams.get('search')

		try {
			if (!this.props.loggedin) return window.location.replace('/login')

			await this.getColumList()
			await this.getConditions()

			if (!search) {
				await this.handleLoadFirstData()
			}

			await this.loadFilters()

			await this.handleListeningOnChanges() //Listen on any change in the data.
		} catch (error) {
		}
		//setting filters

		if (!isNull(search)) {
			setTimeout(async () => {
				await this.handleSearchByValue(search, 1)
			}, 1000)
		}
		///search by filter
		if (!isNull(urlParams.get('filter'))) {
			setTimeout(async () => {
				const { loadedFilters } = this.state
				let urlFilter = find(loadedFilters, {id: Number(urlParams.get('filter'))})
				if (urlFilter) await this.loadSelectedFilter(urlFilter)
			}, 1000)
		}
		//search by column
		if (!isNull(urlParams.get('filterByColumn'))) {
			setTimeout(() => {
				for (const key of urlParams.keys()) {
					let filterValues = decodeURIComponent(urlParams.get(key))
					filterValues = filterValues.split(',')
					if (key !== 'filterByColumn' && key !== 'search') {
						//	this.handleFilterAction({key: key}, {selectedFilter: null, filter_values: filterValues}, false)
					}
				}
			}, 1500)
		}
	}

	getConditions = () => {
		const { filterObject } = this.state

		Globals.New_Axios()
			.post(`${Globals.NEW_API_URL}/${Globals.ENDPOINTVERSION}/spaces/${Globals.SELECTEDSPACEID}/filters/inventory/conditions`, {
				filter_id: filterObject?.id,
				q: {
					[Globals.watchSearchParams]: this.state.searchTerm,
					...this.state.inventoryColumnFilters
				}
			})
			.then(response => {
				const { inventoryFilters } = this.props

				let dataDictionary = filter(response.data, (item) => {
					return !find(inventoryFilters, { key: item.column_key })
				})

				const { headerData } = this.state

				this.setState({
					headerData: unionBy(dataDictionary, headerData, 'column_key')
				})
			})
			.catch(err => {
				console.error(err)
			})
	}

	getColumList = async () => {
		await Globals.New_Axios()
			.get(`${Globals.NEW_API_URL}/${Globals.ENDPOINTVERSION}/spaces/${Globals.SELECTEDSPACEID}/column_configurations/inventory`)
			.then(response => {
				if (response.status && response.status >= 200 && response.status < 300) {
					let dataDictionary = response.data.column_configurations

					dataDictionary = dataDictionary.map(dd => {
						const columnRender = _dataListRenders.filter(dl => dl.key === dd.key)
						const columnUnknownRender = _dataListRenders.filter(dl => dl.key === 'unknown')
						if (!isEmpty(columnRender)) {
							//If the key exist in the data list renders then push to it the data (table row head render) and the table cell render.
							dd.data = head(columnRender).data
							dd.render = head(columnRender).render
							dd.resize = head(columnRender).resize
							dd.checked = head(columnRender).checked
							dd.fixed = head(columnRender).fixed
							dd.width = head(columnRender).width
						} else {
							//Other wise fetch the unknow key and push it to this (uknown key means render it as text).
							dd.data = head(columnUnknownRender).data
							dd.render = head(columnUnknownRender).render
							dd.resize = head(columnUnknownRender).resize
							dd.checked = head(columnUnknownRender).checked
							dd.fixed = head(columnUnknownRender).fixed
						}

						return dd
					})

					this.setState({
						_dataList: dataDictionary,
					})
					this.setState({
						columns: dataDictionary,
						dataList: dataDictionary,
					})
				}
			})
			.catch(err => {
				console.error(err)
			})
	}

	handleLoadFirstData = async () => {
		this.setState({ loadingMoreData: true });

		let { orderBy, order, filterObject } = this.state
		if (customSortFields.includes(orderBy)) {
			orderBy = `${orderBy}_custom_sort`
		}
		const sortParams = [`${orderBy} ${order}`]
		if (orderBy === 'warranty') {
			sortParams.unshift(`warranty_no_date ${order}`)
		}

		const pageRequest = `&page=${this.state.offset}`;
		const loadWatchesDataUrl = `${Globals.NEW_API_URL}/${Globals.ENDPOINTVERSION}/spaces/${Globals.SELECTEDSPACEID}/watches/list?kind=inventory&frontend_filter_enabled=true`

		const paramsQ = {
			s: sortParams,
			[Globals.watchSearchParams]: this.state.searchTerm,
			...this.state.inventoryColumnFilters
		}

		this.setState({
			loadWatchesDataConfig: {
				url: loadWatchesDataUrl,
				paramsQ: paramsQ
			}
		})

		try {
			const response = await Globals.New_Axios().post(`${loadWatchesDataUrl}${pageRequest}`, {
				q: paramsQ,
				filter_id: filterObject?.id,
			});

			if (response.status && response.status >= 200 && response.status < 300) {
				this.handleDataResponse(response.data);
			}
		} catch (error) {
			this.handleError(error);
		}
	};

	handleDataResponse = (data) => {
		const { current_page } = data;
		const newDataProvider = current_page === 1 ? data.watches : [...this.state.dataProvider, ...data.watches];

		// Update state with the new data
		this.setState(
			{
				dataProvider: newDataProvider,
				originalDataProvider: newDataProvider,
				offset: current_page + 1,
				loadingMoreData: false,
			},
			() => {
				isEmpty(this.state.inventoryColumnFilters) && this.props.inventoryUpdateFilters({});
			}
		);
	};

	promisedSetState = (newState) => new Promise(resolve => this.setState(newState, resolve));

	handleInventoryUploadDone = async (response, forceClose, isAddNewInventory=false, forceCloseAddWatch=true) => {
		if (forceClose) this.props.toggleDetailsCardInventory(false)

		if (isAddNewInventory) {
			await this.promisedSetState(
				{
					orderBy: 'created_at',
					order: 'DESC',
					offset: 0,
				}
			)

			this.handleLoadFirstData()
		} else {
			if (isFunction(this.props.refreshListAfterProductEdit)) this.props.refreshListAfterProductEdit(response)
			this.updateItemInInventories(response)
		}

		if (response && !isEmpty(this.state.singleDataRow)) {
			//Replace the single data row with the new data.

			//const singleDataRow = this.state.singleDataRow
			//const bundleData = response?.data

			this.setState({
				singleDataRow: response,
			})
		}
		if (forceCloseAddWatch) {
			this.setState({openAdd: false})
		}
	}

	updateItemInInventories = async (inventoryData) => {
		let  { dataProvider } = this.state
		let index = findIndex(dataProvider, { id: inventoryData.id })
		dataProvider[index] = inventoryData
		dataProvider = dataProvider.filter(inventory => inventory.status !== 'sold_to' && !inventory._destroy)
		this.setState(
			{
				dataProvider: [...dataProvider],
				originalDataProvider: [...dataProvider],
				loadingMoreData: false,
			}
		);
	};

	handleFilterAction = (keyData, request, isFilter = true) => {
		const urlParams = new URLSearchParams(window.location.search)
		let originalDataProvider
		if (!isNull(urlParams.get('search'))) {
			originalDataProvider = this.state.searchDataProvider
		} else {
			originalDataProvider = this.state.originalDataProvider
		}

		let dataProvider = originalDataProvider
		//Start with filter_value.
		const filter_values = this.state.filter_values
		filter_values[keyData.key] = request.filter_values

		this.setState({
			dataProvider: dataProvider,
			filterDataProvider: dataProvider,
			filter_values: filter_values,
		})
	}

	handleSearchByValue = (searchVal, offset = undefined) => {
		this.setState({searchKey: searchVal}, () => {
			this.setState(
				{
					searchTerm: searchVal,
					offset: offset || this.state.offset,
				},
				async () => {
					await this.getConditions()
					await this.handleLoadFirstData()
				}
			)
		})
	}

	loadSelectedFilter = (filters, $event) => {
		const urlParams = new URLSearchParams(window.location.search)
		if (isNull(urlParams.get('search'))) {
			let newurl = window.location.protocol + '//' + window.location.host + window.location.pathname + '?filter=' + filters.id
			window.history.pushState({path: newurl}, '', newurl)
		}


		this.setState({userSelectedFilter: filters.name, filterObject: filters})
		this.setState({
			visibleFilterToolView: !this.state.visibleFilterToolView,
		})
		filters.q_parameter.map(_filter => {
			return this.props.inventoryUpdateFilters({
				isFull: false,
				isPrimary: true,
				key: _filter.column_name,
				request: {filter_values: _filter.filter_values, selectedFilter: null},
			})
		})

		this.setState(
			{
				offset: 1,
			},
			async () => {
				await this.getConditions()
				await this.handleLoadFirstData()
			}
		)
	}

	render() {
		const state = this.state
		const props = this.props
		return (
			<>
				{props.isOpenDetailsInventory && (
					<ProductDetails
						data={props.data}
						columns={state.columns}
						toggleDetailsCardInventory={() => props.toggleDetailsCardInventory(!props.isOpenDetailsInventory)}
						handleInventoryUploadDone={this.handleInventoryUploadDone}
						userSelectedFilter={state.filterObject}
						loadSelectedFilter={this.loadSelectedFilter}
						handleFilterAction={this.handleFilterAction}
						filter_values_overall={state.filter_values_overall}
						handleSearchByValue={this.handleSearchByValue}
						getConditions={this.getConditions}
						searchKey={state.searchKey}
					/>
				)}
			</>
		)
	}
}

const mapStateToProps = (state, props) => {
	return {
		profile: state.profile,
		isOpenSideBar: state.isOpenSideBar,
		loggedin: state.loggedin,
		isOpenDetailsInventory: state.isOpenDetailsInventory,
		inventoryFilters: state.inventoryFilters,
		currentSpace: state.currentSpace,
	}
}

const mapActionsToProps = {
	toggleSidebar,
	toggleDetailsCardInventory,
	inventoryUpdateFilters,
	addToast,
}

export default connect(mapStateToProps, mapActionsToProps)(DrawnWatchDetail)
