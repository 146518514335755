import React, {useEffect, useRef} from 'react'
import classNames from 'classnames'

import {Globals} from '../../Context'
import { focusElement, onPressEscape, onPressFocusSearchInput } from '../../utils/keyboardHandler'
import WatchBoxDropdown from '../WatchBoxDropdown'
import isEmpty from 'lodash/isEmpty'
import head from 'lodash/head'
import isNull from 'lodash/isNull'

const SalesProductsHunter = ({loadedProducts, handleCloseProductsHunter, handleAddingItemToOrder, handleRemovingItemToOrder, memoedItems, handleSearchProducts, handleItemPriceChange, props, handleUpdateItemProduct, loadingProduct}) => {
	const searchInputRef = useRef(null);
	const addItem = item => {
		if (item.status.toLowerCase() !== 'on_hand') {
			props.addToast({
				title: 'Invoice',
				text: 'Item is already Memoed/Invoiced.',
				type: 'error',
			})
		} else {
			handleAddingItemToOrder(item)
		}
	}

	useEffect(() => {
		focusElement('.product-hunter-view')
		window.addEventListener('keydown', handleKeyDown);
		return () => {
		  window.removeEventListener('keydown', handleKeyDown);
		};
	}, [])

	const handleKeyDown = (event) => {
		onPressFocusSearchInput(event, searchInputRef.current)
	};

	return (
		<>
			<div
				onKeyDown={onPressEscape((event) => handleCloseProductsHunter())}
				className="hidden product-hunter-view bg-black/50 fixed top-0 right-0 left-0 bottom-0 z-40 md:block"
				tabIndex="-1"
			>
				<div
					className="bg-white rounded-tl-xl absolute top-0 right-0 shadow-xl overflow-hidden h-full"
					style={{
						maxWidth: '80%',
						width: '100%',
					}}
				>
					{
						loadingProduct &&
							<div className="absolute top-0 left-0 right-0 bottom-0 flex justify-center items-center z-50">
								<button
									type="button"
									className="inline-flex items-center px-4 py-2 leading-6 text-sm shadow rounded-md text-white bg-blue transition ease-in-out duration-150 cursor-not-allowed"
									disabled
								>
									<svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
										{' '}
										<circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4" />
										<path
											className="opacity-75"
											fill="currentColor"
											d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
										/>
									</svg>
									<span>Loading... </span>
								</button>
							</div>
					}
					<div className="flex items-center gap-3 bg-white border-b py-6 px-10">
						<div className="flex flex-row items-center justify-between w-full">
							<div className="flex gap-1">
								<button
									onClick={() => {
										handleCloseProductsHunter()
									}}
								>
									<svg className="w-6 h-6" viewBox="0 0 22 22" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
										<path d="M17.8672 3.19508C17.625 3.2008 17.3949 3.30218 17.2271 3.47706L11.0003 9.70395L4.77337 3.47706C4.68784 3.38914 4.58554 3.31928 4.47252 3.27161C4.35951 3.22395 4.23807 3.19944 4.11541 3.19956C3.93314 3.19978 3.75506 3.25434 3.60394 3.35626C3.45282 3.45819 3.33551 3.60284 3.267 3.77176C3.1985 3.94068 3.1819 4.12618 3.21933 4.30457C3.25676 4.48297 3.34652 4.64615 3.47715 4.77329L9.70404 11.0002L3.47715 17.2271C3.38917 17.3115 3.31894 17.4127 3.27055 17.5247C3.22216 17.6366 3.1966 17.7571 3.19536 17.879C3.19412 18.001 3.21722 18.122 3.26332 18.2349C3.30942 18.3478 3.37759 18.4504 3.46382 18.5366C3.55006 18.6229 3.65264 18.691 3.76556 18.7371C3.87847 18.7832 3.99944 18.8063 4.1214 18.8051C4.24335 18.8038 4.36383 18.7783 4.47578 18.7299C4.58774 18.6815 4.68891 18.6113 4.77337 18.5233L11.0003 12.2964L17.2271 18.5233C17.3116 18.6113 17.4128 18.6815 17.5247 18.7299C17.6367 18.7783 17.7572 18.8038 17.8791 18.8051C18.0011 18.8063 18.1221 18.7832 18.235 18.7371C18.3479 18.691 18.4505 18.6229 18.5367 18.5366C18.6229 18.4504 18.6911 18.3478 18.7372 18.2349C18.7833 18.122 18.8064 18.001 18.8052 17.879C18.8039 17.7571 18.7784 17.6366 18.73 17.5247C18.6816 17.4127 18.6113 17.3115 18.5234 17.2271L12.2965 11.0002L18.5234 4.77329C18.6568 4.64538 18.7484 4.48015 18.7862 4.29926C18.824 4.11837 18.8063 3.93027 18.7354 3.75963C18.6644 3.58899 18.5436 3.44377 18.3886 3.34303C18.2337 3.24228 18.052 3.19071 17.8672 3.19508Z" />
									</svg>
								</button>
								<h2 className="font-semibold text-2xl uppercase">Add ordered items</h2>
							</div>
						</div>
					</div>

					<div className="flex relative w-full justify-end items-center p-5 px-10">
						<div>
							<div className="text-gray-400 relative">
								<input
									ref={searchInputRef}
									className="text-gray-700 text-xs bg-white border flex items-center max-w-xs outline-blue-300 px-3 pr-9 relative rounded w-screen h-11"
									type="text"
									name="search"
									placeholder="Find a product by SKU"
									onChange={handleSearchProducts}
								/>
								<button className="pointer-events-none absolute right-0 top-0 h-full px-3">
									<svg className="w-6 h-6" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
										<path fillRule="evenodd" d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z" clipRule="evenodd" />
									</svg>
								</button>
							</div>
						</div>
					</div>
					<div className="max-h-full overflow-scroll overflow-x-hidden pb-60 px-10">
						<table className="relative min-w-full border-b bg-transparent overflow-scroll">
							<thead className="bg-white border-t border-b">
								<tr>
								<th className="w-5/12 py-2 pr-2 text-sm font-semibold tracking-worderer">Product</th>
								<th className="w-1/12 py-2 pr-2 text-sm font-semibold tracking-worderer"></th>
								<th className="py-2 pr-2 text-sm font-semibold tracking-worderer">SKU</th>
								<th className="w-1/12 py-2 pr-2 text-sm font-semibold tracking-worderer">Box</th>
								<th className="py-2 pr-2 text-sm font-semibold tracking-worderer">Wholesale Price</th>
								<th className="py-2 text-sm font-semibold tracking-worderer text-right">Action</th>
								</tr>
							</thead>
							<tbody className="divide-y">
								{loadedProducts?.map((product, index) => {
									return (
										<tr
											key={index}
											className={classNames('cursor-pointer hover:bg-gray-100', {
												'bg-white': !isEmpty(memoedItems) && isEmpty(memoedItems?.filter(item => item.id === product.id)),
												'bg-neutral-100': !isEmpty(memoedItems) && !isEmpty(memoedItems?.filter(item => item.id === product.id)),
											})}
											onClick={!product.inBag ? () => addItem(product) : handleRemovingItemToOrder.bind(this, product)}
										>
												<th className="py-2 pr-2 text-sm font-normal tracking-worderer">{product.name}</th>
												<th className="py-2 text-sm font-normal tracking-worderer"></th>
												<th className="py-2 text-sm font-normal tracking-worderer">{product.sku}</th>
												<th className="py-2 text-base font-normal tracking-worderer">
													<WatchBoxDropdown
														product={product}
														handleUpdateItemProduct={handleUpdateItemProduct}
													/>
												</th>
												<th className="py-2 text-sm font-normal tracking-worderer">
												<input
													onChange={e => {
														e.stopPropagation()
														handleItemPriceChange(product, e.target.value)
													}}
													onClick={e => {
														e.stopPropagation()
														handleItemPriceChange(product, e.target.value)
													}}
													type="text"
													className="border px-2 py-1 rounded"
													value={product.wholesale_price ? Globals.formatCurrency(product.wholesale_price) : ''}
													placeholder="Item Price"
													style={{
														maxWidth: 100,
													}}
												/>
											</th>
											<th className="py-2 text-sm font-normal tracking-worderer text-right">
												{!product.inBag && (
													<button
														// onClick={()=> handleAddingItemToOrder.bind(this, product)}
														className="inline-flex text-xs bg-green-100 text-green-700 border border-green-100 hover:bg-green-200 hover:border-green-200 transition rounded items-center pl-1 pr-2 py-0.5"
													>
														<svg className="w-4 h-4" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
															<path fillRule="evenodd" d="M10 5a1 1 0 011 1v3h3a1 1 0 110 2h-3v3a1 1 0 11-2 0v-3H6a1 1 0 110-2h3V6a1 1 0 011-1z" clipRule="evenodd" />
														</svg>
														<span>Add</span>
													</button>
												)}
												{product.inBag && (
													<button
														onClick={() => handleRemovingItemToOrder.bind(this, product)}
														className="inline-flex text-xs bg-red-100 text-red-700 border border-red-100 hover:bg-red-200 hover:border-red-200 transition rounded items-center pl-1 pr-2 py-0.5"
													>
														<svg className="w-4 h-4" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
															<path
																fillRule="evenodd"
																d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z"
																clipRule="evenodd"
															/>
														</svg>
														<span>Remove</span>
													</button>
												)}
											</th>
										</tr>
									)
								})}
							</tbody>
						</table>
						{isEmpty(loadedProducts) && <div className="text-xs py-4">No products found</div>}
					</div>
					<div className="absolute bg-white border-t bottom-0 flex justify-end px-10 py-3 w-full">
						<button
							className="hover:bg-blue-400 transition rounded bg-blue-500 text-white px-5 py-2 text-md"
							onClick={() => {
								handleCloseProductsHunter()
							}}
						>
							Done
						</button>
					</div>
				</div>
			</div>
			<div className="bg-black/50 bottom-0 fixed flex items-start justify-center inset-x-0 z-50 md:hidden h-screen">
				<div className="max-w-xl w-full mt-auto">
					<div className="bg-[#4B7CBE] max-h-[43px] flex items-center px-8 py-4 relative rounded-t-xl text-white w-full md:bg-slate-600 md:max-h-none md:rounded-t-xl">
						<span className="text-white font-bold text-lg uppercase">Add Product</span>
						<button className="absolute right-0 mr-5 cursor-pointer" onClick={handleCloseProductsHunter}>
							<img className='hidden md:block' src="/Images/close.svg" alt="close" width={22} height={22} />
							<svg className='md:hidden' xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28" fill="none">
								<path d="M11.4874 14.1231L6.06906 8.51228L8.47885 6.18518L13.8972 11.796L19.8677 6.03036L22.1948 8.44015L16.2243 14.2058L21.6774 19.8526L19.2676 22.1797L13.8145 16.5329L7.80802 22.3333L5.48091 19.9235L11.4874 14.1231Z" fill="white"/>
							</svg>
						</button>
					</div>
					<div className="bg-white text-gray-500 p-4 w-full overflow-y-scroll h-[80vh] pb-24 px-2 pt-2">
						<div className='flex justify-center text-sm font-medium text-center text-gray-500 items-center'>
							<div className="relative mt-2 mb-2 max-w-[320px]">
								<svg className='absolute top-[10px] left-[8px] bg-transparent' xmlns="http://www.w3.org/2000/svg" width="27" height="25" viewBox="0 0 27 25" fill="none">
									<path d="M20.361 20.1069L14.2853 14.3909C11.5825 16.1984 7.85809 15.7562 5.71743 13.3738C3.57676 10.9914 3.73394 7.46349 6.07902 5.2579C8.42349 3.05134 12.1745 2.90285 14.7077 4.91631C17.241 6.92977 17.7113 10.4333 15.7896 12.9759L21.8653 18.6919L20.3621 20.1059L20.361 20.1069ZM10.5888 5.49988C8.57279 5.49946 6.83351 6.83057 6.42398 8.68733C6.01445 10.5441 7.04722 12.4162 8.89702 13.1701C10.7468 13.9241 12.9052 13.3527 14.0655 11.8019C15.2257 10.2512 15.0689 8.14721 13.6899 6.7639L14.3331 7.3639L13.6081 6.6839L13.5953 6.6719C12.7999 5.91907 11.7172 5.49702 10.5888 5.49988Z" fill="black"/>
								</svg>
								<input
									type="search"
									className="p-2 pl-8 max-w-[320px]  border-2 rounded"
									placeholder="Search Inventory"
									onChange={handleSearchProducts}
								/>
							</div>
						</div>
						<div className="relative min-w-full border-b bg-transparent">
							<div className='min-w-full table-fixed'>
									{loadedProducts.map((productItem, index) => {
										return (
											<div
												key={index}
												onClick={!productItem.inBag ? () => addItem(productItem) : handleRemovingItemToOrder.bind(this, productItem)}
												className={classNames('rounded-[5px] grid grid-cols-4 gap-2 border-[1px] cursor-pointer', {
													'bg-slate-300': productItem.inBag,
												})}
											>
												<div className="py-4 px-2 text-sm font-normal tracking-worderer">
													<div
														className="bg-center bg-contain bg-white bg-no-repeat cursor-pointer h-16 md:h-24 m-auto w-16 md:w-24"
														style={{
															backgroundImage: head(productItem?.images) !== undefined ? `url('${head(productItem?.images).url}')` : `url('/watch_icon.png')`,
														}}
													></div>
												</div>
												<div className="flex flex-col justify-center text-[10px] font-normal tracking-worderer col-span-2">
														<div>
															<span className="font-semibold mr-1">
																SKU:
															</span>
															{ productItem?.sku}
														</div>
														<span className='min-w-[160px] tracking-worderer'>
															{!isNull(productItem) && productItem?.name}
														</span>
												</div>
												<div className="relative flex items-start text-sm font-semibold tracking-worderer mt-2 mr-2">
													<label className="absolute left-[5px] top-[1px] ">$</label>
													<input
														onChange={e => {
															e.stopPropagation()
															handleItemPriceChange(productItem, e.target.value)
														}}
														onClick={e => {
															e.stopPropagation()
															handleItemPriceChange(productItem, e.target.value)
														}}
														type="text"
														className="border rounded max-w-[63px] pl-3 rounded-[19px]"
														value={productItem.wholesale_price ? Globals.formatCurrency(productItem.wholesale_price) : ''}
													/>
												</div>
											</div>
										)
									})}
							</div>
						</div>
					</div>
				</div>
				<div className="absolute inset-x-0 bottom-8 mb-4 flex justify-center">
					<div className='flex justify-around items-center bg-[#4B7CBE] w-[225px] h-[36px] rounded-[30px] text-sm	'>
						<button
						onClick={handleCloseProductsHunter}
							className="text-[#FFFFFF] w-full h-full"
							type="button"
							>
							Cancel
						</button>
						<div className='border-[1px] h-[80%] border-[#ccc]'></div>
						<button
							onClick={handleCloseProductsHunter}
							className="text-[#FFFFFF] w-full h-full"
							type="button"
						>
							Save
						</button>
					</div>
				</div>
			</div>
		</>
	)
}

export default SalesProductsHunter
