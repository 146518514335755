import React from 'react'
import ClassNames from 'classnames'
import Moment from 'moment'
import Tooltip from '@mui/material/Tooltip'
import {connect} from 'react-redux'
import {
    LeadingActions,
    SwipeableListItem,
    SwipeAction,
    TrailingActions,
	Type as ListType,
} from 'react-swipeable-list';
import 'react-swipeable-list/dist/styles.css';

import { getUnopenedConversationCount, getConversationsCount } from '../../app/appActions'
import DropdownMenu from '../DropdownMenu'
import {Globals} from '../../Context'
import isNil from 'lodash/isNil'
import isEmpty from 'lodash/isEmpty'
import head from 'lodash/head'

const WatchImage = React.memo(function Image({ src }) {
    return (
        <div
            className="bg-center bg-contain bg-no-repeat cursor-pointer h-16 md:h-24 m-auto w-16 md:w-24 max-h-[60px] max-w-[60px]"
            style={{
                backgroundImage: src !== undefined ? `url('${src}')` : `url('/watch_icon.png')`,
            }}
        ></div>
    )
});

const SidebarItem = props => {
    const { previousActiveTab, selectedThread, thread, onOpenConversation, toggleReadStatus, tab, index, isDesktop = true, onRemoveThread } = props

    const deleteConversation = async () => {
        try {
            const response = await Globals.New_Axios().delete(`${Globals.baseUri}/conversations/${thread.id}`)
            if (response.status === 200) {
                onRemoveThread(thread)
            }
        } catch (error) {
            console.log(error)
        }
    }

    if (isNil(thread)) {
        return null
    }

    const trailingActions = () => {
		return(
			<TrailingActions>
                <SwipeAction
                    className="bg-[#E1373794]"
                    onClick={() => deleteConversation()}
                >
                    <img src="/Images/delete.svg" alt="delete.svg" width={70} className='mx-auto px-[15px]' />
                </SwipeAction>
			</TrailingActions>
		);
	}

	const leadingActions = () => {
		return(
			<LeadingActions className="">
				<SwipeAction onClick={() => toggleReadStatus(thread, !thread.read)} className="bg-[#3771E194]">
                    {
                        thread.read ? (
                            <img src="/Images/mail-unread.svg" alt="mail.svg" width={70} className='mx-auto px-[15px]' />
                        ) : (
                            <img src="/Images/mail-read.svg" alt="mail.svg" width={70} className='mx-auto px-[15px]' />
                        )
                    }
				</SwipeAction>
			</LeadingActions>
		)
	}

    if (isDesktop) {
        return (
            <div
                className={ClassNames(
                    'grid grid-cols-10 py-3 transition-all hover:opacity-90 cursor-pointer p-5 py-0 transition-all hove:opacity-80',
                    {
                        'bg-gray-50': !isEmpty(selectedThread) && selectedThread.id === thread.id,
                    }
                )}
                onClick={() => {
                    onOpenConversation(thread)
                }}
            >

                <div className="col-span-8 relative">
                    {
                        !thread.read && (
                            <div className="absolute top-[50%] mt-[-20px] left-[-18px] text-blue-500">
                                ●
                            </div>
                        )
                    }
                    <div className="flex flex-row gap-3 items-center">
                        <div className="basis-1/4">
                            <div className="inline-block rounded-full text-white overflow-hidden">
                                <WatchImage src={(thread.watch?.images && !!head(thread.watch.images)) ? `${head(thread.watch.images).url}` : `/watch_icon.png`} />
                            </div>
                        </div>
                        <div className="basis-full min-w-0">
                            <div className="">
                                {
                                    tab === 'buy' && (
                                        <h4 className="font-medium text-[14px] truncate text-[#333]">{thread.watch?.short_name}</h4>
                                    )
                                }

                                {
                                    tab === 'sell' && (
                                        <h4 className="font-medium text-[14px] truncate text-[#333]">{thread.watch?.short_name_sell}</h4>
                                    )
                                }
                                {thread.status === 'archived' ? (
                                    <div className="font-medium text-sm text-[#333] mt-1">
                                        No longer available
                                    </div>
                                ) : (!isNil(thread.watch?.wholesale_price) ? (
                                        <div className="font-medium text-sm text-blue-500 mt-1">
                                            {thread.watch?.wholesale_price_formatted}
                                        </div>
                                    ) :
                                    <div className="font-medium text-sm text-blue-500 mt-1">
                                        Contact for Price
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>

                <div className="col-span-2 min-w-0 relative">
                    <Tooltip title={Moment(thread.created_at).format('Do of MMMM YYYY @HH:mm')} placement="right" arrow>
                        <div className="pt-2.5 text-gray-400 text-xs text-right truncate">
                            {Moment(thread.created_at).format('MM/DD/YYYY')}
                        </div>
                    </Tooltip>

                    <DropdownMenu
                        containerStyles="text-[18px] leading-none text-right whitespace-nowrap cursor-pointer relative"
                        label="..."
                        items={[
                            {
                                label: thread.read ? 'Mark as unread' : 'Mark as read',
                                onClick: () => {
                                    toggleReadStatus(thread, !thread.read)
                                },
                            }
                        ]}
                    />
                    {
                        tab === 'buy' && (
                            <div className="bottom-0 text-gray-400 text-xs text-right absolute right-0 whitespace-nowrap">
                                {thread.watch?.space.name}
                            </div>
                        )
                    }

                    {
                        tab === 'sell' && (
                            <div className="flex flex-col text-gray-400 text-xs text-right absolute right-0 whitespace-nowrap">
                                <span>{thread.sender_space.name}</span>
                            </div>
                        )
                    }

                </div>
            </div>
        )
    }

    return (
        <SwipeableListItem
            trailingActions={trailingActions()}
            leadingActions={leadingActions()}
            maxSwipe={0.3}
            type={ListType.IOS}
            actionDelay={10000}
            destructiveCallbackDelay={10000}
            onClick={() => {
                onOpenConversation(thread)
            }}
            {...props}
        >
            <div className="grid relative border-b h-[79px] grow">
                <div className={ClassNames("grid grid-cols-12 bg-white z-10 ps-[34px] pe-[14px] px-6 py-3", {
                    '!bg-[#F4F4F4]': index % 2 !== 0,
                })}>
                    <div className="col-span-8 flex items-center relative">
                        {!thread.read}
                        <p
                            className={ClassNames('text-transparent text-xl absolute left-[-22px]', {
                                '!text-[#4B7CBE]': !thread.read,
                            })}
                        >
                            ●
                        </p>
                        <div>
                            <img
                                src={!!head(thread.watch?.images) ? `${head(thread.watch.images).url}` : `/watch_icon.png`}
                                alt="watch"
                                loading="lazy"
                                style={{maxWidth: 'unset', objectFit: 'contain'}}
                                className={ClassNames('bg-white rounded-full w-[45px] h-[45px] border', {
                                    'p-2': !head(thread.watch?.images),
                                })}
                            />
                        </div>
                        <div className="text-left ml-[10px]">
                            {(tab === 'sell' || (tab === 'search' && previousActiveTab === 'sell')) && (
                                <>
                                    <div className="font-semibold text-[14px] text-[#333]">{thread.sender_space.name}</div>
                                    <div className="line-clamp-1 text-[12px] w-10/12">
                                        <span className="font-semibold text-[#333] w-12 text-ellipsis overflow-hidden">{thread.watch?.short_name_sell}</span>
                                    </div>
                                </>
                            )}
                            {(tab === 'buy' || (tab === 'search' && previousActiveTab === 'buy')) && (
                                <>
                                    <div className="text-[13px] text-[#333333] line-clamp-2">
                                        <span className="font-semibold">{thread.watch?.short_name}
                                        </span>
                                    </div>
                                    <div className="font-bold text-[10px] text-[#000000] mt-1">{thread.watch?.space.name}</div>
                                </>
                            )}
                        </div>
                    </div>
                    <div className="col-span-4 text-right mt-2">
                        <div className="text-[#9E9E9E] font-medium text-[10px]">{Moment(thread.created_at).format('MMM DD')}</div>
                        <div className="text-[#1976D2] font-semibold text-[14px] break-words">
                            {!isNil(thread.watch?.wholesale_price) ? `$${thread.watch?.wholesale_price_formatted}` : 'Contact for Price'}
                        </div>
                    </div>
                </div>
            </div>
        </SwipeableListItem>
    )
}

const mapStateToProps = (state, props) => {
	return {
		profile: state.profile,
	}
}

const mapActionsToProps = {
    getUnopenedConversationCount,
    getConversationsCount
}

export default connect(mapStateToProps, mapActionsToProps)(SidebarItem)
