import React, {useMemo} from 'react'
import ReactDOM from 'react-dom/client'
import {BrowserRouter, Routes, Route} from 'react-router-dom'
import {Globals} from './Context'
import {Provider, useSelector} from 'react-redux'
import {store} from './app/store'
import {logOut} from './app/appActions'
import momentTimeZone from 'moment-timezone'

import reportWebVitals from './reportWebVitals'
import './index.css'

import Pickspace from './Components/Pickspace/Pickspace'
import DryConfirmation from './Components/DryConfirmation/DryConfirmation'
import SettingsVariable from './Context/SettingsVariable'
import AddInventory from './Components/Inventory/AddInventory'

import Bugsnag from '@bugsnag/js'

import BugsnagPluginReact from '@bugsnag/plugin-react'
import WebStore from './Pages/WebStore'
import {RequireHttps} from './utils/RequireHttps'
import {fetchProfile, getSpaceDetail} from './utils/UserSessionHelper'
import AppcuesService from './services/AppcuesService'
import {buildUserProperties} from './utils/AppcuesProperties'
import RouterWrapper from './RouterWrapper'
import Watch from './Components/Watch'
import NotFound from './Components/NotFound'
import PaymentSuccess from './Components/PaymentSuccess'
import MobileCheckOut from './Pages/MobileCheckOut'
import isEmpty from 'lodash/isEmpty'
import isNull from 'lodash/isNull'

import Login from './Pages/Login'
import ThankYouForSigningUp from './Pages/ThankYouForSigningUp'
import Register from './Pages/Register'
import InventoryShare from './Pages/InventoryShare'
import InventoryShareGallery from './Pages/InventoryShareGallery'
import InventoryItemShare from './Pages/InventoryItemShare'
import WebStoreExpired from './Pages/WebStoreExpired'
import PasswordSetting from './Pages/PasswordSetting'
import PasswordAuthentication from './Pages/PasswordAuthentication'
import TermsOfUse from './Pages/TermsOfUse'
import PrivacyPolicy from './Pages/PrivacyPolicy'
import Main from './Pages/Main'
import Inventory from './Pages/Inventory'
import SoldInventory from './Pages/SoldInventory'
import Marketplace from './Pages/Marketplace'
import Messages from './Pages/Messages'
import Memo from './Pages/Memo'
import Sales from './Pages/Sales'
import Customers from './Pages/Customers'
import Account from './Pages/Account'

let customErrorBoundaryPrevStoreData = {}
const CustomErrorBoundary = ({error, info, children}) => {
	const storeData = useSelector(state => ({
			profile: state.profile,
			space: state.currentSpace
		}
	))

	if (
		storeData.profile &&
		storeData.space &&
		customErrorBoundaryPrevStoreData.profile !== storeData.profile &&
		customErrorBoundaryPrevStoreData.space !== storeData.space
	  ) {
		const {profile: {id, full_name}, space} = storeData
		const sessionMetadata = {
			space_name: space.name,
			space_id: space.id,
			user_name: full_name,
			user_id: id
		}
		Bugsnag.start({
			apiKey: process.env.BUGSNAG_API_KEY,
			plugins: [new BugsnagPluginReact()],
			metadata: sessionMetadata,
			onError: (event) => {
				// Check if the error is a non-Error object or unhandled rejection
				if (event.errors[0].errorMessage.includes('unhandledrejection handler received a non-error')) {
					return false
				}
				event.addMetadata('meta', sessionMetadata)
				// Allow all other errors to be reported
				return true
			}
		})
	}

	if (customErrorBoundaryPrevStoreData !== storeData) {
		customErrorBoundaryPrevStoreData = storeData
	}

	const Component = useMemo(() => {
		return Bugsnag.isStarted() ? Bugsnag.getPlugin('react').createErrorBoundary(React) : React.Fragment
	}, [Bugsnag.isStarted()])
	return (
		<Component>
			{children}
		</Component>
	)
}
const init = async () => {
	if (window.location.host.startsWith('app.') &&
		(window.location.pathname.startsWith('/store/') || window.location.pathname.startsWith('/inventory/share/'))) {
		window.location.host = window.location.host.replace('app.', 'share.')
	}

	if (!window.location.pathname.startsWith('/store/')) {
		await fetchProfile()

		if (!!Globals.SELECTEDSPACEID) {
			await getSpaceDetail()
		}

		if (!!Globals.currentUserId) {
			const user = Globals.Cache.get(Globals.USERPROFILE)
			AppcuesService.initialize(Globals.currentUserId, buildUserProperties(JSON.parse(user)))
		}

		//Whenever the user login status change this will trigger.
		await Globals.loginChangeHandler(() => {
			store.dispatch(logOut())
		})
	}

	window.addEventListener('storage', (event) => {
		if (event.key === 'logoutEvent') {
			store.dispatch(logOut())
		} else if (event.key === 'loginSpace') {
			window.location = '/'
		} else if (event.key === 'logoutSpace') {
			Globals.Cache.deleteCookie(Globals.USERSELECTEDSPACE)
			Globals.Cache.deleteStorage(Globals.USERSELECTEDSPACE)
			Globals.Cache.deleteCookie(Globals.Sensitive_Data_Column)
			Globals.Cache.deleteStorage(Globals.Sensitive_Data_Column)
			window.location = '/'
		}
	})

	const root = ReactDOM.createRoot(document.getElementById('root'))

	if (!isEmpty(Globals.Cache.get(Globals.USERPROFILE)) && isNull(Globals.Cache.get(Globals.USERSELECTEDSPACE))) {
		return root.render(
			<>
				{process.env.NODE_ENV === 'production' && <RequireHttps />}
				<Provider store={store}>
					<CustomErrorBoundary>
						<SettingsVariable>
							<BrowserRouter>
								<Routes>
									<Route exact path="*" element={<Pickspace />} />
									<Route exact path="/pickspace"
										   element={<Pickspace />} />
									<Route exact path="/register/continue/:token"
										   element={<Register continuous />} />
									<Route exact path="/register/internal_invite/:token"
										   element={<Register internalInvite />} />
									<Route exact path="/thank-you"
										   element={<ThankYouForSigningUp />} />
									<Route exact path="/dry/confirmation/:token"
										   element={<DryConfirmation />} />
									<Route exact path="/inventory/share/:id"
										   element={<InventoryShare />} />
									<Route exact path="/inventory/share/gallery/:id"
										   element={<InventoryShareGallery />} />
									<Route exact path="/inventory/share/item/:id"
										   element={<InventoryItemShare />} />
									<Route exact path="/store/:uid/:slug"
										   element={<WebStore />} />
									<Route exact path="/store/:slug"
										   element={<WebStoreExpired />} />
									<Route exact path="/watches/:id"
										   element={<Watch />} />
									<Route exact path="/not-found"
										   element={<NotFound />} />
									<Route exact path="/payment-success"
									       element={<PaymentSuccess />} />
									<Route exact path="/mobile-checkout"
									       element={<MobileCheckOut/>} />
								</Routes>
							</BrowserRouter>
						</SettingsVariable>
					</CustomErrorBoundary>
				</Provider>
			</>
		)
	}

	if (isEmpty(Globals.Cache.get(Globals.USERPROFILE))) {
		return root.render(
			<>
				{process.env.NODE_ENV === 'production' && <RequireHttps />}
				<Provider store={store}>
					<CustomErrorBoundary>
						<SettingsVariable>
							<BrowserRouter>
								<Routes>
									<Route exact path="*" element={<Login />} />
									<Route exact path="/" element={<Login />} />
									<Route exact path="/login" element={<Login />} />
									<Route exact path="/thank-you"
										   element={<ThankYouForSigningUp />} />
									<Route exact path="/password-setting"
										   element={<PasswordSetting />} />
									<Route exact path="/reset/password/:token"
										   element={<PasswordAuthentication />} />
									<Route exact path="/dry/confirmation/:token"
										   element={<DryConfirmation />} />
									<Route exact path="/terms-of-use"
										   element={<TermsOfUse />} />
									<Route exact path="/privacy-policy"
										   element={<PrivacyPolicy />} />
									<Route exact path="/login/continue/:token"
										   element={<Login continuous />} />
									<Route exact path="/register"
										   element={<Register />} />
									<Route exact path="/register/continue/:token"
										   element={<Register continuous />} />
									<Route exact path="/register/internal_invite/:token"
										   element={<Register internalInvite />} />
									<Route exact path="/inventory/share/:id"
										   element={<InventoryShare />} />
									<Route exact path="/inventory/share/gallery/:id"
										   element={<InventoryShareGallery />} />
									<Route exact path="/inventory/share/item/:id"
										   element={<InventoryItemShare />} />
									<Route exact path="/store/:uid/:slug"
										   element={<WebStore />} />
									<Route exact path="/store/:slug"
										   element={<WebStoreExpired />} />
									<Route exact path="/watches/:id"
										   element={<Watch />} />
									<Route exact path="/not-found"
										   element={<NotFound />} />
									<Route exact path="/mobile-checkout"
									       element={<MobileCheckOut/>} />
								</Routes>
							</BrowserRouter>
						</SettingsVariable>
					</CustomErrorBoundary>
				</Provider>
			</>
		)
	}

	return root.render(
		<>
			{process.env.NODE_ENV === 'production' && <RequireHttps />}
			<Provider store={store}>
				<CustomErrorBoundary>
					<SettingsVariable>
						<BrowserRouter>
							<RouterWrapper>
								<Routes>
									<Route exact path="/" element={<Login />} />
									<Route exact path="/login" element={<Login />} />
									<Route exact path="/thank-you"
										   element={<ThankYouForSigningUp />} />
									<Route exact path="/dry/confirmation/:token"
										   element={<DryConfirmation />} />
									<Route exact path="/login/continue/:token"
										   element={<Login continuous />} />
									<Route exact path="/register"
										   element={<Register />} />
									<Route exact path="/register/continue/:token"
										   element={<Register continuous />} />
									<Route exact path="/register/internal_invite/:token"
										   element={<Register internalInvite />} />
									<Route exact path="/dashboard"
										   element={<Main />} />
									<Route exact path="/inventory"
										   element={<Inventory />} />
									<Route exact path="/inventory-sold"
										   element={<SoldInventory />} />
									<Route exact path="/inventory/add"
										   element={<AddInventory />} />
									<Route exact path="/marketplace"
										   element={<Marketplace />} />
									<Route exact path="/messages"
										   element={<Messages />} />
									<Route exact path="/messages/:conversation_id"
										   element={<Messages />} />
									<Route exact path="/messages/:type/:product"
										   element={<Messages />} />
									<Route exact path="/messages/:type/:product/:price"
										   element={<Messages />} />
									<Route exact path="/memo" element={<Memo />} />
									<Route exact path="/sales" element={<Sales />} />
									<Route exact path="/customers"
										   element={<Customers />} />
									<Route exact path="/account"
										   element={<Account />} />
									<Route exact path="/inventory/share/:id"
										   element={<InventoryShare />} />
									<Route exact path="/inventory/share/gallery/:id"
										   element={<InventoryShareGallery />} />
									<Route exact path="/inventory/share/item/:id"
										   element={<InventoryItemShare />} />
									<Route exact path="/store/:uid/:slug"
										   element={<WebStore />} />
									<Route exact path="/store/:slug"
										   element={<WebStoreExpired />} />
									<Route exact path="/watches/:id"
										   element={<Watch />} />
									<Route exact path="/not-found"
										   element={<NotFound />} />
									<Route exact path="/payment-success"
										   element={<PaymentSuccess />} />
									<Route exact path="/mobile-checkout"
									       element={<MobileCheckOut/>} />
								</Routes>
							</RouterWrapper>
						</BrowserRouter>
					</SettingsVariable>
				</CustomErrorBoundary>
			</Provider>
		</>
	)
}

init()
reportWebVitals()
