import React, { useEffect, useState } from 'react'
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { useForm, Controller } from 'react-hook-form'
import { useDispatch } from 'react-redux'

import { Globals } from '../../../Context'
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { addToast } from '../../../app/appActions'
import ConfirmPopUp from '../../ConfirmPopUp'
import isNil from 'lodash/isNil'
import isEmpty from 'lodash/isEmpty'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import Paper from '@mui/material/Paper'
import InputBase from '@mui/material/InputBase'
import IconButton from '@mui/material/IconButton'
import Box from '@mui/material/Box'
import RadioGroup from '@mui/material/RadioGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import Radio from '@mui/material/Radio'

const WebStoreContent = ({item, handleSetWebStoresAfterUpdate, handleSetWebStoresAfterDelete, handleSetWebStoresRegenerate, handleShowPassword, showPassword, profile, handleCancelWebstore}) => {
    const dispatch = useDispatch()
    const { handleSubmit, control, watch, reset, setValue } = useForm({});
    const [copyText, setCopyText] = useState('Click to Copy')
    const [uid, setUid] = useState('')
    const [formErrors, setFormErrors] = useState({})

    useEffect(() => {
        const defaultFormValues = {
            enable: item?.store_enabled,
            wholesale_price_visible: item?.wholesale_price_visible,
            markup_price_percentage: isNil(item?.markup_price_percentage) ? '' : item?.markup_price_percentage,
            markup_price_fixed:  isNil(item?.markup_price_fixed) ? '' : item?.markup_price_fixed,
            store_email_enabled: item?.store_email_enabled,
            store_email: item?.store_email || '',
            store_phone_enabled: item?.store_phone_enabled,
            store_phone: item?.store_phone || '',
            store_address_enabled: item?.store_address_enabled,
            password_required: item?.password_required,
            images_visible: item?.images_visible,
            store_password: item?.store_password || '',
            slug: item?.slug,
            view_ui: !!item?.view_ui ? (item?.view_ui === 'grid' ? true : false) : null
        };
        setUid(item?.uid)
        reset(defaultFormValues);
    }, [item]);

    const onSubmit = async (data) => {
        let errors = {}
        if(JSON.parse(data.password_required) && data.store_password === '') {
			errors = {...errors, store_password: 'Please enter your password.'}
            setFormErrors(errors)
        }
        let viewUI
        if(data?.view_ui === true || data?.view_ui === 'true') {
            viewUI = 'grid'
        } else if(data?.view_ui === false || data?.view_ui === 'false') {
            viewUI = 'card'
        } else {
            viewUI = null
			errors = {...errors, view_ui: 'Please select view UI.'}
            setFormErrors(errors)
        }

        if (!isEmpty(errors)) {
			return Object.values(errors)
				.reverse()
				.map((error) => {
					dispatch(
                        addToast({
                            title: 'Cannot save web store settings',
                            text: error,
                            type: 'error',
                        })
                    )
				})
		}

        const webStoreSettingData = {
            web_store_setting: {
                enabled: item.enabled,
                markup_price_fixed: data.markup_price_fixed,
                markup_price_percentage: data.markup_price_percentage,
                password_required: data.password_required,
                slug: data.slug,
                store_address_enabled: data.store_address_enabled,
                store_email: data.store_email,
                store_email_enabled: data.store_email_enabled,
                store_password: data.store_password,
                store_phone: data.store_phone,
                store_phone_enabled: data.store_phone_enabled,
                title: !!item.title ? item.title : '(Add Title)',
                wholesale_price_visible: data.wholesale_price_visible,
                images_visible: data.images_visible,
                view_ui: viewUI,
                uid: uid
            }
        }
        try {
            if(item.id) {
                const response = await Globals.New_Axios().patch(`${Globals.baseUri}/web_store_settings/${item.id}`, webStoreSettingData)
                handleSetWebStoresAfterUpdate(response.data)
            } else {
                const response = await Globals.New_Axios().post(`${Globals.baseUri}/web_store_settings`, webStoreSettingData)
                handleSetWebStoresAfterUpdate(response.data)
            }
            dispatch(
                addToast({
                    title: 'Success',
                    text: 'Web Store Setting has been saved.',
                    type: 'success',
                }))

            setFormErrors({})
        } catch (error) {
            dispatch(
                addToast({
                    title: 'Error',
                    text: error.response?.data?.message || 'Something went wront please try again later.',
                    type: 'error',
                }))

            setFormErrors({})
        }
    }

    const isPersisted = () => {
        return !!item.id;
    }

    const copySuccess = () => {
        dispatch(addToast({
            title: 'Success',
            text: 'URL Copied to Clipboard',
            type: 'success',
        }));
        setCopyText('Copied');

        setTimeout(() => {
            setCopyText('Click to Copy');
        }, 2000);
    };

    const copyError = (errorText) => {
        dispatch(addToast({
            title: 'Error',
            text: errorText,
            type: 'error',
        }));
    };

    const copyToClipboard = (text) => {
        if (navigator.clipboard && navigator.clipboard.writeText) {
            navigator.clipboard.writeText(text).then(copySuccess).catch(() => copyError('Could not copy text'));
        } else {
            copyError('Your browser does not support copying to clipboard');
        }
    };

    const handleChangeWholesalePrice = (event) => {
        let defaultValues = {
            ...watch(),
            [event.target.name]: event.target.value
        }
        if (event.target.name === "markup_price_percentage") {
            defaultValues = {
                ...defaultValues,
                markup_price_fixed: ''
            }
        } else {
            defaultValues = {
                ...defaultValues,
                markup_price_percentage: ''
            }
        }
        reset(defaultValues);
    }

    const phoneFormatter = (value) => {
        if (!value) return value;
        const currentValue = value.replace(/[^\d]/g, '');
        const cvLength = currentValue.length;

        if (cvLength < 4) return currentValue;
        if (cvLength < 7) return `${currentValue.slice(0, 3)}.${currentValue.slice(3)}`;
        return `${currentValue.slice(0, 3)}.${currentValue.slice(3, 6)}.${currentValue.slice(6, 10)}`;
    }


    const handleChangeLink = (event) => {
        let defaultValues = {
            ...watch(),
            [event.target.name]: event.target.value
        }

        reset(defaultValues);
    }

    const handleDeleteStore = async () => {
        try {
            if(item.id) {
                const response = await Globals.New_Axios().delete(`${Globals.baseUri}/web_store_settings/${item.id}`)
                handleSetWebStoresAfterDelete(response.data)
                dispatch(
                    addToast({
                        title: 'Success',
                        text: 'Web Store Setting has been deleted.',
                        type: 'success',
                    }))
            } else {
                dispatch(
                    addToast({
                        title: 'Error',
                        text: 'Web Store Not Delete',
                        type: 'error',
                    }))
            }

        } catch (error) {
            dispatch(
                addToast({
                    title: 'Error',
                    text: error.response?.data?.message || 'Something went wront please try again later.',
                    type: 'error',
                }))
        }
    }

    const handleRegenerateUniqueLink = async () => {
        if(item.id) {
            const response = await Globals.New_Axios().post(`${Globals.baseUri}/web_store_settings/regenerate?uid=${uid}`)
            handleSetWebStoresRegenerate(response.data.old_web_store_setting, response.data.new_web_store_setting)
        }
    }

    const displayError = key => {
        if (!isEmpty(formErrors[key])) return <div className="my-2 rounded bg-red-200 p-1 px-4 font-medium text-red-700 inline">{formErrors[key]}</div>
    }

    const isStoreEmailEnabled = watch('store_email_enabled') && watch('store_email_enabled').toString() === 'true'
    const isStorePhoneEnabled = watch('store_phone_enabled') && watch('store_phone_enabled').toString() === 'true'
    const isPasswordRequired = watch('password_required') && watch('password_required').toString() === 'true'

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <div className="flex mt-16">
                <div className="flex items-center">
                    <Typography variant="h6" sx={{ fontWeight: 'bold', mr: 2, fontSize: 26 }}>
                        Settings
                    </Typography>
                    <Paper sx={{ p: '2px 4px', display: 'flex', alignItems: 'center', }} variant="outlined">
                        <div>
                            <span className='text-[#5D6E81] text-[16px]'>{Globals.shareDomain + `/store/${uid}/`}</span>
                            <InputBase
                                name="slug"
                                sx={{ color: '#5D6E81', fontSize:16 }}
                                onChange={handleChangeLink}
                                value={watch('slug')}
                            />
                        </div>
                        <Box
                            sx={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}
                            onClick={() => copyToClipboard(Globals.shareDomain + `/store/${uid}/${watch('slug')}`)}
                        >
                            <IconButton type="button" sx={{  color: '#A2ACBA' }} aria-label="search">
                                <ContentCopyIcon sx={{ fontSize: 20 }}/>
                            </IconButton>
                            <Typography sx={{ color: '#A2ACBA', mr: 2, width: 'max-content', fontSize: 16 }} variant="inherit">
                                {copyText}
                            </Typography>
                        </Box>
                    </Paper>
                    <ConfirmPopUp
                        onConfirm={handleRegenerateUniqueLink}
                        title="Confirm Regeneration"
                        content="Regenerating this link will retain your current settings but remove access for anyone who previously had this link. Are you sure you want to proceed?"
                    >
                        <img onClick={handleRegenerateUniqueLink} src="/Images/regenerate.svg" className='ml-4 cursor-pointer'/>
                    </ConfirmPopUp>
                </div>
            </div>

            <div className="flex items-center mt-4">
                <div className="mr-6 text-[16px]">How do you want your inventory displayed?</div>
                <Controller
                    name="view_ui"
                    control={control}
                    defaultValue={null}
                    render={({ field }) => (
                        <RadioGroup {...field} row>
                            <FormControlLabel
                                value={true}
                                control={<Radio size="small" />}
                                label={
                                    <Box component="div">
                                        Grid View
                                    </Box>
                                }
                            />
                            <FormControlLabel
                                value={false}
                                control={<Radio size="small" />}
                                label={
                                    <div className='flex items-center'>
                                        <Box sx={{mr:1}} component="div">
                                            Card View
                                        </Box>
                                        <Box component="div" fontSize={13}>
                                            (Recommended for End-User)
                                        </Box>
                                    </div>
                                }
                            />
                        </RadioGroup>
                    )}
                />
            </div>
            {displayError('view_ui')}

            <div className="flex items-center mt-6">
                <div className="mr-6 text-[16px]">Do you want your images visible on the link?</div>
                    <Controller
                        name="images_visible"
                        control={control}
                        defaultValue={null}
                        render={({ field }) => (
                            <RadioGroup {...field} aria-labelledby="wholesale-price-visibility-group" row>
                                <FormControlLabel
                                    value={true}
                                    control={<Radio size="small" />}
                                    label={
                                        <Box component="div">
                                            Yes
                                        </Box>
                                    }
                                />
                                <FormControlLabel
                                    value={false}
                                    control={<Radio size="small" />}
                                    label={
                                        <Box component="div">
                                            No
                                        </Box>
                                    }
                                />
                            </RadioGroup>
                        )}
                    />
            </div>

            <div className="flex items-center mt-6">
                <div className="mr-6 text-[16px]">Do you want your address visible?</div>
                <Controller
                    name="store_address_enabled"
                    control={control}
                    defaultValue={null}
                    render={({ field }) => (
                        <RadioGroup {...field} row>
                            <FormControlLabel
                                value={true}
                                control={<Radio size="small" />}
                                label={
                                    <Box component="div">
                                        Yes
                                    </Box>
                                }
                                />
                            <FormControlLabel
                                value={false}
                                control={<Radio size="small" />}
                                label={
                                    <Box component="div">
                                        No
                                    </Box>
                                }
                            />
                        </RadioGroup>
                    )}
                />
            </div>


            <div className="flex items-center mt-4">
                <div className="mr-6 text-[16px]">Do you want your wholesale price visible on the link?</div>
                <Controller
                    name="wholesale_price_visible"
                    control={control}
                    defaultValue={null}
                    render={({ field }) => (
                        <RadioGroup {...field} aria-labelledby="wholesale-price-visibility-group" row>
                            <FormControlLabel
                                value={true}
                                control={<Radio size="small" />}
                                label={
                                    <Box component="div">
                                        Yes
                                    </Box>
                                }
                            />
                            <FormControlLabel
                                value={false}
                                control={<Radio size="small" />}
                                label={
                                    <Box component="div">
                                        No
                                    </Box>
                                }
                            />
                        </RadioGroup>
                    )}
                />
            </div>



            <div className="flex items-center mt-6">
                <div className="mr-6 text-[16px]">Would you like to mark-up your wholesale price?</div>
                <Controller
                    name="markup_price_percentage"
                    control={control}
                    render={({ field }) => {
                        return(
                            <TextField
                                {...field}
                                sx={{ width: 105, minWidth: 105}}
                                label="% Mark Up"
                                value={isNil(field.value) ? '' : field.value}
                                inputProps={{style: {color: '#5D6E81'}}}
                                InputLabelProps={{style: {color: '#5D6E81'}}}
                                variant="outlined"
                                size="small"
                                onChange={(event) => {
                                    handleChangeWholesalePrice(event)
                                }}
                            />
                        )
                    }}
                />
                <Controller
                    name="markup_price_fixed"
                    control={control}
                    render={({ field }) =>
                        <TextField
                            {...field}
                            sx={{ width: 105, minWidth:105, ml: 2,  "& .MuiInputBase-input": { height: 25 }}}
                            label="$ Mark Up"
                            variant="outlined"
                            value={isNil(field.value) ? '' : field.value}
                            size="small"
                            inputProps={{style: {color: '#5D6E81'}}}
                            InputLabelProps={{style: {color: '#5D6E81'}}}
                            onChange={(event) => {
                                handleChangeWholesalePrice(event)
                            }}
                        />
                    }
                />
            </div>

            <div className="flex items-center mt-6">
                <div className="mr-6 text-[16px]">Do you want your email visible?</div>
                <Controller
                    name="store_email_enabled"
                    control={control}
                    defaultValue={null}
                    render={({ field }) => (
                        <RadioGroup {...field} row>
                            <FormControlLabel
                                value={true}
                                control={<Radio size="small" />}
                                label={
                                    <Box component="div">
                                        Yes
                                    </Box>
                                }
                            />
                            <FormControlLabel
                                value={false}
                                control={<Radio size="small" />}
                                label={
                                    <Box component="div">
                                        No
                                    </Box>
                                }
                            />
                        </RadioGroup>
                    )}
                />
                {isStoreEmailEnabled &&
                    <Controller
                        name="store_email"
                        control={control}
                        defaultValue={''}
                        render={({ field }) =>
                        <TextField {...field}
                            sx={{ ml: 2, minWidth: '251px' }}
                            inputProps={{
                                style: {color: '#5D6E81'},
                                className: 'disabled:!bg-gray-200'
                            }}
                            InputLabelProps={{style: {color: '#5D6E81'}}}
                            label="Email"
                            variant="outlined"
                            size="small"
                        />}
                    />
                }
            </div>

            <div className="flex items-center mt-6">
                <div className="mr-6 text-[16px]">Do you want your phone number visible?</div>
                <Controller
                    name="store_phone_enabled"
                    control={control}
                    defaultValue={null}
                    render={({ field }) => (
                        <RadioGroup {...field} row>
                            <FormControlLabel
                                value={true}
                                control={<Radio size="small" />}
                                label={
                                    <Box component="div">
                                        Yes
                                    </Box>
                                }
                            />
                            <FormControlLabel
                                value={false}
                                control={<Radio size="small" />}
                                label={
                                    <Box component="div">
                                        No
                                    </Box>
                                }
                            />
                        </RadioGroup>
                    )}
                />
                {isStorePhoneEnabled  &&
                    <Controller
                        name="store_phone"
                        control={control}
                        defaultValue={''}
                        render={({ field }) => <TextField
                            {...field}
                            sx={{ ml: 2, minWidth: '251px' }}
                            label="Phone Number"
                            variant="outlined"
                            size="small"
                            inputProps={{
                                style: {color: '#5D6E81'},
                                className: 'disabled:!bg-gray-200'
                            }}
                            InputLabelProps={{style: {color: '#5D6E81'}}}
                            value={phoneFormatter(field.value)}
                            onChange={(e) => {
                                field.onChange(phoneFormatter(e.target.value))
                            }}
                        />}
                    />
                }

            </div>

            <div className="flex items-center mt-6">
                <div className="mr-6 text-[16px]">Would you like a password required to access your link?</div>

                <Controller
                    name="password_required"
                    control={control}
                    defaultValue={null}
                    render={({ field }) => (
                        <RadioGroup {...field} row>
                            <FormControlLabel
                                value={true}
                                control={<Radio size="small" />}
                                label={
                                    <Box component="div">
                                        Yes
                                    </Box>
                                }
                            />
                            <FormControlLabel
                                value={false}
                                control={<Radio size="small" />}
                                label={
                                    <Box component="div">
                                        No
                                    </Box>
                                }
                            />
                        </RadioGroup>
                    )}
                />

                {isPasswordRequired &&
                    <Controller
                        name="store_password"
                        control={control}
                        defaultValue={''}
                        render={({ field }) => <TextField
                            {...field}
                            sx={{ ml: 2, minWidth: '251px' }}
                            label="Password"
                            variant="outlined"
                            size="small"
                            classes={{ root: isPasswordRequired ? '' : 'bg-gray-200' }}
                            type={showPassword ? "text" : "password"}
                            InputLabelProps={{style: {color: '#5D6E81'}}}
                            InputProps={{
                                endAdornment: (
                                    <IconButton
                                        onClick={handleShowPassword}
                                        sx={{ p: '10px', color: '#A2ACBA', cursor: 'pointer' }}
                                        aria-label="search"
                                    >
                                        {showPassword ? <VisibilityOffIcon  sx={{ fontSize: 20 }}/> : <VisibilityIcon sx={{ fontSize: 20 }}/>}
                                    </IconButton>
                                ),
                                style: {color: '#5D6E81'}
                            }}
                        />}
                    />
                }

            </div>
            {displayError('store_password')}

            <div className="flex items-center mt-12 justify-center">
                {
                    isPersisted() && profile?.role === 'owner' && (
                        <ConfirmPopUp
                            onConfirm={handleDeleteStore}
                            title="Confirm deleting web store settings"
                            content="Are you sure you want to delete it?"
                        >
                            <div
                                className="flex justify-center p-2 lg:p-3 rounded-md text-xs font-bold leading-5 lg:text-sm text-[#FF0000] cursor-pointer uppercase border border-[#FF0000] w-[90px] h-[47px] mr-12"
                            >
                                <span>Delete</span>
                            </div>
                    </ConfirmPopUp>
                    )
                }
                {
                    !isPersisted() && (
                        <ConfirmPopUp
                            onConfirm={() => handleCancelWebstore(item)}
                            title="Confirm cancelling web store settings"
                            content="Are you sure you want to cancel it?"
                        >
                            <div
                                className="flex justify-center p-2 lg:p-3 rounded-md text-xs font-bold leading-5 lg:text-sm text-[#FF0000] cursor-pointer uppercase border border-[#FF0000] w-[90px] h-[47px] mr-12"
                            >
                                <span>Cancel</span>
                            </div>
                    </ConfirmPopUp>
                    )
                }
                <button
                    className="p-2 lg:p-3 rounded-md text-xs font-bold leading-5 lg:text-sm text-[#2C79E0] cursor-pointer uppercase border border-[#2C79E0] w-[90px] h-[47px]"
                    type="submit"
                >
                    <span>Save</span>
                </button>
            </div>
        </form>
    )
}

export default WebStoreContent
