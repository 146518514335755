import React, {useEffect, useRef} from 'react'
import Classnames from 'classnames'


import {focusElement, onPressEscape, onPressFocusSearchInput} from '../../utils/keyboardHandler'
import WatchBoxDropdown from '../WatchBoxDropdown'
import isEmpty from 'lodash/isEmpty'

const ProductsHunter = ({loadedProducts, handleCloseProductsHunter, handleAddingItemToMemo, handleRemovingItemToMemo, memoedItems, handleSearchProducts, handleItemPriceChange, props, handleUpdateItemProduct, loadingProduct}) => {
    const searchInputRef = useRef(null);
	const addItem = item => {
		if (item.status.toLowerCase() !== 'on_hand') {
			props.addToast({
				title: 'Memo',
				text: 'Item is already Memoed/Invoiced.',
				type: 'error',
			})
		} else {
			handleAddingItemToMemo(item)
		}
	}

	useEffect(() => {
		focusElement('.product-hunter-view')
		window.addEventListener('keydown', handleKeyDown);
		return () => {
		  window.removeEventListener('keydown', handleKeyDown);
		};
	}, [])


	const handleKeyDown = (event) => {
		onPressFocusSearchInput(event, searchInputRef.current)
	};

	return (
		<div
			onKeyDown={onPressEscape((event) => handleCloseProductsHunter())}
			className="product-hunter-view bg-black/50 fixed top-0 right-0 left-0 bottom-0 z-40" tabIndex="-1"
		>
			<div
				className="bg-white rounded-tl-xl absolute top-0 right-0 shadow-xl overflow-hidden h-full"
				style={{
					maxWidth: '80%',
					width: '100%',
				}}
			>
				{
					loadingProduct &&
					<div className="absolute top-0 left-0 right-0 bottom-0 flex justify-center items-center z-50">
						<button
							type="button"
							className="inline-flex items-center px-4 py-2 leading-6 text-sm shadow rounded-md text-white bg-blue transition ease-in-out duration-150 cursor-not-allowed"
							disabled
						>
							<svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
								{' '}
								<circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4" />
								<path
									className="opacity-75"
									fill="currentColor"
									d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
								/>
							</svg>
							<span>Loading... </span>
						</button>
					</div>
				}
				<div className="flex items-center gap-3 bg-white border-b py-6 px-10">
					<div className="flex flex-row items-center justify-between w-full">
						<div className="flex gap-1">
							<button
								onClick={() => {
									handleCloseProductsHunter()
								}}
							>
								<svg className="w-6 h-6" viewBox="0 0 22 22" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
									<path d="M17.8672 3.19508C17.625 3.2008 17.3949 3.30218 17.2271 3.47706L11.0003 9.70395L4.77337 3.47706C4.68784 3.38914 4.58554 3.31928 4.47252 3.27161C4.35951 3.22395 4.23807 3.19944 4.11541 3.19956C3.93314 3.19978 3.75506 3.25434 3.60394 3.35626C3.45282 3.45819 3.33551 3.60284 3.267 3.77176C3.1985 3.94068 3.1819 4.12618 3.21933 4.30457C3.25676 4.48297 3.34652 4.64615 3.47715 4.77329L9.70404 11.0002L3.47715 17.2271C3.38917 17.3115 3.31894 17.4127 3.27055 17.5247C3.22216 17.6366 3.1966 17.7571 3.19536 17.879C3.19412 18.001 3.21722 18.122 3.26332 18.2349C3.30942 18.3478 3.37759 18.4504 3.46382 18.5366C3.55006 18.6229 3.65264 18.691 3.76556 18.7371C3.87847 18.7832 3.99944 18.8063 4.1214 18.8051C4.24335 18.8038 4.36383 18.7783 4.47578 18.7299C4.58774 18.6815 4.68891 18.6113 4.77337 18.5233L11.0003 12.2964L17.2271 18.5233C17.3116 18.6113 17.4128 18.6815 17.5247 18.7299C17.6367 18.7783 17.7572 18.8038 17.8791 18.8051C18.0011 18.8063 18.1221 18.7832 18.235 18.7371C18.3479 18.691 18.4505 18.6229 18.5367 18.5366C18.6229 18.4504 18.6911 18.3478 18.7372 18.2349C18.7833 18.122 18.8064 18.001 18.8052 17.879C18.8039 17.7571 18.7784 17.6366 18.73 17.5247C18.6816 17.4127 18.6113 17.3115 18.5234 17.2271L12.2965 11.0002L18.5234 4.77329C18.6568 4.64538 18.7484 4.48015 18.7862 4.29926C18.824 4.11837 18.8063 3.93027 18.7354 3.75963C18.6644 3.58899 18.5436 3.44377 18.3886 3.34303C18.2337 3.24228 18.052 3.19071 17.8672 3.19508Z" />
								</svg>
							</button>
							<h2 className="font-semibold text-2xl uppercase">Add memoed items</h2>
						</div>
					</div>
				</div>

				<div className="flex relative w-full justify-end items-center p-5 px-10">
					<div>
						<div className="text-gray-400 relative">
							<input
								ref={searchInputRef}
								className="text-gray-700 text-xs bg-white border flex items-center max-w-xs outline-blue-300 px-3 pr-9 relative rounded w-screen h-11"
								type="text"
								name="search"
								placeholder="Find a product by SKU"
								onChange={handleSearchProducts}
							/>
							<button className="pointer-events-none absolute right-0 top-0 h-full px-3">
								<svg className="w-6 h-6" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
									<path fillRule="evenodd" d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z" clipRule="evenodd" />
								</svg>
							</button>
						</div>
					</div>
				</div>
				<div className="max-h-full overflow-scroll overflow-x-hidden pb-60 px-10">
					<table className="relative min-w-full border-b bg-transparent overflow-scroll">
						<thead className="bg-white border-t border-b">
							<tr>
								<th className="w-5/12 py-2 pr-2 text-sm font-semibold tracking-worderer">Product</th>
								<th className="w-1/12 py-2 pr-2 text-sm font-semibold tracking-worderer"></th>
								<th className="py-2 pr-2 text-sm font-semibold tracking-worderer">SKU</th>
								<th className="w-1/12 py-2 pr-2 text-sm font-semibold tracking-worderer">Box</th>
								<th className="py-2 pr-2 text-sm font-semibold tracking-worderer">Wholesale Price</th>
								<th className="py-2 text-sm font-semibold tracking-worderer text-right">Action</th>
							</tr>
						</thead>
						<tbody className="divide-y">
							{loadedProducts?.map((product, index) => {
								return (
									<tr
										key={index}
										className={Classnames('cursor-pointer hover:bg-gray-100', {
											'bg-white': !isEmpty(memoedItems) && isEmpty(memoedItems?.filter(item => item.id === product.id)),
											'bg-neutral-100': !isEmpty(memoedItems) && !isEmpty(memoedItems?.filter(item => item.id === product.id)),
										})}
										onClick={!product.inBag ? () => addItem(product) : handleRemovingItemToMemo.bind(this, product)}
									>
										<th className="py-2 pr-2 text-sm font-normal tracking-worderer">{product.name}</th>
										<th className="py-2 text-sm font-normal tracking-worderer"></th>
										<th className="py-2 text-sm font-normal tracking-worderer">{product.sku}</th>
										<th className="py-2 text-base font-normal tracking-worderer">
												<WatchBoxDropdown
													product={product}
													handleUpdateItemProduct={handleUpdateItemProduct}
												/>
											</th>
										<th className="py-2 text-sm font-normal tracking-worderer">
											<input
												onChange={e => {
													e.stopPropagation()
													handleItemPriceChange(product, e.target.value)
												}}
												onClick={e => {
													e.stopPropagation()
													handleItemPriceChange(product, e.target.value)
												}}
												type="text"
												className="border px-2 py-1 rounded"
												value={product.wholesale_price ? product.wholesale_price : 0}
												placeholder="Item Price"
												style={{
													maxWidth: 100,
												}}
											/>
										</th>
										<th className="py-2 text-sm font-normal tracking-worderer text-right">
											{!product.inBag && (
												<button
													// onClick={() => addItem(product) }
													className="inline-flex text-xs bg-green-100 text-green-700 border border-green-100 hover:bg-green-200 hover:border-green-200 transition rounded items-center pl-1 pr-2 py-0.5"
												>
													<svg className="w-4 h-4" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
														<path fillRule="evenodd" d="M10 5a1 1 0 011 1v3h3a1 1 0 110 2h-3v3a1 1 0 11-2 0v-3H6a1 1 0 110-2h3V6a1 1 0 011-1z" clipRule="evenodd" />
													</svg>
													<span>Add</span>
												</button>
											)}
											{product.inBag && (
												<button
													onClick={() => handleRemovingItemToMemo.bind(this, product)}
													className="inline-flex text-xs bg-red-100 text-red-700 border border-red-100 hover:bg-red-200 hover:border-red-200 transition rounded items-center pl-1 pr-2 py-0.5"
												>
													<svg className="w-4 h-4" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
														<path
															fillRule="evenodd"
															d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z"
															clipRule="evenodd"
														/>
													</svg>
													<span>Remove</span>
												</button>
											)}
										</th>
									</tr>
								)
							})}
						</tbody>
					</table>
					{isEmpty(loadedProducts) && <div className="text-xs py-4">No products found</div>}
				</div>

				<div className="absolute bg-white border-t bottom-0 flex justify-end px-10 py-3 w-full">
					<button
						className="hover:bg-blue-400 transition rounded bg-blue-500 text-white px-5 py-2 text-md"
						onClick={() => {
							handleCloseProductsHunter()
						}}
					>
						Done
					</button>
				</div>
			</div>
		</div>
	)
}

export default ProductsHunter
