import React from 'react'
import {connect} from 'react-redux'

import TopTitlePage from '../TopTitlePage'
import {ShareWhatsappEmail} from './ShareWhatsappEmail'
import {ListNotes} from './Notes'
import {ListRepairs} from './Repairs'
import {Globals} from '../../Context'
import {focusElement} from '../../utils/keyboardHandler'
import {addToast} from '../../app/appActions'
import PerformanceLimitAccessModal from '../common/PerformanceLimitAccessModal'

class TopCardDetails extends React.Component {
	constructor() {
		super()
		this.state = {
			visibleNotesDropDown: false,
			visibleRepairsDropDown: false,
			visibleShareWhatAppEmailDropDown: false,
			openPerformanceLimitAccessModal: false,
			isOpenRepair: false,
			isOpenEditRepair: false,
			isOpenNote: false,
			isOpenEditNote: false,
			openEmail: false,
			openDialog: false
		}
		this.buttonRefs = [];

		// Bind the event handler to the class
		this.handleKeyDown = this.handleKeyDown.bind(this);
	}

	handleOpenEdit = e => {
		if (this.props.currentSpace?.internal_subscription.plan_type === 'performance' && this.props.currentSpace?.internal_subscription.status === 'active') {
			this.setState({openPerformanceLimitAccessModal: true})
		} else {
			this.props.openEditPost()
		}
	}


	componentDidMount() {
		window.addEventListener('keydown', this.handleKeyDown);
	}

	  componentWillUnmount() {
		window.removeEventListener('keydown', this.handleKeyDown);
	}

	goToNextButton = (currentIndex) => {
		if (currentIndex > 0) {
			this.buttonRefs[currentIndex - 1].focus();
		}
	}

	goToPreviousButton = (currentIndex) => {
		if (currentIndex < this.buttonRefs.length - 1) {
			this.buttonRefs[currentIndex + 1].focus();
		}
	}

	closeEditInventory = () => {
		this.props.closeEditInventory();
		focusElement('.single-product-view');
	}

	handleKeyDown = (event) => {
		const { previewVisible } = this.props;
		const {isOpenRepair, isOpenEditRepair, isOpenNote, isOpenEditNote, openEmail, openDialog, visibleNotesDropDown, visibleRepairsDropDown, visibleShareWhatAppEmailDropDown} = this.state
		const {openEditInventory} = this.props
		if(isOpenRepair || isOpenEditRepair || isOpenNote || isOpenEditNote || openEmail || previewVisible || openDialog) return
		const focusedElement = document.activeElement;
		const currentIndex = this.buttonRefs.findIndex(ref => ref === focusedElement);
		switch (event.key) {
			case Globals.keyboardButton.arrowLeft:
				if(openEditInventory || visibleNotesDropDown || visibleRepairsDropDown || visibleShareWhatAppEmailDropDown) return
				this.goToNextButton(currentIndex)
				break;
			case Globals.keyboardButton.arrowRight:
				if(openEditInventory  || visibleNotesDropDown || visibleRepairsDropDown || visibleShareWhatAppEmailDropDown) return
				this.goToPreviousButton(currentIndex)
				break;
			case Globals.keyboardButton.escape:
				this.closeEditInventory()
				break;
		}
	};

	addToRefs = (el) => {
		if (el && !this.buttonRefs.includes(el)) {
			this.buttonRefs.push(el);
		}
	};

	handleVisibleShareEmail = (isVisible) => {
		this.setState({openEmail: isVisible})
	}

	handleVisibleDialog = (isVisible) => {
		if (this.props.profile !== 'manager') {
			this.setState({openDialog: isVisible})
		} else {
			return this.props.addToast({
				text: 'You are not authorized to access this page.',
				type: 'error',
			})
		}
	}

	handleVisibleAddRepair = (isVisible) => {
		if (this.props.currentSpace?.internal_subscription.plan_type === 'performance' && this.props.currentSpace?.internal_subscription.status === 'active') {
			this.setState({openPerformanceLimitAccessModal: true})
		} else {
			this.setState({isOpenRepair: isVisible})
		}
	}

	handleVisibleEditRepair = (isVisible) => {
		if (this.props.currentSpace?.internal_subscription.plan_type === 'performance' && this.props.currentSpace?.internal_subscription.status === 'active') {
			this.setState({openPerformanceLimitAccessModal: true})
		} else {
			this.setState({isOpenEditRepair: isVisible})
		}
	}

	handleVisibleAddNote = (isVisible) => {
		if (this.props.currentSpace?.internal_subscription.plan_type === 'performance' && this.props.currentSpace?.internal_subscription.status === 'active') {
			this.setState({openPerformanceLimitAccessModal: true})
		} else {
			this.setState({isOpenNote: isVisible})
		}
	}

	handleVisibleEditNote = (isVisible) => {
		if (this.props.currentSpace?.internal_subscription.plan_type === 'performance' && this.props.currentSpace?.internal_subscription.status === 'active') {
			this.setState({openPerformanceLimitAccessModal: true})
		} else {
			this.setState({isOpenEditNote: isVisible})
		}
	}

	handleOpenPerformanceLimitAccessModal = () => {
		this.setState({openPerformanceLimitAccessModal: true})
	}

	handleVisibleNotesDropDown = (isVisible) => {
		this.setState({visibleNotesDropDown: isVisible, visibleRepairsDropDown: false, visibleShareWhatAppEmailDropDown: false})
	}

	handleVisibleRepairsDropDown = (isVisible) => {
		this.setState({visibleRepairsDropDown: isVisible, visibleNotesDropDown:false, visibleShareWhatAppEmailDropDown: false})
	}

	handleVisibleShareWhatAppEmailDropDown = (isVisible) => {
		this.setState({visibleShareWhatAppEmailDropDown: isVisible, visibleRepairsDropDown: false, visibleNotesDropDown:false})
	}

	render() {
		const props = this.props
		const state = this.state
		return (
			<React.Fragment>
				<div className="flex lg:flex-row flex-col items-center relative  Whitecontainer lg:bg-white border-b pb-5">
					<div className="flex relative w-full pl-10 mr-5">
						<div className="flex items-end truncate w-96">
							<button onClick={e => props.toggleDetailsCardInventory(false)} className="mr-3 cursor-pointer relative lg:-top-2">
								<svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
									<path
										d="M17.8672 3.19508C17.625 3.2008 17.3949 3.30218 17.2271 3.47706L11.0003 9.70395L4.77337 3.47706C4.68784 3.38914 4.58554 3.31928 4.47252 3.27161C4.35951 3.22395 4.23807 3.19944 4.11541 3.19956C3.93314 3.19978 3.75506 3.25434 3.60394 3.35626C3.45282 3.45819 3.33551 3.60284 3.267 3.77176C3.1985 3.94068 3.1819 4.12618 3.21933 4.30457C3.25676 4.48297 3.34652 4.64615 3.47715 4.77329L9.70404 11.0002L3.47715 17.2271C3.38917 17.3115 3.31894 17.4127 3.27055 17.5247C3.22216 17.6366 3.1966 17.7571 3.19536 17.879C3.19412 18.001 3.21722 18.122 3.26332 18.2349C3.30942 18.3478 3.37759 18.4504 3.46382 18.5366C3.55006 18.6229 3.65264 18.691 3.76556 18.7371C3.87847 18.7832 3.99944 18.8063 4.1214 18.8051C4.24335 18.8038 4.36383 18.7783 4.47578 18.7299C4.58774 18.6815 4.68891 18.6113 4.77337 18.5233L11.0003 12.2964L17.2271 18.5233C17.3116 18.6113 17.4128 18.6815 17.5247 18.7299C17.6367 18.7783 17.7572 18.8038 17.8791 18.8051C18.0011 18.8063 18.1221 18.7832 18.235 18.7371C18.3479 18.691 18.4505 18.6229 18.5367 18.5366C18.6229 18.4504 18.6911 18.3478 18.7372 18.2349C18.7833 18.122 18.8064 18.001 18.8052 17.879C18.8039 17.7571 18.7784 17.6366 18.73 17.5247C18.6816 17.4127 18.6113 17.3115 18.5234 17.2271L12.2965 11.0002L18.5234 4.77329C18.6568 4.64538 18.7484 4.48015 18.7862 4.29926C18.824 4.11837 18.8063 3.93027 18.7354 3.75963C18.6644 3.58899 18.5436 3.44377 18.3886 3.34303C18.2337 3.24228 18.052 3.19071 17.8672 3.19508Z"
										fill="#5D6E81"
									/>
								</svg>
							</button>
							<TopTitlePage title="DEALER SKU #" />
							<h2 className="ml-2 relative font-semibold lg:text-3xl truncate">{props.post?.sku}</h2>
							<span></span>
						</div>
					</div>
					{
						//['owner', 'administrator', 'editor', ''].includes(this.props?.profile.role) && (
						<div className="flex lg:relative place-content-right lg:right-0 justify-center items-center mt-6 ">
							<ShareWhatsappEmail
								ref={ref => this.addToRefs(ref)}
								data={props.post} profile={props.profile}
								handleVisibleShareWhatAppEmailDropDown={this.handleVisibleShareWhatAppEmailDropDown}
								visibleShareWhatAppEmailDropDown={this.state.visibleShareWhatAppEmailDropDown}
								handleVisibleShareEmail={this.handleVisibleShareEmail}
								openEmail={state.openEmail}
								handleVisibleDialog={this.handleVisibleDialog}
								openDialog={state.openDialog}
								handlePreview={props.handlePreview}
								currentSpace={props.currentSpace}
							/>
							<ListNotes
								ref={ref => this.addToRefs(ref)}
								data={props.post}
								handleVisibleNotesDropDown={this.handleVisibleNotesDropDown}
								visibleNotesDropDown={this.state.visibleNotesDropDown}
								handleVisibleAddNote={this.handleVisibleAddNote}
								handleOpenPerformanceLimitAccessModal={this.handleOpenPerformanceLimitAccessModal}
								isOpenNote={state.isOpenNote}
								handleVisibleEditNote={this.handleVisibleEditNote}
								currentSpace={props.currentSpace}
								isOpenEditNote={state.isOpenEditNote}
								addToast={this.props.addToast}
								role={props.profile?.role}
							/>
							<ListRepairs
								ref={ref => this.addToRefs(ref)}
								data={props.post}
								onDone={this.props.onDone}
								handleVisibleRepairsDropDown={this.handleVisibleRepairsDropDown}
								visibleRepairsDropDown={this.state.visibleRepairsDropDown}
								handleVisibleAddRepair={this.handleVisibleAddRepair}
								isOpenRepair={state.isOpenRepair}
								handleVisibleEditRepair={this.handleVisibleEditRepair}
								isOpenEditRepair={state.isOpenEditRepair}
								addToast={this.props.addToast}
								role={props.profile?.role}
							/>
							{['owner', 'admin', 'editor'].includes(props.profile?.role) &&
								<button ref={ref => this.addToRefs(ref)}  className="text-sm bg-white flex items-center px-3 w-56 sm:mr-5 mr-1 ButtonSort" onClick={this.handleOpenEdit}>
									<svg className="mr-2" width="20" height="21" viewBox="0 0 20 21" fill="#5D6E81" xmlns="http://www.w3.org/2000/svg">
										<path d="M1 15.2832V19.0332H4.75L15.81 7.9732L12.06 4.2232L1 15.2832ZM3.92 17.0332H3V16.1132L12.06 7.0532L12.98 7.9732L3.92 17.0332ZM18.71 3.6632L16.37 1.3232C16.17 1.1232 15.92 1.0332 15.66 1.0332C15.4 1.0332 15.15 1.1332 14.96 1.3232L13.13 3.1532L16.88 6.9032L18.71 5.0732C19.1 4.6832 19.1 4.0532 18.71 3.6632Z" />
									</svg>
									<span className="font-normal">Edit</span>
								</button>
							}
						</div>
						//)
					}
				</div>

				{state.openPerformanceLimitAccessModal && (
					<PerformanceLimitAccessModal
						closeModal={() => this.setState({openPerformanceLimitAccessModal: false})}
					/>
				)}
			</React.Fragment>
		)
	}
}
const mapStateToProps = (state, props) => {
	return {
		currentSpace: state.currentSpace
	}
}

const mapActionsToProps = {
	addToast,
}

export default connect(mapStateToProps, mapActionsToProps)(TopCardDetails)
