import React from 'react'
import {connect} from 'react-redux'

import {addToast} from '../../../app/appActions'
import ContentDialog from './ContentDialog'

const ShareWhatAppEmailDialog = (props) => {
	const { titleHeader, handleVisibleDialog, handleSubmit, handlePreview, openGalleryItems } = props

	return (
		<div tabIndex="-1" aria-hidden="true" className="bg-black/50 fixed flex h-screen items-center justify-center left-0 overflow-hidden inset-0 w-full z-50 ">
			<div className="relative max-h-[1050px] max-w-[583px]">
				<div className="relative bg-white rounded-xl shadow h-full">
					<div className="flex justify-between items-start px-8 py-4 rounded-t-xl border-b bg-slate-600 text-white">
						<h3 className="text-white font-bold text-lg">{titleHeader}</h3>
						<button
							onClick={() => {
								handleVisibleDialog(false)
							}}
							type="button"
							className="bg-transparent rounded-xl text-sm py-1.5 ml-auto inline-flex items-center"
						>
							<svg className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
								<path
									fillRule="evenodd"
									d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
									clipRule="evenodd"
								></path>
							</svg>
						</button>
					</div>
					<ContentDialog handleSubmit={handleSubmit} handlePreview={handlePreview} openGalleryItems={openGalleryItems} {...props}/>
				</div>
			</div>
		</div>
	)
}

const mapStateToProps = (state, props) => {
	return {
	}
}

const mapActionsToProps = {
	addToast,
}

export default connect(mapStateToProps, mapActionsToProps)(ShareWhatAppEmailDialog)
