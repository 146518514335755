import React, {useRef, useEffect} from 'react'
import {Swiper, SwiperSlide} from 'swiper/react'
import {Scrollbar} from 'swiper/modules'
import 'swiper/css'
import './previewimage.css'
import 'swiper/css/scrollbar'

const WatchDetailImagesMobile = ({data}) => {
	const {images} = data
	const swiperElRef = useRef(null)

	useEffect(() => {
		if (swiperElRef.current) {
			swiperElRef.current.swiper.update()
		}
	}, [])

	return (
		<div className="mb-4 w-full mx-auto md:hidden">
			<Swiper
				ref={swiperElRef}
				scrollbar={{
					hide: false,
					el: '.swiper-scrollbar-wrapper',
				}}
				modules={[Scrollbar]}
				className="mySwiper relative"
				autoHeight={true}
			>
				{images.map((image, index) => (
					<SwiperSlide key={index}>
						{image?.type.includes('video') ? (
							<video
                                className="w-full h-full"
                                controls
                                poster={image.thumb_url}
                            >
								<source src={image.video_url} type="video/mp4" />
							</video>
							) : (
								<img src={image.url} alt="watch" className="w-full h-auto mx-auto object-contain" />
							)}
					</SwiperSlide>
				))}
				<div className="swiper-scrollbar swiper-scrollbar-wrapper max-w-3/4 absolute z-[9999999999999] !bg-[#ccc]"></div>
			</Swiper>
		</div>
	)
}

export default WatchDetailImagesMobile
