import React, {useState, useEffect} from 'react'

import ContactInformation from './ContactInformation'
import CustomerInvoices from './CustomerInvoices'
import CustomerLedger from './CustomerLedger'
import CustomerTransactions from './CustomerTransactions'
import {focusElement, onPressEscape} from '../../utils/keyboardHandler'

const CustomerDetail = ({singleCustomerView, handleClickOut, setVisibleSingleCustomerView, handleOpenEditForm, props}) => {
	//const [singleCustomerView, setSingleCustomerView] = useState()
	const [visibleTab, setVisibleTab] = useState('contact')
	const activeClassHref = 'inline-flex items-center justify-center p-4 text-blue-600 border-b-2 border-blue-600 rounded-t-lg active dark:text-blue-500 dark:border-blue-500 group'
	const activeClassSvg = 'w-6 h-6 mr-2 text-blue-600 dark:text-blue-500'

	useEffect(() => {
		focusElement('.single-customer-view')
	}, [])

	return (
		<div className="single-customer-view bg-black/50 fixed top-0 right-0 left-0 bottom-0 z-40" onClick={handleClickOut} tabIndex="-1" onKeyDown={onPressEscape(event => handleClickOut(event))}>
			<div className="bg-white rounded-tl-xl absolute top-0 right-0 shadow-xl overflow-hidden h-full" style={{maxWidth: '1200px', width: '100%'}}>
				<div className="flex items-center gap-3 bg-white border-b py-6 px-10">
					<div className="flex flex-row items-center justify-between w-full">
						<div className="flex gap-1">
							<button onClick={setVisibleSingleCustomerView}>
								<svg className="w-6 h-6" viewBox="0 0 22 22" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
									<path d="M17.8672 3.19508C17.625 3.2008 17.3949 3.30218 17.2271 3.47706L11.0003 9.70395L4.77337 3.47706C4.68784 3.38914 4.58554 3.31928 4.47252 3.27161C4.35951 3.22395 4.23807 3.19944 4.11541 3.19956C3.93314 3.19978 3.75506 3.25434 3.60394 3.35626C3.45282 3.45819 3.33551 3.60284 3.267 3.77176C3.1985 3.94068 3.1819 4.12618 3.21933 4.30457C3.25676 4.48297 3.34652 4.64615 3.47715 4.77329L9.70404 11.0002L3.47715 17.2271C3.38917 17.3115 3.31894 17.4127 3.27055 17.5247C3.22216 17.6366 3.1966 17.7571 3.19536 17.879C3.19412 18.001 3.21722 18.122 3.26332 18.2349C3.30942 18.3478 3.37759 18.4504 3.46382 18.5366C3.55006 18.6229 3.65264 18.691 3.76556 18.7371C3.87847 18.7832 3.99944 18.8063 4.1214 18.8051C4.24335 18.8038 4.36383 18.7783 4.47578 18.7299C4.58774 18.6815 4.68891 18.6113 4.77337 18.5233L11.0003 12.2964L17.2271 18.5233C17.3116 18.6113 17.4128 18.6815 17.5247 18.7299C17.6367 18.7783 17.7572 18.8038 17.8791 18.8051C18.0011 18.8063 18.1221 18.7832 18.235 18.7371C18.3479 18.691 18.4505 18.6229 18.5367 18.5366C18.6229 18.4504 18.6911 18.3478 18.7372 18.2349C18.7833 18.122 18.8064 18.001 18.8052 17.879C18.8039 17.7571 18.7784 17.6366 18.73 17.5247C18.6816 17.4127 18.6113 17.3115 18.5234 17.2271L12.2965 11.0002L18.5234 4.77329C18.6568 4.64538 18.7484 4.48015 18.7862 4.29926C18.824 4.11837 18.8063 3.93027 18.7354 3.75963C18.6644 3.58899 18.5436 3.44377 18.3886 3.34303C18.2337 3.24228 18.052 3.19071 17.8672 3.19508Z" />
								</svg>
							</button>
							<h2 className="font-semibold text-2xl uppercase">
								#{singleCustomerView.id} {singleCustomerView.company_name}
							</h2>
						</div>

						<div>
							<button className="text-sm bg-white flex items-center px-3 w-56  ButtonSort" onClick={handleOpenEditForm}>
								<svg className="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
									<path
										strokeLinecap="round"
										strokeLinejoin="round"
										strokeWidth={2}
										d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z"
									/>
								</svg>
								<span>Edit</span>
							</button>
						</div>
					</div>
				</div>

				<div className="border-b border-gray-200 dark:border-gray-700">
					<ul className="flex flex-wrap -mb-px text-sm font-medium text-center text-gray-500 dark:text-gray-400">
						<li
							className="mr-1 text-black"
							onClick={() => {
								setVisibleTab('contact')
							}}
						>
							<a
								href="#"
								className={
									visibleTab === 'contact'
										? activeClassHref
										: 'inline-flex items-center justify-center p-4 border-b-2 border-transparent rounded-t-lg hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300 group'
								}
							>
								<svg
									className={visibleTab === 'contact' ? activeClassSvg : 'w-4 h-4 mr-2 text-gray-400 group-hover:text-gray-500 dark:text-gray-500 dark:group-hover:text-gray-300'}
									stroke="currentColor"
									fill="none"
									viewBox="0 0 22 18"
									xmlns="http://www.w3.org/2000/svg"
									aria-hidden="true"
								>
									<path
										d="M16 17H21V15C21 13.3431 19.6569 12 18 12C17.0444 12 16.1931 12.4468 15.6438 13.1429M16 17H6M16 17V15C16 14.3438 15.8736 13.717 15.6438 13.1429M6 17H1V15C1 13.3431 2.34315 12 4 12C4.95561 12 5.80686 12.4468 6.35625 13.1429M6 17V15C6 14.3438 6.12642 13.717 6.35625 13.1429M6.35625 13.1429C7.0935 11.301 8.89482 10 11 10C13.1052 10 14.9065 11.301 15.6438 13.1429M14 4C14 5.65685 12.6569 7 11 7C9.34315 7 8 5.65685 8 4C8 2.34315 9.34315 1 11 1C12.6569 1 14 2.34315 14 4ZM20 7C20 8.10457 19.1046 9 18 9C16.8954 9 16 8.10457 16 7C16 5.89543 16.8954 5 18 5C19.1046 5 20 5.89543 20 7ZM6 7C6 8.10457 5.10457 9 4 9C2.89543 9 2 8.10457 2 7C2 5.89543 2.89543 5 4 5C5.10457 5 6 5.89543 6 7Z"
										strokeWidth="2"
										strokeLinecap="round"
										strokeLinejoin="round"
									/>
								</svg>
								Contact Information
							</a>
						</li>
						<li
							className="mr-1 text-black"
							onClick={() => {
								setVisibleTab('transactions')
							}}
						>
							<a
								href="#"
								className={
									visibleTab === 'transactions'
										? activeClassHref
										: 'inline-flex items-center justify-center p-4 border-b-2 border-transparent rounded-t-lg hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300 group'
								}
								aria-current="page"
							>
								<svg
									fill="currentColor"
									viewBox="0 0 18 20"
									xmlns="http://www.w3.org/2000/svg"
									aria-hidden="true"
									className={
										visibleTab === 'transactions' ? activeClassSvg : 'w-4 h-4 mr-2 text-gray-400 group-hover:text-gray-500 dark:text-gray-500 dark:group-hover:text-gray-300'
									}
								>
									<path d="M16.5 1.5L15 0L13.5 1.5L12 0L10.5 1.5L9 0L7.5 1.5L6 0L4.5 1.5L3 0L1.5 1.5L0 0V20L1.5 18.5L3 20L4.5 18.5L6 20L7.5 18.5L9 20L10.5 18.5L12 20L13.5 18.5L15 20L16.5 18.5L18 20V0L16.5 1.5ZM16 17.09H2V2.91H16V17.09ZM3 13H15V15H3V13ZM3 9H15V11H3V9ZM3 5H15V7H3V5Z" />
								</svg>
								Transactions
							</a>
						</li>
						<li
							className="mr-1 text-black"
							onClick={() => {
								setVisibleTab('ledger')
							}}
						>
							<a
								href="#"
								className={
									visibleTab === 'ledger'
										? activeClassHref
										: 'inline-flex items-center justify-center p-4 border-b-2 border-transparent rounded-t-lg hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300 group'
								}
							>
								<svg
									className={visibleTab === 'ledger' ? activeClassSvg : 'w-4 h-4 mr-2 text-gray-400 group-hover:text-gray-500 dark:text-gray-500 dark:group-hover:text-gray-300'}
									stroke="currentColor"
									fill="none"
									viewBox="0 0 16 20"
									xmlns="http://www.w3.org/2000/svg"
									aria-hidden="true"
								>
									<path
										d="M5 12L11 6M5.50003 6.5H5.51003M10.5 11.5H10.51M15 19V3C15 1.89543 14.1046 1 13 1H3C1.89543 1 1 1.89543 1 3V19L4.5 17L8 19L11.5 17L15 19ZM6 6.5C6 6.77614 5.77614 7 5.5 7C5.22386 7 5 6.77614 5 6.5C5 6.22386 5.22386 6 5.5 6C5.77614 6 6 6.22386 6 6.5ZM11 11.5C11 11.7761 10.7761 12 10.5 12C10.2239 12 10 11.7761 10 11.5C10 11.2239 10.2239 11 10.5 11C10.7761 11 11 11.2239 11 11.5Z"
										strokeWidth="2"
										strokeLinecap="round"
										strokeLinejoin="round"
									/>
								</svg>
								Ledger
							</a>
						</li>
						<li
							className="mr-1 text-black"
							onClick={() => {
								setVisibleTab('invoices')
							}}
						>
							<a
								href="#"
								className={
									visibleTab === 'invoices'
										? activeClassHref
										: 'inline-flex items-center justify-center p-4 border-b-2 border-transparent rounded-t-lg hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300 group'
								}
							>
								<svg
									className={visibleTab === 'invoices' ? activeClassSvg : 'w-4 h-4 mr-2 text-gray-400 group-hover:text-gray-500 dark:text-gray-500 dark:group-hover:text-gray-300'}
									aria-hidden="true"
									xmlns="http://www.w3.org/2000/svg"
									fill="currentColor"
									viewBox="0 0 25 25"
								>
									<path d="M16 1h-3.278A1.992 1.992 0 0 0 11 0H7a1.993 1.993 0 0 0-1.722 1H2a2 2 0 0 0-2 2v15a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2V3a2 2 0 0 0-2-2Zm-3 14H5a1 1 0 0 1 0-2h8a1 1 0 0 1 0 2Zm0-4H5a1 1 0 0 1 0-2h8a1 1 0 1 1 0 2Zm0-5H5a1 1 0 0 1 0-2h2V2h4v2h2a1 1 0 1 1 0 2Z" />
								</svg>
								Invoices
							</a>
						</li>
					</ul>
				</div>
				{visibleTab === 'contact' && <ContactInformation singleCustomerView={singleCustomerView} />}
				{visibleTab === 'invoices' && <CustomerInvoices props={props} singleCustomerView={singleCustomerView} />}
				{visibleTab === 'ledger' && <CustomerLedger props={props} singleCustomerView={singleCustomerView} />}
				{visibleTab === 'transactions' && <CustomerTransactions props={props} singleCustomerView={singleCustomerView} />}
			</div>
		</div>
	)
}

export default CustomerDetail
