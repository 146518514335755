import {useState} from 'react'
import Personal from './Personal'
import Organization from './Organization'
import {connect} from 'react-redux'
import {Globals} from '../../Context'

const Contactinfo = props => {
	const [isPersonal, setPersonal] = useState(true)
	const [Organisation, setOrganisation] = useState(false)
	const [billing, setBilling] = useState(false)
	const OpenPersonal = () => {
		setPersonal(true)
		setOrganisation(false)
		setBilling(false)
	}

	const OpenOrganisation = () => {
		setPersonal(false)
		setBilling(false)
		setOrganisation(true)
	}

	const openBilling = () => {
		openBillingPortal()
	}

	const openBillingPortal = () => {
		Globals.New_Axios().post(`${Globals.NEW_API_URL}/${Globals.ENDPOINTVERSION}/spaces/${Globals.SELECTEDSPACEID}/internal_subscriptions/billing_portal`)
			.then(response => {
				const url = response.data.url
				window.open(url, '_blank')
			}
		)
	}

	return (
		<div className="w-full">
			{(props.profile.role === "owner" || props.profile.role === "admin") && (
				<div className="flex border-b-2 w-full pl-5">
					<button
						className="cursor-pointer flex flex-row justify-center items-center border-b-2 mr-2 sm:mr-4"
						style={{
							fontWeight: isPersonal ? '700' : '500',
							borderColor: isPersonal ? '#2C79E0' : 'transparent',
						}}
						onClick={OpenPersonal}
					>
						<span className="lg:text-sm text-xxs w-full">Personal</span>
					</button>
					<button
						className="cursor-pointer flex flex-row justify-center items-center border-b-2 mx-2 sm:mx-4 py-3"
						style={{
							fontWeight: Organisation ? '700' : '500',
							borderColor: Organisation ? '#2C79E0' : 'transparent',
						}}
						onClick={OpenOrganisation}
					>
						<span className="lg:text-sm text-xxs w-full">Company details</span>
					</button>
					<button
						className="cursor-pointer flex flex-row justify-center items-center border-b-2 mx-2 sm:mx-4 py-3"
						style={{
							fontWeight: billing ? '700' : '500',
							borderColor: billing ? '#2C79E0' : 'transparent',
						}}
						onClick={openBilling}
					>
						<span className="lg:text-sm text-xxs w-full">Billing</span>
					</button>
				</div>
			)}
			{isPersonal && <Personal />}
			{(Organisation && props.profile.role === "owner" || Organisation && props.profile.role === "admin") && <Organization />}
		</div>
	)
}

const mapStateToProps = (state, props) => {
	return {
		profile: state.profile,
	}
}


const mapActionsToProps = {}

export default connect(mapStateToProps, mapActionsToProps)(Contactinfo)
