import {SortableContainer} from 'react-sortable-hoc'
import {arrayMoveImmutable} from 'array-move'
import PropTypes from 'prop-types'
import React, {Component} from 'react'
import AddPhotoAlternateOutlinedIcon from '@mui/icons-material/AddPhotoAlternateOutlined';
import {v4 as uuid} from 'uuid'
import ProcessUpload from '../../../utils/ProcessUpload'
import './UploadMediaMobile.scss'
import isObject from 'lodash/isObject'
import IconButton from '@mui/material/IconButton'
import Box from '@mui/material/Box'

class UploadMediaMobile extends Component {
	constructor() {
		super()
		this.state = {
			filesList: [],
			errors: [],
			previewPicture: null,
		}
	}

	componentDidMount() {
		let loadedImages = []
		if (this.props.defaultValue) {
			let defaultImage = !isObject(this.props.defaultValue) ? JSON.parse(this.props.defaultValue) : this.props.defaultValue

			defaultImage.forEach(image => {
				loadedImages.push({
					id: image,
					post_id: image,
					done: true,
					file: undefined,
					size: undefined,
					type: undefined,
					progress: 100,
					src: image.url,
				})
			})
			this.setState({filesList: loadedImages}, () => this.props.uploadUpdate(this.state.filesList))
		}
		if (this.props.defaultImages) {
			this.props.defaultImages.forEach((image, index) => {
				loadedImages.push({
					id: index,
					post_id: index,
					done: true,
					file: undefined,
					size: undefined,
					type: undefined,
					progress: 100,
					src: image,
				})
			})
			this.setState({filesList: loadedImages})
		}
	}

	componentWillReceiveProps(nextProps) {
		if (nextProps.image !== this.props.image) {
			this.handleRemovePicture(nextProps.image)
		  }
	}

	handleFileReading = async $file => {
		try {
			return new Promise((resolve, reject) => {
				const fileReader = new FileReader()
				fileReader.readAsDataURL($file)
				fileReader.onloadend = $response => {
					const {
						target: {result},
					} = $response
					return resolve(result)
				}
			})
		} catch (error) {
			return false
		}
	}

	handleInputChange = async $event => {
		try {
			const state = this.state
			const {files} = $event.target

			let newPreviews = await Promise.all(
				Object.values(files).map(async file => {
					if (!this.props.allowedTypes.includes(file.type))
						this.setState({
							errors: [...state.errors, `${file.name} type is not allowed`],
						})

					//this.props.uploadUpdate(file)

					if (this.props.allowedTypes.includes(file.type)) {
						const src = await this.handleFileReading(file)

						const fileId = uuid()
						const _file = {
							id: fileId,
							src: src,
							file: file,
							type: file.type,
							size: file.size,
							progress: 0,
							done: false,
						}

						ProcessUpload(
							_file.file,
							100000,
							res => {
								//Progress update
								let filesList = this.state.filesList
								filesList = filesList.map(file => {
									if (!file) return null
									if (file.id === fileId) {
										file.progress = Math.round(res)
									}
									return file
								})

								this.setState({filesList: filesList})
							},
							res => {
								//Done
								let filesList = this.state.filesList
								filesList = filesList.map(file => {
									if (!file) return null
									if (file.id === fileId) {
										file.progress = 100
										file.done = true
										file.post_id = res.upload
									}
									return file
								})

								this.setState({filesList: filesList}, () => this.props.uploadUpdate(this.state.filesList))
							},
							error => {
								//Error happened.
								let filesList = this.state.filesList
								filesList = filesList.filter(_file => _file.id !== fileId)
								this.setState({filesList: filesList}, () => this.props.uploadUpdate(this.state.filesList))
								this.setState({
									errors: [...this.state.errors, `unable to upload file: ${file.name}`],
								})
							},
							this.props.uploadTo
						)

						return _file
					}
					return null
				})
			)

			let {filesList} = state
			newPreviews = newPreviews.filter(file => file !== null) //clear files
			filesList = [...filesList, ...newPreviews]
			//this.UploadField.value = null
			this.setState({filesList})
		} catch (error) {
			console.warn(error)
		}
	}

	handleRemovePicture = async (id) => {
		let filesList = this.state.filesList
		filesList = filesList.filter(file => file.post_id.id !== id.id)
		this.setState({filesList: filesList}, () => this.props.uploadUpdate(this.state.filesList))
	}

	handleDrop = async $event => {
		$event.preventDefault()
		$event.target = $event.dataTransfer
		this.handleInputChange($event)
	}

	dismissError = _index => {
		let errors = this.state.errors
		errors = errors.filter((error, index) => index !== _index)
		this.setState({errors: errors})
	}

	handlePreview = (picture, $event) => {
		$event.preventDefault()
		this.setState({previewPicture: picture}, () => this.previewPictureContainer.focus())
	}

	render() {
		const props = this.props
		const state = this.state

		// const SortableItem = SortableElement(({value}) => <div>{value}</div>)
		const SortableList = SortableContainer(({items}) => {
			return (
				<div
					className={props.innerClassNames ? props.innerClassNames : ''}
					onDragOver={e => e.preventDefault()}
					onDrop={this.handleDrop}
				>
					<div key="empty-index">
						<label className="border-dashed border-2 border-[#D9E2EE] rounded-md flex flex-col items-center justify-center cursor-pointer min-w-[62px] h-[198px] bg-[#F7F7F7] mr-2">
							<IconButton aria-label="upload pictures" component="span">
								<AddPhotoAlternateOutlinedIcon className="text-black" sx={{fontSize: '40px'}} />
							</IconButton>

							<input
								type="file"
								id="dropzone-file"
								className="hidden"
								multiple
								onChange={this.handleInputChange}
								accept={props.allowedTypes.join(',')}
								ref={UploadField => {
									this.UploadField = UploadField
								}}
							/>
							<Box component="span" sx={{ display: 'block' }} className="text-black">Add File</Box>
						</label>
					</div>
				</div>
			)
		})

		return (
			<div>
				<div className="upload-media-container">
					<SortableList
						axis="xy"
						helperClass="z-50"
						onSortMove={e => {
							//debugger
						}}
						onSortEnd={({oldIndex, newIndex}) => {
							this.setState(
								({filesList}) => ({
									filesList: arrayMoveImmutable(filesList, oldIndex, newIndex),
								}),
								() => {
									this.props.uploadUpdate(this.state.filesList)
								}
							)
						}}
						items={state.filesList.map((file, index) => {
							if (!file) return ''
							return (
								<div key={index} className="relative bg-gray-100 rounded-xl border-2 border-dashed border-gray-100 overflow-hidden" style={{paddingTop: '100%'}}>
									<div
										className="absolute top-0 left-0 right-0 bottom-0 flex justify-center items-center bg-cover bg-center"
										style={{
											backgroundImage: `url(${file.src})`,
										}}
									>
										<button className="bg-blue rounded-full p-2 absolute top-2 right-2 z-auto" onClick={this.handleRemovePicture.bind(this, file.id)}>
											<svg className="pointer-events-none" width="10" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
												<path
													d="M14.8672 0.194897C14.625 0.200615 14.3949 0.301995 14.2271 0.47688L8.00026 6.70377L1.77337 0.47688C1.68784 0.38896 1.58554 0.319098 1.47252 0.271431C1.35951 0.223764 1.23807 0.199261 1.11541 0.199373C0.933136 0.199599 0.755062 0.254158 0.60394 0.35608C0.452819 0.458003 0.335512 0.602661 0.267004 0.771577C0.198497 0.940492 0.181898 1.126 0.21933 1.30439C0.256762 1.48278 0.346524 1.64597 0.47715 1.7731L6.70404 7.99999L0.47715 14.2269C0.389174 14.3113 0.318936 14.4125 0.27055 14.5245C0.222163 14.6364 0.196601 14.7569 0.19536 14.8789C0.194119 15.0008 0.217224 15.1218 0.263323 15.2347C0.309421 15.3476 0.377586 15.4502 0.463825 15.5364C0.550064 15.6227 0.652643 15.6908 0.765556 15.7369C0.878469 15.783 0.999445 15.8061 1.1214 15.8049C1.24335 15.8037 1.36383 15.7781 1.47578 15.7297C1.58774 15.6813 1.68891 15.6111 1.77337 15.5231L8.00026 9.29622L14.2271 15.5231C14.3116 15.6111 14.4128 15.6813 14.5247 15.7297C14.6367 15.7781 14.7572 15.8037 14.8791 15.8049C15.0011 15.8061 15.1221 15.783 15.235 15.7369C15.3479 15.6908 15.4505 15.6227 15.5367 15.5364C15.6229 15.4502 15.6911 15.3476 15.7372 15.2347C15.7833 15.1218 15.8064 15.0008 15.8052 14.8789C15.8039 14.7569 15.7784 14.6364 15.73 14.5245C15.6816 14.4125 15.6113 14.3113 15.5234 14.2269L9.29648 7.99999L15.5234 1.7731C15.6568 1.6452 15.7484 1.47997 15.7862 1.29908C15.824 1.11819 15.8063 0.93009 15.7354 0.759448C15.6644 0.588807 15.5436 0.443591 15.3886 0.342846C15.2337 0.2421 15.052 0.19053 14.8672 0.194897Z"
													fill="white"
												/>
											</svg>
										</button>
										{!file.done && (
											<div className="progress">
												<svg
													width="40"
													viewBox="0 0 120 120"
													style={{
														transform: 'rotate(-90deg)',
													}}
												>
													<circle cx="60" cy="60" r="54" fill="none" stroke="white" strokeWidth="12" pathLength="100"></circle>
													<circle
														strokeDashoffset={-file.progress}
														strokeDasharray="100"
														cx="60"
														cy="60"
														r="54"
														fill="none"
														stroke="black"
														strokeWidth="12"
														pathLength="100"
													/>
												</svg>
											</div>
										)}
									</div>
								</div>
							)
						})}
					/>
				</div>
			</div>
		)
	}
}

UploadMediaMobile.defaultProps = {
	text: 'Add Photos',
	maximum: 40,
	allowedTypes: ['image/jpeg', 'image/jpg', 'image/png'],
	uploadUpdate: response => {},
}

UploadMediaMobile.propTypes = {
	text: PropTypes.string,
	maximum: PropTypes.number,
	allowedTypes: PropTypes.array,
	uploadUpdate: PropTypes.func,
}

export default UploadMediaMobile
